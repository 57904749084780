const SEARCH_LIST_MEMBERS_DATA_REQUEST = "SEARCH_LIST_MEMBERS_DATA_REQUEST";
const SEARCH_LIST_MEMBERS_DATA_SUCCESS = "SEARCH_LIST_MEMBERS_DATA_SUCCESS";
const SEARCH_LIST_MEMBERS_DATA_FAILURE = "SEARCH_LIST_MEMBERS_DATA_FAILURE";

const CLEAR_SEARCH_LIST_MEMBERS_RESPONSE = "CLEAR_SEARCH_LIST_MEMBERS_RESPONSE";

const SEND_REQUEST_MEMBERS_DATA_REQUEST = "SEND_REQUEST_MEMBERS_DATA_REQUEST";
const SEND_REQUEST_MEMBERS_DATA_SUCCESS = "SEND_REQUEST_MEMBERS_DATA_SUCCESS";
const SEND_REQUEST_MEMBERS_DATA_FAILURE = "SEND_REQUEST_MEMBERS_DATA_FAILURE";

const CLEAR_SEND_REQUEST_MEMBERS_RESPONSE =
  "CLEAR_SEND_REQUEST_MEMBERS_RESPONSE";

export {
  SEARCH_LIST_MEMBERS_DATA_REQUEST,
  SEARCH_LIST_MEMBERS_DATA_SUCCESS,
  SEARCH_LIST_MEMBERS_DATA_FAILURE,
  CLEAR_SEARCH_LIST_MEMBERS_RESPONSE,
  SEND_REQUEST_MEMBERS_DATA_REQUEST,
  SEND_REQUEST_MEMBERS_DATA_SUCCESS,
  SEND_REQUEST_MEMBERS_DATA_FAILURE,
  CLEAR_SEND_REQUEST_MEMBERS_RESPONSE,
};
