import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../library/urls";
import { setCookie } from "../../library/utilities/functions";
import { changeChatDetails } from "../PoolsChat/PoolsChatAction";
import { useHistory } from "react-router";
import { FaAngleDoubleDown } from "react-icons/fa";
import { searchServiceApi } from "../../library/api/SearchApiService";
import { serviceList } from "./SearchListAction";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchMiniLoading from "./Loading/SearchMiniLoading";
import RequestModal from "../PoolsChat/RequestModal";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const ServicesSearchList = ({
  isSevicesPage,
  showOnlyThreeServie,
  setShowOnlyThreeService,
}) => {
  const searchListState = useSelector((state) => state.SearchListReducer);
  const serviceDataFromRedux = useSelector(
    (state) => state?.searchLoadingReducer.serviceList
  );
  const isloading = useSelector((state) => state?.searchLoadingReducer.data);
  const toolbarState = useSelector((state) => state.ToolbarReducer);
  const {
    searchNameInput: { reduxSearchName },
  } = toolbarState;
  const {
    searchServiceList: { list, current_page, total },
  } = searchListState;
  const {
    searchServiceApi: { searchServiceListLoading },
  } = searchListState;
  const { searchList } = searchListState;
  const history = useHistory();
  const [serviceData, setServiceData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [allServiceList, setAllServiceList] = useState([]);
  const [ishaveData, setisHaveData] = useState(false);
  const dispatch = useDispatch();
  const [editAppointent, setEditAppointment] = useState(false);
  const [userDetails,setUserDetails]=useState({})
  const [requestData, setRequestData] = useState({
    date: new Date(),
    request_nature: "",
    description: "",
    reschedule: false,
    iscancel: false,
    title: "",
  });

  const requestModalRef = useRef();
  /* navigate to chat component with userId */
  const handleChat = (userId, isFriend) => {
    if (isFriend) {
      setCookie("friendId", userId, 1);
      setCookie("chatType", "user");
      dispatch(changeChatDetails({ currenUserId: userId }));
      history.push("/chat");
    }
  };
  const dat = new Date();
  function getTwentyFourHourTime(amPmString) {
    var date = new Date("1/1/2013 " + amPmString);
    return `${dat.getFullYear()}-${(dat.getMonth() + 1).toString().length < 2
      ? "0" + (dat.getMonth() + 1).toString()
      : dat.getMonth() + 1
      }-${dat.getDate().toString().length < 2
        ? "0" + dat.getDate().toString()
        : dat.getDate()
      }T${date.getHours().toString().length < 2
        ? "0" + date.getHours().toString()
        : date.getHours()
      }:${date.getMinutes().toString().length < 2
        ? "0" + date.getMinutes().toString()
        : date.getMinutes()
      }`;
  }
  /* fiter array if day and time match with local_time and date  */
  useEffect(() => {
    const TodayDate = new Date().getDay();
    allServiceList?.map((itemsA, index) => {
      // allServiceList[index].isOpen = "Closed";
      itemsA.availability?.length > 0 &&
        itemsA.availability?.map((itemsB) => {
          if (weekday[TodayDate] == itemsB.day_of_week) {
            const date1 = new Date(getTwentyFourHourTime(itemsB.time_from));
            const date2 = new Date(getTwentyFourHourTime(itemsB.time_to));
            if (
              dat.getTime() <= date1.getTime() &&
              itemsB.time_from != "OPEN 24 HOURS"
            ) {
              // allServiceList[index].isOpen = "Closed";
              allServiceList[index].startingTime = itemsB.time_from;
            } else if (
              dat.getTime() < date2.getTime() &&
              itemsB.time_from != "OPEN 24 HOURS"
            ) {
              // allServiceList[index].isOpen = "Open";
              allServiceList[index].EndingTime = itemsB.time_to;
            } else if (itemsB.time_from == "OPEN 24 HOURS") {
              allServiceList[index].twentyFoursHours = itemsB.time_from;
            }
          }
        });
      setServiceData([...allServiceList]);
    });
  }, [allServiceList]);
  //show total user

  useEffect(() => {
    if (searchList.length > 0 && total != undefined) {
      setDataLength(+total + searchList.length);
    } else if (allServiceList?.length > 0 && total != undefined && +total > 0) {
      setDataLength(+total);
    } else if (searchList.length > 0 && +total == 0) {
      setDataLength(searchList.length);
    } else {
      setDataLength(0);
    }
  }, [searchList, allServiceList, dataLength, total]);

  useEffect(() => {
    if (serviceDataFromRedux.length > 0) {
      setAllServiceList(serviceDataFromRedux);
    }
  }, [serviceDataFromRedux]);
  useEffect(() => {
    if (total == 0) {
      setServiceData([]);
    }
  }, [allServiceList, total]);

  function getData() {
    const data = new FormData();
    data.append("search", reduxSearchName);
    data.append("page", current_page + 1);
    if (list?.length > 0) {
      dispatch(searchServiceApi(data)).then((res) => {
        const {
          response: {
            data: { list, current_page },
          },
        } = res;
        let responsedata = { res: list, page: current_page };
        dispatch(serviceList(responsedata));
      });
    }
  }
  useEffect(() => {
    setisHaveData(isloading?.ishaveData);
  }, [isloading]);
  const fetchMoreData = () => {
    getData();
  };


  // open request modal //

const handleOpenRequestModal = (e, value,details,) => {

  if (value === "edit") {
    setEditAppointment(true);
    setRequestData({
      ...requestData,
      iscancel: false,
      request_nature: "",
      description: "",
    });
  } else if (value === "no-edit") {
    setEditAppointment(false);
    setUserDetails(details)
    setRequestData({
      date: new Date(),
      request_nature: "",
      description: "",
      reschedule: false,
      iscancel: false,
      title: "",
    });
  }

  // requestModalRef.current.click();
};
  return (
    <section className="search-users bg-primary spacer search-result tab2">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto full-md">
            <h1 className="text-black  text-capitalize mb-0 h5  mb-3">
              {serviceData?.length > 0 || searchList?.length > 0 ? isSevicesPage ? dataLength : total !== undefined ? total : 0
                : 0}{" "}
              Search result found
            </h1>
            {isSevicesPage && serviceData?.length > 0 && (
              <h4 className="mb-3">Services</h4>
            )}
            <InfiniteScroll
              dataLength={serviceData.length}
              next={isSevicesPage === undefined && fetchMoreData}
              hasMore={total != serviceData?.length}
              loader={
                serviceData.length > 14 &&
                isSevicesPage === undefined && <SearchMiniLoading />
              }
            >
              {serviceData?.length > 0 &&
                serviceData.slice(0, showOnlyThreeServie).map((items) => {
                  return (
                    <div
                      className="search-users__wrapper d-flex flex-wrap align-items-start  bg-white p-4 br-20 position-relative mb-3 pane"
                      key={items?.user_id}
                    >
                      <div className="search-users__img d-flex align-items-center">
                        {items?.acc_type == 1 ? (
                          <div className="search-users__img d-flex align-items-center">
                            <img
                              onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/member-default.svg")
                              }
                              src={
                                !!items
                                  ? IMAGE_BASE_URL + items?.profile_image
                                  : "/assets/images/member-default.svg"
                              }
                            />
                          </div>
                        ) : (
                          <div className="search-users__img d-flex align-items-center business-user">
                            <img
                              onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/business_logo.svg")
                              }
                              src={
                                !!items
                                  ? IMAGE_BASE_URL + items?.business_image
                                  : "/assets/images/business_logo.svg"
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="search-users__detail user-detail pr-0">
                        <Link
                          to={`/search-profile/${items?.user_id}`}

                            // to={`/search-profile/${items?.user_id}?individual=${!!items?.acc_type == 1 ? true : false
                            // }`}
                          className="search_achor"
                          onClick={() => {
                            localStorage.setItem("ClickOnSeachProfile", false);
                          }}
                        >
                          <h5 className="text-capitalize mb-0 search_header text-break pr-5">
                            {items?.acc_type == 1
                              ? items?.name
                              : items?.business_name.slice(0, 23)}
                          </h5>
                          <p className="small font-italic mb-2">
                            {items?.work_name}
                          </p>
                          <span className="service-price h5">{`$${items?.hourly_rate}`}</span>
                          <div className="d-flex w-100 small align-items-center">
                            {items?.area !== "" ? (
                              <span className="mr-3">
                                <i className="ri-map-pin-2-fill text-secondary align-middle"></i>
                                {items?.area}
                              </span>
                            ) : null}
                            {items?.availability?.length > 0 ? (
                              <span>
                                <span
                                  className={`${items.isOpen === "Open" ||
                                    !!items.twentyFoursHours
                                    ? "text-success"
                                    : "text-secondary"
                                    } `}
                                >
                                  {!!items.twentyFoursHours
                                    ? items.twentyFoursHours
                                      .charAt(0)
                                      .toUpperCase() +
                                    items.twentyFoursHours
                                      .slice(1)
                                      .toLowerCase()
                                    : items.isOpen}
                                </span>
                                {items.EndingTime || items.startingTime
                                  ? "-"
                                  : null}
                                {items.EndingTime || items.startingTime
                                  ? items.isOpen == "Open"
                                    ? `Closes ${items.EndingTime} `
                                    : `Open ${items.startingTime}`
                                  : null}
                              </span>
                            ) : (
                              <span className={"text-secondary"}>Closed</span>
                            )}
                          </div>
                          <div className="mt-3">
                            <p>{items?.business_intro}</p>
                          </div>
                        </Link>

                        <div className="contact-service contact-service-blk">
                          <div className="network__action d-flex align-items-center justify-content-end call-info border-0">
                           
                           
                          <a
                           ref={requestModalRef}
                           href="javascript:;"
                           onClick={(e) =>
                             handleOpenRequestModal(e, "no-edit",items)
                           }
                           data-toggle="modal"
                           data-placement="top"
                           title="Request"
                           data-target="#request-modal"
                            className="link-color network-options btn-request"
                          >
                            <i className="ri-calendar-2-line align-middle text-white" alt="request" />
                          </a>
                           
                            {!items.phone_visibility && (
                              <a
                                href={`tel:${items?.country_code + items?.phone
                                  }`}
                                className="network-options btn-blue call-info"
                                title=" Phone Number"
                              >
                                <i className="ri-phone-fill text-white"></i>
                              </a>
                            )}
                            <a
                              href="javascript:;"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="PoolsChat"
                              className="link-color network__action__icon chat-icon network-options"
                              onClick={() =>
                                handleChat(items.user_id, items.friend_check)
                              }
                            >
                              <img src="/assets/images/chat.png" alt="chat" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </InfiniteScroll>
            {isSevicesPage &&
              showOnlyThreeServie == 3 &&
              allServiceList?.length > 0 &&
              serviceData?.length > 3 && (
                <div className="more-service-cta">
                  <button
                    className="btn btn-primary mx-auto d-block service-btn"
                    onClick={() =>
                      setShowOnlyThreeService(allServiceList?.length)
                    }
                  >
                    <FaAngleDoubleDown /> {allServiceList.length - 3} More
                    Search Result Found
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
      <RequestModal
        editAppointent={editAppointent}
        setEditAppointment={setEditAppointment}
        messageDetails={userDetails}
        // addToast={addToast}
        handleOpenRequestModal={handleOpenRequestModal}
        // dispatch={dispatch}
        requestData={requestData}
        setRequestData={setRequestData}
     
      />
    </section>
  );
};

export default ServicesSearchList;
