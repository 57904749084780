import { GET_SHARE_USER_PROFILE } from "../../library/urls";
import { GET_API, POST_API } from "../../main/fetch";
import {
  SHARE_USER_PROFILE_FAILURE,
  SHARE_USER_PROFILE_REQUEST,
  SHARE_USER_PROFILE_SUCCESS,
} from "./ShareProfileActionConstant";

const getShareUserProfile = (id) => {

  return {
    
    [POST_API]: {
      endpoint: `${GET_SHARE_USER_PROFILE}?user_id=${id}`,
      types: [
        SHARE_USER_PROFILE_REQUEST,
        SHARE_USER_PROFILE_SUCCESS,
        SHARE_USER_PROFILE_FAILURE,
      ],
    },
  };
};

export { getShareUserProfile };
