import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCookie, setCookie } from "../../library/utilities/functions";
import { changeChatDetails } from "../PoolsChat/PoolsChatAction";
import { useDispatch } from "react-redux";
import { individualFriendListApi } from "../../library/api/MyNetworkApiService";
import Scrollbars from "react-custom-scrollbars";
import { clearUnfriendResponse } from "../SearchProfile/SearchProfileAction";
import { changeFriendListDetail } from "./MyNetworkAction";
import { useToasts } from "react-toast-notifications";
import {
  sendRequestDataApi,
  unfriendUserApi,
} from "../../library/api/SearchApiService";
import {
  API_BASE_URL,
  GOOGLE_MEET_INTEGRATION,
  IMAGE_BASE_URL,
  SOCKET,
} from "../../library/urls";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchMiniLoading from "../SearchList/Loading/SearchMiniLoading";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { SiGooglemeet } from "react-icons/si";
import RequestModal from "../PoolsChat/RequestModal";

const IndividualNetwork = ({ loading, name, override }) => {
  // refs//
  const prevCountRef = useRef("");
  const requestModalRef = useRef("");

  // hooks//
  const history = useHistory();
  const { addToast } = useToasts();
  // browswer storage//
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  // states//
  const [filterFriendsArray, setFilterFriendArray] = useState([]);
  const [memberListData, setMemberListData] = useState([]);
  const [popLoading, setPopLoading] = useState(false);
  const [oddEven, setOddEven] = useState(1);
  const [showUnLinkFriend, setShowUnLinkFriend] = useState(null);

  const [editAppointent, setEditAppointment] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [requestData, setRequestData] = useState({
    date: new Date(),
    request_nature: "",
    description: "",
    reschedule: false,
    iscancel: false,
    title: "",
    is_accepted: false,
    is_declined: false,
  });

  // redux//
  const dispatch = useDispatch();
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const searchState = useSelector((state) => state.SearchProfileReducer);
  const searchListState = useSelector((state) => state.SearchListReducer);
  const {
    sendRequestApi: { sendRequestStatus, sendRequestMessage },
  } = searchListState;
  const {
    unfriendApi: { unfriendStatus },
  } = searchState;

  const {
    individualFriendListDetail: { individualFriendList },
    individualfriendListApi: {
      individualFriendListDetails,
      individualTotalFriend,
    },
  } = networkState;
  const {
    friendListDetail: { friendList },
  } = networkState;

  useEffect(() => {
    if (!!unfriendStatus) {
      dispatch(clearUnfriendResponse());
    }
  }, [unfriendStatus]);

  //this function is used to unfriend the friend
  const handleUnFriend = (item, index) => {
    const bodyParameter = {
      id: item.user_id,
    };
    dispatch(unfriendUserApi(bodyParameter));
    for (let i in filterFriendsArray) {
      if (i == index) {
        filterFriendsArray.splice(i, 1);
        dispatch(changeFriendListDetail({ friendList: filterFriendsArray }));
      }
    }
  };

  //this function is used to redirect the chat page when clicking on the chat icon and open that user message box
  const handleChat = (data) => {
    setCookie("friendId", data.user_id, 1);
    setCookie("chatType", "user");
    history.push("/chat");
    dispatch(changeChatDetails({ currenUserId: data.user_id }));
  };

  //this function is used to open particular user details.
  const openProfileBox = (item) => {
    if (profileData.id == item.user_id) {
      history.push(`/account/${profileData?.user_slug}`);
    } else {
      history.push(
        `/search-profile/${item.user_id}`

        // `/search-profile/${item.user_id}?individual=${
        //   !!item.is_individual ? true : false
        // }`
      );
    }
  };

  // open request modal //

  const handleOpenRequestModal = (e, value, details) => {
    if (value === "edit") {
      setEditAppointment(true);
      setRequestData({
        ...requestData,
        iscancel: false,
        request_nature: "",
        description: "",
        is_accepted: false,
        is_declined: false,
      });
    } else if (value === "no-edit") {
      setEditAppointment(false);
      setUserDetails(details);
      setRequestData({
        date: new Date(),
        request_nature: "",
        description: "",
        reschedule: false,
        iscancel: false,
        title: "",
        is_accepted: false,
        is_declined: false,
      });
    }

    // requestModalRef.current.click();
  };

  // send request
  const handleSendRequests = (item, index) => {
    for (let i in filterFriendsArray) {
      if (i == index) {
        filterFriendsArray[i].request_sent = 1;
        dispatch(changeFriendListDetail({ friendList: filterFriendsArray }));
      }
    }
    const bodyParameter = {
      id: item.user_id,
    };
    dispatch(sendRequestDataApi(bodyParameter));
  };

  // load more//
  const fetchMoreData = () => {
    if (window.location.pathname.match("/network")) {
      const data = new FormData();
      data.append("user_id", profileData.id);
      data.append("page", individualFriendListDetails + 1);
      dispatch(individualFriendListApi(data));
    }
  };

  useEffect(() => {
    const NewFriendList = Object.values(
      friendList.reduce(
        (acc, cur) => Object.assign(acc, { [cur.user_id]: cur }),
        {}
      )
    );

    let SearchOnlyIndividualData = NewFriendList.filter(
      (x) => x.is_individual == 1
    );

    if (name?.length > 0) {
      if (SearchOnlyIndividualData.length > 0) {
        setFilterFriendArray(SearchOnlyIndividualData);
      } else {
        setFilterFriendArray([]);
      }
    }
  }, [individualFriendList, friendList]);

  useEffect(() => {
    const NewFriendList = Object.values(
      individualFriendList.reduce(
        (acc, cur) => Object.assign(acc, { [cur.user_id]: cur }),
        {}
      )
    );

    setFilterFriendArray(NewFriendList);
  }, [individualFriendList]);

  useEffect(() => {
    if (!!sendRequestStatus) {
      addToast(sendRequestMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      dispatch(clearSendRequestResponse());
    }
  }, [sendRequestStatus]);

  const showCartHandler = (i, id) => {
    if (id != prevCountRef.current) {
      setShowUnLinkFriend(null);
      setOddEven(1);
    }
  };

  var frdMesageStatus = {
    friend_id: "",
    online: { read: false, unread: true, reciever_id: "" },
    offline: false,
  };
  // --- to send google meet request --- //
  const handleStartMeeting = async (details) => {
    if (profileData?.refresh_token == null || profileData?.refresh_token=="") {
      try {
        let config = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getCookie("token_id"),
          },
        };
        await axios(
          `${GOOGLE_MEET_INTEGRATION}?user_id=${profileData?.id}`,
          config
        )
          .then((response) => {
            if (response?.data?.data)
              window.open(response?.data?.data.auth_url, "_blank");
          })
          .catch((err) => {
            addToast(err, {
              appearance: "error",
              autoDismiss: true,
            });
          });
      } catch (error) {
        addToast(error, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      var message = {
        session_id: getCookie("token_id"),
        message: "",
        sender_name: !!profileData.is_individual
          ? profileData.name
          : profileData.business_name,
        device_token: null,
        created_at: new Date(),
        messageStatus: frdMesageStatus,
        frds_acknowledged: 1,
        message_id: uuidv4(),
        sender_id: Number(profileData.id),
        ignore_user_from_id: 1,
        ignore_user_to_id: 1,
        type: 1,
        is_individual: profileData.is_individual,
        is_business: profileData.is_business,
        refresh_token: profileData.refresh_token,
      };

      message.reciever_id = details?.user_id;

      message.group_id = null;
      SOCKET.emit("google_meet", message);
    }
SOCKET.off("google_auth_error").on(
      "google_auth_error",
      async (response) => {
        if (response) {
           try {
        let config = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getCookie("token_id"),
          },
        };
        await axios(
          `${GOOGLE_MEET_INTEGRATION}?user_id=${profileData?.id}`,
          config
        )
          .then((response) => {
            if (response?.data?.data)
              window.open(response?.data?.data.auth_url, "_blank");
          })
          .catch((err) => {
            addToast(err, {
              appearance: "error",
              autoDismiss: true,
            });
          });
      } catch (error) {
        addToast(error, {
          appearance: "error",
          autoDismiss: true,
        });
      }
        }
      }
    );
  };


  // add memeber function//
  const handleMemberPopList = (id) => {
    setPopLoading(true);
    let config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
    };

    axios(API_BASE_URL + "/members/added-by?user_id=" + id, config).then(
      (response) => {
        const ListData = response.data.data;
        setMemberListData(ListData);
        setPopLoading(false);
      }
    );
  };

  // unlink frient function//
  const handleUnLinkFriend = (item) => {
    if (oddEven % 2 !== 0) {
      setShowUnLinkFriend(item.user_id);
    } else {
      setShowUnLinkFriend(null);
    }
    setOddEven(oddEven + 1);
  };

  useEffect(() => {
    prevCountRef.current = showUnLinkFriend;
  }, [showUnLinkFriend]);
  return loading ? (
    <ClipLoader color={"#2f2529"} loading={loading} size={80} css={override} />
  ) : (
    <div>
      <div className="row mt-4">
        <div className="col-md-12 d-flex align-items-center justify-content-between">
          <h1 className="h2">Individual Network</h1>
        </div>
      </div>
      <InfiniteScroll
        style={{ overflow: "visible" }}
        dataLength={filterFriendsArray.length}
        next={filterFriendsArray.length > 14 && fetchMoreData}
        hasMore={filterFriendsArray.length != individualTotalFriend}
        loader={filterFriendsArray.length > 14 && <SearchMiniLoading />}
      >
        <div className="row mt-5 scale-effect">
          {!!filterFriendsArray.length &&
            filterFriendsArray.map((item, index) => (
              <div
                className="col-md-4 col-lg-3 col-sm-6 col-50"
                key={"friend-" + index}
                onMouseEnter={() => showCartHandler(index, item.user_id)}
              >
                <div className="network__box bg-white pt-4 br-20 text-center position-relative">
                  {!!item.is_individual ? (
                    <div
                      className="network__box__img normal-user mx-auto network__box--lgthumb"
                      onClick={() => openProfileBox(item)}
                    >
                      <a href="javascript:;">
                        {" "}
                        <img
                          onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                          }
                          src={
                            !!item.user_profile
                              ? IMAGE_BASE_URL + item.user_profile
                              : "/assets/images/member-default.svg"
                          }
                        />
                      </a>
                    </div>
                  ) : (
                    <div
                      className="network__box__img business-user mx-auto"
                      onClick={() => openProfileBox(item)}
                    >
                      <a href="javascript:;">
                        <img
                          onError={(ev) =>
                            (ev.target.src = "/assets/images/business_logo.svg")
                          }
                          src={
                            !!item.user_profile
                              ? IMAGE_BASE_URL + item.user_profile
                              : "/assets/images/business_logo.svg"
                          }
                          alt="user"
                        />
                      </a>
                    </div>
                  )}
                  <div className="network__box__info mt-2 px-2">
                    <a
                      onClick={() => openProfileBox(item)}
                      href="javascript:;"
                      className="link-color text-capitalize"
                    >
                      <h5 className="text-capitalize h6 mb-0">
                        {item.user_name?.length > 19
                          ? `${item.user_name.slice(0, 19)}...`
                          : item.user_name}
                      </h5>
                    </a>
                    <div
                      className={`mb-0 text-capitalize  small network-tooltip position-relative pb-2 ${
                        !!item.work && "cursor-pointer"
                      }`}
                    >
                      <span className="text-fade">
                        {item.work?.length > 20
                          ? `${item.work?.slice(0, 20)}...`
                          : item.work}
                      </span>

                      {item?.event_request_nature !== "" && (
                        <div>
                          <p className="req-brief d-inline-block small mb-0 d-flex align-item-center mb-0 justify-content-center text-primary">
                            <i class="ri-calendar-2-line pr-1 "></i>
                            {`${
                              item?.request === "receive"
                                ? item?.user_name
                                : "You"
                            } requested for ${item?.event_request_nature}`}
                          </p>
                        </div>
                      )}
                      {item.work?.length > 20 ? (
                        <div className="view-modal">{item.work}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  className={`card-hover ${
                    showUnLinkFriend == item.user_id && "card-zindex"
                  }`}
                >
                  {!!item?.is_individual && item.first_member_image ? (
                    <div className="d-flex align-items-start justify-content-center">
                      {!!item.first_member_name && (
                        <div className="more-connection">
                          <img
                            style={{
                              borderRadius: "50%",
                              height: "20px",
                              width: "20px",
                            }}
                            src={
                              !!item.first_member_image
                                ? IMAGE_BASE_URL + item.first_member_image
                                : null
                            }
                          />
                          {item.more_than_one && (
                            <span
                              className="network_card_btn"
                              href="javascript:;"
                              data-toggle="modal"
                              data-target="#languageModal"
                              data-backdrop="static"
                              data-keyboard="false"
                              onClick={() => handleMemberPopList(item.user_id)}
                            >
                              1+
                            </span>
                          )}
                        </div>
                      )}
                      <p className="px-2 mb-0">
                        {item.first_member_name.length > 16
                          ? `${item.first_member_name.slice(0, 16)}...`
                          : item.first_member_name}
                      </p>
                    </div>
                  ) : null}
                  <div className="network__action d-flex align-items-center justify-content-end mt-3 call-info">
                    {profileData.id != item.user_id &&
                      (!!item.friend_check ? (
                        <>
                          <a
                            ref={requestModalRef}
                            href="javascript:;"
                            onClick={(e) =>
                              handleOpenRequestModal(e, "no-edit", item)
                            }
                            data-toggle="modal"
                            data-placement="top"
                            title="Request"
                            data-target="#request-modal"
                            className="link-color network-options btn-request"
                          >
                            <i
                              className="ri-calendar-2-line  text-white"
                              alt="request"
                            />
                          </a>
                          {!item.phone_visibility && (
                            <span className="network-options btn-blue call-info">
                              <i className="ri-phone-fill text-white"></i>
                              <ul>
                                <li className="my-3">
                                  <div className="call-info-modal">
                                    <span className="d-block border-0">
                                      <a
                                        className="link-color"
                                        href={`tel:${
                                          item.country_code + item.phone
                                        }`}
                                      >
                                        <i className="ri-phone-fill align-middle text-primary"></i>{" "}
                                        {item.country_code + item.phone}
                                      </a>
                                    </span>
                                  </div>
                                </li>

                                <li>
                                  <div className="call-info-modal">
                                    <span
                                      className="d-block border-0"
                                      onClick={() => handleStartMeeting(item)}
                                    >
                                      <a className="link-color">
                                        <img src="/assets/images/google-meet.svg" />{" "}
                                        Google Meet
                                      </a>
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </span>
                          )}
                          <a
                            href="javascript:;"
                            onClick={() => handleChat(item)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="PoolsChat"
                            className="link-color network__action__icon chat-icon network-options"
                          >
                            <img src="/assets/images/chat.png" alt="chat" />
                          </a>
                        </>
                      ) : !item.request_sent ? (
                        <a
                          href="javascript:;"
                          onClick={() => handleSendRequests(item, index)}
                          className="btn btn-blue btn-sm network-linked"
                        >
                          <i className="ri-user-follow-line" /> Link Up
                        </a>
                      ) : (
                        <a
                          className="btn bg-success btn-sm network-linked"
                          href="javascript:;"
                          style={{ cursor: "default" }}
                        >
                          <i className="ri-user-follow-line" /> Request Sent
                        </a>
                      ))}
                    <div className="profile-action mt-0 w-auto">
                      <div onClick={() => handleUnLinkFriend(item)}>
                        <i className="ri-more-2-fill network__action__icon" />
                      </div>
                      <ul
                        className={`important-list-drop ${
                          showUnLinkFriend == item.user_id &&
                          "profile-action-active"
                        }`}
                      >
                        {!!item.friend_check &&
                          profileData.id != item.user_id && (
                            <li>
                              <a
                                href="javascript:;"
                                onClick={() => handleUnFriend(item, index)}
                              >
                                <i className="ri-user-unfollow-fill"></i>{" "}
                                Un-link
                              </a>
                            </li>
                          )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {!filterFriendsArray.length && (
            <div
              className="col-md-12 d-flex align-items-center justify-content-center"
              style={{ height: "40vh" }}
            >
              {individualFriendList.length < 1 && name.length == 0 && (
                <p className="font-weight-bold h4 ">No Networks found</p>
              )}
              {filterFriendsArray.length < 1 && name.length > 0 && (
                <p className="font-weight-bold h4 ">No Networks found</p>
              )}
            </div>
          )}
        </div>
      </InfiniteScroll>
      <div
        className="modal fade"
        id="languageModal"
        tabIndex="-1"
        aria-labelledby="languageModalModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon"
                />
                All Members
              </h5>
              <button
                type="button"
                onClick={() => setMemberListData([])}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <ClipLoader
                color={"#000"}
                loading={popLoading}
                css={{
                  position: "absolute",
                  top: "35%",
                  left: "45%",
                  width: "100px",
                  height: "100px",
                  zIndex: "9999",
                  transform: "translate(-50%, -50%)",
                }}
              />
              <Scrollbars autoHide style={{ height: 400 }}>
                <div className="p-2">
                  {memberListData &&
                    memberListData.map((items, i) => {
                      return (
                        <div
                          className="network_pop_list add-member-block"
                          key={i}
                        >
                          <img
                            onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/business_logo.svg")
                            }
                            src={
                              !!items.business_image
                                ? IMAGE_BASE_URL + items.business_image
                                : "/assets/images/business_logo.svg"
                            }
                            alt="user"
                          />
                          <p className="ml-3 mb-0">{items.business_name}</p>
                        </div>
                      );
                    })}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
      <RequestModal
        editAppointent={editAppointent}
        setEditAppointment={setEditAppointment}
        messageDetails={userDetails}
        // addToast={addToast}
        handleOpenRequestModal={handleOpenRequestModal}
        // dispatch={dispatch}
        requestData={requestData}
        setRequestData={setRequestData}
      />
    </div>
  );
};
export default IndividualNetwork;
