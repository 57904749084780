import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { getFooterContentApi } from "../landingContentAction";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Footer() {
  const dispatch = useDispatch();
  const footerContent = useSelector(
    (state) => state.LandingPageReducer?.landingPageFooter);

  const { getLandingFooterData } = footerContent;
  useEffect(() => {
    dispatch(getFooterContentApi());
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 900,
    });
    AOS.refresh();
  }, [])
  return (
    <div className="landing-wrapper">
      {window.location.pathname !== "/login" && window.location.pathname !== "/signup" && window.location.pathname !== "/forget-password" && window.location.pathname !== "/change-password" &&
        <section className="call-action spacer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="call-action__inner spacer br-20">
                  <div className="call-action__content">
                    <div className="row">
                      <div className="col-lg-8 col-md-12">
                        <img
                          src="/assets/images/web-icon.svg"
                          alt="icon"
                          className="mb-5"
                        />
                        <h2 className="heading-h2 font-typo-medium mb-0">
                          {!!getLandingFooterData?.data &&
                            getLandingFooterData?.data?.above_footer_title}
                        </h2>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-8 col-md-12">
                        <p className="mb-0">
                          {!!getLandingFooterData?.data &&
                            parse(
                              getLandingFooterData &&
                              getLandingFooterData?.data
                                .above_footer_description
                            )}
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-12 d-flex mt-4 mt-lg-0 justify-content-start justify-content-lg-end">
                        <Link to="/signup" className="btn btn-primary">
                          Get Started
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <footer className="spacer pb-3 theme-primary-bg text-white section-bright">
        <div className="container">
          <div className="row justify-content-between align-items-end mb-4">
            <div className="col-md-5 pr-5">
              <a href="">
                <img src="/assets/images/pools-white.svg" alt="logo" />
              </a>
              <p className="mt-3 mb-3 mb-md-0">
                {" "}
                {!!getLandingFooterData?.data &&
                  parse(
                    !!getLandingFooterData &&
                    getLandingFooterData?.data?.footer_text
                  )}
              </p>
            </div>
            <div className="col-md-5 w-auto footer-items">
              <a href="https://www.bbb.org/us/wy/laramie/profile/business-services/poolsmagnic-0805-46135955/#sealclick" target="_blank" rel="nofollow">
                <img src="/assets/images/business.svg" alt="Business" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer__seprator mt-2"></div>
        <div className="container footer__bottom">
          <div className="row justify-content-between pt-3 align-items-center">
            <div className="col-md-6">
              <span>&copy; 2024 All rights reserved.</span>
            </div>

            <div className="col-md-6 w-auto  footer-items">
              <Link to="/term-service" className="mr-4">
                Terms &amp; Conditions
              </Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
