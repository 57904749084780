import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getShareUserProfile } from "./ShareProfileAction";
import { IMAGE_BASE_URL } from "../../library/urls";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  EmploymentDetail,
  GeneralDetail,
  ProjectDetail,
  SocialLink,
} from "../SearchProfile/common";
import { ClipLoader } from "react-spinners";

export default function ShareProfile() {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileData = useSelector((state) => state.ShareUserProfileReducer);
  const {
    getShareProfileData: { getShareProfileResponse },
  } = userProfileData;
  const override = {
    width: "40px",
    height: "40px",
    position: "absolute",
    top: "60%",
    right: "47%",
    // borderColor:"black",
  };
  const handleNavigate = () => {
    history.push("/login");
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getShareUserProfile(userid));
  }, [userid]);

  if (Object.keys(getShareProfileResponse)?.length === 0) {
    return (
      <center>
        <ClipLoader color={"black"} css={override} />
      </center>
    );
  }

  return (
    <div>
      <section
        className="profile-header spacer bg-primary"
        style={{
          backgroundImage: `url("${
            !!getShareProfileResponse?.profile_details?.cover_image
              ? IMAGE_BASE_URL +
                getShareProfileResponse?.profile_details?.cover_image
              : "/assets/images/pools_cover_image.png"
          }")`,
        }}
      >
        <div className="container profile-area">
          <div className="row">
            <div className="col-md-12">
              {/* BEGIN profile-header-content */}
              <div className="profile-header-content">
                {/* BEGIN profile-header-img */}
                <div className="profile-header-content__inner  d-flex align-items-center">
                  {!!getShareProfileResponse?.profile_details?.is_individual ==
                  0 ? (
                    <>
                      <div className="business-logo">
                        <img
                          onError={(ev) =>
                            (ev.target.src = "/assets/images/business_logo.svg")
                          }
                          src={
                            !!getShareProfileResponse &&
                            !!getShareProfileResponse?.profile_details
                              ?.business_image
                              ? IMAGE_BASE_URL +
                                getShareProfileResponse?.profile_details
                                  ?.business_image
                              : "/assets/images/business_logo.svg"
                          }
                          alt="logo"
                        />
                      </div>

                      <div className="profile-header-img">
                        <img
                          onError={(e) =>
                            (e.target.scr = "/assets/images/member-default.svg")
                          }
                          src={
                            !!getShareProfileResponse &&
                            !!getShareProfileResponse?.profile_details
                              ?.profile_image
                              ? IMAGE_BASE_URL +
                                getShareProfileResponse?.profile_details
                                  ?.profile_image
                              : "/assets/images/member-default.svg"
                          }
                          alt="profile-image"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="profile-header-img">
                        <img
                          onError={(e) =>
                            (e.target.scr = "/assets/images/member-default.svg")
                          }
                          src={
                            !!getShareProfileResponse &&
                            !!getShareProfileResponse?.profile_details
                              ?.profile_image
                              ? IMAGE_BASE_URL +
                                getShareProfileResponse?.profile_details
                                  ?.profile_image
                              : "/assets/images/member-default.svg"
                          }
                          alt="profile-image"
                        />
                      </div>
                    </>
                  )}

                  {/* ---- FOR INDIVIDUAL ---- */}
                  {getShareProfileResponse?.profile_details?.is_individual ==
                  true ? (
                    <div className="profile-header-info ">
                      <div
                        className="name-status d-inline position-relative d-flex align-items-center individua-status"
                        style={{ cursor: "no-drop", width: "max-content" }}
                      >
                        <h3>
                          {!!getShareProfileResponse &&
                            getShareProfileResponse?.profile_details?.name
                              ?.charAt(0)
                              .toUpperCase() +
                              getShareProfileResponse?.profile_details?.name
                                ?.slice(1)
                                .substr(0, 22)}{" "}
                        </h3>
                      </div>
                      <div className="mb-0">
                        {!!getShareProfileResponse &&
                          getShareProfileResponse?.profile_details?.work}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`profile-header-info business-profile ${
                        getShareProfileResponse?.profile_details
                          ?.profile_visibility == 1
                          ? "ml-5"
                          : null
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <h3 className="mr-1 mb-0">
                          {!!getShareProfileResponse &&
                            getShareProfileResponse?.profile_details?.business_name
                              ?.charAt(0)
                              .toUpperCase() +
                              getShareProfileResponse?.profile_details?.business_name
                                ?.slice(1)
                                .substr(0, 22)}{" "}
                        </h3>
                      </div>
                      <div className="mb-0">
                        {getShareProfileResponse?.profile_details
                          ?.profile_visibility == 0 &&
                          getShareProfileResponse?.profile_details
                            ?.is_individual == 0 && (
                            <span className="font-weight-bold text-capitalize">
                              {!!getShareProfileResponse
                                ? getShareProfileResponse?.profile_details?.name
                                : ""}
                            </span>
                          )}

                        <span className="font-italic">
                          {!!getShareProfileResponse?.profile_details
                            ?.specialization
                            ? getShareProfileResponse?.profile_details
                                ?.profile_visibility == 1
                              ? getShareProfileResponse?.profile_details
                                  ?.specialization
                              : `, ${" "} ${
                                  getShareProfileResponse?.profile_details
                                    ?.specialization
                                }`
                            : null}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="profile-action ml-auto call-info">
                    {!!getShareProfileResponse && (
                      <>
                        <a
                          onClick={handleNavigate}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Request"
                          className="link-color network-options btn-request"
                        >
                          <i
                            className="ri-calendar-2-line align-middle text-white"
                            alt="request"
                          />
                        </a>

                        {!getShareProfileResponse.phone_visibility && (
                          <a
                            onClick={handleNavigate}
                            className="network-options btn-blue"
                          >
                            <i className="ri-phone-fill text-white"></i>
                            <div className="call-info-modal">
                              <span className="d-block border-0">
                                <i className="ri-phone-fill align-middle text-primary"></i>{" "}
                                Phone Number
                              </span>
                            </div>
                          </a>
                        )}
                        <a
                          // href="javascript:;"
                          onClick={handleNavigate}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="PoolsChat"
                          className={`link-color network__action__icon network-options chat-icon`}
                        >
                          <img src="/assets/images/chat.png" alt="chat" />
                        </a>
                      </>
                    )}
                  </div>

                  {/* BEGIN profile-header-info */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="profile-tabs py-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 text-white">
              <h4 className="mb-0">
                Dive into PoolsMagnic and become a valuable member today!
                Network and link up with others, enrich your work environment,
                and collaborate better.
              </h4>
            </div>
            <div className="col-md-4 text-white">
              <div className="d-flex mt-2 justify-content-md-end login__popup-wrap">
                <Link className=" btn btn-white" to="/login">
                  <span>Login</span>
                </Link>

                <Link className=" btn btn-outline" to="/signup">
                  <span>Sign Up</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="profile-tab-content spacer bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                // className={`tab-pane fade  ${
                //   !profile_details?.is_supporter &&
                //   profile_details?.is_supporter != undefined
                //     ? "show active"
                //     : ""
                // }`}
                // id="pills-home"
                // role="tabpanel"
                // aria-labelledby="pills-home-tab"
                >
                  <GeneralDetail
                    profile_details={getShareProfileResponse.profile_details}
                    is_individual={
                      getShareProfileResponse?.profile_details?.is_individual
                    }
                  />
                  {getShareProfileResponse?.profile_details?.is_individual ==
                  1 ? (
                    <EmploymentDetail
                      employment_details={
                        getShareProfileResponse.employment_details
                      }
                    />
                  ) : (
                    <ProjectDetail
                      project_details={getShareProfileResponse.project_details}
                    />
                  )}
                  <SocialLink
                    social_details={getShareProfileResponse.social_details}
                  />
                </div>
                <div
                  className={`tab-pane fade  ${
                    getShareProfileResponse?.profile_details?.is_supporter
                      ? "show active"
                      : ""
                  }`}
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {/* <ProfilePosts
                    addToast={addToast}
                    userId={userId}
                    profile_details={profile_details}
                  /> */}
                </div>
                {/* <div
                  className="tab-pane fade"
                  id="pills-contacts"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <ProfileGallery getPhotos={photoList} />
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-members"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <ProfileMembers
                    addedMembers={addedMembers}
                    userId={userId}
                    memberData={addedMembers}
                  />
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-services"
                  role="tabpanel"
                  aria-labelledby="pills-services-tab"
                >
                  <ServicesTab
                    searchProfileData={profile_details}
                    pathname={"/" + loc.pathname.split("/")[1]}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
