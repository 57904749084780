import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import ShareGalleryModel from "./ShareGalleryModel";
import { Modal, ModalBody, Dropdown } from "react-bootstrap";
import { IMAGE_BASE_URL } from "../../../urls";
import {
  changeGalleryDetail,
  clearGalleryDeleteResponse,
  clearGalleryPhotoResponse,
  clearGalleryVideoResponse,
} from "./ProfileGalleryAction";
import { useLocation } from "react-router";
import OpenLargeFile from "./OpenLargeFile";
import {
  deleteGalleryFilesApi,
  getGalleryPhotoApi,
  getGalleryVideoApi,
} from "../../../api/ProfilePostApiService";
import { getCookie, useForceUpdate } from "../../../utilities/functions";
import SearchListMembers from "../../../../modules/SearchMembersList";
import { List, stepButtonClasses } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

let selectedCheck = [];

const ProfileGallery = () => {
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const forceUpdate = useForceUpdate();
  const [openConfirmModel, setConfirmModel] = useState(false);
  const [photoListData, setPhotoListData] = useState([]);
  const { pathname } = useLocation();
  const closeDeleteEl = useRef();
  const galleryOperationEl = useRef();
  const labelEl = useRef();
  const unselectEl = useRef();
  const history = useHistory()
  const GalleryState = useSelector((state) => state.ProfileGalleryReducer);
  const AccountState = useSelector((state) => state.AccountReducer);
  const homeDataaa = useSelector((state) => state.HomeReducer);
  const {
    galleryDetails: {
      photoList,
      videoList,
      photoPage,
      videoPage,
      totalVideo,
      totalPhoto,
      deleteSelectedItem,
      unSelectedState,
    },
  } = GalleryState;
  const {
    getGalleryPhotoApi: { getPhotoStatus },
  } = GalleryState;
  const {
    getGalleryVideoApi: { getVideoStatus },
  } = GalleryState;
  const {
    deleteFilesApi: { deleteFileStatus },
  } = GalleryState;
  const [videoListData, setVideoListData] = useState([]);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  if (history.action === "POP") {

    history.push(`/account/${profileData?.user_slug}`);
  }

  useEffect(() => {
    $(document).on("click", function (e) {
      var container = $(".profile-action");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(".profile-action ul").removeClass("profile-action-active");
      }
    });

    $(".profile-action .ri-more-2-fill").on("click", function (event) {
      event.stopPropagation();
      if (
        $(this)
          .parents()
          .siblings()
          .find("ul")
          .hasClass("profile-action-active")
      ) {
        $(".profile-action ul").removeClass("profile-action-active");
      }
      if ($(this).next("ul").hasClass("profile-action-active")) {
        $(this).next("ul").removeClass("profile-action-active");
      } else {
        $(this).next("ul").addClass("profile-action-active");
      }
    });

    return () => {
      dispatch(clearGalleryPhotoResponse());
      dispatch(clearGalleryVideoResponse());
    };
  }, []);

  useEffect(() => {
    $("#del-media").click(function () {
      $(".gallery__block__media label").css("opacity", 1);
    });
  }, [photoList?.length > 0]);

  useEffect(() => {
    if (!!deleteFileStatus) {
      dispatch(clearGalleryDeleteResponse());
    }
  }, [deleteFileStatus]);

  //this function is used to pagination on photos
  const handlePhotoPagination = () => {
    dispatch(changeGalleryDetail({ photoPage: photoPage + 1 }));
  };
  // this function is used to pagination on videos
  const handleVideoPagination = () => {
    dispatch(changeGalleryDetail({ videoPage: videoPage + 1 }));
  };

  const handleOpenGalleryModel = (e) => {
    galleryOperationEl.current.click();
  };

  //this function is used to open large video and image  in gallery section.

  const handleCarousalFile = (data, index) => {
    let carousalsDetail = {
      activeIndexFile: index,
      carousalDataFile: data,
    };
    dispatch(changeGalleryDetail(carousalsDetail));
  };

  //this function is used to select files like image and video then push into array.
  const handleCheck = (e, url) => {
    const target = e.target;
    var value = target.value;
    target.checked = target.checked;
    if (target.checked) {
      let selectedArray = selectedCheck;
      selectedArray.push(value);
    } else {
      let selectedArray = selectedCheck;
      var index = selectedArray.indexOf(value);
      selectedArray.splice(index, 1);
    }
    dispatch(changeGalleryDetail({ deleteSelectedItem: selectedCheck }));
  };

  // this function is used to delete the gallery videos and images

  const handleDeleteItems = () => {
    let pList = photoList,
      delItems = deleteSelectedItem;

    for (let i in videoList) {
      for (let j in delItems) {
        if (delItems[j] == videoList[i].id) {
          videoList.splice(i, 1);
        }
      }
    }

    dispatch(changeGalleryDetail({ photoList: pList }));
    const bodyParameter = {
      file_id: deleteSelectedItem.join(","),
    };
    dispatch(deleteGalleryFilesApi(bodyParameter))
      .then((response) => {

        if (response?.response?.data?.status === 200) {
          const bodyParameter = {
            page: photoPage,
            user_id: profileData.id,
            type: 0, //for image
          };
          dispatch(getGalleryPhotoApi(bodyParameter))
            // setPhotoListData(res.response.data.list);
            closeDeleteEl.current.click();
            handleUnSelect(0);
        } else {
          addToast("err", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleUnSelect = (id) => {
    var labels = document.querySelectorAll("#unCheck");

    labels.forEach(function (label) {
      label.style.opacity = 0;
    });
    const gallery = {
      deleteSelectedItem: [],
      unSelectedState: 0,
    };
    dispatch(changeGalleryDetail(gallery));
    if (!!id) {
      galleryOperationEl.current.click();
    }
    var textinputs = document.querySelectorAll(
      "input[class=files-gallery]:checked"
    );

    for (let i in textinputs) {
      if (!!textinputs[i].checked) {
        textinputs[i].checked = false;
      }
    }
    selectedCheck = [];
  };

  //
  const handleDeletePhotoVideo = () => {
    galleryOperationEl.current.click();
    dispatch(changeGalleryDetail({ unSelectedState: 1 }));
  };

  // remove duplicates obj of image from array //
  useEffect(() => {

    if (photoList?.length > 0) {
      const uniqueArr = photoList.reduce((accumulator, currentObject) => {
        // Check if an object with the same id already exists in the accumulator
        const existingObject = accumulator.find(
          (obj) => obj.id === currentObject.id
        );

        // If not found, add the currentObject to the accumulator
        if (!existingObject) {
          accumulator.push(currentObject);
        }

        return accumulator;
      }, []);

      setPhotoListData(uniqueArr);
    }
  }, [photoList]);

  // remove duplicates obj of video from video-array//
  useEffect(() => {
    if (videoList?.length > 0) {
      const uniqueArr = videoList.reduce((accumulator, currentObject) => {
        const existingObject = accumulator.find(
          (obj) => obj.id === currentObject.id
        );
        if (!existingObject) {
          accumulator.push(currentObject);
        }
        return accumulator;
      }, []);

      setVideoListData(uniqueArr);
    }
  }, [videoList]);

  //---fetching images in gallery ---//
  useEffect(() => {
    const bodyParameter = {
      page: photoPage,
      user_id: !!AccountState.getProfileApi.getProfileResponse
        ? AccountState.getProfileApi.getProfileResponse.id
        : "",
      type: 0,
    };

    dispatch(getGalleryPhotoApi(bodyParameter));
  }, []);

  //---fetching video list in gallery---/
  useEffect(() => {
    const body = {
      page: videoPage,
      user_id: !!AccountState.getProfileApi.getProfileResponse
        ? AccountState.getProfileApi.getProfileResponse.id
        : "",
      type: 1, //for video
    };
    dispatch(getGalleryVideoApi(body));
  }, [videoPage]);

  return (
    <>
      <section className="gallery">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="gallery__block bg-white p-3 br-20 custom-shadow">
                <div className="gallery__block__heading d-flex align-items-center mb-4">
                  <h1 className="h4 mb-0">Gallery</h1>
                  {pathname == `/account/${profileData?.user_slug}/gallery` && (
                    <div className="profile-action ml-auto mt-0">
                      <i
                        ref={galleryOperationEl}
                        className="ri-more-2-fill"
                      ></i>
                      <ul className="important-list-drop">
                        <li>
                          <a
                            data-toggle="modal"
                            onClick={(e) => handleOpenGalleryModel(e)}
                            data-target="#gallery-modal"
                            href="javascript:;"
                            className="btn_fix"
                          >
                            <i className="ri-add-circle-line"></i> Add
                            Photo/Video
                          </a>
                        </li>
                        {!unSelectedState && photoList?.length > 0 ? (
                          <li>
                            <a
                              onClick={() => handleDeletePhotoVideo()}
                              href="javascript:;"
                              id="del-media"
                              className="btn_fix"
                            >
                              <i className="ri-delete-bin-line"></i> Delete
                              Photo/Video
                            </a>
                          </li>
                        ) : photoList?.length > 0 ? (
                          <li>
                            <a
                              ref={unselectEl}
                              onClick={() => handleUnSelect(1)}
                              href="javascript:;"
                              id=""
                            >
                              <i className="ri-delete-bin-line"></i> Unselect
                              all
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="gallery__block__media">
                  <div className="picture-gallery">
                    <div className="gallery__block__media__heading d-flex justify-content-between mb-4">
                      <h5 className="mb-0">Pictures</h5>

                      {/* {photoList.length > 0 && photoPage * 10 < totalPhoto ? (
                        <p
                          onClick={() => handlePhotoPagination()}
                          className="mb-0 dark-txt"
                        >
                          View more
                        </p>
                      ) : (
                        ""
                      )} */}
                    </div>

                    <div className="picture-gallery__inner">
                      {photoListData &&
                        photoListData.map((item, index) => (
                          <div className="uploaded-pictures" key={index}>
                            <input
                              type="checkbox"
                              onClick={(e) => handleCheck(e, item.file_name)}
                              value={item.id}
                              className="files-gallery"
                              name="upload-picture"
                              id={`picture-${index}`}
                            />
                            <label
                              id="unCheck"
                              ref={labelEl}
                              htmlFor={`picture-${index}`}
                            ></label>

                            <img
                              onClick={() =>
                                handleCarousalFile(photoListData, index)
                              }
                              data-toggle="modal"
                              data-target="#large-gallery-modal"
                              className="br-20"
                              onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/image-placeholder.jpg")
                              }
                              src={
                                !!item
                                  ? IMAGE_BASE_URL + item.file_name
                                  : "/assets/images/image-placeholder.jpg"
                              }
                              alt="user"
                            />
                          </div>
                        ))}

                      {!photoListData.length && <p>No photos found</p>}
                    </div>
                    
                  </div>

                  <div className="video-gallery mt-4">
                    <div className="gallery__block__media__heading d-flex justify-content-between mb-4">
                      <h5 className="mb-0">Videos</h5>
                      {/* {videoListData.length > 0 && videoPage * 10 < totalVideo ? (
                        <p
                          onClick={() => handleVideoPagination()}
                          className="mb-0 dark-txt"
                        >
                          View more
                        </p>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <div className="video-gallery__inner">
                      {videoListData.map((item, i) => (
                        <div className="uploaded__videos" key={i}>
                          <video
                            onClick={() => handleCarousalFile(videoListData, i)}
                            src={
                              !!item
                                ? IMAGE_BASE_URL + item.file_name
                                : "/assets/images/image-placeholder.jpg"
                            }
                            data-toggle="modal"
                            data-target="#large-gallery-modal"
                            className="br-20"
                          />

                          <input
                            type="checkbox"
                            onClick={(e) => handleCheck(e, item.file_name)}
                            name="upload-video"
                            className="files-gallery"
                            value={item.id}
                            id={`video-${i}`}
                          />
                          <label
                            id="unCheck"
                            ref={labelEl}
                            htmlFor={`video-${i}`}
                          ></label>
                        </div>
                      ))}
                      {!!getVideoStatus && videoListData.length < 1 ? (
                        <p>No videos found</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!!deleteSelectedItem && deleteSelectedItem.length > 0 && (
        <a
          href="javascript:;"
          onClick={() => setConfirmModel(true)}
          className="btn btn-lg btn-secondary del-selected"
        >
          <i className="ri-delete-bin-line align-bottom"></i> Delete Selected
        </a>
      )}
      <ShareGalleryModel
        GalleryState={GalleryState}
        addToast={addToast}
        dispatch={dispatch}
      />
      <OpenLargeFile GalleryState={GalleryState} />

      <Modal
        show={openConfirmModel}
        onHide={() => setConfirmModel(false)}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-size"
      >
        <div className="modal-body text-center">
          <button
            ref={closeDeleteEl}
            onClick={() => setConfirmModel(false)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <p className="pt-4">
            Are you sure you want to delete the selected items ?
          </p>
          <div className="alert-btn mt-3 pb-4">
            <a
              href="javascript:;"
              onClick={() => handleDeleteItems()}
              className="btn btn-secondary btn-sm mr-3"
            >
              Yes
            </a>
            <a
              href="javascript:;"
              onClick={() => setConfirmModel(false)}
              className="btn btn-blue btn-sm"
            >
              No
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ProfileGallery;
