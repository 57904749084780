import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUpDataApi } from "../../library/api/signUpApiService";
import { Input } from "../../library/common/components";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  addValidation,
  replceMultiStringWithSIngle,
} from "../../library/utilities/functions";
import {
  changeIsFinalIndividualInput,
  changeLoginBusinessInput,
} from "./SignupActions";
import { signUpBusinessValidation } from "./validation";
import { useHistory } from "react-router";

const override = css`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 80%;
  right: 47%;
`;
const BusinesSignUp = ({ updateClearData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const passwordElValidation = useRef(null);
  const cofirmPasswordValidation = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const [error, setError] = useState(false);
  const [isClickSignUp, setIsClickSignUp] = useState(false);
  const SignupState = useSelector((state) => state.SignupReducer);
  const {
    signUpBussiness: { businessName, password, verifyPassword },
    is_clear_data,
  } = SignupState;
  const {
    signUpIndividual: { individualName, email, phoneNo, cntCode,first_name,last_name },
  } = SignupState;
  const {
    SignupApiResponse: { signupApiLoading },
  } = SignupState;
  const [validatedBusinessName, setValidatedBusinessName] = useState("");

  const handleChange = (e, data) => {
    // -----//

    const target = e.target;
    var regex = /^[a-zA-Z ]*$/;
    if (data === "businessName") {
      if (regex.test(e.target.value)) {
        dispatch(changeLoginBusinessInput({ [target.name]: target.value }));
      }
      const Valid = businessvalidation();
    }

    if (data === "password") {
      dispatch(changeLoginBusinessInput({ [target.name]: target.value }));
      if (!!password.length) {
        let validation = {
          is_valid_password: {
            status: false,
            validation: passwordElValidation,
          },
        };
        validation = signUpBusinessValidation(
          validation,
          SignupState.signUpBussiness,
          "password"
        );
        addValidation(validation);
      }
    } else if (data === "confirm") {
      dispatch(changeLoginBusinessInput({ [target.name]: target.value }));
      let validation = {
        is_valid_confirmPassword: {
          status: false,
          validation: cofirmPasswordValidation,
        },
      };
      validation = signUpBusinessValidation(
        validation,
        SignupState.signUpBussiness,
        "confirm_password"
      );
      addValidation(validation);
    }
    setError(false);
  };

  const businessvalidation = () => {
    const businessErr = {};
    let Valid = true;
    let Name = replceMultiStringWithSIngle(businessName);
    if (Name.length == "" && Name.length == " ") {
      businessErr.businessShort = "Please enter name";
      Valid = false;
    }
    return Valid;
  };

  //this function is used to submit the form.When all validation was done then hit the API.
  const handleSubmit = () => {
    if (businessName.trim().length < 2) {
      setIsValidName(true);
    } else {
      setIsValidName(false);
    }
    const validation =
      SignupState.is_individual === "false"
        ? !!businessName.length && !!password.length && !!verifyPassword.length
        : !!password.length && !!verifyPassword.length;
    if (validation) {

      if (businessName.length <= 25) {
        const Valid = businessvalidation();
        let validation = {
          is_valid_password: {
            status: false,
            validation: passwordElValidation,
          },
          is_valid_confirmPassword: {
            status: false,
            validation: cofirmPasswordValidation,
          },
        };
        validation = signUpBusinessValidation(
          validation,
          SignupState.signUpBussiness,
          "password"
        );
        validation = signUpBusinessValidation(
          validation,
          SignupState.signUpBussiness,
          "confirm_password"
        );
        let { is_valid_password, is_valid_confirmPassword } = validation;
        addValidation(validation);
        if (SignupState.is_individual === "false") {
          if (
            is_valid_password.status &&
            !!Valid &&
            is_valid_confirmPassword.status &&
            password === verifyPassword
          ) {
            setIsClickSignUp(true);
            const bodyParameter = {
              first_name: first_name,
              last_name:last_name,
              email: email,
              phone: phoneNo,
              country_code: "+" + cntCode,
              business_name: validatedBusinessName,
              password: password,
            };
            dispatch(signUpDataApi(bodyParameter)).then(() => {
              setTimeout(() => setIsClickSignUp(false), 2000);
            });
          }
        } else {
          if (
            is_valid_password?.status &&
            is_valid_confirmPassword?.status &&
            password === verifyPassword
          ) {
            const bodyParameter = {
              first_name: first_name,
              last_name:last_name,
              email: email,
              phone: phoneNo,
              country_code: "+" + cntCode,
              business_name: validatedBusinessName,
              password: password,
            };
            dispatch(signUpDataApi(bodyParameter)).then(() => {
              setTimeout(() => setIsClickSignUp(false), 2000);
            });
          }
        }
      }
    } else {
      setError(true);
    }
  };
  const handleBack = () => {
    dispatch(changeIsFinalIndividualInput({ is_individual_final: true }));
  };
  const handleTermService = () => {
    updateClearData();
    history.push("/term-service");
  };
  const handlePrivacyPolicy = () => {
    updateClearData();
    history.push("/privacy-policy");
  };
  useEffect(() => {
    if (password.length > 0) {
      let validation = {
        is_valid_password: { status: false, validation: passwordElValidation },
      };
      validation = signUpBusinessValidation(
        validation,
        SignupState.signUpBussiness,
        "password"
      );
      addValidation(validation);
    } else {
      passwordElValidation.current.style.display = "none";
      cofirmPasswordValidation.current.style.display = "none";
    }
    if (!!verifyPassword.length) {
      let validation = {
        is_valid_confirmPassword: {
          status: false,
          validation: cofirmPasswordValidation,
        },
      };
      validation = signUpBusinessValidation(
        validation,
        SignupState.signUpBussiness,
        "confirm_password"
      );
      addValidation(validation);
    } else {
      cofirmPasswordValidation.current.style.display = "none";
    }
  }, [password, verifyPassword]);
  useEffect(() => {
    setValidatedBusinessName(businessName.slice(0, 24));
  }, [businessName]);

  return (
    <div className="step-2">
      {SignupState.is_individual === "false" && (
        <div className="form-group">
          <label>Business Name</label>
          <Input
            type="text"
            name="businessName"
            id="capitalize"
            value={validatedBusinessName}
            onChange={(e) => handleChange(e, "businessName")}
            className="form-control"
            placeholder="Business name"
            style={{ "text-transform": "capitalize" }}
          />
          {businessName.length > 24 ? (
            <p className="error-message">
              You can not exceed limit above 24 character.
            </p>
          ) : null}
          {businessName.trim().length == 0 && isValidName ? (
            <div className="error-message">Please enter business name.</div>
          ) : null}
        </div>
      )}
      <div className="form-group">
        <label>Password</label>
        <div className="position-relative password-field">
          <Input
            value={password}
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={(e) => handleChange(e, "password")}
            className="form-control"
            placeholder="Password"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          {!!password && (
            <i
              className={showPassword ? "ri-eye-line" : "ri-eye-off-line"}
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </div>
        {error && !password.length && (
          <p className="error-message">Please enter password.</p>
        )}
        <p
          style={{ display: "none" }}
          ref={passwordElValidation}
          className="error-message"
        >
          Password must contain at least 8 characters, including
          upper/lowercase, number and special character.{" "}
        </p>
      </div>
      <div className="form-group">
        <label>Confirm Password</label>
        <div className="position-relative password-field">
          <Input
            value={verifyPassword}
            type={showVerifyPassword ? "text" : "password"}
            name="verifyPassword"
            onChange={(e) => handleChange(e, "confirm")}
            className="form-control"
            placeholder="Confirm Password"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          {!!verifyPassword && (
            <i
              className={showVerifyPassword ? "ri-eye-line" : "ri-eye-off-line"}
              onClick={() => setShowVerifyPassword(!showVerifyPassword)}
            />
          )}
        </div>
        {error && !verifyPassword.length && (
          <p className="error-message">Please enter confirm password.</p>
        )}
        <p
          style={{ display: "none" }}
          ref={cofirmPasswordValidation}
          className="error-message"
        >
          The passwords don't match. Please try again.
        </p>
      </div>
      <div className="form-group">
        <p className="mb-0">
          By signing up, you agree to the{" "}
          <a
            href="javascript:;"
            onClick={() => handleTermService()}
            className="highlight-txt"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            onClick={() => handlePrivacyPolicy()}
            href="javascript:;"
            className="highlight-txt"
          >
            Privacy Policy
          </a>
          , including{" "}
          <a href="javascript:;" className="highlight-txt">
            Cookie Use
          </a>
          .
        </p>
      </div>
      <div className="form-group">
        <div className="d-flex flex-column mt-4">
          <button
            href="javascript:;"
            disabled={isClickSignUp}
            onClick={handleSubmit}
            className={
              !!signupApiLoading
                ? "btn w-50 mx-auto btn-secondary  disabled"
                : "btn btn-secondary btn-primary w-50 mx-auto"
            }
          >
            Sign Up
          </button>
          <ClipLoader
            color={"#fff"}
            loading={!!signupApiLoading ? true : false}
            css={override}
          />
        </div>
      </div>
      <div className="form-group text-right mb-0 steps-control">
        <a
          href="javascript:;"
          className="btn btn-primary btn-lg"
          onClick={handleBack}
          id="steps-prev"
        >
          Back
        </a>
      </div>
    </div>
  );
};
export default BusinesSignUp;
