import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { BarLoader } from "react-spinners";
import { css } from "@emotion/core";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Input } from "../../library/common/components";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  getCookie,
  replceMultiStringWithSIngle,
} from "../../library/utilities/functions";
import { changeChatDetails, changeShareAttachments } from "./PoolsChatAction";
import { GET_CREATE_MEDIA, IMAGE_BASE_URL, SOCKET } from "../../library/urls";
import axios from "axios";
import moment from "moment";
import {
  changeFriendListDetail,
  clearFriendListApiResponse,
} from "../MyNetwork/MyNetworkAction";
import {
  AiOutlineFileWord,
  AiOutlineFilePdf,
  AiOutlineFilePpt,
  AiOutlineFileExcel,
} from "react-icons/ai";
import {
  searchFriendListApi,
  searchFriendonChat,
} from "../../library/api/MyNetworkApiService";
import Scrollbars from "react-custom-scrollbars";
let currentUserId = "";
let allBaseImages = [];
let links = [];
let files = [];

export default function SearchFriendModal({
  searchFriendModal,
  poolsChatState,
  closeSearchFriendModal,
  setSearchFriendModal,
}) {
  const selectInputRef = useRef();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [uplodImageData, setuplodImageData] = useState(0);
  const [image, setImage] = useState([]);
  const [uploadDocument, setUploadDocument] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [sendOnlyDocs, setSendOnlyDocs] = useState(true);
  const [searchFriendsName, setSearchFriendsName] = useState("");
  const [myUrls, setUrls] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [uploadDocumentFile, setUploadDocumentFile] = useState(null);
  const [sendOnlyPicture, setSendOnlyPicture] = useState(true);
  const [selectedFriend, setSelectedFriend] = useState({});
  const [buttonState, setButtonState] = useState(false);
  const [uploadMedia, setUploadMedia] = useState(false);
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const [searchFriendData, setSearchFriendData] = useState([]);
  const [writeMessage, setWriteMessage] = useState("");
  const [error, setError] = useState(false);
  const [friendNotFound, setFriendNotFound] = useState(false);
  const {
    chatDetails: { currenUserId, userMessage },
    ShareAttachment: { mediaFile, mediaUrl, shareText, fileVisibility },
  } = poolsChatState;
  var frdMesageStatus = {
    friend_id: "",
    online: { read: false, unread: true, reciever_id: "" },
    offline: false,
  };
  let name = "";
  const {
    friendListDetail: { friendList, searchFriendName, searchFriendPage },
    searchFriendData: { searchFriendList },
  } = networkState;

  // GET COOKIES DATA
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  // FUCNCTION TO STORE SEARCHED FRIEND
  const handleSearchFriend = (value) => {
    setSelectedFriend(value);
  };

  useEffect(() => {
    setSearchFriendData(searchFriendList);
  }, [searchFriendList]);

  // ADD LABEL AS USER IN FRIEND LIST TO SHOW VALUE ON SELECT BOX

  // WHEN USER TYPE MESSAGE
  const handleChange = (e) => {
    links = [];
    e.target.value.trim().replace(/\s\s+/g, " ");
    let splStrg = e.target.value.split(" ");
    setWriteMessage(e.target.value);

    for (let i = 0; i < splStrg.length; i++) {
      if (
        splStrg[i].match(
          "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
        )
      ) {
        links.push(splStrg[i]);
      }
    }

    SOCKET.emit("typing", {
      user_id: profileData.id,
      typing_user: !!profileData.is_individual
        ? profileData.name
        : profileData.business_name,
      reciever_id: !!selectedFriend ? selectedFriend?.user_id : currenUserId,
      frds_acknowledged: 1,
      type: !!getCookie("chatType") ? getCookie("chatType") : "",
    });
  };

  const handleSumbitMessage = (e) => {
    if (!!Object.keys(selectedFriend).length > 0) {
      if (mediaUrl.length > 0) {
        handleSendAll();
        closeSearchFriendModal(
          setSelectedFriend,
          setSearchFriendData,
          setSearchFriendsName,
          setUploadMedia,
          removeFile,
          setWriteMessage,
          setFriendNotFound,
          setError
        );
      } else {
        links = [];
        e.preventDefault();
        if (writeMessage.trim().length > 0) {
          var message = {
            session_id: getCookie("token_id"),
            message: replceMultiStringWithSIngle(writeMessage),
            sender_name: !!profileData.is_individual
              ? profileData.name
              : profileData.business_name,
            device_token: null,
            created_at: new Date(),
            frds_acknowledged: 1,
            message_id: uuidv4(),
            messageStatus: frdMesageStatus,
            sender_id: Number(profileData?.id),
            ignore_user_from_id: 1,
            ignore_user_to_id: 1,
            is_individual: profileData.is_individual,
            is_business: profileData.is_business,
            type: "user",
            reciever_id: !!selectedFriend
              ? selectedFriend?.id
              : Number(currenUserId),
          };

          SOCKET.emit("send_message", message);

          const lastMessage = {
            user_id: profileData.id,
            frd_id: !!selectedFriend
              ? selectedFriend?.id
              : Number(currenUserId),
            message: replceMultiStringWithSIngle(writeMessage),
          };
          SOCKET.emit("get_my_frds_last_message", lastMessage);
          setWriteMessage("");
          closeSearchFriendModal(
            setSelectedFriend,
            setSearchFriendData,
            setSearchFriendsName,
            removeFile,
            setUploadMedia,
            setWriteMessage,
            setFriendNotFound,
            setError
          );
        } else {
          addToast("Please enter message.", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
        }
      }
    } else {
      addToast("Please select your friend.", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      closeSearchFriendModal(
        setSelectedFriend,
        setSearchFriendData,
        setSearchFriendsName,
        setUploadMedia,
        removeFile,
        setError
      );
    }
  };

  // TO CLOSE SEND MEDIA MODAL
  const handleAllCloseButton = () => {
    setUploadMedia(false);
    setUploadDocumentFile(null);
    setVideoFile(null);
    setImage(null);
    setWriteMessage("");
    setSearchFriendData([]);
    setSearchFriendsName("");
    setSelectedFriend({});
    setUrls([]);
    let clearAttachment = {
      mediaUrl: [],
      mediaFile: [],
    };
    dispatch(
      changeShareAttachments({
        ...poolsChatState?.ShareAttachment,
        ...clearAttachment,
      })
    );

    dispatch(changeChatDetails);
    allBaseImages = [];
    files = [];
  };

  //HANDLE DOCS
  const handleDocumentChange = (e) => {
    var dat = [...e.target.files];

    for (var i = 0; i < dat.length; i++) {
      const docUpload = e.target.files[i];
      if (docUpload) {
        const docFileName = docUpload.name.split(".");
        const docFormat = docFileName[docFileName.length - 1];
        if (
          docFormat === "docx" ||
          docFormat === "ppt" ||
          docFormat === "doc" ||
          docFormat === "pdf" ||
          docFormat === "pptx" ||
          docFormat === "xls" ||
          docFormat === "csv" ||
          docFormat === "odp" ||
          docFormat === "xlsx"
        ) {
          if (dat.length + mediaUrl.length < 6) {
            files.push(e.target.files[i]);
          } else {
          }

          dispatch(changeShareAttachments({ mediaFile: files }));

          if (dat.length + mediaUrl.length < 6) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              allBaseImages.push(docUpload.name);
              dispatch(changeShareAttachments({ mediaUrl: allBaseImages }));
            });
            reader.readAsDataURL(e.target.files[i]);
          } else {
            setuplodImageData(1);
          }
        } else {
          addToast("Please , Select the Document", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
        }
      }
    }
  };

  // ON CHANGE FUNCTION ON IMAGE
  const handleChangeFile = (e) => {
    var dat = [...e.target.files];
    //   files = [];
    for (var i = 0; i < dat.length; i++) {
      const data = e.target.files[i];
      if (!!data) {
        const fileName = data.name.split(".");
        const imageFormat = fileName[fileName.length - 1];
        if (
          imageFormat === "png" ||
          imageFormat === "jpg" ||
          imageFormat === "jpeg" ||
          imageFormat === "PNG" ||
          imageFormat === "JPG" ||
          imageFormat === "JPEG"
        ) {
          if (dat.length + mediaUrl.length < 6) {
            files.push(e.target.files[i]);
          } else {
          }
          dispatch(changeShareAttachments({ mediaFile: files }));

          // Pusing inform with base64
          if (dat.length + mediaUrl.length < 6) {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
              allBaseImages.push(reader.result);

              dispatch(changeShareAttachments({ mediaUrl: allBaseImages }));
            });
            reader.readAsDataURL(e.target.files[i]);
          } else {
            setuplodImageData(1);
          }
        } else {
          addToast("Only .png, .jpg, .jpeg image formats supported.", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
        }
      }
    }
  };

  //Send file,image and document code
  const hiddenFileInput = React.useRef(null);
  const handleVideoInput = React.useRef(null);
  const handleDocumentInput = React.useRef(null);

  const handleClick = (data) => {
    if (data == "images") {
      hiddenFileInput.current.click();
    } else if (data == "docs") {
      handleDocumentInput.current.click();
    } else {
      handleVideoInput.current.click();
    }
  };
  const getCurrentDateTime = () => {
    return moment().format("L") + " " + moment().format("LT");
  };

  // FUNCTION TO SEND IMAGES
  const handleSendImages = () => {
    if (mediaFile) {
      const data = new FormData();
      mediaFile.forEach((tag) => data.append("document[]", tag));
      data.append("document_type", "media");
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      setButtonState(true);

      axios(GET_CREATE_MEDIA, config).then((response) => {
        if (response.status == 200) {
          var msg = {};
          if (response.data.data) {
            for (let i = 0; i < response.data.data.length; i++) {
              msg.fileName = response.data.data[i].file_name;
              msg.session_id = getCookie("token_id");

              msg.reciever_id = !!selectedFriend
                ? selectedFriend?.id
                : Number(currenUserId);

              msg.sender_name = !!profileData.is_individual
                ? profileData.name
                : profileData.business_name;
              msg.message = null;
              msg.message_id = uuidv4();
              msg.frds_acknowledged = 1;
              msg.sender_id = Number(profileData.id);
              msg.ignore_user_from_id = 1;
              msg.ignore_user_to_id = 1;

              msg.type = "user";
              msg.created_at = new Date();

              SOCKET.emit("send_media", msg);

              msg = {};
            }
          }
          let clearAttachment = {
            shareText: "",
            mediaFile: [],
            mediaUrl: [],
          };
          dispatch(
            changeShareAttachments({
              ...poolsChatState.SharePostInput,
              ...clearAttachment,
            })
          );
          (allBaseImages = []), (files = []);

          setUrls([]);
          setButtonState(false);
          setImage(null);
          setUploadImage(false);
          setUploadMedia(false);
          setSendOnlyDocs(true);
          setImage(null);

          SOCKET.off("image_file").on("image_file", (messages) => {
            let getLastImages = {
              completeMessageList: messages.obj,
            };
            dispatch(changeChatDetails(messages.obj));
          });
        } else {
          addToast("Something went wrong. Try again!", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
          setButtonState(false);
          setUploadImage(false);
        }
      });
    }
  };

  // FUNCTION TO SEND DOCUMENT
  const handleSendDucument = () => {
    if (!!uploadDocument) {
      const data = new FormData();
      uploadDocument.forEach((tagData) => data.append("document[]", tagData));
      data.append("document_type", "media");
      setButtonState(true);

      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };

      axios(GET_CREATE_MEDIA, config).then((response) => {
        if (response.status == 200) {
          var msg = {};

          if (response.data.data) {
            for (let i = 0; i < response.data.data.length; i++) {
              msg.sender_id = Number(profileData.id);
              msg.docs = response.data.data[i].file_name;
              msg.session_id = getCookie("token_id");
              msg.sender_name = !!profileData.is_individual
                ? profileData.name
                : profileData.business_name;
              msg.device_token = null;
              msg.frds_acknowledged = 1;

              msg.reciever_id = !!selectedFriend
                ? selectedFriend?.id
                : Number(currenUserId);

              msg.message = null;
              msg.message_id = uuidv4();
              msg.messageStatus = frdMesageStatus;
              msg.created_at = getCurrentDateTime();
              msg.ignore_user_from_id = 1;
              msg.ignore_user_to_id = 1;

              SOCKET.emit("document", msg);
              var msg = {};
            }
          }
          setButtonState(false);
          setUploadDocument(null);
          setUploadImage(false);
          setUploadDocumentFile(null);
          setSendOnlyPicture(true);
        } else {
          addToast("Something went wrong. Try again!", {
            appearance: "error",
            autoDismiss: true,
     
          });
          setButtonState(false);
        }
      });
    }
  };

  // SEND ALL MEDIA
  const handleSendAll = () => {
    if (image || uploadDocument || mediaFile) {
      handleSendImages();
      handleSendDucument();
    } else
      addToast("Please Select Media!", {
        appearance: "error",
        autoDismiss: true,
   
      });
  };

  // GET SOCKET RESPONSE

  // REMOVE SELECTED MEDIA FILE

  const removeFile = (file) => {
    if (file === "all") {
      mediaUrl.splice(0, mediaUrl.length);
      mediaFile.splice(0, mediaFile.length);
      dispatch(changeShareAttachments({ mediaUrl: mediaUrl }));
      dispatch(changeShareAttachments({ mediaFile: mediaFile }));
    } else {
      if (file === videoFile) {
        setVideoFile(null);
        setVideo([]);
      }

      mediaUrl.splice(file, 1);
      dispatch(changeShareAttachments({ mediaUrl: mediaUrl }));
      for (let i in mediaFile) {
        if (i == file) {
          mediaFile.splice(i, 1);
          dispatch(changeShareAttachments({ mediaFile: mediaFile }));
        }
      }
      if (file === uploadDocumentFile) {
        setUploadDocument("");
        setUploadDocumentFile(null);
      }
    }
  };

  const removeSelectedFriend = (data) => {
    if (selectedFriend.id === data.id) {
      setSelectedFriend({});
      setSearchFriendsName("");
    }
  };

  const handleChangeFriend = (e) => {
    var regex = /^[a-zA-Z ]*$/;
    setFriendNotFound(false);
    if (regex.test(e.target.value)) {
      setSearchFriendsName(e.target.value);
      setError(false);
    }
  };

  const handleSearchFriendSubmit = (e) => {
    e.preventDefault();
    if (searchFriendsName.trim().length > 0) {
      const bodyParameters = {
        chat: 1,
        search: !!searchFriendsName
          ? replceMultiStringWithSIngle(searchFriendsName)
          : "",
      };
      dispatch(searchFriendonChat(bodyParameters)).then((res) => {
        if (!!res.response.data.data.length) {
          setFriendNotFound(false);
        } else {
          setFriendNotFound(true);
        }
      });
    } else {
      setError(true);
    }
  };
  const handleSelectedMemeber = (item) => {
    setSelectedFriend(item);
    setSearchFriendsName(item.name);
    setSearchFriendData([]);
  };

  const showIcon = (data) => {
    let iconStyle = { width: "4rem", height: "4rem" };
    if (data.match("doc") || data.match("docx")) {
      return <AiOutlineFileWord style={iconStyle} color="blue" />;
    } else if (data.match("pdf")) {
      return <AiOutlineFilePdf style={iconStyle} color="red" />;
    } else if (data.match("csv") || data.match("xlsx") || data.match("xls")) {
      return <AiOutlineFileExcel style={iconStyle} color="green" />;
    } else if (data.match("ppt") || data.match("pptx") || data.match("odp")) {
      return <AiOutlineFilePpt style={iconStyle} color="orange" />;
    } else {
      return null;
    }
  };

  const handleUploadMedia = () => {
    setUploadMedia(!uploadMedia);
  };

  return (
    <>
      {!!searchFriendModal && (
        <div>
          <div>
            <Modal show={searchFriendModal} centered id="search-friend-modal">
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <img
                    src="/assets/images/icon.svg"
                    alt="icon"
                    className="site-icon"
                  />
                  <h5 className="modal-title ml-2" id="exampleModalLabel">
                    Write a Message
                  </h5>
                </div>
                <button
                  onClick={() =>
                    closeSearchFriendModal(
                      setSelectedFriend,
                      setSearchFriendData,
                      setSearchFriendsName,
                      setUploadMedia,
                      removeFile,
                      setWriteMessage,
                      setFriendNotFound,
                      setError
                    )
                  }
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div
                    className="form-group"
                    onSubmit={handleSearchFriendSubmit}
                  >
                    {selectedFriend?.name?.length ? (
                      <div className="form-group add-memeber-group d-flex align-items-center position-relative">
                        <a
                          href="javascript:;"
                          className="remove-selected"
                          onClick={() => removeSelectedFriend(selectedFriend)}
                        >
                          <i className="ri-close-fill"></i>
                        </a>
                        <img
                          src={
                            (!!selectedFriend?.is_individual &&
                              selectedFriend?.profile_image !== "") ||
                            null
                              ? IMAGE_BASE_URL + selectedFriend?.profile_image
                              : IMAGE_BASE_URL + selectedFriend?.business_image
                          }
                          onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                          }
                          alt="user"
                          className={
                            !!selectedFriend.is_individual
                              ? "user-profile"
                              : "business-user"
                          }
                        />
                        <div className="mb-0 ml-3">
                          <p>
                            {selectedFriend?.is_individual
                              ? selectedFriend?.name
                              : selectedFriend.business_name}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <form autoComplete="off">
                        <div className="form-group mb-0 position-relative search-form w-100 ml-0">
                          <Input
                            type="text"
                            name="searchFriendsName"
                            onChange={(e) => handleChangeFriend(e)}
                            placeholder="Search People or business"
                            className="form-control bg-white"
                            value={searchFriendsName}
                          />
                          <i
                            className="ri-search-line"
                            onClick={handleSearchFriendSubmit}
                          ></i>
                        </div>
                        {error && (
                          <p className="error-message" style={{ color: "red" }}>
                            you need to enter something to search friend
                          </p>
                        )}
                        {friendNotFound && (
                          <p className="error-message" style={{ color: "red" }}>
                            Friend not found
                          </p>
                        )}
                        <div className=" mt-4">
                          {!Object.keys(selectedFriend).length && (
                            <>
                              <div>
                                {" "}
                                {!!searchFriendData &&
                                  searchFriendData?.length > 0 && (
                                    <form method="post">
                                      <Scrollbars style={{ height: 150 }}>
                                        {searchFriendData?.length > 0 &&
                                          searchFriendData.map(
                                            (item, index) => (
                                              <div
                                                className="add-member-block"
                                                key={index}
                                              >
                                                <div
                                                  className="add-memeber-group"
                                                  onClick={() =>
                                                    handleSelectedMemeber(item)
                                                  }
                                                  checked={
                                                    selectedFriend?.id ==
                                                    item?.id
                                                  }
                                                >
                                                  <label
                                                    className="d-flex align-items-center"
                                                    for={`create_${item?.id}`}
                                                  >
                                                    <img
                                                      src={
                                                        (!!item.is_individual &&
                                                          item.profile_image !==
                                                            "") ||
                                                        null
                                                          ? IMAGE_BASE_URL +
                                                            item.profile_image
                                                          : IMAGE_BASE_URL +
                                                            item.business_image
                                                      }
                                                      onError={(ev) =>
                                                        (ev.target.src =
                                                          "/assets/images/member-default.svg")
                                                      }
                                                      alt="user"
                                                      className={
                                                        !!item.is_individual
                                                          ? "user-profile"
                                                          : "business-user"
                                                      }
                                                    />
                                                    <p className="mb-0 ml-3">
                                                      {item.is_individual
                                                        ? item.name
                                                        : item.business_name}
                                                    </p>{" "}
                                                    <i
                                                      className="ri-checkbox-circle-fill "
                                                      style={{
                                                        display:
                                                          selectedFriend?.id ==
                                                          item?.id
                                                            ? "block"
                                                            : "none",
                                                      }}
                                                    ></i>
                                                  </label>
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </Scrollbars>
                                    </form>
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                      </form>
                    )}

                    {selectedFriend.id && (
                      <textarea
                        name="userMessage"
                        value={writeMessage}
                        onChange={handleChange}
                        className="form-control textarea-field"
                        placeholder="Write a message"
                      />
                    )}
                    <div className="grid">
                      {mediaUrl.length > 0 &&
                        mediaUrl.map((data, index) => (
                          <div className="media-box" id={index}>
                            <a
                              href="javascript:;"
                              className="rejected"
                              onClick={() => removeFile(index)}
                            >
                              <i className="ri-close-fill"></i>
                            </a>

                            {(data.match("data:video/mp4") || data.match("data:video/webm") ||
                              data.includes(".mp4")) && <video src={data} />}
                            {(data.match("data:image/") ||
                              data.includes(".png") ||
                              data.includes(".jpg") ||
                              data.includes(".JPG") ||
                              data.includes(".PNG") ||
                              data.includes(".JPEG") ||
                              data.includes(".jpeg")) && (
                              <img src={data} alt="kjfh" />
                            )}

                            {(data.match("data:application/") ||
                              data.includes(".docx") ||
                              data.includes(".ppt") ||
                              data.includes(".doc") ||
                              data.includes(".pdf") ||
                              data.includes(".pptx") ||
                              data.includes(".xls") ||
                              data.includes(".csv") ||
                              data.includes(".odp") ||
                              data.includes(".xlsx")) && (
                              <div>
                                <i>{showIcon(data)}</i>
                                <p>{data}</p>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="d-flex w-100 align-items-center">
                  {!!selectedFriend.id && (
                    <div className="plus-box d-flex justify-content-center align-items-center ">
                      <div>
                        <label
                          className="d-flex justify-content-center align-items-center"
                          onClick={() => handleUploadMedia()}
                        >
                          <i
                            className="ri-add-line textLinkPre-white"
                            style={{
                              transform: uploadMedia
                                ? "rotate(225deg)"
                                : "rotate(90deg)",
                              transition: "all 0.3s ease 0.1s",
                            }}
                          ></i>
                        </label>
                      </div>

                      <div className="feeds-post__type ml-3">
                        {" "}
                        {!!uploadMedia && (
                          <div className="d-flex w-100">
                            {sendOnlyPicture && (
                              <div className="feeds-post__category w-auto">
                                <a
                                  href="javascript:;"
                                  onClick={() => handleClick("images")}
                                >
                                  <i className="ri-image-fill pictures-option" />{" "}
                                  Pictures
                                  <input
                                    id="uploadfile"
                                    type="file"
                                    className="d-none"
                                    multiple={true}
                                    accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                                    ref={hiddenFileInput}
                                    onChange={(e) => handleChangeFile(e)}
                                    style={{ display: "none" }}
                                  />
                                </a>
                              </div>
                            )}

                            {sendOnlyDocs && (
                              <div className="feeds-post__category w-auto">
                                <a
                                  href="javascript:;"
                                  onClick={() => handleClick("docs")}
                                >
                                  <i className="ri-links-line link-option"></i>{" "}
                                  Document
                                  <input
                                    type="file"
                                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.odp"
                                    name="file-document"
                                    value=""
                                    id="upload_fle-document"
                                    className="d-none"
                                    multiple="true"
                                    ref={handleDocumentInput}
                                    onChange={(e) => handleDocumentChange(e)}
                                    style={{ display: "none" }}
                                  />
                                </a>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(selectedFriend.id && !!writeMessage.trim().length > 0) ||
                  !!mediaUrl.length ? (
                    <button
                      name="submit"
                      onClick={handleSumbitMessage}
                      className="btn btn-primary btn-sm btn-full ml-auto"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Send
                    </button>
                  ) : null}
                </div>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
}
