import {
  CHANGE_SIGNUP_BUSSINESS_INPUT,
  CHANGE_SIGNUP_INDIVIDUAL_INPUT,
  CHECK_ISFINAL_INDIVIDUAL_INPUT,
  CHECK_SIGNUP_INDIVIDUAL_INPUT,
  CLEAR_SIGNUP_API_RESPONSE,
  CLEAR_SIGNUP_PAGE,
  SIGNUP_DATA_FAILURE,
  SIGNUP_DATA_REQUEST,
  SIGNUP_DATA_SUCCESS,
} from "./SignupConstants";

const initialState = {
  signUpIndividual: {
    individualName: "",
    first_name:"",
    last_name:"",
    email: "",

    phoneNo: "",
    cntCode: 1,
    cntName: "United States",
    isShowPhone: 0,
  },
  signUpBussiness: {
    businessName: "",
    first_name:"",
    last_name:"",
    password: "",
    verifyPassword: "",
  },

  is_individual: "true",
  is_individual_final: true,

  SignupApiResponse: {
    signupApiLoading: false,
    signupApiStatus: "",
    signupMessage: "",
    signUpSuccess: "",
  },
};

export const SignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SIGNUP_INDIVIDUAL_INPUT: {
      return {
        ...state,
        ...{
          signUpIndividual: { ...state.signUpIndividual, ...action.newState },
        },
      };
    }
    case CHANGE_SIGNUP_BUSSINESS_INPUT: {
      return {
        ...state,
        ...{
          signUpBussiness: { ...state.signUpBussiness, ...action.newState },
        },
      };
    }
    case CHECK_SIGNUP_INDIVIDUAL_INPUT: {
      return {
        ...state,
        ...action.newState,
      };
    }
    case CHECK_ISFINAL_INDIVIDUAL_INPUT:
      return {
        ...state,
        ...action.newState,
      };
    case CLEAR_SIGNUP_PAGE:
      let Individual = {
        individualName: "",
        first_name:"",
        last_name: "",
        phoneNo: "",
        cntCode: 1,
        cntName: "United States",
        isShowPhone: 0,
      };
      let business = {
        businessName: "",
        first_name:"",
        last_name:"",
        password: "",
        verifyPassword: "",
      };
      return {
        ...state,
        ...{
          signUpIndividual: { ...state.signUpIndividual, ...Individual },
          signUpBussiness: { ...state.signUpBussiness, ...business },
        },
      };
    case SIGNUP_DATA_REQUEST:
      return {
        ...state,
        ...{
          SignupApiResponse: {
            ...state.SignupApiResponse,
            ...{ signupApiLoading: true },
          },
        },
      };
    case SIGNUP_DATA_SUCCESS:
      const {
        response: {
          data: { success, message, status },
        },
      } = action;
      let signupStateSuccess = {
        signupApiLoading: false,
        signupApiStatus: status,
        signupMessage: message,
        signUpSuccess: success,
      };
      return {
        ...state,
        ...{
          SignupApiResponse: {
            ...state.SignupApiResponse,
            ...signupStateSuccess,
          },
        },
      };
    case SIGNUP_DATA_FAILURE:
      let signupStateFailure = {
        signupApiLoading: false,
        signupApiStatus: action.response.status,
        signupMessage: action.response.message,
      };
      return {
        ...state,
        ...{
          SignupApiResponse: {
            ...state.SignupApiResponse,
            ...signupStateFailure,
          },
        },
      };
    case CLEAR_SIGNUP_API_RESPONSE:
      let signupResponse = {
        signupApiStatus: "",
        signupMessage: "",
      };
      return {
        ...state,
        ...{
          SignupApiResponse: { ...state.SignupApiResponse, ...signupResponse },
        },
      };

    default:
      return state;
  }
};
