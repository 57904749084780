import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { SELLER_UPDATE_STATUS } from "../../library/urls";
import { getCookie, scroolTop } from "../../library/utilities/functions";
import axios from "axios";
import { signUpAddDataApi } from "../../library/api/AccountApiService";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { changeGeneralInfoInput } from "../Account/AccountAction";

const languageData = [
  { value: 1, label: "Native" },
  { value: 2, label: "Intermediate" },
  { value: 3, label: "Expert" },
];
const BusinessSkillAndExpertise = ({ isEdit, profileData }) => {
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [allSelectedLanguage, setAllSelectedLanguage] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [getAllBusinessData, setGetBusinessData] = useState([]);
  const [getAllSelectedExpertise, setGetAllSelectedExpertise] = useState([]);
  const [showAddLangusgeError, setShowAddLangusgeError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [filterExpertiseOptions, setFilterExpertiseOptions] = useState([]);
  const [filterLanguageOption, setFilterLanguageOption] = useState([]);
  const selectInputModalRef = useRef();
  const selectInputModalTwoRef = useRef();
  const selectInputRef = useRef();
  const dispatch = useDispatch();
  const AccountStateData = useSelector((state) => state.AccountReducer);
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#deebff" : null,
        color: "#333333",
      };
    },
  };
  const handleSelectedLangusgeChange = (newData) => {
    setSelectedLanguage(newData);
  };

  const submitLanguage = () => {
    if (
      selectedLanguage != "" &&
      selectedLevel != "" &&
      selectedLanguage != null &&
      selectedLevel != null
    ) {
      let dataArray = [...allSelectedLanguage];
      dataArray.push({
        label: selectedLanguage?.label,
        value: selectedLanguage?.value,
        proficiency_level: selectedLevel?.label,
        proficiency_id: selectedLevel?.value,
      });
      setAllSelectedLanguage(dataArray);
      setShowAddLangusgeError(false);
      $("#addlanguageModal").modal("hide");
      selectInputModalRef.current.select.clearValue();
      selectInputModalTwoRef.current.select.clearValue();
    } else {
      setShowAddLangusgeError(true);
    }
  };

  const handleSkills = (opt) => {
    setGetAllSelectedExpertise(opt);
    dispatch(changeGeneralInfoInput({ expertise: opt }));
  };

  const handleDelete = (id) => {
    const newShowSelectedLanguage = allSelectedLanguage.filter(
      (item, index) => index !== id
    );
    setAllSelectedLanguage(newShowSelectedLanguage);
  };
  const handleDropDownChange = (e, i) => {
    /* add particular proficiency_level value in api and id with onchange */
    const getProficiencyId = languageData.find(
      (item) => item.label === e.target.value
    ).value;
    let clone = [...allSelectedLanguage];
    let objData = clone[i];
    objData.proficiency_level = e.target.value;
    objData.proficiency_id = getProficiencyId;
    clone[i] = objData;
  };
  const addAndUpdateSkillAndExertiseApifunc = () => {
    const data = new FormData();
    data.append("form_step", 2);
    data.append("type_individual", 0);
    data.append("seller_status", 1);

    getAllSelectedExpertise.forEach((item, index) =>
      data.append(`expertise_ids['${index}'][expertise_id]`, item.value)
    );
    allSelectedLanguage.forEach((item, index) =>
      data.append(`language['${index}'][language_id]`, item.value)
    );
    allSelectedLanguage.forEach((item, index) =>
      data.append(
        `language['${index}'][proficiency_level]`,
        item.proficiency_id
      )
    );

    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };
    /* Read more about isConfirmed, isDenied below */
    Swal.fire({
      title: "<h5 style='color:#212529'>" + "Do you want to save?" + "</h5>",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Save",
      confirmButtonColor: "#00a8f3",
      denyButtonColor: "#88001b",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(signUpAddDataApi(data)).then(() =>
          axios(SELLER_UPDATE_STATUS, config).then((response) => { })
        );
        var next = jQuery(".seller-tabs  .active").parent("li").next("li");
        next.find("a").removeClass("disabled");
        next.find("a").trigger("click");
        scroolTop();
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handleSaveStepTwo = (data) => {
    if (data === 1) {
      if (
        getAllSelectedExpertise?.length > 0 &&
        allSelectedLanguage.length > 0
      ) {
        addAndUpdateSkillAndExertiseApifunc();
      } else {
        setShowError(true);
      }
    } else if (data === 2) {
      if (
        isEdit === false &&
        getAllSelectedExpertise?.length > 0 &&
        allSelectedLanguage.length > 0
      ) {
        var next = jQuery(".seller-tabs  .active").parent("li").next("li");
        next.find("a").removeClass("disabled");
        next.find("a").trigger("click");
        scroolTop();
      } else if (
        isEdit &&
        getAllSelectedExpertise?.length > 0 &&
        allSelectedLanguage.length > 0
      ) {
        addAndUpdateSkillAndExertiseApifunc();
      } else {
        setShowError(true);
      }
    }
  };
  const handleBack = () => {
    $("#pills-tab > li:eq(0) a").trigger("click");
  };

  useEffect(() => {
    const option = AccountStateData.expetiseList?.filter((itemA) => {
      return !getAllSelectedExpertise?.find((itemB) => {
        return itemA.name === itemB.label;
      });
    });
    const newOptiom = option?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setFilterExpertiseOptions(newOptiom);
  }, [AccountStateData.expetiseList, getAllSelectedExpertise]);
  useEffect(() => {
    const option = AccountStateData.languageList.filter((itemA) => {
      return !allSelectedLanguage?.find((itemB) => {
        return itemA.name === itemB.label;
      });
    });
    const newOptiom = option?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setFilterLanguageOption(newOptiom);
  }, [AccountStateData.languageList, allSelectedLanguage]);
  useEffect(async () => {
    setGetBusinessData(
      AccountStateData?.getAllSellerDataApi?.getAllSellerDatas
    );
  }, [AccountStateData]);

  /*add api data in states when component render first time */
  useEffect(() => {
    if (
      getAllBusinessData?.expertises?.length ||
      getAllBusinessData?.languages?.length
    ) {
      setGetAllSelectedExpertise(getAllBusinessData.expertises);
      setAllSelectedLanguage(getAllBusinessData.languages);
    }
  }, [getAllBusinessData]);

  const handleCloseModel = () => {
    selectInputModalRef.current.select.clearValue();
    selectInputModalTwoRef.current.select.clearValue();
    setShowAddLangusgeError(false)
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <div className="form-group create-select-field">
          <h5>What are the various things your company can do? </h5>
          <p className="mb-1">
            Adding your company expertise, it would help people to know what you
            do
          </p>
          <p>
            show what your team can offer, and help us choose which jobs to
            recommend to you.
          </p>
          <Select
            options={filterExpertiseOptions}
            placeholder="Add your company Expertise"
            className="basic-multi-select"
            classNamePrefix="select"
            isClearable
            isMulti
            onChange={(opt) => handleSkills(opt)}
            ref={selectInputRef}
            value={
              isEdit
                ? getAllSelectedExpertise?.map((items) => {
                  return {
                    label: items.label,
                    value: items.value,
                    __isNew__: true,
                  };
                })
                : getAllBusinessData?.expertises !== undefined &&
                getAllBusinessData.expertises?.map((data) => {
                  return {
                    label: data.label,
                    value: data.value,
                    __isNew__: true,
                  };
                })
            }
            isDisabled={isEdit ? false : true}
          />
          {showError && getAllSelectedExpertise.length == 0 && (
            <p className="error-message" style={{ color: "red" }}>
              Please select the skills
            </p>
          )}
        </div>
        <div className="form-group">
          <h5>Tell us which languages your company members speak.</h5>
          <p>
            PoolsMagnic is global, So clients are often interested to know what
            languages you speak.
          </p>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead id="language-table">
                <tr>
                  <th>Language(s)</th>
                  <th>Proficiency Level</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allSelectedLanguage.map((items, i) => {
                  return (
                    <>
                      <tr key={i} style={{ alignItems: "center" }}>
                        <td>{items.label} </td>
                        <td>
                          <select
                            onChange={(e) => handleDropDownChange(e, i)}
                            className="form-control select-ui level-select"
                            disabled={isEdit ? false : true}
                          >
                            <option>{items.proficiency_level}</option>
                            <option>
                              {items.proficiency_level !== "Native"
                                ? "Native"
                                : "Expert"
                                  ? "Intermediate"
                                  : "Expert"}
                            </option>
                            <option>
                              {items.proficiency_level !== "Expert"
                                ? "Expert"
                                : "Intermediate"}
                            </option>
                          </select>
                        </td>

                        <td>
                          <button
                            className="btn-blank d-flex aliign-items-center link-color"
                            onClick={() => handleDelete(i)}
                            disabled={isEdit ? false : true}
                          >
                            <i className="ri-delete-bin-line align-middle mr-1" />
                            Delete
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <a
            href="javascript:;"
            className="link-color"
            data-toggle="modal"
            data-target="#addlanguageModal"
            data-backdrop="static"
            data-keyboard="false"
            style={{ pointerEvents: isEdit ? null : "none" }}
          >
            <i className="ri-add-fill align-middle"></i> Add a Language
          </a>
          {showError && allSelectedLanguage.length == 0 && (
            <p className="error-message" style={{ color: "red" }}>
              Please add the language
            </p>
          )}
          <div className="form-group d-flex justify-content-end mt-5 flex-wrap btn-wrapper">
            {isEdit && (
              <>
                <a
                  href="javascript:;"
                  className="btn btn-secondary mr-3"
                  onClick={handleBack}
                >
                  Start Over
                </a>
                <a
                  href="javascript:;"
                  className="btn btn-blue mr-3"
                  onClick={() => handleSaveStepTwo(1)}
                >
                  Save
                </a>
              </>
            )}
            <a
              href="javascript:;"
              className="btn btn-primary "
              onClick={() => handleSaveStepTwo(2)}
            >
              Next to Price &amp; Availability
            </a>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addlanguageModal"
        tabIndex="-1"
        aria-labelledby="languageModalModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="languageModalLabel">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon"
                />{" "}
                Add Language
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Language</label>
                    <Select
                      ref={selectInputModalRef}
                      options={filterLanguageOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      styles={customStyles}
                      placeholder="Add your Language(s)"
                      onChange={(newState) =>
                        handleSelectedLangusgeChange(newState)
                      }
                    />
                    {showAddLangusgeError &&
                      selectedLanguage?.label?.length == undefined && (
                        <p className="error-message" style={{ color: "red" }}>
                          Please select the Language
                        </p>
                      )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Proficiency Level</label>
                    <Select
                      ref={selectInputModalTwoRef}
                      styles={customStyles}
                      placeholder="Select Level"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={languageData}
                      onChange={(newValueData) =>
                        setSelectedLevel(newValueData)
                      }
                    />
                    {showAddLangusgeError &&
                      selectedLevel?.label?.length == undefined && (
                        <p className="error-message" style={{ color: "red" }}>
                          Please select the Proficiency Level
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={submitLanguage}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessSkillAndExpertise;
