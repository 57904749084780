import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changeSharePostInput,
  clearPostUploadResponse,
  clearPostUpdateResponse,
  changePostDetails,
} from "../HomeActions";
import { IMAGE_BASE_URL } from "../../../library/urls";
import {
  postUpdateApi,
  postUploadApi,
} from "../../../library/api/HomeApiService";
import { replceMultiStringWithSIngle } from "../../../library/utilities/functions";
import { useLocation } from "react-router";

import Microlink from "@microlink/react";

let links = [],
  allBaseImages = [],
  files = [];

const SharePost = ({ HomeState, profileData, addToast }) => {
  const [uplodImageData, setuplodImageData] = useState(0);
  const [mediaArray, setMediaArray] = useState([]);
  const [error, setError] = useState(false);
  const [fileId, setFileId] = useState([]);
  const dispatch = useDispatch();
  const inputFile = useRef();
  const inputVideoFile = useRef();
  const closeEl = useRef();
  let { pathname } = useLocation();
  const profilePostState = useSelector((state) => state.ProfilePostReducer);
  const {
    getProfilePostDetail: { getProfilePost },
  } = profilePostState;
  const {
    SharePostInput: { shareText, mediaFile, mediaUrl, fileVisibility },
    uploadPostApi: { uploadPostStatus, uploadPostLoading, uploadPostResponse },
    updatePostApi: { updatePostStatus, updatePostLoading, updatePostResponse },
    allPostDetail: { allPost },
    updatePost: { postDetail },
  } = HomeState;

  useEffect(() => {
    if (postDetail) {
      if (postDetail.post_id) {
        postDetail.post_data.map((item) => {
          allBaseImages.push(IMAGE_BASE_URL + item.file_name);
          let obj = {
            imageName: item.file_name,
            url: IMAGE_BASE_URL + item.file_name,
          };
          setMediaArray([...mediaArray, obj]);
        });
      }
    }
  }, [postDetail]);

  //this function is used when change the text and links exist then links push in array.
  const handleChange = (e) => {
    links = [];
    dispatch(changeSharePostInput({ shareText: e.target.value }));
    e.target.value.trim().replace(/\s\s+/g, " ");
    let splStr = e.target.value.split(" ");

    for (let i = 0; i < splStr.length; i++) {
      if (
        splStr[i].match(
          "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
        )
      ) {
        links.push(splStr[i]);
      }
    }
    setError(false);
  };

  useEffect(() => {
    if (!!uploadPostStatus && uploadPostStatus == "200") {
      closeEl.current.click();
      if (!!uploadPostResponse) {
        if (pathname == "/newsfeeds") {
          allPost.unshift({
            ...uploadPostResponse,
            ...{ user_id: profileData.id },
          });
        } else {
          getProfilePost.unshift({
            ...uploadPostResponse,
            ...{ user_id: profileData.id },
          });
        }
      }
      dispatch(clearPostUploadResponse());
    }
  }, [uploadPostStatus]);

  // status of update post //
  useEffect(() => {
    if (!!updatePostStatus && updatePostStatus == "200") {
      closeEl.current.click();
      setFileId([]);
      if (!!updatePostResponse) {
        if (pathname == "/newsfeeds") {
          for (let i = 0; i < allPost.length; i++) {
            if (allPost[i].post_id === updatePostResponse.post_id) {
              allPost.splice(i, 1);
              allPost.splice(i, 0, {
                ...updatePostResponse,
                ...{ user_id: profileData.id },
              });
            }
          }
        } else {
          for (let i = 0; i < getProfilePost.length; i++) {
            if (getProfilePost[i].post_id === updatePostResponse.post_id) {
              getProfilePost.splice(i, 1);
              getProfilePost.splice(i, 0, {
                ...updatePostResponse,
                ...{ user_id: profileData.id },
              });
            }
          }
        }
      }
      dispatch(clearPostUpdateResponse());
      dispatch(changePostDetails({ postDetail: {} }));
    }
  }, [updatePostStatus]);

  //this function is used when change the file photo
  const handleFileChange = (e) => {
    var dat = [...e.target.files];
    for (var i = 0; i < dat.length; i++) {
      const data = e.target.files[i];
      if (!!data) {
        const fileName = data.name.split(".");
        const imageFormat = fileName[fileName.length - 1];
        if (
          imageFormat === "png" ||
          imageFormat === "jpg" ||
          imageFormat === "jpeg" ||
          imageFormat === "PNG" ||
          imageFormat === "JPG" ||
          imageFormat === "JPEG"
        ) {
          setError(false);
          if (dat.length + mediaUrl.length < 6) {
            files.push(e.target.files[i]);
          } else {
          }

          dispatch(changeSharePostInput({ mediaFile: files }));
          // Pusing inform with base64
          if (dat.length + mediaUrl.length < 6) {
            const reader = new FileReader();
            let obj = {
              imageName: e.target.files[i] && e.target.files[i].name,
            };
            reader.addEventListener("load", () => {
              allBaseImages.push(reader.result);
              (obj.url = reader.result), setMediaArray([...mediaArray, obj]);
              dispatch(changeSharePostInput({ mediaUrl: allBaseImages }));
            });
            reader.readAsDataURL(e.target.files[i]);
          } else {
            setuplodImageData(1);
          }
        } else {
          addToast("Only .png, .jpg, .jpeg image formats supported.", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
        }
      }
    }
  };
  useEffect(() => {
    if (uplodImageData === 1) {
      addToast("You can upload maximum 5 items", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      setuplodImageData(0);
    }
  }, [uplodImageData]);

  //this function is used when change the video which is shared by user
  const handleVideoChange = (e) => {
    var data = e.target.files[0];
    if (!!data) {
      const fileName = data.name.split(".");
      const imageFormat = fileName[fileName.length - 1];
      if (
        imageFormat === "mp4" ||
        imageFormat === "MP4" ||
        imageFormat === "mov" ||
        imageFormat === "MOV" ||
        imageFormat === "webm" ||
        imageFormat === "WEBM"
      ) {
        if (data.size < 5000024) {
          files.push(...e.target.files);
          setError(false);
          dispatch(changeSharePostInput({ mediaFile: files }));
          const reader = new FileReader();
          let obj = {
            imageName: e.target.files[0] && e.target.files[0].name,
          };
          reader.addEventListener("load", () => {
            allBaseImages.push(reader.result);
            (obj.url = reader.result), setMediaArray([...mediaArray, obj]);
            dispatch(changeSharePostInput({ mediaUrl: allBaseImages }));
          });
          reader.readAsDataURL(e.target.files[0]);
        } else {
          addToast("Maximum upload video limit is 5 mb", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
        }
      } else {
        addToast("The video formal is not supported", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      }
    }
  };

  const openPictureFolder = () => {
    if (mediaUrl.length < 5) {
      inputFile.current.click();
    } else {
      addToast("You can upload maximum 5 items", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
    }
    setError(false);
  };
  const openVideoFolder = () => {
    if (mediaUrl.length < 5) {
      inputVideoFile.current.click();
    } else {
      addToast("You can upload maximum 5 items", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
    }
  };

  //this function is used when close the share feed model.
  const handleClose = () => {
    let clearInput = {
      shareText: "",
      mediaFile: [],
      mediaUrl: [],
    };
    links = [];
    dispatch(
      changeSharePostInput({ ...HomeState.SharePostInput, ...clearInput })
    );
    dispatch(changePostDetails({ postDetail: {} }));
    setMediaArray([]);

    (allBaseImages = []), (files = []);
    setError(false);
  };

  // remove file//
  const removeFile = (index, url) => {
    if (!!postDetail.post_data) {
      let details = postDetail.post_data;
      for (let i = 0; i < details.length; i++) {
        if (details[i].file_name === url.split(IMAGE_BASE_URL)[1]) {
          setFileId([...fileId, details[i].file_id]);
        }
      }
      mediaUrl.splice(index, 1);
      allBaseImages = mediaUrl;
      dispatch(changeSharePostInput({ mediaUrl: mediaUrl }));
      for (let i = 0; i < mediaArray.length; i++) {
        for (let j = 0; j < mediaFile.length; j++) {
          if (
            mediaArray[i].imageName === mediaFile[j].name &&
            mediaArray[i].url === url
          ) {
            mediaFile.splice(j, 1);
            dispatch(changeSharePostInput({ mediaFile: mediaFile }));
          }
        }
      }
    } else {
      mediaUrl.splice(index, 1);
      dispatch(changeSharePostInput({ mediaUrl: mediaUrl }));
      for (let i in mediaFile) {
        if (i == index) {
          mediaFile.splice(i, 1);
          dispatch(changeSharePostInput({ mediaFile: mediaFile }));
        }
      }
    }
  };

  //this function is used to share the image and video in the post
  const handleSharePost = () => {

    if (mediaFile.length > 0 || shareText.trim() != "") {
      let picture = [];
      let videos = [];
      for (let i in mediaFile) {
        const fileName = mediaFile[i].name.split(".");
        const imageFormat = fileName[fileName.length - 1];
        if (
          imageFormat === "mp4" ||
          imageFormat === "MP4" ||
          imageFormat === "mov" ||
          imageFormat === "MOV" ||
          imageFormat === "webm" ||
          imageFormat === "WEBM"
        ) {
          videos.push(mediaFile[i]);
        } else {
          picture.push(mediaFile[i]);
        }
      }
      const bodyParameters = new FormData();

      picture.forEach(function (image, i) {
        bodyParameters.append("image_" + i, image);
      });
      videos.forEach(function (video, i) {
        bodyParameters.append("video_" + i, video);
      });

      bodyParameters.append("videos", "" + videos.length);
      bodyParameters.append("images", "" + !!picture ? picture.length : "");
      bodyParameters.append("type", "" + 0);

      bodyParameters.append("visibility", "" + fileVisibility);
      bodyParameters.append(
        "text",
        "" + replceMultiStringWithSIngle(shareText) == "" ||
          replceMultiStringWithSIngle(shareText) == " "
          ? ""
          : replceMultiStringWithSIngle(shareText)
      );

      dispatch(postUploadApi(bodyParameters)).then((res) => {
        addToast(res.response.data.message, {
          appearance: "success",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      });
    } else {
      setError(true);
    }
  };

  const handleUpdatePost = () => {
    if (
      mediaFile.length > 0 ||
      !!mediaUrl.length ||
      shareText.trim() != "" ||
      !!links.length
    ) {
      let picture = [];
      let videos = [];
      for (let i in mediaFile) {
        const fileName = mediaFile[i].name.split(".");
        const imageFormat = fileName[fileName.length - 1];
        if (
          imageFormat === "mp4" ||
          imageFormat === "MP4" ||
          imageFormat === "mov" ||
          imageFormat === "MOV" ||
          imageFormat === "webm" ||
          imageFormat === "WEBM"

        ) {
          videos.push(mediaFile[i]);
        } else {
          picture.push(mediaFile[i]);
        }
      }
      const bodyParameters = new FormData();
      if (postDetail) {
        fileId.forEach(function (file, i) {
          bodyParameters.append("file_id[]", file);
        });
        bodyParameters.append("id", postDetail.post_id);
      }
      picture.forEach(function (image, i) {
        bodyParameters.append("images[]", image);
      });
      videos.forEach(function (video, i) {
        bodyParameters.append("videos[]", video);
      });
      bodyParameters.append("videos", "" + videos.length);
      bodyParameters.append("images", "" + !!picture ? picture.length : "");
      bodyParameters.append("type", "" + 0);
      bodyParameters.append("visibility", "" + fileVisibility);
      bodyParameters.append(
        "text",
        "" + replceMultiStringWithSIngle(shareText) == "" ||
          replceMultiStringWithSIngle(shareText) == " "
          ? ""
          : replceMultiStringWithSIngle(shareText)
      );
      dispatch(postUpdateApi(bodyParameters));
    } else {
      setError(true);
    }
  };
  return (
    <>
      {/* Modal */}
      <div
        className="modal fade"
        id="feeds-modal"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon"
                />
                {postDetail && postDetail.post_id
                  ? "Update Post"
                  : "Share a Post"}
              </h5>
              <button
                type="button"
                onClick={handleClose}
                ref={closeEl}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="feeds-modal__top d-flex mb-3">
                <div className="feeds-post__user mr-3">
                  {!!profileData.is_individual ? (
                    <img
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/member-default.svg")
                      }
                      src={
                        !!profileData
                          ? IMAGE_BASE_URL + profileData.profile_image
                          : "/assets/images/member-default.svg"
                      }
                      alt="user"
                    />
                  ) : (
                    <img
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/business_logo.svg")
                      }
                      src={
                        !!profileData
                          ? IMAGE_BASE_URL + profileData.business_image
                          : "/assets/images/business_logo.svg"
                      }
                      alt="logo"
                    />
                  )}{" "}
                </div>
                <div className="feeds-post__user__heading">
                  <h6 className="text-capitalize">
                    {!!profileData.is_individual && !!profileData
                      ? profileData.name
                      : profileData.business_name}
                  </h6>
                  <select
                    name="visibilty"
                    className="form-control"
                    value={fileVisibility || ""}
                    onChange={(e) =>
                      changeSharePostInput({ fileVisibility: e.target.value })
                    }
                  >
                    <option value={0}>Public</option>
                  </select>
                </div>
              </div>
              <textarea
                name="feeds"
                id="feeds-content"
                onChange={handleChange}
                value={shareText || ""}
                cols={30}
                rows={10}
                placeholder="What do you want to share with your audience?"
                className="form-control"
              />
              <div className="grid">
                {mediaUrl.length > 0 &&
                  mediaUrl.map((data, index) => (
                    <div className="media-box" id={index}>
                      <a
                        href="javascript:;"
                        className="rejected"
                        onClick={() => removeFile(index, data)}
                      >
                        <i className="ri-close-fill"></i>
                      </a>
                      {data.match("data:video/mp4") || data.match("data:video/webm") || data.includes(".mp4") ? (
                        <video src={data} />
                      ) : (
                        <img src={data} alt="kjfh" />
                      )}
                    </div>
                  ))}
              </div>
            </div>

            {links.map((data) => {
              return (
                <Microlink
                  url={data}
                  media="video"
                  size="large"
                  style={{ maxWidth: "90%" }}
                  autoPlay
                />
              );
            })}
            {error && (
              <p
                className="error-message"
                style={{ color: "red", paddingBottom: 5, paddingLeft: 23 }}
              >
                Select atleast one from the given below
              </p>
            )}
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-md-9 px-0">
                  <div className="feeds-post__type d-flex justify-content-start w-100">
                    <div className="feeds-post__category">
                      <a href="javascript:;" onClick={openPictureFolder}>
                        <i className="ri-image-fill pictures-option" /> Pictures
                        <input
                          id="uploadfile"
                          type="file"
                          className="d-none"
                          ref={inputFile}
                          onChange={handleFileChange}
                          multiple={true}
                          accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                        />
                      </a>
                    </div>
                    <div className="feeds-post__category">
                      {" "}
                      <a href="javascript:;" onClick={openVideoFolder}>
                        <i className="ri-movie-line video-option" /> Videos
                        <input
                          type="file"
                          name="file"
                          value={""}
                          ref={inputVideoFile}
                          id="upload_fle"
                          onChange={handleVideoChange}
                          className="d-none"
                          accept=" video/*"
                        />
                      </a>
                    </div>
                    <div className="feeds-post__category">
                      {" "}
                      <a href="javascript:;">
                        <i className="ri-links-line link-option"></i> Links
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 text-right btn-spacer">
                  <a
                    href="javascript:;"
                    onClick={
                      postDetail && postDetail.post_id
                        ? handleUpdatePost
                        : handleSharePost
                    }
                    className={
                      !!uploadPostLoading
                        ? "btn btn-primary btn-sm disabled btn-full"
                        : "btn btn-primary btn-sm btn-full"
                    }
                  >
                    {postDetail && postDetail.post_id
                      ? "Update Post"
                      : "Share "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SharePost;
