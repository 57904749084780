import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getAboutusContentApi,
  getAllBlogsApi,
  getFooterContentApi,
  getRecentBlogApi,
  getWorkEarnContent,
} from "./landingContentAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Navbar = () => {
  const authState = useSelector((state) => state.Authreducer);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const classHiddenVisible = (val, opacity) => {
    const hover = document.querySelector(".about .about-dropdown");

    hover.style.visibility = val;
    hover.style.opacity = opacity;
  };

  const handleHover = (data) => {
    if (data == "enter") {
      classHiddenVisible("visible", 1);
    } else if (data == "leave") {
      classHiddenVisible("hidden", 0);
    }
    // else {
    //   addRemoveClass("addClass")
    // }
  };
  // FOR NAVBAR HIDE AND SHOW ON SCROLL
  useEffect(() => {
    let mobMenu = document.querySelector(".navbar-toggler");
    let navtag = document.getElementById("navbarNavAltMarkup");
    mobMenu.addEventListener("click", function () {
      mobMenu.classList.toggle("toggle-menu");
      navtag.classList.toggle("toggle-menubar");
      document.body.classList.toggle("overflow-hidden");
      if (navigator.platform === "iPhone") {
        let userAgent = navigator.userAgent;
        if (userAgent.match(/chrome|chromium|crios/i)) {
          document.querySelector(".mob-option").style.bottom = 120 + "px";
        } else {
          document.querySelector(".mob-option").style.bottom = 100 + "px";
        }
      } else {
        document.querySelector(".mob-option").style.bottom = 70 + "px";
      }
    });

    var oldscroll = window.scrollY;
    const x = window.addEventListener("scroll", function (event) {
      var header = document.querySelector(".main-header");
      if (window.location.pathname == "/" || "/blog" || "/our-story") {
        if (window.pageYOffset > 90) {
          if (oldscroll < window.scrollY) {
            header?.classList.add("headerhide");
          } else {
            header?.classList.remove("headerhide");
          }
        } else {
          header?.classList.remove("headerhide");
        }
        if (header) {
          if (oldscroll > 90) {
            header.style.background = "#fff";
          } else {
            header.style.background = "transparent";
          }
          oldscroll = window.scrollY;
        }
      }
    });
    return () => {
      window.removeEventListener("scroll", x);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllBlogsApi());
    dispatch(getRecentBlogApi());
    dispatch(getAboutusContentApi());
    dispatch(getWorkEarnContent());
  }, []);
  // FOR ACTIVE LINK
  const activePath = window.location.pathname;
  const handleMenuCollapse = () => {
    let mobMenu = document.querySelector(".navbar-toggler");
    let navtag = document.getElementById("navbarNavAltMarkup");
    mobMenu.classList.remove("toggle-menu");
    navtag.classList.remove("toggle-menubar");
    document.body.classList.remove("overflow-hidden");
  };

  return (
    <div>
      <header
        className={`${
          activePath == "/blog"
            ? "main-header py-3  bg-white "
            : "main-header py-3"
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-md p-0">
                <Link className="navbar-brand" to="/">
                  <img
                    src="/assets/images/pools-logo.svg"
                    alt="logo"
                    className="web-logo"
                  />
                </Link>
                <button
                  className="navbar-toggler p-0 border-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarNavAltMarkup"
                >
                  <div className="navbar-nav  ml-auto pr-4">
                    <Link
                      className={`nav-link ${
                        activePath == "/" ? "active" : null
                      }`}
                      aria-current="page"
                      to="/"
                    >
                      <span onClick={handleMenuCollapse}>Home</span>
                    </Link>
              

                    <Link
                      activeclass="active"
                      to="/service-provider"
                      className={`nav-link ${
                        activePath == "/service-provider"
                          ? "active"
                          : null
                      }`}
                    >
                      <span onClick={handleMenuCollapse}>
                        Find a Service Provider
                      </span>
                    </Link>

                    <Link
                      activeclass="active"
                      to="/work-and-earn"
                      className={`nav-link ${
                        activePath == "/work-and-earn" ? "active" : null
                      }`}
                    >
                      <span onClick={handleMenuCollapse}>Work and Earn</span>
                    </Link>

                    <Link
                      className={`position-relative   nav-link about ${
                        activePath == "/our-story" ? "active" : null
                      }`}
                      onMouseEnter={() => handleHover("enter")}
                      onMouseLeave={() => handleHover("leave")}
                    >
                      <span className="about-us" data-item="About us">
                        About Us
                        <i className="ri-arrow-down-s-line align-middle" />
                      </span>

                      <div className="about-dropdown nav-dropdown">
                        <ul className="list-unstyled mb-0">
                          <li>
                            <Link
                              to="/our-story"
                              className="link-color"
                              onClick={() => handleHover("leave")}
                            >
                              Our Story
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/blog"
                              className="link-color"
                              onClick={() => handleHover("leave")}
                            >
                              Discover and Explore
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </div>

                  <div className="mob-option d-block d-md-none">
                    <Link
                      className={`nav-link btn btn-primary mb-3 ${
                        activePath == "/login" ? "active" : null
                      }`}
                      to="/login"
                    >
                      <span data-item="About us" onClick={handleMenuCollapse}>
                        Login
                      </span>
                    </Link>
                    <Link className="nav-link btn btn-outline" to="/signup">
                      <span data-item="About us" onClick={handleMenuCollapse}>
                        Sign Up
                      </span>
                    </Link>
                  </div>
                </div>

                <div className="header__account pl-4 d-none d-md-block">
                  <Link to="/login">Login</Link>
                  <Link
                    to="/signup"
                    className="btn btn-primary"
                    onClick={handleMenuCollapse}
                  >
                    Sign Up
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
