import {
  CHECK_IS_POST_CLICKED,
  CLEAR_CANCEL_REQUEST_RESPONSE,
  CLEAR_SEARCH_PROFILE_RESPONSE,
  CLEAR_UNFRIEND_RESPONSE,
  CHANGE_FRIEND_DETAIL,
} from "./SearchProfileConstant";

// this function is used to clear search profile api response
const clearProfileResponse = (newState) => {
  return { type: CLEAR_SEARCH_PROFILE_RESPONSE, newState };
};
//this function is used to clear cancel request api response
const clearCancelRequestResponse = (newState) => {
  return { type: CLEAR_CANCEL_REQUEST_RESPONSE, newState };
};
//this function is used to clear unfriend api response
const clearUnfriendResponse = (newState) => {
  return { type: CLEAR_UNFRIEND_RESPONSE, newState };
};
//this function is used to check user come from home page.
const checkIsPostClicked = (newState) => {
  return { type: CHECK_IS_POST_CLICKED, newState };
};

//this function is used to change details of particular friend
const changeFriendDetail = (newState) => {
  return { type: CHANGE_FRIEND_DETAIL, newState };
};
export {
  clearProfileResponse,
  clearCancelRequestResponse,
  clearUnfriendResponse,
  checkIsPostClicked,
  changeFriendDetail,
};
