import { GET_API, POST_API } from "../../main/fetch";
import {
  GET_POST_FAILURE,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  INVITE_DATA_FAILURE,
  INVITE_DATA_REQUEST,
  INVITE_DATA_SUCCESS,
  LIKE_DISLIKE_POST_DATA_FAILURE,
  LIKE_DISLIKE_POST_DATA_REQUEST,
  LIKE_DISLIKE_POST_DATA_SUCCESS,
  POST_COMMENT_DATA_FAILURE,
  POST_COMMENT_DATA_REQUEST,
  POST_COMMENT_DATA_SUCCESS,
  POST_COMMENT_LIST_DATA_FAILURE,
  POST_COMMENT_LIST_DATA_REQUEST,
  POST_COMMENT_LIST_DATA_SUCCESS,
  POST_COMMENT_REPLY_DATA_FAILURE,
  POST_COMMENT_REPLY_DATA_REQUEST,
  POST_COMMENT_REPLY_DATA_SUCCESS,
  POST_COMMENT_REPLY_LIST_DATA_FAILURE,
  POST_COMMENT_REPLY_LIST_DATA_REQUEST,
  POST_COMMENT_REPLY_LIST_DATA_SUCCESS,
  POST_LIKE_LIST_DATA_FAILURE,
  POST_LIKE_LIST_DATA_REQUEST,
  POST_LIKE_LIST_DATA_SUCCESS,
  POST_REACTION_DATA_FAILURE,
  POST_REACTION_DATA_REQUEST,
  POST_REACTION_DATA_SUCCESS,
  POST_REACTION_LIST_DATA_FAILURE,
  POST_REACTION_LIST_DATA_REQUEST,
  POST_REACTION_LIST_DATA_SUCCESS,
  POST_SHARE_DATA_FAILURE,
  POST_SHARE_DATA_REQUEST,
  POST_SHARE_DATA_SUCCESS,
  POST_SHARE_LIST_DATA_FAILURE,
  POST_SHARE_LIST_DATA_REQUEST,
  POST_SHARE_LIST_DATA_SUCCESS,
  POST_UPLOAD_FAILURE,
  POST_UPLOAD_REQUEST,
  POST_UPLOAD_SUCCESS,
  POST_UPDATE_FAILURE,
  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  POST_VIEW_DATA_FAILURE,
  POST_VIEW_DATA_REQUEST,
  POST_VIEW_DATA_SUCCESS,
  POST_VIEW_LIST_DATA_FAILURE,
  POST_VIEW_LIST_DATA_REQUEST,
  POST_VIEW_LIST_DATA_SUCCESS,
  SINGLE_LIKE_LIST_DATA_FAILURE,
  SINGLE_LIKE_LIST_DATA_REQUEST,
  SINGLE_LIKE_LIST_DATA_SUCCESS,
  SINGLE_POST_CMT_REPLY_DATA_FAILURE,
  SINGLE_POST_CMT_REPLY_DATA_REQUEST,
  SINGLE_POST_CMT_REPLY_DATA_SUCCESS,
  SINGLE_POST_CMT_REPLY_LIST_DATA_FAILURE,
  SINGLE_POST_CMT_REPLY_LIST_DATA_REQUEST,
  SINGLE_POST_CMT_REPLY_LIST_DATA_SUCCESS,
  SINGLE_POST_COMMENT_DATA_FAILURE,
  SINGLE_POST_COMMENT_DATA_REQUEST,
  SINGLE_POST_COMMENT_DATA_SUCCESS,
  SINGLE_POST_COMMENT_LIST_DATA_FAILURE,
  SINGLE_POST_COMMENT_LIST_DATA_REQUEST,
  SINGLE_POST_COMMENT_LIST_DATA_SUCCESS,
  SINGLE_POST_LIKE_DATA_FAILURE,
  SINGLE_POST_LIKE_DATA_REQUEST,
  SINGLE_POST_LIKE_DATA_SUCCESS,
  SINGLE_POST_REACTION_DATA_FAILURE,
  SINGLE_POST_REACTION_DATA_REQUEST,
  SINGLE_POST_REACTION_DATA_SUCCESS,
  SINGLE_POST_SHARE_DATA_FAILURE,
  SINGLE_POST_SHARE_DATA_REQUEST,
  SINGLE_POST_SHARE_DATA_SUCCESS,
  SINGLE_POST_SHARE_LIST_DATA_FAILURE,
  SINGLE_POST_SHARE_LIST_DATA_REQUEST,
  SINGLE_POST_SHARE_LIST_DATA_SUCCESS,
  SINGLE_POST_VIEW_DATA_FAILURE,
  SINGLE_POST_VIEW_DATA_REQUEST,
  SINGLE_POST_VIEW_DATA_SUCCESS,
  SINGLE_POST_VIEW_LIST_DATA_FAILURE,
  SINGLE_POST_VIEW_LIST_DATA_REQUEST,
  SINGLE_POST_VIEW_LIST_DATA_SUCCESS,
  SINGLE_REACTION_LIST_DATA_FAILURE,
  SINGLE_REACTION_LIST_DATA_REQUEST,
  SINGLE_REACTION_LIST_DATA_SUCCESS,
  GET_PROMOTIONAL_REQUEST,
  GET_PROMOTIONAL_SUCCESS,
  GET_PROMOTIONAL_FAIULRE,
} from "../../modules/Home/HomeConstants";
import {
  COMMENT_LIST_API,
  GET_POST_API,
  INVITE_DATA_API,
  LIKE_DISLIKE_POST_API,
  POST_COMMENT_API,
  POST_COMMENT_REPLY_API,
  POST_COMMENT_REPLY_LIST_API,
  POST_LIKE_LIST_API,
  POST_REACTION_API,
  POST_REACTION_LIST_API,
  POST_SHARE_API,
  POST_SHARE_LIST_API,
  POST_UPLOAD_API,
  POST_UPDATE_API,
  POST_VIEW_API,
  POST_VIEW_LIST_API,
  SINGLE_POST_COMMENT_API,
  SINGLE_POST_COMMENT_LIST_API,
  SINGLE_POST_COMMENT_REPLY_API,
  SINGLE_POST_COMMENT_REPLY_LIST_API,
  SINGLE_POST_LIKE_DISLIKE_API,
  SINGLE_POST_LIKE_LIST_API,
  SINGLE_POST_REACTION_API,
  SINGLE_POST_REACTION_LIST_API,
  SINGLE_POST_SHARE_API,
  SINGLE_POST_SHARE_LIST_API,
  SINGLE_POST_VIEW_API,
  SINGLE_POST_VIEW_LIST_API,
  GET_PROMOTIONAL_HUB,
} from "../urls";

//this function is used to send invitation
const inviteApi = (body) => {
  return {
    [POST_API]: {
      endpoint: INVITE_DATA_API,
      types: [INVITE_DATA_REQUEST, INVITE_DATA_SUCCESS, INVITE_DATA_FAILURE],
      body,
    },
  };
};
//this function is used to upload  the post
const postUploadApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_UPLOAD_API,
      types: [POST_UPLOAD_REQUEST, POST_UPLOAD_SUCCESS, POST_UPLOAD_FAILURE],
      body,
      is_form_data: true,
    },
  };
};

const postUpdateApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_UPDATE_API,
      types: [POST_UPDATE_REQUEST, POST_UPDATE_SUCCESS, POST_UPDATE_FAILURE],
      body,
      is_form_data: true,
    },
  };
};

const getPromotionalHub = ()=>
{
 
return {
  [GET_API]:{
    endpoint:GET_PROMOTIONAL_HUB,
    types:[GET_PROMOTIONAL_REQUEST,GET_PROMOTIONAL_SUCCESS,GET_PROMOTIONAL_FAIULRE],
  }
}
}

//this function is used to fetch all post
const getPostApi = (page) => {
  return {
    [GET_API]: {
      endpoint: GET_POST_API + "?page=" + page,
      types: [GET_POST_REQUEST, GET_POST_SUCCESS, GET_POST_FAILURE],
    },
  };
};
//this function is used to like dislike post
const likeDislikeApi = (body) => {
  return {
    [POST_API]: {
      endpoint: LIKE_DISLIKE_POST_API,
      types: [
        LIKE_DISLIKE_POST_DATA_REQUEST,
        LIKE_DISLIKE_POST_DATA_SUCCESS,
        LIKE_DISLIKE_POST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to like dislike particular file on post
const singlePostLikeDislikeApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_LIKE_DISLIKE_API,
      types: [
        SINGLE_POST_LIKE_DATA_REQUEST,
        SINGLE_POST_LIKE_DATA_SUCCESS,
        SINGLE_POST_LIKE_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to react on post
const postReactionApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_REACTION_API,
      types: [
        POST_REACTION_DATA_REQUEST,
        POST_REACTION_DATA_SUCCESS,
        POST_REACTION_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to react on particular file in post
const singlePostReactionApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_REACTION_API,
      types: [
        SINGLE_POST_REACTION_DATA_REQUEST,
        SINGLE_POST_REACTION_DATA_SUCCESS,
        SINGLE_POST_REACTION_DATA_FAILURE,
      ],
      body,
    },
  };
};
// this function is used to fetch post like list
const postLikeListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_LIKE_LIST_API,
      types: [
        POST_LIKE_LIST_DATA_REQUEST,
        POST_LIKE_LIST_DATA_SUCCESS,
        POST_LIKE_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch particular file like list in post
const singlePostLikeListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_LIKE_LIST_API,
      types: [
        SINGLE_LIKE_LIST_DATA_REQUEST,
        SINGLE_LIKE_LIST_DATA_SUCCESS,
        SINGLE_LIKE_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch reaction list
const postReactListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_REACTION_LIST_API,
      types: [
        POST_REACTION_LIST_DATA_REQUEST,
        POST_REACTION_LIST_DATA_SUCCESS,
        POST_REACTION_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch particular file reaction list
const singlePostReactListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_REACTION_LIST_API,
      types: [
        SINGLE_REACTION_LIST_DATA_REQUEST,
        SINGLE_REACTION_LIST_DATA_SUCCESS,
        SINGLE_REACTION_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch post comment list
const postCommentListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: COMMENT_LIST_API,
      types: [
        POST_COMMENT_LIST_DATA_REQUEST,
        POST_COMMENT_LIST_DATA_SUCCESS,
        POST_COMMENT_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to add comment on post
const postCommentApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_COMMENT_API,
      types: [
        POST_COMMENT_DATA_REQUEST,
        POST_COMMENT_DATA_SUCCESS,
        POST_COMMENT_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to reply the comment on post
const postCommentReplyApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_COMMENT_REPLY_API,
      types: [
        POST_COMMENT_REPLY_DATA_REQUEST,
        POST_COMMENT_REPLY_DATA_SUCCESS,
        POST_COMMENT_REPLY_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch reply comment list
const postCommentReplyListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_COMMENT_REPLY_LIST_API,
      types: [
        POST_COMMENT_REPLY_LIST_DATA_REQUEST,
        POST_COMMENT_REPLY_LIST_DATA_SUCCESS,
        POST_COMMENT_REPLY_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to comment on particular file
const singlePostCommentApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_COMMENT_API,
      types: [
        SINGLE_POST_COMMENT_DATA_REQUEST,
        SINGLE_POST_COMMENT_DATA_SUCCESS,
        SINGLE_POST_COMMENT_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch particular post comment list
const singlePostCommentListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_COMMENT_LIST_API,
      types: [
        SINGLE_POST_COMMENT_LIST_DATA_REQUEST,
        SINGLE_POST_COMMENT_LIST_DATA_SUCCESS,
        SINGLE_POST_COMMENT_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to reply the comment on particular file
const singlePostCommentReplyApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_COMMENT_REPLY_API,
      types: [
        SINGLE_POST_CMT_REPLY_DATA_REQUEST,
        SINGLE_POST_CMT_REPLY_DATA_SUCCESS,
        SINGLE_POST_CMT_REPLY_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch the comment reply list on particular post
const singlePostCommentReplyListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_COMMENT_REPLY_LIST_API,
      types: [
        SINGLE_POST_CMT_REPLY_LIST_DATA_REQUEST,
        SINGLE_POST_CMT_REPLY_LIST_DATA_SUCCESS,
        SINGLE_POST_CMT_REPLY_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to view the post
const postViewApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_VIEW_API,
      types: [
        POST_VIEW_DATA_REQUEST,
        POST_VIEW_DATA_SUCCESS,
        POST_VIEW_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to view the particular file
const singlePostViewApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_VIEW_API,
      types: [
        SINGLE_POST_VIEW_DATA_REQUEST,
        SINGLE_POST_VIEW_DATA_SUCCESS,
        SINGLE_POST_VIEW_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch the view list on post
const postViewListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_VIEW_LIST_API,
      types: [
        POST_VIEW_LIST_DATA_REQUEST,
        POST_VIEW_LIST_DATA_SUCCESS,
        POST_VIEW_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch the view list in particular file
const singlePostViewListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_VIEW_LIST_API,
      types: [
        SINGLE_POST_VIEW_LIST_DATA_REQUEST,
        SINGLE_POST_VIEW_LIST_DATA_SUCCESS,
        SINGLE_POST_VIEW_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to share the post
const postFeedShareApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_SHARE_API,
      types: [
        POST_SHARE_DATA_REQUEST,
        POST_SHARE_DATA_SUCCESS,
        POST_SHARE_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to share the particular file
const singlePostShareApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_SHARE_API,
      types: [
        SINGLE_POST_SHARE_DATA_REQUEST,
        SINGLE_POST_SHARE_DATA_SUCCESS,
        SINGLE_POST_SHARE_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch post feed share list
const postFeedShareListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: POST_SHARE_LIST_API,
      types: [
        POST_SHARE_LIST_DATA_REQUEST,
        POST_SHARE_LIST_DATA_SUCCESS,
        POST_SHARE_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to fetch file feed share list
const singlePostFeedShareListApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SINGLE_POST_SHARE_LIST_API,
      types: [
        SINGLE_POST_SHARE_LIST_DATA_REQUEST,
        SINGLE_POST_SHARE_LIST_DATA_SUCCESS,
        SINGLE_POST_SHARE_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
export {
  inviteApi,
  postUploadApi,
  postUpdateApi,
  getPostApi,
  getPromotionalHub,
  likeDislikeApi,
  singlePostLikeDislikeApi,
  postReactionApi,
  postCommentListApi,
  singlePostReactionApi,
  postLikeListApi,
  singlePostLikeListApi,
  postReactListApi,
  singlePostReactListApi,
  postCommentApi,
  postCommentReplyApi,
  postCommentReplyListApi,
  singlePostCommentApi,
  singlePostCommentListApi,
  singlePostCommentReplyApi,
  singlePostCommentReplyListApi,
  singlePostViewApi,
  postViewApi,
  postViewListApi,
  singlePostViewListApi,
  postFeedShareApi,
  singlePostShareApi,
  postFeedShareListApi,
  singlePostFeedShareListApi,
};
