const CHANGE_SEARCH_NAME_INPUT = "CHANGE_SEARCH_NAME_INPUT";

const CHNAGE_SEARCH_MEMBERS_NAME_INPUT = "CHNAGE_SEARCH_MEMBERS_NAME_INPUT";

const ONSCREEN_NOTIFICATION_DATA_REQUEST = "ONSCREEN_NOTIFICATION_DATA_REQUEST";
const ONSCREEN_NOTIFICATION_DATA_SUCCESS = "ONSCREEN_NOTIFICATION_DATA_SUCCESS";
const ONSCREEN_NOTIFICATION_DATA_FAILURE = "ONSCREEN_NOTIFICATION_DATA_FAILURE";

const READALL_NOTIFICATION_DATA_REQUEST = "READALL_NOTIFICATION_DATA_REQUEST";
const READALL_NOTIFICATION_DATA_SUCCESS = "READALL_NOTIFICATION_DATA_SUCCESS";
const READALL_NOTIFICATION_DATA_FAILURE = "READALL_NOTIFICATION_DATA_FAILURE";

const LOGOUT_API_DATA_REQUEST = "LOGOUT_API_DATA_REQUEST";
const LOGOUT_API_DATA_SUCCESS = "LOGOUT_API_DATA_SUCCESS";
const LOGOUT_API_DATA_FAILURE = "LOGOUT_API_DATA_FAILURE";

const CHANGE_UNREAD_FRIEND_MESSAGE = "CHANGE_UNREAD_FRIEND_MESSAGE";
const CHANGE_UNREAD_GROUP_MESSAGE = "CHANGE_UNREAD_GROUP_MESSAGE";

const UNREAD_USER_MESSAGE="UNREAD_USER_MESSAGE"
export {
  CHANGE_SEARCH_NAME_INPUT,
  ONSCREEN_NOTIFICATION_DATA_REQUEST,
  ONSCREEN_NOTIFICATION_DATA_SUCCESS,
  ONSCREEN_NOTIFICATION_DATA_FAILURE,
  READALL_NOTIFICATION_DATA_REQUEST,
  READALL_NOTIFICATION_DATA_SUCCESS,
  READALL_NOTIFICATION_DATA_FAILURE,
  LOGOUT_API_DATA_REQUEST,
  LOGOUT_API_DATA_SUCCESS,
  LOGOUT_API_DATA_FAILURE,
  CHANGE_UNREAD_FRIEND_MESSAGE,
  CHANGE_UNREAD_GROUP_MESSAGE,
  CHNAGE_SEARCH_MEMBERS_NAME_INPUT,
  UNREAD_USER_MESSAGE
};
