import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { Input } from "../../library/common/components";
import {
  addDocumentTitle,
  addValidation,
} from "../../library/utilities/functions";
import BusinesSignUp from "./BusinessSignUp";
import {
  changeIsFinalIndividualInput,
  changeIsIndividualInput,
  changeLoginBusinessInput,
  changeLoginIndividualInput,
  clearSignupApiResponse,
  clearSignupPage,
} from "./SignupActions";
import {
  signUpIndividualValidation,
  signUpIndividualValidationh,
  onChangeEmailValidation,
} from "./validation";
import "react-telephone-input/css/default.css";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import Footer from "../../library/common/components/LandingPage/HomeSection/Footer";
import { Link } from "react-router-dom";

let is_clear_data = true;
const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [validatedindividualName, setValidatedindividualName] = useState("");
  const [validatedFirstName, setValidatedFirstName] = useState("");
  const [validatedLastName, setValidatedLastName] = useState("");
  const [check, setCheck] = useState(true);
  const [countryTelNum, setCountryTelNum] = useState("");
  const [IsHaveNum, setIsHaveNum] = useState(false);
  const nameRef = useRef(null);
  const [error, setError] = useState(false);
  const nameElvalidation = useRef(null);
  const lastNameRef = useRef(null);
  const lastnameValidation = useRef(null);
  const emailElvalidation = useRef(null);

  const [isValidphoneValue, setIsValidPhoneValue] = useState(false);
  const { addToast } = useToasts();
  const SignupState = useSelector((state) => state.SignupReducer);

  const {
    signUpIndividual: { individualName, email, first_name, last_name },
  } = SignupState;

  const {
    SignupApiResponse: {
      signupStateSuccess,
      signUpSuccess,
      signupApiStatus,
      signupMessage,
    },
  } = SignupState;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!!signupApiStatus && signupApiStatus == "200") {
      addToast(
        <p>
          <span
            style={{ color: "#000", fontWeight: 700 }}
          >{`${signupMessage}.`}</span>
          <br />
          <br />
          <span>
            {
              "A one-time verification email has been sent to you. Please, confirm your account to log In."
            }
          </span>
        </p>,
        {
          appearance: "success",
          autoDismiss: true,
       
        }
      );
      history.push("/login");
    }
    if (signupApiStatus == "400") {
      addToast(signupMessage, {
        appearance: "error",
        autoDismiss: true,
    
      });
      dispatch(clearSignupApiResponse());
    }
  }, [signupApiStatus]);

  useEffect(() => {
    if (!!signupStateSuccess && signupStateSuccess == 200) {
      addToast(
        signupMessage +
          "." +
          Array(16).fill("\xa0", 0, 16).join(" ") +
          "Email is verified",
        {
          appearance: "success",
          autoDismiss: true,
     
        }
      );
    }

    if (signupStateSuccess == "400") {
      addToast(signupMessage, {
        appearance: "error",
        autoDismiss: true,
    
      });
      dispatch(clearSignupApiResponse());
    }
  }, [signupStateSuccess]);

  useEffect(() => {
    if (!!signUpSuccess && signUpSuccess == 200) {
      addToast(
        signupMessage +
          "." +
          Array(16).fill("\xa0", 0, 16).join(" ") +
          "Email is verified",
        {
          appearance: "success",
          autoDismiss: true,
      
        }
      );
    }

    if (signupStateSuccess == "400") {
      addToast(signupMessage, {
        appearance: "error",
        autoDismiss: true,
     
      });
      dispatch(clearSignupApiResponse());
    }
  }, [signupStateSuccess]);

  useEffect(() => {
    $(".country .react-tel-input")
      .next("p")
      .click(function () {
        $(this).hide();
        const allInputs = document.querySelectorAll("input");
        for (let i in allInputs) {
          if (i == 2) {
            allInputs[i].focus();
          }
        }
      });
    is_clear_data = true;
    addDocumentTitle("Sign Up");
    if (!!nameRef.current) {
      nameRef.current.focus();
    }
    return () => {
      if (!!is_clear_data) {
        dispatch(changeIsFinalIndividualInput({ is_individual: "true" }));
        dispatch(clearSignupPage());
        dispatch(changeIsFinalIndividualInput({ is_individual_final: true }));
        dispatch(clearSignupApiResponse());
      }
    };
  }, []);
  const updateClearData = () => {
    is_clear_data = false;
  };
  //this function is rendered when clicking on the Next button.When all input fields are filled then showed the next step.
  let validation;

  const handleChange = (e, data) => {
    const target = e.target;
    if (target.name === "first_name") {
      validation = {
        is_valid_first_name: { status: false, validation: nameElvalidation },
      };

      validation = signUpIndividualValidation(
        validation,
        SignupState.signUpIndividual
      );
      addValidation(validation);
      if (target.name === "first_name") {
        var firstName =
          target.value.charAt(0).toUpperCase() + target.value.slice(1);
      }
      dispatch(
        changeLoginIndividualInput({
          [target.name]: target.value.replace(/[^a-z]/g, " "),
          [target.name]: firstName,
        })
      );
    } else if (target.name === "last_name") {
      if (target.name === "last_name") {
        var lastName =
          target.value.charAt(0).toUpperCase() + target.value.slice(1);
      }
      dispatch(
        changeLoginIndividualInput({
          [target.name]: target.value.replace(/[^a-z]/g, " "),
          [target.name]: lastName,
        })
      );
    } else {
      dispatch(changeLoginIndividualInput({ [target.name]: target.value }));
      if (email.length > 0) {
        validation = {
          is_valid_email: { status: false, validation: emailElvalidation },
        };
        validation = onChangeEmailValidation(
          validation,
          SignupState.signUpIndividual
        );
        addValidation(validation);
      }
    }
  };

  //this function is used when changing the phone number input field.
  const handleInputChange = (telNumber) => {
    if (telNumber !== undefined) {
      if (isValidPhoneNumber(telNumber) === false) {
        setIsValidPhoneValue(true);
      } else {
        setIsValidPhoneValue(false);
      }

      let phoneNumber = parsePhoneNumber(telNumber);

      if (phoneNumber !== undefined) {
        var { countryCallingCode, nationalNumber } = phoneNumber;
        setCountryTelNum("+" + countryCallingCode + nationalNumber);
        dispatch(changeLoginIndividualInput({ isShowPhone: 1 }));
        dispatch(changeLoginIndividualInput({ cntCode: countryCallingCode }));
        if (telNumber.split("+" + countryCallingCode).length > 1) {
          dispatch(
            changeLoginIndividualInput({
              phoneNo: telNumber.split("+" + countryCallingCode)[1],
            })
          );
        }
      }
    }
  };
  const handleNextSumbit = () => {
    if (countryTelNum?.length !== 0 && isValidphoneValue) {
      setIsHaveNum(false);
      setShowPhoneError(true);
    } else {
      setIsHaveNum(true);
      setShowPhoneError(false);
    }
    if (!!first_name.length && !!email.length) {
      validation = {
        is_valid_first_name: { status: false, validation: nameElvalidation },
        is_valid_email: { status: false, validation: emailElvalidation },
      };
      validation = signUpIndividualValidation(
        validation,
        SignupState.signUpIndividual
      );
      validation = signUpIndividualValidationh(
        validation,
        SignupState.signUpIndividual
      );
      addValidation(validation);
      let { is_valid_first_name, is_valid_email } = validation;
      addValidation(validation);
      if (
        is_valid_first_name.status &&
        is_valid_email.status &&
        isValidphoneValue == false &&
        countryTelNum?.length > 0 &&
        first_name.length < 20 &&
        last_name.length < 20
      ) {
        if (SignupState.is_individual === "true") {
          dispatch(changeLoginBusinessInput({ businessName: "" }));
        }
        dispatch(
          changeIsFinalIndividualInput({
            is_individual_final: SignupState.is_individual,
          })
        );
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (first_name.length > 0) {
      validation = {
        is_valid_first_name: { status: false, validation: nameElvalidation },
      };
      validation = signUpIndividualValidation(
        validation,
        SignupState?.signUpIndividual
      );
      setTimeout(() => addValidation(validation), 1000);
    }
    if (email.length > 0) {
      validation = {
        is_valid_email: { status: false, validation: emailElvalidation },
      };
      validation = onChangeEmailValidation(
        validation,
        SignupState.signUpIndividual
      );
      setTimeout(() => addValidation(validation), 1000);
    }
  }, [email, first_name]);
  useEffect(() => {
    setValidatedFirstName(first_name.slice(0, 20));
    setValidatedLastName(last_name.slice(0, 20));
  }, [first_name, last_name]);
  return (
    <>
      <div className="container">
        <div className="row align-items-center my-3 my-lg-4 pb-5">
          <div className="col-md-6 col-lg-6 mb-4 mb-md-0 d-none d-md-block">
            <div className="login-svg">
              <img width="100%" src="/assets/images/communication.gif" alt="signup" />
            </div>
          </div>

          <div className="col-md-6 col-lg-5 ml-lg-auto">
            <div className="form mt-4 bg-white">
              <div className="form__header d-flex align-items-center mb-4 justify-content-center flex-wrap">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon"
                />
                <h1 className="font-weight-bold mb-0 h3 w-100 text-center">
                  Create your account
                </h1>
              </div>
              {SignupState.is_individual_final == true ? (
                <form>
                  <div className="step-1">
                    <div className="form-group">
                      <div className="custom-radio d-flex">
                        {location.pathname.match("/signup-member") ? (
                          <>
                            <div className="custom-radio__btn mr-3">
                              <Input
                                type="radio"
                                name="account"
                                id="Individual"
                                value={check}
                                checked={
                                  SignupState.is_individual == "true"
                                    ? "checked"
                                    : ""
                                }
                                onChange={(e) => {
                                  setCheck(true);
                                  dispatch(
                                    changeIsIndividualInput({
                                      is_individual: e.target.value,
                                    })
                                  );
                                }}
                              />
                              <label
                                htmlFor="Individual"
                                className="btn btn-primary"
                              >
                                Individual
                              </label>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="custom-radio__btn mr-3">
                              <Input
                                type="radio"
                                name="account"
                                id="Individual"
                                value={check}
                                checked={
                                  SignupState.is_individual == "true"
                                    ? "checked"
                                    : ""
                                }
                                onChange={(e) => {
                                  setCheck(true);
                                  dispatch(
                                    changeIsIndividualInput({
                                      is_individual: e.target.value,
                                    })
                                  );
                                }}
                              />
                              <label
                                htmlFor="Individual"
                                className="btn btn-primary"
                              >
                                Individual
                              </label>
                            </div>

                            <div className="custom-radio__btn">
                              <Input
                                type="radio"
                                name="account"
                                id="Business"
                                value={false}
                                checked={
                                  SignupState.is_individual == "false"
                                    ? "checked"
                                    : ""
                                }
                                onChange={(e) =>
                                  dispatch(
                                    changeIsIndividualInput({
                                      is_individual: e.target.value,
                                    })
                                  )
                                }
                              />
                              <label
                                htmlFor="Business"
                                className="btn btn-primary"
                              >
                                Business
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <div>
                        <label>First Name</label>
                        <Input
                          type="text"
                          name="first_name"
                          ref={nameRef}
                          value={validatedFirstName}
                          onChange={(e) => handleChange(e, "first_name")}
                          className="form-control"
                          placeholder="first name"
                          style={{ "text-transform": "capitalize" }}
                        />
                        {error && !first_name.length && (
                          <p className="error-message">
                            Please enter first name.
                          </p>
                        )}
                        {first_name.length > 20 ? (
                          <p className="error-message">
                            You can not exceed limit above 20 character
                          </p>
                        ) : null}
                        <p
                          style={{ display: "none" }}
                          ref={nameElvalidation}
                          className="error-message"
                        >
                          {/* Please enter first name. */}
                        </p>
                      </div>

                      <div>
                        <label>Last Name</label>
                        <Input
                          type="text"
                          name="last_name"
                          // ref={lastNameRef}
                          value={validatedLastName}
                          onChange={(e) => handleChange(e, "last_name")}
                          className="form-control"
                          placeholder="last name"
                          style={{ "text-transform": "capitalize" }}
                        />

                        {last_name.length > 20 ? (
                          <p className="error-message">
                            You can not exceed limit above 20 character
                          </p>
                        ) : null}
                        <p
                          style={{ display: "none" }}
                          ref={nameElvalidation}
                          className="error-message"
                        >
                          {/* Please enter first name. */}
                        </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Email ID</label>
                      <Input
                        type="email"
                        name="email"
                        value={email}
                        className="form-control"
                        onChange={handleChange}
                        placeholder="Email ID"
                      />
                      {!email.length && error && (
                        <p className="error-message">Please enter email id. </p>
                      )}
                      <p
                        style={{ display: "none" }}
                        ref={emailElvalidation}
                        className="error-message"
                      >
                        Please enter valid email id.{" "}
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <div className="country text-left">
                        <PhoneInput
                          className="signup-input"
                          value={countryTelNum}
                          international={true}
                          onChange={(value) => handleInputChange(value)}
                          placeholder="Phone Number"
                        />

                        <p className="error-message">
                          {countryTelNum?.length == 0 && IsHaveNum
                            ? "Please enter phone number."
                            : null}
                        </p>
                        {showPhoneError && (
                          <p className="error-message">
                            Please enter valid phone number.{" "}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-0 steps-control d-flex justify-content-between flex-wrap">
                      {location.pathname.match("/signup-member") ? (
                        <>
                          <a
                            href="javascript:;"
                            onClick={handleNextSumbit}
                            className="btn btn-primary"
                            id="steps-next"
                          >
                            Continue
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            href="javascript:;"
                            onClick={handleNextSumbit}
                            className="btn btn-primary"
                            id="steps-next"
                          >
                            Continue
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-group text-center form__footer d-flex justify-content-center mt-3 mb-0">
                    <p className="mb-0">Already have an account?</p>
                    <Link to="/login" className="link-color-purple pl-2">
                      Log in
                    </Link>
                  </div>
                </form>
              ) : (
                <BusinesSignUp updateClearData={updateClearData} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Signup;
