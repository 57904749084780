import React, { useDebugValue, useEffect, useState } from "react";
import Footer from "./HomeSection/Footer";
import PopularServices from "./PopularServices";
import ProviderService from "./ProviderService";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchProviderList,
  getAllPopularServices,
  getFindServiesProvider,
  getSearchProvidersData,
} from "./landingContentAction";
import { searchProviderRegex } from "../../../utilities/functions";
import Input from "../Input/Input";
import HTMLReactParser from "html-react-parser";

export default function ServiceProvider() {
  const dispatch = useDispatch();

  const popularServices = useSelector((state) => state.serviceProviderReducer);



  const {
    getPopularServicesData: { getPopularServicesList },getServiceProviderContent:{getServiceProviderData
    }

  } = popularServices;



  const [searchClick, setSearchClick] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchPage, setSearchPage] = useState(1);

  // handle search function run if searchValue have some relevent text//
  const handleSubmitSearch = (e) => {
    e.preventDefault();
    const testedRegex = searchProviderRegex(searchValue);
    if (searchValue !== "" && searchValue !== null && testedRegex) {
      dispatch(getSearchProvidersData(searchValue, searchPage));
      setSearchClick(true);
    }
  };

  useEffect(() => {
    if (getPopularServicesList?.length === 0) {
      try {
        dispatch(getAllPopularServices())
          .then((res) => {
            setSearchPage(1);

            return res;
          })
          .catch((err) => {
            cosole.log(err);
            throw err;
          });
      } catch (err) {
        throw err;
      }
    }
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getShareUserProfile(userid));
  }, []);

  // CALL FOR SEARCH LIST LOADMORE DATA//
  useEffect(() => {
    if (searchPage > 1) {
      dispatch(getSearchProvidersData(searchValue, searchPage));
    }
  }, [searchPage]);

  // CLEAR IF ANY REVIOUS SEARCH PROVIDER LIST COMPONENT RENDER FIRST TIME //
  useEffect(() => {
    dispatch(clearSearchProviderList(null));
    dispatch(getFindServiesProvider())
  }, []);




  return (
    <>
      <div className="landing-wrapper">
        <section className="spacer body-spacer hero">
          <div className="container">
            <div className="row">
              <div className="col-md-7 mx-auto text-center">
                <h1 className="font-weight-bold mb-0">{getServiceProviderData?.title}</h1>
                <p className="my-4">
{getServiceProviderData?.title !== undefined && HTMLReactParser(getServiceProviderData?.description)}
                </p>

                <div className="services-search">
                  <form  onSubmit={handleSubmitSearch}
                    autoComplete="off">
                    <Input
                      onChange={(e) => setSearchValue(e.target.value)}
                      type="text"
                      className="form-control"
                      name="search"
                      value={searchValue}
              
                    />
                    <button className="btn" onClick={handleSubmitSearch}>
                      search
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!searchClick && (
          <section className="spacer">
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-12">
                  <h2 className="font-weight-bold">Popular Services</h2>
                </div>
              </div>
              {/* services map start */}
              <PopularServices
                getPopularServicesList={getPopularServicesList}
              />
              {/* services map end */}
            </div>
          </section>
        )}

        <ProviderService
          searchClick={searchClick}
          searchPage={searchPage}
          setSearchPage={setSearchPage}
        />
      </div>

      <Footer />
    </>
  );
}
