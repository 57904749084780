import React, { useState } from "react";
export default function IndustryTab({ getAllSellerDatas,
  industry,
  pathname,
  onUpdateServiceSubmit,
  industryDescArr,
  isShow,
  editIndustryDesc,
  openDeleteConfirmModal
  , btnDisable }) {
  const [inputs, setInputs] = useState([]);
  return (
    <div>

      <div className="profile__box custom-shadow">
        <div className="profile__box__heading d-flex align-items-center justify-content-between">
          <h3 className="h4">Industry(s)</h3>
        </div>

        <div>
          {!!getAllSellerDatas && !!Object.keys(getAllSellerDatas).length ? (
            <>
              {industry.map((item, i) => (
                <div className="services-listing__inner" key={i}>
                  <div className="services-listing__header d-flex align-items-start">
                    <div className="services-listing__img">
                      <img src="/assets/images/services-icon.svg" alt="services icon" />
                    </div>
                    <div>
                    <span className="h5 m-0">{item.label}</span>
                    <p className="mb-1 service-description">
                    {industryDescArr[i]?.description}
                  </p>
                  <div className="service-control">
                    {pathname == "/account" ? (
                      <>
                        <a
                          href="javascript:;"

                          onClick={(e) => {
                            editIndustryDesc(item, i);
                          }}
                        >
                          <i className="ri-pencil-line"></i>
                        </a>
                        <a href="javascript:;" onClick={() => openDeleteConfirmModal(item, "industry", "Industry")}>
                          <i className="ri-delete-bin-line"></i>
                        </a>
                      </>
                    ) : null}
                  </div>
                    </div>
                  </div>
                  <div>
                    {isShow === item.id ? (
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="Description">Description</label>
                            <textarea
                              name="primary_description"
                              className="form-control"
                              onChange={(e) => {
                                item.description = e.target.value;
                                setInputs([...inputs]);
                              }}
                              value={item.description}
                              placeholder="Describe your industry"
                            />
                            <p
                              style={{ display: "none" }}
                              className="error-message"
                            ></p>
                          </div>
                          <div className="form-group  mt-3 form-btn text-right">
                            <button

                              value="Update"
                              onClick={() => {
                                onUpdateServiceSubmit(item, "industry");
                              }}
                              className="btn btn-blue"
                              disabled={btnDisable}
                            >Update</button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </>
          ) : (
            null
          )}
          {!industry?.length &&
            <p>No Services</p>
          }
        </div>
      </div>
    </div>
  );
}
