import { getCookie } from '../../library/utilities/functions';
let Symbol = require('es6-symbol');

const deleteApi = (endpoint, body) => {
    let config = {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getCookie('token_id')
        },
        body: JSON.stringify(body) 
    };

    return fetch(endpoint, config)
        .then((response) => {
            return response.json().then(data => ({ data, status: response.status }))
        })
        .catch(err => ({ data: { error: true, message: "Internal Server Error" }, status: 500 }))
}

export const DELETE_API = Symbol('CALL DELETE API');

export default store => next => action => {
    const deleteAPI = action[DELETE_API];
    if (typeof deleteAPI === 'undefined') return next(action);
    let {endpoint, types, body } = deleteAPI;
    const [requestType, successType, errorType] = types;
    return (next({ type: requestType }),
        deleteApi(endpoint, body)).then(
            response => {
                if (response.status === 200) {
                    return next({ response, type: successType })
                }
                else if (response.status === 401) {
                    return next({ response, type: errorType })
                }
                else {
                    return next({ response, type: errorType })
                }
            }
        )
}