import React, { useEffect, useState } from "react";

import "rc-time-picker/assets/index.css";
import moment from "moment";
import axios, { Axios } from "axios";
import { SELLER_SIGNUP_API, SELLER_UPDATE_STATUS } from "../../library/urls";
import { getCookie, setCookie } from "../../library/utilities/functions";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import TimezoneSelect from "react-timezone-select";
import { TimeArr } from "../IndividualSeller/TimeRange";
import Select from "react-select";
const BusinessPriceAndTime = ({ isEdit }) => {
  const { addToast } = useToasts();
  const [hourlyRate, setHourlyRate] = useState("");
  const [getAllBusinessData, setGetAllBusinessData] = useState([]);
  const [days, setDays] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const AccountStateData = useSelector((state) => state.AccountReducer);
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [timeOptions, setTimeOptions] = useState([]);
  const profileData = !!getCookie("profile_data")
  ? JSON.parse(getCookie("profile_data"))
  : "";
  const [daysItem, setDaysItem] = useState([
    {
      id: 1,
      day: "Monday",
      startTimeId: 1,
      EndTimeId: 2,
      time_from: "",
      time_to: "",
      startmomentTime: "",
      endMomomentTime: "",
    },
    {
      id: 2,
      day: "Tuesday",
      startTimeId: 1,
      EndTimeId: 2,
      time_from: "",
      time_to: "",
      startmomentTime: "",
      endMomomentTime: "",
    },
    {
      id: 3,
      day: "Wednesday",
      startTimeId: 1,
      EndTimeId: 2,
      time_from: "",
      time_to: "",
      startmomentTime: "",
      endMomomentTime: "",
    },
    {
      id: 4,
      day: "Thursday",
      startTimeId: 1,
      EndTimeId: 2,
      time_from: "",
      time_to: "",
      startmomentTime: "",
      endMomomentTime: "",
    },
    {
      id: 5,
      day: "Friday",
      startTimeId: 1,
      EndTimeId: 2,
      time_from: "",
      time_to: "",
      startmomentTime: "",
      endMomomentTime: "",
    },
    {
      id: 6,
      day: "Saturday",
      startTimeId: 1,
      EndTimeId: 2,
      time_from: "",
      time_to: "",
      startmomentTime: "",
      endMomomentTime: "",
    },
    {
      id: 7,
      day: "Sunday",
      startTimeId: 1,
      EndTimeId: 2,
      time_from: "",
      time_to: "",
      startmomentTime: "",
      endMomomentTime: "",
    },
  ]);

  const handleTimePicker = (e, name, inputsTimeId, time_from) => {
    /* trigger onChange of week_of_day */
    const newArray = daysItem.map((item, i) => {
      if (item.day === name) {
        if (inputsTimeId === item.startTimeId) {
          return { ...item, time_from: e.label };
        } else {
          return { ...item, time_to: e.label };
        }
      } else {
        return item;
      }
    });
    setDaysItem(newArray);
    /* add startTime and End time in week_of day array with onchange */
    days.map((item, i) => {
      if (item.day_of_week === name) {
        if (inputsTimeId === 1) {
          days[i].time_from = e.label;
          days[i].time_to = "12:00 AM";
        } else if (inputsTimeId === 2) {
          days[i].time_to = e.label;
        }
      }
    });
  };
  /* make newArray with onChange and if toggle true add new Value in array if toggle false remove value from array */
  const handleDays = (e, data) => {
    if (e.target.checked) {
      setDaysItem((prev) => {
        return prev.map((item) => {
          if (item.id === data.id) {
            return {
              ...item,
              day_of_week: e.target.value,
              time_from: "OPEN 24 HOURS",
              time_to: "",
              checked: false,
            };
          } else {
            return { ...item };
          }
        });
      });
      const indexdata = days?.findIndex(
        (data) => data.day_of_week === e.target.value
      );
      let newDaysArray = days;
      newDaysArray.splice(indexdata, 1);
      setDays(newDaysArray);
    } else if (e.target.checked == false) {
      setDaysItem((prev) => {
        return prev.map((item) => {
          if (item.id === data.id) {
            return { ...item, time_from: "", time_to: "", checked: true };
          } else {
            return { ...item };
          }
        });
      });
      setDays([
        ...days,
        {
          day_of_week: e.target.value,
          time_from: "OPEN 24 HOURS",
          time_to: "",
        },
      ]);
    } else {
      days.slice(-1);
    }
  };

  const handleSave = () => {
    if (
      hourlyRate != 0 &&
      days.length > 0 &&
      !!Object.keys(selectedTimezone).length
    ) {
      const data = new FormData();
      data.append("form_step", 3);
      data.append("seller_status", 1);
      data.append("type_individual", 1);
      data.append("value", selectedTimezone.value);
      data.append("label", selectedTimezone.label);
      data.append("altName", selectedTimezone.altName);
      data.append("abbrev", selectedTimezone.abbrev);
      data.append("offset", selectedTimezone.offset);
      if (!!getAllBusinessData?.hourly_rate) {
        data.append("while_edit", true);
      }
      days?.forEach((items, i) => {
        if (items.time_from == "OPEN 24 HOURS") {
          data.append(`business_hours[${i}][is_twenty_four]`, 1);
          data.append(`business_hours[${i}][twenty_four]`, items.time_from);
          data.append(`business_hours[${i}][time_from]`, "");
          data.append(`business_hours[${i}][day]`, items.day_of_week),
            data.append(`business_hours[${i}][time_to]`, "");
        } else {
          data.append(`business_hours[${i}][is_twenty_four]`, 0);
          data.append(`business_hours[${i}][time_from]`, items.time_from);
          data.append(`business_hours[${i}][day]`, items.day_of_week),
            data.append(`business_hours[${i}][time_to]`, items.time_to);
        }
      });
      data.append("hourly_rate", hourlyRate);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      Swal.fire({
        title: "<h5 style='color:#212529'>" + "Do you want to save ?" + "</h5>",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        confirmButtonColor: "#00a8f3",
        denyButtonColor: "#88001b",
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios(SELLER_SIGNUP_API, config).then((response) => {
            const data = response;
            setError(false);
            setCookie("become_seller", true);
            if (data.data.status === 200) {
              axios(SELLER_UPDATE_STATUS, config).then((response) => {});
            } else if (data.data.status === 400) {
              Swal.fire({
                title:
                  "<h5 style='color:#212529'>" +
                  "Please fill the valid time" +
                  "</h5>",
                showCancelButton: false,
                confirmButtonText: "Go Back",
                confirmButtonColor: "#00a8f3",
              });
            }
          });
        }
      });
    } else {
      setError(true);
    }
  };
  const handleFinish = () => {
    if (
      hourlyRate != 0 &&
      days.length > 0 &&
      !!Object.keys(selectedTimezone).length
    ) {
      const data = new FormData();
      data.append("form_step", 3);
      data.append("seller_status", 1);
      data.append("type_individual", 0);
      data.append("value", selectedTimezone.value);
      data.append("label", selectedTimezone.label);
      data.append("altName", selectedTimezone.altName);
      data.append("abbrev", selectedTimezone.abbrev);
      data.append("offset", selectedTimezone.offset);
      if (!!getAllBusinessData?.hourly_rate) {
        data.append("while_edit", true);
      }
      days?.forEach((items, i) => {
        if (items.time_from == "OPEN 24 HOURS") {
          data.append(`business_hours[${i}][is_twenty_four]`, 1);
          data.append(`business_hours[${i}][twenty_four]`, items.time_from);
          data.append(`business_hours[${i}][time_from]`, "");
          data.append(`business_hours[${i}][day]`, items.day_of_week),
            data.append(`business_hours[${i}][time_to]`, "");
        } else {
          data.append(`business_hours[${i}][is_twenty_four]`, 0);
          data.append(`business_hours[${i}][time_from]`, items.time_from);
          data.append(`business_hours[${i}][day]`, items.day_of_week),
            data.append(`business_hours[${i}][time_to]`, items.time_to);
        }
      });
      data.append("hourly_rate", hourlyRate);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      Swal.fire({
        title:
          "<h5 style='color:#212529'>" +
          "Do you want to Finish the Application ?" +
          "</h5>",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        confirmButtonColor: "#00a8f3",
        denyButtonColor: "#88001b",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios(SELLER_SIGNUP_API, config).then((response) => {
            const data = response;
            setError(false);
            setCookie("become_seller", true);
            if (data.data.status === 200) {
  
              history.push(`/account/${profileData?.user_slug}`);

              const showSuccessMsg = data.data.message.split(".")[0];
              const splitmsg = data.data.message.split(".")[1];
              if (splitmsg?.length > 0) {
                addToast(
                  <p>
                    <span
                      style={{ color: "#000", fontWeight: 700 }}
                    >{`${showSuccessMsg}.`}</span>
                    <br />
                    <br />
                    <span>
                      {data.data.message.slice(50, data.data.message?.length)}
                    </span>
                  </p>,
                  {
                    appearance: "success",
                    autoDismiss: true,
                    // autoDismissTimeout: 2000,
                  }
                );
              } else {
                addToast(data.data.message, {
                  appearance: "success",
                  autoDismiss: true,
                  // autoDismissTimeout: 2000,
                });
              }

              axios(SELLER_UPDATE_STATUS, config);
            } else if (data.data.status === 400) {
              Swal.fire({
                title:
                  "<h5 style='color:#212529'>" +
                  "Please fill the valid time" +
                  "</h5>",
                showCancelButton: false,
                confirmButtonText: "Go back",
                confirmButtonColor: "#00a8f3",
              });
            }
          });
        }
      });
    } else {
      setError(true);
    }
  };
  const handleBack = () => {
    $("#pills-tab > li:eq(0) a").trigger("click");
  };

  useEffect(async () => {
    setGetAllBusinessData(
      AccountStateData?.getAllSellerDataApi?.getAllSellerDatas
    );
  }, [AccountStateData]);
  /*add data in my local object */
  useEffect(() => {
    setHourlyRate(getAllBusinessData?.hourly_rate);
    getAllBusinessData?.availability &&
      getAllBusinessData?.availability?.map((item, index) => {
        daysItem.map((items, i) => {
          if (
            item.day_of_week.charAt(0).toUpperCase().slice(0) +
              item.day_of_week.slice(1) ===
            items.day
          ) {
            daysItem[i].time_from = item.time_from;
            daysItem[i].time_to = item.time_to;
            daysItem[i].startmomentTime = moment(`${items.time_from}`, "HH:mm");
            daysItem[i].endMomomentTime = moment(`${items.time_to}`, "HH:mm");
            daysItem[i].checked = true;
          }
        });
      });
    if (!!Object.keys(getAllBusinessData).length) {
      setDays([...getAllBusinessData?.availability]);
      if (getAllBusinessData.label) {
        setSelectedTimezone({
          label: getAllBusinessData.label,
          altName: getAllBusinessData.alt_name,
          abbrev: getAllBusinessData.abbrev,
          value: getAllBusinessData.value,
          offset: getAllBusinessData.offset,
        });
      }
    }
  }, [getAllBusinessData?.availability, isEdit]);

  const hendleHourly = (e) => {
    let hourly = e.target.value;
    if (hourly.length > 5) {
      let newValue = hourly.slice(0, 5);
      setHourlyRate(newValue);
    } else {
      setHourlyRate(hourly);
    }
  };
  useEffect(() => {
    const options = TimeArr.filter((x) => x.label != "OPEN 24 HOURS");
    setTimeOptions(options);
  }, [TimeArr]);

  return (
    <div
      className="tab-pane fade"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div className="form-group">
        <h5>
          Tell people how much your hourly rate is and When you are open for
          business
        </h5>
        <p>You can always change the information later.</p>
        <h5 className="mb-3">Add Your Business Hours</h5>
        <div className="time-zone mb-3">
          <label className="time-zone__label mr-2 w-auto">
            Select Time Zone
          </label>
          <TimezoneSelect
            value={selectedTimezone}
            onChange={setSelectedTimezone}
            className={!isEdit && "disabledTimeZoneSelect"}
            placeholder="Select Time Zone.."
          />
        </div>
        {error && !Object.keys(selectedTimezone).length && (
          <p
            className="error-message"
            style={{ color: "red", paddingBottom: 5 }}
          >
            Please select time zone
          </p>
        )}
        {daysItem?.map((items, i) => {
          return (
            <>
              <div className="timing-block mb-3" key={i}>
                <div className="flex flex-column time-zone__label mr-2">
                  <span className="mb-1">{items.day}</span>
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="switch h-auto"
                      id={items.day}
                      value={items.day}
                      checked={!items.checked}
                      disabled={isEdit ? false : true}
                      onClick={(e) => handleDays(e, items)}
                    />
                    <label className="ml-1 mb-0 small">Closed</label>
                  </div>
                </div>

                <div className="timer-input ">
                  <Select
                    options={TimeArr}
                    isSearchable={false}
                    placeholder="Please Select your time from"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={isEdit ? !items.checked : true}
                    value={
                      !!items.time_from
                        ? { label: items.time_from }
                        : { label: "OPEN 24 HOURS" }
                    }
                    onChange={(opt) =>
                      handleTimePicker(
                        opt,
                        items.day,
                        items.startTimeId,
                        items.time_from
                      )
                    }
                  />
                  {items.time_from && items.time_from != "OPEN 24 HOURS" && (
                    <Select
                      options={timeOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder=""
                      isDisabled={isEdit ? false : true}
                      isSearchable={false}
                      value={
                        !!items.time_to
                          ? { label: items.time_to }
                          : { label: "12:00 AM " }
                      }
                      onChange={(opt) =>
                        handleTimePicker(
                          opt,
                          items.day,
                          items.EndTimeId,
                          items.time_from
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </>
          );
        })}
        {error && days.length == 0 ? (
          <p className="error-message" style={{ color: "red" }}>
            Please Select at Least one Day
          </p>
        ) : null}
      </div>
      <div className="form-group">
        <h5>Set your hourly rate.</h5>
        <p>
          Clients will see this rate on your profile and in search results once
          you publish your profile.{" "}
        </p>
        <div className="hourly-rate position-relative w-50">
          <i className="ri-money-dollar-circle-line"></i>
          <input
            type="text"
            min="1"
            name="rate"
            className="form-control"
            placeholder="Hourly rate"
            maxLength={5}
            value={isEdit ? hourlyRate : getAllBusinessData?.hourly_rate}
            disabled={isEdit ? false : true}
            onChange={(e) => hendleHourly(e)}
          />
        </div>
        {error && hourlyRate == 0 && (
          <p className="error-message" style={{ color: "red" }}>
            Please add the Hourly Rate
          </p>
        )}
      </div>
      <div className="form-group d-flex justify-content-end mt-5 flex-wrap btn-wrapper">
        <a
          href="javascript:;"
          className="btn btn-secondary mr-3"
          onClick={handleBack}
        >
          Start Over
        </a>
        {isEdit && (
          <>
            <a
              href="javascript:;"
              className="btn btn-blue mr-3"
              onClick={handleSave}
            >
              Save
            </a>
            <a
              href="javascript:;"
              className="btn btn-primary"
              onClick={handleFinish}
            >
              Finish Application
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default BusinessPriceAndTime;
