import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from "react-router-redux";
import rootReducer from './mainReducer';
import { createBrowserHistory as createHistory } from 'history'
import { getApi, postApi, deleteApi } from '../fetch';
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from 'redux-persist';




const history = createHistory();
const middleware = routerMiddleware(history);

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        getApi,
        postApi,
        deleteApi,
        middleware
    )
);

export const persistor = persistStore(store);