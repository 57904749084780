import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import {
  getCookie,
  replceMultiStringWithSIngle,
} from "../../../library/utilities/functions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useDropzone } from "react-dropzone";
import dropZone from "./dropZone.css";
import { DELETE_PROJECT_IMAGE, IMAGE_BASE_URL } from "../../../library/urls";
import LargeImageSlide from "./LargeImageSlide";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

const Project = forwardRef(
  ({
    allref,
    is_valid_from_date,
    is_valid_to_date,
    AccountState,
    onShowHideProjects,
    changeProjectDetail,
    resetProject,
    handleUploadProjectIcon,
    onProjectsSubmit,
    deleteProject,
    setProjectIcon,
    projectIcon,
    editProjectIcon,
    setEditProjecticon,
    editProjectOpen,
    is_edit_project,
    descriptionLength,
    projectImages,
    setProjectImages,
    handleUploadProjectImages,
  }) => {
    const {
      getProjectsApi: { getProjectsMessage, projects, getProjectsSucess },
      my_projects,
    } = AccountState;

    const [sliderDetails, setSliderDetails] = useState({
      activeIndexFile: 0,
      sliderDataFile: [],
    });

    const { addToast } = useToasts();

    const {
      getRootProps: getRootProjectImgProps,
      getInputProps: getInputProjectImgProps,
      isDragActive: isProjectImgDragActive,
    } = useDropzone({
      accept: {
        "image/jpeg": [".png", ".jpeg", ".jpg", ".webp", ".bmp"],
      },
      maxFiles: 10,

      onDrop: (acceptedFiles) => {
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        setProjectImages((projectImages) => [
          ...projectImages,
          ...acceptedFiles,
        ]);
      },
    });

    const handleRemoveProjectImages = async (imageData, imgIndex) => {
      if (imageData?.id) {
        let data = {
          image_id: imageData?.id,
        };

        let config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getCookie("token_id"),
          },
          data: data,
        };

        try {
          await axios(DELETE_PROJECT_IMAGE, config).then((response) => {
            if (response?.data?.status === 200) {
              const updateProjectImages = projectImages.filter(
                (item, index) => {
                  if (index !== imgIndex) {
                    return item;
                  }
                }
              );
              setProjectImages(updateProjectImages);

              addToast(response?.data?.success, {
                appearance: "success",
                autoDismiss: true,
                // autoDismissTimeout: 2000,
              });
            }
          });
        } catch (err) {
          addToast(err, {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
        }
      } else {
        const updateProjectImages = projectImages.filter((item, index) => {
          if (index !== imgIndex) {
            return item;
          }
        });

        setProjectImages(updateProjectImages);
      }
    };

    const handleOpenSilderModal = (file, index) => {
      setSliderDetails({
        ...sliderDetails,
        ...{ activeIndexFile: index, sliderDataFile: file },
      });
    };
    const handleRemoveProjectIcon = () => {
      if (projectIcon !== null) {
        setProjectIcon(null);
      } else {
        setEditProjecticon("");
        setProjectIcon(null);
      }
    };

    useEffect(() => {
      my_projects.map((project, index) => {
        if (project?.project_icon?.length > 0) {
          setEditProjecticon(project?.project_icon);
        }
        if (project?.project_images?.length > 0) {
          setProjectImages(project?.project_images);
        }
      });
    }, [my_projects]);

    return (
      <>
        <div className="profile__box custom-shadow">
          <div className="profile__box__heading d-flex align-items-center justify-content-between">
            <h3 className="text-uppercase h4">
              Projects
              <i
                className="ri-edit-box-line project-setting"
                onClick={onShowHideProjects}
              />
            </h3>
          </div>

          {/* ---- PROJECT FROM START ---- */}

          <div className="project-form">
            <form onSubmit={onProjectsSubmit}>
              {my_projects.map((project, index) => (
                <>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group current-project">
                        <input
                          type="checkbox"
                          name="current"
                          onChange={(e) => {
                            changeProjectDetail(e, index);
                          }}
                          checked={project.current}
                          className="h-auto"
                        />
                        <span className="ml-2">Current Project</span>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <div
                        className="position-relative mb-3 mx-auto project-profile"
                        style={{
                          width: "100px",
                          height: "100px",
                          backgroundColor: "#c5c6c7",
                        }}
                      >
                        {projectIcon !== null && (
                          <img
                            src={
                              !!projectIcon && URL.createObjectURL(projectIcon)
                            }
                            className="profile__place-img"
                          />
                        )}
                        {projectIcon == null && (
                          <>
                            <label
                              htmlFor="uploadFileInput"
                              className={`upload_project_icon mb-0`}
                            >
                              <i className="ri-camera-line"></i>
                              {`${!is_edit_project ? "Icon" : ""}`}
                            </label>
                          </>
                        )}

                        {editProjectIcon !== undefined &&
                          editProjectIcon?.length > 0 && (
                            <>
                              <img
                                src={IMAGE_BASE_URL + editProjectIcon}
                                className="profile__place-img"
                              />
                            </>
                          )}

                        {(projectIcon !== null ||
                          project?.project_icon !== "") && (
                          <span className="project-cross">
                            <i
                              className="ri-close-circle-line text-danger"
                              onClick={handleRemoveProjectIcon}
                            />
                          </span>
                        )}
                      </div>

                      <input
                        type="file"
                        id="uploadFileInput"
                        accept="image/png, image/jpeg, image/png, image/webp, image/bmp"
                        name="projectIcon"
                        onChange={(event) => {
                          handleUploadProjectIcon(event);
                          // setSelectedThumbinal(event.target.files[0]);
                          // onChange(event);
                          // trigger("yupNewsThumbinal");
                        }}
                        style={{ display: "none" }}
                      />
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="Project Title">Project Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          onChange={(e) => {
                            changeProjectDetail(e, index);
                          }}
                          value={project.title || ""}
                          placeholder="Name of the Project"
                        />
                        <p
                          style={{ display: "none" }}
                          ref={allref.project_title_validation}
                          className="error-message"
                        >
                          Project title is required{" "}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="from">Project Link/Website</label>
                        <input
                          type="text"
                          name="project_link"
                          id="link-content"
                          onChange={(e) => {
                            changeProjectDetail(e, index);
                          }}
                          value={project?.project_link || ""}
                          placeholder="Add Link/Website"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="Type of job">Type of job</label>

                        <input
                          type="text"
                          name="type_of_job"
                          className="form-control"
                          onChange={(e) => {
                            changeProjectDetail(e, index);
                          }}
                          value={project.type_of_job || ""}
                          placeholder="Job done in the Project"
                        />
                        <p
                          style={{ display: "none" }}
                          ref={allref.project_job_type_validation}
                          className="error-message"
                        >
                          Job type is required{" "}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="from">from</label>
                          <DatePicker
                            className="bg-trsp"
                            id="dateob"
                            name="from"
                            maxDetail={"year"}
                            maxDate={new Date()}
                            value={project.from || ""}
                            selected={project.from}
                            format="MM/yyyy"
                            monthPlaceholder="MM"
                            clearIcon={null}
                            onChange={(date) =>
                              changeProjectDetail(
                                { target: { name: "from", value: date } },
                                index
                              )
                            }
                            yearPlaceholder="YYYY"
                          />
                          {!is_valid_from_date && (
                            <p className="error-message">
                              From date is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="To">To</label>
                          <DatePicker
                            className="bg-trsp"
                            maxDetail={"year"}
                            disabled={project.current ? true : false}
                            maxDate={new Date()}
                            id="dateob"
                            name="to"
                            value={project.to || ""}
                            selected={project.to}
                            format="MM/yyyy"
                            monthPlaceholder="MM"
                            clearIcon={null}
                            onChange={(date) =>
                              changeProjectDetail(
                                { target: { name: "to", value: date } },
                                index
                              )
                            }
                            yearPlaceholder="YYYY"
                          />
                          {!is_valid_to_date && (
                            <p className="error-message">To date is required</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="Location">Location</label>
                        <input
                          type="text"
                          name="location"
                          className="form-control"
                          onChange={(e) => {
                            changeProjectDetail(e, index);
                          }}
                          value={project.location || ""}
                          placeholder="Location of the Project"
                        />
                        <p
                          style={{ display: "none" }}
                          ref={allref.project_address_validation}
                          className="error-message"
                        >
                          Location is required{" "}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="Description">Description</label>
                        <textarea
                          name="description"
                          className="form-control"
                          onChange={(e) => {
                            changeProjectDetail(e, index);
                          }}
                          value={project.description || ""}
                          placeholder="Describe your Business project"
                        />
                        <p
                          style={{ display: "none" }}
                          ref={allref.project_description_validation}
                          className="error-message"
                        >
                          {project.description.length > 1500
                            ? "Maximum length is 1500"
                            : replceMultiStringWithSIngle(
                                project.description
                              ) == ""
                            ? "Description is required"
                            : ""}
                        </p>
                        {descriptionLength}/1500
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div>
                        <label>Upload Project Images</label>
                        <section className="dropzone-container">
                          <div
                            {...getRootProjectImgProps({
                              className: "dropzone",
                            })}
                          >
                            <input {...getInputProjectImgProps()} />
                            {isProjectImgDragActive ? (
                              <p className="text-center">
                                Drop the files here ...
                              </p>
                            ) : (
                              <>
                                <p className="text-center">
                                  Drag 'n' drop some files here, or click to
                                  select files
                                </p>

                                <p className="text-center text-muted">
                                  10 files are the maximum number of files you
                                  can drop here
                                </p>
                              </>
                            )}
                          </div>
                          <aside>
                            {projectImages.map((item, index) => (
                              <div
                                className="d-inline-block position-relative"
                                key={index}
                              >
                                <img
                                  src={item?.preview || item?.name?.file_name}
                                  className="drop-img"
                                />
                                {item && (
                                  <span className="delete_project_image">
                                    <i
                                      className="ri-close-circle-line text-danger "
                                      onClick={() =>
                                        handleRemoveProjectImages(item, index)
                                      }
                                    />
                                  </span>
                                )}
                              </div>
                            ))}

                            {}
                          </aside>
                        </section>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="form-group text-left mt-4 form-btn">
                          <input
                            type="button"
                            name="submit"
                            value="Reset"
                            onClick={(e) => {
                              resetProject(index);
                            }}
                            disabled={
                              Object.values(my_projects[0]).every(
                                (item) => !item
                              ) && projectImages.length < 1
                            }
                            className="btn btn-secondary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}

              <div className="form-group  mt-3 form-btn text-right">
                <input
                  type="submit"
                  name="submit"
                  value={is_edit_project ? "Update" : "Save"}
                  className="btn btn-blue"
                />
                {!is_edit_project && (
                  <input
                    type="button"
                    name="submit"
                    value="Save and Add more"
                    onClick={() => {
                      onProjectsSubmit(null);
                    }}
                    className="btn btn-primary"
                  />
                )}
              </div>
            </form>
          </div>
          {/* ---- PROJECT FROM END ---- */}
          {/*------ SHOW ALL PROJECTS START ------ */}
          <div className="projects__listing">
            <div className="row">
              <div className="col-md-12">
                {((getProjectsSucess && projects.length == 0) ||
                  !getProjectsSucess) && <div>{getProjectsMessage}</div>}
                {getProjectsSucess &&
                  projects.length > 0 &&
                  projects.map((data, index) => (
                    <div className="projects__listing__inner d-flex flex-wrap">
                      <span className="project-timeline position-absolute">
                        {data.from_date} -{" "}
                        {data.current_project == "1" ? "Present" : data.to_date}
                      </span>
                      <div className="d-flex mb-3">
                        {data.project_icon.length > 0 ? (
                          <div className="project__icon mr-2">
                            <img src={IMAGE_BASE_URL + data.project_icon} />
                          </div>
                        ) : (
                          <div className="project__icon mr-2">
                            <i className="ri-folder-settings-line" />
                          </div>
                        )}

                        <div className="projects__head">
                          <h4 className="mb-0">
                            <Link
                              href="javascript:;"
                              className="link-color text-capitalize"
                            >
                              {data.title}
                            </Link>
                          </h4>
                          <p className="mb-0">
                            <span className="font-weight-bold"></span>
                            {data.job_type} in {data.address}
                          </p>
                        </div>
                      </div>

                      <div className="project__details">
                        <div className="project__descp">
                          <p>{data.description}</p>
                        </div>
                        {data?.link !== null && (
                          <div>
                            <strong className="mr-1">Link:</strong>
                            <a
                              href={`${data.link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data?.link}{" "}
                            </a>
                          </div>
                        )}

                        {data?.images?.length > 0 && (
                          <div className="project-media d-flex justify-content-end mt-4">
                            <div className="position-relative">
                              <img
                                role="button"
                                onClick={() =>
                                  handleOpenSilderModal(data?.images, index)
                                }
                                src={
                                  data?.images.length > 0 &&
                                  data?.images[0].name?.file_name
                                }
                                data-toggle="modal"
                                data-target="#popover-modal"
                                className="br-20"
                              />

                              <img
                                role="button"
                                onClick={() =>
                                  handleOpenSilderModal(data?.images, index)
                                }
                                src={
                                  data?.images.length > 1 &&
                                  data?.images[1]?.name?.file_name
                                }
                                data-toggle="modal"
                                data-target="#popover-modal"
                                className="br-20"
                              />
                              <span className="text-secondary ml-2">
                                {data?.images.length > 2 &&
                                  `${data.images.length - 2} more`}
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="project__controls mt-4">
                          <a
                            href="javascript:;"
                            className="link-color"
                            onClick={() => {
                              editProjectOpen(data);
                            }}
                          >
                            <i className="ri-pencil-line"></i>
                          </a>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              deleteProject(data.id);
                            }}
                            className="link-color"
                          >
                            <i className="ri-delete-bin-line"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/*------ SHOW ALL PROJECTS END ------ */}
        </div>

        <LargeImageSlide
          sliderDetails={sliderDetails}
          setSliderDetails={setSliderDetails}
          projects={projects}
        />
      </>
    );
  }
);
export default Project;
