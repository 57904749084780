import React from "react";
import DatePicker from "react-date-picker";
import { replceMultiStringWithSIngle } from "../../../library/utilities/functions";

const Employment = ({
  allref,
  is_valid_from_date,
  is_valid_to_date,
  AccountState,
  onShowHideEmployments,
  changeEmploymentDetail,
  resetEmployment,
  onEmploymentsSubmit,
  deleteEmployment,
  editEmploymentOpen,
  is_edit_employment,
  descriptionLength,
}) => {
  const {
    getEmploymentsApi: {
      getEmploymentsMessage,
      employments,
      getEmploymentsSucess,
    },
    my_employments,
  } = AccountState;
  return (
    <div className="profile__box custom-shadow">
      <div className="profile__box__heading d-flex align-items-center justify-content-between">
        <h3 className="text-uppercase h4">
          Employments
          <i
            className="ri-edit-box-line project-setting"
            onClick={onShowHideEmployments}
          />
        </h3>
      </div>
      <div className="project-form">
        <form onSubmit={onEmploymentsSubmit}>
          {my_employments.map((employment, index) => (
            <>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group current-project">
                    <input
                      type="checkbox"
                      name="current"
                      onChange={(e) => {
                        changeEmploymentDetail(e, index);
                      }}
                      checked={employment.current}
                      className="h-auto"
                    />
                    <span className="ml-2">Current Job</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Company Name">Company Name</label>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      onChange={(e) => {
                        changeEmploymentDetail(e, index);
                      }}
                      value={employment.title || ""}
                      placeholder="Name of the Company"
                    />
                    <p
                      style={{ display: "none" }}
                      ref={allref.employment_title_validation}
                      className="error-message"
                    >
                      Company name is required{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="from">from</label>
                      <DatePicker
                        className="bg-trsp"
                        id="dateob"
                        name="from"
                        maxDetail={"year"}
                        maxDate={new Date()}
                        value={employment.from || ""}
                        selected={employment.from}
                        format="MM/yyyy"
                        monthPlaceholder="MM"
                        onChange={(date) =>
                          changeEmploymentDetail(
                            { target: { name: "from", value: date } },
                            index
                          )
                        }
                        yearPlaceholder="YYYY"
                      />
                      {!is_valid_from_date && (
                        <p className="error-message">From date is required</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="To">To</label>
                      <DatePicker
                        className="bg-trsp"
                        maxDetail={"year"}
                        disabled={employment.current ? true : false}
                        maxDate={new Date()}
                        id="dateob"
                        name="to"
                        value={employment.to || ""}
                        selected={employment.to}
                        format="MM/yyyy"
                        monthPlaceholder="MM"
                        onChange={(date) =>
                          changeEmploymentDetail(
                            { target: { name: "to", value: date } },
                            index
                          )
                        }
                        yearPlaceholder="YYYY"
                      />
                      {!is_valid_to_date && (
                        <p className="error-message">To date is required</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Position Title">Position Title</label>
                    <input
                      type="text"
                      name="type_of_job"
                      className="form-control"
                      onChange={(e) => {
                        changeEmploymentDetail(e, index);
                      }}
                      value={employment.type_of_job || ""}
                      placeholder="Your role"
                    />
                    <p
                      style={{ display: "none" }}
                      ref={allref.employment_job_type_validation}
                      className="error-message"
                    >
                      Position title is required{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Location">Location</label>
                    <input
                      type="text"
                      name="location"
                      className="form-control"
                      onChange={(e) => {
                        changeEmploymentDetail(e, index);
                      }}
                      value={employment.location || ""}
                      placeholder="State, Country"
                    />
                    <p
                      style={{ display: "none" }}
                      ref={allref.employment_address_validation}
                      className="error-message"
                    >
                      Location is required{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="Description">Description</label>
                    <textarea
                      name="description"
                      className="form-control"
                      onChange={(e) => {
                        changeEmploymentDetail(e, index);
                      }}
                      value={employment.description || ""}
                      placeholder="Job description of assignments, operations and duties"
                    />
                    {descriptionLength}/1500
                    <p
                      style={{ display: "none" }}
                      ref={allref.employment_description_validation}
                      className="error-message"
                    >
                      {employment.description.length > 1500
                        ? "Maximum length is 1500"
                        : replceMultiStringWithSIngle(employment.description) ==
                          ""
                          ? "Description is required"
                          : ""}{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="form-group text-left mt-4 form-btn mb-0">
                      <input
                        type="button"
                        name="submit"
                        value="Reset"
                        onClick={(e) => {
                          resetEmployment(index);
                        }}
                        disabled={Object.values(my_employments[0]).every((item) => !item)}
                        className="btn btn-secondary btn-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="form-group  mt-md-3 form-btn text-right">
            <input
              type="submit"
              name="submit"
              value={is_edit_employment ? "Update" : "Save"}
              className="btn btn-blue btn-full"
            />
            {!is_edit_employment && (
              <input
                type="button"
                name="submit"
                value="Save and Add more"
                onClick={() => {
                  onEmploymentsSubmit(null);
                }}
                className="btn btn-primary btn-full save-more"
              />
            )}
          </div>
        </form>
      </div>
      <div className="projects__listing">
        <div className="row">
          <div className="col-md-12">
            {((getEmploymentsSucess && employments.length == 0) ||
              !getEmploymentsSucess) && <div>{getEmploymentsMessage}</div>}
            {getEmploymentsSucess &&
              employments.length > 0 &&
              employments.map((data, index) => (
                <div className="projects__listing__inner d-flex flex-wrap">
                  <span className="project-timeline position-absolute">
                    {data.from_date} -{" "}
                    {!!data.current_job == "1" ? "Present" : data.to_date}
                  </span>
                  <div className="project__icon">
                    <i className="ri-folder-settings-line" />
                  </div>
                  <div className="project__details">
                    <h4>
                      <a
                        href="javascript:;"
                        className="link-color text-capitalize"
                      >
                        {data.company_name}
                      </a>
                    </h4>
                    <span className="project-timeline position-relative timeline-hide">
                      {data.from_date} -{" "}
                      {!!data.current_job == "1" ? "Present" : data.to_date}
                    </span>
                    <p>
                      <span className="font-weight-bold"></span>
                      {data.position} in {data.address}
                    </p>
                    <div className="project__descp">
                      <p>{data.description}</p>
                    </div>
                    <div className="project__controls mt-4">
                      <a
                        href="javascript:;"
                        className="link-color"
                        onClick={() => {
                          editEmploymentOpen(data);
                        }}
                      >
                        <i className="ri-pencil-line"></i>
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          deleteEmployment(data.id);
                        }}
                        className="link-color"
                      >
                        <i className="ri-delete-bin-line"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Employment;
