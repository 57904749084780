import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromotionalHub } from "../../../library/api/HomeApiService";
import { Skeleton } from "primereact/skeleton";

export default function PromotionalHub() {
  const dispatch = useDispatch();
  const getPromotionalContent = useSelector(
    (state) => state.PromotionalHubReducer
  );

  const {
    promotionalHubContent: {
      getPromotionalPageData,
      getPromotionalLoading,
      getPromotionalPageHeader,
    },
  } = getPromotionalContent;

  useEffect(() => {
    dispatch(getPromotionalHub());
  }, []);

  if (!!getPromotionalLoading) {
    return (
      <>
        <div className="card">
          <div className="flex m-3">
            <Skeleton shape="circle" size="2rem" className="mr-3"></Skeleton>
            <div>
              <Skeleton width="10rem" className="mt-2"></Skeleton>
            </div>
          </div>

          <div className="flex mb-3">
            <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
            <div>
              <Skeleton width="10rem" className="mb-2"></Skeleton>
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height=".5rem"></Skeleton>
            </div>
          </div>

          <div className="flex mb-3">
            <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
            <div>
              <Skeleton width="10rem" className="mb-2"></Skeleton>
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height=".5rem"></Skeleton>
            </div>
          </div>

          <div className="flex mb-3">
            <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
            <div>
              <Skeleton width="10rem" className="mb-2"></Skeleton>
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height=".5rem"></Skeleton>
            </div>
          </div>
        </div>
      </>
    );
  }

  // if (getPromotionalLoading == false && getPromotionalPageData?.length === 0) {
  //   return (
  //     <>
  //       <p>No content found</p>
  //     </>
  //   );
  // }

  return (
    <>
      {!!getPromotionalPageData?.length > 0 && (
        <>
          <div className="bg-white p-3 br-20 custom-shadow user-widget">
            <div className="newsfeed__title d-flex align-items-center">
              {!!getPromotionalPageHeader?.header_icon && (
                <img onError={(ev) =>
                (ev.target.src =
                  "/assets/images/member-default.svg")
                } src={getPromotionalPageHeader?.header_icon} />
              )}
              <span className="h5 mb-0">
                {!!getPromotionalPageHeader?.title &&
                  getPromotionalPageHeader?.title}
              </span>
            </div>
            {getPromotionalPageData?.length > 0 &&
              getPromotionalPageData.map((item, index) => (
                <div key={index}>
                  <div className="mb-4">
                    <div className="newsfeed-post">
                      <div className="newsfeed-post__image">
                        <a href={item?.link} target="_blank">

{
  item?.link_pic ? <img
  onError={(ev) =>
  (ev.target.src =
    "/assets/images/member-default.svg")
  }
  src={item?.link_pic}
  className="img-fluid br-20"
  alt="..."
/> :
 <img
  src={ "/assets/images/cover_img.png"}
  className="img-fluid br-20"
  alt="..."
/>
}
                          
                        </a>
                      </div>
                      <div className="newsfeed-post__descp">
                        <span className="d-block h6">
                          <a href="javascript:void(0)" className=" link-color text-wrap">
                            {item?.author_title?.length > 45
                              ? item?.author_title?.slice(0, 45) + "..."
                              : item.author_title}
                          </a>
                        </span>
                        <div className="d-flex align-items-center small my-1">
                          {item?.author_pic && (
                            <img
                              onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/image-placeholder.jpg")
                              }
                              src={item.author_pic}
                              className="rounded-circle newsfeed__author mr-1"
                            />
                          )}
                          <span>
                            {item?.author_name?.length > 15
                              ? item?.author_name?.slice(0, 15) + "..."
                              : item?.author_name}
                          </span>
                        </div>

                        <span>
                          <a  href={item?.link} target="_blank">
                            {item.link.length > 10
                              ? item.link.slice(0, 16) + "..."
                              : item.link}
                          </a>
                        </span>
                        <p className="small mb-0">
                          {item.description.length > 50
                            ? item.description.slice(0, 50) + "..."
                            : item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
}
