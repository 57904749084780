import React, { Component, useEffect } from "react";
import { IMAGE_BASE_URL, READ_SERVICE_NOTIFICATION } from "../../library/urls";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { getCookie } from "../../library/utilities/functions";
function NotificationList(props) {
  const history = useHistory();

  const {
    list,
    handleRedirect,
    handleAccept,
    handleDecline,
    handleMembersAccept,
    handleMembersDecline,
    NotificationCount,
    allNotificationResponse,
  } = props;
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const openServices = async (data, index) => {
    if (data.type === "Become a seller") {
      const formData = new FormData();
      formData.append("id", data.id);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
      };

      await axios(`${READ_SERVICE_NOTIFICATION}/${data.id}`, config)
        .then((response) => {
          if (response?.data?.status == 200) {
            localStorage.setItem("clickServiceNotification", true);

            history.push(`/account/${profileData?.user_slug}`);
          }
        })
        .catch((err) => {
          throw err;
        });
    } else if (data.type == "Service Request") {
      const formData = new FormData();
      formData.append("id", data.id);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
      };

      await axios(`${READ_SERVICE_NOTIFICATION}/${data.id}`, config)
        .then((response) => {
          if (response?.data?.status == 200) {
            history.push(`/account/${profileData?.user_slug}`);
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  if (list.length > 0) {
    !!list &&
      list.map((item, index) => {
        if (item.read_notification === 1 && item.title == "Become a seller") {
          list.splice(index, 1);
          return list;
        } else {
          return list;
        }
      });
  }

  return (
    <div>
      <InfiniteScroll
        dataLength={list.length}
        next={!!list.length && props.fetchMoreData}
        hasMore={props.NotificationCount != list?.length}
      >
        {!!list.length &&
          list.map((data, index) => (
            <div
              className={
                !data.title.match("New Friend Request!")
                  ? "search-users__wrapper d-flex flex-wrap bg-white py-4  position-relative align-items-start accepted"
                  : "search-users__wrapper d-flex flex-wrap bg-white py-4 position-relative align-items-start"
              }
              key={index}
            >
              <div className="search-users__img d-flex align-items-center">
                {!!data.is_individual ? (
                  <div className="search-users__img d-flex align-items-center">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(
                          `/search-profile/${data.sender_id}
                          `
                          // ?individual=${!!data.is_individual ? true : false}
                        )
                      }
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/member-default.svg")
                      }
                      src={
                        !!data
                          ? data.type == "Become a seller"
                            ? "/assets/images/notification.svg"
                            : IMAGE_BASE_URL + data.profile_image
                          : "/assets/images/member-default.svg"
                      }
                      alt="user"
                    ></img>
                  </div>
                ) : (
                  <div
                    className={`search-users__img d-flex align-items-center ${
                      data.type == "Become a seller"
                        ? "first-time-notification"
                        : "business-user"
                    }`}
                    style={{ border: data.type == "Become a seller" && "none" }}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(
                          `/search-profile/${data.sender_id}
                          `
                          // ?individual=${!!data.is_individual ? true : false}
                        )
                      }
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/business_logo.svg")
                      }
                      src={
                        !!data
                          ? data.type == "Become a seller"
                            ? "/assets/images/notification.svg"
                            : IMAGE_BASE_URL + data.business_image
                          : "/assets/images/business_logo.svg"
                      }
                      alt="user"
                    ></img>
                  </div>
                )}
              </div>
              <div
                className={
                  data.type === "FriendRequest" ||
                  data.type === "Member Add Request"
                    ? "search-users__detail notifications-actions"
                    : "search-users__detail notifications-actions pr-0"
                }
              >
                {!!data.check.post ? (
                  <h5
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRedirect(data)}
                    className={"ml-2 link-color mb-1" + data.type}
                  >
                    {data.description}
                  </h5>
                ) : (
                  <>
                    {data?.type === "Become a seller" && (
                      <h5
                        style={{ cursor: "pointer" }}
                        onClick={() => openServices(data, index)}
                        className={"ml-2 link-color " + data.type}
                      >
                        {data.description}
                        {data.type == "Become a seller" && (
                          <i className="ri-external-link-line"></i>
                        )}
                      </h5>
                    )}

                    {data?.type === "Service Request" && (
                      <>
                        {data?.description.includes("strong") ? (
                          <>
                          <h5
                            style={{ cursor: "pointer" }}
                            onClick={() => openServices(data, index)}
                            className={"ml-2 link-color " + data.type}
                            dangerouslySetInnerHTML={{
                              __html: data?.description,
                            }}
                          >
                          </h5>
                             {/* {data.type == "Service Request" && (
                              <i className="ri-external-link-line"></i>
                            )} */}
                            </>
                        ) : (
                          <h5
                            style={{ cursor: "pointer" }}
                            onClick={() => openServices(data, index)}
                            className={"ml-2 link-color " + data.type}
                          >
                            {data.description}
                            {data.type == "Service Request" && (
                              <i className="ri-external-link-line"></i>
                            )}
                          </h5>
                        )}
                      </>

                      // <h5
                      //   style={{ cursor: "pointer" }}
                      //   onClick={() => openServices(data, index)}
                      //   className={"ml-2 link-color " + data.type}
                      // >
                      //   {data.description}
                      //   {data.type == "Service Request" && (
                      //     <i className="ri-external-link-line"></i>
                      //   )}
                      //   <p>this is test</p>
                      // </h5>
                    )}

                    {data.type !== "Service Request" &&
                      data.type !== "Become a seller" && (
                        <h5
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push(
                              `/search-profile/${data.sender_id}
                            `
                            )
                          }
                          className={"ml-2 link-color " + data.type}
                        >
                          {data.description}
                          {data.type == "Become a seller" && (
                            <i className="ri-external-link-line"></i>
                          )}
                        </h5>
                      )}
                  </>
                )}
                {data.title == "New Friend Request!" ? (
                  <div className="request-user ml-auto ">
                    <a
                      href="javascript:;"
                      onClick={() => handleAccept(data, index)}
                      className="accepted"
                    >
                      {" "}
                      <i className="ri-check-line" />
                    </a>
                    <a
                      href="javascript:;"
                      onClick={() => handleDecline(data, index)}
                      className="rejected"
                    >
                      {" "}
                      <i className="ri-close-fill" />
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {data.type == "Member Add Request" ? (
                  <>
                    <div className="request-user ml-auto ">
                      <a
                        href="javascript:;"
                        onClick={() => handleMembersAccept(data, index)}
                        className="accepted"
                      >
                        <i className="ri-check-line" />
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => handleMembersDecline(data, index)}
                        className="rejected"
                      >
                        <i className="ri-close-fill" />
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <small className="text-right d-block  mr-2">{data.time}</small>
              </div>
            </div>
          ))}
      </InfiniteScroll>
    </div>
  );
}

export default NotificationList;
