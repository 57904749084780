import axios from "axios";
import { useCallback, useState } from "react";
import { GOOGLE_MEET_INTEGRATION } from "../urls";
import { useToasts } from "react-toast-notifications";

//this function is used for check this all page exist//
const is_page_exist_private = () => {
  let is_page_exist = false;
  const pathname = window.location.pathname;
  switch (pathname) {
    case "/newsfeeds":
      is_page_exist = true;
    // case "/account":
    //   is_page_exist = true;

    case pathname.match("/account") ? pathname : "":
      is_page_exist = true;
    case "/manage-account":
      is_page_exist = true;
    case "/search-list":
      is_page_exist = true;
    case "/all-notification":
      is_page_exist = true;

    case "/first-time-login":
      is_page_exist = true;
    case pathname.match("/network") ? pathname : "":
      is_page_exist = true;

    case pathname.match("/members") ? pathname : "":
      is_page_exist = true;

    case pathname.match("/post") ? pathname : "":
      is_page_exist = true;
    case pathname.match("/account/profile-post") ? pathname : "":
      is_page_exist = true;
    case pathname.match("/account/gallery") ? pathname : "":
      is_page_exist = true;
    case pathname.match("/account/services") ? pathname : "":
      is_page_exist = true;
    case pathname.match("/search-profile") ? pathname : "":
      is_page_exist = true;

    case pathname.match("/account/members") ? pathname : "":
      is_page_exist = true;
    case "/chat":
      is_page_exist = true;
    case "/contact-us":
      is_page_exist = true;
    case "/become-seller":
      is_page_exist = true;
    case "/become-seller-steps":
      is_page_exist = true;
    case "/blog":
      is_page_exist = true;
    case "/our-story":
      is_page_exist = true;

    case "/work-and-earn":
      is_page_exist = true;
    case "/service-provider":
      is_page_exist = true;

    case pathname.match("/blog-detail/") ? pathname : "":
      is_page_exist = true;
  }
  return is_page_exist;
};

//this function is used for check this login pages exist
const is_page_exist_protected = () => {
  let is_page_exist = false;
  const pathname = window.location.pathname;

  switch (pathname) {
    case "/login":
      is_page_exist = true;
    case "/blog":
      is_page_exist = true;
    case "/our-story":
      is_page_exist = true;

    case "/work-and-earn":
      is_page_exist = true;
    case "/service-provider":
      is_page_exist = true;
    case pathname.match("/blog-detail/") ? pathname : "":
      is_page_exist = true;
    case "/signup":
      is_page_exist = true;
    case pathname.match("/search-user") ? pathname : "":
      is_page_exist = true;
    case "/signup-member":
      is_page_exist = true;
    case "/":
      is_page_exist = true;
    case "/forget-password":
      is_page_exist = true;
    case "/change-password":
      is_page_exist = true;
    case "/privacy-policy":
      is_page_exist = true;
    case "/term-service":
      is_page_exist = true;

    // case pathname.includes("/p")?pathname:"":
    //   is_page_exist=true
  }
  return is_page_exist;
};

//this function is used for check this all pages exist
const is_page_exist_app = (params) => {
  let is_page_exist = false;
  const pathname = window.location.pathname;

  switch (pathname) {
    case "/login":
      is_page_exist = true;
    case "/blog":
      is_page_exist = true;
    case "/our-story":
      is_page_exist = true;

    case "/work-and-earn":
      is_page_exist = true;
    case "/service-provider":
      is_page_exist = true;
    case pathname.match("/blog-detail/") ? pathname : "":
      is_page_exist = true;
    case "/newsfeeds":
      is_page_exist = true;
    case "/signup":
      is_page_exist = true;
    case pathname.match("/search-user/") ? pathname : "":
      is_page_exist = true;

    case "/signup-member":
      is_page_exist = true;
    case "/":
      is_page_exist = true;
    case "/forget-password":
      is_page_exist = true;
    case "/change-password":
      is_page_exist = true;
    // case "/account":
    //   is_page_exist = true;
    case pathname.match("/account") ? pathname : "":
      is_page_exist = true;
    case "/manage-account":
      is_page_exist = true;
    case "/search-list":
      is_page_exist = true;
    case "/all-notification":
      is_page_exist = true;
    case pathname.match("/search-profile") ? pathname : "":
      is_page_exist = true;

    case "/first-time-login":
      is_page_exist = true;
    case pathname.match("network") ? pathname : "":
      is_page_exist = true;
    case pathname.match("members") ? pathname : "":
      is_page_exist = true;
    case pathname.match("/post") ? pathname : "":
      is_page_exist = true;
    case pathname.match("/account/profile-post") ? pathname : "":
      is_page_exist = true;
    case pathname.match("/account/gallery") ? pathname : "":
      is_page_exist = true;

    case pathname.match("/account/members") ? pathname : "":
      is_page_exist = true;

    case pathname.match("/account/services") ? pathname : "":
      is_page_exist = true;

    case "/privacy-policy":
      is_page_exist = true;

    case "/members":
      is_page_exist = true;
    case "/term-service":
      is_page_exist = true;
    case "/chat":
      is_page_exist = true;
    case "/become-seller":
      is_page_exist = true;
    case "/become-seller-steps":
      is_page_exist = true;
    case "/contact-us":
      is_page_exist = true;
  }
  return is_page_exist;
};
//this function is used for replace multiple spaces with single space
const replceMultiStringWithSIngle = (string) => {
  if (!!string) {
    string = string.trim().replace(/\s\s+/g, " ");
  }
  return string;
};
//this function is used add title
const addDocumentTitle = (title) => {
  if (title) {
    document.title = "PoolsMagnic | " + title;
  } else {
    document.title = "PoolsMagnic";
  }
};
//this function is used add style when validation not valid
const addValidation = (validation) => {
  for (var key of Object.keys(validation)) {
    if (!validation[key].status) {
      validation[key].validation.current.style.display = "block";
    } else {
      validation[key].validation.current.style.display = "none";
    }
  }
};
//this function is used for remove validation
const removeValidation = (validation) => {
  for (var key of Object.keys(validation)) {
    if (!validation[key].status && !!validation[key].validation.current) {
      validation[key].validation.current.style.display = "none";
    }
  }
};

//this function is for get cookie by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

let profileData = !!getCookie("profile_data")
  ? JSON.parse(getCookie("profile_data"))
  : "";

//this function is used for set cokkies
const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
//this function is used for clear all cookies
const clearCookies = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};
//this function is used for clear cookie by name
const clearSingleCookie = (name) => {
  var d = new Date();
  d.setTime(d.getTime());
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    name +
    "=" +
    "" +
    ";domain=" +
    window.location.hostname +
    ";path=/;" +
    expires;
};
//this function is used for add placeholder when image is broken
export const addDefaultSrc = (ev) => {
  ev.target.src = "/assets/images/image-placeholder.jpg";
};
//this function is used for add placeholder when image is broken
export const returnDefaultImage = (ev) => {
  return "/assets/images/image-placeholder.jpg";
};
//this function is used for top
const scroolTop = () => {
  window.scrollTo(0, 0);
};
//this function is used for forcefully state update
export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
}
//this function is used for covert num into 'K' and 'M'
const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K";
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num < 900) {
    return num;
  }
};

// for show error notifiction on google-login//
export function Error(error) {
  const { addToast } = useToasts();
  addToast(error, {
    appearance: "error",
    autoDismiss: true,
    autoDismissTimeout: 4000,
  });
}

//this function is used to check if exist HTML elements  in the code
function isElement(obj) {
  try {
    return obj instanceof HTMLElement;
  } catch (e) {
    return (
      typeof obj === "object" &&
      obj.nodeType === 1 &&
      typeof obj.style === "object" &&
      typeof obj.ownerDocument === "object"
    );
  }
}

//compare two array and return unique array of values//
function filterSelectedValueOfArr(optionList, SelectedList) {
  
  const option = optionList.filter((itemA) => {
    return !SelectedList?.find((itemB) => {
      return itemA.title === itemB.label;
    });
  });

  // add label and value keys to array of objects//
  const newOptiom = option.map((item) => {
    return {
      label: item.title,
      value: item.id,
      status:item.status
    };
  });

  return newOptiom;
}

const onlyUniqueArrOfObject = (array) => {
  const uniqueValue = Object.values(
    array.reduce((acc, cur) => Object.assign(acc, { [cur.value]: cur }), {})
  );
  return uniqueValue;
};

const searchProviderRegex = (inputValue) => {
  const regex = /^[a-zA-Z]\S*(?:\s\S*)*$/;
  if (regex.test(inputValue)) {
    return true;
  } else {
    return false;
  }
};



const getUniqueItemOfArray = (array)=>
{
  const uniqueArrItem = array.reduce(
    (accumulator, currentObject) => {
      // Check if an object with the same id already exists in the accumulator
      const existingObject = accumulator.find(
        (obj) => obj.id === currentObject.id
      );
  
      // If not found, add the currentObject to the accumulator
      if (!existingObject) {
        accumulator.push(currentObject);
      }
  
      return accumulator;
    },
    []
  );
 return uniqueArrItem
}


export const isMobile = () => window.matchMedia("(max-width: 700px)").matches;

export {
  is_page_exist_private,
  is_page_exist_protected,
  is_page_exist_app,
  scroolTop,
  numFormatter,
  searchProviderRegex,
  replceMultiStringWithSIngle,
  addValidation,
  removeValidation,
  isElement,
  addDocumentTitle,
  getCookie,
  setCookie,
  clearCookies,
  clearSingleCookie,
  filterSelectedValueOfArr,
  onlyUniqueArrOfObject,
  getUniqueItemOfArray,
};
