const ACCEPT_MEMBERS_DATA_REQUEST = "ACCEPT_MEMBERS_DATA_REQUEST";
const ACCEPT_MEMBERS_DATA_SUCCESS = "ACCEPT_MEMBERS_DATA_SUCCESS";
const ACCEPT_MEMBERS_DATA_FAILURE = "ACCEPT_MEMBERS_DATA_FAILURE";

const DECLINE_MEMBERS_DATA_REQUEST = "DECLINE_MEMBERS_DATA_REQUEST";
const DECLINE_MEMBERS_DATA_SUCCESS = "DECLINE_MEMBERS_DATA_SUCCESS";
const DECLINE_MEMBERS_DATA_FAILURE = "DECLINE_MEMBERS_DATA_FAILURE";

const CLEAR_MEMBERS_ACCEPT_REQUEST_RESPONSE =
  "CLEAR_MEMBERS_ACCEPT_REQUEST_RESPONSE";

const CLEAR_MEMBERS_DECLINE_REQUEST_RESPONSE =
  "CLEAR_MEMBERS_DECLINE_REQUEST_RESPONSE";

export {
  ACCEPT_MEMBERS_DATA_REQUEST,
  ACCEPT_MEMBERS_DATA_SUCCESS,
  ACCEPT_MEMBERS_DATA_FAILURE,
  CLEAR_MEMBERS_ACCEPT_REQUEST_RESPONSE,
  DECLINE_MEMBERS_DATA_REQUEST,
  DECLINE_MEMBERS_DATA_SUCCESS,
  DECLINE_MEMBERS_DATA_FAILURE,
  CLEAR_MEMBERS_DECLINE_REQUEST_RESPONSE,
};
