import React from "react";
import { useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { IMAGE_BASE_URL } from "../../../urls";
import { changeGalleryDetail } from "./ProfileGalleryAction";

const OpenLargeFile = ({ GalleryState }) => {
  const {
    galleryDetails: { carousalDataFile, activeIndexFile },
  } = GalleryState;
  const dispatch = useDispatch();

  //this function is used to close the large image model.
  const handleCloseCarousal = () => {
    let carousalsDetail = {
      activeIndexFile: "",
      carousalDataFile: [],
    };
    dispatch(changeGalleryDetail(carousalsDetail));
  };
  //this function is used to play the video
  const handlePlays = (e, index) => {
    var videos = document.querySelectorAll("video");
    videos.forEach(function (video) {
      if (e.currentTarget.id == video.id) {
        video.play();
      } else {
        video.pause();
      }
    });
  };
  
  const OnChangecrusal = () => {
    var videos = document.querySelectorAll("video");
    videos.forEach(function (video) {
      video.pause();
    });
  };
  return (
    <>
      <div
        className="modal fade"
        id="large-gallery-modal"
        tabIndex={-1}
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content h-100">
            <div className="modal-body h-100">
              <button
                onClick={handleCloseCarousal}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>

              <Carousel
                useKeyboardArrows={true}
                emulateTouch={true}
                onChange={OnChangecrusal}
                selectedItem={activeIndexFile}
                showIndicators={false}
                swipeable={false}
                showStatus={false}
                showThumbs={false}
                autoPlay={false}
              >
                {carousalDataFile.map((data, i) => (
                  <div className="carousel-inner">
                    {data.ext == "mp4" ||
                      data.ext == "mov" ||
                      data.ext == "MP4" ||
                      data.ext == "MOV" ||
                      data.ext == "webm" ||
                      data.ext == "WEBM"
                      ? (
                        <video
                          onPlay={(e) => handlePlays(e, i)}
                          id={"videoStop" + i}
                          controls
                          src={IMAGE_BASE_URL + data.file_name}
                          type="video/*"
                        >
                        </video>
                      ) : (<img
                        className="d-block w-100"
                        src={
                          !!data.file_name
                            ? IMAGE_BASE_URL + data.file_name
                            : returnDefaultImage()
                        }
                        onError={(ev) =>
                        (ev.target.src =
                          "/assets/images/image-placeholder.jpg")}
                        alt="First slide"
                      />)}
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OpenLargeFile;
