const CHANGE_LOGIN_INPUT = "CHANGE_LOGIN_INPUT"

const CLEAR_LOGIN_INPUT = "CLEAR_LOGIN_INPUT"

const LOGIN_DATA_REQUEST="LOGIN_DATA_REQUEST";
const LOGIN_DATA_SUCCESS ="LOGIN_DATA_SUCCESS";
const LOGIN_DATA_FAILURE ="LOGIN_DATA_FAILURE";

const CLEAR_LOGIN_API_RESPONSE = "CLEAR_LOGIN_API_RESPONSE"

export { CHANGE_LOGIN_INPUT, CLEAR_LOGIN_INPUT ,LOGIN_DATA_REQUEST ,LOGIN_DATA_SUCCESS,LOGIN_DATA_FAILURE,
    CLEAR_LOGIN_API_RESPONSE}