const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

const CLEAR_PROFILE_RESPONSE = "CLEAR_PROFILE_RESPONSE";

const CHANGE_GENERAL_INFO_INPUT = "CHANGE_GENERAL_INFO_INPUT";

const CHANGE_BASIC_INFO_INPUT = "CHANGE_BASIC_INFO_INPUT";

const UPDATE_ADDITIONAL_INFO_REQUEST = "UPDATE_ADDITIONAL_INFO_REQUEST";
const UPDATE_ADDITIONAL_INFO_SUCCESS = "UPDATE_ADDITIONAL_INFO_SUCCESS";
const UPDATE_ADDITIONAL_INFO_FAILURE = "UPDATE_ADDITIONAL_INFO_FAILURE";

const CLEAR_UPDATE_INFO_RESPONSE = "CLEAR_UPDATE_INFO_RESPONSE";

const LANGUAGE_LIST_REQUEST = "LANGUAGE_LIST_REQUEST";
const LANGUAGE_LIST_SUCCESS = "LANGUAGE_LIST_SUCCESS";
const LANGUAGE_LIST_FAILURE = "LANGUAGE_LIST_FAILURE";

const SKILLS_LIST_REQUEST = "SKILLS_LIST_REQUEST";
const SKILLS_LIST_SUCCESS = "SKILLS_LIST_SUCCESS";
const SKILLS_LIST_FAILURE = "SKILLS_LIST_FAILURE";

const EXPERTISE_LIST_REQUEST = "EXPERTISE_LIST_REQUEST";
const EXPERTISE_LIST_SUCCESS = "EXPERTISE_LIST_SUCCESS";
const EXPERTISE_LIST_FAILURE = "EXPERTISE_LIST_FAILURE";

const SPECIALIZATION_LIST_REQUEST = "SPECIALIZATION_LIST_REQUEST";
const SPECIALIZATION_LIST_SUCCESS = "SPECIALIZATION_LIST_SUCCESS";
const SPECIALIZATION_LIST_FAILURE = "SPECIALIZATION_LIST_FAILURE";
/**
 * projects constants
 */
const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";

const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";

const CHANGE_MY_PROJECT_DETAIL_INFO = "CHANGE_MY_PROJECT_DETAIL_INFO";

const SAVE_MY_PROJECTS_REQUEST = "SAVE_MY_PROJECTS_REQUEST";
const SAVE_MY_PROJECTS_SUCCESS = "SAVE_MY_PROJECTS_SUCCESS";
const SAVE_MY_PROJECTS_FAILURE = "SAVE_MY_PROJECTS_FAILURE";

const CLEAR_SAVE_MY_PROJECTS_RESPONSE = "CLEAR_SAVE_MY_PROJECTS_RESPONSE";

const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";

const CLEAR_DELETE_PROJECT_RESPONSE = "CLEAR_DELETE_PROJECT_RESPONSE";

const CHANGE_EDIT_PROJECT = "CHANGE_EDIT_PROJECT";

const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";
const CHANGE_SOCIAL_ICON_INPUT = "CHANGE_SOCIAL_ICON_INPUT";

const ADD_SOCIAL_ICON_REQUEST = "ADD_SOCIAL_ICON_REQUEST";
const ADD_SOCIAL_ICON_SUCCESS = "ADD_SOCIAL_ICON_SUCCESS";
const ADD_SOCIAL_ICON_FAILURE = "ADD_SOCIAL_ICON_FAILURE";

const CLEAR_ADD_SOCIAL_RESPONSE = "CLEAR_ADD_SOCIAL_RESPONSE";

const GET_SOCIAL_ICON_REQUEST = "GET_SOCIAL_ICON_REQUEST";
const GET_SOCIAL_ICON_SUCCESS = "GET_SOCIAL_ICON_SUCCESS";
const GET_SOCIAL_ICON_FAILURE = "GET_SOCIAL_ICON_FAILURE";

const CLEAR_UPDATE_PROJECT_RESPONSE = "CLEAR_UPDATE_PROJECT_RESPONSE";

const GET_MANAGE_ACCOUNT_REQUEST = "GET_MANAGE_ACCOUNT_REQUEST";
const GET_MANAGE_ACCOUNT_SUCCESS = "GET_MANAGE_ACCOUNT_SUCCESS";
const GET_MANAGE_ACCOUNT_FAILURE = "GET_MANAGE_ACCOUNT_FAILURE";

/**
 * employment constants
 */

const GET_EMPLOYMENTS_REQUEST = "GET_EMPLOYMENTS_REQUEST";
const GET_EMPLOYMENTS_SUCCESS = "GET_EMPLOYMENTS_SUCCESS";
const GET_EMPLOYMENTS_FAILURE = "GET_EMPLOYMENTS_FAILURE";

const ADD_NEW_EMPLOYMENT = "ADD_NEW_EMPLOYMENT";

const CHANGE_MY_EMPLOYMENT_DETAIL_INFO = "CHANGE_MY_EMPLOYMENT_DETAIL_INFO";

const SAVE_MY_EMPLOYMENTS_REQUEST = "SAVE_MY_EMPLOYMENTS_REQUEST";
const SAVE_MY_EMPLOYMENTS_SUCCESS = "SAVE_MY_EMPLOYMENTS_SUCCESS";
const SAVE_MY_EMPLOYMENTS_FAILURE = "SAVE_MY_EMPLOYMENTS_FAILURE";

const CLEAR_SAVE_MY_EMPLOYMENTS_RESPONSE = "CLEAR_SAVE_MY_EMPLOYMENTS_RESPONSE";

const DELETE_EMPLOYMENT_REQUEST = "DELETE_EMPLOYMENT_REQUEST";
const DELETE_EMPLOYMENT_SUCCESS = "DELETE_EMPLOYMENT_SUCCESS";
const DELETE_EMPLOYMENT_FAILURE = "DELETE_EMPLOYMENT_FAILURE";

const CLEAR_DELETE_EMPLOYMENT_RESPONSE = "CLEAR_DELETE_EMPLOYMENT_RESPONSE";

const CHANGE_EDIT_EMPLOYMENT = "CHANGE_EDIT_EMPLOYMENT";

const UPDATE_EMPLOYMENT_REQUEST = "UPDATE_EMPLOYMENT_REQUEST";
const UPDATE_EMPLOYMENT_SUCCESS = "UPDATE_EMPLOYMENT_SUCCESS";
const UPDATE_EMPLOYMENT_FAILURE = "UPDATE_EMPLOYMENT_FAILURE";

const CLEAR_UPDATE_EMPLOYMENT_RESPONSE = "CLEAR_UPDATE_EMPLOYMENT_RESPONSE";

/*business_seller_constant */
const GET_ALL_BUSINESS_INDUSTRIES = "GET_ALL_BUSINESS_INDUSTRIES";
const INDUSTRIES_LIST_SUCCESS = "INDUSTRIES_LIST_SUCCESS";
const INDUSTRIES_LIST_FAILURE = "INDUSTRIES_LIST_FAILURE";

const SELLER_SERVICE_LIST_REQUEST = "SELLER_SERVICE_LIST_REQUEST";

const SELLER_SERVICE_LIST_SUCCESS = "SELLER_SERVICE_LIST_SUCCESS";
const SELLER_SERVICE_LIST_FAILURE = "SELLER_SERVICE_LIST_FAILURE";

const ALL_SELLER_DATA_REQUEST = "ALL_SELLER_DATA_REQUEST";
const ALL_SELLER_DATA_SUCCESS = "ALL_SELLER_DATA_SUCCESS";
const ALL_SELLER_DATA_FAILURE = "ALL_SELLER_DATA_FAILURE";

const SELLER_ADD_DATA_REQUEST = "SELLER_ADD_DATA_REQUEST";
const SELLER_ADD_DATA_SUCCESS = "SELLER_ADD_DATA_SUCCESS";
const SELLER_ADD_DATA_FAILURE = "SELLER_ADD_DATA_FAILURE";

const CREATE_MAIN_SERVICES_REQUEST = "CREATE_MAIN_SERVICES_REQUEST";
const CREATE_MAIN_SERVICES_SUCCESS = "CREATE_MAIN_SERVICES_SUCCESS";
const CREATE_MAIN_SERVICES_FAILURE = "CREATE_MAIN_SERVICES_FAILURE";

const CREATE_SECONDARY_SERVICES_REQUEST = "CREATE_MAIN_SERVICES_REQUEST";
const CREATE_SECONDARY_SERVICES_SUCCESS = "CREATE_SECONDARY_SERVICES_SUCCESS";
const CREATE_SECONDARY_SERVICES_FAILURE = "CREATE_SECONDARY_SERVICES_FAILURE";



const UPDATE_SELLER_SERVICE_REQUEST = "UPDATE_SELLER_SERVICE_REQUEST";
const UPDATE_SELLER_SERVICE_SUCCESS = "UPDATE_SELLER_SERVICE_SUCCESS";
const UPDATE_SELLER_SERVICE_FAILURE = "UPDATE_SELLER_SERVICE_FAILURE";

const CLEAR_SELLER_SERVICE_RESPONSE = "CLEAR_SELLER_SERVICE_RESPONSE";
const CHANGE_EDIT_SELLER_SERVICE = "CHANGE_EDIT_SELLER_SERVICE";

const DELETE_USER_SERVICE_REQUEST = "DELETE_USER_SERVICE_REQUEST";
const DELETE_USER_SERVICE_SUCCESS = "DELETE_USER_SERVICE_SUCCESS";
const DELETE_USER_SERVICE_FAILURE = "DELETE_USER_SERVICE_FAILURE";

export {
  CLEAR_SELLER_SERVICE_RESPONSE,
  CHANGE_EDIT_SELLER_SERVICE,
  UPDATE_SELLER_SERVICE_REQUEST,
  UPDATE_SELLER_SERVICE_SUCCESS,
  UPDATE_SELLER_SERVICE_FAILURE,
  UPDATE_ADDITIONAL_INFO_REQUEST,
  UPDATE_ADDITIONAL_INFO_SUCCESS,
  UPDATE_ADDITIONAL_INFO_FAILURE,
  CLEAR_UPDATE_INFO_RESPONSE,
  LANGUAGE_LIST_REQUEST,
  LANGUAGE_LIST_SUCCESS,
  LANGUAGE_LIST_FAILURE,
  SKILLS_LIST_REQUEST,
  SKILLS_LIST_SUCCESS,
  SKILLS_LIST_FAILURE,
  SPECIALIZATION_LIST_REQUEST,
  SPECIALIZATION_LIST_SUCCESS,
  SPECIALIZATION_LIST_FAILURE,
  GET_MANAGE_ACCOUNT_REQUEST,
  GET_MANAGE_ACCOUNT_SUCCESS,
  GET_MANAGE_ACCOUNT_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  CLEAR_PROFILE_RESPONSE,
  CHANGE_GENERAL_INFO_INPUT,
  CHANGE_BASIC_INFO_INPUT,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  ADD_NEW_PROJECT,
  CHANGE_MY_PROJECT_DETAIL_INFO,
  SAVE_MY_PROJECTS_REQUEST,
  SAVE_MY_PROJECTS_SUCCESS,
  SAVE_MY_PROJECTS_FAILURE,
  CLEAR_SAVE_MY_PROJECTS_RESPONSE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  CLEAR_DELETE_PROJECT_RESPONSE,
  CHANGE_EDIT_PROJECT,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  CLEAR_UPDATE_PROJECT_RESPONSE,
  GET_EMPLOYMENTS_REQUEST,
  GET_EMPLOYMENTS_SUCCESS,
  GET_EMPLOYMENTS_FAILURE,
  ADD_NEW_EMPLOYMENT,
  CHANGE_MY_EMPLOYMENT_DETAIL_INFO,
  SAVE_MY_EMPLOYMENTS_REQUEST,
  SAVE_MY_EMPLOYMENTS_SUCCESS,
  SAVE_MY_EMPLOYMENTS_FAILURE,
  CLEAR_SAVE_MY_EMPLOYMENTS_RESPONSE,
  DELETE_EMPLOYMENT_REQUEST,
  DELETE_EMPLOYMENT_SUCCESS,
  DELETE_EMPLOYMENT_FAILURE,
  CLEAR_DELETE_EMPLOYMENT_RESPONSE,
  CHANGE_EDIT_EMPLOYMENT,
  UPDATE_EMPLOYMENT_REQUEST,
  UPDATE_EMPLOYMENT_SUCCESS,
  UPDATE_EMPLOYMENT_FAILURE,
  CLEAR_UPDATE_EMPLOYMENT_RESPONSE,
  ADD_SOCIAL_ICON_REQUEST,
  ADD_SOCIAL_ICON_SUCCESS,
  ADD_SOCIAL_ICON_FAILURE,
  GET_SOCIAL_ICON_REQUEST,
  GET_SOCIAL_ICON_SUCCESS,
  GET_SOCIAL_ICON_FAILURE,
  CHANGE_SOCIAL_ICON_INPUT,
  CLEAR_ADD_SOCIAL_RESPONSE,
  GET_ALL_BUSINESS_INDUSTRIES,
  INDUSTRIES_LIST_SUCCESS,
  INDUSTRIES_LIST_FAILURE,
  SELLER_SERVICE_LIST_REQUEST,
  SELLER_SERVICE_LIST_SUCCESS,
  SELLER_SERVICE_LIST_FAILURE,
  ALL_SELLER_DATA_REQUEST,
  ALL_SELLER_DATA_SUCCESS,
  ALL_SELLER_DATA_FAILURE,
  SELLER_ADD_DATA_REQUEST,
  SELLER_ADD_DATA_SUCCESS,
  SELLER_ADD_DATA_FAILURE,
  EXPERTISE_LIST_REQUEST,
  EXPERTISE_LIST_SUCCESS,
  EXPERTISE_LIST_FAILURE,
  DELETE_USER_SERVICE_REQUEST,
  DELETE_USER_SERVICE_SUCCESS,
  DELETE_USER_SERVICE_FAILURE,
  CREATE_MAIN_SERVICES_REQUEST,
  CREATE_MAIN_SERVICES_SUCCESS,
  CREATE_MAIN_SERVICES_FAILURE,
  CREATE_SECONDARY_SERVICES_REQUEST,
  CREATE_SECONDARY_SERVICES_SUCCESS,
  CREATE_SECONDARY_SERVICES_FAILURE
};
