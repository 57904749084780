import {
  CHANGE_ACTIVA_CAROUSAL,
  CHANGE_ALL_POST_DETAIL,
  CHANGE_COMMENT_INPUT_DETAIL,
  CHANGE_LIKE_LIST_PAGE,
  CHANGE_POST_VIEW_DETAIL,
  CHANGE_REACTION_LIST_DETAIL,
  CHANGE_SHARE_INPUT,
  CHANGE_SHARE_LIST_DETAIL,
  CLEAR_COMMENT_REPLY_RESPONSE,
  CLEAR_GET_POST_RESPONSE,
  CLEAR_INVITE_RESPONSE,
  CLEAR_LIKE_DISLIKE_RESPONSE,
  CLEAR_POST_COMMENT_LIST_RESPONSE,
  CLEAR_POST_COMMENT_REPLY_LIST_RESPONSE,
  CLEAR_POST_COMMENT_RESPONSE,
  CLEAR_POST_LIKE_LIST_RESPONSE,
  CLEAR_POST_REACTION_LIST_RESPONSE,
  CLEAR_POST_REACTION_RESPONSE,
  CLEAR_POST_SHARE_LIST_RESPONSE,
  CLEAR_POST_SHARE_RESPONSE,
  CLEAR_POST_UPLOAD_RESPONSE,
  CLEAR_POST_UPDATE_RESPONSE,
  CLEAR_POST_VIEW_LIST_RESPONSE,
  CLEAR_POST_VIEW_RESPONSE,
  CLEAR_SINGLE_LIKE_LIST_RESPONSE,
  CLEAR_SINGLE_LIKE_POST_RESPONSE,
  CLEAR_SINGLE_POST_CMT_REPLY_LIST_RESPONSE,
  CLEAR_SINGLE_POST_CMT_REPLY_RESPONSE,
  CLEAR_SINGLE_POST_COMMENT_LIST_RESPONSE,
  CLEAR_SINGLE_POST_COMMENT_RESPONSE,
  CLEAR_SINGLE_POST_LIST_RESPONSE,
  CLEAR_SINGLE_POST_REACTION_RESPONSE,
  CLEAR_SINGLE_POST_SHARE_LIST_RESPONSE,
  CLEAR_SINGLE_POST_VIEW_RESPONSE,
  CLEAR_SINGLE_REACTION_LIST_RESPONSE,
  INVITE_MODEL_INPUT,
  POST_EDIT_DATA,
} from "./HomeConstants";

//this function is used when invite input change
const changeInviteModelInput = (newState) => {
  return { type: INVITE_MODEL_INPUT, newState };
};
//this function is used for clear invite api response
const clearinviteapiResponse = (newState) => {
  return { type: CLEAR_INVITE_RESPONSE, newState };
};
//this function is used when share post image , video , text  change
const changeSharePostInput = (newState) => {
  return { type: CHANGE_SHARE_INPUT, newState };
};
//this funtion is used when clear post upload api response
const clearPostUploadResponse = (newState) => {
  return { type: CLEAR_POST_UPLOAD_RESPONSE, newState };
};

//this funtion is used when clear post update api response
const clearPostUpdateResponse = (newState) => {
  return { type: CLEAR_POST_UPDATE_RESPONSE, newState };
};

//this function is used when clear get all post api response
const clearGetPostDetail = (newState) => {
  return { type: CLEAR_GET_POST_RESPONSE, newState };
};
//this function is used when particular file details changed in post
const changeActiveCarousal = (newState) => {
  return { type: CHANGE_ACTIVA_CAROUSAL, newState };
};
//this function is used when post detail change
const changeAllPostDetail = (newState) => {
  return { type: CHANGE_ALL_POST_DETAIL, newState };
};
//this function is used when clear like & dislike api response
const clearLikeDislikeResponse = (newState) => {
  return { type: CLEAR_LIKE_DISLIKE_RESPONSE, newState };
};
//this function is used when clear particular image like &  dislike api response
const clearSingleLikeResponse = (newState) => {
  return { type: CLEAR_SINGLE_LIKE_POST_RESPONSE, newState };
};
//this function is used when clear react on post api response
const clearPostReactionResponse = (newState) => {
  return { type: CLEAR_POST_REACTION_RESPONSE, newState };
};
//this function is used when clear react on particular file  api response
const clearSingleReactionResponse = (newState) => {
  return { type: CLEAR_SINGLE_POST_REACTION_RESPONSE, newState };
};
// this function is used when change like list page no
const changeLikeListPage = (newState) => {
  return { type: CHANGE_LIKE_LIST_PAGE, newState };
};
//this function is used when clear like list api response
const clearLikeListResponse = (newState) => {
  return { type: CLEAR_POST_LIKE_LIST_RESPONSE, newState };
};
//this function is used when clear particular file like list api response in post
const clearSingleLikeListResponse = (newState) => {
  return { type: CLEAR_SINGLE_LIKE_LIST_RESPONSE, newState };
};
//this function is used when clear post reaction list api response
const clearPostReactionListResponse = (newState) => {
  return { type: CLEAR_POST_REACTION_LIST_RESPONSE, newState };
};
//this function is used when change post reaction details
const changePostReactionDetails = (newState) => {
  return { type: CHANGE_REACTION_LIST_DETAIL, newState };
};
//this function is used when clear single post reaction list api response
const clearSinglePostReactionListResponse = (newState) => {
  return { type: CLEAR_SINGLE_REACTION_LIST_RESPONSE, newState };
};
//this function is used when change comment details
const changeCommentDetailInput = (newState) => {
  return { type: CHANGE_COMMENT_INPUT_DETAIL, newState };
};
//this function is used when clear comment list api response
const clearCommentListResponse = (newState) => {
  return { type: CLEAR_POST_COMMENT_LIST_RESPONSE, newState };
};
//this function is used when clear comments api response on post
const clearPostCommentResponse = (newState) => {
  return { type: CLEAR_POST_COMMENT_RESPONSE, newState };
};
//this function is used when clear reply on comment api response in post
const clearCommentReplyResponse = (newState) => {
  return { type: CLEAR_COMMENT_REPLY_RESPONSE, newState };
};
//this function is used when clear reply list api response in comments
const clearCommentReplyListResponse = (newState) => {
  return { type: CLEAR_POST_COMMENT_REPLY_LIST_RESPONSE, newState };
};
//this function is used when clear comments on particular file api response in post
const clearSinglePostCommentResponse = (newState) => {
  return { type: CLEAR_SINGLE_POST_COMMENT_RESPONSE, newState };
};
//this function is used when clear particular file comment list api response in post
const clearSinglePostCommentListResponse = (newState) => {
  return { type: CLEAR_SINGLE_POST_COMMENT_LIST_RESPONSE, newState };
};
//this function is used when clear reply on comments in particular file api response in post
const clearSinglePostCmtReplyResponse = (newState) => {
  return { type: CLEAR_SINGLE_POST_CMT_REPLY_RESPONSE, newState };
};
//this function is used when clear comments reply list in particular file api response
const clearSinglePostCmtReplyListResponse = (newState) => {
  return { type: CLEAR_SINGLE_POST_CMT_REPLY_LIST_RESPONSE, newState };
};
//this function is used when clear view post api response
const clearPostViewResponse = (newState) => {
  return { type: CLEAR_POST_VIEW_RESPONSE, newState };
};
//this function is used when clear view particular file api response
const clearSinglePostViewResponse = (newState) => {
  return { type: CLEAR_SINGLE_POST_VIEW_RESPONSE, newState };
};
//this function is used when change view details like view page , view list api response
const changeViewsDetails = (newState) => {
  return { type: CHANGE_POST_VIEW_DETAIL, newState };
};
//this function is used when clear post view list api response
const clearPostViewListResponse = (newState) => {
  return { type: CLEAR_POST_VIEW_LIST_RESPONSE, newState };
};
//this function is used when clear particular file view list api response
const clearSinglePostViewListResponse = (newState) => {
  return { type: CLEAR_SINGLE_POST_LIST_RESPONSE, newState };
};
const clearPostShareResponse = (newState) => {
  return { type: CLEAR_POST_SHARE_RESPONSE, newState };
};
const clearSinglePostShareResponse = (newState) => {
  return { type: CLEAR_POST_SHARE_RESPONSE, newState };
};
const changeShareListDetail = (newState) => {
  return { type: CHANGE_SHARE_LIST_DETAIL, newState };
};
const clearShareListResponse = (newState) => {
  return { type: CLEAR_POST_SHARE_LIST_RESPONSE, newState };
};
const clearSingleShareListResonse = (newState) => {
  return { type: CLEAR_SINGLE_POST_SHARE_LIST_RESPONSE, newState };
};

const changePostDetails = (newState) => {
  return { type: POST_EDIT_DATA, newState };
};

export {
  changeInviteModelInput,
  clearinviteapiResponse,
  changeSharePostInput,
  clearPostUploadResponse,
  clearPostUpdateResponse,
  clearGetPostDetail,
  changeActiveCarousal,
  changeAllPostDetail,
  clearLikeDislikeResponse,
  clearSingleLikeResponse,
  clearPostReactionResponse,
  clearSingleReactionResponse,
  changeLikeListPage,
  clearLikeListResponse,
  clearSingleLikeListResponse,
  clearPostReactionListResponse,
  changePostReactionDetails,
  clearSinglePostReactionListResponse,
  changeCommentDetailInput,
  clearCommentListResponse,
  clearPostCommentResponse,
  clearCommentReplyResponse,
  clearCommentReplyListResponse,
  clearSinglePostCommentResponse,
  clearSinglePostCommentListResponse,
  clearSinglePostCmtReplyResponse,
  clearSinglePostCmtReplyListResponse,
  clearSinglePostViewResponse,
  clearPostViewResponse,
  changeViewsDetails,
  clearPostViewListResponse,
  clearSinglePostViewListResponse,
  changeShareListDetail,
  clearPostShareResponse,
  clearSinglePostShareResponse,
  clearShareListResponse,
  clearSingleShareListResonse,
  changePostDetails,
};
