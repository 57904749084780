import React, { forwardRef, useEffect, useRef, useState } from "react";

import { getCookie } from "../../library/utilities/functions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import dayGridPlugin from "@fullcalendar/daygrid";
import { registerLocale } from "react-datepicker";
import timeGridPlugin from "@fullcalendar/timegrid";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { SOCKET } from "../../library/urls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import {
  addAvailability,
  cancleEventRequestApi,
  deleteAvailabilityApi,
  getAllAvailabilityApi,
  rescheduleEventRequestApi,
  sendEventRequest,
  updateUserAvailability,
} from "./PoolsChatAction";
import { EVENT_REQUEST_NAME } from "../../library/common/constants/NameConstant";
import CustomeSelect from "./CustomeSelect";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Form } from "react-bootstrap";

const RequestModal = forwardRef(
  ({
    messageDetails,
    messageId,
    editAppointent,
    setEditAppointment,
    requestData,
    updateEventMessage,
    setRequestData,
    storeEventDetails,
  }) => {
    registerLocale("es", es);

    //hooks//
    const history = useHistory();
    const closeEl = useRef();

    const { addToast } = useToasts();
    const dispatch = useDispatch();

    // browser storage//
    const profileData = !!getCookie("profile_data")
      ? JSON.parse(getCookie("profile_data"))
      : "";

    // states//
    const [customeValue, setCustomValue] = useState(false);
    const [isCalendarShow, setIsCalendarShow] = useState(false);
    const [isShowAvailabilityFields, setIsShowAvailabilityFields] =
      useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [currentDate, setCurrentDate] = useState(new Date());
    const [descriptionError, setDescriptionError] = useState(false);
    const [customTitleError, setCustomeTitleError] = useState(false);

    const [dateModalOpen, setDateModalOpen] = useState(true);

    const [addAvailabilityModal, setAddAvailabilityModal] = useState(false);
    const [editAvailabilityLoading, setEditAvailabilityLoading] =
      useState(false);
    const [recentAvailability, setRecentAvailability] = useState({});

    const [upadatedAvailability, setUpdatedAvailability] = useState([]);
    const [getSingleEvent, setGetSingleEvent] = useState({});
    const [hideModalOne, setHideModalOne] = useState("");
    const [showEditAvailability, setShowEditAvailability] = useState(false);
    const [events, setEvents] = useState([]);

    const [availabilityFields, setAvailabilityFields] = useState({
      id: null,
      created_from: profileData?.id,
      availability_title: "",
      start: new Date(),
      end: "",
    });
    const [saveAvailabilityLoad, setSaveAvailabilityLoad] = useState(false);
    const [availableSlots, setAvailableSlots] = useState([]);
    // error handling state //
    const [filedsErrors, setFieldsErrors] = useState({
      title_error: false,
      date_error: false,
    });


    // redux//
    const allAvailabilitySlots = useSelector((state) => state.PoolsChatReducer);

    const {
      getAllAvailabilitySlots: { allAvailabilitySlotsData },
    } = allAvailabilitySlots;

    // static Request Nature data//
    const options = [
      { value: "Quote", label: "Quote", icon: "ri-file-list-3-line" },
      {
        value: "Appointment",
        label: "Appointment",
        icon: "ri-calendar-todo-fill",
      },
      { value: "Custom", label: "Custom", icon: "ri-tools-line" },
    ];

    // custome style object //
    const customStyles = {
      control: (provided) => ({
        ...provided,

        // Set the desired width here
      }),

      option: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        maxHeight: "54px",
      }),
    };

    // customeOption  input field//
    const customOption = ({ innerProps, label, data }) => (
      <div
        {...innerProps}
        style={{ padding: "2px", display: "flex", alignItems: "center" }}
      >
        <i className={data.icon} style={{ margin: "10px" }}></i>
        {label}
      </div>
    );

    // function to schedule appointment//
    const handleScheduleAppointment = (e, requestType) => {
      if (
        requestData.request_nature === "Custom" &&
        requestData.title.length < 1
      ) {
        setCustomeTitleError(true);
        return;
      }
      // --------IF NO TITLE ERROR START EVENT FUNCTIONALITY-----//
      else {
        setCustomeTitleError(false);
        setEditAppointment(false);

        var frdMesageStatus = {
          friend_id: "",
          online: { read: false, unread: true, reciever_id: "" },
          offline: false,
        };

        e.preventDefault();
        var message = {
          session_id: getCookie("token_id"),
          message: requestData,
          sender_name: !!profileData.is_individual
            ? profileData.name
            : profileData.business_name,
          device_token: null,
          created_at: new Date(),
          frds_acknowledged: 1,
          message_id: requestType === "reschedule" ? messageId : uuidv4(),
          event_id: requestData?.event_id ? requestData?.event_id : "",
          messageStatus: frdMesageStatus,
          sender_id: Number(profileData?.id),
          ignore_user_from_id: 1,
          ignore_user_to_id: 1,
          is_individual: profileData?.is_individual === 1 ? 1 : 0,
          is_business: profileData?.is_individual === 0 ? 1 : 0,
          type: messageDetails?.type,
          message_type: 3,
          reciever_id:
            messageDetails?.type === "user"
              ? history?.location?.pathname.includes("/search-profile")
                ? messageDetails?.id
                : messageDetails?.user_id
              : messageDetails?.id,
        };

        if (messageDetails?.type === "group") {
          message.group_id = messageDetails?.id;
        }
        if (requestType === "cancel") {
          message.isCancel = 1;
        }

        if (e.target.name === "reschedule") {
          // CASE :- if request reschedule //

          function isModified(value) {
            // check the status of the acknowledgement//
            if (value?.status === 400) {
              addToast(value?.message, {
                appearance: "error",
                autoDismiss: true,
              });
            } else if (value?.status === 200) {
              // if status 200 hit api of reschedule request notification //

              dispatch(
                rescheduleEventRequestApi({
                  event_id: requestData.event_id,
                  notification_type:
                    EVENT_REQUEST_NAME.RESCHEDULE_EVENT_REQUEST,
                  from: storeEventDetails?.user_from_id,
                  to: storeEventDetails?.user_to_id,
                  message_id: message?.message_id,
                })
              )
                .then((res) => {
                  if (res.response.data.status === 200) {
                    updateEventMessage(message);
                    SOCKET.emit("get-schedule-events", {
                      sender_id: profileData.id,
                      reciever_id:
                        messageDetails?.type === "user"
                          ? history?.location?.pathname.includes(
                              "/search-profile"
                            )
                            ? messageDetails?.id
                            : messageDetails?.user_id
                          : messageDetails?.id,
                      page: 1,
                      action: false,
                    });

                    addToast("Request modified successfull.", {
                      appearance: "success",
                      autoDismiss: true,
                    });
                  }
                })
                .catch((err) => {
                  addToast("Request not modified try again.", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                });
            }
          }
          // emit event for modify request with msg obj and callback aknowledgement//

          SOCKET.emit("update-schedule-event", message, isModified);
        } else {
          // CASE :-create new event request event //

          // when create event success//
          function isSuccess(value) {
            // check status of acknowledegement //
            if (value?.status === 400) {
              addToast(value?.message, {
                appearance: "error",
                autoDismiss: true,
              });
            } else if (value?.status === 200) {
              // setRecentAvailability({});
              dispatch(
                sendEventRequest({
                  event_id: value.event_id,
                  notification_type: EVENT_REQUEST_NAME.CREATE_EVENT_REQUEST,
                  from: value.sender_id,
                  to: value.reciever_id,
                  message_id: message?.message_id,
                })
              )
                .then((res) => {
                  if (res.response.data.status === 200) {
                    SOCKET.emit("get-schedule-events", {
                      sender_id: profileData.id,
                      reciever_id:
                        messageDetails?.type === "user"
                          ? history?.location?.pathname.includes(
                              "/search-profile"
                            )
                            ? messageDetails?.id
                            : messageDetails?.user_id
                          : messageDetails?.id,
                      page: 1,
                      action: false,
                    });

                    addToast("Request sent successfull.", {
                      appearance: "success",
                      autoDismiss: true,
                    });
                  }
                })
                .catch((err) => {
                  addToast("Something went wrong try again", {
                    appearance: "error",
                    autoDismiss: true,
                  });
                });
            }
          }

          // emit event of sechedule on new event request with msg obj and cb acknowledement //

          SOCKET.emit("schedule-event", message, isSuccess);
        }
        setCustomValue(false);
        closeEl.current.click;
      }
    };

    //----function to close request modal-----//
    const handleCloseRequestModal = () => {
      const modal = new bootstrap.Modal(
        document.getElementById("request-modal")
      );
      modal.hide;
      var backdrop = document.querySelector(".modal-backdrop");
      backdrop.parentNode.removeChild(backdrop);
      setCustomeTitleError(false);
      setCustomValue(false);
      // setEvents([]);
      setRequestData({
        date: new Date(),
        request_nature: "Quote",
        description: "",
        reschedule: false,
        iscancel: false,
        title: "",
        is_accepted: false,
        is_declined: false,
      });

      setStartDate(new Date());
      // setDateModalOpen(false);
    };

    //---openDateModal----//
    const openDateModal = () => {
      // setDateModalOpen(!dateModalOpen);
    };

    // change fields of request modal//
    const handleChange = (event, customeValue = "") => {
      if (customeValue === "") {
        const { name, value } = event.target;
        if (name === "description" && value.length > 150) {
          setDescriptionError(true);
        } else {
          setDescriptionError(false);
        }
        setRequestData({
          ...requestData,
          [name]: value,
        });
      } else {
        if (event?.value === "Custom" && customeValue === "request_nature") {
          setCustomValue(true);
          setRequestData({
            ...requestData,
            title: event?.value,
          });
        } else if (
          customeValue === "request_nature" &&
          event?.value !== "Custom"
        ) {
          setCustomValue(false);
          setCustomeTitleError(false);
          setRequestData({
            ...requestData,
            title: "",
          });
        }
        setRequestData({
          ...requestData,
          [customeValue]: event.value,
        });
      }
    };

    // change data and time
    const handleDateTimeChange = (date) => {
      setStartDate(date);
      setRequestData({
        ...requestData,
        date: date,
      });
    };

    // function to hide first modal when second modal is open//
    const handleHideModalOne = (data) => {
      setIsCalendarShow(true);
      setHideModalOne("add-availability");
      try {
        dispatch(getAllAvailabilityApi(profileData?.id));
      } catch (err) {
        throw err;
      }
    };

    // handle close caledar modal//
    const handleCloseCalendarModal = (params) => {
      if (params === "cross") {
        setIsCalendarShow(false);
        setHideModalOne("");
        // setIsShowAvailabilityFields(false);
      } else if (params === "select-date") {
        setIsCalendarShow(false);
        setIsShowAvailabilityFields(true);
      }
    };

    const handleCloseEditAvailability = (data) => {
      if (data === "cross") {
        setShowEditAvailability(false);
        setIsCalendarShow(true);
      } else if (data === "delete") {
        setEditAvailabilityLoading(true);

        dispatch(
          deleteAvailabilityApi({ user_availability_id: getSingleEvent?.id })
        )
          .then((result) => {
            if (result.response.status === 200) {
              const deleteSlots = availableSlots.filter((item) => {
                return item.id != getSingleEvent?.id;
              });
              setAvailableSlots(deleteSlots);

              addToast(result?.response?.data?.success, {
                appearance: "success",
                autoDismiss: true,
              });
              setShowEditAvailability(false);
              setEditAvailabilityLoading(false);

              setIsCalendarShow(true);
            }
          })
          .catch((err) => {
            addToast(err, {
              appearance: "error",
              autoDismiss: true,
            });
            setEditAvailabilityLoading(false);
          });
      } else if (data === "update") {
        if (getSingleEvent?.title.trim() === "") {
        } else {
          let payloadData = {
            user_availability_id: Number(getSingleEvent?.id),
            title: getSingleEvent?.title,
            start_date: moment(getSingleEvent?.start).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            end_date: moment(getSingleEvent?.end).format("YYYY-MM-DD HH:mm:ss"),
            created_from: Number(profileData?.id),
          };
          setEditAvailabilityLoading(true);
          dispatch(updateUserAvailability(payloadData))
            .then((result) => {
              if (result?.response?.status === 200) {
                setUpdatedAvailability(result?.response?.data.data);
                setIsCalendarShow(true);
                setEditAvailabilityLoading(false);

                addToast(result?.response?.data?.message, {
                  appearance: "success",
                  autoDismiss: true,
                });
                setShowEditAvailability(false);
              }
            })
            .catch((err) => {
              addToast("Error in update availability", {
                appearance: "error",
                autoDismiss: true,
              });
              setEditAvailabilityLoading(true);
            });
        }
      }
    };

    // function to set availability time fields//
    const handleAvailabilityFields = (e, name) => {
      if (name === "availability_title") {
        setAvailabilityFields({
          ...availabilityFields,
          [e.target.name]: e.target.value,
        });
      } else if (name === "start") {
        setAvailabilityFields({ ...availabilityFields, [name]: e });
      } else {
        setAvailabilityFields({ ...availabilityFields, [name]: e });
      }
    };

    //------close availability modal and set Availability Fields to initial state----//
    const closeAvailabilityFields = (params) => {
      if (params === "close-fields") {
        setEvents([]);
        setIsCalendarShow(true);
        setIsShowAvailabilityFields(false);
        setFieldsErrors({
          title_error: false,
          date_error: false,
        });
        setAvailabilityFields({
          availability_title: "",
          start: new Date(),
          end: "",
        });
      } else if (params === "save-btn") {
        setSaveAvailabilityLoad(true);
        if (availabilityFields?.availability_title?.length === 0) {
          setFieldsErrors({ ...filedsErrors, title_error: true });
          setSaveAvailabilityLoad(false);
        } else if (
          availabilityFields?.end === "" ||
          availabilityFields?.end?.getTime() <=
            availabilityFields?.start.getTime()
        ) {
          setSaveAvailabilityLoad(false);

          setFieldsErrors({ ...filedsErrors, date_error: true });
        } else {
          let payloadData = {
            title: availabilityFields?.availability_title,
            start_date: moment(availabilityFields?.start).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            end_date: moment(availabilityFields?.end).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            created_from: profileData?.id,
          };

          dispatch(addAvailability(payloadData))
            .then((result) => {
              if (result?.response?.status === 200) {
                addToast("Availability added successfull", {
                  appearance: "success",
                  autoDismiss: true,
                });
                setSaveAvailabilityLoad(false);

                let newSlot = {
                  id: result?.response?.data?.data?.id,
                  title: result?.response?.data?.data?.title,
                  start: result?.response?.data?.data?.start_date,
                  end: result?.response?.data?.data?.end_date,
                };

                // setRecentAvailability(newSlot);

                // setAvailableSlots([...availableSlots, newSlot]);
                setAddAvailabilityModal(true);

                setIsCalendarShow(true);
                setIsShowAvailabilityFields(false);
              } else {
                addToast("Something went wrong please try again", {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            })
            .catch((err) => {
              setSaveAvailabilityLoad(false);
            });

          setFieldsErrors({
            ...filedsErrors,
            date_error: false,
            title_error: false,
          });
        }
      }
    };

    // hide calendar modal

    useEffect(() => {
      if (!!requestData) {
        const dateString = requestData?.date;
        const date = new Date(dateString);
        setStartDate(date);
      }
    }, [requestData]);

    const handleEventClick = (info) => {
      setShowEditAvailability(true);
      setIsCalendarShow(false);
      const event = info.event;
      setGetSingleEvent({
        id: event.id,
        title: event.title,
        start: event.start,
        end: event.end,
      });
    };

    useEffect(() => {
      if (allAvailabilitySlotsData?.length > 0) {
        const slotsArr = allAvailabilitySlotsData.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
            start: item?.start_date,
            end: item?.end_date,
            created_from: item?.created_from,
          };
        });
        if (slotsArr?.length > 0) {
          setAvailableSlots(slotsArr);
        }
      }
    }, [allAvailabilitySlotsData]);

    const handleEditDateAvailability = (e, type) => {
      if (type === "title") {
        setGetSingleEvent({ ...getSingleEvent, [type]: e.target.value });
      } else {
        setGetSingleEvent({ ...getSingleEvent, [type]: e });
      }
    };

    useEffect(() => {
      if (upadatedAvailability?.length > 0) {
        setAvailableSlots(updateUserAvailability);
      }
    }, [updateUserAvailability]);

    return (
      <>
        {/* Request Modal */}
        <div
          className={`${
            hideModalOne === "add-availability" ? "request-modal-close" : ""
          }`}
        >
          <div
            className="modal fade "
            id="request-modal"
            data-backdrop="static"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg   " role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img
                      src="/assets/images/icon.svg"
                      alt="icon"
                      className="site-icon"
                    />
                    {` Send a request to  ${
                      messageDetails?.user_name || messageDetails?.name
                    }`}
                  </h5>
                  <button
                    onClick={handleCloseRequestModal}
                    ref={closeEl}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="feeds-opt">
                        <label>Nature of the request</label>
                        {/* <CustomeSelect options={options}/> 
                        
                        
                        
                        */}

                        <Select
                          options={options}
                          name="request_nature"
                          value={
                            requestData?.request_nature?.length > 0 && {
                              label: requestData.request_nature,
                              value: requestData.request_nature,
                            }
                          }
                          placeholder="Name of the request*"
                          onChange={(opt) =>
                            handleChange(opt, "request_nature")
                          }
                          components={{ Option: customOption }}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      {dateModalOpen && (
                        <div className="date_req_input">
                          <label>Date and time</label>
                          <DatePicker
                            name="date"
                            className="form-control"
                            selected={startDate}
                            onChange={handleDateTimeChange}
                            // locale="es"
                            showTimeSelect
                            timeFormat="p"
                            minDate={currentDate}
                            minTime={
                              startDate.toDateString() ===
                              new Date().toDateString()
                                ? new Date().getTime()
                                : new Date().setHours(0, 0, 0)
                            }
                            maxTime={new Date().setHours(23, 59, 59)}
                            timeIntervals={5}
                            dateFormat="Pp"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 mb-3">
                      {!!customeValue && (
                        <div>
                          <label>Input Your Custom Request</label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            onChange={handleChange}
                            style={{ minHeight: "57px" }}
                          />
                          {customTitleError && (
                            <p className="text-danger">
                              Please enter your custom title
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <textarea
                      placeholder=" Write a description"
                      value={requestData?.description}
                      name="description"
                      onChange={handleChange}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>

                  {/* {availableSlotsError &&
                    recentAvailability?.start === undefined && (
                      <p className="text-danger">
                        Please add your availability time
                      </p>
                    )} */}
                  {recentAvailability?.start !== undefined && (
                    <>
                      <label>Your available time slots</label>
                      <div className="row">
                        <div className="col-md-6 date_req_input mb-4">
                          <input
                            name="availability_title"
                            value={`${moment(recentAvailability?.start).format(
                              "DD/MM/YY hh:mm A"
                            )} - ${moment(recentAvailability?.start).format(
                              "hh:mm A"
                            )}`}
                            type="text"
                            className="w-100 form-control"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {!!descriptionError && (
                    <p className="text-danger">
                      Message too long. Maximum 150 characters allowed.
                    </p>
                  )}
                </div>
                <div className="modal-footer">
                  <div className="row w-100">
                    <div className="col-md-4 px-0">
                      {/* <div className="request__type d-flex justify-content-start">
                        <button className="event-time">
                          <img
                            src="assets/images/request-calendar.png"
                            alt="Schedule"
                          />{" "}
                          Schedule a time
                        </button>
                      </div> */}
                    </div>
                    {/* <span className="seprater"></span> */}
                    <div className="col-md-4 px-0">
                      <div className="request__type d-flex justify-content-start">
                        {/* <button
                          className="event-time"
                          data-toggle="modal"
                          data-target="#availabilityModal"
                          onClick={() => handleHideModalOne("add-availability")}
                        >
                          <img
                            src="assets/images/icons8-clock-48.png"
                            alt="Schedule"
                            style={{ height: "32px" }}
                          />{" "}
                          Add your availability
                        </button> */}
                      </div>
                    </div>

                    <div className="col-md-12 text-md-right mt-3">
                      <button
                        name={`${!!editAppointent ? "reschedule" : "Request"}`}
                        data-dismiss={
                          requestData.request_nature === "Custom"
                            ? requestData.title.length > 0 && "modal"
                            : "modal"
                        }
                        disabled={
                          !!descriptionError ||
                          requestData?.request_nature === ""
                        }
                        onClick={(e) =>
                          handleScheduleAppointment(
                            e,
                            !!editAppointent ? "reschedule" : "Request"
                          )
                        }
                        className={"btn btn-primary btn-sm ml-2"}
                      >
                        {!!editAppointent ? "Modify request" : "Request"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {REQUEST MODAL END}  */}

        {/* CALENDAR MODAL START */}
        <Modal
          show={isCalendarShow}
          onHide={() => handleCloseCalendarModal("cross")}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <img
                src="/assets/images/icon.svg"
                alt="icon"
                className="site-icon"
              />
              {`Send your available time slots to ${messageDetails?.user_name}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div
              style={{
                minHeight: "500px",
                width: "100%",
              }}
            >
              {
                <FullCalendar
                  height="800px"
                  width="400px"
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  eventDisplay="block"
                  eventClick={handleEventClick}
                  editable={true}
                  initialView="timeGridWeek"
                  progressiveEventRendering={true}
                  events={availableSlots}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "timeGridWeek,list",
                  }}
                  eventContent={(eventInfo) => {
                    // Customize the event rendering
                    return (
                      <div
                        style={{
                          backgroundColor: "#3498ff",
                          color: "white",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <b>{eventInfo.timeText}</b>
                        <p>{eventInfo.event.title}</p>
                      </div>
                    );
                  }}
                />
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => handleCloseCalendarModal("select-date")}
            >
              Select Date
            </Button>
          </Modal.Footer>
        </Modal>

        {/* CALENDAR MODAL END */}
        {/* Avilabilty Modal fields start*/}

        <Modal
          show={isShowAvailabilityFields}
          onHide={() => closeAvailabilityFields("close-fields")}
        >
          <Modal.Header closeButton>
            <Modal.Title> Add Availability Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div>
                <p>Title</p>
                <input
                  name="availability_title"
                  value={availabilityFields?.availability_title}
                  onChange={(e) =>
                    handleAvailabilityFields(e, "availability_title")
                  }
                  type="text"
                  className="w-100 form-control"
                />
                {filedsErrors?.title_error && (
                  <p className="text-danger">
                    Please enter availability's title
                  </p>
                )}
              </div>
              <div className=" mt-3 time-fields">
                <div className="row">
                  <div className="col-md-6 date_req_input">
                    <p>Start Date Time</p>
                    <DatePicker
                      name="start_time"
                      selected={
                        availabilityFields?.start === ""
                          ? new Date()
                          : availabilityFields?.start
                      }
                      onChange={(e) => handleAvailabilityFields(e, "start")}
                      // locale="es"
                      showTimeSelect
                      timeFormat="p"
                      minDate={currentDate}
                      minTime={
                        availabilityFields?.start !== "" &&
                        availabilityFields?.start.toDateString() ===
                          new Date().toDateString()
                          ? new Date().getTime()
                          : new Date().setHours(0, 0, 0)
                      }
                      maxTime={new Date().setHours(23, 59, 59)}
                      timeIntervals={1}
                      dateFormat="Pp"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 date_req_input">
                    <p>End Date Time</p>
                    <DatePicker
                      name="date"
                      selected={
                        availabilityFields?.end !== ""
                          ? availabilityFields?.end
                          : availabilityFields?.start
                      }
                      onChange={(e) => handleAvailabilityFields(e, "end")}
                      // locale="es"

                      showTimeSelect
                      timeFormat="p"
                      minDate={
                        availabilityFields?.start !== ""
                          ? availabilityFields?.start
                          : new Date()
                      }
                      minTime={
                        availabilityFields?.start.toDateString() ===
                        new Date().toDateString()
                          ? new Date().getTime()
                          : new Date().setHours(0, 0, 0)
                      }
                      maxTime={new Date().setHours(23, 59, 59)}
                      timeIntervals={1}
                      dateFormat="Pp"
                      className="form-control"
                    />
                  </div>
                  {filedsErrors?.date_error && (
                    <p className="text-danger px-3">
                      End date and time must be greater than Start date and
                      time.
                    </p>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={saveAvailabilityLoad}
              variant="primary"
              onClick={() => closeAvailabilityFields("save-btn")}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Avilabilty Modal fields end*/}

        {/* Edit Availability start */}
        <Modal
          show={showEditAvailability}
          onHide={() => handleCloseEditAvailability("cross")}
        >
          <Modal.Header closeButton>
            <Modal.Title> Edit Availability Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div>
              <p>Title</p>
              <input
                name="title"
                value={getSingleEvent?.title}
                onChange={(e) => handleEditDateAvailability(e, "title")}
                type="text"
                className="w-100 form-control"
              />

              {filedsErrors?.title_error && (
                <p className="text-danger">Please enter availability's title</p>
              )}
            </div>
            <div className=" mt-3 time-fields">
              <div className="row">
                <div className="col-md-6 date_req_input">
                  <p>Start Date Time</p>
                  <DatePicker
                    name="start_time"
                    selected={getSingleEvent?.start}
                    onChange={(e) => handleEditDateAvailability(e, "start")}
                    // locale="es"
                    showTimeSelect
                    timeFormat="p"
                    minDate={currentDate}
                    minTime={
                      getSingleEvent?.start?.toDateString() ===
                      new Date().toDateString()
                        ? new Date().getTime()
                        : new Date().setHours(0, 0, 0)
                    }
                    maxTime={new Date().setHours(23, 59, 59)}
                    timeIntervals={1}
                    dateFormat="Pp"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 date_req_input">
                  <p>End Date Time</p>
                  <DatePicker
                    name="date"
                    selected={getSingleEvent?.end}
                    onChange={(e) => handleEditDateAvailability(e, "end")}
                    // locale="es"

                    showTimeSelect
                    timeFormat="p"
                    minDate={
                      getSingleEvent?.start !== ""
                        ? getSingleEvent?.start
                        : new Date()
                    }
                    minTime={
                      getSingleEvent?.start?.toDateString() ===
                      new Date().toDateString()
                        ? new Date().getTime()
                        : new Date().setHours(0, 0, 0)
                    }
                    maxTime={new Date().setHours(23, 59, 59)}
                    timeIntervals={1}
                    dateFormat="Pp"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={editAvailabilityLoading}
              variant="secondary"
              onClick={() => handleCloseEditAvailability("delete")}
            >
              Delete
            </Button>
            <Button
              disabled={editAvailabilityLoading}
              variant="primary"
              onClick={() => handleCloseEditAvailability("update")}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Edit Availability end */}
      </>
    );
  }
);
export default RequestModal;
