
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  searchListDataApi,
} from "../../library/api/SearchApiService";

import AllSearchList from "./AllSearchList";
import PeopleAndBusinessList from "./PeopleAndBusinessList";
import {
  clearSendRequestResponse,
} from "./SearchListAction";
import ServicesSearchList from "./ServicesSearchList";
import { useLocation } from "react-router-dom";


const allTabs = [
  {
    id: 1,
    name: "All",
    href: 'sectionA',
  },
  {
    id: 2,
    name: "Services",
    href: 'sectionB',
  },
  {
    id: 3,
    name: "People & Business",
    href: 'sectionC',
  }
]


const SearchList = () => {
  const [activeTab, setActiveTab] = useState('')
  let { pathname } = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const searchListState = useSelector((state) => state.SearchListReducer);
  const toolbarState = useSelector((state) => state.ToolbarReducer);

  const {
    searchNameInput: { reduxSearchName },
  } = toolbarState;
  const {
    searchList,
  } = searchListState;
  const {
    sendRequestApi: {
      sendRequestMessage,
      sendRequestStatus,
    },
  } = searchListState;

  useEffect(() => {
    if (!!sendRequestStatus && sendRequestStatus == "200") {
      addToast(sendRequestMessage, {
        appearance: "success",
        autoDismiss: true,
      
      });
      const bodyParameter = {
        search: reduxSearchName,
      };
      dispatch(searchListDataApi(bodyParameter));
      dispatch(clearSendRequestResponse());
    }
    if (sendRequestStatus == "400") {
      dispatch(clearSendRequestResponse());
      const bodyParameter = {
        search: reduxSearchName,
      };
      dispatch(searchListDataApi(bodyParameter));
    }
  }, [sendRequestStatus]);

  const handleTabs = (data) => {
    if (data === 'All') {
      setActiveTab(data)
      localStorage.setItem('tabActive', data)
    } else if (data === 'Services') {
      localStorage.setItem('tabActive', data)
      setActiveTab(data)
    } else if (data == 'People & Business') {
      localStorage.setItem('tabActive', data)
      setActiveTab(data)
    }
  }

  useEffect(() => {
    let getLocatTab = localStorage.getItem('tabActive')
    const isClickOnSeachProfile = localStorage.getItem('ClickOnSeachProfile')
    var boolValue = JSON.parse(isClickOnSeachProfile);
    if (pathname === "/search-list" && boolValue) {
      localStorage.setItem('tabActive', "All")
    } else {
      localStorage.setItem('tabActive', getLocatTab)
    }
  }, [])
  useEffect(() => {
    let tabsData = localStorage.getItem('tabActive')
    if (tabsData) {
      setActiveTab(tabsData)
    } else {
      setActiveTab("All")
    }
  }, [activeTab])

  return (
    <>
      <section className="hero-inner py-3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <ul className="search-filter  nav nav-pills " id="myTab">
                {allTabs.map((items, i) => {
                  return (
                    <li className="nav-item " key={i}>
                      <a className={`nav-link ${items.name == activeTab ? 'active' : null}`} data-toggle="tab" onClick={() => handleTabs(items.name)} style={{ cursor: "pointer" }} >
                        {items.name}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
      {activeTab === "All" ? <AllSearchList searchList={searchList} /> : null}
      {activeTab === "Services" ? <ServicesSearchList searchList={searchList} /> : null}
      {activeTab === "People & Business" ? <PeopleAndBusinessList searchList={searchList} /> : null}
    </>
  );
};
export default SearchList;
