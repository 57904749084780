import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IMAGE_BASE_URL } from "../../library/urls";

const SearchListMembers = () => {
  const searchListMembersState = useSelector(
    (state) => state.SearchMembersListReducer
  );
  const [addSelectedIndividualId, setAddSelectedIndividualId] = useState([]);
  const [selectedIndividualId, setSelectedIndividualId] = useState([]);
  const networkState = useSelector((state) => state.MyNetworkReducer);

  const {
    friendListDetail: { friendList },
  } = networkState;
  const {
    searchApi: { searchListSuccess },
    searchList,
  } = searchListMembersState;

  const handleAddIndividual = () => {
    let added = $(".add-individual-member").find("input:checked");

    if (added.length > 0) {
      let individaulAdd = [];
      for (let i = 0; i < added.length; i++) {
        individaulAdd.push(added[i].id);
      }
      setAddSelectedIndividualId(individaulAdd);
    } else {
      setAddSelectedIndividualId([]);
    }
  };

  const handlleAddClick = () => {
    const newData = [];
    for (let i = 0; i < addSelectedIndividualId.length; i++) {
      friendList
        .filter((user) => user.user_id === Number(addSelectedIndividualId[i]))
        .map((item) => {
          newData.push(item);
        });
    }
    setSelectedIndividualId(newData);
  };
  return (
    <>
      <>
        {!!searchList && !!searchListSuccess ? (
          <section className="hero-inner py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="text-white  text-capitalize mb-0 h5">
                    {searchList.length} Search result found
                  </h1>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {searchList.length > 0 && (
          <section className="search-users bg-primary spacer search-result">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mx-auto full-md">
                  {searchList.map((data) => (
                    <div className="search-users__wrapper d-flex flex-wrap align-items-center bg-white p-4 br-20 position-relative mb-3">
                      {!!data.is_individual && (
                        <>
                          <div className="search-users__img d-flex align-items-center">
                            <div
                              className="add-individual-member"
                              onClick={() => handleAddIndividual()}
                            >
                              <input type="checkbox" id={data.id} />
                              <img
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/member-default.svg")
                                }
                                src={
                                  !!data
                                    ? IMAGE_BASE_URL + data.profile_image
                                    : "/assets/images/member-default.svg"
                                }
                                alt="user"
                              ></img>
                            </div>
                          </div>
                          <div className="search-users__detail">
                            <div className="link-color">
                              <h5 className="text-capitalize mb-0">
                                {data.name}
                              </h5>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <button className="btn btn-primary" onClick={handlleAddClick}>
                {" "}
                Add
              </button>
            </div>
          </section>
        )}
      </>

      <>
        {selectedIndividualId.map((item) => {
          return (
            <div className="create-footer-group">
              <img
                src={
                  !!item.user_profile && item.user_profile !== " "
                    ? IMAGE_BASE_URL + item.user_profile
                    : "/assets/images/member-default.svg"
                }
                onError={(ev) =>
                  (ev.target.src = "/assets/images/member-default.svg")
                }
                alt="user"
                className="user-profile"
              />
              <i className="ri-close-line position-relative text-white"></i>
            </div>
          );
        })}
      </>
    </>
  );
};
export default SearchListMembers;
