const FORGET_PASSWORD_INPUT = "FORGET_PASSWORD_INPUT";

const CLEAR_FORGET_PASSWORD_INPUT = "CLEAR_FORGET_PASSWORD_INPUT";

const FORGET_PASSWORD_DATA_REQUEST = "FORGET_PASSWORD_DATA_REQUEST";
const FORGET_PASSWORD_DATA_SUCCESS = "FORGET_PASSWORD_DATA_SUCCESS";
const FORGET_PASSWORD_DATA_FAILURE = "FORGET_PASSWORD_DATA_FAILURE";

const CLEAR_FORGET_API_RESPONSE = "CLEAR_FORGET_API_RESPONSE";

export {
  FORGET_PASSWORD_INPUT,
  CLEAR_FORGET_PASSWORD_INPUT,
  FORGET_PASSWORD_DATA_REQUEST,
  FORGET_PASSWORD_DATA_SUCCESS,
  FORGET_PASSWORD_DATA_FAILURE,
  CLEAR_FORGET_API_RESPONSE,
};
