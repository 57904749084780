import React, { useEffect, useRef, useState } from "react";
import $, { data } from "jquery";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { Breadcrumb } from "react-bootstrap";
import Microlink from "@microlink/react";
import { IMAGE_BASE_URL } from "../../../library/urls";
import { useDispatch, useSelector } from "react-redux";
import {
  getPostApi,
  getPromotionalHub,
  likeDislikeApi,
  postCommentApi,
  postCommentListApi,
  postCommentReplyApi,
  postCommentReplyListApi,
  postFeedShareApi,
  postFeedShareListApi,
  postLikeListApi,
  postReactionApi,
  postReactListApi,
  postViewApi,
  postViewListApi,
  singlePostCommentListApi,
} from "../../../library/api/HomeApiService";
import {
  changeActiveCarousal,
  changeAllPostDetail,
  changeCommentDetailInput,
  changeLikeListPage,
  changePostDetails,
  changePostReactionDetails,
  changeShareListDetail,
  changeSharePostInput,
  changeViewsDetails,
  clearCommentListResponse,
  clearCommentReplyListResponse,
  clearCommentReplyResponse,
  clearGetPostDetail,
  clearLikeDislikeResponse,
  clearLikeListResponse,
  clearPostCommentResponse,
  clearPostReactionListResponse,
  clearPostReactionResponse,
  clearPostShareResponse,
} from "../HomeActions";
import {
  addDefaultSrc,
  numFormatter,
  returnDefaultImage,
  scroolTop,
  useForceUpdate,
} from "../../../library/utilities/functions";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useHistory, useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Input from "../../../library/common/components/Input/Input";
import { checkIsPostClicked } from "../../SearchProfile/SearchProfileAction";
import { deleteMyPostApi } from "../../../library/api/ProfilePostApiService";
import { clearMyDetelePostResponse } from "../../../library/common/components/ProfilePosts/ProfilePostAction";
import { Modal } from "react-bootstrap";
import SideBarProfileMenu from "./SideBarProfileMenu";
import PromotionalHub from "./PromotionalHub";

const override = css`
  text-align: center;
  width: 25px;
  height: 25px;
  position: absolute;
`;

let post_page = 1,
  links = [],
  allPosts = [],
  count = 0;
const Posts = ({ profileData, HomeState }) => {
  const [openConfirmModel, setConfirmModel] = useState(false);
  const closeDeleteEl = useRef();
  const [IndexofDeletPost, setIndexofDeletPost] = useState("");
  const [deletePostDataId, setdeletePostDataId] = useState("");
  const forceUpdate = useForceUpdate();
  const { addToast } = useToasts();
  const { pathname } = useLocation();
  const profilePostState = useSelector((state) => state.ProfilePostReducer);
  const {
    uploadPostApi: { uploadPostLoading, uploadPostStatus, updatePostStatus },
  } = HomeState;
  const {
    allPostDetail: { allPost, allPostPage },
  } = HomeState;
  const {
    likeDislikeApi: { likeDislikeSucess },
  } = HomeState;
  const {
    postReactionApi: { reactionStatus },
  } = HomeState;
  const {
    LikeListDetail: { likeListPage, postId },
  } = HomeState;
  const {
    reactionListDetail: { reactListPage, reactionPostId },
  } = HomeState;
  const {
    postViewListDetail: { viewListPage, viewPostId },
  } = HomeState;
  const {
    getPostApi: { getPostStatus },
  } = HomeState;
  const {
    commentApi: { commentStatus, commentResponse },
  } = HomeState;
  const {
    commentListApi: { commentListStatus, commentListLoading },
  } = HomeState;
  const {
    commentReplyApi: { commentReplyStatus, commentReplyResponse },
  } = HomeState;
  const {
    commentReplyListApi: { commentReplyListStatus },
  } = HomeState;
  const {
    shareFeedPostApi: { shareFeedPostStatus, shareFeedPostMessage },
  } = HomeState;
  const {
    reactListApi: { reactListStatus },
  } = HomeState;
  const {
    postShareListDetail: { sharePostId, shareListPage },
  } = HomeState;
  const {
    deletePostApi: { deletePostStatus, deletePostMessage },
  } = profilePostState;
  const [postCommentScrool, setPostCommentScrool] = useState(false);
  const [scroolPostId, setPostId] = useState(false);
  const [isReact, setIsReact] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  post_page = allPostPage;
  allPosts = allPost;

  const {
    activeCarousalDetail: { activeCarousal, activeIndex },
  } = HomeState;
  const [propss, setpropss] = useState(false);
  const postScroolToBottom = () => {
    if (!!postCommentScrool) {
      var div = document.getElementById("scroll-single-post-" + scroolPostId);
      if (!!div) {
        window.setTimeout(() => {
          div.children[0].scroll({
            top: div.children[0].scrollHeight,
            behavior: "smooth",
          });
        }, 100);
      }
      setPostCommentScrool(true);
    }
  };
  //pause video when modal is open
  useEffect(() => {
    if (activeCarousal.length == 0) {
      setpropss(false);
    } else {
      setpropss(true);
    }
  }, [activeCarousal]);

  useEffect(() => {
    if (!!deletePostStatus) {
      addToast(deletePostMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearMyDetelePostResponse());
    }
  }, [deletePostStatus]);
  useEffect(() => {
    if (!!reactListStatus && !!isReact) {
      dispatch(clearPostReactionListResponse());
      setIsReact(false);
    }
  }, [reactListStatus]);
  useEffect(() => {
    count = 0;
    scroolTop();
    $(window).scroll(function () {
      if (
        $(window).scrollTop() + $(window).height() >=
        $(document).height() - 1
      ) {
        if (window.location.pathname == "/newsfeeds") {
          dispatch(changeAllPostDetail({ allPostPage: post_page + 1 }));
        }
      }
    });

    return () => {
      dispatch(clearGetPostDetail());
      dispatch(clearLikeListResponse());
      dispatch(clearPostReactionListResponse());
      let commentDetail = {
        commentListPage: "",
        commentPostId: "",
      };
      dispatch(changeCommentDetailInput(commentDetail));
    };
  }, []);
  useEffect(() => {
    if (!!shareListPage) {
      const bodyParameter = {
        page: shareListPage,
        post_id: sharePostId,
      };
      dispatch(postFeedShareListApi(bodyParameter));
    }
  }, [shareListPage]);
  useEffect(() => {
    if (shareFeedPostStatus == 200) {
      addToast(shareFeedPostMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearPostShareResponse());
    }
  }, [shareFeedPostStatus]);
  useEffect(() => {
    if (!!commentReplyListStatus) {
      dispatch(clearCommentReplyListResponse());
    }
  }, [commentReplyListStatus]);

  useEffect(() => {
    if (!!commentReplyStatus) {
      if (!!commentReplyResponse) {
        let nestedComments = document.getElementById(
          `cmt-${commentReplyResponse.post_id}-nested-${commentReplyResponse.comment_id}`
        ).style.display;
        if (nestedComments != "none") {
          for (let i in allPost) {
            const innerArr = allPost[i].commentList;
            for (let j in innerArr) {
              if (innerArr[j].comment_id == commentReplyResponse.comment_id) {
                innerArr[j].subComment.push(commentReplyResponse);
                dispatch(changeAllPostDetail({ allPost: allPost }));
              }
            }
          }
        }
      }
      dispatch(clearCommentReplyResponse());
    }
  }, [commentReplyStatus]);
  useEffect(() => {
    if (commentListStatus == 200) {
      postScroolToBottom();
      for (let i in allPost) {
        const innerArr = allPost[i].commentList;
        for (let j in innerArr) {
          innerArr[j].replyComment = "";
          innerArr[j].subComment = [];
          innerArr[j].replyCommentPage = 0;
          innerArr[j].showHide = false;
          dispatch(changeAllPostDetail({ allPost: allPost }));
        }
      }
      dispatch(clearCommentListResponse());
    }
  }, [commentListStatus]);
  useEffect(() => {
    if (!!commentStatus || !!allPost) {
      if (!!commentResponse) {
        if (
          document
            .getElementById("comment-listing-" + commentResponse.post_id)
            .getAttribute("class")
            .match("show-comments")
        ) {
          for (let i in allPost) {
            if (allPost[i].post_id == commentResponse.post_id) {
              allPost[i].commentList.push(commentResponse);
              dispatch(changeAllPostDetail({ allPost: allPost }));
              postScroolToBottom();
            }
          }
        }
      }
      dispatch(clearPostCommentResponse());
    }
  }, [commentStatus, commentResponse]);

  useEffect(() => {
    if (getPostStatus == 200) {
      for (let i in allPost) {
        allPost[i].comment = "";
        allPost[i].commentList = [];
        allPost[i].commentPage = 0;

        dispatch(changeAllPostDetail({ allPost: allPost }));
      }
    }
  }, [getPostStatus]);
  useEffect(() => {
    if (!!reactListPage) {
      const bodyParameter = {
        post_id: reactionPostId,
        page: reactListPage,
      };
      dispatch(postReactListApi(bodyParameter));
    }
  }, [reactListPage]);
  useEffect(() => {
    if (!!likeListPage) {
      const bodyParameter = {
        post_id: postId,
        page: likeListPage,
      };
      dispatch(postLikeListApi(bodyParameter));
    }
  }, [likeListPage]);
  useEffect(() => {
    if (!!viewListPage) {
      const bodyParameter = {
        post_id: viewPostId,
        page: viewListPage,
      };
      dispatch(postViewListApi(bodyParameter));
    }
  }, [viewListPage]);
  useEffect(() => {
    if (!!reactionStatus) {
      dispatch(clearPostReactionResponse());
      let reactDetails = {
        reactListPage: 1,
      };
      dispatch(changePostReactionDetails(reactDetails));
    }
  }, [reactionStatus]);
  useEffect(() => {
    if (!!likeDislikeSucess) {
      dispatch(clearLikeDislikeResponse());
    }
  }, [likeDislikeSucess]);

  useEffect(() => {
    if (!!allPostPage) {
      dispatch(getPostApi(allPostPage));
    }
  }, [allPostPage]);

  const unbindJqueryEvents = () => {
    $(".feeds-post__action .ri-more-2-fill").unbind("click");
    $(".open-comment").unbind("click");
    $(".card-footer .react-icon .react-model").unbind("click");
    $(".post-share-link  a").unbind("click");
    $("body").unbind("click");
  };

  useEffect(() => {
    if (getPostStatus == 200 || !!deletePostStatus || !!uploadPostStatus) {
      unbindJqueryEvents();

      $(".open-comment").click(function () {
        $(this)
          .parent(".post_comment__counter")
          .nextAll(".comment-post")
          .toggleClass("show-comments");
      });

      $(".card-footer .react-icon .react-model").click(function (event) {
        event.stopPropagation();
        $(this).parent().parent().find(".react-box").toggleClass("open-emoji");
      });
      $("body").click(function (event) {
        $(".react-box").removeClass("open-emoji");
        $(".share-box").removeClass("open-share");
      });

      $(".post-share-link  a").click(function (event) {
        event.stopPropagation();
        $(this)
          .parent(".post-share-link")
          .find(".share-box")
          .toggleClass("open-share");
      });

      $("body").click(function (event) {
        $(".dropdown-list").removeClass("profile-action-active");
      });

      $(".feeds-post__action .ri-more-2-fill").on("click", function (events) {
        events.stopPropagation();

        if (
          $(this)
            .parents(".card")
            .siblings()
            .find(".dropdown-list")
            .hasClass("profile-action-active")
        ) {
          $(".dropdown-list").removeClass("profile-action-active");
        }
        if (
          $(this)
            .parents(".card-header")
            .find("ul")
            .hasClass("profile-action-active")
        ) {
          $(".dropdown-list").removeClass("profile-action-active");
        } else {
          $(this)
            .parents(".card-header")
            .find("ul")
            .addClass("profile-action-active");
        }
      });
    }
  }, [getPostStatus, deletePostStatus, uploadPostStatus]);
  //this function is used to convert text into anchor tag.
  const checkLinks = (text) => {
    let newText = "<span>";
    if (!!text) {
      let splStr = text.split(" ");
      for (let i = 0; i < splStr.length; i++) {
        if (
          splStr[i].match(
            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
          )
        ) {
          newText =
            newText +
            "<a href=" +
            splStr[i] +
            " target='_blank'>" +
            splStr[i] +
            "</a>" +
            (i !== splStr.length ? " " : "");
        } else {
          newText = newText + splStr[i] + (i !== splStr.length ? " " : "");
        }
      }
      newText = newText + "</span>";
    }
    return newText;
  };
  //this function is used to open single post into model.
  useEffect(() => {
    var videos = document.querySelectorAll("video");
    if (propss) {
      videos.forEach(function (video) {
        video.pause();
      });
    }
  }, []);
  const handleSinglePost = (data, index, e) => {
    var videos = document.querySelectorAll("video");

    videos.forEach(function (video) {
      video.pause();
    });

    let carousal = {
      activeCarousal: data,
      activeIndex: 0,
    };
    dispatch(
      changeActiveCarousal({ ...HomeState.activeCarousalDetail, ...carousal })
    );
  };

  //this function is used to open multiple posts into model
  const handleClickItem = (data, index, e) => {
    if (e.target.id.match("video")) {
      e.target.pause();
    }
    let carousal = {
      activeCarousal: data,
      activeIndex: index,
    };
    dispatch(
      changeActiveCarousal({ ...HomeState.activeCarousalDetail, ...carousal })
    );
  };
  //this function is rendered when carousal post changing
  const handleOnChange = () => {
    var videos = document.querySelectorAll("video");
    videos.forEach(function (video) {
      video.pause();
    });
  };
  //this function is rendered when playing the video
  const handlePlay = (e) => {
    var videos = document.querySelectorAll("video");

    videos.forEach(function (video) {
      if (e.currentTarget.id == video.id) {
        {
          !propss ? video.play() : video.pause();
        }
      } else {
        video.pause();
      }
    });
  };
  //this function is used to open a particular profile when clicking on the post profile picture
  const handleOpenProfile = (data) => {
    dispatch(checkIsPostClicked({ isPostClicked: true }));
    const bodyParameter = {
      post_id: data.post_id,
    };
    dispatch(postViewApi(bodyParameter));
    if (profileData.id == data.user_id) {
      history.push(`/account/${profileData?.user_slug}`);
    } else {
      history.push(
        `/search-profile/${data.user_id}`

        // `/search-profile/${data.user_id}?individual=${
        //   !!data.is_individual ? true : false
        // }`
      );
    }
  };
  //this function is used to open a particular profile when clicking on the shared post profile picture
  const handleOpenSharedProfile = (data) => {
    dispatch(checkIsPostClicked({ isPostClicked: true }));
    const bodyParameter = {
      post_id: data.post_id,
    };
    dispatch(postViewApi(bodyParameter));
    if (profileData.id == data.shared_by_user.user_id) {
      history.push(`/account/${profileData?.user_slug}`);
    } else {
      history.push(
        `/search-profile/${data.shared_by_user.user_id}`

        // `/search-profile/${data.shared_by_user.user_id}?individual=${
        //   !!data.shared_by_user?.id_individual ? true : false
        // }`
      );
    }
  };

  //this function is used to reaction on a post
  const handleReact = (id, data, index) => {
    setIsReact(true);
    const bodyParameter = {
      post_id: data.post_id,
      reaction: id,
    };
    dispatch(postReactionApi(bodyParameter));
    let reactDetail = {
      reactionPostId: data.post_id,
    };
    dispatch(changePostReactionDetails(reactDetail));
    for (let i in allPost) {
      if (i == index) {
        allPost[i].reaction = data.reaction != id ? id : "";
        if (!data.react) {
          allPost[i].react = !data.react ? true : data.react;

          forceUpdate();
        }
      }
    }
  };

  const handleLikeDislike = (data, index) => {
    const bodyParameter = {
      post_id: data.post_id,
      likecheck: !!data.like ? "false" : "true",
    };
    dispatch(likeDislikeApi(bodyParameter));
    for (let i in allPost) {
      if (i == index) {
        allPost[i].like = !!data.like ? false : true;
        if (data.like) {
          allPost[i].post_like = allPost[i].post_like + 1;
        } else {
          if (allPost[i].post_like > allPost[i].post_like - 1) {
            allPost[i].post_like = allPost[i].post_like - 1;
          }
        }
      }
    }
  };
  const handleLikeList = (id) => {
    let likeDetail = {
      likeListPage: 1,
      postId: id,
    };
    dispatch(changeLikeListPage(likeDetail));
  };
  //this function is used to fetch reaction list.
  const handleReactList = (id) => {
    let reactDetail = {
      reactListPage: 1,
      reactionPostId: id,
    };
    dispatch(changePostReactionDetails(reactDetail));
  };
  //this function is used to fetch view list
  const handelViewList = (data) => {
    if (!!data.post_views) {
      let viewDetail = {
        viewListPage: 1,
        viewPostId: data.post_id,
      };
      dispatch(changeViewsDetails(viewDetail));
    }
  };
  //this function is used to sumbit comment on post and increase th count plus one
  const handleSumbitComment = (e, data, index) => {
    e.preventDefault();
    if (!!data.comment) {
      const bodyParameter = {
        post_id: data.post_id,
        comment: data.comment,
      };
      dispatch(postCommentApi(bodyParameter));
      for (let i in allPost) {
        if (i == index) {
          allPost[i].comment_count = allPost[i].comment_count + 1;
          allPost[i].comment = "";
          forceUpdate();
        }
      }
    }
  };
  const handleCommentReplySumbit = (e, data, index) => {
    e.preventDefault();
    const bodyParameter = {
      post_id: data.post_id,
      comment_id: data.comment_id,
      comment: data.replyComment,
    };
    dispatch(postCommentReplyApi(bodyParameter));
    for (let i in allPost) {
      const innerPost = allPost[i].commentList;
      for (let j in innerPost) {
        if (j == index) {
          innerPost[j].replyComment = "";
          innerPost[j].reply = innerPost[j].reply + 1;
          forceUpdate();
          dispatch(changeAllPostDetail({ allPost: allPost }));
        }
      }
    }
  };
  const handleChangeComment = (e, id) => {
    setPostCommentScrool(true);
    for (let i in allPost) {
      if (id == allPost[i].post_id) {
        allPost[i].comment = e.target.value;
      }
    }
    dispatch(changeAllPostDetail({ allPost: allPost }));
  };
  const handleChangeReplyInput = (e, id) => {
    for (let i in allPost) {
      const innerPost = allPost[i].commentList;
      for (let j in innerPost) {
        if (id == innerPost[j].comment_id) {
          innerPost[j].replyComment = e.target.value;
          dispatch(changeAllPostDetail({ allPost: allPost }));
        }
      }
    }
  };
  //this function is used when open comment box and hit api
  const showCommentModel = (index, id) => {
    if (
      document
        .getElementById("comment-listing-" + id)
        .getAttribute("class")
        .match("show-comments")
    ) {
      setPostCommentScrool(true);
      for (let i in allPost) {
        if (i == index) {
          allPost[i].actualComment = allPost[i].comment_count;
          if (!!allPost[i].comment_count) {
            if (!commentListLoading) {
              const bodyParameter = {
                post_id: id,
                page: 1,
              };
              dispatch(postCommentListApi(bodyParameter));
            }
          }
        }
      }

      setPostId(id);
    } else {
      for (let i in allPost) {
        if (i == index) {
          allPost[i].commentList = [];
          allPost[i].commentPage = "";
        }
      }
      dispatch(clearCommentListResponse());
    }
    for (let i in allPost) {
      if (i == index) {
        allPost[i].commentPage = 1;
      }
    }
    let commentDetail = {
      commentListPage: 1,
      commentPostId: id,
    };
    dispatch(changeCommentDetailInput(commentDetail));
  };
  //this function is used to implementing pagination on the comment
  const handleCommentPaginate = (data, index) => {
    setPostCommentScrool(false);
    for (let i in allPost) {
      if (i == index) {
        allPost[i].commentPage = data.commentPage + 1;
      }
    }
    const bodyParameter = {
      post_id: data.post_id,
      page: data.commentPage,
    };
    dispatch(postCommentListApi(bodyParameter));
  };

  const handleReplyCommentPaginate = (data, index) => {
    for (let i in allPost) {
      const innerPost = allPost[i].commentList;
      for (let j in innerPost) {
        if (j == index) {
          innerPost[j].replyCommentPage = innerPost[j].replyCommentPage + 1;

          dispatch(changeAllPostDetail({ allPost: allPost }));
        }
      }
    }

    const bodyParameter = {
      page: data.replyCommentPage,
      post_id: data.post_id,
      comment_id: data.comment_id,
    };
    dispatch(postCommentReplyListApi(bodyParameter));
  };

  const handleOpenReplyInput = (e, item) => {
    let commentStyle = document.getElementById(
      `cmt-${item.post_id}-input-${item.comment_id}`
    ).style.display;
    if (commentStyle == "none") {
      document.getElementById(
        `cmt-${item.post_id}-input-${item.comment_id}`
      ).style.display = "flex";
    } else {
      document.getElementById(
        `cmt-${item.post_id}-input-${item.comment_id}`
      ).style.display = "none";
    }
  };

  const handleOpenNestedReply = (item, index, cmtIndex) => {
    let nestedComment = document.getElementById(
      `cmt-${item.post_id}-nested-${item.comment_id}`
    ).style.display;

    if (nestedComment == "none") {
      for (let i in allPost) {
        const innerPost = allPost[i].commentList;
        for (let j in innerPost) {
          if (j == index) {
            innerPost[j].replyCommentPage = innerPost[j].replyCommentPage + 1;
            innerPost[j].showHide = true;
            innerPost[j].actualReplyComment = innerPost[j].reply;
            forceUpdate();
          }
        }
      }
      dispatch(changeCommentDetailInput({ commentId: item.comment_id }));
      document.getElementById(
        `cmt-${item.post_id}-nested-${item.comment_id}`
      ).style.display = "block";
      const bodyParameter = {
        page: 1,
        post_id: item.post_id,
        comment_id: item.comment_id,
      };

      dispatch(postCommentReplyListApi(bodyParameter));
    } else {
      for (let i in allPost) {
        const innerPost = allPost[i].commentList;
        for (let j in innerPost) {
          if (j == index) {
            innerPost[j].subComment = [];
            innerPost[j].replyCommentPage = 0;
            innerPost[j].showHide = false;
            innerPost[j].actualReplyComment = 0;
            forceUpdate();
          }
        }
      }

      document.getElementById(
        `cmt-${item.post_id}-nested-${item.comment_id}`
      ).style.display = "none";
    }
  };
  const handleShareFeedPosts = (data) => {
    const bodyParameter = {
      post_id: data.post_id,
    };
    dispatch(postFeedShareApi(bodyParameter));
  };

  const handleShareList = (data) => {
    if (!!data.share_count) {
      let shareDetail = {
        shareListPage: 1,
        sharePostId: data.post_id,
      };
      dispatch(changeShareListDetail(shareDetail));
    }
  };
  //this function is used to convert links into preview links
  const convertLinks = (text) => {
    links = [];
    if (!!text) {
      text.trim().replace(/\s\s+/g, " ");
      let splStr = text.split(" ");

      for (let i = 0; i < splStr.length; i++) {
        if (
          splStr[i].match(
            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
          )
        ) {
          links.push(splStr[i]);
        }
      }
      return links.map((data, i) => (
        <div key={i}>
          <Microlink
            url={data}
            media="video"
            size="large"
            style={{ maxWidth: "90%" }}
            autoPlay
          />
        </div>
      ));
    }
    return "";
  };

  const handleOpenComment = (data) => {
    if (profileData.id == data.user_id) {
      history.push(`/account/${profileData?.user_slug}`);
    } else {
      history.push(
        `/search-profile/${data.user_id}`

        // `/search-profile/${data.user_id}?individual=${
        //   !!data.is_individual ? true : false
        // }`
      );
    }
  };

  const handleDeletePost = (index, data) => {
    for (let i in allPost) {
      if (i == IndexofDeletPost) {
        allPost.splice(i, 1);
        forceUpdate();
        dispatch(changeAllPostDetail({ allPost: allPost }));
      }
    }
    const bodyParameter = {
      post_id: deletePostDataId.post_id,
    };
    dispatch(deleteMyPostApi(bodyParameter));
    setConfirmModel(false);
  };
  const OpenconfirmModel = (index, data) => {
    setConfirmModel(true);
    setIndexofDeletPost(index);
    setdeletePostDataId(data);
  };

  const handleEditPosts = (index, data) => {
    let requestData = [];
    dispatch(changePostDetails({ postDetail: data }));
    dispatch(changeSharePostInput({ shareText: data.post_text }));
    if (data.post_data.length > 0) {
      for (let i = 0; i < data.post_data.length; i++) {
        requestData.push(IMAGE_BASE_URL + data.post_data[i].file_name);
        dispatch(changeSharePostInput({ mediaUrl: requestData }));
      }
    }
  };
  useEffect(() => {
    return () => {
      unbindJqueryEvents();
    };
  }, []);

  return (
    <section className="feeds bg-primary spacer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-3">
            {!!profileData?.id && <SideBarProfileMenu />}
          </div>
          <div className="col-sm-12 col-lg-6 mx-auto">
            <div className="feeds-post bg-white p-3 br-20 custom-shadow">
              <div className="feeds-post__header d-flex flex-wrap align-items-center">
                <div className="feeds-post__user">
                  {!!profileData?.is_individual ? (
                    <img
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/member-default.svg")
                      }
                      src={
                        !!profileData
                          ? IMAGE_BASE_URL + profileData.profile_image
                          : "/assets/images/member-default.svg"
                      }
                      alt="user"
                    />
                  ) : (
                    <img
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/business_logo.svg")
                      }
                      src={
                        !!profileData
                          ? IMAGE_BASE_URL + profileData.business_image
                          : "/assets/images/business_logo.svg"
                      }
                      alt="logo"
                      className="business_post_profile"
                    />
                  )}
                </div>
                <input
                  type="text"
                  name="post"
                  className="form-control share-thought"
                  placeholder="What do you want to share with your audience?"
                  data-toggle="modal"
                  data-target="#feeds-modal"
                  readOnly
                />
              </div>
              <div className="feeds-post__type mt-4 d-flex justify-content-between feed_main">
                <div className="feeds-post__category">
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#feeds-modal"
                  >
                    <i className="ri-image-fill pictures-option " /> Pictures
                  </a>
                </div>
                <div className="feeds-post__category">
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#feeds-modal"
                  >
                    <i className="ri-movie-line video-option" /> Videos
                  </a>
                </div>
                <div className="feeds-post__category">
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#feeds-modal"
                  >
                    <i className="ri-links-line link-option"></i> Links
                  </a>
                </div>
              </div>
            </div>
            {!!allPost &&
              allPost?.map((data, index) => (
                <div className="card my-4" key={index}>
                  <div className="card-header d-flex align-items-center">
                    <div className="feeds-post__user mr-3">
                      {!!data.shared ? (
                        !!data.shared_by_user?.id_individual ? (
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenSharedProfile(data)}
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                            }
                            src={
                              !!data
                                ? IMAGE_BASE_URL +
                                data.shared_by_user.user_profile
                                : "/assets/images/member-default.svg"
                            }
                            alt="user"
                          />
                        ) : (
                          <div className="business-pofile-lead">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => handleOpenSharedProfile(data)}
                              onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/business_logo.svg")
                              }
                              src={
                                !!data
                                  ? IMAGE_BASE_URL +
                                  data.shared_by_user?.business_image
                                  : "/assets/images/business_logo.svg"
                              }
                              alt="logo"
                            />
                          </div>
                        )
                      ) : !!data.is_individual ? (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenProfile(data)}
                          onError={(ev) =>
                          (ev.target.src =
                            "/assets/images/member-default.svg")
                          }
                          src={
                            !!data
                              ? IMAGE_BASE_URL + data.user_profile
                              : "/assets/images/member-default.svg"
                          }
                          alt="user"
                        />
                      ) : (
                        <div className="business-pofile-lead">
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenProfile(data)}
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/business_logo.svg")
                            }
                            src={
                              !!data
                                ? IMAGE_BASE_URL + data?.business_image
                                : "/assets/images/business_logo.svg"
                            }
                            alt="logo"
                          />
                        </div>
                      )}
                    </div>
                    <div className="card-header__timeline">
                      {!!data.shared ? (
                        !!data.shared_by_user?.id_individual ? (
                          <h6
                            className="mb-0 text-capitalize"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenSharedProfile(data)}
                          >
                            {data.shared_by_user.user_name}
                          </h6>
                        ) : (
                          <h6
                            className="mb-0 text-capitalize"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenSharedProfile(data)}
                          >
                            {data.shared_by_user?.business_name}
                          </h6>
                        )
                      ) : !!data.is_individual ? (
                        <h6
                          className="mb-0 text-capitalize"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenProfile(data)}
                        >
                          {data.user_name}
                        </h6>
                      ) : (
                        <h6
                          className="mb-0 text-capitalize"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenProfile(data)}
                        >
                          {data.business_name}
                        </h6>
                      )}
                      {!!data.shared ? (
                        !!data.shared_by_user?.id_individual ? (
                          <span className="small text-capitalize">
                            {!!data.shared_by_user.work
                              ? data.shared_by_user.work
                              : ""}
                          </span>
                        ) : (
                          <span className="small text-capitalize">
                            {!!data.shared_by_user?.specialization
                              ? data.shared_by_user?.specialization
                              : ""}
                          </span>
                        )
                      ) : !!data.is_individual ? (
                        <span className="small text-capitalize">
                          {!!data.work ? data.work : ""}
                        </span>
                      ) : (
                        <span className="small text-capitalize">
                          {!!data?.specialization ? data?.specialization : ""}
                        </span>
                      )}
                    </div>
                    {!!data.shared
                      ? data.shared_by_user?.user_id == profileData.id && (
                        <div className="feeds-post__action ml-auto">
                          <i className="ri-more-2-fill dot-icon"></i>
                          <ul className="important-list-drop dropdown-list">
                            <li>
                              <a
                                href="javascript:;"
                                onClick={() => OpenconfirmModel(index, data)}
                              >
                                <i className="ri-delete-bin-6-line"></i>{" "}
                                Delete post
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#feeds-modal"
                                onClick={() => handleEditPosts(index, data)}
                              >
                                <i className="ri-edit-2-fill"></i> Edit post
                              </a>
                            </li>
                          </ul>
                        </div>
                      )
                      : data.user_id == profileData.id && (
                        <div className="feeds-post__action ml-auto">
                          <i className="ri-more-2-fill dot-icon"></i>
                          <ul className="important-list-drop dropdown-list">
                            <li>
                              <a
                                href="javascript:;"
                                onClick={() => OpenconfirmModel(index, data)}
                              >
                                <i className="ri-delete-bin-6-line"></i>{" "}
                                Delete post
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#feeds-modal"
                                onClick={() => handleEditPosts(index, data)}
                              >
                                <i className="ri-edit-2-fill Last"></i> Edit
                                post
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                  </div>

                  <div className="card-body">
                    {data.post_text !== " " && (
                      <div
                        className="card-text mb-4"
                        dangerouslySetInnerHTML={{
                          __html: checkLinks(data.post_text),
                        }}
                      ></div>
                    )}
                    {convertLinks(data.post_text)}
                    {data.post_data?.length > 1 ? (
                      <Carousel
                        useKeyboardArrows={true}
                        emulateTouch={true}
                        dynamicHeight={true}
                        showStatus={false}
                        showArrows={true}
                        swipeable={true}
                        onChange={handleOnChange}
                        showThumbs={false}
                        autoPlay={false}
                        className="home-post"
                      >
                        {data.post_data.map((datas, i) => {
                          return (
                            <div
                              onClick={(e) =>
                                handleClickItem(data.post_data, i, e)
                              }
                              key={i}
                            >
                              {datas.extension == "mp4" ||
                                datas.extension == "mov" ||
                                datas.extension == "MP4" ||
                                datas.extension == "MOV" ||
                                datas.extension == "webm" ||
                                datas.extension == "WEBM" ? (
                                <video
                                  onPlay={(e) => handlePlay(e)}
                                  id={"videoPause" + index + i}
                                  src={IMAGE_BASE_URL + datas.file_name}
                                  controls
                                  data-toggle="modal"
                                  data-target="#post-modal"
                                />
                              ) : (
                                <div
                                  data-toggle="modal"
                                  data-target="#post-modal"
                                >
                                  <img
                                    onError={(ev) => addDefaultSrc(ev)}
                                    className="d-block w-100"
                                    src={
                                      !!datas.file_name
                                        ? IMAGE_BASE_URL + datas.file_name
                                        : returnDefaultImage()
                                    }
                                    alt="First slide"
                                    data-toggle="modal"
                                    data-target="#post-modal"
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </Carousel>
                    ) : (
                      data.post_data.map((image, i) => {
                        return (
                          <div
                            className="text-center"
                            onClick={(e) =>
                              handleSinglePost(data.post_data, index, e)
                            }
                            key={i}
                          >
                            {image.extension == "mp4" ||
                              image.extension == "mov" ||
                              image.extension == "MP4" ||
                              image.extension == "MOV" ||
                              image.extension == "webm" ||
                              image.extension == "WEBM" ? (
                              <video
                                onPlay={(e) => handlePlay(e)}
                                id={"videoPause" + index}
                                controls
                                src={IMAGE_BASE_URL + image.file_name}
                                data-toggle="modal"
                                data-target="#post-modal"
                              ></video>
                            ) : (
                              <img
                                onError={(ev) => addDefaultSrc(ev)}
                                src={
                                  !!image.file_name
                                    ? IMAGE_BASE_URL + image.file_name
                                    : returnDefaultImage()
                                }
                                data-toggle="modal"
                                data-target="#post-modal"
                                className="w-100"
                              />
                            )}
                          </div>
                        );
                      })
                    )}
                    {!!data.shared ? (
                      <div className="share-post d-flex align-items-center mt-3">
                        <div className="feeds-post__user mr-3 share-post-user">
                          {!!data.is_individual ? (
                            <img
                              onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/member-default.svg")
                              }
                              src={
                                !!data
                                  ? IMAGE_BASE_URL + data.user_profile
                                  : "/assets/images/member-default.svg"
                              }
                              alt="user"
                            />
                          ) : (
                            <div className="business-pofile-lead">
                              <img
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/business_logo.svg")
                                }
                                src={
                                  !!data
                                    ? IMAGE_BASE_URL + data?.business_image
                                    : "/assets/images/business_logo.svg"
                                }
                                alt="logo"
                              />
                            </div>
                          )}
                        </div>
                        <div className="share-post__user">
                          {!!data.is_individual ? (
                            <h6 className="mb-0 text-capitalize">
                              {data.user_name}
                            </h6>
                          ) : (
                            <h6 className="mb-0 text-capitalize">
                              {data.business_name}
                            </h6>
                          )}
                          {!!data.is_individual ? (
                            <span className="small text-capitalize">
                              {!!data.work ? data.work : ""}
                            </span>
                          ) : (
                            <span className="small text-capitalize">
                              {!!data?.specialization
                                ? data?.specialization
                                : ""}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-footer">
                    <div className="post-status d-flex align-items-center justify-content-between">
                      <div className="post__action d-flex align-items-center">
                        <div className="post__action__inner d-flex justify-content-between">
                          <div className="feed__reaction react-icon">
                            <div className="reaction d-flex align-items-center">
                              <div className="react-open">
                                {!!data.post_react ? (
                                  data.reaction_list.length > 0 && (
                                    <div className="reaction-listing">
                                      {data.reaction_list.map((item, i) => {
                                        return (
                                          <div
                                            className={
                                              !!Number(item.Reaction_check)
                                                ? "my-react"
                                                : ""
                                            }
                                            key={i}
                                          >
                                            {item.reaction == 1 ? (
                                              <img
                                                src="/assets/images/likepng1.png"
                                                alt="Like"
                                              />
                                            ) : item.reaction == 2 ? (
                                              <img
                                                src="/assets/images/love.png"
                                                alt="Love"
                                              />
                                            ) : item.reaction == 3 ? (
                                              <img
                                                src="/assets/images/clap.png"
                                                alt="clab"
                                              />
                                            ) : item.reaction == 4 ? (
                                              <img
                                                src="/assets/images/celebrate.png"
                                                alt="celebrate"
                                              />
                                            ) : item.reaction == 5 ? (
                                              <img
                                                src="/assets/images/think.png"
                                                alt="Thinking"
                                              />
                                            ) : (
                                              (item.reaction = 6 ? (
                                                <img
                                                  src="/assets/images/upset.png"
                                                  alt="upset"
                                                />
                                              ) : (
                                                ""
                                              ))
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )
                                ) : (
                                  <i className="ri-emotion-line" />
                                )}
                              </div>
                              <a
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#react-modal"
                                onClick={() => handleReactList(data.post_id)}
                              >
                                {" "}
                                {data.post_react > 0
                                  ? numFormatter(data.post_react)
                                  : ""}
                              </a>{" "}
                              <span
                                style={{ cursor: "pointer" }}
                                className="react-model"
                              >
                                &nbsp;React
                              </span>
                            </div>
                            <div className="react-box">
                              <a
                                className={
                                  data.reaction == 1 ? "last-rection" : ""
                                }
                                href="javascript:;"
                                id="1"
                              >
                                <img
                                  src="/assets/images/300x300.gif"
                                  alt="Like"
                                  onClick={() => handleReact(1, data, index)}
                                />
                              </a>
                              <a
                                className={
                                  data.reaction == 2 ? "last-rection" : ""
                                }
                                href="javascript:;"
                                id="2"
                              >
                                <img
                                  src="/assets/images/love.gif"
                                  alt="Love"
                                  onClick={() => handleReact(2, data, index)}
                                />
                              </a>
                              <a
                                className={
                                  data.reaction == 3 ? "last-rection" : ""
                                }
                                href="javascript:;"
                                id="3"
                              >
                                <img
                                  src="/assets/images/clap.gif"
                                  alt="clab"
                                  onClick={() => handleReact(3, data, index)}
                                />
                              </a>
                              <a
                                className={
                                  data.reaction == 4 ? "last-rection" : ""
                                }
                                href="javascript:;"
                                id="4"
                              >
                                <img
                                  src="/assets/images/celebrate.gif"
                                  alt="celebrate"
                                  onClick={() => handleReact(4, data, index)}
                                />
                              </a>
                              <a
                                className={
                                  data.reaction == 5 ? "last-rection" : ""
                                }
                                href="javascript:;"
                                id="5"
                              >
                                <img
                                  src="/assets/images/thinking.gif"
                                  alt="Thinking"
                                  onClick={() => handleReact(5, data, index)}
                                />
                              </a>
                              <a
                                className={
                                  data.reaction == 6 ? "last-rection" : ""
                                }
                                href="javascript:;"
                                id="6"
                              >
                                <img
                                  src="/assets/images/upset.gif"
                                  alt="upset"
                                  onClick={() => handleReact(6, data, index)}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="post-share-link">
                          <a
                            href="javascript:;"
                            className="btn-sm ml-auto link-txt"
                          >
                            <i className="ri-share-forward-line"></i> Share
                          </a>
                          <div className="share-box">
                            <ul className="list-unstyled mb-0">
                              <li>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleShareFeedPosts(data)}
                                  className="link-color"
                                >
                                  <i className="ri-file-list-3-line" /> Share In
                                  feed
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="post-status__comments d-flex">
                        {data.post_views > 0 ? (
                          <span
                            style={{
                              cursor:
                                data.post_views > 0 ? "pointer" : "default",
                            }}
                            data-toggle="modal"
                            data-target={
                              data.post_views > 0 ? "#view-modal" : ""
                            }
                            onClick={() => handelViewList(data)}
                          >
                            {numFormatter(data.post_views)} Views
                          </span>
                        ) : (
                          ""
                        )}
                        {data.share_count > 0 ? (
                          <span
                            style={{
                              cursor:
                                data.share_count > 0 ? "pointer" : "default",
                            }}
                            data-toggle="modal"
                            data-target={
                              data.share_count > 0 ? "#share-modal" : ""
                            }
                            onClick={() => handleShareList(data)}
                          >
                            {numFormatter(data.share_count)} Share
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="post_comment" id="post_comments">
                      <div
                        className="post_comment__counter d-flex my-3 justify-content-between align-items-center"
                        onClick={(e) => showCommentModel(index, data.post_id)}
                      >
                        <span
                          style={{
                            cursor: !!data.comment_count
                              ? "pointer"
                              : "default",
                          }}
                          className="open-comment"
                        >
                          {!!data.comment_count > 0
                            ? "Comments" +
                            " " +
                            numFormatter(data.comment_count)
                            : "No Comments"}
                        </span>
                        {!!data.comment_count && (
                          <span
                            style={{
                              cursor: !!data.comment_count
                                ? "pointer"
                                : "default",
                            }}
                            className="open-comment"
                          >
                            <i className="ri-arrow-down-s-line"></i>
                            <i className="ri-arrow-down-s-line"></i>
                          </span>
                        )}
                      </div>
                      <div
                        id={"comment-listing-" + data.post_id}
                        className="comment-post"
                      >
                        {!!data.commentList &&
                          !!data.commentList.length > 0 &&
                          data.commentPage * 5 < data.actualComment ? (
                          <span
                            className="load-more"
                            onClick={() => handleCommentPaginate(data, index)}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="ri-download-line align-middle"></i>{" "}
                            Load More
                          </span>
                        ) : (
                          ""
                        )}
                        {!!data.commentList &&
                          data.commentList.length > 0 &&
                          data.commentList.length > 4 ? (
                          <Scrollbars
                            id={"scroll-single-post-" + data.post_id}
                            style={{ width: 550, height: 375 }}
                            autoHide
                          >
                            {data.commentList.map((item, i) => (
                              <div
                                className="post_comment__listing my-3"
                                key={i}
                              >
                                <div className="post_comment__listing__user">
                                  {!!item.is_individual ? (
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleOpenComment(item)}
                                      onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/member-default.svg")
                                      }
                                      src={
                                        !!item
                                          ? IMAGE_BASE_URL + item.image
                                          : "/assets/images/member-default.svg"
                                      }
                                      alt="user"
                                    />
                                  ) : (
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleOpenComment(item)}
                                      onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/business_logo.svg")
                                      }
                                      src={
                                        !!item
                                          ? IMAGE_BASE_URL + item.image
                                          : "/assets/images/business_logo.svg"
                                      }
                                      alt="logo"
                                    />
                                  )}
                                </div>
                                <div className="post_comment__listing__review">
                                  <div className="comment-bg">
                                    <p className="mb-1">{item.comment}</p>
                                  </div>
                                  <div className="view-reply">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        handleOpenReplyInput(e, item)
                                      }
                                    >
                                      <i className="ri-reply-line" /> Reply
                                    </span>
                                    {!!item.reply ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleOpenNestedReply(item, i, index)
                                        }
                                        className="open-reply"
                                      >
                                        <i className="ri-eye-line" />
                                        {!!item.showHide ? "Hide" : "View"}{" "}
                                        {item.reply} Reply
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div
                                    style={{ display: "none" }}
                                    id={`cmt-${item.post_id}-nested-${item.comment_id}`}
                                  >
                                    {!!item.subComment &&
                                      !!item.subComment.length > 0 &&
                                      item.replyCommentPage * 3 <
                                      item.actualReplyComment ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        className="d-block text-center"
                                        onClick={() =>
                                          handleReplyCommentPaginate(item, i)
                                        }
                                      >
                                        View{" "}
                                        {item.reply - item.subComment.length}{" "}
                                        pervious replies
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {!!item.subComment &&
                                      item.subComment.map((subComment, i) => {
                                        return (
                                          <div
                                            className="post_comment__listing post__comment__nested "
                                            key={i}
                                          >
                                            <div className="post_comment__listing__user">
                                              {!!subComment.is_individual ? (
                                                <img
                                                  onError={(ev) =>
                                                  (ev.target.src =
                                                    "/assets/images/member-default.svg")
                                                  }
                                                  src={
                                                    !!subComment
                                                      ? IMAGE_BASE_URL +
                                                      subComment.image
                                                      : "/assets/images/member-default.svg"
                                                  }
                                                  alt="user"
                                                />
                                              ) : (
                                                <img
                                                  onError={(ev) =>
                                                  (ev.target.src =
                                                    "/assets/images/business_logo.svg")
                                                  }
                                                  src={
                                                    !!subComment
                                                      ? IMAGE_BASE_URL +
                                                      subComment.image
                                                      : "/assets/images/business_logo.svg"
                                                  }
                                                  alt="logo"
                                                />
                                              )}
                                            </div>
                                            <div className="post_comment__listing__commentbox">
                                              <div className="comment-bg">
                                                <p>{subComment.comment}</p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                  <div
                                    style={{ display: "none" }}
                                    id={`cmt-${item.post_id}-input-${item.comment_id}`}
                                    className="post_comment__listing post__comment__nested"
                                  >
                                    <div className="post_comment__listing__user">
                                      {!!profileData.is_individual ? (
                                        <img
                                          onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/member-default.svg")
                                          }
                                          src={
                                            !!profileData
                                              ? IMAGE_BASE_URL +
                                              profileData.profile_image
                                              : "/assets/images/member-default.svg"
                                          }
                                          alt="user"
                                        />
                                      ) : (
                                        <img
                                          onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/business_logo.svg")
                                          }
                                          src={
                                            !!profileData
                                              ? IMAGE_BASE_URL +
                                              profileData?.business_image
                                              : "/assets/images/business_logo.svg"
                                          }
                                          alt="logo"
                                        />
                                      )}{" "}
                                    </div>
                                    <div className="post_comment__listing__commentbox">
                                      <form
                                        onSubmit={(e) =>
                                          handleCommentReplySumbit(e, item, i)
                                        }
                                        autoComplete="off"
                                      >
                                        <Input
                                          type="text"
                                          name="replyComment"
                                          value={item.replyComment || ""}
                                          onChange={(e) =>
                                            handleChangeReplyInput(
                                              e,
                                              item.comment_id
                                            )
                                          }
                                          className="form-control"
                                          placeholder="Write a comment"
                                        />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Scrollbars>
                        ) : (
                          !!data.commentList &&
                          data.commentList.length > 0 &&
                          data.commentList.map((item, i) => (
                            <div className="post_comment__listing my-3" key={i}>
                              <div className="post_comment__listing__user">
                                {!!item.is_individual ? (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOpenComment(item)}
                                    onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/member-default.svg")
                                    }
                                    src={
                                      !!item
                                        ? IMAGE_BASE_URL + item.image
                                        : "/assets/images/member-default.svg"
                                    }
                                    alt="user"
                                  />
                                ) : (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOpenComment(item)}
                                    onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/business_logo.svg")
                                    }
                                    src={
                                      !!item
                                        ? IMAGE_BASE_URL + item.image
                                        : "/assets/images/business_logo.svg"
                                    }
                                    alt="logo"
                                  />
                                )}
                              </div>
                              <div className="post_comment__listing__review">
                                <div className="comment-bg">
                                  <p className="mb-1">{item.comment}</p>
                                </div>
                                <div className="view-reply">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      handleOpenReplyInput(e, item)
                                    }
                                  >
                                    <i className="ri-reply-line" /> Reply
                                  </span>
                                  {!!item.reply ? (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOpenNestedReply(item, i, index)
                                      }
                                      className="open-reply"
                                    >
                                      <i className="ri-eye-line" />
                                      {!!item.showHide ? "Hide" : "View"}{" "}
                                      {item.reply} reply
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div
                                  style={{ display: "none" }}
                                  id={`cmt-${item.post_id}-nested-${item.comment_id}`}
                                >
                                  {!!item.subComment &&
                                    !!item.subComment.length > 0 &&
                                    item.replyCommentPage * 3 <
                                    item.actualReplyComment ? (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="d-block text-center"
                                      onClick={() =>
                                        handleReplyCommentPaginate(item, i)
                                      }
                                    >
                                      View {item.reply - item.subComment.length}{" "}
                                      pervious replies
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {!!item.subComment &&
                                    item.subComment.map((subComment, i) => {
                                      return (
                                        <div
                                          className="post_comment__listing post__comment__nested "
                                          key={i}
                                        >
                                          <div className="post_comment__listing__user">
                                            {!!subComment.is_individual ? (
                                              <img
                                                onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/member-default.svg")
                                                }
                                                src={
                                                  !!subComment
                                                    ? IMAGE_BASE_URL +
                                                    subComment.image
                                                    : "/assets/images/member-default.svg"
                                                }
                                                alt="user"
                                              />
                                            ) : (
                                              <img
                                                onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/business_logo.svg")
                                                }
                                                src={
                                                  !!subComment
                                                    ? IMAGE_BASE_URL +
                                                    subComment.image
                                                    : "/assets/images/business_logo.svg"
                                                }
                                                alt="logo"
                                              />
                                            )}
                                          </div>
                                          <div className="post_comment__listing__commentbox">
                                            <div className="comment-bg">
                                              <p>{subComment.comment}</p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                                <div
                                  style={{ display: "none" }}
                                  id={`cmt-${item.post_id}-input-${item.comment_id}`}
                                  className="post_comment__listing post__comment__nested"
                                >
                                  <div className="post_comment__listing__user">
                                    {!!profileData.is_individual ? (
                                      <img
                                        onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/member-default.svg")
                                        }
                                        src={
                                          !!profileData
                                            ? IMAGE_BASE_URL +
                                            profileData.profile_image
                                            : "/assets/images/member-default.svg"
                                        }
                                        alt="user"
                                      />
                                    ) : (
                                      <img
                                        onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/business_logo.svg")
                                        }
                                        src={
                                          !!profileData
                                            ? IMAGE_BASE_URL +
                                            profileData?.business_image
                                            : "/assets/images/business_logo.svg"
                                        }
                                        alt="logo"
                                      />
                                    )}{" "}
                                  </div>
                                  <div className="post_comment__listing__commentbox">
                                    <form
                                      onSubmit={(e) =>
                                        handleCommentReplySumbit(e, item, i)
                                      }
                                      autoComplete="off"
                                    >
                                      <Input
                                        type="text"
                                        name="replyComment"
                                        value={item.replyComment || ""}
                                        onChange={(e) =>
                                          handleChangeReplyInput(
                                            e,
                                            item.comment_id
                                          )
                                        }
                                        className="form-control"
                                        placeholder="Write a comment"
                                      />
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>

                      <div className="post__commtent__user">
                        <div className="feeds-post__header d-flex flex-wrap align-items-center">
                          <div className="feeds-post__user">
                            {!!profileData.is_individual ? (
                              <img
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/member-default.svg")
                                }
                                src={
                                  !!profileData
                                    ? IMAGE_BASE_URL + profileData.profile_image
                                    : "/assets/images/member-default.svg"
                                }
                                alt="user"
                              />
                            ) : (
                              <img
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/business_logo.svg")
                                }
                                src={
                                  !!profileData
                                    ? IMAGE_BASE_URL +
                                    profileData?.business_image
                                    : "/assets/images/business_logo.svg"
                                }
                                alt="logo"
                              />
                            )}{" "}
                          </div>
                          <form
                            onSubmit={(e) =>
                              handleSumbitComment(e, data, index)
                            }
                            className="comment-form"
                            autoComplete="off"
                          >
                            <Input
                              type="text"
                              name="comment"
                              value={data.comment || ""}
                              onChange={(e) =>
                                handleChangeComment(e, data.post_id)
                              }
                              className="form-control"
                              placeholder="Write a comment"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="col-sm-12 col-lg-3">
            {" "}
            <PromotionalHub />
          </div>

          <Modal
            show={openConfirmModel}
            onHide={() => setConfirmModel(false)}
            backdrop="static"
            keyboard={false}
            centered
            className=""
          >
            <div className="modal-body text-center">
              <button
                ref={closeDeleteEl}
                onClick={() => setConfirmModel(false)}
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <p className="pt-4">Are you sure you want to delete this post?</p>
              <div className="alert-btn mt-3 pb-4">
                <a
                  href="javascript:;"
                  onClick={() => handleDeletePost()}
                  className="btn btn-secondary btn-sm mr-3"
                >
                  Yes
                </a>
                <a
                  href="javascript:;"
                  onClick={() => setConfirmModel(false)}
                  className="btn btn-blue btn-sm"
                >
                  No
                </a>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
};
export default Posts;
