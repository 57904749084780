import { GET_API, POST_API } from "../../main/fetch"
import { LOGIN_DATA_REQUEST, LOGIN_DATA_SUCCESS, LOGIN_DATA_FAILURE } from "../../modules/Login/LoginConstants"
import { MAIN_SLIDER_DATA_FAILURE, MAIN_SLIDER_DATA_REQUEST, MAIN_SLIDER_DATA_SUCCESS, CHECK_AUTH_REQUEST, CHECK_AUTH_SUCCESS, CHECK_AUTH_FAILURE } from "../../modules/Main/MainConstants"
import { AUTH_CHECK, LOGIN_DATA_API, MAIN_SLIDER_API } from "../urls"

//this function is used to login to the website
const loginDataApi = (body) => {
    return {
        [POST_API]: {
            endpoint: LOGIN_DATA_API,
            types: [LOGIN_DATA_REQUEST,
                LOGIN_DATA_SUCCESS,
                LOGIN_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch main page slider images. 
const mainSliderDataApi = (body) => {
    return {
        [GET_API]: {
            endpoint: MAIN_SLIDER_API,
            types: [MAIN_SLIDER_DATA_REQUEST,
                MAIN_SLIDER_DATA_SUCCESS,
                MAIN_SLIDER_DATA_FAILURE],
            body
        }
    }
}
const checkAuthApi = (body) => {

    return {
        [POST_API]: {
            endpoint: AUTH_CHECK,
            types: CHECK_AUTH_REQUEST,
            body,
            is_form_data: true,
        }
    }
}

export { loginDataApi, mainSliderDataApi, checkAuthApi }