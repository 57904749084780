import { replceMultiStringWithSIngle } from "../../library/utilities/functions";
//this function is used to implementing validation for individual sign up field.
const signUpIndividualValidation = (validation, signupProps) => {
  let firstName = replceMultiStringWithSIngle(signupProps.first_name);
  validation.is_valid_first_name.status =
    firstName != "" && firstName != " " ? true : false;

  return validation;
};

const signUpIndividualValidationh = (validation, signupProps) => {
  let firstName = replceMultiStringWithSIngle(signupProps.first_name);

  validation.is_valid_email.status = signupProps.email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
    ? true
    : false;
  return validation;
};
const onChangeEmailValidation = (validation, signupProps) => {
  let Name = replceMultiStringWithSIngle(signupProps.individualName);

  validation.is_valid_email.status =
    signupProps.email.length > 0 ? true : false;
  return validation;
};

//this function is used to implementing validation for business sign up field
const signUpBusinessValidation = (validation, signupProps, data) => {
  if (data === "password") {
    validation.is_valid_password.status = signupProps.password.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    )
      ? true
      : false;
  } else {
    validation.is_valid_confirmPassword.status =
      signupProps.password === signupProps.verifyPassword ? true : false;
  }

  return validation;
};

export {
  signUpIndividualValidation,
  signUpBusinessValidation,
  signUpIndividualValidationh,
  onChangeEmailValidation,
};
