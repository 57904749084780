import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";
import { ReactTinyLink } from "react-tiny-link";
import Microlink from "@microlink/react";
import {
  addDefaultSrc,
  getCookie,
  numFormatter,
  returnDefaultImage,
} from "../../library/utilities/functions";
import { Input } from "../../library/common/components";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import {
  fileCommentReplyListApi,
  fileFeedShareListApi,
  fileViewListApi,
  getparticularPostApi,
  particulaFileCommentListApi,
  particularFileCommentApi,
  particularFileCommentReplyApi,
  particularFileShareApi,
  particularPostCmtReplyApi,
  particularPostCmtReplyListApi,
  particularPostCommentApi,
  particularPostCommentListApi,
  particularPostFeedShareApi,
  particularPostReactApi,
  particularPostReactListApi,
  particularPostShareListApi,
  particularPostViewListApi,
  singleFileReactListApi,
  singleParticularFileReactApi,
} from "../../library/api/PostApiService";
import { IMAGE_BASE_URL } from "../../library/urls";
import {
  changeParticularCommentListDetail,
  changeParticularPostDetail,
  changeParticularReactListDetail,
  changeParticularShareListDetil,
  changeParticularViewDetail,
  clearFileCmtReplyListResponse,
  clearFileCmtReplyResponse,
  clearFileReactListResponse,
  clearParticularCommentListResponse,
  clearParticularFileCmtListResponse,
  clearParticularFileCmtResponse,
  clearparticularFileResponse,
  clearParticularPostCommentResponse,
  clearParticularPostReactResponse,
  clearParticularPostResponse,
  clearParticularReplyCmtResponse,
  clearParticularReplyListResponse,
  clearParticularShareFileResponse,
  clearParticularSharePostResponse,
  clearPostReactListResponse,
} from "./PostAction";

import PostModel from "./PostModels";

let links = [];

const Post = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const postId = params.get("post_id");
  const fileId = params.get("file_id");

  const postState = useSelector((state) => state.PostReducer);
  const {
    particularPostDetail: { particularPost },
  } = postState;
  const {
    particularPostApi: { particularPostStatus },
  } = postState;
  const {
    particularPostReactionApi: { reactionStatus },
  } = postState;
  const {
    singleFileReactionApi: { singleReactionStatus },
  } = postState;
  const {
    reactionListDetail: { reactListPage, singleReactListpage },
  } = postState;
  const {
    postReactListApi: { reactListStatus },
  } = postState;
  const {
    fileReactListApi: { singleReactListStatus },
  } = postState;
  const {
    commentListApi: { commentListStatus, commentListLoading },
  } = postState;
  const {
    commentApi: { commentStatus, commentResponse },
  } = postState;
  const {
    commentReplyApi: { commentReplyStatus, commentReplyResponse },
  } = postState;
  const {
    commentReplyListApi: { commentReplyListStatus },
  } = postState;
  const {
    singlePostCommentApi: { SingleCommentResponse, SingleCommentStatus },
  } = postState;
  const {
    singlePostCommentListApi: {
      singleCommentListStatus,
    },
  } = postState;
  const {
    singlePostCommentReplyApi: {
      singleCommentReplyResponse,
      singleCommentReplyStatus,
    },
  } = postState;
  const {
    singleCommentReplyListApi: {
      singleReplyListStatus,
    },
  } = postState;
  const {
    shareFeedPostApi: { shareFeedPostStatus, shareFeedPostMessage },
  } = postState;
  const {
    shareSingleFeedPostApi: { shareSingleFeedStatus, shareSingleFeedMessage },
  } = postState;
  const {
    postViewListDetail: { viewListPage, singleViewListpage },
  } = postState;
  const {
    postShareListDetail: { singleShareListpage, shareListPage },
  } = postState;

  const [openModel, setOpenModel] = useState(false);
  const [openViewModel, setViewmodel] = useState(false);
  const [openShareModel, setOpenShareModel] = useState(false);
  const [postCommentScrool, setPostCommentScrool] = useState(false);
  const [isReact, setIsReact] = useState(false);
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const history = useHistory();

  const postScroolToBottom = () => {
    if (!!postCommentScrool) {
      var div = document.getElementById("scroll-single-post");
      if (!!div) {
        window.setTimeout(() => {
          div.children[0].scroll({
            top: div.children[0].scrollHeight,
            behavior: "smooth",
          });
        }, 100);
      }
      setPostCommentScrool(true);
    }
  };

  useEffect(() => {
    if (!!particularPost) {
      $(".open-comment").click(function () {
        $(this)
          .parent(".post_comment__counter")
          .nextAll(".comment-post")
          .toggleClass("show-comments");
      });

      $(".card-footer .react-icon .react-model").click(function (event) {
        event.stopPropagation();
        $(this).parent().parent().find(".react-box").toggleClass("open-emoji");
      });

      $(".post-share-link  a").click(function (event) {
        event.stopPropagation();
        $(this)
          .parent(".post-share-link")
          .find(".share-box")
          .toggleClass("open-share");
      });
    }
  }, [particularPost]);
  useEffect(() => {
    if (shareFeedPostStatus == 200) {
      addToast(shareFeedPostMessage, {
        appearance: "success",
        autoDismiss: true,
   
      });
      dispatch(clearParticularSharePostResponse());
    }
  }, [shareFeedPostStatus]);

  useEffect(() => {
    if (!!shareListPage) {
      const bodyParameter = {
        post_id: postId,
      };
      dispatch(particularPostShareListApi(bodyParameter));
    }
  }, [shareListPage]);
  useEffect(() => {
    if (!!singleShareListpage) {
      const bodyParameter = {
        page: singleShareListpage,
        post_id: postId,
        file_id: fileId,
      };
      dispatch(fileFeedShareListApi(bodyParameter));
    }
  }, [singleShareListpage]);
  useEffect(() => {
    if (shareSingleFeedStatus == 200) {
      addToast(shareSingleFeedMessage, {
        appearance: "success",
        autoDismiss: true,

      });
      dispatch(clearParticularShareFileResponse());
    }
  }, [shareSingleFeedStatus]);
  useEffect(() => {
    if (!!viewListPage) {
      const bodyParameter = {
        post_id: postId,
        page: viewListPage,
      };
      dispatch(particularPostViewListApi(bodyParameter));
    }
  }, [viewListPage]);
  useEffect(() => {
    if (!!singleViewListpage) {
      const body = {
        page: singleViewListpage,
        post_id: postId,
        file_id: fileId,
      };
      dispatch(fileViewListApi(body));
    }
  }, [singleViewListpage]);
  useEffect(() => {
    if (!!SingleCommentStatus) {
      if (!!SingleCommentResponse) {
        if (
          document
            .getElementById("comment-listings")
            .getAttribute("class")
            .match("show-comments")
        ) {
          particularPost.commentList.push(SingleCommentResponse);
          dispatch(
            changeParticularPostDetail({ particularPost: particularPost })
          );
          postScroolToBottom();
        }
      }
      dispatch(clearParticularFileCmtResponse());
    }
  }, [SingleCommentStatus]);
  useEffect(() => {
    if (singleCommentListStatus == 200) {
      postScroolToBottom();

      const innerArr = particularPost.commentList;
      for (let j in innerArr) {
        innerArr[j].replyComment = "";
        innerArr[j].subComment = [];
        innerArr[j].replyCommentPage = 0;
        innerArr[j].showHide = false;
        dispatch(
          changeParticularPostDetail({ particularPost: particularPost })
        );
      }

      dispatch(clearParticularFileCmtListResponse());
    }
  }, [singleCommentListStatus]);
  useEffect(() => {
    if (!!commentReplyListStatus) {
      dispatch(clearParticularReplyListResponse());
    }
  }, [commentReplyListStatus]);
  useEffect(() => {
    if (!!singleReplyListStatus) {
      dispatch(clearFileCmtReplyListResponse());
    }
  }, [singleReplyListStatus]);
  useEffect(() => {
    if (!!singleCommentReplyStatus) {
      if (!!singleCommentReplyResponse) {
        let nestedComments = document.getElementById(
          `cmt-${singleCommentReplyResponse.post_id}-nested-${singleCommentReplyResponse.comment_id}`
        ).style.display;
        if (nestedComments != "none") {
          const innerArr = particularPost.commentList;
          for (let j in innerArr) {
            if (
              innerArr[j].comment_id == singleCommentReplyResponse.comment_id
            ) {
              innerArr[j].subComment.push(singleCommentReplyResponse);
              dispatch(
                changeParticularPostDetail({ particularPost: particularPost })
              );
            }
          }
        }
      }
      dispatch(clearFileCmtReplyResponse());
    }
  }, [singleCommentReplyStatus]);
  useEffect(() => {
    if (!!commentReplyStatus) {
      if (!!commentReplyResponse) {
        let nestedComments = document.getElementById(
          `cmt-${commentReplyResponse.post_id}-nested-${commentReplyResponse.comment_id}`
        ).style.display;
        if (nestedComments != "none") {
          const innerArr = particularPost.commentList;
          for (let j in innerArr) {
            if (innerArr[j].comment_id == commentReplyResponse.comment_id) {
              innerArr[j].subComment.push(commentReplyResponse);
              dispatch(
                changeParticularPostDetail({ particularPost: particularPost })
              );
            }
          }
        }
      }
      dispatch(clearParticularReplyCmtResponse());
    }
  }, [commentReplyStatus]);
  useEffect(() => {
    if (commentListStatus == 200) {
      postScroolToBottom();

      const innerArr = particularPost.commentList;
      for (let j in innerArr) {
        innerArr[j].replyComment = "";
        innerArr[j].subComment = [];
        innerArr[j].replyCommentPage = 0;
        innerArr[j].showHide = false;
        dispatch(
          changeParticularPostDetail({ particularPost: particularPost })
        );
      }

      dispatch(clearParticularCommentListResponse());
    }
  }, [commentListStatus]);
  useEffect(() => {
    if (!!commentStatus) {
      if (!!commentResponse) {
        if (
          document
            .getElementById("comment-listings")
            .getAttribute("class")
            .match("show-comments")
        ) {
          particularPost.commentList.push(commentResponse);
          dispatch(
            changeParticularPostDetail({ particularPost: particularPost })
          );
          postScroolToBottom();
        }
      }
      dispatch(clearParticularPostCommentResponse());
    }
  }, [commentStatus]);
  useEffect(() => {
    if (!!reactListStatus && !!isReact) {
      dispatch(changeParticularReactListDetail({ reactListPage: 1 }));
      dispatch(clearPostReactListResponse());
      setIsReact(false);
    }
  }, [reactListStatus]);
  useEffect(() => {
    if (!!singleReactListStatus && !!isReact) {
      dispatch(changeParticularReactListDetail({ singleReactListpage: 1 }));
      dispatch(clearFileReactListResponse());
      setIsReact(false);
    }
  }, [singleReactListStatus]);
  useEffect(() => {
    if (!!singleReactionStatus) {
      dispatch(changeParticularReactListDetail({ singleReactListpage: 1 }));
      dispatch(clearparticularFileResponse());
    }
  }, [singleReactionStatus]);
  useEffect(() => {
    if (!!reactionStatus) {
      dispatch(changeParticularReactListDetail({ reactListPage: 1 }));
      dispatch(clearParticularPostReactResponse());
    }
  }, [reactionStatus]);
  useEffect(() => {
    if (!!reactListPage) {
      const bodyParameter = {
        post_id: postId,
        page: reactListPage,
      };
      dispatch(particularPostReactListApi(bodyParameter));
    }
  }, [reactListPage]);

  useEffect(() => {
    dispatch(clearParticularPostResponse());
    if (!!fileId && typeof fileId == "string") {
      const bodyParameter = {
        postId: postId,
        fileId: fileId,
      };
      dispatch(getparticularPostApi(bodyParameter));
    } else {
      const bodyParameter = {
        postId: postId,
      };
      dispatch(getparticularPostApi(bodyParameter));
    }
  }, [history.location.search]);

  useEffect(() => {
    if (!!singleReactListpage) {
      const bodyParameter = {
        file_id: fileId,
        page: singleReactListpage,
      };
      dispatch(singleFileReactListApi(bodyParameter));
    }
  }, [singleReactListpage]);
  useEffect(() => {
    $("body").click(function () {
      $(".share-box").removeClass("open-share");
    });
    $("body").click(function (event) {
      $(".react-box").removeClass("open-emoji");
    });

    return () => {
      dispatch(clearParticularPostResponse());
    };
  }, []);
  useEffect(() => {
    if (!!particularPostStatus && particularPostStatus == 200) {
      particularPost.comment = "";
      (particularPost.commentList = []), (particularPost.commentPage = 0);

      dispatch(changeParticularPostDetail({ particularPost: particularPost }));
    }
  }, [particularPostStatus]);
  //thi function is used to convert links into hyper text tag.
  const checkLinks = (text) => {
    let newText = "<span>";
    if (!!text) {
      let splStr = text.split(" ");

      for (let i = 0; i < splStr.length; i++) {
        if (
          splStr[i].match(
            "^http(s?)://[0-9a-zA-Z]([-.w]*[0-9a-zA-Z])*(:(0-9)*)*(/?)([a-zA-Z0-9-.?,'/\\+&amp;%$#_]*)?$"
          )
        ) {
          newText =
            newText +
            "<a href=" +
            splStr[i] +
            " target='_blank'>" +
            splStr[i] +
            "</a>" +
            (i !== splStr.length ? " " : "");
        } else {
          newText = newText + splStr[i] + (i !== splStr.length ? " " : "");
        }
      }
      newText = newText + "</span>";
    }
    return newText;
  };
  //this function is used to convert text into links preview
  const convertLinks = (text) => {
    links = [];
    if (!!text) {
      text.trim().replace(/\s\s+/g, " ");
      let splStr = text.split(" ");

      for (let i = 0; i < splStr.length; i++) {
        if (
          splStr[i].match(
            "^http(s?)://[0-9a-zA-Z]([-.w]*[0-9a-zA-Z])*(:(0-9)*)*(/?)([a-zA-Z0-9-.?,'/\\+&amp;%$#_]*)?$"
          )
        ) {
          links.push(splStr[i]);
        }
      }

      return links.map((data) => (
        <Microlink
          url={data}
          media="video"
          size="large"
          style={{ maxWidth: "90%" }}
          autoPlay
        />
      ));
    }
    return "";
  };
  const showPostCommentModel = (index, id) => {
    if (
      document
        .getElementById("comment-listings")
        .getAttribute("class")
        .match("show-comments")
    ) {
      setPostCommentScrool(true);
      particularPost.commentPage = 1;
      particularPost.actualComment = particularPost.comment_count;

      if (!!fileId) {
        const bodyParameter = {
          page: 1,
          post_id: postId,
          file_id: fileId,
        };
        dispatch(particulaFileCommentListApi(bodyParameter));
      } else {
        if (!commentListLoading) {
          const bodyParameter = {
            post_id: postId,
            page: 1,
          };
          dispatch(particularPostCommentListApi(bodyParameter));
        }
      }
    } else {
      particularPost.commentList = [];
      particularPost.commentPage = "";

      dispatch(clearParticularCommentListResponse());
    }
  };
  //this function is used react on post
  const handleReact = (id) => {
    setIsReact(true);
    particularPost.reaction = particularPost.reaction != id ? id : "";
    if (!particularPost.react) {
      particularPost.post_react = particularPost.post_react + 1;
      particularPost.react = !particularPost.react
        ? true
        : particularPost.react;
    }
    dispatch(changeParticularPostDetail({ particularPost: particularPost }));
    if (!!fileId) {
      const bodyParameter = {
        post_id: postId,
        reaction: id,
        file_id: fileId,
      };
      dispatch(singleParticularFileReactApi(bodyParameter));
    } else {
      const bodyParameter = {
        post_id: postId,
        reaction: id,
      };
      dispatch(particularPostReactApi(bodyParameter));
    }
  };
  const closePostModels = () => {
    setOpenModel(false);
  };
  const handleParticularReactList = () => {
    setOpenModel(true);
    if (!!fileId) {
      dispatch(changeParticularReactListDetail({ singleReactListpage: 1 }));
    } else {
      dispatch(changeParticularReactListDetail({ reactListPage: 1 }));
    }
  };
  const handleSumbitParticularComment = (e) => {
    e.preventDefault();
    if (!!particularPost.comment) {
      dispatch(changeParticularPostDetail({ particularPost: particularPost }));
      if (!!fileId) {
        const bodyParameter = {
          post_id: postId,
          comment: particularPost.comment,
          file_id: fileId,
        };
        dispatch(particularFileCommentApi(bodyParameter));
      } else {
        const bodyParameter = {
          post_id: postId,
          comment: particularPost.comment,
        };
        dispatch(particularPostCommentApi(bodyParameter));
      }
      particularPost.comment_count = particularPost.comment_count + 1;
      particularPost.comment = "";
    }
  };
  const handleChangeParticularComment = (e) => {
    particularPost.comment = e.target.value;
    dispatch(changeParticularPostDetail({ particularPost: particularPost }));
  };
  const handleOpenReplyInput = (e, item) => {
    let commentStyle = document.getElementById(
      `cmt-${item.post_id}-input-${item.comment_id}`
    ).style.display;
    if (commentStyle == "none") {
      document.getElementById(
        `cmt-${item.post_id}-input-${item.comment_id}`
      ).style.display = "flex";
    } else {
      document.getElementById(
        `cmt-${item.post_id}-input-${item.comment_id}`
      ).style.display = "none";
    }
  };
  //this function is used to submit details when reply on comment in post.
  const handleCommentReplySumbit = (e, data, index) => {
    e.preventDefault();
    if (!!data.replyComment) {
      if (!!fileId) {
        const bodyParameter = {
          post_id: data.post_id,
          file_id: data.file_id,
          comment_id: data.comment_id,
          comment: data.replyComment,
        };
        dispatch(particularFileCommentReplyApi(bodyParameter));
      } else {
        const bodyParameter = {
          post_id: data.post_id,
          comment_id: data.comment_id,
          comment: data.replyComment,
        };
        dispatch(particularPostCmtReplyApi(bodyParameter));
      }

      const innerPost = particularPost.commentList;
      for (let j in innerPost) {
        if (j == index) {
          innerPost[j].replyComment = "";
          innerPost[j].reply = innerPost[j].reply + 1;

          dispatch(
            changeParticularPostDetail({ particularPost: particularPost })
          );
        }
      }
    }
  };
  //this function is used to change reply comment
  const handleChangeReplyInput = (e, id) => {
    const innerPost = particularPost.commentList;
    for (let j in innerPost) {
      if (id == innerPost[j].comment_id) {
        innerPost[j].replyComment = e.target.value;
        dispatch(
          changeParticularPostDetail({ particularPost: particularPost })
        );
      }
    }
  };
  const handleOpenNestedReply = (item, index) => {
    let nestedComment = document.getElementById(
      `cmt-${item.post_id}-nested-${item.comment_id}`
    ).style.display;

    if (nestedComment == "none") {
      const innerPost = particularPost.commentList;
      for (let j in innerPost) {
        if (j == index) {
          innerPost[j].replyCommentPage = innerPost[j].replyCommentPage + 1;
          innerPost[j].showHide = true;
          innerPost[j].actualReplyComment = innerPost[j].reply;
        }
      }

      dispatch(
        changeParticularCommentListDetail({ commentId: item.comment_id })
      );
      document.getElementById(
        `cmt-${item.post_id}-nested-${item.comment_id}`
      ).style.display = "block";
      if (!!fileId) {
        const bodyParameter = {
          page: 1,
          post_id: item.post_id,
          file_id: item.file_id,
          comment_id: item.comment_id,
        };
        dispatch(fileCommentReplyListApi(bodyParameter));
      } else {
        const bodyParameter = {
          page: 1,
          post_id: item.post_id,
          comment_id: item.comment_id,
        };
        dispatch(particularPostCmtReplyListApi(bodyParameter));
      }
    } else {
      const innerPost = particularPost.commentList;
      for (let j in innerPost) {
        if (j == index) {
          innerPost[j].subComment = [];
          innerPost[j].replyCommentPage = 0;
          innerPost[j].showHide = false;
          innerPost[j].actualReplyComment = 0;
        }
      }
      document.getElementById(
        `cmt-${item.post_id}-nested-${item.comment_id}`
      ).style.display = "none";
    }
  };
  //this function is used to fetch replied comment list
  const handleReplyCommentPaginate = (data, index) => {
    const innerPost = particularPost.commentList;
    for (let j in innerPost) {
      if (j == index) {
        innerPost[j].replyCommentPage = innerPost[j].replyCommentPage + 1;

        dispatch(
          changeParticularPostDetail({ particularPost: particularPost })
        );
      }
    }

    if (!!fileId) {
      const bodyParameter = {
        page: data.replyCommentPage,
        post_id: data.post_id,
        file_id: data.file_id,
        comment_id: data.comment_id,
      };
      dispatch(fileCommentReplyListApi(bodyParameter));
    } else {
      const bodyParameter = {
        page: data.replyCommentPage,
        post_id: data.post_id,
        comment_id: data.comment_id,
      };
      dispatch(particularPostCmtReplyListApi(bodyParameter));
    }
  };
  //this function is used to fetch comment list according to page
  const handleCommentPaginate = (data) => {
    setPostCommentScrool(false);
    particularPost.commentPage = particularPost.commentPage + 1;
    if (!!fileId) {
      const bodyParameter = {
        page: particularPost.commentPage,
        post_id: postId,
        file_id: fileId,
      };
      dispatch(particulaFileCommentListApi(bodyParameter));
    } else {
      const bodyParameter = {
        post_id: data.post_id,
        page: data.commentPage,
      };
      dispatch(particularPostCommentListApi(bodyParameter));
    }
  };
  const closeViewModel = () => {
    setViewmodel(false);
  };
  //this function is used to fetch view list.
  const handelViewList = () => {
    setViewmodel(true);
    if (!!fileId) {
      dispatch(changeParticularViewDetail({ singleViewListpage: 1 }));
    } else {
      dispatch(changeParticularViewDetail({ viewListPage: 1 }));
    }
  };
  const handleShareFeeds = () => {
    if (!!fileId) {
      const bodyParameter = {
        post_id: postId,
        file_id: fileId,
      };
      dispatch(particularFileShareApi(bodyParameter));
    } else {
      const bodyParameter = {
        post_id: postId,
      };
      dispatch(particularPostFeedShareApi(bodyParameter));
    }
  };
  //this function is used to fetch share list
  const handleShareList = () => {
    setOpenShareModel(true);
    if (!!fileId) {
      dispatch(changeParticularShareListDetil({ singleShareListpage: 1 }));
    } else {
      dispatch(changeParticularShareListDetil({ shareListPage: 1 }));
    }
  };
  const handleCloseShareModel = () => {
    setOpenShareModel(false);
  };
  const handleOpenPostProfile = (item) => {
    if (profileData.id == item.user_id) {
      history.push(`/account/${profileData.user_slug}`);
    } else {
      history.push(
        `/search-profile/${item.user_id}`

        // `/search-profile/${item.user_id}?individual=${!!item.is_individual ? true : false
        // }`
      );
    }
  };

  const handleOpenPostSharedProfile = (data) => {
    if (profileData.id == data.shared_by_user.user_id) {
      history.push(`/account/${profileData?.user_slug}`);
    } else {
      history.push(
        `/search-profile/${data.shared_by_user.user_id}`

        // `/search-profile/${data.shared_by_user.user_id}?individual=${!!data.shared_by_user.id_individual ? true : false
        // }`
      );
    }
  };
  const handlePlay = (e) => {
    var videos = document.querySelectorAll("video");
    videos.forEach(function (video) {
      if (e.currentTarget.id == video.id) {
        video.play();
      } else {
        video.pause();
      }
    });
  };

  return (
    <>
      <section className="feeds bg-primary spacer">
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6 mx-auto">
              {!!fileId ? (
                <div className="card my-4">
                  <div className="card-header d-flex align-items-center">
                    <div className="feeds-post__user mr-3">
                      {!!particularPost.is_individual ? (
                        <img
                          onClick={() => handleOpenPostProfile(particularPost)}
                          style={{ cursor: "pointer" }}
                          onError={(ev) =>
                            (ev.target.src = "/assets/images/member-default.svg")
                          }
                          src={
                            !!particularPost
                              ? IMAGE_BASE_URL + particularPost.profile_image
                              : "/assets/images/member-default.svg"
                          }
                          alt="user"
                        />
                      ) : (
                        <div className="business-pofile-lead">
                          <img
                            onClick={() =>
                              handleOpenPostProfile(particularPost)
                            }
                            style={{ cursor: "pointer" }}
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/business_logo.svg")
                            }
                            src={
                              !!particularPost
                                ? IMAGE_BASE_URL + particularPost.business_image
                                : "/assets/images/business_logo.svg"
                            }
                            alt="logo"
                          />
                        </div>
                      )}
                    </div>
                    <div className="card-header__timeline">
                      {!!particularPost.is_individual ? (
                        <h6
                          onClick={() => handleOpenPostProfile(particularPost)}
                          className="mb-0 text-capitalize"
                          style={{ cursor: "pointer" }}
                        >
                          {particularPost.user_name}
                        </h6>
                      ) : (
                        <h6
                          onClick={() => handleOpenPostProfile(particularPost)}
                          className="mb-0 text-capitalize"
                          style={{ cursor: "pointer" }}
                        >
                          {particularPost.business_name}
                        </h6>
                      )}
                      {!!particularPost.is_individual ? (
                        <span className="small text-capitalize">
                          {!!particularPost.user_work
                            ? particularPost.user_work
                            : ""}
                        </span>
                      ) : (
                        <span className="small text-capitalize">
                          {!!particularPost.user_specialization
                            ? particularPost.user_specialization
                            : ""}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      {particularPost.extension == "mp4" ||
                        particularPost.extension == "mov" ||
                        particularPost.extension == "MP4" ||
                        particularPost.extension == "MOV" ? (
                        <video
                          onPlay={(e) => handlePlay(e)}
                          id={"videoPause" + index}
                          controls="controls"
                          src={IMAGE_BASE_URL + particularPost.file_name}
                          data-toggle="modal"
                          data-target="#post-modal"
                        ></video>
                      ) : (
                        <img
                          onError={(ev) => addDefaultSrc(ev)}
                          src={
                            !!particularPost.file_name
                              ? IMAGE_BASE_URL + particularPost.file_name
                              : returnDefaultImage()
                          }
                          data-toggle="modal"
                          data-target="#post-modal"
                          className="w-100"
                        />
                      )}
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="post-status d-flex align-items-center justify-content-between">
                      <div className="post__action d-flex align-items-center">
                        <div className="post__action__inner d-flex justify-content-between">
                          <div className="feed__reaction react-icon">
                            <div className="reaction d-flex align-items-center">
                              <div className="react-open">
                                {!!particularPost.react_count ? (
                                  particularPost.reaction_list.length > 0 && (
                                    <div className="reaction-listing">
                                      {particularPost.reaction_list.map(
                                        (item) => {
                                          return (
                                            <div
                                              className={
                                                !!Number(item.Reaction_check)
                                                  ? "my-react"
                                                  : ""
                                              }
                                            >
                                              {item.reaction == 1 ? (
                                                <img
                                                  src="/assets/images/likepng1.png"
                                                  alt="Like"
                                                />
                                              ) : item.reaction == 2 ? (
                                                <img
                                                  src="/assets/images/love.png"
                                                  alt="Love"
                                                />
                                              ) : item.reaction == 3 ? (
                                                <img
                                                  src="/assets/images/clap.png"
                                                  alt="clab"
                                                />
                                              ) : item.reaction == 4 ? (
                                                <img
                                                  src="/assets/images/celebrate.png"
                                                  alt="celebrate"
                                                />
                                              ) : item.reaction == 5 ? (
                                                <img
                                                  src="/assets/images/think.png"
                                                  alt="Thinking"
                                                />
                                              ) : (
                                                (item.reaction = 6 ? (
                                                  <img
                                                    src="/assets/images/upset.png"
                                                    alt="upset"
                                                  />
                                                ) : (
                                                  ""
                                                ))
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <i className="ri-emotion-line" />
                                )}
                              </div>
                              <a
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#react-modal"
                                onClick={() => handleParticularReactList()}
                              >
                                {" "}
                                {particularPost.react_count > 0
                                  ? numFormatter(particularPost.react_count)
                                  : ""}
                              </a>{" "}
                              <span
                                style={{ cursor: "pointer" }}
                                className="react-model"
                              >
                                &nbsp;React
                              </span>
                            </div>
                            <div className="react-box">
                              <div className="react-box">
                                <a
                                  className={
                                    particularPost.reaction == 1
                                      ? "last-rection"
                                      : ""
                                  }
                                  href="javascript:;"
                                  id="1"
                                >
                                  <img
                                    src="/assets/images/300x300.gif"
                                    alt="Like"
                                    onClick={() => handleReact(1)}
                                  />
                                </a>
                                <a
                                  className={
                                    particularPost.reaction == 2
                                      ? "last-rection"
                                      : ""
                                  }
                                  href="javascript:;"
                                  id="2"
                                >
                                  <img
                                    src="/assets/images/love.gif"
                                    alt="Love"
                                    onClick={() => handleReact(2)}
                                  />
                                </a>
                                <a
                                  className={
                                    particularPost.reaction == 3
                                      ? "last-rection"
                                      : ""
                                  }
                                  href="javascript:;"
                                  id="3"
                                >
                                  <img
                                    src="/assets/images/clap.gif"
                                    alt="clab"
                                    onClick={() => handleReact(3)}
                                  />
                                </a>
                                <a
                                  className={
                                    particularPost.reaction == 4
                                      ? "last-rection"
                                      : ""
                                  }
                                  href="javascript:;"
                                  id="4"
                                >
                                  <img
                                    src="/assets/images/celebrate.gif"
                                    alt="celebrate"
                                    onClick={() => handleReact(4)}
                                  />
                                </a>
                                <a
                                  className={
                                    particularPost.reaction == 5
                                      ? "last-rection"
                                      : ""
                                  }
                                  href="javascript:;"
                                  id="5"
                                >
                                  <img
                                    src="/assets/images/thinking.gif"
                                    alt="Thinking"
                                    onClick={() => handleReact(5)}
                                  />
                                </a>
                                <a
                                  className={
                                    particularPost.reaction == 6
                                      ? "last-rection"
                                      : ""
                                  }
                                  href="javascript:;"
                                  id="6"
                                >
                                  <img
                                    src="/assets/images/upset.gif"
                                    alt="upset"
                                    onClick={() => handleReact(6)}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="post-share-link">
                          <a
                            href="javascript:;"
                            className="btn-sm ml-auto link-txt"
                          >
                            <i className="ri-share-forward-line"></i> Share
                          </a>
                          <div className="share-box">
                            <ul className="list-unstyled mb-0">
                              <li>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleShareFeeds()}
                                  className="link-color"
                                >
                                  <i className="ri-file-list-3-line" /> Share In
                                  feed
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="post-status__comments d-flex">
                        {particularPost.view_count > 0 ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handelViewList()}
                          >
                            {numFormatter(particularPost.view_count)} Views
                          </span>
                        ) : (
                          ""
                        )}
                        {particularPost.share_count > 0 ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShareList()}
                          >
                            {numFormatter(particularPost.share_count)} Share
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="post_comment" id="post_comments">
                      <div
                        className="post_comment__counter d-flex my-3 justify-content-between align-items-center"
                        onClick={() => showPostCommentModel()}
                      >
                        <span
                          style={{
                            cursor: !!particularPost.comment_count
                              ? "pointer"
                              : "default",
                          }}
                          className="open-comment"
                        >

                          {!!particularPost.comment_count > 0
                            ? 'Comments' + " " + numFormatter(particularPost.comment_count)
                            : "No Comments"}
                        </span>
                        {!!particularPost.comment_count &&
                          <span
                            style={{
                              cursor: !!particularPost.comment_count
                                ? "pointer"
                                : "default",
                            }}
                            className="open-comment"
                          >
                            <i className="ri-arrow-down-s-line"></i>
                            <i className="ri-arrow-down-s-line"></i>
                          </span>}
                      </div>
                      <div id="comment-listings" className="comment-post ">
                        {!!particularPost.commentList &&
                          !!particularPost.commentList.length > 0 &&
                          particularPost.commentPage * 5 <
                          particularPost.actualComment ? (
                          <span
                            className="load-more"
                            onClick={() =>
                              handleCommentPaginate(particularPost)
                            }
                            style={{ cursor: "pointer" }}>
                            <i className="ri-download-line align-middle"></i>{" "}
                            Load More
                          </span>
                        ) : (
                          ""
                        )}
                        {!!particularPost.commentList &&
                          particularPost.commentList.length > 0 &&
                          particularPost.commentList.length > 4 ? (
                          <Scrollbars
                            id="scroll-single-post"
                            style={{ width: 550, height: 375 }}
                            autoHide
                          >
                            {particularPost.commentList.map((item, i) => (
                              <div className="post_comment__listing my-3">
                                <div className="post_comment__listing__user">
                                  {!!item.is_individual ? (
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOpenPostProfile(item)
                                      }
                                      onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/member-default.svg")
                                      }
                                      src={
                                        !!item
                                          ? IMAGE_BASE_URL + item.image
                                          : "/assets/images/member-default.svg"
                                      }
                                      alt="user"
                                    />
                                  ) : (
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOpenPostProfile(item)
                                      }
                                      onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/business_logo.svg")
                                      }
                                      src={
                                        !!item
                                          ? IMAGE_BASE_URL + item.image
                                          : "/assets/images/business_logo.svg"
                                      }
                                      alt="logo"
                                    />
                                  )}
                                </div>
                                <div className="post_comment__listing__review">
                                  <div className="comment-bg">
                                    <p className="mb-1">{item.comment}</p>
                                  </div>
                                  <div className="view-reply">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        handleOpenReplyInput(e, item)
                                      }
                                    >
                                      <i className="ri-reply-line" /> Reply
                                    </span>
                                    {!!item.reply ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleOpenNestedReply(item, i)
                                        }
                                        className="open-reply"
                                      >
                                        <i className="ri-eye-line" />
                                        {!!item.showHide ? "Hide" : "View"}{" "}
                                        {item.reply} Reply
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div
                                    style={{ display: "none" }}
                                    id={`cmt-${item.post_id}-nested-${item.comment_id}`}
                                  >
                                    {!!item.subComment &&
                                      !!item.subComment.length > 0 &&
                                      item.replyCommentPage * 3 <
                                      item.actualReplyComment ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        className="d-block text-center"
                                        onClick={() =>
                                          handleReplyCommentPaginate(item, i)
                                        }
                                      >
                                        View{" "}
                                        {item.reply - item.subComment.length}{" "}
                                        pervious replies
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {!!item.subComment &&
                                      item.subComment.map((subComment) => {
                                        return (
                                          <div className="post_comment__listing post__comment__nested ">
                                            <div className="post_comment__listing__user">
                                              {!!subComment.is_individual ? (
                                                <img
                                                  onError={(ev) =>
                                                  (ev.target.src =
                                                    "/assets/images/member-default.svg")
                                                  }
                                                  src={
                                                    !!subComment
                                                      ? IMAGE_BASE_URL +
                                                      subComment.image
                                                      : "/assets/images/member-default.svg"
                                                  }
                                                  alt="user"
                                                />
                                              ) : (
                                                <img
                                                  onError={(ev) =>
                                                  (ev.target.src =
                                                    "/assets/images/business_logo.svg")
                                                  }
                                                  src={
                                                    !!subComment
                                                      ? IMAGE_BASE_URL +
                                                      subComment.image
                                                      : "/assets/images/business_logo.svg"
                                                  }
                                                  alt="logo"
                                                />
                                              )}
                                            </div>
                                            <div className="post_comment__listing__commentbox">
                                              <div className="comment-bg">
                                                <p>{subComment.comment}</p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                  <div
                                    style={{ display: "none" }}
                                    id={`cmt-${item.post_id}-input-${item.comment_id}`}
                                    className="post_comment__listing post__comment__nested"
                                  >
                                    <div className="post_comment__listing__user">
                                      {!!profileData.is_individual ? (
                                        <img
                                          onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/member-default.svg")
                                          }
                                          src={
                                            !!profileData
                                              ? IMAGE_BASE_URL +
                                              profileData.profile_image
                                              : "/assets/images/member-default.svg"
                                          }
                                          alt="user"
                                        />
                                      ) : (
                                        <img
                                          onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/business_logo.svg")
                                          }
                                          src={
                                            !!profileData
                                              ? IMAGE_BASE_URL +
                                              profileData.business_image
                                              : "/assets/images/business_logo.svg"
                                          }
                                          alt="logo"
                                        />
                                      )}{" "}
                                    </div>
                                    <div className="post_comment__listing__commentbox">
                                      <form
                                        onSubmit={(e) =>
                                          handleCommentReplySumbit(e, item, i)
                                        }
                                        autoComplete="off"
                                      >
                                        <Input
                                          type="text"
                                          name="replyComment"
                                          value={item.replyComment}
                                          onChange={(e) =>
                                            handleChangeReplyInput(
                                              e,
                                              item.comment_id
                                            )
                                          }
                                          className="form-control"
                                          placeholder="Write a comment"
                                        />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Scrollbars>
                        ) : (
                          !!particularPost.commentList &&
                          particularPost.commentList.length > 0 &&
                          particularPost.commentList.map((item, i) => (
                            <div className="post_comment__listing my-3">
                              <div className="post_comment__listing__user">
                                {!!item.is_individual ? (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOpenPostProfile(item)}
                                    onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/member-default.svg")
                                    }
                                    src={
                                      !!item
                                        ? IMAGE_BASE_URL + item.image
                                        : "/assets/images/member-default.svg"
                                    }
                                    alt="user"
                                  />
                                ) : (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOpenPostProfile(item)}
                                    onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/business_logo.svg")
                                    }
                                    src={
                                      !!item
                                        ? IMAGE_BASE_URL + item.image
                                        : "/assets/images/business_logo.svg"
                                    }
                                    alt="logo"
                                  />
                                )}
                              </div>
                              <div className="post_comment__listing__review">
                                <div className="comment-bg">
                                  <p className="mb-1">{item.comment}</p>
                                </div>
                                <div className="view-reply">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      handleOpenReplyInput(e, item)
                                    }
                                  >
                                    <i className="ri-reply-line" /> Reply
                                  </span>
                                  {!!item.reply ? (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOpenNestedReply(item, i)
                                      }
                                      className="open-reply"
                                    >
                                      <i className="ri-eye-line" />
                                      {!!item.showHide ? "Hide" : "View"}{" "}
                                      {item.reply} reply
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  style={{ display: "none" }}
                                  id={`cmt-${item.post_id}-nested-${item.comment_id}`}
                                >
                                  {!!item.subComment &&
                                    !!item.subComment.length > 0 &&
                                    item.replyCommentPage * 3 <
                                    item.actualReplyComment ? (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="d-block text-center"
                                      onClick={() =>
                                        handleReplyCommentPaginate(item, i)
                                      }
                                    >
                                      View {item.reply - item.subComment.length}{" "}
                                      pervious replies
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {!!item.subComment &&
                                    item.subComment.map((subComment) => {
                                      return (
                                        <div className="post_comment__listing post__comment__nested ">
                                          <div className="post_comment__listing__user">
                                            {!!subComment.is_individual ? (
                                              <img
                                                onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/member-default.svg")
                                                }
                                                src={
                                                  !!subComment
                                                    ? IMAGE_BASE_URL +
                                                    subComment.image
                                                    : "/assets/images/member-default.svg"
                                                }
                                                alt="user"
                                              />
                                            ) : (
                                              <img
                                                onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/business_logo.svg")
                                                }
                                                src={
                                                  !!subComment
                                                    ? IMAGE_BASE_URL +
                                                    subComment.image
                                                    : "/assets/images/business_logo.svg"
                                                }
                                                alt="logo"
                                              />
                                            )}
                                          </div>
                                          <div className="post_comment__listing__commentbox">
                                            <div className="comment-bg">
                                              <p>{subComment.comment}</p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                                <div
                                  style={{ display: "none" }}
                                  id={`cmt-${item.post_id}-input-${item.comment_id}`}
                                  className="post_comment__listing post__comment__nested"
                                >
                                  <div className="post_comment__listing__user">
                                    {!!profileData.is_individual ? (
                                      <img
                                        onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/member-default.svg")
                                        }
                                        src={
                                          !!profileData
                                            ? IMAGE_BASE_URL +
                                            profileData.profile_image
                                            : "/assets/images/member-default.svg"
                                        }
                                        alt="user"
                                      />
                                    ) : (
                                      <img
                                        onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/business_logo.svg")
                                        }
                                        src={
                                          !!profileData
                                            ? IMAGE_BASE_URL +
                                            profileData.business_image
                                            : "/assets/images/business_logo.svg"
                                        }
                                        alt="logo"
                                      />
                                    )}{" "}
                                  </div>
                                  <div className="post_comment__listing__commentbox">
                                    <form
                                      onSubmit={(e) =>
                                        handleCommentReplySumbit(e, item, i)
                                      }
                                      autoComplete="off"
                                    >
                                      <Input
                                        type="text"
                                        name="replyComment"
                                        value={item.replyComment}
                                        onChange={(e) =>
                                          handleChangeReplyInput(
                                            e,
                                            item.comment_id
                                          )
                                        }
                                        className="form-control"
                                        placeholder="Write a comment"
                                      />
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>

                      <div className="post__commtent__user">
                        <div className="feeds-post__header d-flex flex-wrap align-items-center">
                          <div className="feeds-post__user">
                            {!!profileData.is_individual ? (
                              <img
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/member-default.svg")
                                }
                                src={
                                  !!profileData
                                    ? IMAGE_BASE_URL + profileData.profile_image
                                    : "/assets/images/member-default.svg"
                                }
                                alt="user"
                              />
                            ) : (
                              <img
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/business_logo.svg")
                                }
                                src={
                                  !!profileData
                                    ? IMAGE_BASE_URL +
                                    profileData.business_image
                                    : "/assets/images/business_logo.svg"
                                }
                                alt="logo"
                              />
                            )}
                          </div>
                          <form
                            onSubmit={(e) => handleSumbitParticularComment(e)}
                            className="comment-form"
                            autoComplete="off"
                          >
                            <Input
                              value={particularPost.comment}
                              onChange={(e) => handleChangeParticularComment(e)}
                              type="text"
                              name="comment"
                              className="form-control"
                              placeholder="Write a comment"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card my-4">
                  <div className="card-header d-flex align-items-center">
                    <div className="feeds-post__user mr-3">
                      {!!particularPost.shared ? (
                        !!particularPost.shared_by_user.id_individual ? (
                          <img
                            onClick={() =>
                              handleOpenPostSharedProfile(particularPost)
                            }
                            style={{ cursor: "pointer" }}
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                            }
                            src={
                              !!particularPost
                                ? IMAGE_BASE_URL +
                                particularPost.shared_by_user.user_profile
                                : "/assets/images/member-default.svg"
                            }
                            alt="user"
                          />
                        ) : (
                          <div className="business-pofile-lead">
                            <img
                              onClick={() =>
                                handleOpenPostSharedProfile(particularPost)
                              }
                              style={{ cursor: "pointer" }}
                              onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/business_logo.svg")
                              }
                              src={
                                !!particularPost
                                  ? IMAGE_BASE_URL +
                                  particularPost.shared_by_user.business_image
                                  : "/assets/images/business_logo.svg"
                              }
                              alt="logo"
                            />
                          </div>
                        )
                      ) : !!particularPost.is_individual ? (
                        <img
                          onClick={() => handleOpenPostProfile(particularPost)}
                          style={{ cursor: "pointer" }}
                          onError={(ev) =>
                            (ev.target.src = "/assets/images/member-default.svg")
                          }
                          src={
                            !!particularPost
                              ? IMAGE_BASE_URL + particularPost.user_profile
                              : "/assets/images/member-default.svg"
                          }
                          alt="user"
                        />
                      ) : (
                        <div className="business-pofile-lead">
                          <img
                            onClick={() =>
                              handleOpenPostProfile(particularPost)
                            }
                            style={{ cursor: "pointer" }}
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/business_logo.svg")
                            }
                            src={
                              !!particularPost
                                ? IMAGE_BASE_URL + particularPost.business_image
                                : "/assets/images/business_logo.svg"
                            }
                            alt="logo"
                          />
                        </div>
                      )}
                    </div>
                    <div className="card-header__timeline">
                      {!!particularPost.shared ? (
                        !!particularPost.shared_by_user.id_individual ? (
                          <h6
                            onClick={() =>
                              handleOpenPostSharedProfile(particularPost)
                            }
                            className="mb-0 text-capitalize"
                            style={{ cursor: "pointer" }}
                          >
                            {particularPost.shared_by_user.user_name}
                          </h6>
                        ) : (
                          <h6
                            onClick={() =>
                              handleOpenPostSharedProfile(particularPost)
                            }
                            className="mb-0 text-capitalize"
                            style={{ cursor: "pointer" }}
                          >
                            {particularPost.shared_by_user.business_name}
                          </h6>
                        )
                      ) : !!particularPost.is_individual ? (
                        <h6
                          onClick={() => handleOpenPostProfile(particularPost)}
                          className="mb-0 text-capitalize"
                          style={{ cursor: "pointer" }}
                        >
                          {particularPost.user_name}
                        </h6>
                      ) : (
                        <h6
                          onClick={() => handleOpenPostProfile(particularPost)}
                          className="mb-0 text-capitalize"
                          style={{ cursor: "pointer" }}
                        >
                          {particularPost.business_name}
                        </h6>
                      )}
                      {!!particularPost.shared ? (
                        !!particularPost.shared_by_user.id_individual ? (
                          <span className="small text-capitalize">
                            {!!particularPost.shared_by_user.work
                              ? particularPost.shared_by_user.work
                              : ""}
                          </span>
                        ) : (
                          <span className="small text-capitalize">
                            {!!particularPost.shared_by_user.specialization
                              ? particularPost.shared_by_user.specialization
                              : ""}
                          </span>
                        )
                      ) : !!particularPost.is_individual ? (
                        <span className="small text-capitalize">
                          {!!particularPost.work ? particularPost.work : ""}
                        </span>
                      ) : (
                        <span className="small text-capitalize">
                          {!!particularPost.specialization
                            ? particularPost.specialization
                            : ""}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    {particularPost.post_text !== " " && (
                      <div
                        className="card-text mb-4"
                        dangerouslySetInnerHTML={{
                          __html: checkLinks(particularPost.post_text),
                        }}
                      ></div>
                    )}
                    {convertLinks(particularPost.post_text)}
                    {!!particularPost &&
                      !!particularPost.post_data &&
                      (particularPost.post_data.length > 1 ? (
                        <Carousel
                          useKeyboardArrows={true}
                          emulateTouch={true}
                          className="home-post"
                          showStatus={false}
                          showArrows={true}
                          swipeable={true}
                          showThumbs={false}
                          autoPlay={false}
                        >
                          {particularPost.post_data.map((datas, index, i) => {
                            return (
                              <div>
                                {datas.extension == "mp4" ||
                                  datas.extension == "mov" ||
                                  datas.extension == "MP4" ||
                                  datas.extension == "MOV" ? (
                                  <video
                                    onPlay={(e) => handlePlay(e)}
                                    id={"videoPause" + index + i}
                                    src={IMAGE_BASE_URL + datas.file_name}
                                    controls
                                    data-toggle="modal"
                                    data-target="#post-modal"
                                  />
                                ) : (
                                  <div
                                    data-toggle="modal"
                                    data-target="#post-modal"
                                  >
                                    <img
                                      onError={(ev) => addDefaultSrc(ev)}
                                      className="d-block w-100"
                                      src={
                                        !!datas.file_name
                                          ? IMAGE_BASE_URL + datas.file_name
                                          : returnDefaultImage()
                                      }
                                      alt="First slide"
                                      data-toggle="modal"
                                      data-target="#post-modal"
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </Carousel>
                      ) : (
                        particularPost.post_data.map((image) => {
                          return (
                            <div className="text-center">
                              {image.extension == "mp4" ||
                                image.extension == "mov" ||
                                image.extension == "MP4" ||
                                image.extension == "MOV" ? (
                                <video
                                  onPlay={(e) => handlePlay(e)}
                                  id={"videoPause"}
                                  controls
                                  src={IMAGE_BASE_URL + image.file_name}
                                  data-toggle="modal"
                                  data-target="#post-modal"
                                ></video>
                              ) : (
                                <img
                                  onError={(ev) => addDefaultSrc(ev)}
                                  src={
                                    !!image.file_name
                                      ? IMAGE_BASE_URL + image.file_name
                                      : returnDefaultImage()
                                  }
                                  data-toggle="modal"
                                  data-target="#post-modal"
                                  className="w-100"
                                />
                              )}
                            </div>
                          );
                        })
                      ))}

                    {!!particularPost.shared ? (
                      <div className="share-post d-flex align-items-center mt-3">
                        <div className="feeds-post__user mr-3 share-post-user">
                          {!!particularPost.is_individual ? (
                            <img
                              onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/member-default.svg")
                              }
                              src={
                                !!particularPost
                                  ? IMAGE_BASE_URL + particularPost.user_profile
                                  : "/assets/images/member-default.svg"
                              }
                              alt="user"
                            />
                          ) : (
                            <div className="business-pofile-lead">
                              <img
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/business_logo.svg")
                                }
                                src={
                                  !!particularPost
                                    ? IMAGE_BASE_URL +
                                    particularPost.business_image
                                    : "/assets/images/business_logo.svg"
                                }
                                alt="logo"
                              />
                            </div>
                          )}
                        </div>
                        <div className="share-post__user">
                          {!!particularPost.is_individual ? (
                            <h6 className="mb-0 text-capitalize">
                              {particularPost.user_name}
                            </h6>
                          ) : (
                            <h6 className="mb-0 text-capitalize">
                              {particularPost.business_name}
                            </h6>
                          )}
                          {!!particularPost.is_individual ? (
                            <span className="small text-capitalize">
                              {!!particularPost.work ? particularPost.work : ""}
                            </span>
                          ) : (
                            <span className="small text-capitalize">
                              {!!particularPost.specialization
                                ? particularPost.specialization
                                : ""}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-footer">
                    <div className="post-status d-flex align-items-center justify-content-between">
                      <div className="post__action d-flex align-items-center">
                        <div className="post__action__inner d-flex justify-content-between">
                          <div className="feed__reaction react-icon">
                            <div className="reaction d-flex align-items-center">
                              <div className="react-open">
                                {!!particularPost.post_react ? (
                                  particularPost.reaction_list.length > 0 && (
                                    <div className="reaction-listing">
                                      {particularPost.reaction_list.map(
                                        (item) => {
                                          return (
                                            <div
                                              className={
                                                !!Number(item.Reaction_check)
                                                  ? "my-react"
                                                  : ""
                                              }
                                            >
                                              {item.reaction == 1 ? (
                                                <img
                                                  src="/assets/images/likepng1.png"
                                                  alt="Like"
                                                />
                                              ) : item.reaction == 2 ? (
                                                <img
                                                  src="/assets/images/love.png"
                                                  alt="Love"
                                                />
                                              ) : item.reaction == 3 ? (
                                                <img
                                                  src="/assets/images/clap.png"
                                                  alt="clab"
                                                />
                                              ) : item.reaction == 4 ? (
                                                <img
                                                  src="/assets/images/celebrate.png"
                                                  alt="celebrate"
                                                />
                                              ) : item.reaction == 5 ? (
                                                <img
                                                  src="/assets/images/think.png"
                                                  alt="Thinking"
                                                />
                                              ) : (
                                                (item.reaction = 6 ? (
                                                  <img
                                                    src="/assets/images/upset.png"
                                                    alt="upset"
                                                  />
                                                ) : (
                                                  ""
                                                ))
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <i className="ri-emotion-line" />
                                )}
                              </div>
                              <a
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#react-modal"
                                onClick={() => handleParticularReactList()}
                              >
                                {" "}
                                {particularPost.post_react > 0
                                  ? numFormatter(particularPost.post_react)
                                  : ""}
                              </a>{" "}
                              <span
                                style={{ cursor: "pointer" }}
                                className="react-model"
                              >
                                &nbsp;React
                              </span>
                            </div>
                            <div className="react-box">
                              <a
                                className={
                                  particularPost.reaction == 1
                                    ? "last-rection"
                                    : ""
                                }
                                href="javascript:;"
                                id="1"
                              >
                                <img
                                  src="/assets/images/300x300.gif"
                                  alt="Like"
                                  onClick={() => handleReact(1)}
                                />
                              </a>
                              <a
                                className={
                                  particularPost.reaction == 2
                                    ? "last-rection"
                                    : ""
                                }
                                href="javascript:;"
                                id="2"
                              >
                                <img
                                  src="/assets/images/love.gif"
                                  alt="Love"
                                  onClick={() => handleReact(2)}
                                />
                              </a>
                              <a
                                className={
                                  particularPost.reaction == 3
                                    ? "last-rection"
                                    : ""
                                }
                                href="javascript:;"
                                id="3"
                              >
                                <img
                                  src="/assets/images/clap.gif"
                                  alt="clab"
                                  onClick={() => handleReact(3)}
                                />
                              </a>
                              <a
                                className={
                                  particularPost.reaction == 4
                                    ? "last-rection"
                                    : ""
                                }
                                href="javascript:;"
                                id="4"
                              >
                                <img
                                  src="/assets/images/celebrate.gif"
                                  alt="celebrate"
                                  onClick={() => handleReact(4)}
                                />
                              </a>
                              <a
                                className={
                                  particularPost.reaction == 5
                                    ? "last-rection"
                                    : ""
                                }
                                href="javascript:;"
                                id="5"
                              >
                                <img
                                  src="/assets/images/thinking.gif"
                                  alt="Thinking"
                                  onClick={() => handleReact(5)}
                                />
                              </a>
                              <a
                                className={
                                  particularPost.reaction == 6
                                    ? "last-rection"
                                    : ""
                                }
                                href="javascript:;"
                                id="6"
                              >
                                <img
                                  src="/assets/images/upset.gif"
                                  alt="upset"
                                  onClick={() => handleReact(6)}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="post-share-link">
                          <a
                            href="javascript:;"
                            className="btn-sm ml-auto link-txt"
                          >
                            <i className="ri-share-forward-line"></i> Share
                          </a>
                          <div className="share-box">
                            <ul className="list-unstyled mb-0">
                              <li>
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="link-color"
                                  onClick={() => handleShareFeeds()}
                                >
                                  <i className="ri-file-list-3-line" /> Share In
                                  feed
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="post-status__comments d-flex">
                        {particularPost.post_views > 0 ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handelViewList()}
                          >
                            {numFormatter(particularPost.post_views)} Views
                          </span>
                        ) : (
                          ""
                        )}
                        {particularPost.share_count > 0 ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShareList()}
                          >
                            {numFormatter(particularPost.share_count)} Share
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="post_comment" id="post_comments">
                      <div
                        className="post_comment__counter d-flex my-3 justify-content-between align-items-center"
                        onClick={() => showPostCommentModel()}
                      >
                        <span
                          style={{
                            cursor: !!particularPost.comment_count
                              ? "pointer"
                              : "default",
                          }}
                          className="open-comment"
                        >

                          {!!particularPost.comment_count > 0
                            ? 'Comments' + " " + numFormatter(particularPost.comment_count)
                            : "No Comments"}
                        </span>
                        {!!particularPost.comment_count && <span
                          style={{
                            cursor: !!particularPost.comment_count
                              ? "pointer"
                              : "default",
                          }}
                          className="open-comment"
                        >
                          <i className="ri-arrow-down-s-line"></i>
                          <i className="ri-arrow-down-s-line"></i>
                        </span>}
                      </div>
                      <div id="comment-listings" className="comment-post">
                        {!!particularPost.commentList &&
                          !!particularPost.commentList.length > 0 &&
                          particularPost.commentPage * 5 <
                          particularPost.actualComment ? (
                          <span
                            className="load-more"
                            onClick={() =>
                              handleCommentPaginate(particularPost)
                            }
                            style={{ cursor: "pointer" }}>
                            <i className="ri-download-line align-middle"></i>{" "}
                            Load More
                          </span>
                        ) : (
                          ""
                        )}
                        {!!particularPost.commentList &&
                          particularPost.commentList.length > 0 &&
                          particularPost.commentList.length > 4 ? (
                          <Scrollbars
                            id="scroll-single-post"
                            style={{ width: 550, height: 375 }}
                            autoHide
                          >
                            {particularPost.commentList.map((item, i) => (
                              <div className="post_comment__listing my-3">
                                <div className="post_comment__listing__user">
                                  {!!item.is_individual ? (
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOpenPostProfile(item)
                                      }
                                      onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/member-default.svg")
                                      }
                                      src={
                                        !!item
                                          ? IMAGE_BASE_URL + item.image
                                          : "/assets/images/member-default.svg"
                                      }
                                      alt="user"
                                    />
                                  ) : (
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOpenPostProfile(item)
                                      }
                                      onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/business_logo.svg")
                                      }
                                      src={
                                        !!item
                                          ? IMAGE_BASE_URL + item.image
                                          : "/assets/images/business_logo.svg"
                                      }
                                      alt="logo"
                                    />
                                  )}
                                </div>
                                <div className="post_comment__listing__review">
                                  <div className="comment-bg">
                                    <p className="mb-1">{item.comment}</p>
                                  </div>
                                  <div className="view-reply">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        handleOpenReplyInput(e, item)
                                      }
                                    >
                                      <i className="ri-reply-line" /> Reply
                                    </span>
                                    {!!item.reply ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleOpenNestedReply(item, i)
                                        }
                                        className="open-reply"
                                      >
                                        <i className="ri-eye-line" />
                                        {!!item.showHide ? "Hide" : "View"}{" "}
                                        {item.reply} Reply
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div
                                    style={{ display: "none" }}
                                    id={`cmt-${item.post_id}-nested-${item.comment_id}`}
                                  >
                                    {!!item.subComment &&
                                      !!item.subComment.length > 0 &&
                                      item.replyCommentPage * 3 <
                                      item.actualReplyComment ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        className="d-block text-center"
                                        onClick={() =>
                                          handleReplyCommentPaginate(item, i)
                                        }
                                      >
                                        View{" "}
                                        {item.reply - item.subComment.length}{" "}
                                        pervious replies
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {!!item.subComment &&
                                      item.subComment.map((subComment) => {
                                        return (
                                          <div className="post_comment__listing post__comment__nested ">
                                            <div className="post_comment__listing__user">
                                              {!!subComment.is_individual ? (
                                                <img
                                                  onError={(ev) =>
                                                  (ev.target.src =
                                                    "/assets/images/member-default.svg")
                                                  }
                                                  src={
                                                    !!subComment
                                                      ? IMAGE_BASE_URL +
                                                      subComment.image
                                                      : "/assets/images/member-default.svg"
                                                  }
                                                  alt="user"
                                                />
                                              ) : (
                                                <img
                                                  onError={(ev) =>
                                                  (ev.target.src =
                                                    "/assets/images/business_logo.svg")
                                                  }
                                                  src={
                                                    !!subComment
                                                      ? IMAGE_BASE_URL +
                                                      subComment.image
                                                      : "/assets/images/business_logo.svg"
                                                  }
                                                  alt="logo"
                                                />
                                              )}
                                            </div>
                                            <div className="post_comment__listing__commentbox">
                                              <div className="comment-bg">
                                                <p>{subComment.comment}</p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                  <div
                                    style={{ display: "none" }}
                                    id={`cmt-${item.post_id}-input-${item.comment_id}`}
                                    className="post_comment__listing post__comment__nested"
                                  >
                                    <div className="post_comment__listing__user">
                                      {!!profileData.is_individual ? (
                                        <img
                                          onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/member-default.svg")
                                          }
                                          src={
                                            !!profileData
                                              ? IMAGE_BASE_URL +
                                              profileData.profile_image
                                              : "/assets/images/member-default.svg"
                                          }
                                          alt="user"
                                        />
                                      ) : (
                                        <img
                                          onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/business_logo.svg")
                                          }
                                          src={
                                            !!profileData
                                              ? IMAGE_BASE_URL +
                                              profileData.business_image
                                              : "/assets/images/business_logo.svg"
                                          }
                                          alt="logo"
                                        />
                                      )}{" "}
                                    </div>
                                    <div className="post_comment__listing__commentbox">
                                      <form
                                        onSubmit={(e) =>
                                          handleCommentReplySumbit(e, item, i)
                                        }
                                        autoComplete="off"
                                      >
                                        <Input
                                          type="text"
                                          name="replyComment"
                                          value={item.replyComment}
                                          onChange={(e) =>
                                            handleChangeReplyInput(
                                              e,
                                              item.comment_id
                                            )
                                          }
                                          className="form-control"
                                          placeholder="Write a comment"
                                        />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Scrollbars>
                        ) : (
                          !!particularPost.commentList &&
                          particularPost.commentList.length > 0 &&
                          particularPost.commentList.map((item, i) => (
                            <div className="post_comment__listing my-3">
                              <div className="post_comment__listing__user">
                                {!!item.is_individual ? (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOpenPostProfile(item)}
                                    onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/member-default.svg")
                                    }
                                    src={
                                      !!item
                                        ? IMAGE_BASE_URL + item.image
                                        : "/assets/images/member-default.svg"
                                    }
                                    alt="user"
                                  />
                                ) : (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOpenPostProfile(item)}
                                    onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/business_logo.svg")
                                    }
                                    src={
                                      !!item
                                        ? IMAGE_BASE_URL + item.image
                                        : "/assets/images/business_logo.svg"
                                    }
                                    alt="logo"
                                  />
                                )}
                              </div>
                              <div className="post_comment__listing__review">
                                <div className="comment-bg">
                                  <p className="mb-1">{item.comment}</p>
                                </div>
                                <div className="view-reply">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      handleOpenReplyInput(e, item)
                                    }
                                  >
                                    <i className="ri-reply-line" /> Reply
                                  </span>
                                  {!!item.reply ? (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOpenNestedReply(item, i)
                                      }
                                      className="open-reply"
                                    >
                                      <i className="ri-eye-line" />
                                      {!!item.showHide ? "Hide" : "View"}{" "}
                                      {item.reply} reply
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div
                                  style={{ display: "none" }}
                                  id={`cmt-${item.post_id}-nested-${item.comment_id}`}
                                >
                                  {!!item.subComment &&
                                    !!item.subComment.length > 0 &&
                                    item.replyCommentPage * 3 <
                                    item.actualReplyComment ? (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="d-block text-center"
                                      onClick={() =>
                                        handleReplyCommentPaginate(item, i)
                                      }
                                    >
                                      View {item.reply - item.subComment.length}{" "}
                                      pervious replies
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {!!item.subComment &&
                                    item.subComment.map((subComment) => {
                                      return (
                                        <div className="post_comment__listing post__comment__nested ">
                                          <div className="post_comment__listing__user">
                                            {!!subComment.is_individual ? (
                                              <img
                                                onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/member-default.svg")
                                                }
                                                src={
                                                  !!subComment
                                                    ? IMAGE_BASE_URL +
                                                    subComment.image
                                                    : "/assets/images/member-default.svg"
                                                }
                                                alt="user"
                                              />
                                            ) : (
                                              <img
                                                onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/business_logo.svg")
                                                }
                                                src={
                                                  !!subComment
                                                    ? IMAGE_BASE_URL +
                                                    subComment.image
                                                    : "/assets/images/business_logo.svg"
                                                }
                                                alt="logo"
                                              />
                                            )}
                                          </div>
                                          <div className="post_comment__listing__commentbox">
                                            <div className="comment-bg">
                                              <p>{subComment.comment}</p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                                <div
                                  style={{ display: "none" }}
                                  id={`cmt-${item.post_id}-input-${item.comment_id}`}
                                  className="post_comment__listing post__comment__nested"
                                >
                                  <div className="post_comment__listing__user">
                                    {!!profileData.is_individual ? (
                                      <img
                                        onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/member-default.svg")
                                        }
                                        src={
                                          !!profileData
                                            ? IMAGE_BASE_URL +
                                            profileData.profile_image
                                            : "/assets/images/member-default.svg"
                                        }
                                        alt="user"
                                      />
                                    ) : (
                                      <img
                                        onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/business_logo.svg")
                                        }
                                        src={
                                          !!profileData
                                            ? IMAGE_BASE_URL +
                                            profileData.business_image
                                            : "/assets/images/business_logo.svg"
                                        }
                                        alt="logo"
                                      />
                                    )}{" "}
                                  </div>
                                  <div className="post_comment__listing__commentbox">
                                    <form
                                      onSubmit={(e) =>
                                        handleCommentReplySumbit(e, item, i)
                                      }
                                      autoComplete="off"
                                    >
                                      <Input
                                        type="text"
                                        name="replyComment"
                                        value={item.replyComment}
                                        onChange={(e) =>
                                          handleChangeReplyInput(
                                            e,
                                            item.comment_id
                                          )
                                        }
                                        className="form-control"
                                        placeholder="Write a comment"
                                      />
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>

                      <div className="post__commtent__user">
                        <div className="feeds-post__header d-flex flex-wrap align-items-center">
                          <div className="feeds-post__user">
                            {!!profileData.is_individual ? (
                              <img
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/member-default.svg")
                                }
                                src={
                                  !!profileData
                                    ? IMAGE_BASE_URL + profileData.profile_image
                                    : "/assets/images/member-default.svg"
                                }
                                alt="user"
                              />
                            ) : (
                              <img
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/business_logo.svg")
                                }
                                src={
                                  !!profileData
                                    ? IMAGE_BASE_URL +
                                    profileData.business_image
                                    : "/assets/images/business_logo.svg"
                                }
                                alt="logo"
                              />
                            )}
                          </div>
                          <form
                            onSubmit={(e) => handleSumbitParticularComment(e)}
                            className="comment-form"
                            autoComplete="off"
                          >
                            <Input
                              value={particularPost.comment}
                              onChange={(e) => handleChangeParticularComment(e)}
                              type="text"
                              name="comment"
                              className="form-control"
                              placeholder="Write a comment"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-3" />
          </div>
        </div>
      </section>
      <PostModel
        closePostModels={closePostModels}
        openModel={openModel}
        postState={postState}
        openViewModel={openViewModel}
        closeViewModel={closeViewModel}
        handleCloseShareModel={handleCloseShareModel}
        openShareModel={openShareModel}
        profileData={profileData}
      />
    </>
  );
};
export default Post;
