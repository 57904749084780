import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { MEDIA_BASE_URL } from "../../library/urls";

export default function MediaPage({
  getShareMediaData,
  setMediaPage,
  mediaPage,
  totalCounts,
}) {
  
  // states//
  const [scrollNext, setScrollNext] = useState(false);

  // scroll function
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e;
    if (
      scrollHeight - (Math.floor(scrollTop) + clientHeight) ===0 &&
      totalCounts !== getShareMediaData.length &&
      scrollNext === false
    ) {
      setScrollNext(true);
    }
  };

  // use for setMedia page and scroll next state -- >//
  useEffect(() => {

    if (!!scrollNext) {
      setMediaPage(mediaPage + 1);
      setScrollNext(false);
    }
  }, [scrollNext]);


  return (
    <div>
      <Scrollbars onScrollFrame={handleScroll} style={{ height: 380 }}>
        {getShareMediaData &&
          getShareMediaData.map((item, index) => (
            <div
              key={index}
              style={{
                maxWidth: 310,
                padding: "10px",
                background: "rgba(0,168,243,0.2)",
                borderRadius: "8px",
                marginTop: "10px",
              }}
            >
              {item.media &&
                item.media.match(
                  "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
                ) && (
                  <div style={{ width: 100 }}>
                    <a style={{ maxWidth: 100 }} href={item.media}>
                      {item.media}{" "}
                    </a>
                  </div>
                )}

              {!!item.media && (
                <>
                  {(item.media.match(".mp4") ||
                    item.media.match(".web") ||
                    item.media.match(".mov")) && (
                    <div>
                      <video
                        controls
                        style={{
                          width: "100%",
                          borderRadius: "8px",
                        }}
                        src={MEDIA_BASE_URL + item.media}
                      />
                    </div>
                  )}
                </>
              )}

              {!!item.media && (
                <>
                  {(item.media.match(".png") ||
                    item.media.match(".jpg") ||
                    item.media.match(".jpeg")) && (
                    <img
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/member-default.svg")
                      }
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                      }}
                      src={MEDIA_BASE_URL + item.media}
                    />
                  )}
                </>
              )}

              {item.media && (
                <>
                  {(item.media.match(".docx") ||
                    item.media.match(".doc") ||
                    item.media.match(".pdf") ||
                    item.media.match(".pptx") ||
                    item.media.match(".ppt") ||
                    item.media.match(".csv") ||
                    item.media.match(".xls")) && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <i
                        style={{ fontSize: "2.5rem" }}
                        className={
                          item.media.match("doc") || item.media.match("docx")
                            ? "ri-file-word-2-line"
                            : item.media.match("ppt") ||
                              item.media.match("pptx")
                            ? "ri-file-ppt-2-line"
                            : item.media.match("pdf")
                            ? "ri-file-pdf-line"
                            : item.media.match("csv") ||
                              item.media.match("xlsx") ||
                              item.media.match("xls")
                            ? "ri-file-excel-2-line"
                            : ""
                        }
                      ></i>
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.media}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
      </Scrollbars>
    </div>
  );
}
