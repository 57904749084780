import React, { useEffect, useState } from "react";
import IndividualOverView from "../IndividualSeller/IndividualOverView";
import SkillAndExpertise from "../IndividualSeller/SkillAndExpertise";
import PriceAndTime from "../IndividualSeller/PriceAndTime";
import BusinessOverView from "../BusinessSeller/BusinessOverView";
import BusinessPriceAndTime from "../BusinessSeller/BusinessPriceAndTime";
import BusinessSkillAndExpertise from "../BusinessSeller/BusinessSkillAndExpertise";
import { getCookie } from "../../library/utilities/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  expertiseListApi,
  fetchServiceListApi,
  getAllSellerDataApi,
  industryListApi,
  languageListApi,
  skilsListApi,
} from "../../library/api/AccountApiService";

const SellerSteps = () => {
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const [isEdit, setIsEdit] = useState(false);
  const [getAllSellerData, setgetAllSellerData] = useState([]);
  const check_individual = getCookie("individual");
  const AccountStateData = useSelector((state) => state.AccountReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    var first_tab = $("#pills-tab > li:eq(0)").width();
    $(".progess-bar__loader").css("width", first_tab);
    $("#pills-tab > li:eq(0)").on("click", function (event) {
      var current_Tabs = $(this).width();
      $(".progess-bar__loader").css("width", current_Tabs + "px");
    });

    $("#pills-tab > li:eq(1)").on("click", function (event) {
      var current_Tabs = $(this).width();
      var totalWidthsecond = first_tab + current_Tabs;
      $(".progess-bar__loader").css("width", totalWidthsecond + "px");
    });

    $("#pills-tab > li:eq(2)").on("click", function (event) {
      var current_Tabs = $(this).width();
      var prevTab = $(this).prev().width();
      var totalWidththird = first_tab + current_Tabs + prevTab;
      $(".progess-bar__loader").css("width", totalWidththird + "px");
    });

    $(window).on("resize", function () {
      var first_tab = $("#pills-tab > li:eq(0)").width();
      $(".progess-bar__loader").css("width", first_tab);
      $("#pills-tab > li:eq(0)").on("click", function (event) {
        var current_Tabs = $(this).width();

        $(".progess-bar__loader").css("width", current_Tabs + "px");
      });

      $("#pills-tab > li:eq(1)").on("click", function (event) {
        var current_Tabs = $(this).width();
        var totalWidthsecond = first_tab + current_Tabs;
        $(".progess-bar__loader").css("width", totalWidthsecond + "px");
      });

      $("#pills-tab > li:eq(2)").on("click", function (event) {
        var current_Tabs = $(this).width();
        var prevTab = $(this).prev().width();
        var totalWidththird = first_tab + current_Tabs + prevTab;
        $(".progess-bar__loader").css("width", totalWidththird + "px");
      });
    });

    $(".switch input").on("change", function () {
      if ($(this).is(":checked")) {
        $(this)
          .parents(".timing-block")
          .find(".add-timer")
          .removeClass("d-none")
          .addClass("d-flex");
      } else {
        $(this)
          .parents(".timing-block")
          .find(".add-timer")
          .addClass("d-none")
          .removeClass("d-flex");
      }
    });

    jQuery("body").on("click", ".next-btn", function () {
      var next = jQuery(".seller-tabs  .active").parent("li").next("li");
      next.find("a").trigger("click");
    });
  }, []);

  const handleEdit = () => {
    setIsEdit(true);
  };
  useEffect(() => {
    if (!!getAllSellerData.work_name?.length) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [getAllSellerData?.work_name]);

  useEffect(async () => {
    setgetAllSellerData(
      AccountStateData?.getAllSellerDataApi?.getAllSellerDatas
    );
  }, [AccountStateData]);

  useEffect(() => {
    const data = {
      page: 1,
    };
    dispatch(fetchServiceListApi(data));
    if (getCookie("individual") == "true") {
      dispatch(skilsListApi());
    } else {
      dispatch(expertiseListApi());
      dispatch(industryListApi());
    }
    dispatch(languageListApi());

    dispatch(getAllSellerDataApi());
  }, []);



  return (
    <>
      <section className="spacer">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-sm-12 mx-auto">
              {getAllSellerData?.work_name ? (
                <div className="d-flex justify-content-end mb-2 ">
                  <button
                    onClick={handleEdit}
                    className="btn btn-primary btn-sm mb-2 btn_fix"
                  >
                    <i className="ri-pencil-line align-middle"></i> Edit Profile
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="bg-white p-3 br-20 custom-shadow">
                <div className="progess-bar position-relative">
                  <div className="progess-bar__loader"></div>
                  <ul
                    className="nav mb-3 nav-pills seller-tabs"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        <img
                          src="/assets/images/information.png"
                          alt="Overview"
                        />{" "}
                        Overview
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link disabled"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        <img src="/assets/images/key-skills.png" alt="Skills" />{" "}
                        Skills &amp; Expertise
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link disabled"
                        id="pills-contact-tab"
                        data-toggle="pill"
                        href="#pills-contact"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        <img src="/assets/images/schedule.png" alt="Price" />{" "}
                        Price &amp; Time
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  {check_individual == "true" ? (
                    <>
                      <IndividualOverView
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                        profileData={profileData}
                      />
                      <SkillAndExpertise
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        profileData={profileData}
                      />
                      <PriceAndTime isEdit={isEdit} setIsEdi={setIsEdit} />
                    </>
                  ) : (
                    <>
                      <BusinessOverView
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        profileData={profileData}
                      />
                      <BusinessPriceAndTime
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                      />
                      <BusinessSkillAndExpertise
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        profileData={profileData}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SellerSteps;
