import {
  CHANGE_SIGNUP_INDIVIDUAL_INPUT,
  CHANGE_SIGNUP_BUSSINESS_INPUT,
  CHECK_SIGNUP_INDIVIDUAL_INPUT,
  CHECK_ISFINAL_INDIVIDUAL_INPUT,
  CLEAR_SIGNUP_PAGE,
  CLEAR_SIGNUP_API_RESPONSE,
} from "./SignupConstants";

//this function is used to change signup individual inputs
const changeLoginIndividualInput = (newState) => {
  return { type: CHANGE_SIGNUP_INDIVIDUAL_INPUT, newState };
};
//this function is used to change signup business inputs
const changeLoginBusinessInput = (newState) => {
  return { type: CHANGE_SIGNUP_BUSSINESS_INPUT, newState };
};
//this function is used to change user type like : indiviual type or business
const changeIsIndividualInput = (newState) => {
  return { type: CHECK_SIGNUP_INDIVIDUAL_INPUT, newState };
};
//this function is used to change final user type like : indiviual type or business.
const changeIsFinalIndividualInput = (newState) => {
  return { type: CHECK_ISFINAL_INDIVIDUAL_INPUT, newState };
};
//this function is used to clear signup page data
const clearSignupPage = (newState) => {
  return { type: CLEAR_SIGNUP_PAGE, newState };
};
//this function is used to clear signup api response
const clearSignupApiResponse = (newState) => {
  return { type: CLEAR_SIGNUP_API_RESPONSE, newState };
};

export {
  changeLoginIndividualInput,
  changeLoginBusinessInput,
  changeIsIndividualInput,
  changeIsFinalIndividualInput,
  clearSignupPage,
  clearSignupApiResponse,
};
