import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/react";
import { useToasts } from "react-toast-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import { loginDataApi } from "../../library/api/LoginApiService";
import { Input } from "../../library/common/components";

import {
  addDocumentTitle,
  addValidation,
  clearCookies,
  setCookie,
} from "../../library/utilities/functions";

import {
  changeLoginInput,
  clearLoginApiResponse,
  clearLoginInput,
} from "./LoginActions";
import {
  loginFormValidation,
  passwordValidation,
  onChangeLoginValidation,
} from "./validations";
import { Link, useHistory } from "react-router-dom";
import { changeIsAuth } from "../../library/common/actions/AuthActions";
import { SOCKET, establishSocket } from "../../library/urls";
import Footer from "../../library/common/components/LandingPage/HomeSection/Footer";

const override = css`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  right: 47%;
`;

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  const { addToast } = useToasts();
  const nameEl = useRef(null);
  const nameElvalidation = useRef(null);
  const passwordElValidation = useRef(null);
  const loginState = useSelector((state) => state.LoginReducer);
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const {
    loginInput: { name, password },
  } = loginState;
  const {
    loginApiResponse: {
      loginApiLoading,
      loginDataResponse,
      loginApiStatus,
      loginMessage,
    },
  } = loginState;

  // CALL WHEN USER  LOGIN
  useEffect(async () => {
    addValidation;
    addValidation;

    if (!!loginApiStatus && loginApiStatus == "200") {
      let tokenSplit = loginDataResponse.token.split("|");
      setCookie("token_id", tokenSplit[1], 1);
      setCookie("profile_data", JSON.stringify(loginDataResponse), 1);
      setCookie("firstTimeLogin", loginDataResponse.first_time_login, 1);
      setCookie("individual", loginDataResponse.is_individual, 1);
      window.localStorage.setItem("isSession", true);

      // WHEN USER LOGIN CHANGE ITS AUTH STATE
      dispatch(changeIsAuth({ is_auth: true }));
      establishSocket();

      // NAVIGATE TO HOME PAGE

      addToast("Login Successful", {
        appearance: "success",
        autoDismiss: true,
      });
    }

    // IF STATUS IS FALSE
    if (loginApiStatus == "400") {
      addToast(loginMessage, {
        appearance: "error",
        autoDismiss: true,
      });

      clearCookies();
      dispatch(clearLoginApiResponse());
    }
  }, [loginApiStatus]);

  useEffect(() => {
    addDocumentTitle("Login");
    nameEl.current.focus();
    return () => {
      dispatch(clearLoginInput());
      dispatch(clearLoginApiResponse());
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //this function is used when change input fileds.
  const handleChange = (e, data) => {
    const target = e.target;
    if (data === "text") {
      if (name.length > 0) {
        let validation = {
          is_valid_name: { status: false, validation: nameElvalidation },
        };
        validation = onChangeLoginValidation(validation, loginState.loginInput);
        addValidation(validation);
      }
    } else if (data === "password") {
      let validation = {
        is_valid_password: { status: false, validation: passwordElValidation },
      };
      validation = passwordValidation(validation, loginState.loginInput);

      addValidation(validation);
    }
    dispatch(changeLoginInput({ [target.name]: target.value }));
  };

  // this function is used to submit login details according to validation and then hit api
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!!name.length && !!password.length) {
      let validation = {
        is_valid_name: { status: false, validation: nameElvalidation },
        is_valid_password: { status: false, validation: passwordElValidation },
      };
      validation = loginFormValidation(validation, loginState.loginInput);
      validation = passwordValidation(validation, loginState.loginInput);
      addValidation(validation);

      passwordElValidation.current.style.display = "none";
      let { is_valid_name, is_valid_password } = validation;
      if (is_valid_name.status && is_valid_password.status) {
        setIsLoginClicked(true);
        let bodyParameters = {
          email: name,
          password: password,
        };

        if (!loginApiLoading) {
          const data = new FormData();
          // Adding files to the formdata
          data.append("seller_status ", 1);
          dispatch(loginDataApi(bodyParameters)).then(() => {
            setTimeout(() => setIsLoginClicked(false), 2000);
          });
          addDocumentTitle("");
        }
      }
    } else {
      setError(true);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window.location.search == "?success") {
      addToast("Email is verified", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        let url = window?.location.href;
        window.location.replace(url.split("?")[0]);
      }, 2000);
    } else if (window.location.search == "?error") {
      addToast("Email is not verified", {
        appearance: "error",
        autoDismiss: true,
      });
      setTimeout(() => {
        let url = window?.location.href;
        window.location.replace(url.split("?")[0]);
      }, 2000);
    } else if (window.location.search == "?sessionexpired") {
      addToast("Your Email Verification Link Has Expired", {
        appearance: "error",
        autoDismiss: true,
      });
      setTimeout(() => {
        let url = window?.location.href;
        window.location.replace(url.split("?")[0]);
      }, 2000);
    } else if (window.location.search == "?resetpwd-success") {
      addToast("Password changed successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        let url = window?.location.href;
        window.location.replace(url.split("?")[0]);
      }, 2000);
    } else if (window.location.search == "?resetpwd-error") {
      addToast("Unable to reset please try again later", {
        appearance: "error",
        autoDismiss: true,
      });
      setTimeout(() => {
        let url = window?.location.href;
        window.location.replace(url.split("?")[0]);
      }, 2000);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (name.length > 0) {
      let validation = {
        is_valid_name: { status: false, validation: nameElvalidation },
      };
      validation = onChangeLoginValidation(validation, loginState.loginInput);
      addValidation(validation);
    }
    if (!!password.length) {
      let validation = {
        is_valid_password: { status: false, validation: passwordElValidation },
      };
      validation = passwordValidation(validation, loginState.loginInput);

      addValidation(validation);
    }
  }, [name, password]);

  return (
    <>
      <div className="container">
        <div className="row align-items-center my-3 my-lg-4 pb-5">
          <div className="col-md-6 col-lg-6 mb-4 mb-md-0 d-none d-md-block">
            <div className="login-svg" >
              <img style={{
    backgroundImage: "url('/assets/images/Login-Page-Graphic-Image.png')",
  }}  src="/assets/images/login-animate.gif" alt="login" />
            </div>
          </div>

          <div className="col-md-6 col-lg-5 ml-lg-auto">
            <div className="form mt-4 bg-white">
              <div className="form__header d-flex align-items-center mb-4 justify-content-center flex-wrap">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon"
                />
                <h1 className="font-weight-bold mb-0 h3 w-100 text-center">
                  Log In to PoolsMagnic
                </h1>
              </div>
              <form onSubmit={handleSubmit}>
                <fieldset disabled={!!loginApiLoading}>
                  <div className="form-group">
                    <label>Email ID</label>
                    <Input
                      type="text"
                      name="name"
                      ref={nameEl}
                      className="form-control"
                      value={name}
                      onChange={(e) => handleChange(e, "text")}
                      placeholder="Email ID"
                    />
                    {!name.length && error && (
                      <p className="error-message">Please enter email id.</p>
                    )}
                    {!!name.length && (
                      <p
                        style={{ display: "none" }}
                        ref={nameElvalidation}
                        className="error-message"
                      >
                        Please enter valid email id.
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="position-relative password-field">
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        onChange={(e) => handleChange(e, "password")}
                        placeholder="Password"
                      />

                      {!!password && (
                        <i
                          className={
                            showPassword ? "ri-eye-line" : "ri-eye-off-line"
                          }
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </div>
                    {error && !password.length && (
                      <p className="error-message">Please enter password. </p>
                    )}
                    <p
                      style={{ display: "none" }}
                      ref={passwordElValidation}
                      className="error-message"
                    >
                      Please enter password.{" "}
                    </p>
                  </div>

                  <div className="form-group">
                    <div className="row align-items-center">
                      <div className="col-sm-5 col-md-12 col-lg-5 pr-lg-0">
                        <label className="theme-checkbox mb-0">
                          <input type="checkbox" name="checkbox" />
                          <span>Remember Me</span>
                        </label>
                      </div>

                      <div className="col-sm-7 col-md-12 col-lg-7 px-lg-0">
                        <Link
                          to="/forget-password"
                          className="link-color-text text-sm-right text-md-left text-lg-right d-block mt-3 mt-sm-0 mt-md-3 mt-lg-0"
                        >
                          Forgot Your Sign In Info?{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center mt-4 position-relative">
                    <Input
                      type="submit"
                      value="Log In"
                      className="btn btn-blue btn-primary w-75 br-50"
                      disabled={isLoginClicked}
                    />
                    <ClipLoader
                      color={"#fff"}
                      loading={!!loginApiLoading ? true : false}
                      css={override}
                    />
                  </div>
                  <div className="form-group text-center form__footer d-flex justify-content-center">
                    <p className="mb-0">Sign Up For PoolsMagnic. </p>
                    <Link to="/signup" className="link-color-purple pl-2">
                      Sign Up
                    </Link>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Login;
