import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { Scrollbars } from "react-custom-scrollbars";
import {
  changeParticularReactListDetail,
  changeParticularShareListDetil,
  changeParticularViewDetail,
  clearFileReactListResponse,
  clearFileViewListResponse,
  clearParticularPostShareListResponse,
  clearPostReactListResponse,
  clearPostViewListResponse,
  clearShareFileListResponse,
} from "./PostAction";
import { IMAGE_BASE_URL } from "../../library/urls";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const override = css`
  text-align: center;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 40%;
  right: 50%;
`;

const PostModel = ({
  openModel,
  closePostModels,
  postState,
  openViewModel,
  closeViewModel,
  handleCloseShareModel,
  openShareModel,
  profileData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    reactionListDetail: {
      reactListPage,
      singleReactListpage,
      reactList,
      totalReact,
    },
  } = postState;
  const {
    postReactListApi: { reactListStatus, reactListLoading },
  } = postState;
  const {
    fileReactListApi: { singleReactListStatus, singleReactListLoading },
  } = postState;
  const {
    postViewListDetail: {
      viewList,
      totalViews,
      viewListPage,
      singleViewListpage,
    },
  } = postState;
  const {
    postViewListApi: { viewListLoading, viewListStatus },
  } = postState;
  const {
    singlePostViewListApi: { singleViewListLoading },
  } = postState;
  const {
    postShareListDetail: {
      shareListPage,
      shareList,
      totalShare,
      singleShareListpage,
    },
  } = postState;
  const {
    feedShareListApi: { feedShareListLoading, feedShareListStatus },
  } = postState;
  const {
    singlefeedShareListApi: { singleShareListLoading },
  } = postState;

  useEffect(() => {
    return () => {
      dispatch(clearParticularPostShareListResponse());
      dispatch(clearShareFileListResponse());
      dispatch(clearFileViewListResponse());
      dispatch(clearPostViewListResponse());
      dispatch(clearFileReactListResponse());
      dispatch(clearPostReactListResponse());
    };
  }, []);
  const handleScroolReact = (response) => {
    if (response.top == 1) {
      if (reactListStatus == 200) {
        dispatch(
          changeParticularReactListDetail({ reactListPage: reactListPage + 1 })
        );
      } else {
        dispatch(
          changeParticularReactListDetail({
            singleReactListpage: singleReactListpage + 1,
          })
        );
      }
    }
  };
  const handleCloseReactListModel = () => {
    closePostModels();
    dispatch(clearFileReactListResponse());
    dispatch(clearPostReactListResponse());
  };
  const handleViewScrool = () => {
    if (response.top == 1) {
      if (viewListStatus == 200) {
        dispatch(
          changeParticularViewDetail({ viewListPage: viewListPage + 1 })
        );
      } else {
        dispatch(
          changeParticularViewDetail({
            singleViewListpage: singleViewListpage + 1,
          })
        );
      }
    }
  };
  const handleViewListClose = () => {
    closeViewModel();
    dispatch(clearFileViewListResponse());
    dispatch(clearPostViewListResponse());
  };
  const handleShareListClose = () => {
    handleCloseShareModel();
    dispatch(clearParticularPostShareListResponse());
    dispatch(clearShareFileListResponse());
  };
  const handleShareScrool = () => {
    if (response.top == 1) {
      if (feedShareListStatus == 200) {
        dispatch(
          changeParticularShareListDetil({ shareListPage: shareListPage + 1 })
        );
      } else {
        dispatch(
          changeParticularShareListDetil({
            singleShareListpage: singleShareListpage + 1,
          })
        );
      }
    }
  };
  const handleOpenUser = (data) => {
    if (profileData.id == data.user_id) {
      history.push(`/account/${profileData?.user_slug}`);
    } else {
      history.push(
        `/search-profile/${data.user_id}`

        // `/search-profile/${data.user_id}?individual=${!!data.is_individual ? true : false
        // }`
      );
    }
  };
  return (
    <>
      <Modal
        show={openModel}
        onHide={closePostModels}
        backdrop="static"
        keyboard={false}
        centered
        className=""
      >
        <div className="modal-header">
          <ul className="nav nav-pills mb-3" id="pills-details" role="tablist">
            {totalReact.length > 0 &&
              totalReact.map((item) => {
                return (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-contact-tab"
                      data-toggle="pill"
                      href="#pills-contact"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      {item.reaction == 1 ? (
                        <img src="/assets/images/300x300.gif" alt="Like" />
                      ) : item.reaction == 2 ? (
                        <img src="/assets/images/love.gif" alt="Love" />
                      ) : item.reaction == 3 ? (
                        <img src="/assets/images/clap.gif" alt="clab" />
                      ) : item.reaction == 4 ? (
                        <img
                          src="/assets/images/celebrate.gif"
                          alt="celebrate"
                        />
                      ) : item.reaction == 5 ? (
                        <img src="/assets/images/thinking.gif" alt="Thinking" />
                      ) : (
                        (item.reaction = 6 ? (
                          <img src="/assets/images/upset.gif" alt="upset" />
                        ) : (
                          ""
                        ))
                      )}
                      {item.count}
                    </a>
                  </li>
                );
              })}
          </ul>
          <button
            onClick={() => handleCloseReactListModel()}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <div className="users-listing">
                <Scrollbars onScrollFrame={handleScroolReact} autoHide>
                  {reactList.length > 0 &&
                    reactList.map((data) => (
                      <div className="users-listing__item align-items-center">
                        <div
                          className="reaction__counter position-relative"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenUser(data)}
                        >
                          {!!data.is_individual ? (
                            <img
                              onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/member-default.svg")
                              }
                              src={
                                !!data.user_image
                                  ? IMAGE_BASE_URL + data.user_image
                                  : "/assets/images/member-default.svg"
                              }
                              alt="user"
                            />
                          ) : (
                            <img
                              onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/business_logo.svg")
                              }
                              src={
                                !!data.user_image
                                  ? IMAGE_BASE_URL + data.user_image
                                  : "/assets/images/business_logo.svg"
                              }
                              alt="user"
                            />
                          )}
                          <div className="reaction-image">
                            {data.reaction == 1 ? (
                              <img src="/assets/images/300x300.gif" alt="Like" />
                            ) : data.reaction == 2 ? (
                              <img src="/assets/images/love.gif" alt="Love" />
                            ) : data.reaction == 3 ? (
                              <img src="/assets/images/clap.gif" alt="clab" />
                            ) : data.reaction == 4 ? (
                              <img
                                src="/assets/images/celebrate.gif"
                                alt="celebrate"
                              />
                            ) : data.reaction == 5 ? (
                              <img
                                src="/assets/images/thinking.gif"
                                alt="Thinking"
                              />
                            ) : (
                              (data.reaction = 6 ? (
                                <img
                                  src="/assets/images/upset.gif"
                                  alt="upset"
                                />
                              ) : (
                                ""
                              ))
                            )}
                          </div>
                        </div>

                        <h6
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenUser(data)}
                          className="text-capitalize mb-0 link-color"
                        >
                          {data.user_name}
                        </h6>
                      </div>
                    ))}
                </Scrollbars>
                {reactList.length <= 0 && (
                  <ClipLoader
                    color={"#212529"}
                    loading={
                      !!reactListLoading || !!singleReactListLoading
                        ? true
                        : false
                    }
                    css={override}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* view MODAL */}

      <Modal
        show={openViewModel}
        onHide={closeViewModel}
        backdrop="static"
        keyboard={false}
        centered
        className=""
      >
        <div className="modal-header">
          <ul className="nav nav-pills mb-3" id="pills-details" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                <img src="/assets/images/eye-fill.svg" alt="like" />
                {totalViews}
              </a>
            </li>
          </ul>
          <button
            onClick={() => handleViewListClose()}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <div className="users-listing">
                <Scrollbars onScrollFrame={handleViewScrool} autoHide>
                  {viewList.length > 0 &&
                    viewList.map((data) => (
                      <div className="users-listing__item">
                        {!!data.is_individual ? (
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenUser(data)}
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                            }
                            src={
                              !!data.user_image
                                ? IMAGE_BASE_URL + data.user_image
                                : "/assets/images/member-default.svg"
                            }
                            alt="user"
                          />
                        ) : (
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenUser(data)}
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/business_logo.svg")
                            }
                            src={
                              !!data.user_image
                                ? IMAGE_BASE_URL + data.user_image
                                : "/assets/images/business_logo.svg"
                            }
                            alt="user"
                          />
                        )}
                        <h6
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenUser(data)}
                          className="text-capitalize mb-0 link-color"
                        >
                          {data.user_name}
                        </h6>
                      </div>
                    ))}
                </Scrollbars>
                {viewList.length <= 0 && (
                  <ClipLoader
                    color={"#212529"}
                    loading={
                      !!viewListLoading || !!singleViewListLoading
                        ? true
                        : false
                    }
                    css={override}
                  />
                )}
              </div>
            </div>
          </div>
          {/* </div>
          </div>
        </div> */}
        </div>
      </Modal>

      {/* share MODAL */}

      <Modal
        show={openShareModel}
        onHide={handleCloseShareModel}
        backdrop="static"
        keyboard={false}
        centered
        className=""
      >
        <div className="modal-header">
          <ul className="nav nav-pills mb-3" id="pills-details" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                <i className="ri-share-fill"></i>
                {totalShare}
              </a>
            </li>
          </ul>
          <button
            type="button"
            onClick={() => handleShareListClose()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <div className="users-listing">
                <Scrollbars onScrollFrame={handleShareScrool} autoHide>
                  {shareList.length > 0 &&
                    shareList.map((data) => (
                      <div className="users-listing__item">
                        {!!data.is_individual ? (
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenUser(data)}
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                            }
                            src={
                              !!data.user_image
                                ? IMAGE_BASE_URL + data.user_image
                                : "/assets/images/member-default.svg"
                            }
                            alt="user"
                          />
                        ) : (
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenUser(data)}
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/business_logo.svg")
                            }
                            src={
                              !!data.user_image
                                ? IMAGE_BASE_URL + data.user_image
                                : "/assets/images/business_logo.svg"
                            }
                            alt="user"
                          />
                        )}
                        <h6
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenUser(data)}
                          className="text-capitalize mb-0"
                        >
                          {data.user_name}
                        </h6>
                      </div>
                    ))}
                </Scrollbars>
                {shareList.length <= 0 && (
                  <ClipLoader
                    color={"#212529"}
                    loading={
                      !!feedShareListLoading || !!singleShareListLoading
                        ? true
                        : false
                    }
                    css={override}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default PostModel;
