import React, { useRef, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { IMAGE_BASE_URL } from "../../library/urls";
import axios from "axios";
import { CREATE_GROUP, SOCKET } from "../../library/urls";

import { getCookie } from "../../library/utilities/functions";
import { friendListApi } from "../../library/api/MyNetworkApiService";
import { useDispatch, useSelector } from "react-redux";
import { clearFriendListApiResponse } from "../MyNetwork/MyNetworkAction";
import Scrollbars from "react-custom-scrollbars";
import {
  allFriendandGroupList,
  changeFriendListPage,
  getSingleGroupApi,
} from "./PoolsChatAction";

const CreateGroupModal = (props) => {

  // redux //
  const poolsChatState = useSelector((state) => state.PoolsChatReducer);
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const { friendList, handleClose, setIsCreateGroup } = props;
  const {
    chatDetails: { currenUserId },
  } = poolsChatState;

  // notification toast//
  const { addToast } = useToasts();

  // refs//
  const closeEl = useRef();
  const closeAddEl = useRef();


  // states//
  const [groupName, setGroupName] = useState("");
  const [groupImageUrl, setGroupImageUrl] = useState(null);
  const [groupImage, setGroupImage] = useState(null);
  const [createdGroupId, setCreatedGroupId] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [addSelectedUserId, setAddSelectedUserId] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showTitleError, setShowTitleError] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [showGroupNameError, setShowGroupNameError] = useState(false);
  const { chatFriendListPage } = poolsChatState;
  const [hideModalOne, setHideModalOne] = useState("");
  const dispatch = useDispatch();


  // browser storage//
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";




  const handleAddUserClose = (e) => {
    setHideModalOne("");

    if (e === "Add") {
      let data = [];
      for (let i = 0; i <= addSelectedUserId.length; i++) {
        friendList
          .filter((user) => user.user_id === Number(addSelectedUserId[i]))
          .map((item) => {
            data.push(item);
          });
      }
      setSelectedUser(data);
      setShowError(false);
      setShowGroupNameError(false);
      document.getElementsByClassName(`newClassCheck`).checked = false;
      $("#modal-example").modal("hide");
    } else {
      setSelectedUser([]);
      setShowError(false);
      setShowGroupNameError(false);
      $("#modal-example").modal("hide");
      setAddSelectedUserId([]);
    }
  };

  // remove user from group
  const handleRemoveUser = (id) => {
    document.getElementById(`create_${id}`).checked = false;

    const updatedUserId = addSelectedUserId.filter(
      (item) => item !== String(id)
    );

    const newList = selectedUser.filter((item) => item.user_id !== id);
    setAddSelectedUserId(updatedUserId);
    setSelectedUser(newList);
  };


  // add member in group //
  const handleGroupMember = (user) => {
    let doc = $(".add-memeber-group").find("input:checked");

    if (doc.length) {
      let membersId = [];
      for (let i = 0; i < doc.length; i++) {
        let userId = doc[i].id.split("_")[1];
        membersId.push(userId);
      }
      setAddSelectedUserId(membersId);
    } else {
      setAddSelectedUserId([]);
    }
  };


  // create group function//
  const handleCreateGroup = () => {
    if (addSelectedUserId.length > 0 && groupName.trim().length > 0) {
      const data = new FormData();
      data.append("title", groupName);
      data.append("group_image", groupImage);
      addSelectedUserId.forEach((tag) => data.append("user_id[]", tag));
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };

      setButtonState(true);
      axios(CREATE_GROUP, config)
        .then((response) => {
          if (response.data.success === true) {
            $("#create-modal").modal("hide");

            addToast("Group created successfully", {
              appearance: "success",
              autoDismiss: true,
              // autoDismissTimeout: 6000,
            });
            setGroupName("");
            setGroupImageUrl(null);

            setButtonState(false);
            dispatch(clearFriendListApiResponse());
            const body = {
              page: 1,
              chat: true,
            };
            dispatch(friendListApi(body));

            setSelectedUser([]);
            setAddSelectedUserId([]);
            setCreatedGroupId(response.data.data.data[0].id);

            SOCKET.emit("manage_group", {
              group_id: response.data.data.data[0].id,
              event: "create",
              title: response.data.data.data[0].title,
            });
          } else {
            addToast(response.data.message, {
              appearance: "error",
              autoDismiss: true,
              // autoDismissTimeout: 6000,
            });
          }
        })

        .catch((err) => {
          addToast("Unable to create a group. Please try again.", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 6000,
          });
        });
    } else {
      if (addSelectedUserId.length === 0) {
        setShowError(true);
      }
      if (groupName.trim().length == 0) {
        setShowGroupNameError(true);
      }
    }
  };



  // listent socket of friend list to add in group //
  useEffect(() => {
    if (createdGroupId !== null) {
      dispatch(getSingleGroupApi(createdGroupId));
      SOCKET.emit("get_friend_and_group_list", {
        u_id: profileData?.id,
        socket_id: SOCKET.id,
      });

      SOCKET.off("get_friend_and_group_list").on("get_friend_and_group_list", (chatList) => {
        dispatch(allFriendandGroupList(chatList));
      });
    }

    setTimeout(() => {
      SOCKET.emit("get_all_unread_group_messages", {
        user_id: profileData.id,
        grp_id: currenUserId,
        toMe: true,
      });

      SOCKET.emit("get_all_unread_group_messages_for_login_user", {
        user_id: profileData.id,
        grp_id: currenUserId,
        toMe: true,
      });
    }, 2000);
    setCreatedGroupId(null);
  }, [createdGroupId]);

  // change image of grpup file change //
  const handleFileChange = (e) => {
    var data = e.target.files[0];
    if (!!data) {
      const fileName = data.name.split(".");
      const imageFormat = fileName[fileName.length - 1];
      if (e.target.files[0]) {
        if (
          imageFormat === "png" ||
          imageFormat === "jpg" ||
          imageFormat === "jpeg" ||
          imageFormat === "SVG" ||
          imageFormat === "svg" ||
          imageFormat === "PNG" ||
          imageFormat === "JPG" ||
          imageFormat === "JPEG"
        ) {
          setGroupImageUrl(URL.createObjectURL(e.target.files[0]));
          setGroupImage(e.target.files[0]);
        } else {
          addToast("Only .png, .jpg, .jpeg image formats supported.", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 6000,
          });
        }
      }
    }
  };
  
  // scroll list of group members//
  const handleScroolChat = (response) => {
    if (response.top == 1) {
      dispatch(
        changeFriendListPage({ chatFriendListPage: chatFriendListPage + 1 })
      );
    }
  };

  const handleCloseModalOne = (data) => {
    setHideModalOne(data);
  };


  // function to send group name//
  const handleGroupName = (e) => {
    var regex = /^[a-zA-Z \d+]*$/;

    if (regex.test(e.target.value)) {
      setGroupName(e.target.value), setShowGroupNameError(false);
    }
  };

  return (
    <>
      <div
        className={`${hideModalOne === "add-user" ? "show-add-member" : ""}`}
      >
        <div
          className="modal fade"
          id="create-modal"
          data-backdrop="static"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img
                    src="/assets/images/icon.svg"
                    alt="icon"
                    className="site-icon"
                  />
                  Create a new Group Chat
                </h5>
                <button
                  type="button"
                  onClick={props.handleClose}
                  ref={closeEl}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-blk d-flex align-items-center">
                  <form
                    className="d-flex align-items-center w-100"
                    autoComplete="off"
                  >
                    <div className="modal-blk-logo position-relative d-inline-block">
                      <img
                        src={
                          groupImageUrl !== null
                            ? groupImageUrl
                            : "/assets/images/user.png"
                        }
                        alt="user"
                        className="user-profile"
                      />
                      <div className="modal-edit-profile d-flex justify-content-center align-items-center">
                        <label
                          className="d-flex justify-content-center align-items-center mb-0"
                          htmlFor="myFile"
                        >
                          <i className="ri-pencil-line text-white"></i>
                        </label>
                        <input
                          className="plus-btn"
                          type="file"
                          id="myFile"
                          onChange={handleFileChange}
                          name="filename"
                        />
                      </div>
                    </div>
                    <div className="modal-blk-content w-75">
                      <input
                        type="text"
                        name="userMessage"
                        placeholder="Group Chat Name"
                        onChange={(e) => {
                          handleGroupName(e);
                        }}
                        className="form-control"
                        value={groupName}
                      ></input>
                      {showGroupNameError && (
                        <p className="text-danger small mb-0">
                          Please, add group title
                        </p>
                      )}
                    </div>
                  </form>
                </div>
                <div
                  className="modal-box d-inline-flex mt-4 align-items-center"
                  data-toggle="modal"
                  data-target="#modal-example"
                  onClick={() => handleCloseModalOne("add-user")}
                >
                  <div className="modal-box-logo">
                    <i className="ri-user-add-fill text-white gdd"></i>
                  </div>
                  <div className="modal-box-content d-flex align-items-center flex-column">
                    <p className="mb-0">Add Group Chat Members</p>
                    {showError && (
                      <p className="text-danger small mb-0">
                        Please, add group chat members
                      </p>
                    )}
                  </div>
                </div>

                <div className="create-footer-group-blk mt-5 d-flex">
                  {!!selectedUser.length &&
                    selectedUser.map((item, index) => (
                      <div className="create-footer-group" key={index}>
                        <img
                          src={
                            !!item.user_profile && item.user_profile !== " "
                              ? IMAGE_BASE_URL + item.user_profile
                              : "/assets/images/member-default.svg"
                          }
                          onError={(ev) =>
                          (ev.target.src =
                            "/assets/images/member-default.svg")
                          }
                          alt="user"
                          className={
                            !!item.is_individual
                              ? "selected-user-profile"
                              : "selected-business-user"
                          }
                        />
                        <i
                          className="ri-close-line position-relative text-white"
                          onClick={() => handleRemoveUser(item.user_id)}
                        ></i>
                      </div>
                    ))}
                </div>
              </div>

              <div className="modal-footer pt-4">
                <div className="row w-100">
                  <div className="col-md-12 px-0"></div>
                  <div className="col-md-12 text-right btn-spacer">
                    <a
                      href="javascript:;"
                      className={
                        !!buttonState
                          ? "btn btn-primary btn-sm btn-full disabled"
                          : "btn btn-primary btn-sm btn-full"
                      }
                      onClick={handleCreateGroup}
                    >
                      Create
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* second modal */}

      <div
        className="modal fade upper-modal"
        id="modal-example"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog add-group-member-modal modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon outer"
                />
                Add Group Chat Members
              </h5>
              <button
                type="button"
                onClick={handleAddUserClose}
                ref={closeAddEl}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body mt-4">
              <form method="post">
                <Scrollbars
                  autoHide
                  style={{ height: 400 }}
                  onScrollFrame={handleScroolChat}
                >
                  {friendList.map(
                    (item, index) =>
                      item.user_id != 176 &&
                      !item.user_deleted && (
                        <div className="add-member-block mt-1" key={index}>
                          <div
                            className="add-memeber-group"
                            onClick={() => handleGroupMember(item)}
                          >
                            <input
                              type="checkbox"
                              id={`create_${item.user_id}`}
                              className="newClassCheck"
                            />
                            <label
                              className="d-flex align-items-center"
                              htmlFor={`create_${item.user_id}`}
                            >
                              <img
                                src={
                                  !!item.user_profile &&
                                    item.user_profile !== " "
                                    ? IMAGE_BASE_URL + item.user_profile
                                    : "/assets/images/member-default.svg"
                                }
                                onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/member-default.svg")
                                }
                                alt="user"
                                className={`${!!item.is_individual
                                  ? "user-profile"
                                  : "business-user"
                                  }`}
                              />
                              <p className="mb-0 ml-3">
                                {item.is_individual
                                  ? item.user_name
                                  : item.business_name}
                              </p>{" "}
                              <i className="ri-checkbox-circle-fill"></i>
                            </label>
                          </div>
                        </div>
                      )
                  )}
                </Scrollbars>
              </form>
            </div>

            <div className="modal-footer border-0 pt-4">
              <div className="row w-100">
                <div className="col-md-12 px-0"></div>
                <div
                  className="col-md-12 text-right btn-spacer"
                  onClick={() => handleAddUserClose("Add")}
                >
                  <a
                    href="javascript:;"
                    className="btn btn-primary btn-sm btn-full"
                  >
                    {" "}
                    Add
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateGroupModal;
