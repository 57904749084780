import React from "react";
import { usePosition } from "use-position";
import { useEffect } from "react";
import "../Home/common/firstTime.css";
import { useToasts } from "react-toast-notifications";
import {
  Basicinfo,
  ProfileBox,
  Project,
  Employment,
  SocialIcon,
} from "./common";

import { useDispatch, useSelector } from "react-redux";
import {
  getProfileApi,
  deleteProjectApi,
  getSocialIconApi,
  getProjectsApi,
  saveMyProjectsApi,
  updateProjectApi,
  deleteEmploymentApi,
  getEmploymentsApi,
  saveMyEmploymentsApi,
  updateEmploymentApi,
  languageListApi,
  skilsListApi,
  updateAdditinalApi,
  signUpAddDataApi,
  getAllSellerDataApi,
  industryListApi,
  fetchServiceListApi,
  expertiseListApi,
} from "../../library/api/AccountApiService";
import {
  addDefaultSrc,
  addValidation,
  getCookie,
  removeValidation,
  replceMultiStringWithSIngle,
  returnDefaultImage,
  scroolTop,
  setCookie,
} from "../../library/utilities/functions";
import {
  changeBasicInfoInput,
  clearProfileData,
  clearUpdateProfileRespons,
  addNewProject,
  changeMyProjectDetailInfo,
  clearSaveMyProjectsResponse,
  clearDeleteProjectResponse,
  changeEditProject,
  clearUpdateProjectResponse,
  addNewEmployment,
  changeMyEmploymentDetailInfo,
  clearSaveMyEmploymentsResponse,
  clearDeleteEmploymentResponse,
  changeEditEmployment,
  clearUpdateEmploymentResponse,
  clearSocialIconResponse,
  clearUpdateProfileResponse,
} from "./AccountAction";

import { useRef } from "react";
import moment from "moment";
import { employmentFormValidation, projectFormValidation } from "./validations";
import { useState } from "react";

import {
  ProfileGallery,
  ProfilePosts,
  ProfileMembers,
} from "../../library/common/components";
import { checkIsPostClicked } from "../SearchProfile/SearchProfileAction";
import {
  ShareGalleryFilesApi,
  getGalleryPhotoApi,
  getGalleryVideoApi,
} from "../../library/api/ProfilePostApiService";
import {
  changeGalleryDetail,
  clearGalleryPhotoResponse,
  clearGalleryVideoResponse,
} from "../../library/common/components/ProfileGallery/ProfileGalleryAction";
import { changeUserAllPostDetail } from "../../library/common/components/ProfilePosts/ProfilePostAction";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { MEMBERS } from "../../library/urls";
import { event } from "jquery";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import "intro.js/introjs.css";
import introJs from "intro.js";
import { FileUpload } from "primereact/fileupload";

let edit_employment = false,
  add_more = false;

var edit_project = false;
const Account = () => {
  const project_title_validation = useRef(null);
  const project_job_type_validation = useRef(null);
  const project_address_validation = useRef(null);
  const project_description_validation = useRef(null);
  const employment_title_validation = useRef(null);
  const employment_job_type_validation = useRef(null);
  const employment_address_validation = useRef(null);
  const [memberData, setMemberData] = useState([]);
  const employment_description_validation = useRef(null);
  const history = useHistory();
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [tabStatus, setTabStatus] = useState("");
  const [is_valid_from_date, update_is_valid_from_date] = useState(true);
  const [is_valid_to_date, update_is_valid_to_date] = useState(true);
  const [serviceCount, setServiceCount] = useState(false);
  const { latitude, longitude } = usePosition();
  const [pillsValue, setPillValue] = useState("home");
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const check_individual = getCookie("individual");
  const AccountState = useSelector((state) => state.AccountReducer);
  const GalleryState = useSelector((state) => state.ProfileGalleryReducer);
  const profilePostState = useSelector((state) => state.ProfilePostReducer);
  const location = useLocation();

  const { username } = useParams();
  // const [visitStatusStep,setVisitStatusStep]=useState(false)
  const [link, setLink] = useState("");
  const [projectIcon, setProjectIcon] = useState(null);
  const [editProjectIcon, setEditProjecticon] = useState("");
  const [projectImages, setProjectImages] = useState([]);
  const [clickNotification, setClickNotification] = useState(false);
  const {
    galleryDetails: { photoPage, videoPage, photoList },
  } = GalleryState;
  const {
    getProfilePostDetail: { getProfilePost },
  } = profilePostState;
  const {
    basicInfo: { profileImage, coverImage, businessImage },
    my_projects,
    my_employments,
  } = AccountState;
  const {
    GeneralInput: { intro, specialization, expertise, address, work },
    getAllSellerDataApi: { getAllSellerDatas },
    is_edit_project,
    is_edit_employment,
  } = AccountState;
  const authState = useSelector((state) => state.Authreducer);
  const {
    updateAdditionApi: { updateAdditionStatus, updateAdditionMessage },
    getProfileApi: { getProfileStatus },
    saveMyProjectsApi: { saveMyProjectsStatus, saveMyProjectsMessage },
    deleteProjectApi: { deleteProjectStatus, deleteProjectMessage },
    updateProjectApi: { updateProjectStatus, updateProjectMessage },

    saveMyEmploymentsApi: { saveMyEmploymentsStatus, saveMyEmploymentsMessage },
    deleteEmploymentApi: { deleteEmploymentStatus, deleteEmploymentMessage },
    updateEmploymentApi: { updateEmploymentStatus, updateEmploymentMessage },
  } = AccountState;

  edit_project = is_edit_project;

  edit_employment = is_edit_employment;
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  useEffect(() => {
    if (!!updateAdditionStatus && updateAdditionStatus == "200") {
      dispatch(clearProfileData());
      dispatch(getProfileApi());
      addToast(updateAdditionMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearUpdateProfileResponse());
    }
    if (!!updateAdditionStatus && updateAdditionStatus != "200") {
      addToast(updateAdditionMessage, {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearUpdateProfileResponse());
    }
  }, [updateAdditionStatus]);

  useEffect(() => {
    if (getProfileStatus === 200) {
      for (let i in getProfilePost) {
        if (getProfilePost[i].user_id === profileData.id) {
          getProfilePost[i].user_profile = profileData.profile_image;
          getProfilePost[i].business_image = profileData.business_image;
        }
        dispatch(changeUserAllPostDetail({ getProfilePost: getProfilePost }));
      }
    }
  }, [getProfileStatus]);

  useEffect(() => {
    if (!!updateProjectStatus && updateProjectStatus == "200") {
      // get api
      if (getCookie("individual") == "true") {
      } else {
        dispatch(getProjectsApi());
      }
      // close the dialog...
      const showProjectsDialog = document.querySelector(".project-setting");
      showProjectsDialog.click();
      addToast(updateProjectMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearUpdateProjectResponse());
      setDescriptionLength(0);
    }
    if (updateProjectStatus == "400") {
      addToast(updateProjectMessage, {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearUpdateProjectResponse());
    }
    // clear api response...
  }, [updateProjectStatus]);

  useEffect(() => {
    if (!!saveMyProjectsStatus && saveMyProjectsStatus == "200") {
      // get api
      if (getCookie("individual") == "true") {
      } else {
        dispatch(getProjectsApi());
      }
      if (!add_more) {
        // close the dialog...
        const showProjectsDialog = document.querySelector(".project-setting");
        showProjectsDialog.click();
      } else {
        // reset project....
        resetProject(0, true);
      }
      addToast(saveMyProjectsMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearSaveMyProjectsResponse());
      setDescriptionLength(0);
    }
    if (saveMyProjectsStatus == "400") {
      addToast(saveMyProjectsMessage, {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearSaveMyProjectsResponse());
    }
    // clear api response...
  }, [saveMyProjectsStatus]);

  useEffect(() => {
    if (!!deleteProjectStatus && deleteProjectStatus == "200") {
      // get api
      if (getCookie("individual") == "true") {
      } else {
        dispatch(getProjectsApi());
      }
      addToast(deleteProjectMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearDeleteProjectResponse());
    }
    if (deleteProjectStatus == "400") {
      addToast(deleteProjectMessage, {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearDeleteProjectResponse());
    }
    // clear api response...
  }, [deleteProjectStatus]);

  const handleUploadProjectIcon = (event) => {
    if (event.target.name === "projectIcon") {
      setProjectIcon(event.target.files[0]);
    } else if (event.target.name === "projectImages") {
      let images = Object.values(event.target.files);

      setProjectImages(images);
    }
  };

  /**
   * employment
   */

  useEffect(() => {
    if (!!updateEmploymentStatus && updateEmploymentStatus == "200") {
      // get api
      if (getCookie("individual") == "true") {
        dispatch(getEmploymentsApi());
      } else {
      }
      // close the dialog...
      const showEmploymentsDialog = document.querySelector(".project-setting");
      showEmploymentsDialog.click();
      addToast(updateEmploymentMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearUpdateEmploymentResponse());
    }

    // clear api response...
    if (updateEmploymentStatus == "400") {
      addToast(updateEmploymentMessage, {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearUpdateEmploymentResponse());
    }
  }, [updateEmploymentStatus]);

  useEffect(() => {
    if (saveMyEmploymentsStatus !== "") {
      if (!!saveMyEmploymentsStatus) {
        // get api
        if (getCookie("individual") == "true") {
          dispatch(getEmploymentsApi());
        } else {
        }

        if (!add_more) {
          // close the dialog...
          const showEmploymentsDialog =
            document.querySelector(".project-setting");
          showEmploymentsDialog.click();
          setDescriptionLength(0);
        } else {
          // reset project....
          resetEmployment(0, true);
        }

        addToast(saveMyEmploymentsMessage, {
          appearance: "success",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      } else {
        addToast(saveMyEmploymentsMessage, {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      }
      // clear api response...
      dispatch(clearSaveMyEmploymentsResponse());
    }
  }, [saveMyEmploymentsStatus]);

  useEffect(() => {
    if (!!deleteEmploymentStatus && deleteEmploymentStatus == "200") {
      // get api
      if (getCookie("individual") == "true") {
        dispatch(getEmploymentsApi());
      } else {
      }
      addToast(deleteEmploymentMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearDeleteEmploymentResponse());
    }
    if (deleteEmploymentStatus == "400") {
      addToast(deleteEmploymentMessage, {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearDeleteEmploymentResponse());
    }
    // clear api response...
  }, [deleteEmploymentStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearProfileData());
      setDescriptionLength(0);
      dispatch(checkIsPostClicked({ isPostClicked: false }));
    };
  }, []);

  if (username && profileData?.user_slug !== username && !!authState.is_auth) {
    // split userid from username slug--//
    let user_id = username.split("-");

    history.push(`/search-profile/${user_id[1]}`);
  } else if (authState.is_auth == false) {
    history.push(`/search-user/`);
  }

  const handleFileChange = (e) => {
    var data = e.target.files[0];
    let lat, lon;
    if (!!data) {
      const fileName = data.name.split(".");
      const imageFormat = fileName[fileName.length - 1];
      if (e.target.files[0]) {
        if (
          imageFormat === "png" ||
          imageFormat === "jpg" ||
          imageFormat === "jpeg" ||
          imageFormat === "SVG" ||
          imageFormat === "svg" ||
          imageFormat === "PNG" ||
          imageFormat === "JPG" ||
          imageFormat === "JPEG"
        ) {
          dispatch(
            changeBasicInfoInput({ [e.target.name]: e.target.files[0] })
          );
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            dispatch(changeBasicInfoInput({ [e.target.id]: reader.result }));
          });
          reader.readAsDataURL(e.target.files[0]);

          const bodyParameters = new FormData();
          if (check_individual === "true") {
            bodyParameters.append("work", "" + work);
            bodyParameters.append("specialization", "");
          } else {
            bodyParameters.append("specialization", specialization);
            bodyParameters.append("work", "");
            expertise.map((data, i) =>
              bodyParameters.append(
                `expertise['${i}'][expertise_ids]`,
                data.value
              )
            );
          }

          bodyParameters.append("business_intro", intro);

          bodyParameters.append("address", "" + address);
          bodyParameters.append(
            "latitude",
            +getCookie("latitude") ? getCookie("latitude") : ""
          );
          bodyParameters.append(
            "longitude",
            +getCookie("longitude") ? getCookie("longitude") : ""
          );
          bodyParameters.append(
            "profile_image",
            e.target.name == "profileImage" ? e.target.files[0] : profileImage
          );
          bodyParameters.append(
            "business_image",
            e.target.name == "businessImage" ? e.target.files[0] : businessImage
          );
          bodyParameters.append(
            "cover_image",
            e.target.name == "coverImage" ? e.target.files[0] : coverImage
          );

          const data = new FormData();
          data.append(
            "profile_image",
            e.target.name == "profileImage" ? e.target.files[0] : profileImage
          );
          dispatch(signUpAddDataApi(data));
          dispatch(updateAdditinalApi(bodyParameters));

          // });
        } else {
          addToast("Only .png, .jpg, .jpeg image formats supported.", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
        }
      }
    }
  };

  const removeProjectValidation = () => {
    window.setTimeout(() => {
      update_is_valid_from_date(true);
      update_is_valid_to_date(true);
      let validation = {
        is_valid_title: { status: false, validation: project_title_validation },
        is_valid_job_type: {
          status: false,
          validation: project_job_type_validation,
        },
        is_valid_address: {
          status: false,
          validation: project_address_validation,
        },
        is_valid_description: {
          status: false,
          validation: project_description_validation,
        },
      };
      removeValidation(validation);
    }, 0);
  };

  const removeEmploymentValidation = () => {
    window.setTimeout(() => {
      update_is_valid_from_date(true);
      update_is_valid_to_date(true);
      let validation = {
        is_valid_title: {
          status: false,
          validation: employment_title_validation,
        },
        is_valid_job_type: {
          status: false,
          validation: employment_job_type_validation,
        },
        is_valid_address: {
          status: false,
          validation: employment_address_validation,
        },
        is_valid_description: {
          status: false,
          validation: employment_description_validation,
        },
      };
      removeValidation(validation);
    }, 0);
  };

  // hide and show projects ------//
  const onShowHideProjects = (data) => {
    add_more = false;

    removeProjectValidation();
    const showProjectsDialog = document.querySelector(".project-show");
    if (!!showProjectsDialog) {
      if (data === "true") {
        edit_project = true;
      }

      if (edit_project === false) {
        setEditProjecticon("");
        setProjectImages([]);
        dispatch(addNewProject());
      }
    } else {
      setDescriptionLength(0);
      dispatch(changeEditProject(false));
      const default_project = [
        {
          current: false,
          title: "",
          from: "",
          to: "",
          type_of_job: "",
          location: "",
          description: "",
          link: "",
        },
      ];
      dispatch(changeMyProjectDetailInfo(default_project));
    }
  };
  const activePath = window.location.pathname;
  // change project details function -----//
  const changeProjectDetail = (e, index) => {
    let target = e.target;

    if (target.value !== null && target.name !== "project_link") {
      var regex = /^[a-zA-Z ]*$/;

      const _validation =
        target?.value.length < 2 ? regex.test(target.value) : target.value;

      if (_validation) {
        // for description //
        if (e.target.name == "description") {
          let replaceString = replceMultiStringWithSIngle(target.value);
          setDescriptionLength(replaceString.length);
        }
        // add value to other fields //

        let projects = my_projects;

        for (let i in projects) {
          if (i == index) {
            projects[i] = {
              ...projects[i],
              ...{
                [target.name]:
                  target.type == "checkbox" ? target.checked : target.value,
              },
            };
          }
        }

        dispatch(changeMyProjectDetailInfo(projects));
      }
    } else if (target.name === "project_link" && target.value.length > 0) {
      const linkValue = target.value;

      const urlPattern =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

      // if (urlPattern.test(linkValue)) {

      let projects = my_projects;
      for (let i in projects) {
        if (i == index) {
          projects[i] = {
            ...projects[i],
            ...{
              [target.name]:
                target.type == "checkbox" ? target.checked : linkValue,
            },
          };
        }
      }
      dispatch(changeMyProjectDetailInfo(projects));
      // }
    } else if (
      target.value === null &&
      target.name === "project_link" &&
      (target.name === "to" || target.name === "from")
    ) {
      let projects = my_projects;
      for (let i in projects) {
        if (i == index) {
          projects[i] = {
            ...projects[i],
            ...{
              [target.name]: target.type == "checkbox" ? target.checked : "",
            },
          };
        }
      }
      dispatch(changeMyProjectDetailInfo(projects));
    }
  };

  // -----reset project start----//
  const resetProject = (index, add_more_project) => {
    add_more = !!add_more_project ? true : false;
    removeProjectValidation();
    let projects = my_projects;
    const default_project = {
      current: false,
      title: "",
      from: "",
      to: "",
      type_of_job: "",
      location: "",
      description: "",
      project_link: "",
    };
    for (let i in projects) {
      if (i == index) {
        projects[i] = default_project;
      }
    }
    setProjectImages([]);
    setProjectIcon(null);
    setEditProjecticon("");
    setLink("");
    setDescriptionLength(0);
    dispatch(changeMyProjectDetailInfo(projects));
  };
  //------ reset project end-----//
  const handleSharePost = () => {
    if (projectImages.length > 0) {
      let picture = [];

      for (let i in projectImages) {
        picture.push(projectImages[i]);
      }

      const bodyParameter = new FormData();

      picture.forEach(function (image, i) {
        bodyParameter.append("image_" + i, image);
      });

      bodyParameter.append("images", "" + !!picture ? picture.length : "");
      bodyParameter.append("type", "" + 1);
      bodyParameter.append("visibility", 0);

      dispatch(ShareGalleryFilesApi(bodyParameter)).then(() => {
        addToast("Uploaded successfully", {
          appearance: "success",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
        setProjectImages([]);
      });
    }
  };

  // Function to sunmit project details
  const onProjectsSubmit = (e) => {
    if (!!e) {
      e.preventDefault();
      add_more = false;
    } else {
      add_more = true;
    }
    let projects = my_projects;
    let sendProjects = [];
    const formData = new FormData();
    let payloadData = {};
    let filesUpload = [];

    projectImages.map((item) => {
      if (item?.name?.file_name == undefined) {
        filesUpload.push(item);
      }
    });


  
    // for validation on process only//
    for (let i in projects) {
     
      payloadData = {
        project_id: projects[i].project_id ? projects[i].project_id : "",
        current_project: !!projects[i].current ? 1 : 0,
        title: projects[i]?.title,
        raw_to_date: !projects[i].current ? projects[i].to.toString() : "",
        raw_from_date: projects[i].from.toString(),
        from_date: moment(projects[i].from).format("MM/YYYY"),
        to_date: !projects[i].current
          ? moment(projects[i].to).format("MM/YYYY")
          : "",
        job_type: projects[i].type_of_job,
        address: projects[i].location,
        description: projects[i].description,
        latitude: longitude !== undefined ? longitude : "",
        longitude: latitude !== undefined ? latitude : "",
        project_icon: !!projectIcon ? projectIcon : editProjectIcon,
        link:
          projects[i]?.project_link !== undefined
            ? projects[i]?.project_link
            : "",
        project_images: filesUpload.length > 0 ? filesUpload : [],
      };
    }
  
    formData.append("project_id", payloadData?.project_id);
    formData.append("current_project", payloadData?.current_project ? 1 : 0);
    formData.append("title", payloadData.title);
    formData.append("raw_to_date", payloadData.raw_to_date);
    formData.append("raw_from_date", payloadData.raw_from_date);
    formData.append("from_date", payloadData.from_date);
    formData.append("to_date", payloadData.to_date);
    formData.append("job_type", payloadData.job_type);
    formData.append("address", payloadData.address);
    formData.append("description", payloadData.description);
    formData.append("latitude", payloadData.longitude);
    formData.append("longitude", payloadData.latitude);
    formData.append(
      "project_icon",
      !!payloadData?.project_icon ? payloadData?.project_icon : ""
    );
    formData.append("link", payloadData?.link);
    // formData.append("images[]","")
    // formData.append()

    if (is_edit_project) {
      if (!!projectIcon) {
        formData.append("is_icon", 1);
      } else if (!!editProjectIcon) {
        formData.append("is_icon", 2);
      } else {
        formData.append("is_icon", 0);
      }

      if (projectImages?.length > 0) {
        formData.append("is_image", 1);
      } else {
        formData.append("is_image", 0);
      }
    }

    // projectImages.forEach((item, i) =>
    //   formData.append(
    //     `images[${i}]`,
    //     !!item?.file_name ? item?.file_name : item
    //   )
    // );

    filesUpload.forEach((item, i) => formData.append(`images[${i}]`, item));

    //
    // ---append payloadData to form---//

    let validation = {
      is_valid_title: { status: false, validation: project_title_validation },
      is_valid_job_type: {
        status: false,
        validation: project_job_type_validation,
      },
      is_valid_address: {
        status: false,
        validation: project_address_validation,
      },
      is_valid_description: {
        status: false,
        validation: project_description_validation,
      },
    };

    validation = projectFormValidation(validation, payloadData);

    let {
      is_valid_title,
      is_valid_job_type,
      is_valid_address,
      is_valid_description,
    } = validation;
    addValidation(validation);

    const current_project = payloadData.current_project;
    const from_date = payloadData.from_date;
    const to_date = payloadData.to_date;

    let is_valid_from = false,
      is_valid_to = false;
    if (!!from_date && from_date != "Invalid date") {
      // success
      is_valid_from = true;
      update_is_valid_from_date(true);
    } else {
      // error
      is_valid_from = false;
      update_is_valid_from_date(false);
    }
    if (!current_project) {
      if (!!to_date && to_date != "Invalid date") {
        is_valid_to = true;
        update_is_valid_to_date(true);
      } else {
        is_valid_to = false;
        update_is_valid_to_date(false);
      }
    } else {
      is_valid_to = true;
      update_is_valid_to_date(true);
    }

    if (
      is_valid_title.status &&
      is_valid_job_type.status &&
      is_valid_address.status &&
      is_valid_description.status &&
      is_valid_from &&
      is_valid_to
    ) {
      if (is_edit_project) {
        dispatch(updateProjectApi(formData)).then((res) => {
          if (res.response.data.status === 200) {
            console.log("success");
          }
        });
        setProjectIcon(null);
        setLink("");
      } else {
        dispatch(saveMyProjectsApi(formData))
          .then((resp) => {
            if (resp?.response?.status == 200) {
              setProjectIcon(null);
              setLink("");
            } else {
              addToast(s, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          })
          .catch((err) => {
            addToast(err, {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }
    }
  };

  // ------EDIT PROJECT ------//
  const editProjectOpen = (project) => {
    setDescriptionLength(project.description.length);
    dispatch(changeEditProject(true));

    const edit_project = [
      {
        project_id: project.id,
        current: !!project.current_project ? true : false,
        title: project.title,
        from: !!project.raw_from_date ? new Date(project.raw_from_date) : "",
        to: !!project?.raw_to_date  ? new Date(project.raw_to_date) : "",
        type_of_job: project.job_type,
        location: project.address,
        description: project.description,
        project_icon: project?.project_icon || "",
        project_link:
          project.link != undefined && project.link.length > 0
            ? project.link
            : "",
        project_images: project?.images.length > 0 ? project?.images : [],
      },
    ];

    dispatch(changeMyProjectDetailInfo(edit_project));

    setTimeout(() => {
      const showProjectsDialog = document.querySelector(".project-setting");
      showProjectsDialog.click();
    }, 500);
  };

  // PROJECT EDIT END -----//

  // show hide employements  //
  const onShowHideEmployments = () => {
    window.setTimeout(() => {
      add_more = false;
      removeEmploymentValidation();
      const showEmploymentsDialog = document.querySelector(".project-show");
      if (!!showEmploymentsDialog) {
        if (!edit_employment) dispatch(addNewEmployment());
        setDescriptionLength(0);
      } else {
        dispatch(changeEditEmployment(false));
        const default_employment = [
          {
            current: false,
            title: "",
            from: "",
            to: "",
            type_of_job: "",
            location: "",
            description: "",
          },
        ];
        dispatch(changeMyEmploymentDetailInfo(default_employment));
      }
    }, 0);
  };

  // -----change employement details -----//
  const changeEmploymentDetail = (e, index) => {
    let target = e.target;
    var regex = /^[a-zA-Z ]*$/;
    const _validation =
      target.value.length < 2 ? regex.test(target.value) : target.value;
    if (_validation) {
      if (e.target.name == "description") {
        let replaceString = replceMultiStringWithSIngle(target.value);
        setDescriptionLength(replaceString.length);
      }
      let employments = my_employments;
      for (let i in employments) {
        if (i == index) {
          employments[i] = {
            ...employments[i],
            ...{
              [target.name]:
                target.type == "checkbox" ? target.checked : target.value,
            },
          };
        }
      }
      dispatch(changeMyEmploymentDetailInfo(employments));
    }
  };

  // -----reset employement -----//
  const resetEmployment = (index, add_more_employment) => {
    add_more = !!add_more_employment ? true : false;
    removeEmploymentValidation();
    let employments = my_employments;
    const default_employment = {
      current: false,
      title: "",
      from: "",
      to: "",
      type_of_job: "",
      location: "",
      description: "",
    };
    for (let i in employments) {
      if (i == index) {
        employments[i] = default_employment;
      }
    }
    dispatch(changeMyEmploymentDetailInfo(employments));
    setDescriptionLength(0);
  };

  // ----- on create employement ---- //
  const onEmploymentsSubmit = (e) => {
    if (!!e) {
      e.preventDefault();
      add_more = false;
    } else {
      add_more = true;
    }
    let employments = my_employments;
    let sendEmployments = [];

    // hit api to send the details to the server...
    for (let i in employments) {
      sendEmployments.push({
        emp_id: employments[i].employment_id,
        current_job: !!employments[i].current ? 1 : 0,
        company_name: employments[i].title,
        from_date: moment(employments[i].from).format("MM/YYYY"),
        to_date: !employments[i].current
          ? moment(employments[i].to).format("MM/YYYY")
          : null,
        raw_from_date: employments[i].from.toString(),
        raw_to_date: !employments[i].current
          ? employments[i].to.toString()
          : null,
        position: employments[i].type_of_job,
        address: employments[i].location,
        latitude: latitude,
        longitude: longitude,
        description: employments[i].description,
      });
    }
    let validation = {
      is_valid_company_name: {
        status: false,
        validation: employment_title_validation,
      },
      is_valid_position: {
        status: false,
        validation: employment_job_type_validation,
      },
      is_valid_address: {
        status: false,
        validation: employment_address_validation,
      },
      is_valid_description: {
        status: false,
        validation: employment_description_validation,
      },
    };
    validation = employmentFormValidation(validation, sendEmployments[0]);
    let {
      is_valid_company_name,
      is_valid_position,
      is_valid_address,
      is_valid_description,
    } = validation;
    addValidation(validation);
    const current_employment = sendEmployments[0].current_job;
    const from_date = sendEmployments[0].from_date;
    const to_date = sendEmployments[0].to_date;
    let is_valid_from = false,
      is_valid_to = false;
    if (!!from_date && from_date != "Invalid date") {
      // success
      is_valid_from = true;
      update_is_valid_from_date(true);
    } else {
      // error
      is_valid_from = false;
      update_is_valid_from_date(false);
    }
    if (!current_employment) {
      if (!!to_date && to_date != "Invalid date") {
        is_valid_to = true;
        update_is_valid_to_date(true);
      } else {
        is_valid_to = false;
        update_is_valid_to_date(false);
      }
    } else {
      is_valid_to = true;
      update_is_valid_to_date(true);
    }

    if (
      is_valid_company_name.status &&
      is_valid_position.status &&
      is_valid_address.status &&
      is_valid_description.status &&
      is_valid_from &&
      is_valid_to
    ) {
      if (is_edit_employment) {
        dispatch(updateEmploymentApi(sendEmployments[0]));
      } else {
        dispatch(saveMyEmploymentsApi(sendEmployments[0]));
      }
    }
  };

  //---- edit employement open -----//
  const editEmploymentOpen = (employment) => {
    setDescriptionLength(employment.description.length);
    dispatch(changeEditEmployment(true));
    const edit_employment = [
      {
        employment_id: employment.id,
        current: !!employment.current_job ? true : false,
        title: employment.company_name,
        from: !!employment.raw_from_date
          ? new Date(employment.raw_from_date)
          : "",
        to: !!employment.raw_to_date ? new Date(employment.raw_to_date) : "",
        type_of_job: employment.position,
        location: employment.address,
        description: employment.description,
      },
    ];
    dispatch(changeMyEmploymentDetailInfo(edit_employment));

    const showEmploymentsDialog = document.querySelector(".project-setting");
    showEmploymentsDialog.click();
  };

  useEffect(() => {
    setClickNotification(localStorage.getItem("clickServiceNotification"));
    if (
      localStorage.getItem("clickServiceNotification") === true ||
      localStorage.getItem("clickServiceNotification") === "true"
    ) {
    }
  }, [localStorage.getItem("clickServiceNotification")]);

  // TO CHECK IS CLICK ON SERVICE NOTIFICATION//
  useEffect(() => {
    setClickNotification(localStorage.getItem("clickServiceNotification"));
  }, [localStorage.getItem("clickServiceNotification")]);

  // fetch api data//

  useEffect(() => {
    const data = {
      page: 1,
    };
    dispatch(fetchServiceListApi(data));
    scroolTop();
    dispatch(industryListApi());
    dispatch(getProfileApi());
    dispatch(languageListApi());
    dispatch(getSocialIconApi());
    dispatch(getAllSellerDataApi());
    if (getCookie("individual") == "true") {
      dispatch(getEmploymentsApi());
      dispatch(skilsListApi());
    } else {
      dispatch(getProjectsApi());
      dispatch(expertiseListApi());
    }
    $(".project-setting").click(function () {
      $(".project-form").toggleClass("project-show");
      $(".projects__listing").toggleClass("project-hide");
    });
    $(".socail-media-setting").click(function () {
      $(".socail-media-form").toggleClass("socail-show");
      $(".social-media-icons").toggleClass("project-hide");
    });
  }, []);

  useEffect(() => {
    setServiceCount(localStorage.getItem("clickServiceNotification"));
  }, [localStorage.getItem("clickServiceNotification")]);

  // ---remove service count ---//

  const removeServiceCount = (e, tab) => {
    if (tab == "home") {
      localStorage.setItem("clickServiceNotification", false);
      setClickNotification(false);
    } else if (tab == "posts") {
      localStorage.setItem("clickServiceNotification", false);
      setClickNotification(false);
    } else if (tab == "galley") {
      localStorage.setItem("clickServiceNotification", false);
      setClickNotification(false);
    } else if (tab == "members") {
      localStorage.setItem("clickServiceNotification", false);
      setClickNotification(false);
    } else if (tab == "services") {
      localStorage.setItem("clickServiceNotification", false);
      setClickNotification(false);
    }
  };

  // ---delete project api --- //
  const deleteProject = (project_id) => {
    dispatch(deleteProjectApi({ project_id }));
  };

  // ----- delete employement -----//
  const deleteEmployment = (employment_id) => {
    dispatch(deleteEmploymentApi({ emp_id: employment_id }));
  };

  let firstTimeLogin = !!getCookie("firstTimeLogin")
    ? getCookie("firstTimeLogin")
    : "";

  let displayTutorial = localStorage.getItem("displayProfileIntro");

  // function to generate tutorial steps //
  const generateSteps = (type) => {
    let arr = [
      {
        title:
          ' <div class="d-flex align-items-start flex-column flex-md-row"> <img src="/assets/images/icon.svg" style="width: 60px;" />  <h6  class="pl-0 pl-md-3 m-0 invite-title">Enhance Your Profile, Showcase Achievements, and Highlight Your Projects.</h6></div>',
        intro:
          "Enhance your profile by updating it with comprehensive project details that illuminate your achievements and exhibit your portfolio, enabling you to distinguish yourself. Provide your potential clients with a deeper understanding of who you are and what you bring to the table.",
      },
      {
        element: ".search-form",
        title:
          '<div class="d-flex align-items-start flex-column flex-md-row mt-2"> <img src="/assets/images/poolsicon.png" style="width: 60px;height: 50px;object-fit: contain;" />  <h6  class="pl-0 pl-md-3 m-0 invite-title">Search, Explore, Connect, and Book from a Diverse Array of Individuals and Businesses.</h6></div> <div><img style="width: 500px;height: 250px;object-fit: contain;" src="/assets/images/search.gif"  /> </div>    ',
        intro:
          "Search for what you need: individuals and businesses, including new users, all of whom come from diverse backgrounds, possess varied experiences, and offer their unique perspectives. Explore and book services from others.",
        position: "bottom",
      },
      {
        element: ".slide-status",
        title:
          '<div class="d-flex align-items-start flex-column flex-md-row"><img src="/assets/images/icon.svg" style="width: 60px;"/> <h6class="pl-0 pl-md-3 m-0 invite-title">Kickstart Your Business, Become a Seller, and Start Earning.</h6></div> <div> <img src="/assets/images/slide-status.gif" style="height: 100px; width:200px;"/>  </div>',
        intro:
          "Aspiring to pursue your passion and earn in your chosen field? A simple swipe to the right initiates the business application, enabling you to establish yourself as a seller. This visibility connects you with individuals and businesses actively seeking the products or services you offer ",
        position: "left",
      },
      {
        element: ".dropdown-list",

        title:
          '<div class="d-flex align-items-start flex-column flex-md-row"> <img src="/assets/images/icon.svg" style="width: 60px;" />  <h6 class="pl-0 pl-md-3 m-0 invite-title">Keep Your Network Informed with Timely Updates and Status Control.</h6></div>',

        intro:
          "Keep your sphere of influence engaged, from devoted customers to potential clients, by sharing your current updates. Take charge of your business, whether you're enjoying a vacation, operating at full capacity, or navigating any situation – tailor your status accordingly. Staying informed not only conserves your own precious time, energy, and resources but also those of your valued customers. Should you wish to temporarily pause your services, a simple swipe to the left is all it takes.",
        position: "left",
      },
    ];

    if (type === "business") {
      arr.push({
        element: ".member-class",
        title:
          '<div class="d-flex align-items-start flex-column flex-md-row"> <img src="/assets/images/icon.svg" style="width: 60px;" />  <h6 class="pl-0 pl-md-3 m-0 invite-title">Incorporate your team members seamlessly within your business profile.</h6></div>',
        intro:
          "Empower your business profile by seamlessly incorporating team members. This facilitates efficient task delegation and fosters meaningful interactions with your valued business clientele.",
      });
    }
    return arr;
  };

  // first time tutorial start//
  const startIntro = () => {
    let createdElements = [];
    // introOption Object to create intro steps// 
    const introOptions = check_individual
      ? {
          steps: generateSteps("individual"), // generate steps function for individual user
          tooltipClass: "custom-tooltip-profile", // provide custom class to tooltip
          buttonClass: "btn btn-sm btn-primary", // provide custom class to tooltip button
          disableInteraction: true, // disable interaction with element//
        }
      : {
          steps: generateSteps("business"), // generate steps function for business user
          tooltipClass: "custom-tooltip-profile", // provide custom class to tooltip
          buttonClass: "btn btn-sm btn-primary", // provide custom class to tooltip button
          disableInteraction: true, // disable interaction with element//
        };


        //pass the introOption object to setOption//
    const intro = introJs().setOptions(introOptions);


    // when .swipe-input element is targeted open the ul list //
    intro.onbeforechange(async (targetElement) => {
      return new Promise((resolve) => {
        if (targetElement.classList.contains("dropdown-list")) {
          const hiddenElement = document.querySelector(".swipe-input ul");
          hiddenElement.style.opacity = 1;
          hiddenElement.style.visibility = "visible";
        }
        setInterval(resolve, 500);
      });
    });


    //scroll top if tooltip contains scroll//
    intro.onafterchange(() => {
      setTimeout(() => {
        const tooltipContent = document.querySelector(
          ".custom-tooltip-profile"
        );
        if (tooltipContent) {
          tooltipContent.scrollTop = 0;
        }
      }, 1000);

      const currentStep = intro._currentStep;
      if (currentStep === 3) {
        for (const element of createdElements) {
          element.remove();
        }

        // Clear the array after removing elements
        createdElements = [];
      }

      // if user cross the 4th step then hide the ul list//
      if (currentStep === 4) {
        const hiddenElement = document.querySelector(".swipe-input ul");
        hiddenElement.style.opacity = 0;
        hiddenElement.style.visibility = "hidden";
      }
    });

    // if user direct exit from 4th step then hide the ul list//
    intro.onexit(() => {
      const hiddenElement = document.querySelector(".swipe-input ul");
      hiddenElement.style.opacity = 0;
      hiddenElement.style.visibility = "hidden";
    });

    intro.start();
  };

  
  useEffect(() => {
    if (displayTutorial !== "true" && firstTimeLogin === "true") {
    setTimeout(() => {
      startIntro();
      localStorage.setItem("displayProfileIntro", true);
    }, 3000);
    }
  }, []);

  // first time tutorial end //
  const renderOnlyForUsers = () => {
    return (
      <section className="profile-tab-content spacer bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-content" id="pills-tab-Content">
                {/* ---about section start ---*/}
                <div
                  className={`tab-pane fade ${
                    activePath == `/account/${profileData?.user_slug}` &&
                    "show active"
                  }`}
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <ProfileBox
                    AccountState={AccountState}
                    dispatch={dispatch}
                    check_individual={check_individual}
                  />

                  {check_individual == "true" && (
                    <Employment
                      allref={{
                        employment_title_validation,
                        employment_job_type_validation,
                        employment_address_validation,
                        employment_description_validation,
                      }}
                      is_valid_from_date={is_valid_from_date}
                      is_valid_to_date={is_valid_to_date}
                      AccountState={AccountState}
                      onShowHideEmployments={onShowHideEmployments}
                      changeEmploymentDetail={changeEmploymentDetail}
                      resetEmployment={resetEmployment}
                      onEmploymentsSubmit={onEmploymentsSubmit}
                      // createEmployment={createEmployment}
                      deleteEmployment={deleteEmployment}
                      editEmploymentOpen={editEmploymentOpen}
                      is_edit_employment={is_edit_employment}
                      descriptionLength={descriptionLength}
                    />
                  )}
                  {check_individual != "true" && (
                    <Project
                      allref={{
                        project_title_validation,
                        project_job_type_validation,
                        project_address_validation,
                        project_description_validation,
                      }}
                      setProjectImages={setProjectImages}
                      projectImages={projectImages}
                      is_valid_from_date={is_valid_from_date}
                      is_valid_to_date={is_valid_to_date}
                      AccountState={AccountState}
                      onShowHideProjects={onShowHideProjects}
                      changeProjectDetail={changeProjectDetail}
                      handleUploadProjectIcon={handleUploadProjectIcon}
                      resetProject={resetProject}
                      // setProjectImage={setProjectImage}
                      projectImage={profileImage}
                      setProjectIcon={setProjectIcon}
                      setEditProjecticon={setEditProjecticon}
                      editProjectIcon={editProjectIcon}
                      projectIcon={projectIcon}
                      onProjectsSubmit={onProjectsSubmit}
                      deleteProject={deleteProject}
                      editProjectOpen={editProjectOpen}
                      is_edit_project={is_edit_project}
                      descriptionLength={descriptionLength}
                    />
                  )}
                  <SocialIcon AccountState={AccountState} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  // ---- tabs only for admin start----//
  const renderOnlyForAdmin = () => {
    return (
      <section className="profile-tab-content spacer bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="tab-pane fade show active"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <ProfilePosts />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  // ----tabs only for admin end----//

  // basic info and other components---//
  return (
    <>
      <div>
        <Basicinfo
          AccountState={AccountState}
          check_individual={check_individual}
          handleFileChange={handleFileChange}
        />

        <section className="profile-tabs">
          <div className="container">
            <div className="row">
              {/* pill tabs started */}
              <div className="col-md-12">
                <ul className="nav nav-tabs my-2">
                  {/* about start */}
                  {!profileData.is_supporter && (
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={(e) => removeServiceCount(e, "about")}
                    >
                      <Link
                        aria-current="page"
                        to={`/account/${profileData?.user_slug}`}
                        className={`nav-link ${
                          activePath === `/account/${profileData.user_slug}`
                            ? "active"
                            : null
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        About
                      </Link>
                    </li>
                  )}
                  {/* about end */}

                  {/* post start */}
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={(e) => removeServiceCount(e, "posts")}
                  >
                    <Link
                      className={`nav-link ${
                        activePath ==
                        `/account/${profileData?.user_slug}/profile-post`
                          ? "active"
                          : null
                      }`}
                      aria-current="page"
                      to={`/account/${profileData?.user_slug}/profile-post`}
                      style={{ cursor: "pointer" }}
                    >
                      <span>Posts</span>
                    </Link>
                  </li>

                  {/* post end               */}

                  {/* gallery start  */}
                  {!profileData.is_supporter && (
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={(e) => removeServiceCount(e, "gallery")}
                    >
                      <Link
                        aria-current="page"
                        to={`/account/${profileData.user_slug}/gallery`}
                        className={`nav-link ${
                          activePath ===
                          `/account/${profileData.user_slug}/gallery`
                            ? "active"
                            : null
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        Gallery
                      </Link>
                    </li>
                  )}

                  {/* gallery end  */}

                  {/* members start  */}
                  {!profileData.is_supporter
                    ? check_individual == false && (
                        <li
                          className="nav-item"
                          role="presentation"
                          onClick={(e) => removeServiceCount(e, "members")}
                        >
                          <Link
                            aria-current="page"
                            to={`/account/${profileData.user_slug}/members`}
                            className={`nav-link member-class ${
                              activePath ===
                              `/account/${profileData.user_slug}/members`
                                ? "active"
                                : null
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            Members
                          </Link>
                        </li>
                      )
                    : null}
                  {/* members end  */}

                  {/* services start  */}
                  {!profileData.is_supporter && profileData?.is_seller ? (
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={(e) => removeServiceCount(e, "service")}
                    >
                      <Link
                        aria-current="page"
                        to={`/account/${profileData?.user_slug}/services`}
                        className={`nav-link ${
                          (!profileData.is_supporter &&
                            activePath ===
                              `/account/${profileData?.user_slug}/services`) ||
                          clickNotification == "true"
                            ? "active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        Services
                        {serviceCount === "true" ? (
                          <span className="service-active ml-1">1</span>
                        ) : null}
                      </Link>
                    </li>
                  ) : null}
                  {/* service end  */}
                </ul>
              </div>
              {/* pill tabs end */}
            </div>
          </div>
        </section>
        {!profileData.is_supporter
          ? renderOnlyForUsers()
          : renderOnlyForAdmin()}
      </div>
    </>
  );
};
export default Account;
