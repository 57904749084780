import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, NavLink } from "react-router-dom";
import {
  logOutApi,
  readAllNotificationsApi,
} from "../../../library/api/NotificationApiService";
import { changeIsAuth } from "../../../library/common/actions/AuthActions";
import { changeUnreadFriendMessage } from "../../../library/common/components/Toolbar/ToolbarActions";
import { ADMIN, IMAGE_BASE_URL, SOCKET } from "../../../library/urls";
import { useToasts } from "react-toast-notifications";
import {
  clearCookies,
  getCookie,
  setCookie,
} from "../../../library/utilities/functions";
import { clearProfileData } from "../../Account/AccountAction";
import { clearLoginApiResponse } from "../../Login/LoginActions";
import { changeChatDetails } from "../../PoolsChat/PoolsChatAction";
import { getProfileApi } from "../../../library/api/AccountApiService";
import { ListData } from "../../Account/common/SellerStatusListData";
import { clearProfileResponse } from "../../SearchProfile/SearchProfileAction";


export default function SideBarProfileMenu() {

const profileData = !!getCookie("profile_data")
? JSON.parse(getCookie("profile_data"))
: "";
  const check_individual = getCookie("individual");
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const toolbarState = useSelector((state) => state.ToolbarReducer);
  const getSellerData = useSelector((state) => state.AccountReducer);
  const [status, setStatus] = useState("");
  const [border, setBorder] = useState("");
  const { addToast } = useToasts();

  const {
    onScreenNotificationApi: { unReadCount, onScreenNotification },
    unreadUserMessageCount,
  } = toolbarState;
  const {
    getAllSellerDataApi: { getAllSellerDatas },
  } = getSellerData;
  const {
    basicInfo,
    getProfileApi: { getProfileResponse },
  } = getSellerData;

  const goToNotification = (data) => {
    onScreenNotification.map((item) => {
      if (item.type !== data) {
        dispatch(readAllNotificationsApi());
      }
    });
    history.push("/all-notification");
  };

  const handleBecomeSeller = () => {
    if (!!Object.keys(getProfileResponse).length) {
      if (!!getAllSellerDatas.user_id) {
        history.push("/become-seller-steps");
      } else {
        history.push("/become-seller");
      }
    }
  };

  // OPEN SUPPORT CHAT//
  const handleContacUs = () => {
    var device = $(window).width();
    setCookie("chatType", "user");
    history.push("/contact-us");
    SOCKET.emit("contact_support", {
      user_id: profileData.id,
      support_id: Number(ADMIN),
    });
    SOCKET.emit("get_friend_and_group_list", {
      u_id: profileData?.id,
      socket_id: SOCKET.id,
    });
    dispatch(changeChatDetails({ currenUserId: ADMIN }));
    if ($(window).width() < 992) {
      setTimeout(() => {
        $(".chat").find(".col-md-3").addClass("chat-toggle");
        $(".chat").find(".col-md-9").addClass("chat-open");
      }, 50);
    }
  };

  useEffect(() => {
    if (window.location.pathname == "/contact-us") {
      $(".chat").find(".col-md-3").removeClass("chat-toggle");
      $(".chat").find(".col-md-9").removeClass("chat-open");
    }
  }, []);
  const handleLogout = async () => {
    //  CALL SOCKET ON LOGOUT SEND STATUS TO ALL FRIEND
    SOCKET.emit("send_my_message_status", {
      sender_id: profileData.id,
      reciever_id: null,
    });
    dispatch(logOutApi());

    SOCKET.close();
    // UPDATE READ = 0 WHEN USER LOGOUT
    SOCKET.emit("update_all_message_read_unread_status", {
      sender_id: !!profileData ? profileData.id : null,
    });

    // WHEN USER LOGGED OUT CLEAR LOGIN RESPONSE,COOKIES DATA AND SEARCH NAME
    dispatch(clearLoginApiResponse());
    dispatch(clearProfileData());

    addToast("Logout Successful", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 2000,
    });

    clearCookies();
    setCookie(null);
    dispatch(changeIsAuth({ is_auth: false }));
    history.push("/");

    dispatch(changeUnreadFriendMessage({ unreadFriendMessage: [] }));
    window.localStorage.removeItem("servicenotification");
    window.localStorage.removeItem("clickServiceNotification");
    window.localStorage.removeItem("ClickOnSeachProfile");
    window.localStorage.removeItem("tabActive");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("isSession");
  };

  useEffect(() => {
    const sellerStatus = ListData.find(
      (x) => x.name === getProfileResponse?.seller_status
    );
    if (sellerStatus) {
      if (getProfileResponse.seller_status != "Custom") {
        setStatus(sellerStatus.imageOne);
      }

      if (
        !!getProfileResponse.status_image?.length &&
        getProfileResponse.seller_status == "Custom"
      ) {
        setStatus(IMAGE_BASE_URL + getProfileResponse.status_image);
      } else if (getProfileResponse.seller_status == "Custom") {
        setStatus("/assets/images/custom-icon.svg");
      }
      setBorder(sellerStatus.color);
    }
  }, [getProfileResponse]);

  const handleMenuBarToggle = () => {
    let offScreenMenu = document.querySelector(".offscreen-menu");
    offScreenMenu.classList.remove("show");
  };

  useEffect(() => {
    if (pathname != "/chat" && !!getCookie("token_id")?.length) {
      dispatch(getProfileApi());
    }
  }, []);
  const handleRemoveClass = () => {
    const removeClass = document.querySelector(".chat-open");
    const removeToggleClass = document.querySelector(".chat-toggle");

    if (removeClass || removeToggleClass) {
      removeClass.classList.remove("chat-open");
      removeToggleClass.classList.remove("chat-toggle");
    }
    dispatch(changeChatDetails({ currenUserId: "" }));
    dispatch(clearProfileResponse());
  };



  const renderSimpleSideBar = () => {
    return (
      <div className="user-widget__links">
        <ul className="list-unstyled">
          <li className="notiifcation" onClick={handleMenuBarToggle}>
            <a
              id="notification"
              href="javascript:;"
              className={`link-color ${
                pathname == "/all-notification" ? "active" : null
              }`}
              onClick={() => goToNotification("Become a seller")}
            >
              <i className="ri-notification-line" /> Notification
              {!!unReadCount && (
                <span className="notification-active">{unReadCount}</span>
              )}
            </a>
          </li>

          <li onClick={handleMenuBarToggle}>
            <NavLink
              to="/newsfeeds"
              className={(isActive) =>
                "link-color" + (isActive ? " active" : "")
              }
            >
              <i className="ri-file-list-3-line" /> Newsfeed
            </NavLink>
          </li>
          <li onClick={handleMenuBarToggle}>
            <NavLink
              to={`/account/${profileData?.user_slug}`}
              className={(isActive) =>
                "link-color" + (isActive ? " active" : "")
              }
            >
              <i className="ri-account-circle-line"></i> My Profile
            </NavLink>
          </li>

          <li onClick={handleMenuBarToggle}>
            <NavLink
              to="/network"
              className={(isActive) =>
                "link-color" + (isActive ? " active" : "")
              }
            >
              <i className="ri-global-line" /> Network
            </NavLink>
          </li>
          {!profileData?.is_individual && (
            <li onClick={handleMenuBarToggle}>
              <NavLink
                to="/members"
                className={(isActive) =>
                  "link-color" + (isActive ? " active" : "")
                }
              >
                <i className="ri-team-fill" />
                Members
              </NavLink>
            </li>
          )}

          <li onClick={handleMenuBarToggle}>
            <NavLink
              to="/chat"
              className={(isActive) =>
                "link-color" + (isActive ? " active" : "")
              }
              onClick={handleRemoveClass}
            >
              <i className="ri-chat-1-line" /> PoolsChat
              {!!unreadUserMessageCount?.length && (
                <span className="notification-active">
                  {unreadUserMessageCount?.length}
                </span>
              )}
            </NavLink>
          </li>

          <li onClick={handleMenuBarToggle}>
            <a
              href="javascript:;"
              onClick={handleBecomeSeller}
              className={`link-color ${
                pathname == "/become-seller-steps" ? "active" : null
              }`}
            >
              <i className="ri-user-line"></i> Become a seller
            </a>
          </li>

          <li onClick={handleMenuBarToggle}>
            <NavLink
              to="/first-time-login"
              className={(isActive) =>
                "link-color" + (isActive ? " active" : "")
              }
            >
              <i className="ri-share-line"></i> Send an Invitation
            </NavLink>
          </li>

          <li onClick={handleMenuBarToggle}>
            <NavLink
              to="/manage-account"
              className={(isActive) =>
                "link-color" + (isActive ? " active" : "")
              }
            >
              <i className="ri-profile-line"></i> Manage My Account
            </NavLink>
          </li>

          <li onClick={handleMenuBarToggle}>
            <a
              href="javascript:;"
              className={`link-color ${
                pathname == "/contact-us" ? "active" : null
              }`}
              onClick={handleContacUs}
            >
              <i className="ri-contacts-book-2-line"></i> Contact Us
            </a>
          </li>

          <li>
            <a
              href="javascript:;"
              className="link-color"
              onClick={handleLogout}
            >
              <i className="ri-logout-circle-r-line"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    );
  };
  const renderAdminSideBar = () => {
    return (
      <div className="user-widget__links">
        <ul className="list-unstyled">
          <li className="notiifcation" onClick={handleMenuBarToggle}>
            <a
              id="notification"
              href="javascript:;"
              className={`link-color ${
                pathname == "/all-notification" ? "active" : null
              }`}
              onClick={() => goToNotification("Become a seller")}
            >
              <i className="ri-notification-line" /> Notification
              {!!unReadCount && (
                <span className="notification-active">{unReadCount}</span>
              )}
            </a>
          </li>

          <li onClick={handleMenuBarToggle}>
            <NavLink
              to="/newsfeeds"
              className={(isActive) =>
                "link-color" + (isActive ? " active" : "")
              }
            >
              <i className="ri-file-list-3-line" /> Newsfeed
            </NavLink>
          </li>
          <li onClick={handleMenuBarToggle}>
            <NavLink
           to={`/account/${profileData?.user_slug}`}
              className={(isActive) =>
                "link-color" + (isActive ? " active" : "")
              }
            >
              <i className="ri-account-circle-line"></i> My Profile
            </NavLink>
          </li>
          <li onClick={handleMenuBarToggle}>
            <NavLink
              to="/manage-account"
              className={(isActive) =>
                "link-color" + (isActive ? " active" : "")
              }
            >
              <i className="ri-profile-line"></i> Manage My Account
            </NavLink>
          </li>
          <li>
            <a
              href="javascript:;"
              className="link-color"
              onClick={handleLogout}
            >
              <i className="ri-logout-circle-r-line"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      {getProfileResponse && (
        <aside className="bg-white br-20 custom-shadow user-widget d-none d-lg-block">
          <div
            className="user-widget__cover"
            style={{
              backgroundImage: `url(${
                !!getProfileResponse?.cover_image
                  ? IMAGE_BASE_URL + getProfileResponse?.cover_image
                  : "/assets/images/pools_cover_image.png"
              })`,
            }}
          ></div>
          <div className="bg-white br-20 user-widget-outer p-3 mx-2">
            <div className="user-widget__header">
              <div
                className={`user-widget__headshot ${
                  check_individual !== "true"
                    ? "user-widget--business"
                    : "user-widget--individual"
                }`}
              >
                <Link   to={`/account/${profileData?.user_slug}`}onClick={handleMenuBarToggle}>
                  {check_individual !== "true" ? (
                    <img
                      src={IMAGE_BASE_URL + getProfileResponse?.business_image}
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/business_logo.svg")
                      }
                    />
                  ) : (
                    <img
                      src={IMAGE_BASE_URL + getProfileResponse?.profile_image}
                      onError={(ev) =>
                        (ev.target.src = "assets/images/member-default.svg")
                      }
                    />
                  )}
                </Link>
              </div>
              <div className="d-flex flex-column pt-4">
                <span className="h6 text-capitalize mb-0 font-weight-semibold">
                  <Link
                    to={`/account/${profileData?.user_slug}`}
                    className="text-dark"
                    onClick={handleMenuBarToggle}
                  >
                    {getProfileResponse?.is_individual == 0
                      ? getProfileResponse?.business_name?.length > 20
                        ? `${getProfileResponse?.business_name.slice(0, 20)}...`
                        : getProfileResponse?.business_name
                      : getProfileResponse?.name?.length > 20
                      ? `${getProfileResponse.name.slice(0, 20)}...`
                      : getProfileResponse.name}
                  </Link>

                  {!!getProfileResponse.seller_status?.length && (
                    <img
                      src={status}
                      className="user-status-icon ml-1"
                      style={{
                        border: `1px solid ${border}`,
                        borderRadius: "50%",
                        padding: 1,
                        width: 15,
                        height: 15,
                      }}
                    />
                  )}
                </span>
                <span className="small text-capitalize user-widget-tag">
                  {getProfileResponse?.is_individual == 0
                    ? getProfileResponse.specialization
                    : getProfileResponse.work}
                </span>
              </div>
            </div>
            {getProfileResponse?.is_supporter
              ? renderAdminSideBar()
              : renderSimpleSideBar()}
          </div>
        </aside>
      )}
    </>
  );
}
