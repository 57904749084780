const FRIEND_LIST_DATA_REQUEST = "FRIEND_LIST_DATA_REQUEST";
const FRIEND_LIST_DATA_SUCCESS = "FRIEND_LIST_DATA_SUCCESS";
const FRIEND_LIST_DATA_FAILURE = "FRIEND_LIST_DATA_FAILURE";

const CLEAR_FRIEND_LIST_RESPONSE = "CLEAR_FRIEND_LIST_RESPONSE";

const CHANGE_FRIEND_LIST_DETAIL = "CHANGE_FRIEND_LIST_DETAIL";
const CHANGE_GROUP_LIST_DETAIL = "CHANGE_GROUP_LIST_DETAIL";
const SEARCH_FRIEND_LIST_DATA_REQUEST = "SEARCH_FRIEND_LIST_DATA_REQUEST";
const SEARCH_FRIEND_LIST_DATA_SUCCESS = "SEARCH_FRIEND_LIST_DATA_SUCCESS";
const SEARCH_FRIEND_LIST_DATA_FAILURE = "SEARCH_FRIEND_LIST_DATA_FAILURE";
const BUSINESS_FRIEND_LIST_REQUEST = "BUSINESS_FRIEND_LIST_REQUEST";
const BUSINESS_FRIEND_LIST_SUCCESS = "BUSINESS_FRIEND_LIST_SUCCESS";
const BUSINESS_FRIEND_LIST_FAILURE = "BUSINESS_FRIEND_LIST_FAILURE";

const INDIVIDUAL_FRIEND_LIST_REQUEST = "INDIVIDUAL_FRIEND_LIST_REQUEST";
const INDIVIDUAL_FRIEND_LIST_SUCCESS = "INDIVIDUAL_FRIEND_LIST_SUCCESS";
const INDIVIDUAL_FRIEND_LIST_FAILURE = "INDIVIDUAL_FRIEND_LIST_FAILURE";

const CLEAR_SEARCH_FRIEND_RESPONSE = "CLEAR_SEARCH_FRIEND_RESPONSE";
const SEARCH_FRIEND_ONCHAT_REQUEST = "SEARCH_FRIEND_ONCHAT_REQUEST";
const SEARCH_FRIEND_ONCHAT_SUCCESS = "SEARCH_FRIEND_ONCHAT_SUCCESS";
const SEARCH_FRIEND_ONCHAT_FAILURE = "SEARCH_FRIEND_ONCHAT_FAILURE";

const SEARCH_MEMBER_REQUEST = "SEARCH_MEMBER_REQUEST";
const SEARCH_MEMBER_SUCCESS = "SEARCH_MEMBER_SUCCESS";
const SEARCH_MEMBER_FAILURE = "SEARCH_MEMBER_FAILURE";

export {
  FRIEND_LIST_DATA_REQUEST,
  FRIEND_LIST_DATA_SUCCESS,
  FRIEND_LIST_DATA_FAILURE,
  CLEAR_FRIEND_LIST_RESPONSE,
  CHANGE_FRIEND_LIST_DETAIL,
  SEARCH_FRIEND_LIST_DATA_REQUEST,
  SEARCH_FRIEND_LIST_DATA_SUCCESS,
  SEARCH_FRIEND_LIST_DATA_FAILURE,
  CLEAR_SEARCH_FRIEND_RESPONSE,
  CHANGE_GROUP_LIST_DETAIL,
  BUSINESS_FRIEND_LIST_FAILURE,
  BUSINESS_FRIEND_LIST_REQUEST,
  BUSINESS_FRIEND_LIST_SUCCESS,
  INDIVIDUAL_FRIEND_LIST_REQUEST,
  INDIVIDUAL_FRIEND_LIST_SUCCESS,
  INDIVIDUAL_FRIEND_LIST_FAILURE,
  SEARCH_FRIEND_ONCHAT_REQUEST,
  SEARCH_FRIEND_ONCHAT_SUCCESS,
  SEARCH_FRIEND_ONCHAT_FAILURE,
  SEARCH_MEMBER_REQUEST,
  SEARCH_MEMBER_SUCCESS,
  SEARCH_MEMBER_FAILURE
};
