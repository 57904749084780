import { Skeleton } from "primereact/skeleton";
import React from "react";
import ServicesSekeleton from "../Skeleton/ServicesSekeleton";

const PopularServices = ({ getPopularServicesList }) => {


  if (getPopularServicesList.length ===0) {
    return <center><ServicesSekeleton/></center>;
  }


  return (
    <div className="row">
      <div className="col-md-12">
      <div className="services-grid">
        {getPopularServicesList.map((item, index) => (
          <div key={index} className="services-grid__items">
            <a href="javascript:void(0)">
              {" "}
              <img src={item.picture} alt="services name" />
            </a>
            <a href="javascript:void(0)">{item.title}</a>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default PopularServices;
