import { GET_API, POST_API } from "../../main/fetch"
import { BUSINESS_FRIEND_LIST_FAILURE, BUSINESS_FRIEND_LIST_REQUEST, BUSINESS_FRIEND_LIST_SUCCESS, FRIEND_LIST_DATA_FAILURE, FRIEND_LIST_DATA_REQUEST, FRIEND_LIST_DATA_SUCCESS, INDIVIDUAL_FRIEND_LIST_FAILURE, INDIVIDUAL_FRIEND_LIST_REQUEST, INDIVIDUAL_FRIEND_LIST_SUCCESS, SEARCH_FRIEND_LIST_DATA_FAILURE, SEARCH_FRIEND_LIST_DATA_REQUEST, SEARCH_FRIEND_LIST_DATA_SUCCESS, SEARCH_FRIEND_ONCHAT_REQUEST, SEARCH_FRIEND_ONCHAT_SUCCESS, SEARCH_MEMBER_FAILURE, SEARCH_MEMBER_REQUEST, SEARCH_MEMBER_SUCCESS } from "../../modules/MyNetwork/MyNetworkConstant"
import { CHAT_LIST_DATA_FAILURE, CHAT_LIST_DATA_REQUEST, CHAT_LIST_DATA_SUCCESS } from "../../modules/PoolsChat/PoolsChatConstant"
import { SEARCH_BLOG_DATA_FAILURE } from "../common/components/LandingPage/LandingContentConstant"
import { BUSINESS_FRIEND_LIST_API, FRIEND_LIST_API, GET_SEARCHED_MEMBERS, INDIVIDUAL_FRIEND_LIST_API, SEARCH_FRIEND_LIST_API } from "../urls"

//this function is used to fetch friend list 
const friendListApi = (body) => {

    return {
        [POST_API]: {
            endpoint: FRIEND_LIST_API,
            types: [FRIEND_LIST_DATA_REQUEST,
                FRIEND_LIST_DATA_SUCCESS,
                FRIEND_LIST_DATA_FAILURE],
            body
        }
    }
}



const businessFriendListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: BUSINESS_FRIEND_LIST_API,
            types: [BUSINESS_FRIEND_LIST_REQUEST,
                BUSINESS_FRIEND_LIST_SUCCESS,
                BUSINESS_FRIEND_LIST_FAILURE],
            body,
            is_form_data: true,
        }
    }
}
const individualFriendListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: INDIVIDUAL_FRIEND_LIST_API,
            types: [INDIVIDUAL_FRIEND_LIST_REQUEST, INDIVIDUAL_FRIEND_LIST_SUCCESS, INDIVIDUAL_FRIEND_LIST_FAILURE],
            body,
            is_form_data: true,
        }
    }
}

const searchFriendListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SEARCH_FRIEND_LIST_API,
            types: [SEARCH_FRIEND_LIST_DATA_REQUEST,
                SEARCH_FRIEND_LIST_DATA_SUCCESS,
                SEARCH_FRIEND_LIST_DATA_FAILURE],
            body
        }
    }
}


const searchMemberListApi = (body)=>
{
    return{
        [POST_API]:{
            endpoint:GET_SEARCHED_MEMBERS,
            types:[SEARCH_MEMBER_REQUEST,SEARCH_MEMBER_SUCCESS,SEARCH_MEMBER_FAILURE],
            body

        }
    }
}

const chatListApi = (body) => {

    return {
        [POST_API]: {
            endpoint: FRIEND_LIST_API,
            types: [CHAT_LIST_DATA_REQUEST,
                CHAT_LIST_DATA_SUCCESS,
                CHAT_LIST_DATA_FAILURE],
            body,

        }
    }

}
const searchFriendonChat = (body) => {
    return {
        [POST_API]: {
            endpoint: SEARCH_FRIEND_LIST_API,
            types: [SEARCH_FRIEND_ONCHAT_REQUEST, SEARCH_FRIEND_ONCHAT_SUCCESS, SEARCH_BLOG_DATA_FAILURE], body
        }
    }
}
export { friendListApi, searchFriendListApi, chatListApi, businessFriendListApi, individualFriendListApi, searchFriendonChat,searchMemberListApi}