import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { searchProfileDataApi } from "../../library/api/SearchApiService";
import {
  scroolTop,
  setCookie,
  getCookie,
} from "../../library/utilities/functions";
import { clearFriendListApiResponse } from "../MyNetwork/MyNetworkAction";
import { clearProfileResponse } from "../SearchProfile/SearchProfileAction";
import ChatList from "./ChatList";
import MessageBox from "./MessageBox";

import {
  allFriendandGroupList,
  changeChatDetails,
  changeFriendListPage,
  clearChatDetails,
  clearPreviousMediaData,
  getAllshareMedia,
} from "./PoolsChatAction";
// --- new merged import ---//
import { ADMIN, SOCKET } from "../../library/urls";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MediaBox from "./MediaBox";
import { getProfileApi } from "../../library/api/AccountApiService";

const PoolsChat = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const poolsChatState = useSelector((state) => state.PoolsChatReducer);
  const searchState = useSelector((state) => state.SearchProfileReducer);
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const [isFriendListUpdate, setIsFriendListUpdate] = useState(false);
  const [mediaPage, setMediaPage] = useState(1);
  const {
    friendListDetail: { friendList, groupList },
  } = networkState;
  const {
    searchProfileApi: { searchProfileResponse },
  } = searchState;
  const {
    chatDetails: { currenUserId },
  } = poolsChatState;

  
  useEffect(() => {
    if (!!currenUserId) {
      const bodyParameter = {
        id: currenUserId,
        type: "chat",
      };

      dispatch(searchProfileDataApi(bodyParameter));
    }
  }, [currenUserId]);

  useEffect(() => {
    scroolTop();
    dispatch(clearFriendListApiResponse());
    dispatch(getProfileApi());
    return () => {
      dispatch(changeFriendListPage({ chatFriendListPage: 1 }));
      dispatch(clearChatDetails());
      dispatch(changeChatDetails({ currenUserId: "" }));
      dispatch(clearProfileResponse());
      dispatch(clearFriendListApiResponse());
    };
  }, []);


  useEffect(() => {
    dispatch(clearPreviousMediaData([]));

    const bodyParameter = {
      user_to_id: profileData.id,
      user_from_id: currenUserId,
    };

    dispatch(getAllshareMedia(bodyParameter, 1));
  }, [currenUserId]);

  useEffect(() => {
    const bodyParameter = {
      user_to_id: profileData.id,
      user_from_id: currenUserId,
    };

    dispatch(getAllshareMedia(bodyParameter, mediaPage));
  }, [mediaPage]);

  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  const socket_id = !!getCookie("socket_id");

  // ----------- GET FRIEND AND GROUP LIST NEW----------- //
  useEffect(() => {
    SOCKET.emit("get_friend_and_group_list", {
      u_id: profileData?.id,
    });

    const handleConnectError = (error) => {
      console.error("Socket connection error:", error);
    };

    SOCKET.on("error", handleConnectError);
  }, [getCookie("socket_id")]);

  useEffect(() => {
    SOCKET.off("get_friend_and_group_list").on(
      "get_friend_and_group_list",
      (chatList) => {

        dispatch(allFriendandGroupList(chatList));
      }
    );
  }, [getCookie("socket_id")]);

  // when user click on contact-us//
  // useEffect(() => {
  //   if (window.location.pathname == "/contact-us") {
  //     SOCKET.off("get_friend_and_group_list").on(
  //       "get_friend_and_group_list",
  //       (chatList) => {

  //         if (!!chatList.length) {
  //           dispatch(allFriendandGroupList(chatList));
  //         }
  //       }
  //     );
  //   }
  // }, [window.location.pathname == "/contact-us"]);

  return (
    <>
      <section className="chat p-1 h-100">
        <div className="container-fluid h-100">
          <div className="row flex-nowrap h-100 custom-shadow">
            <ChatList
              poolsChatState={poolsChatState}
              searchProfileResponse={searchProfileResponse}
              searchState={searchState}
            />
            <MessageBox
              poolsChatState={poolsChatState}
              setMediaPage={setMediaPage}
              mediaPage={mediaPage}
              searchProfileResponse={searchProfileResponse}
            />
          </div>
        </div>
      </section>
    </>
  );
};
export default PoolsChat;
