import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  businessFriendListApi,
  friendListApi,
  individualFriendListApi,
  searchFriendListApi,
} from "../../library/api/MyNetworkApiService";
import Members from ".";
import { Input, ProfileMembers } from "../../library/common/components";
import {
  getCookie,
  replceMultiStringWithSIngle,
  scroolTop,
} from "../../library/utilities/functions";
import {
  changeFriendListDetail,
  clearFriendListApiResponse,
} from "../MyNetwork/MyNetworkAction";

import { css } from "@emotion/react";
import SideBarProfileMenu from "../Home/common/SideBarProfileMenu";

const override = css`
  position: absolute;
  top: 400%;
  right: 50%;
  z-index: 9999;
  display: block;
`;

const BusinessMembers = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [loading, setLoadilng] = useState(false);

  let { pathname } = useLocation();
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  //   const [netWorkTabs, setNetWorkTabs] = useState("All");
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const {
    friendListDetail: { searchFriendName, searchFriendPage },
  } = networkState;

  const [isSearch, setIsSearch] = useState(false);

  //this function is used to submit search input for members in member list
  // const handleSearchSubmit = (e) => {
  //   e.preventDefault();
  //   name = replceMultiStringWithSIngle(searchFriendName);

  //   if (!!searchFriendName.trim().length) {
  //     setIsSearch(true);
  //     dispatch(clearFriendListApiResponse());
  //     dispatch(changeFriendListDetail({ searchFriendPage: 1 }));
  //   } else {
  //     setIsSearch(false);
  //   }
  // };

  // onChange handler for search member input
  // const handleOnChange = (e) => {
  //   var regex = /^[a-zA-Z ]*$/;
  //   if (regex.test(e.target.value)) {
  //     dispatch(changeFriendListDetail({ searchFriendName: e.target.value }));
  //   }
  //   if (e.target.value.trim().length == 0) {
  //     const data = new FormData();
  //     data.append("user_id", profileData.id);
  //     data.append("page", 1);

  //     const body = {
  //       user_id: userId,
  //       page: 1,
  //       support_customer: false,
  //     };
  //     dispatch(friendListApi(body));
  //   }
  // };

  useEffect(() => {
    if (!!searchFriendPage && !!isSearch) {
      setIsSearch(false);
      const bodyParameters = {
        user_id: userId,
        page: searchFriendPage,
        search: !!searchFriendName
          ? replceMultiStringWithSIngle(searchFriendName)
          : "",
        user_type: "",
      };
      dispatch(searchFriendListApi(bodyParameters));
    }
  }, [searchFriendPage, isSearch]);

  useEffect(() => {
    const data = new FormData();
    data.append("user_id", profileData.id);
    data.append("page", 1);
    dispatch(friendListApi(data));
    // dispatch(individualFriendListApi(data));
  }, []);

  useEffect(() => {
    if (pathname != "members") {
      dispatch(changeFriendListDetail({ searchFriendName: "" }));
    }
    scroolTop();
  }, [pathname]);

  return (
    <>
      <section className="network spacer bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <SideBarProfileMenu />
            </div>
            <div className="col-lg-9 col-sm-12 p-0">
              <div>
                <ProfileMembers />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default BusinessMembers;
