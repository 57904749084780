import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { IMAGE_BASE_URL } from "../../../../urls";
import "aos/dist/aos.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
export default function Feature() {
  const landingPageContentData = useSelector(
    (state) => state.LandingPageReducer.landingPageContent
  );

  const { getLandingPageData } = landingPageContentData;

  return (
    <div>
      <section className="spacer feaures section-normal">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6 col-md-10 mx-auto text-center">
              <span className="section__label">Our Features</span>
              <h2 className="section__subheading mb-0">
                {getLandingPageData?.features_description_heading}
              </h2>
            </div>
          </div>

          <div className="feaures mt-5">
            {!!getLandingPageData &&
              getLandingPageData?.features_description?.map((item, index) => (
                <div
                  data-aos="fade-up"
                  className="row align-items-start align-items-lg-center"
                  key={index}
                >
                  <div className="col-md-6">
                    <svg
                      viewBox="0 0 500 500"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="250" cy="250" r="250" fill="#EEF8F5"></circle>
                      <image href={IMAGE_BASE_URL + item.image} width="100%" />
                    </svg>
                  </div>

                  <div className="col-md-6 mt-4 mt-md-0">
                    <h2 className="section__subheading mb-0">{item?.title}</h2>
                    <div className="section__content my-3">
                      {parse(item?.description)}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="row mt-5">
            <div className="col-md-12 text-center">
              <Link to="/signup" className="btn btn-primary">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
