import {
  GET_ABOUTUS_PAGE_REQUEST,
  GET_ABOUTUS_PAGE_SUCCESS,
  GET_ABOUTUS_PAGE_FAILURE,
} from "./LandingContentConstant";

// TO STORE RESPONSE OF ABOUT US PAGE API
const initialState = {
  aboutUsPageContent: {
    getAboutUsContentLoading: false,
    getAboutUsContentStatus: "",
    getAboutUsContentSuccess: "",
    getAboutUsContentMessage: "",
    getAboutUsContentData: {},
  },
};

export const aboutUsPageReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_ABOUTUS_PAGE_REQUEST:
      return {
        ...state,
        ...{
          aboutUsPageContent: {
            ...state.aboutUsPageContent,
            ...{ getAboutUsContentLoading: true },
          },
        },
      };
    case GET_ABOUTUS_PAGE_SUCCESS:
      let getAboutUsContentSuccess = {
        getAboutUsContentLoading: false,
        getAboutUsContentStatus: 200,
        getAboutUsContentSuccess: action.response.data.success,
        getAboutUsContentMessage: action.response.data.message,
        getAboutUsContentData: action?.response?.data,
      };
      return {
        ...state,
        ...{
          aboutUsPageContent: {
            ...state.aboutUsPageContent,
            ...getAboutUsContentSuccess,
          },
        },
      };
    case GET_ABOUTUS_PAGE_FAILURE:
      let getAboutUsContentFailure = {
        getAboutUsContentLoading: false,
        getAboutUsContentStatus: action.status,
        getAboutUsContentSuccess: false,
        getAboutUsContentMessage: action.response.data.message,
        getAboutUsContentData: {},
      };
      return {
        ...state,
        ...{
          aboutUsPageContent: {
            ...state.aboutUsPageContent,
            ...getAboutUsContentFailure,
          },
        },
      };

    default:
      return state;
  }
};
