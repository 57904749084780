import {
  CLEAR_SEARCH_PROVIDER_LIST,
  GET_FIND_SERVICE_PROVIDER_FAILURE,
  GET_FIND_SERVICE_PROVIDER_REQUEST,
  GET_FIND_SERVICE_PROVIDER_SUCCESS,
  GET_POPULAR_SERVICE_FAILURE,
  GET_POPULAR_SERVICE_REQUEST,
  GET_POPULAR_SERVICE_SUCCESS,
  GET_SEARCH_PROVIDER_FAILURE,
  GET_SEARCH_PROVIDER_REQUEST,
  GET_SEARCH_PROVIDER_SUCCESS,
  GET_SERVICES_PROVIDER_FAILURE,
  GET_SERVICES_PROVIDER_REQUEST,
  GET_SERVICES_PROVIDER_SUCCESS,
} from "../components/LandingPage/LandingContentConstant";

const initialState = {
  getServiceProviderContent: {
    getServiceProviderLoading: false,
    getServiceProviderStatus: "",
    getServiceProviderSuccess: "",
    getServiceProviderMessage: "",
    getServiceProviderData: {},
  },

  getPopularServicesData: {
    getPopularServicesLoading: false,
    getPopularServicesStatus: "",
    getPopularServicesSuccess: "",
    getPopularServicesMessage: "",
    getPopularServicesList: [],
  },

  getServicesProviderData: {
    getServicesProviderLoading: false,
    getServicesProviderStatus: "",
    getServicesProviderSuccess: "",
    getServicesProviderMessage: "",
    getServicesProviderList: [],
  },

  getSearchProviderData: {
    getSearchProviderLoading: false,
    getSearchProviderStatus: "",
    getSearchProviderSuccess: "",
    getSearchProviderMessage: "",
    getSearchProviderList: [],
  },
};

export const serviceProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POPULAR_SERVICE_REQUEST:
      return {
        ...state,
        ...{
          getPopularServicesData: {
            ...state.getPopularServicesData,
            ...{ getPopularServicesLoading: true },
          },
        },
      };

    case GET_POPULAR_SERVICE_SUCCESS:
      let getPopularServicesDataSuccess = {
        getPopularServicesLoading: false,
        getPopularServicesStatus: action.response.status,
        getPopularServicesSuccess: true,
        getPopularServicesMessage: action.response.data.message,
        getPopularServicesList: action.response.data
          ? action.response.data?.data
          : [],
      };
      return {
        ...state,
        ...{
          getPopularServicesData: {
            ...state.getPopularServicesData,
            ...getPopularServicesDataSuccess,
          },
        },
      };
    case GET_POPULAR_SERVICE_FAILURE:
      let getPopularServicesDataFailure = {
        getPopularServicesLoading: false,
        getPopularServicesStatus: action.response.status,
        getPopularServicesSuccess: false,
        getPopularServicesMessage: action.response.message,
        getPopularServicesList: [],
      };
      return {
        ...state,
        ...{
          getPopularServicesData: {
            ...state.getPopularServicesData,
            ...getPopularServicesDataFailure,
          },
        },
      };
    case GET_SERVICES_PROVIDER_REQUEST:
      return {
        ...state,
        ...{
          getServicesProviderData: {
            ...state.getServicesProviderData,
            ...{ getServicesProviderLoading: true },
          },
        },
      };
    case GET_SERVICES_PROVIDER_SUCCESS:
      let getServiceProviderSuccess = {
        getServicesProviderLoading: false,
        getServicesProviderStatus: action.response.status,
        getServicesProviderSuccess: true,
        getServicesProviderMessage: action.response.data.message,
        getServicesProviderList: action.response.data
          ? [
              ...state.getServicesProviderData.getServicesProviderList,
              ...action.response.data.list,
            ]
          : [],
      };

      return {
        ...state,
        ...{
          getServicesProviderData: {
            ...state.getServicesProviderData,
            ...getServiceProviderSuccess,
          },
        },
      };

    case GET_POPULAR_SERVICE_FAILURE:
      let getServiceProviderFailure = {
        getServicesProviderLoading: false,
        getServicesProviderStatus: action.response.status,
        getServicesProviderSuccess: false,
        getServicesProviderMessage: action.response.message,
        getServicesProviderList: [],
      };
      return {
        ...state,
        ...{
          getServicesProviderData: {
            ...state.getServicesProviderData,
            ...getServiceProviderFailure,
          },
        },
      };

    case GET_SEARCH_PROVIDER_REQUEST:
      return {
        ...state,
        ...{
          getSearchProviderData: {
            ...state.getSearchProviderData,
            ...{ getSearchProviderLoading: true },
          },
        },
      };

    case GET_SEARCH_PROVIDER_SUCCESS:
      let getSearchProviderSuccess = {
        getSearchProviderLoading: false,
        getSearchProviderStatus: action.response.state,
        getSearchProviderSuccess: true,
        getSearchProviderMessage: action.response.data.message,
        getSearchProviderList: action.response.data
          ? action.response.data.list
          : [],
      };
      return {
        ...state,
        ...{
          getSearchProviderData: {
            ...state.getSearchProviderData,
            ...getSearchProviderSuccess,
          },
        },
      };

    case GET_SEARCH_PROVIDER_FAILURE:
      let getSearchProviderFailure = {
        getSearchProviderLoading: false,
        getSearchProviderStatus: action.response.state,
        getSearchProviderSuccess: false,
        getSearchProviderMessage: action.response.data.message,
        getSearchProviderList: [],
      };
      return {
        ...state,
        ...{
          getSearchProviderData: {
            ...state.getSearchProviderData,
            getSearchProviderFailure,
          },
        },
      };

    case CLEAR_SEARCH_PROVIDER_LIST:
      let clearSearchProviderState = {
        getSearchProviderLoading: false,
        getSearchProviderStatus: "",
        getSearchProviderSuccess: "",
        getSearchProviderMessage: "",
        getSearchProviderList: [],
      };
      return {
        ...state,
        ...{
          getSearchProviderData: {
            ...state.getSearchProviderData,
            ...clearSearchProviderState,
          },
        },
      };
    case GET_FIND_SERVICE_PROVIDER_REQUEST:
      return {
        ...state,
        ...{
          getServiceProviderContent: {
            ...state.getServiceProviderContent,
            ...{ getServiceProviderLoading: true },
          },
        },
      };

    case GET_FIND_SERVICE_PROVIDER_SUCCESS:
      let getServiceContentSuccess = {
        getServiceProviderLoading: false,
        getServiceProviderStatus: action.response.data.status,
        getServiceProviderSuccess: true,
        getServiceProviderMessage: action.response.data.message,
        getServiceProviderData: action.response.data
          ? action.response.data.data
          : {},
      };

      return {
        ...state,

        ...{
          getServiceProviderContent: {
            ...state.getServiceProviderContent,
            ...getServiceContentSuccess,
          },
        },
      };


      case GET_FIND_SERVICE_PROVIDER_FAILURE:
        let getServiceContentFailure = {
          getServiceProviderLoading: false,
          getServiceProviderStatus: action.response.state,
          getServiceProviderSuccess: false,
          getServiceProviderMessage: action.response.data.message,
          getServiceProviderData: {},
        };
  
        return {
          ...state,
  
          ...{
            getServiceProviderContent: {
              ...state.getServiceProviderContent,
              ...getServiceContentFailure,
            },
          },
        };

    default:
      return state;
  }
};
