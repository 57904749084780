import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import SearchListMembers from "../../../../modules/SearchMembersList";
import { useHistory, useParams } from "react-router";
import AddMembersModal from "./AddMembersModal";
import axios from "axios";
import {
  MEMBERS,
  IMAGE_BASE_URL,
  MEMBERS_REMOVE,
  ADD_MEMBERS,
} from "../../../urls";
import {
  getCookie,
  replceMultiStringWithSIngle,
  setCookie,
} from "../../../utilities/functions";
import { useToasts } from "react-toast-notifications";
import { changeChatDetails } from "../../../../modules/PoolsChat/PoolsChatAction";
import Input from "../Input/Input";
import { searchMemberListApi } from "../../../api/MyNetworkApiService";
import RequestModal from "../../../../modules/PoolsChat/RequestModal";
import { ClipLoader } from "react-spinners";
let selectedCheckMembers = [];
const ProfileMembers = ({ addedMembers, userId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [allMembersData, setAllMembersData] = useState([]);
  const [showDeletedArray, setShowDeletedArray] = useState([]);
  const [isDropDownList, setIsDropDownList] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeItemName, setActiveItemName] = useState("");
  const [showActionModal,setShowActionModal]=useState(false)
  const [activeItemId, setActiveItemId] = useState(null);
  const check_individual = getCookie("individual");
  const [memberListData, setMemberListData] = useState([]);
  const [isAddMember, setIsAddMember] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [isSearchMember, setIsSearchMember] = useState(false);
  const [searchedResponse, setSearchedresponse] = useState([]);
  const [searchMemberName, setSearchMemberName] = useState("");
  const [memberLoading, setMemberLoading] = useState(false);
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const { pathname } = useLocation();
  const membersOperationEl = useRef();
  const labelEl = useRef();
  const [editAppointent, setEditAppointment] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [requestData, setRequestData] = useState({
    date: new Date(),
    request_nature: "",
    description: "",
    reschedule: false,
    iscancel: false,
    title: "",
  });

  const requestModalRef = useRef();
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const {
    searchMemberData: { searchMemberList },
  } = networkState;

  if (history.action === "POP" && pathname !== "/members") {
    history.push(`/account/${profileData?.user_slug}`);
    // history.push("/account");
  }

  const handleSearchMemberModal = (e) => {
    membersOperationEl.current.click();
  };
  const openProfileBoxMembers = (item) => {
    if (profileData.id == item.user_id) {
      history.push("/account");
    } else {
      history.push(`/search-profile/${item.user_id}`);
      // history.push(`/search-profile/${item.user_id}?individual=true`);
    }
  };
  const openProfileBoxMembersOthers = (item) => {
    if (profileData.id == item.user_id) {
      history.push("/account");
    } else {
      // history.push(`/search-profile/${item.user_id}?individual=true`);
      history.push(`/search-profile/${item.user_id}`);
    }
  };
  const handleChatUser = (item) => {
    history.push("/chat");
    setCookie("chatType", "user");
    dispatch(changeChatDetails({ currenUserId: item.user_id }));
  };

  // open request modal //

  const handleOpenRequestModal = (e, value, details) => {
    if (value === "edit") {
      setEditAppointment(true);
      setRequestData({
        ...requestData,
        iscancel: false,
        request_nature: "",
        description: "",
      });
    } else if (value === "no-edit") {
      setEditAppointment(false);

      setUserDetails(details);
      setRequestData({
        date: new Date(),
        request_nature: "",
        description: "",
        reschedule: false,
        iscancel: false,
        title: "",
      });
    }

    // requestModalRef.current.click();
  };

  // fetching memeber //

  useEffect(() => {

    setMemberLoading(true);
    let config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },

    };
    axios(MEMBERS + "?user_id=" + profileData.id, config)
      .then((response) => {
        const MembersData = response.data.list;
        setMemberData(MembersData);
        setMemberLoading(false);
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
        setMemberLoading(false);
      });

  }, []);

  useEffect(() => {
    if (!!addedMembers?.length) {
      setAllMembersData(addedMembers);
    }
  }, [addedMembers]);

  // ---unfriend members---//

  const handleUnFriendMembers = (item) => {
    const data = new FormData();
    data.append("id", item);
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };
    axios(MEMBERS_REMOVE, config).then((response) => {
      const newList = memberListData.filter(
        (member) => member.user_id !== item
      );
      setMemberListData(newList);
      setShowDeleteModal(false);
    });
  };

  useEffect(() => {
    setMemberListData(memberData);
  }, [memberData]);

  const handleSelectdMembersItem = () => {
    membersOperationEl.current.click();
    setShowCheckBox(true);
  };

  // check members---//
  const handleCheckMembers = (e, id) => {
    const target = e.target;
    var value = target.value;
    target.checked = target.checked;
    if (target.checked) {
      let selectedArray = selectedCheckMembers;
      selectedArray.push(value);
    } else {
      let selectedArray = selectedCheckMembers;
      var index = selectedArray.indexOf(value);
      selectedArray.splice(index, 1);
    }
    setShowDeletedArray(selectedCheckMembers);
  };

  //--- delete members function---//
  const handleDeleteMembers = () => {
    if (showDeletedArray.length) {
      const data = new FormData();
      showDeletedArray.forEach((item) => data.append("id[]", item));
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };

      axios(MEMBERS_REMOVE, config).then((response) => {
        const newListData = memberListData.filter((membersData) => {
          !showDeletedArray.some(({ id }) => membersData.user_id === id);
        });
        setMemberListData(newListData);
      });
    }
  };

  const openDropDownList = (id) => {
    setIsDropDownList(isDropDownList === id ? undefined : id);
  };
  const showTriggerDropDown = () => {
    membersOperationEl.current.click();
    $("#members-modal").modal();
  };

  const openDeletePopUp = (item) => {
    setShowDeleteModal(true);
    setActiveItemName(item.user_name);
    setActiveItemId(item.user_id);
  };

  const closeDeletePopUp = () => {
    setShowDeleteModal(false);
    setIsDropDownList();
  };

  useEffect(() => {
    if (!!addedMembers?.length) {
      setAllMembersData(addedMembers);
    }
  }, [addedMembers]);

  let membersList = [];

  membersList = allMembersData;

  let editMemberArray;

  // search memebers -- //

  const handleOnChange = (e) => {
    const { value, name } = e.target;

    var regex = /^[a-zA-Z ]*$/;
    if (regex.test(value)) {
      setSearchMemberName(value.trim());
    }
  };

  useEffect(() => {
    if (searchMemberList.length > 0 && isSearchMember === true) {
      setMemberData(searchMemberList);
    } else {
      setMemberData([]);
    }
  }, [searchMemberList]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (searchMemberName?.length > 0 && pathname == "/members") {
      setIsSearchMember(true);
      const bodyParameter = {
        search: !!searchMemberName
          ? replceMultiStringWithSIngle(searchMemberName)
          : "",
      };
      dispatch(searchMemberListApi(bodyParameter))
        .then((response) => {
          if (response.response.data?.list?.length > 0 && !!isSearchMember) {
            setSearchMemberName("");
          } else if (
            response.response.data?.list?.length === 0 &&
            isSearchMember === true
          ) {
            setSearchedresponse([]);
            setSearchMemberName("");
          }
        })
        .catch((err) => {
          addToast(err, {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
          setSearchMemberName("");
        });
    } else {
      setIsSearchMember(false);
    }
  };

  useEffect(() => {
    if (pathname !== "/members") {
      setIsSearchMember(false);
    }
  }, [pathname]);

  // send request to members
  const handleMemberRequestSend = (item, i) => {
    allMembersData[i].isSendReq = true;
    const data = new FormData();
    data.append("user_id[]", item);

    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };
    axios(ADD_MEMBERS, config).then((response) => {
      if (response.data.status === 200) {
        editMemberArray = membersList.map((itemB, index) => {
          if (itemB.user_id === item) {
            return { ...itemB, member_request_sent: true };
          } else {
            return {
              ...itemB,
            };
          }
        });
        setAllMembersData(editMemberArray);

        addToast(response.data.message, {
          appearance: "success",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      } else {
        addToast(response.data.message, {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      }
    });
  };

  let member = "MEMBER";

  useEffect(() => {
    
    $(document).on("click", function (e) {
 
      var container = $(".profile-action");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(".profile-action ul").removeClass("profile-action-active");
      }
    });

    $(".profile-action .ri-more-2-fill").on("click", function (event) {
      event.stopPropagation();
      if (
        $(this)
          .parents()
          .siblings()
          .find("ul")
          .hasClass("profile-action-active")
      ) {
        $(".profile-action ul").removeClass("profile-action-active");
      }
      if ($(this).next("ul").hasClass("profile-action-active")) {
        $(this).next("ul").removeClass("profile-action-active");
      } else {
        $(this).next("ul").addClass("profile-action-active");
      }
    });
  }, []);



if(memberLoading === true)
{
  return (
    <center>
      
      <ClipLoader  
      // color={"#2F2529"}
      //     loading={memberLoading}
      //     size={80}
      //     css={{
      //       position: "absolute",
      //       top: "45%",
      //       left: "47%",
      //       transform: "translate(-50%,-50%)",
      //     }}
          
          ></ClipLoader>
      </center>
  )
}
  return (
    <>
      <section className="gallery">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="gallery__block network bg-white p-3 br-20 custom-shadow">
                <div className="gallery__block__heading d-flex align-items-center mb-4">
                  <h1 className="h4 mb-0">
                    {`${
                      memberData?.length > 0
                        ? member + "(" + memberData.length + ")"
                        : member + "(S)"
                    }`}
                  </h1>

                  {pathname == "/members" && (
                    <div
                      className="serchMember"
                      style={{ marginLeft: "200px" }}
                    >
                      <form
                        className="search-form network-fliter"
                        onSubmit={handleSearchSubmit}
                        autoComplete="off"
                      >
                        <div className="form-group mb-0 position-relative">
                          <Input
                            type="text"
                            name="searchMemberName"
                            onChange={(e) => handleOnChange(e)}
                            placeholder="Search Members"
                            className="form-control bg-white mt-0"
                            value={searchMemberName}
                          />
                          <i
                            className="ri-search-line"
                            onClick={handleSearchSubmit}
                          ></i>
                        </div>
                      </form>
                    </div>
                  )}
                  {(pathname == `/account/${profileData?.user_slug}/members` ||
                    pathname == "/members") && (
                    <div className="profile-action ml-auto mt-0">
                      <i
                      onClick={()=>setShowActionModal(!showActionModal)}
                        ref={membersOperationEl}
                        className="ri-more-2-fill"
                      ></i>
                      
                      
                      <ul className={`important-list-drop ${!!showActionModal && "profile-action-active"} `} >
                        <li>
                          <a
                            data-toggle="modal"
                            data-target="#members-modal"
                            href="javascript:;"
                            onClick={(e) => handleSearchMemberModal(e)}
                            className="btn_fix"
                          >
                            <i className="ri-add-circle-line"></i> Add a member
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                {(pathname == `/account/${profileData?.user_slug}/members` ||
                  pathname == "/members") && (
                  <div className="row">
                    {memberData && memberData.length > 0 ? (
                      <>
                        {memberData.map((item, index) => {
                          return (
                            <div
                              className="col-md-3 col-sm-6 col-50 d-flex"
                              key={item.id}
                            >
                              <div className="network__box network__box--outline bg-white pt-4 br-20 text-center position-relative w-100">
                                {showCheckBox && (
                                  <div>
                                    <input
                                      type="checkbox"
                                      onClick={(e) =>
                                        handleCheckMembers(e, item.id)
                                      }
                                      value={item.id}
                                      style={{ width: "30px" }}
                                      name="upload-members"
                                      id={`members-${item.id}`}
                                    />

                                    <label
                                      id="unCheck"
                                      ref={labelEl}
                                      htmlFor={`members-${item.id}`}
                                    ></label>
                                  </div>
                                )}
                                <div
                                  className="network__box__img normal-user mx-auto mt-3"
                                  onClick={() => openProfileBoxMembers(item)}
                                >
                                  <a href="javascript:;">
                                    {" "}
                                    <img
                                      onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/member-default.svg")
                                      }
                                      src={
                                        !!item.user_profile
                                          ? IMAGE_BASE_URL + item.user_profile
                                          : "/assets/images/member-default.svg"
                                      }
                                      alt="user"
                                    />
                                  </a>
                                </div>
                                <div className="network__box__info mt-4">
                                  <a
                                    onClick={() => openProfileBoxMembers(item)}
                                    href="javascript:;"
                                    className="link-color text-capitalize"
                                  >
                                    <h5 className="text-capitalize h6">
                                      {item.user_name}
                                    </h5>
                                  </a>
                                  <div className="work-spacer">
                                    <p className="mb-0 text-capitalize">
                                      {item.work}
                                    </p>
                                  </div>
                                </div>
                                <div className="network__action d-flex align-items-center justify-content-end mt-3 call-info">
                                  <>
                                    <a
                                      ref={requestModalRef}
                                      href="javascript:;"
                                      onClick={(e) =>
                                        handleOpenRequestModal(
                                          e,
                                          "no-edit",
                                          item
                                        )
                                      }
                                      data-toggle="modal"
                                      data-placement="top"
                                      title="Request"
                                      data-target="#request-modal"
                                      className="link-color network-options btn-request"
                                    >
                                      <i
                                        className="ri-calendar-2-line align-middle text-white"
                                        alt="request"
                                      />
                                    </a>

                                    {!item.phone_visibility && (
                                      <a
                                        href={`tel:${
                                          item.country_code + item.phone
                                        }`}
                                        className="network-options btn-blue call-info"
                                      >
                                        <i className="ri-phone-fill text-white"></i>
                                        <div className="call-info-modal">
                                          <span className="d-block border-0">
                                            <i className="ri-phone-fill align-middle text-primary"></i>{" "}
                                            Phone Number
                                          </span>
                                        </div>
                                      </a>
                                    )}
                                    <a
                                      href="javascript:;"
                                      onClick={() => handleChatUser(item)}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="PoolsChat"
                                      className="link-color network__action__icon chat-icon network-options"
                                    >
                                      <img
                                        src="/assets/images/chat.png"
                                        alt="chat"
                                      />
                                    </a>
                                  </>
                                  <div
                                    className="profile-action mt-0 w-auto"
                                    key={item.id}
                                  >
                                    <i
                                      className="ri-more-2-fill"
                                      onClick={() =>
                                        openDropDownList(item.user_id)
                                      }
                                    ></i>
                                    {isDropDownList === item.user_id && (
                                      <ul
                                        className="important-list-drop-member"
                                        style={{
                                          visibility: "visible",
                                          opacity: "1",
                                        }}
                                      >
                                        <li>
                                          <a
                                            href="javascript:;"
                                            onClick={() =>
                                              openDeletePopUp(item)
                                            }
                                          >
                                            <i className="ri-user-unfollow-fill"></i>{" "}
                                            Delete Member
                                          </a>
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Modal
                                show={showDeleteModal}
                                onHide={closeDeletePopUp}
                                centered
                              >
                                <div className="modal-body text-center">
                                  <button
                                    onClick={closeDeletePopUp}
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <h5 className="font-weight-bold mt-4">
                                    Would you also like to remove{" "}
                                    <strong style={{ color: "red" }}>
                                      {activeItemName}
                                    </strong>{" "}
                                    from your Network?
                                  </h5>
                                  <p>
                                    <strong style={{ color: "red" }}>
                                      {activeItemName}
                                    </strong>{" "}
                                    Will be removed from your member and network
                                    list.
                                  </p>
                                  <div className="alert-btn mt-3 mb-4">
                                    <a
                                      href="javascript:;"
                                      onClick={() =>
                                        handleUnFriendMembers(activeItemId)
                                      }
                                      className="btn btn-danger btn-sm mr-3"
                                    >
                                      Yes
                                    </a>
                                    <a
                                      href="javascript:;"
                                      onClick={closeDeletePopUp}
                                      className="btn btn-blue btn-sm"
                                    >
                                      No
                                    </a>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {isSearchMember === false ? (
                          <>
                            <div className="row justify-content-center align-items-center">
                              <div className="col-sm-5 text-center mb-4 mb-sm-0">
                                <img
                                  src="/assets/images/no-memeber.svg"
                                  alt="user"
                                />
                              </div>
                              <div className="col-sm-5">
                                <div className="no-member br-20">
                                  <h5 className="mt-2 mb-3">
                                    Currently, there is no member in your team.{" "}
                                    <a
                                      href="javascript:;"
                                      className="member-add-trigger text-secondary"
                                      onClick={showTriggerDropDown}
                                    >
                                      Click here
                                    </a>{" "}
                                    to add team members.
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row justify-content-center align-items-center">
                              <div className="col">
                                <h3>No member found</h3>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}

                {/* start the other members List */}
                {pathname !== `/account/${profileData?.user_slug}/members` &&
                  pathname !== "/members" && (
                    <div className="row">
                      {allMembersData && allMembersData.length > 0 ? (
                        <>
                          {allMembersData.map((item, index) => {
                            return (
                              <div
                                className="col-md-3 col-sm-6 col-50"
                                key={item.id}
                              >
                                <div className="network__box network__box--outline bg-white pt-4 br-20 text-center position-relative">
                                  {showCheckBox && (
                                    <div>
                                      <input
                                        type="checkbox"
                                        onClick={(e) =>
                                          handleCheckMembers(e, item.id)
                                        }
                                        value={item.id}
                                        style={{ width: "30px" }}
                                        name="upload-members"
                                        id={`members-${item.id}`}
                                      />
                                      <label
                                        id="unCheck"
                                        ref={labelEl}
                                        htmlFor={`members-${item.id}`}
                                      ></label>
                                    </div>
                                  )}

                                  <div
                                    className="network__box__img normal-user mx-auto mt-3"
                                    onClick={() =>
                                      openProfileBoxMembersOthers(item)
                                    }
                                  >
                                    <a href="javascript:;">
                                      {" "}
                                      <img
                                        onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/member-default.svg")
                                        }
                                        src={
                                          !!item.user_profile
                                            ? IMAGE_BASE_URL + item.user_profile
                                            : "assets/images/member-default.svg"
                                        }
                                        alt="user"
                                      />
                                    </a>
                                  </div>

                                  <div className="network__box__info mt-4">
                                    <a
                                      onClick={() =>
                                        openProfileBoxMembersOthers(item)
                                      }
                                      href="javascript:;"
                                      className="link-color text-capitalize"
                                    >
                                      <h5 className="text-capitalize h6">
                                        {item.user_name}
                                      </h5>
                                    </a>

                                    <p className="mb-0 text-capitalize">
                                      {item.work}
                                    </p>
                                  </div>

                                  {check_individual == false ? (
                                    <div className="network__action d-flex align-items-center justify-content-center mt-3 pt-2">
                                      {item.member_check === false ? (
                                        <>
                                          {item.member_request_sent ===
                                          false ? (
                                            <button
                                              className="btn btn-blue btn-sm"
                                              onClick={() =>
                                                handleMemberRequestSend(
                                                  item.user_id,
                                                  index
                                                )
                                              }
                                              disabled={item.isSendReq}
                                            >
                                              <i className="ri-user-follow-line"></i>{" "}
                                              Add Member
                                            </button>
                                          ) : (
                                            <a className="btn bg-success btn-sm">
                                              <i className="ri-user-follow-line"></i>{" "}
                                              Request sent
                                            </a>
                                          )}
                                        </>
                                      ) : (
                                        <a className="btn bg-success btn-sm">
                                          <i className="ri-user-follow-line"></i>{" "}
                                          Already your member
                                        </a>
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <Modal
                                  show={showDeleteModal}
                                  onHide={closeDeletePopUp}
                                  centered
                                >
                                  <div className="modal-body text-center">
                                    <button
                                      onClick={closeDeletePopUp}
                                      type="button"
                                      className="close"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                    <h5 className="font-weight-bold mt-4">
                                      Would you also like to remove{" "}
                                      <strong style={{ color: "red" }}>
                                        {activeItemName}
                                      </strong>{" "}
                                      from your Network?
                                    </h5>

                                    <p>
                                      <strong style={{ color: "red" }}>
                                        {activeItemName}
                                      </strong>{" "}
                                      Will be removed from your member and
                                      network list.
                                    </p>
                                    <div className="alert-btn mt-3 mb-4">
                                      <a
                                        href="javascript:;"
                                        onClick={() =>
                                          handleUnFriendMembers(activeItemId)
                                        }
                                        className="btn btn-danger btn-sm mr-3"
                                      >
                                        yes
                                      </a>
                                      <a
                                        href="javascript:;"
                                        onClick={closeDeletePopUp}
                                        className="btn btn-blue btn-sm"
                                      >
                                        No
                                      </a>
                                    </div>
                                  </div>
                                </Modal>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <div className="row justify-content-center align-items-center">
                            <div className="col-sm-5 text-center mb-4 mb-sm-0">
                              <img
                                src="/assets/images/no-memeber.svg"
                                alt="user"
                              />
                            </div>
                            <div className="col-sm-5">
                              <div className="no-member br-20">
                                <h5 className="mt-2 mb-3">
                                  Currently, there is no member
                                </h5>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
              </div>

              {/* Start */}

              {!!showDeletedArray && showDeletedArray.length > 0 && (
                <button
                  className="btn btn-lg btn-secondary"
                  onClick={handleDeleteMembers}
                >
                  {" "}
                  Delete Selected{" "}
                </button>
              )}

              {/* End */}
            </div>
          </div>
        </div>
      </section>
      <AddMembersModal />
      <RequestModal
        editAppointent={editAppointent}
        setEditAppointment={setEditAppointment}
        messageDetails={userDetails}
        // addToast={addToast}
        handleOpenRequestModal={handleOpenRequestModal}
        // dispatch={dispatch}
        requestData={requestData}
        setRequestData={setRequestData}
      />
    </>
  );
};

export default ProfileMembers;
