//this function is used to implementing validation for forget password fileds
const forgetValidation = (validation, forgetProps) => {
  validation.is_valid_email.status = forgetProps.email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
    ? true
    : false;
  return validation;
};

export { forgetValidation };
