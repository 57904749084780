import { replceMultiStringWithSIngle } from "../../library/utilities/functions";

//this function is used to implementing validation for login form
const loginFormValidation = (validation, loginProps) => {
    const { name } = loginProps;
    let Name = replceMultiStringWithSIngle(name);
    validation.is_valid_name.status = name.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
        ? true
        : false;
    return validation;
};
const passwordValidation = (validation, loginProps) => {
    const { password } = loginProps;
    let Name = replceMultiStringWithSIngle(password);

    validation.is_valid_password.status = "" + password.length > 0 ? true : false;
    return validation;
};
const onChangeLoginValidation = (validation, loginProps) => {
    const { name } = loginProps;
    let Name = replceMultiStringWithSIngle(name);
    validation.is_valid_name.status = name.length > 0
        ? true
        : false;

    return validation;
};

export { loginFormValidation, passwordValidation, onChangeLoginValidation };
