import { CHANGE_FRIEND_LIST_DETAIL, CLEAR_FRIEND_LIST_RESPONSE, CLEAR_SEARCH_FRIEND_RESPONSE } from "./MyNetworkConstant"

//this function is used to clear friend list api response 
const clearFriendListApiResponse = (newState) => {

    return{type:CLEAR_FRIEND_LIST_RESPONSE , newState}
}
//this function is used to change details for the friend list
const changeFriendListDetail = (newState) => {

    return{type:CHANGE_FRIEND_LIST_DETAIL , newState}
}

//this function is used to clear search friend api response
const clearSearchFriendResponse = (newState) => {
    return{type:CLEAR_SEARCH_FRIEND_RESPONSE , newState}
}
export {clearFriendListApiResponse ,changeFriendListDetail ,clearSearchFriendResponse}