const ACCEPT_REQUEST_DATA_REQUEST = "ACCEPT_REQUEST_DATA_REQUEST";
const ACCEPT_REQUEST_DATA_SUCCESS = "ACCEPT_REQUEST_DATA_SUCCESS";
const ACCEPT_REQUEST_DATA_FAILURE = "ACCEPT_REQUEST_DATA_FAILURE";

const CLEAR_ACCEPT_REQUEST_RESPONSE = "CLEAR_ACCEPT_REQUEST_RESPONSE";

const DECLINE_REQUEST_DATA_REQUEST = "DECLINE_REQUEST_DATA_REQUEST";
const DECLINE_REQUEST_DATA_SUCCESS = "DECLINE_REQUEST_DATA_SUCCESS";
const DECLINE_REQUEST_DATA_FAILURE = "DECLINE_REQUEST_DATA_FAILURE";

const CLEAR_DECLINE_REQUEST_RESPONSE = "CLEAR_DECLINE_REQUEST_RESPONSE";

const ALL_NOTIFICATION_DATA_REQUEST = "ALL_NOTIFICATION_DATA_REQUEST";
const ALL_NOTIFICATION_DATA_SUCCESS = "ALL_NOTIFICATION_DATA_SUCCESS";
const ALL_NOTIFICATION_DATA_FAILURE = "ALL_NOTIFICATION_DATA_FAILURE";

const CLEAR_ALL_NOTIFICATION_RESPONSE = "CLEAR_ALL_NOTIFICATION_RESPONSE";

const CHANGE_NOTIFICATION_PAGE = "CHANGE_NOTIFICATION_PAGE";

export {
  ACCEPT_REQUEST_DATA_REQUEST,
  ACCEPT_REQUEST_DATA_SUCCESS,
  ACCEPT_REQUEST_DATA_FAILURE,
  CLEAR_ACCEPT_REQUEST_RESPONSE,
  DECLINE_REQUEST_DATA_REQUEST,
  DECLINE_REQUEST_DATA_SUCCESS,
  DECLINE_REQUEST_DATA_FAILURE,
  CLEAR_DECLINE_REQUEST_RESPONSE,
  ALL_NOTIFICATION_DATA_REQUEST,
  ALL_NOTIFICATION_DATA_SUCCESS,
  ALL_NOTIFICATION_DATA_FAILURE,
  CLEAR_ALL_NOTIFICATION_RESPONSE,
  CHANGE_NOTIFICATION_PAGE,
};
