import { combineReducers } from "redux";
import { HomeReducer } from "../../modules/Home/HomeReducer";
import { LoginReducer } from "../../modules/Login/LoginReducer";
import { SignupReducer } from "../../modules/Signup/SignupReducer";
import { Authreducer } from "../.././library/common/reducers/AuthReducer";
import { ForgetPasswordReducer } from "../../modules/ForgetPassword/ForgetPasswordReducer";
import { ResetPasswordReducer } from "../../modules/ResetPassword/ResetPasswordReducer";
import { AccountReducer } from "../../modules/Account/AccountReducer";
import { ToolbarReducer } from "../../library/common/components/Toolbar/ToolbarReducers";
import {
  SearchListReducer,
  searchLoadingReducer,
} from "../../modules/SearchList/SearchListReducer";
import { SearchMembersListReducer } from "../../modules/SearchMembersList/SearchMembersListReducer";
import { SearchProfileReducer } from "../../modules/SearchProfile/SearchProfileReducer";
import { NotificationReducer } from "../../modules/Allnotifications/NotificationReducer";
import { MembersNotificationReducer } from "../../modules/AllMembersNotifications/MembersNotificationReducer";
import { MyNetworkReducer } from "../../modules/MyNetwork/MyNetworkReducer";
import { ProfilePostReducer } from "../../library/common/components/ProfilePosts/ProfilePostReducer";
import { ProfileGalleryReducer } from "../../library/common/components/ProfileGallery/ProfileGalleryReducer";
import { MainReducer } from "../../modules/Main/MainReducer";
import { PostReducer } from "../../modules/Post/PostReducer";
import { PoolsChatReducer } from "../../modules/PoolsChat/PoolsChatReducer";
import { SuggestedNetworkReducer } from "../../modules/SuggestedNetwork/SuggestedNetworkReducer";

import { LandingPageReducer } from "../../library/common/components/LandingPage/landingContentReducer";

import { blogPageReducer } from "../../library/common/components/LandingPage/blogReducer";
import { aboutUsPageReducer } from "../../library/common/components/LandingPage/aboutPageReducer";
import { PromotionalHubReducer } from "../../modules/Home/PromotionalHubReducer";
// new-merged-reducer--//
import { chatListReducer } from "../../modules/PoolsChat/chatListReducer";
import {serviceProviderReducer} from "../../library/common/reducers/ServicesAndProvider"
import { ShareUserProfileReducer } from "../../modules/ShareProfile/ShareProfileReducer";
import {workandEarnReducer} from "../../library/common/reducers/WorkAndEarnReducer"
export default combineReducers({
  HomeReducer,
  LoginReducer,
  PromotionalHubReducer,
  Authreducer,
  SignupReducer,
  ForgetPasswordReducer,
  ResetPasswordReducer,
  AccountReducer,
  ToolbarReducer,
  SearchListReducer,
  searchLoadingReducer,
  SearchMembersListReducer,
  MembersNotificationReducer,
  SearchProfileReducer,
  NotificationReducer,
  MyNetworkReducer,
  ProfilePostReducer,
  ProfileGalleryReducer,
  MainReducer,
  PoolsChatReducer,
  PostReducer,
  SuggestedNetworkReducer,
  LandingPageReducer,
  blogPageReducer,
  aboutUsPageReducer,
  chatListReducer,
  ShareUserProfileReducer,
  serviceProviderReducer,
  workandEarnReducer,
});
