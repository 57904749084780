import Scrollbars from "react-custom-scrollbars";
import HoursOfOperation from "../../../library/common/components/HoursOfOperation";

const GeneralDetail = ({ profile_details, is_individual }) => {
  return (
    <div className="profile__box custom-shadow">
      <div className="profile__box__heading d-flex align-items-center justify-content-between">
        <h3 className="text-uppercase h4">General </h3>
      </div>
      <div className="user-info">
        <div className="user-info__blk">
          <h5 className="user-info__heading mb-3">
            <i className="ri-draft-line" /> intro
          </h5>
          <p>{!!profile_details ? profile_details.business_intro : ""}</p>
        </div>
        {is_individual == "true" ? (
          <div className="user-info__blk">
            <h5 className="user-info__heading mb-3">
              <i className="ri-settings-line" /> work
            </h5>
            <p>{!!profile_details ? profile_details.work : ""}</p>
          </div>
        ) : (
          <div className="user-info__blk">
            <h5 className="user-info__heading mb-3">
              <i className="ri-settings-line" /> Specialization
            </h5>
            <p>{!!profile_details ? profile_details.specialization : ""}</p>
          </div>
        )}
        {is_individual == "true" ? (
          <div className="user-info__blk">
            <h5 className="user-info__heading mb-3">
              <i className="ri-user-line" /> Skills
            </h5>
            <div className="vertically-list text-break">
              <p>
                {!!profile_details
                  ? profile_details?.skills &&
                    profile_details?.skills?.slice(0, 6).map((data, index) => {
                      return index !=
                        profile_details?.skills?.slice(0, 6).length - 1
                        ? data.label + ", "
                        : data.label + "";
                    })
                  : ""}
              </p>
            </div>
            {profile_details?.skills?.length > 6 && (
              <div className="viewall-list position-relative pt-3 d-inline-block">
                <span role="button" className="text-secondary">
                  See More
                </span>
                <div className="skills-listing-wrap">
                  <Scrollbars style={{ height: 250 }}>
                    {profile_details?.skills.map((item, index) => (
                      <p key={index}>{item.label}</p>
                    ))}
                  </Scrollbars>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="user-info__blk">
              <h5 className="user-info__heading mb-3">
                <i className="ri-user-line" /> Expertise
              </h5>
              <div className="vertically-list text-break">
                <p>
                  {profile_details?.expertise
                    ?.slice(0, 6)
                    .map((data, index) => {
                      return index !=
                        profile_details.expertise.slice(0, 6).length - 1
                        ? data.label + ", "
                        : data.label + "";
                    })}
                </p>
              </div>
              {profile_details && profile_details.expertise?.length > 6 && (
                <div className="viewall-list position-relative pt-3 d-inline-block">
                  <span role="button" className="text-secondary">
                    See More
                  </span>
                  <div className="skills-listing-wrap">
                    <Scrollbars style={{ height: 250 }}>
                      {profile_details?.expertise.map((item, index) => (
                        <p key={index}>{item.label}</p>
                      ))}
                    </Scrollbars>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <HoursOfOperation hours_details={profile_details} />
        <div className="user-info__blk">
          <h5 className="user-info__heading mb-3">
            <i className="ri-map-pin-line" /> Address
          </h5>
          <p>{!!profile_details ? profile_details.address : ""}</p>
        </div>
        <div className="user-info__blk">
          <h5 className="user-info mb-3">
            <i className="ri-global-line" />
            Language(s) Spoken
          </h5>
          <div>
            {!!profile_details?.languages ? (
              <div className="languages d-flex">
                {profile_details?.languages.slice(0, 6).map((data, index) => {
                  const { proficiency_level } = data;
                  return (
                    <>
                      {data.label + " "}
                      <div
                        title={proficiency_level}
                        key={index}
                        className="d-flex"
                      >
                        <span
                          className={`${
                            proficiency_level == "Expert"
                              ? "expert"
                              : proficiency_level == "Intermediate"
                              ? "interminate"
                              : proficiency_level == "Native"
                              ? "native"
                              : null
                          }`}
                        >
                          {proficiency_level.charAt(0)}
                        </span>
                        <span style={{ marginRight: 3 }}>
                          {index !=
                          profile_details?.languages.slice(0, 6).length - 1
                            ? ","
                            : " "}
                        </span>
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {profile_details?.languages?.length > 6 && (
              <div className="viewall-list position-relative d-inline-block mt-2">
                <span role="button" className="text-secondary">
                  See More
                </span>
                <div className="skills-listing-wrap">
                  <Scrollbars style={{ height: 250 }}>
                    {profile_details?.languages?.map((item, index) => (
                      <div className="d-flex" key={index}>
                        <p style={{ minWidth: 70 }}>{item.label} </p>
                        <span
                          className="mb-2"
                          title={item.proficiency_level}
                          key={index}
                        >
                          <p
                            className={`${
                              item.proficiency_level == "Expert"
                                ? "expert"
                                : item.proficiency_level == "Intermediate"
                                ? "interminate"
                                : item.proficiency_level == "Native"
                                ? "native"
                                : null
                            }`}
                          >
                            {item.proficiency_level.charAt(0)}
                          </p>
                        </span>
                      </div>
                    ))}
                  </Scrollbars>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GeneralDetail;
