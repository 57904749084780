import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { useToasts } from "react-toast-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { inviteApi } from "../../../library/api/HomeApiService";
import { Modal } from "react-bootstrap";
import { getCookie } from "../../../library/utilities/functions";
import { changeInviteModelInput, clearinviteapiResponse } from "../HomeActions";
import { handleEmailValidation } from "../Validatin";

const override = css`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 76%;
  right: 47%;
`;
const InviteModel = ({ openInvite, closeInviteModel }) => {
  const AccountStateData = useSelector((state) => state.AccountReducer);
  const dispatch = useDispatch();
  const emailElValidation = useRef();
  const { addToast } = useToasts();
  const invitemodel = useSelector((state) => state.HomeReducer);
  const [isInvitationBtnClick, setIsInvitationBtnClick] = useState(false)

  const {
    inviteModelInput: { emails, message },
  } = invitemodel;

  const {
    inviteApiResponse: {
      inviteApiLoading,
      inviteApiStatus,
      inviteMessage,
      inviteApiSuccess,
    },
  } = invitemodel;


  useEffect(() => {
    if (!!inviteApiStatus && inviteApiStatus == "200") {
      addToast(inviteMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearinviteapiResponse());
      closeInviteModel();
    }

    if (inviteApiStatus == "400") {
      addToast("Please enter valid email address.", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearinviteapiResponse());
    }
  }, [inviteApiSuccess]);

  //this function is used to invite the friends.
  const handleSumbit = () => {
    setIsInvitationBtnClick(true)
    if (!!emails.length) {
      const email = emails.map((data) => data.value);
      let validation = {
        is_valid_email: { status: false, validation: emailElValidation },
      };
      validation = handleEmailValidation(validation, emails);
      let {
        is_valid_email: { status },
      } = validation;
      const getMyEmail = email.find((x) => x == AccountStateData.GeneralInput.email);
      if (status && getMyEmail != AccountStateData.GeneralInput.email) {
        setIsInvitationBtnClick(false)
        const bodyParameter = {
          session_id: getCookie("token_id"),
          email_id: email.join(","),
          message: message,
        };
        dispatch(inviteApi(bodyParameter));
      } else {
        setTimeout(() => setIsInvitationBtnClick(false), 2000)
        addToast("You Can Not Sent Mail On This Email Address", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      }
    } else {
      setTimeout(() => setIsInvitationBtnClick(false), 2000)
      addToast("Please Enter The Email Address", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
    }
  };

  return (
    <Modal
      show={openInvite}
      onHide={closeInviteModel}
      backdrop="static"
      keyboard={false}
      centered
      className="invite-modal"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Invite with a personalized email
        </h5>
        <button
          onClick={closeInviteModel}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <div>
          <div className="form-group">
            <CreatableSelect
              placeholder="Enter one or more emails"
              isClearable
              onChange={(newValue) =>
                dispatch(changeInviteModelInput({ emails: newValue }))
              }
              isMulti
            />
            <p
              style={{ display: "none" }}
              ref={emailElValidation}
              className="error-message"
            >
              Please select at least one email.
            </p>
          </div>
          <div className="form-group">
            <textarea
              name="message"
              className="form-control"
              placeholder="Write your message"
              value={message}
              onChange={(e) =>
                dispatch(changeInviteModelInput({ message: e.target.value }))
              }
            />
          </div>
          <div className="form-group text-center mt-4">
            <button
              name="submit"
              onClick={handleSumbit}
              className={
                !!inviteApiLoading
                  ? "btn btn-primary w-75 disabled"
                  : "btn btn-primary w-75"
              }
              disabled={isInvitationBtnClick}
            >
              Invite
            </button>
            <ClipLoader
              color={"#fff"}
              loading={!!inviteApiLoading ? true : false}
              css={override}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default InviteModel;
