const CHANGE_CHAT_DETAILS = "CHANGE_CHAT_DETAILS";

const CHANGE_FRIEND_LIST_PAGE = "CHANGE_FRIEND_LIST_PAGE";

const CLEAR_CHAT_DETAILS = "CLEAR_CHAT_DETAILS";

const CHANGE_SHARE_ATTACHMENTS = "CHANGE_SHARE_ATTACHMENTS";

const CHAT_LIST_DATA_REQUEST = "CHAT_LIST_DATA_REQUEST";
const CHAT_LIST_DATA_SUCCESS = "CHAT_LIST_DATA_SUCCESS";
const CHAT_LIST_DATA_FAILURE = "CHAT_LIST_DATA_FAILURE";

const CLEAR_CHAT_LIST_RESPONSE = "CLEAR_CHAT_LIST_RESPONSE";

//---merged new code---//
const ALL_FRIEND_AND_GROUP_LIST = "ALL_FRIEND_AND_GROUP_LIST";

const GET_SINGLE_GROUP_DATA_REQUEST = "GET_SINGLE_GROUP_DATA_REQUEST";
const GET_SINGLE_GROUP_DATA_SUCCESS = "GET_SINGLE_GROUP_DATA_SUCCESS";
const GET_SINGLE_GROUP_DATA_FAILURE = "GET_SINGLE_GROUP_DATA_FAILURE";

const GET_ALL_SCHEDULE_EVENTS = "GET_ALL_SCHEDULE_EVENTS";
const CLEAR_SCHEDULE_LIST = "CLEAR_SCHEDULE_LIST";
const DECLINE_SCHEDUE_EVENTS = "DECLINE_SCHEDuLE_EVENTS";
const GET_ALL_SHARE_MEDIA_REQUEST = "GET_ALL_SHARE_MEDIA_REQUEST";
const GET_ALL_SHARE_MEDIA_SUCCESS = "GET_ALL_SHARE_MEDIA_SUCCESS";
const GET_ALL_SHARE_MEDIA_FAILURE = "GET_ALL_SHARE_MEDIA_FAILURE";

const SEND_EVENTS_REQUEST = "SEND_EVENTS_REQUEST";
const SEND_EVENTS_REQUEST_SUCCESS = "SEND_EVENTS_REQUEST_SUCCESS";
const SEND_EVENTS_REQUEST_FAILURE = "SEND_EVENTS_REQUEST_FAILURE";

const CLEAR_ALL_MEDIA = "CLEAR_ALL_MEDIA";

const ADD_AVAILABILITY_REQEST = "ADD_AVAILABILITY_REQEST";
const ADD_AVAILABILITY_SUCCESS = "ADD_AVAILABILITY_SUCCESS";
const ADD_AVAILABILITY_FAILURE = "ADD_AVAILABILITY_FAILURE";

const GET_ALL_AVAILABILITY_REQEST = "GET_ALL_AVAILABILITY_REQEST";
const GET_ALL_AVAILABILITY_SUCCESS = "GET_ALL_AVAILABILITY_SUCCESS";
const GET_ALL_AVAILABILITY_FAILURE = "GET_ALL_AVAILABILITY_FAILURE";

const DELETE_AVAILABILITY_REQUEST = "DELETE_AVAILABILITY_REQUEST";
const DELETE_AVAILABILITY_SUCCESS = "DELETE_AVAILABILITY_SUCCESS";
const DELETE_AVAILABILITY_FAILURE = "DELETE_AVAILABILITY_FAILURE";

const CANCEL_EVENT_REQUEST = "CANCEL_EVENT_REQUEST";
const CANCEL_EVENT_SUCCESS = "CANCEL_EVENT_SUCCESS";
const CANCEL_EVENT_FAILURE = "CANCEL_EVENT_FAILURE";

const ACCEPT_EVENT_REQUEST = "ACCEPT_EVENT_REQUEST";
const ACCEPT_EVENT_SUCCESS = "ACCEPT_EVENT_SUCCESS";
const ACCEPT_EVENT_FAILURE = "ACCEPT_EVENT_FAILURE";

const RESCHEDULE_EVENT_REQUEST = "RESCHEDULE_EVENT_REQUEST";
const RESCHEDULE_EVENT_SUCCESS = "RESCHEDULE_EVENT_SUCCESS";
const RESCHEDULE_EVENT_FAILURE = "RESCHEDULE_EVENT_FAILURE";

const DECLINE_EVENT_REQUEST = "DECLINE_EVENT_REQUEST";
const DECLINE_EVENT_SUCCESS = "DECLINE_EVENT_SUCCESS";
const DECLINE_EVENT_FAILURE = "DECLINE_EVENT_FAILURE";

const ACCEPT_SCHEDULE_EVENTS = "ACCEPT_SCHEDULE_EVENTS";

const UPDATE_USER_AVAILABILITY_REQUEST = "UPDATE_USER_AVAILABILITY_REQUEST";
const UPDATE_USER_AVAILABILITY_SUCCESS = "UPDATE_USER_AVAILABILITY_SUCCESS";
const UPDATE_USER_AVAILABILITY_FAILURE = "UPDATE_USER_AVAILABILITY_FAILURE";

const CLEAR_CHAT_LIST = "CLEAR_CHAT_LIST";
export {
  DECLINE_EVENT_REQUEST,
  DECLINE_EVENT_SUCCESS,
  DECLINE_EVENT_FAILURE,
  CANCEL_EVENT_REQUEST,
  CANCEL_EVENT_SUCCESS,
  CANCEL_EVENT_FAILURE,
  ACCEPT_EVENT_REQUEST,
  DECLINE_SCHEDUE_EVENTS,
  ACCEPT_EVENT_SUCCESS,
  ACCEPT_EVENT_FAILURE,
  RESCHEDULE_EVENT_FAILURE,
  RESCHEDULE_EVENT_REQUEST,
  RESCHEDULE_EVENT_SUCCESS,
  DELETE_AVAILABILITY_REQUEST,
  DELETE_AVAILABILITY_SUCCESS,
  DELETE_AVAILABILITY_FAILURE,
  GET_ALL_AVAILABILITY_REQEST,
  GET_ALL_AVAILABILITY_SUCCESS,
  GET_ALL_AVAILABILITY_FAILURE,
  ADD_AVAILABILITY_REQEST,
  ADD_AVAILABILITY_SUCCESS,
  ADD_AVAILABILITY_FAILURE,
  CHANGE_CHAT_DETAILS,
  CHANGE_FRIEND_LIST_PAGE,
  CLEAR_CHAT_DETAILS,
  CHAT_LIST_DATA_REQUEST,
  CHAT_LIST_DATA_SUCCESS,
  CHAT_LIST_DATA_FAILURE,
  GET_ALL_SHARE_MEDIA_REQUEST,
  GET_ALL_SHARE_MEDIA_SUCCESS,
  GET_ALL_SHARE_MEDIA_FAILURE,
  CLEAR_CHAT_LIST_RESPONSE,
  CHANGE_SHARE_ATTACHMENTS,
  ALL_FRIEND_AND_GROUP_LIST,
  GET_SINGLE_GROUP_DATA_REQUEST,
  GET_SINGLE_GROUP_DATA_SUCCESS,
  GET_SINGLE_GROUP_DATA_FAILURE,
  CLEAR_CHAT_LIST,
  GET_ALL_SCHEDULE_EVENTS,
  CLEAR_SCHEDULE_LIST,
  CLEAR_ALL_MEDIA,
  SEND_EVENTS_REQUEST,
  SEND_EVENTS_REQUEST_SUCCESS,
  SEND_EVENTS_REQUEST_FAILURE,
  ACCEPT_SCHEDULE_EVENTS,
  UPDATE_USER_AVAILABILITY_REQUEST,
  UPDATE_USER_AVAILABILITY_SUCCESS,
  UPDATE_USER_AVAILABILITY_FAILURE,
};
