import { DELETE_API, GET_API, POST_API } from "../../main/fetch";
import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  LANGUAGE_LIST_FAILURE,
  LANGUAGE_LIST_REQUEST,
  LANGUAGE_LIST_SUCCESS,
  SKILLS_LIST_FAILURE,
  SKILLS_LIST_REQUEST,
  SKILLS_LIST_SUCCESS,
  UPDATE_ADDITIONAL_INFO_FAILURE,
  UPDATE_ADDITIONAL_INFO_REQUEST,
  UPDATE_ADDITIONAL_INFO_SUCCESS,
  SAVE_MY_PROJECTS_REQUEST,
  SAVE_MY_PROJECTS_SUCCESS,
  SAVE_MY_PROJECTS_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  GET_PROJECTS_FAILURE,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  SAVE_MY_EMPLOYMENTS_REQUEST,
  SAVE_MY_EMPLOYMENTS_SUCCESS,
  SAVE_MY_EMPLOYMENTS_FAILURE,
  DELETE_EMPLOYMENT_REQUEST,
  DELETE_EMPLOYMENT_SUCCESS,
  DELETE_EMPLOYMENT_FAILURE,
  UPDATE_EMPLOYMENT_REQUEST,
  UPDATE_EMPLOYMENT_SUCCESS,
  UPDATE_EMPLOYMENT_FAILURE,
  GET_EMPLOYMENTS_FAILURE,
  GET_EMPLOYMENTS_REQUEST,
  GET_EMPLOYMENTS_SUCCESS,
  GET_SOCIAL_ICON_REQUEST,
  GET_SOCIAL_ICON_SUCCESS,
  GET_SOCIAL_ICON_FAILURE,
  ADD_SOCIAL_ICON_REQUEST,
  ADD_SOCIAL_ICON_SUCCESS,
  ADD_SOCIAL_ICON_FAILURE,
  SPECIALIZATION_LIST_REQUEST,
  SPECIALIZATION_LIST_SUCCESS,
  SPECIALIZATION_LIST_FAILURE,
  GET_ALL_BUSINESS_INDUSTRIES,
  INDUSTRIES_LIST_SUCCESS,
  INDUSTRIES_LIST_FAILURE,
  SELLER_SERVICE_LIST_REQUEST,
  SELLER_SERVICE_LIST_SUCCESS,
  SELLER_SERVICE_LIST_FAILURE,
  ALL_SELLER_DATA_REQUEST,
  ALL_SELLER_DATA_SUCCESS,
  ALL_SELLER_DATA_FAILURE,
  SELLER_ADD_DATA_REQUEST,
  SELLER_ADD_DATA_SUCCESS,
  SELLER_ADD_DATA_FAILURE,
  EXPERTISE_LIST_REQUEST,
  EXPERTISE_LIST_SUCCESS,
  EXPERTISE_LIST_FAILURE,
  UPDATE_SELLER_SERVICE_REQUEST,
  UPDATE_SELLER_SERVICE_SUCCESS,
  UPDATE_SELLER_SERVICE_FAILURE,
  DELETE_USER_SERVICE_REQUEST,
  DELETE_USER_SERVICE_SUCCESS,
  DELETE_USER_SERVICE_FAILURE,
  GET_MANAGE_ACCOUNT_REQUEST,
  GET_MANAGE_ACCOUNT_SUCCESS,
  GET_MANAGE_ACCOUNT_FAILURE,
  CREATE_MAIN_SERVICES_REQUEST,
  CREATE_MAIN_SERVICES_SUCCESS,
  CREATE_MAIN_SERVICES_FAILURE,
  CREATE_SECONDARY_SERVICES_REQUEST,
  CREATE_SECONDARY_SERVICES_SUCCESS,
  CREATE_SECONDARY_SERVICES_FAILURE,
} from "../../modules/Account/AccountConstant";

import {
  GET_PROFILE_API,
  LANGUAGE_LIST_API,
  SKILLS_LIST_API,
  UPDATE_ADDITIONAL_INFO,
  GET_PROJECTS_API,
  SAVE_MY_PROJECTS_API,
  DELETE_PROJECT_API,
  UPDATE_PROJECT_API,
  GET_EMPLOYMENTS_API,
  SAVE_MY_EMPLOYMENTS_API,
  DELETE_EMPLOYMENT_API,
  UPDATE_EMPLOYMENT_API,
  GET_SOCIAL_ICON_API,
  ADD_SOCIAL_ICON_API,
  SPECIALIZATION_LIST_API,
  SEARCH_INDIVIDUAL_API,
  SELLER_GETINDUSTRIESLIST,
  SELLER_SERVICE_LIST,
  GET_SELLER_DETAIL,
  SELLER_SIGNUP_API,
  SELLER_UPDATE_STATUS,
  EXPERTISE_LIST_API,
  GET_SELLER_STATUS,
  UPDATE_SELLER_SERVICE,
  DELETE_USER_SERVICE,
  GET_MANAGE_ACCOUNT_API,
  GET_SELLER_API,
  CREATE_MAIN_SERVICES,
  CREATE_SECONDARY_SERVICES,
} from "../urls";

import {
  SEARCH_LIST_MEMBERS_DATA_REQUEST,
  SEARCH_LIST_MEMBERS_DATA_SUCCESS,
  SEARCH_LIST_MEMBERS_DATA_FAILURE,
} from "../../modules/SearchMembersList/SearchMembersListConstant";
import {
  GET_SELLER_STATUS_DATA_FAILURE,
  GET_SELLER_STATUS_DATA_REQUEST,
  GET_SELLER_STATUS_DATA_SUCCESS,
} from "../common/components/ProfilePosts/ProfilePostConstant";

//this function is used to fetch  the profile data
const getProfileApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_PROFILE_API,
      types: [GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE],
    },
  };
};

const searchListDataMembersApi = (body) => {
  return {
    [GET_API]: {
      endpoint: SEARCH_INDIVIDUAL_API + "?search=" + body.search,
      types: [
        SEARCH_LIST_MEMBERS_DATA_REQUEST,
        SEARCH_LIST_MEMBERS_DATA_SUCCESS,
        SEARCH_LIST_MEMBERS_DATA_FAILURE,
      ],
    },
  };
};

//this function is used to update profile data
const updateAdditinalApi = (body) => {
  return {
    [POST_API]: {
      endpoint: UPDATE_ADDITIONAL_INFO,
      types: [
        UPDATE_ADDITIONAL_INFO_REQUEST,
        UPDATE_ADDITIONAL_INFO_SUCCESS,
        UPDATE_ADDITIONAL_INFO_FAILURE,
      ],
      body,
      is_form_data: true,
    },
  };
};

const signUpAddDataApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SELLER_SIGNUP_API,
      types: [
        SELLER_ADD_DATA_REQUEST,
        SELLER_ADD_DATA_SUCCESS,
        SELLER_ADD_DATA_FAILURE,
      ],
      body,
      is_form_data: true,
    },
  };
};

const createNewMainServices = (body) => {
  return {
    [POST_API]: {
      endpoint: CREATE_MAIN_SERVICES,
      types: [
        CREATE_MAIN_SERVICES_REQUEST,
        CREATE_MAIN_SERVICES_SUCCESS,
        CREATE_MAIN_SERVICES_FAILURE,
      ],
      body,
    },
  };
};

const createNewSecondaryServices = (body) => {
  return {
    [POST_API]: {
      endpoint: CREATE_SECONDARY_SERVICES,
      types: [
        CREATE_SECONDARY_SERVICES_REQUEST,
        CREATE_SECONDARY_SERVICES_SUCCESS,
        CREATE_SECONDARY_SERVICES_FAILURE,
      ],
      body,
    },
  };
};

//this function is used to fetch language list
const languageListApi = () => {
  return {
    [GET_API]: {
      endpoint: LANGUAGE_LIST_API,
      types: [
        LANGUAGE_LIST_REQUEST,
        LANGUAGE_LIST_SUCCESS,
        LANGUAGE_LIST_FAILURE,
      ],
    },
  };
};
//this function is used to fetch specialization list
const specializationListApi = () => {
  return {
    [GET_API]: {
      endpoint: SPECIALIZATION_LIST_API,
      types: [
        SPECIALIZATION_LIST_REQUEST,
        SPECIALIZATION_LIST_SUCCESS,
        SPECIALIZATION_LIST_FAILURE,
      ],
    },
  };
};
//this function is used to fetch skill list
const skilsListApi = () => {
  return {
    [GET_API]: {
      endpoint: SKILLS_LIST_API,
      types: [SKILLS_LIST_REQUEST, SKILLS_LIST_SUCCESS, SKILLS_LIST_FAILURE],
    },
  };
};
const expertiseListApi = () => {
  return {
    [GET_API]: {
      endpoint: EXPERTISE_LIST_API,
      types: [
        EXPERTISE_LIST_REQUEST,
        EXPERTISE_LIST_SUCCESS,
        EXPERTISE_LIST_FAILURE,
      ],
    },
  };
};

//this function is used to fetch industries list
const industryListApi = () => {
  return {
    [GET_API]: {
      endpoint: SELLER_GETINDUSTRIESLIST,
      types: [
        GET_ALL_BUSINESS_INDUSTRIES,
        INDUSTRIES_LIST_SUCCESS,
        INDUSTRIES_LIST_FAILURE,
      ],
    },
  };
};
/* this function is used to fetch main and secondary service */
const fetchServiceListApi = (body) => {
  return {
    [GET_API]: {
      endpoint: SELLER_SERVICE_LIST + "?page=" + body.page,
      types: [
        SELLER_SERVICE_LIST_REQUEST,
        SELLER_SERVICE_LIST_SUCCESS,
        SELLER_SERVICE_LIST_FAILURE,
      ],
    },
  };
};
/**
 *
 * projects api service
 */
//this function is used to get the projects
const getProjectsApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_PROJECTS_API,
      types: [GET_PROJECTS_REQUEST, GET_PROJECTS_SUCCESS, GET_PROJECTS_FAILURE],
    },
  };
};
//this function is used to add projects
const saveMyProjectsApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SAVE_MY_PROJECTS_API,
      types: [
        SAVE_MY_PROJECTS_REQUEST,
        SAVE_MY_PROJECTS_SUCCESS,
        SAVE_MY_PROJECTS_FAILURE,
      ],
      body,
      is_form_data: true,
    },
  };
};
//this function is used to delete the project
const deleteProjectApi = (body) => {
  return {
    [POST_API]: {
      endpoint: DELETE_PROJECT_API,
      types: [
        DELETE_PROJECT_REQUEST,
        DELETE_PROJECT_SUCCESS,
        DELETE_PROJECT_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};
//this function is used to update the project
const updateProjectApi = (body) => {
  return {
    [POST_API]: {
      endpoint: UPDATE_PROJECT_API,
      types: [
        UPDATE_PROJECT_REQUEST,
        UPDATE_PROJECT_SUCCESS,
        UPDATE_PROJECT_FAILURE,
      ],
      body,
      is_form_data: true,
    },
  };
};

/**
 * Employment Api Service
 */
//this function is used to get employment list
const getEmploymentsApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_EMPLOYMENTS_API,
      types: [
        GET_EMPLOYMENTS_REQUEST,
        GET_EMPLOYMENTS_SUCCESS,
        GET_EMPLOYMENTS_FAILURE,
      ],
    },
  };
};
//this function is used to add employment
const saveMyEmploymentsApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SAVE_MY_EMPLOYMENTS_API,
      types: [
        SAVE_MY_EMPLOYMENTS_REQUEST,
        SAVE_MY_EMPLOYMENTS_SUCCESS,
        SAVE_MY_EMPLOYMENTS_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};
//this function is used to delete employment
const deleteEmploymentApi = (body) => {
  return {
    [POST_API]: {
      endpoint: DELETE_EMPLOYMENT_API,
      types: [
        DELETE_EMPLOYMENT_REQUEST,
        DELETE_EMPLOYMENT_SUCCESS,
        DELETE_EMPLOYMENT_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};
//this function is used to update employment
const updateEmploymentApi = (body) => {
  return {
    [POST_API]: {
      endpoint: UPDATE_EMPLOYMENT_API,
      types: [
        UPDATE_EMPLOYMENT_REQUEST,
        UPDATE_EMPLOYMENT_SUCCESS,
        UPDATE_EMPLOYMENT_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};
//this function is used to get social icon
const getSocialIconApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_SOCIAL_ICON_API,
      types: [
        GET_SOCIAL_ICON_REQUEST,
        GET_SOCIAL_ICON_SUCCESS,
        GET_SOCIAL_ICON_FAILURE,
      ],
    },
  };
};
//this function is used to add social icon
const addSocialIconApi = (body) => {
  return {
    [POST_API]: {
      endpoint: ADD_SOCIAL_ICON_API,
      types: [
        ADD_SOCIAL_ICON_REQUEST,
        ADD_SOCIAL_ICON_SUCCESS,
        ADD_SOCIAL_ICON_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to get all individual and business data
const getAllSellerDataApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_SELLER_DETAIL,
      types: [
        ALL_SELLER_DATA_REQUEST,
        ALL_SELLER_DATA_SUCCESS,
        ALL_SELLER_DATA_FAILURE,
      ],
    },
  };
};

// UPDATE SELLER SERVICES ACTION
const updateSellerServices = (body, id) => {
  return {
    [POST_API]: {
      endpoint: UPDATE_SELLER_SERVICE(id),
      types: [
        UPDATE_SELLER_SERVICE_REQUEST,
        UPDATE_SELLER_SERVICE_SUCCESS,
        UPDATE_SELLER_SERVICE_FAILURE,
      ],
      body,
    },
  };
};

const getSellerStatusApi = (body) => {
  return {
    [GET_API]: {
      endpoint: GET_SELLER_STATUS,
      types: [
        GET_SELLER_STATUS_DATA_REQUEST,
        GET_SELLER_STATUS_DATA_SUCCESS,
        GET_SELLER_STATUS_DATA_FAILURE,
      ],
    },
  };
};
const deleteServiceApi = (body) => {
  return {
    [DELETE_API]: {
      endpoint: DELETE_USER_SERVICE,
      types: [
        DELETE_USER_SERVICE_REQUEST,
        DELETE_USER_SERVICE_SUCCESS,
        DELETE_USER_SERVICE_FAILURE,
      ],
      body,
    },
  };
};
const getManageAccountApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_MANAGE_ACCOUNT_API,
      types: [
        GET_MANAGE_ACCOUNT_REQUEST,
        GET_MANAGE_ACCOUNT_SUCCESS,
        GET_MANAGE_ACCOUNT_FAILURE,
      ],
    },
  };
};

export {
  getSellerStatusApi,
  getProfileApi,
  updateAdditinalApi,
  languageListApi,
  skilsListApi,
  getProjectsApi,
  saveMyProjectsApi,
  deleteProjectApi,
  updateProjectApi,
  getEmploymentsApi,
  saveMyEmploymentsApi,
  deleteEmploymentApi,
  updateEmploymentApi,
  getSocialIconApi,
  addSocialIconApi,
  specializationListApi,
  searchListDataMembersApi,
  industryListApi,
  fetchServiceListApi,
  getAllSellerDataApi,
  signUpAddDataApi,
  expertiseListApi,
  updateSellerServices,
  createNewMainServices,
  deleteServiceApi,
  createNewSecondaryServices,
  getManageAccountApi,
};
