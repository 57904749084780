import {
  CHANGE_ACTIVA_CAROUSAL,
  CHANGE_ALL_POST_DETAIL,
  CHANGE_COMMENT_INPUT_DETAIL,
  CHANGE_LIKE_LIST_PAGE,
  CHANGE_POST_VIEW_DETAIL,
  CHANGE_REACTION_LIST_DETAIL,
  CHANGE_SHARE_INPUT,
  CHANGE_SHARE_LIST_DETAIL,
  CLEAR_COMMENT_REPLY_RESPONSE,
  CLEAR_GET_POST_RESPONSE,
  CLEAR_INVITE_RESPONSE,
  CLEAR_LIKE_DISLIKE_RESPONSE,
  CLEAR_POST_COMMENT_LIST_RESPONSE,
  CLEAR_POST_COMMENT_REPLY_LIST_RESPONSE,
  CLEAR_POST_COMMENT_RESPONSE,
  CLEAR_POST_LIKE_LIST_RESPONSE,
  CLEAR_POST_REACTION_LIST_RESPONSE,
  CLEAR_POST_REACTION_RESPONSE,
  CLEAR_POST_SHARE_LIST_RESPONSE,
  CLEAR_POST_SHARE_RESPONSE,
  CLEAR_POST_UPLOAD_RESPONSE,
  CLEAR_POST_UPDATE_RESPONSE,
  CLEAR_POST_VIEW_LIST_RESPONSE,
  CLEAR_POST_VIEW_RESPONSE,
  CLEAR_SINGLE_LIKE_LIST_RESPONSE,
  CLEAR_SINGLE_LIKE_POST_RESPONSE,
  CLEAR_SINGLE_POST_CMT_REPLY_LIST_RESPONSE,
  CLEAR_SINGLE_POST_CMT_REPLY_RESPONSE,
  CLEAR_SINGLE_POST_COMMENT_LIST_RESPONSE,
  CLEAR_SINGLE_POST_COMMENT_RESPONSE,
  CLEAR_SINGLE_POST_LIST_RESPONSE,
  CLEAR_SINGLE_POST_REACTION_RESPONSE,
  CLEAR_SINGLE_POST_SHARE_LIST_RESPONSE,
  CLEAR_SINGLE_POST_SHARE_RESPONSE,
  CLEAR_SINGLE_POST_VIEW_RESPONSE,
  CLEAR_SINGLE_REACTION_LIST_RESPONSE,
  GET_POST_FAILURE,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  INVITE_DATA_FAILURE,
  INVITE_DATA_REQUEST,
  INVITE_DATA_SUCCESS,
  INVITE_MODEL_INPUT,
  LIKE_DISLIKE_POST_DATA_FAILURE,
  LIKE_DISLIKE_POST_DATA_REQUEST,
  LIKE_DISLIKE_POST_DATA_SUCCESS,
  POST_COMMENT_DATA_FAILURE,
  POST_COMMENT_DATA_REQUEST,
  POST_COMMENT_DATA_SUCCESS,
  POST_COMMENT_LIST_DATA_FAILURE,
  POST_COMMENT_LIST_DATA_REQUEST,
  POST_COMMENT_LIST_DATA_SUCCESS,
  POST_COMMENT_REPLY_DATA_FAILURE,
  POST_COMMENT_REPLY_DATA_REQUEST,
  POST_COMMENT_REPLY_DATA_SUCCESS,
  POST_COMMENT_REPLY_LIST_DATA_FAILURE,
  POST_COMMENT_REPLY_LIST_DATA_REQUEST,
  POST_COMMENT_REPLY_LIST_DATA_SUCCESS,
  POST_LIKE_LIST_DATA_FAILURE,
  POST_LIKE_LIST_DATA_REQUEST,
  POST_LIKE_LIST_DATA_SUCCESS,
  POST_REACTION_DATA_FAILURE,
  POST_REACTION_DATA_REQUEST,
  POST_REACTION_DATA_SUCCESS,
  POST_REACTION_LIST_DATA_FAILURE,
  POST_REACTION_LIST_DATA_REQUEST,
  POST_REACTION_LIST_DATA_SUCCESS,
  POST_SHARE_DATA_FAILURE,
  POST_SHARE_DATA_REQUEST,
  POST_SHARE_DATA_SUCCESS,
  POST_SHARE_LIST_DATA_FAILURE,
  POST_SHARE_LIST_DATA_REQUEST,
  POST_SHARE_LIST_DATA_SUCCESS,
  POST_UPLOAD_FAILURE,
  POST_UPLOAD_REQUEST,
  POST_UPLOAD_SUCCESS,
  POST_UPDATE_FAILURE,
  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  POST_VIEW_DATA_FAILURE,
  POST_VIEW_DATA_REQUEST,
  POST_VIEW_DATA_SUCCESS,
  POST_VIEW_LIST_DATA_FAILURE,
  POST_VIEW_LIST_DATA_REQUEST,
  POST_VIEW_LIST_DATA_SUCCESS,
  SINGLE_LIKE_LIST_DATA_FAILURE,
  SINGLE_LIKE_LIST_DATA_REQUEST,
  SINGLE_LIKE_LIST_DATA_SUCCESS,
  SINGLE_POST_CMT_REPLY_DATA_FAILURE,
  SINGLE_POST_CMT_REPLY_DATA_REQUEST,
  SINGLE_POST_CMT_REPLY_DATA_SUCCESS,
  SINGLE_POST_CMT_REPLY_LIST_DATA_FAILURE,
  SINGLE_POST_CMT_REPLY_LIST_DATA_REQUEST,
  SINGLE_POST_CMT_REPLY_LIST_DATA_SUCCESS,
  SINGLE_POST_COMMENT_DATA_FAILURE,
  SINGLE_POST_COMMENT_DATA_REQUEST,
  SINGLE_POST_COMMENT_DATA_SUCCESS,
  SINGLE_POST_COMMENT_LIST_DATA_FAILURE,
  SINGLE_POST_COMMENT_LIST_DATA_REQUEST,
  SINGLE_POST_COMMENT_LIST_DATA_SUCCESS,
  SINGLE_POST_LIKE_DATA_FAILURE,
  SINGLE_POST_LIKE_DATA_REQUEST,
  SINGLE_POST_LIKE_DATA_SUCCESS,
  SINGLE_POST_REACTION_DATA_FAILURE,
  SINGLE_POST_REACTION_DATA_REQUEST,
  SINGLE_POST_REACTION_DATA_SUCCESS,
  SINGLE_POST_SHARE_DATA_FAILURE,
  SINGLE_POST_SHARE_DATA_REQUEST,
  SINGLE_POST_SHARE_DATA_SUCCESS,
  SINGLE_POST_SHARE_LIST_DATA_FAILURE,
  SINGLE_POST_SHARE_LIST_DATA_REQUEST,
  SINGLE_POST_SHARE_LIST_DATA_SUCCESS,
  SINGLE_POST_VIEW_DATA_FAILURE,
  SINGLE_POST_VIEW_DATA_REQUEST,
  SINGLE_POST_VIEW_DATA_SUCCESS,
  SINGLE_POST_VIEW_LIST_DATA_FAILURE,
  SINGLE_POST_VIEW_LIST_DATA_REQUEST,
  SINGLE_POST_VIEW_LIST_DATA_SUCCESS,
  SINGLE_REACTION_LIST_DATA_FAILURE,
  SINGLE_REACTION_LIST_DATA_REQUEST,
  SINGLE_REACTION_LIST_DATA_SUCCESS,
  POST_EDIT_DATA,
} from "./HomeConstants";

const initialState = {
  inviteModelInput: {
    emails: [],
    message: "",
  },
  SharePostInput: {
    shareText: "",
    mediaFile: [],
    mediaUrl: [],
    fileVisibility: 0,
  },
  inviteApiResponse: {
    inviteApiLoading: false,
    inviteApiStatus: "",
    inviteMessage: "",
    inviteApiSuccess: "",
  },
  uploadPostApi: {
    uploadPostLoading: false,
    uploadPostStatus: "",
    uploadPostMessage: "",
    uploadPostResponse: "",
  },
  updatePostApi: {
    updatePostLoading: false,
    updatePostStatus: "",
    updatePostMessage: "",
    updatePostResponse: "",
  },
  allPostDetail: {
    allPost: [],
    allPostPage: 1,
  },
  getPostApi: {
    getPostLoading: false,
    getPostStatus: "",
    getPostMessage: "",
  },
  activeCarousalDetail: {
    activeCarousal: [],
    activeIndex: "",
    singleCommentId: "",
  },
  likeDislikeApi: {
    likeDislikeLoading: false,
    likeDislikeStatus: "",
    likeDislikeSucess: "",
    likeDislikeMesaage: "",
  },
  singleLikeDislikeApi: {
    singleLikeLoading: false,
    singleLikeStatus: "",
    singleLikeSucess: "",
    singleLikeMessage: "",
  },
  postReactionApi: {
    reactionLoading: false,
    reactionStatus: "",
    reactionSucess: "",
    reactionMessage: "",
  },
  singlePostReactionApi: {
    singleReactionLoading: false,
    singleReactionStatus: "",
    singleReactionSucess: "",
    singleReactionMessage: "",
  },
  postLikeListApi: {
    likeListLoading: false,
    likeListStatus: "",
    likeListMessage: "",
  },
  LikeListDetail: {
    likeList: [],
    totalLikes: "",
    likeListPage: 0,
    postId: "",
    fileId: "",
    singleLikeListpage: 0,
  },
  SingleLikeListApi: {
    singleLikeListLoading: false,
    singleLikeListStatus: "",
    singleLikeListMessage: "",
  },
  reactionListDetail: {
    reactList: [],
    totalReact: "",
    reactListPage: 0,
    reactionPostId: "",
    reactionFileId: "",
    singleReactListpage: 0,
  },
  reactListApi: {
    reactListLoading: false,
    reactListStatus: "",
    reactListMessage: "",
  },
  singleReactListApi: {
    singleReactListLoading: false,
    singleReactListStatus: "",
    singleReactListMessage: "",
  },
  commentDetails: {
    commentPostId: "",
    commentListPage: "",
    commentId: "",
  },
  commentListApi: {
    commentListLoading: false,
    commentListStatus: "",
    commentListMessage: "",
  },
  commentApi: {
    commentLoading: false,
    commentStatus: "",
    commentMessage: "",
    commentResponse: "",
  },
  commentReplyApi: {
    commentReplyLoading: false,
    commentReplyMessage: "",
    commentReplyStatus: "",
    commentReplyResponse: "",
  },
  commentReplyListApi: {
    commentReplyListLoading: false,
    commentReplyListMessage: "",
    commentReplyListStatus: "",
  },
  singlePostCommentApi: {
    SingleCommentLoading: false,
    SingleCommentStatus: "",
    SingleCommentMessage: "",
    SingleCommentResponse: "",
  },
  singlePostCommentListApi: {
    singleCommentListLoading: false,
    singleCommentListStatus: "",
    singleCommentListMessage: "",
  },
  singlePostCommentReplyApi: {
    singleCommentReplyLoading: false,
    singleCommentReplyStatus: "",
    singleCommentReplyMessage: "",
    singleCommentReplyResponse: "",
  },
  singleCommentReplyListApi: {
    singleReplyListLoading: false,
    singleReplyListStatus: "",
    singleReplyListMessage: "",
  },
  postViewApi: {
    postViewLoading: false,
    postViewStatus: "",
    postViewMessage: "",
  },
  singlePostViewApi: {
    singleViewLoading: false,
    SingleViewStatus: "",
    SingleViewMessage: "",
  },
  postViewListApi: {
    viewListLoading: false,
    viewListStatus: "",
    viewListmessage: "",
  },
  singlePostViewListApi: {
    singleViewListLoading: false,
    singleViewListStatus: "",
    singleViewListMessage: "",
  },
  postViewListDetail: {
    viewList: [],
    totalViews: "",
    viewListPage: 0,
    viewPostId: "",
    viewFileId: "",
    singleViewListpage: 0,
  },
  shareFeedPostApi: {
    shareFeedPostLoading: false,
    shareFeedPostStatus: "",
    shareFeedPostMessage: "",
  },
  shareSingleFeedPostApi: {
    shareSingleFeedLoading: false,
    shareSingleFeedStatus: "",
    shareSingleFeedMessage: "",
  },
  postShareListDetail: {
    shareList: [],
    totalShare: "",
    shareListPage: 0,
    sharePostId: "",
    shareFileId: "",
    singleShareListpage: 0,
  },
  feedShareListApi: {
    feedShareListLoading: false,
    feedShareListStatus: "",
    feedShareListMessage: "",
  },
  singlefeedShareListApi: {
    singleShareListLoading: false,
    singleShareListStatus: "",
    singleShareListMessage: "",
  },
  updatePost: {
    postDetail: {},
  },
};

export const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITE_MODEL_INPUT:
      return {
        ...state,
        ...{
          inviteModelInput: { ...state.inviteModelInput, ...action.newState },
        },
      };
    case INVITE_DATA_REQUEST:
      return {
        ...state,
        ...{
          inviteApiResponse: {
            ...state.inviteApiResponse,
            ...{ inviteApiLoading: true },
          },
        },
      };
    case INVITE_DATA_SUCCESS:
      const {
        response: {
          data: { success, message, status },
        },
      } = action;
      let inviteApiSucess = {
        inviteApiLoading: false,
        inviteApiStatus: status,
        inviteMessage: message,
        inviteApiSuccess: success,
      };

      return {
        ...state,
        ...{
          inviteApiResponse: { ...state.inviteApiResponse, ...inviteApiSucess },
        },
      };
    case INVITE_DATA_FAILURE:
      let inviteApiFailure = {
        inviteApiLoading: false,
        inviteApiStatus: action.status,
        inviteMessage: action.response.data.message,
        inviteApiSuccess: false,
      };

      return {
        ...state,
        ...{
          inviteApiResponse: {
            ...state.inviteApiResponse,
            ...inviteApiFailure,
          },
        },
      };
    case CLEAR_INVITE_RESPONSE:
      let clearInviteApi = {
        inviteApiLoading: false,
        inviteApiStatus: "",
        inviteMessage: "",
        inviteApiSuccess: "",
      };
      return {
        ...state,
        ...{
          inviteApiResponse: { ...state.inviteApiResponse, ...clearInviteApi },
        },
      };
    case CHANGE_SHARE_INPUT:
      return {
        ...state,
        ...{ SharePostInput: { ...state.SharePostInput, ...action.newState } },
      };
    case POST_EDIT_DATA:
      return {
        ...state,
        ...{ updatePost: { ...state.updatePost, ...action.newState } },
      };
    case POST_UPLOAD_REQUEST:
      return {
        ...state,
        ...{
          uploadPostApi: {
            ...state.uploadPostApi,
            ...{ uploadPostLoading: true },
          },
        },
      };
    case POST_UPLOAD_SUCCESS:
      let uploadApiSucess = {
        uploadPostLoading: false,
        uploadPostStatus: action.response.data.status,
        uploadPostMessage: action.response.data.message,
        uploadPostResponse: !!action.response.data.data
          ? action.response.data.data
          : "",
      };
      return {
        ...state,
        ...{
          uploadPostApi: { ...state.uploadPostApi, ...uploadApiSucess },
        },
      };
    case POST_UPLOAD_FAILURE:
      let uploadFailure = {
        uploadPostLoading: false,
        uploadPostStatus: action.status,
        uploadPostMessage: action.response.data.message,
        uploadPostResponse: "",
      };

      return {
        ...state,
        ...{
          uploadPostApi: { ...state.uploadPostApi, ...uploadFailure },
        },
      };

    case POST_UPDATE_REQUEST:
      return {
        ...state,
        ...{
          updatePostApi: {
            ...state.updatePostApi,
            ...{ updatePostLoading: true },
          },
        },
      };

    case POST_UPDATE_SUCCESS:
      let updateApiSucess = {
        updatePostLoading: false,
        updatePostStatus: action.response.data.status,
        updatePostMessage: action.response.data.message,
        updatePostResponse: !!action.response.data.data
          ? action.response.data.data
          : "",
      };
      return {
        ...state,
        ...{
          updatePostApi: { ...state.updatePostApi, ...updateApiSucess },
        },
      };

    case POST_UPDATE_FAILURE:
      let updateFailure = {
        updatePostLoading: false,
        updatePostStatus: action.status,
        updatePostMessage: action.response.data.message,
        updatePostResponse: "",
      };

      return {
        ...state,
        ...{
          updatePostApi: { ...state.updatePostApi, ...updateFailure },
        },
      };

    case CLEAR_POST_UPDATE_RESPONSE:
      let clearUpdate = {
        updatePostStatus: "",
        updatePostMessage: "",
        updatePostResponse: "",
      };
      return {
        ...state,
        ...{
          updatePostApi: { ...state.updatePostApi, ...clearUpdate },
        },
      };

    case CLEAR_POST_UPLOAD_RESPONSE:
      let clearUpload = {
        uploadPostStatus: "",
        uploadPostMessage: "",
        uploadPostResponse: "",
      };
      return {
        ...state,
        ...{
          uploadPostApi: { ...state.uploadPostApi, ...clearUpload },
        },
      };
    case GET_POST_REQUEST:
      return {
        ...state,
        ...{ getPostApi: { ...state.getPostApi, ...{ getPostLoading: true } } },
      };
    case GET_POST_SUCCESS:
      let getApiSucess = {
        getPostLoading: false,
        getPostStatus: action.response.data.status,
        getPostMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          getPostApi: { ...state.getPostApi, ...getApiSucess },
          ...{
            allPostDetail: {
              ...state.allPostDetail,
              ...{
                allPost: !!action.response.data.list
                  ? [
                    ...state.allPostDetail.allPost,
                    ...action.response.data.list,
                  ]
                  : [],
              },
            },
          },
        },
      };
    case GET_POST_FAILURE:
      let getApiFailure = {
        getPostLoading: false,
        getPostStatus: action.status,
        getPostMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          getPostApi: { ...state.getPostApi, ...getApiFailure },
          ...{ allPostDetail: { ...state.allPostDetail, ...{ allPost: [] } } },
        },
      };
    case CLEAR_GET_POST_RESPONSE:
      let clearGetResponse = {
        getPostStatus: "",
        getPostMessage: "",
      };
      let clearPostDetail = {
        allPost: [],
        allPostPage: 1,
      };
      return {
        ...state,
        ...{
          getPostApi: { ...state.getPostApi, ...clearGetResponse },
          allPostDetail: { ...state.allPostDetail, ...clearPostDetail },
        },
      };
    case CHANGE_ACTIVA_CAROUSAL:
      return {
        ...state,
        ...{
          activeCarousalDetail: {
            ...state.activeCarousalDetail,
            ...action.newState,
          },
        },
      };
    case CHANGE_ALL_POST_DETAIL:
      return {
        ...state,
        ...{ allPostDetail: { ...state.allPostDetail, ...action.newState } },
      };
    case LIKE_DISLIKE_POST_DATA_REQUEST:
      return {
        ...state,
        ...{
          likeDislikeApi: {
            ...state.likeDislikeApi,
            ...{ likeDislikeLoading: true },
          },
        },
      };
    case LIKE_DISLIKE_POST_DATA_SUCCESS:
      let likeDislikeApiSucess = {
        likeDislikeLoading: false,
        likeDislikeStatus: action.response.data.status,
        likeDislikeMesaage: action.response.data.message,
        likeDislikeSucess: !!action.response.data.success
          ? action.response.data.success
          : "",
      };
      return {
        ...state,
        ...{
          likeDislikeApi: { ...state.likeDislikeApi, ...likeDislikeApiSucess },
        },
      };
    case LIKE_DISLIKE_POST_DATA_FAILURE:
      let likeDislikeFailure = {
        likeDislikeLoading: false,
        likeDislikeStatus: action.status,
        likeDislikeMesaage: action.response.data.message,
        likeDislikeSucess: "",
      };

      return {
        ...state,
        ...{
          likeDislikeApi: { ...state.likeDislikeApi, ...likeDislikeFailure },
        },
      };
    case CLEAR_LIKE_DISLIKE_RESPONSE:
      let clearLikePost = {
        likeDislikeStatus: "",
        likeDislikeSucess: "",
        likeDislikeMesaage: "",
      };
      return {
        ...state,
        ...{
          likeDislikeApi: { ...state.likeDislikeApi, ...clearLikePost },
        },
      };
    case SINGLE_POST_LIKE_DATA_REQUEST:
      return {
        ...state,
        ...{
          singleLikeDislikeApi: {
            ...state.singleLikeDislikeApi,
            ...{ singleLikeLoading: true },
          },
        },
      };
    case SINGLE_POST_LIKE_DATA_SUCCESS:
      let singleLikeApiSucess = {
        singleLikeLoading: false,
        singleLikeStatus: action.response.data.status,
        singleLikeMessage: action.response.data.message,
        singleLikeSucess: !!action.response.data.success
          ? action.response.data.success
          : "",
      };
      return {
        ...state,
        ...{
          singleLikeDislikeApi: {
            ...state.singleLikeDislikeApi,
            ...singleLikeApiSucess,
          },
        },
      };
    case SINGLE_POST_LIKE_DATA_FAILURE:
      let singlePostFailure = {
        singleLikeLoading: false,
        singleLikeStatus: action.status,
        singleLikeMessage: action.response.data.message,
        singleLikeSucess: "",
      };

      return {
        ...state,
        ...{
          singleLikeDislikeApi: {
            ...state.singleLikeDislikeApi,
            ...singlePostFailure,
          },
        },
      };
    case CLEAR_SINGLE_LIKE_POST_RESPONSE:
      let clearSingleLikePost = {
        singleLikeStatus: "",
        singleLikeSucess: "",
        singleLikeMessage: "",
      };
      return {
        ...state,
        ...{
          singleLikeDislikeApi: {
            ...state.singleLikeDislikeApi,
            ...clearSingleLikePost,
          },
        },
      };
    case POST_REACTION_DATA_REQUEST:
      return {
        ...state,
        ...{
          postReactionApi: {
            ...state.postReactionApi,
            ...{ reactionLoading: true },
          },
        },
      };
    case POST_REACTION_DATA_SUCCESS:
      let reactionSucess = {
        reactionLoading: false,
        reactionStatus: action.response.data.status,
        reactionMessage: action.response.data.message,
        reactionSucess: !!action.response.data.success
          ? action.response.data.success
          : "",
      };
      return {
        ...state,
        ...{
          postReactionApi: { ...state.postReactionApi, ...reactionSucess },
        },
      };
    case POST_REACTION_DATA_FAILURE:
      let postReactionFailure = {
        reactionLoading: false,
        reactionStatus: action.status,
        reactionMessage: action.response.data.message,
        reactionSucess: "",
      };
      return {
        ...state,
        ...{
          postReactionApi: { ...state.postReactionApi, ...postReactionFailure },
        },
      };
    case CLEAR_POST_REACTION_RESPONSE:
      let clearReactionPost = {
        reactionStatus: "",
        reactionSucess: "",
        reactionMessage: "",
      };
      return {
        ...state,
        ...{
          postReactionApi: { ...state.postReactionApi, ...clearReactionPost },
        },
      };
    case SINGLE_POST_REACTION_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostReactionApi: {
            ...state.singlePostReactionApi,
            ...{ singleReactionLoading: true },
          },
        },
      };
    case SINGLE_POST_REACTION_DATA_SUCCESS:
      let singleReactionSucess = {
        singleReactionLoading: false,
        singleReactionStatus: action.response.data.status,
        singleReactionMessage: action.response.data.message,
        singleReactionSucess: !!action.response.data.success
          ? action.response.data.success
          : "",
      };
      return {
        ...state,
        ...{
          singlePostReactionApi: {
            ...state.singlePostReactionApi,
            ...singleReactionSucess,
          },
        },
      };
    case SINGLE_POST_REACTION_DATA_FAILURE:
      let singleReactionFailure = {
        singleReactionLoading: false,
        singleReactionStatus: action.status,
        singleReactionMessage: action.response.data.message,
        singleReactionSucess: "",
      };
      return {
        ...state,
        ...{
          singlePostReactionApi: {
            ...state.singlePostReactionApi,
            ...singleReactionFailure,
          },
        },
      };
    case CLEAR_SINGLE_POST_REACTION_RESPONSE:
      let clearSingleReaction = {
        singleReactionStatus: "",
        singleReactionSucess: "",
        singleReactionMessage: "",
      };
      return {
        ...state,
        ...{
          singlePostReactionApi: {
            ...state.singlePostReactionApi,
            ...clearSingleReaction,
          },
        },
      };
    case POST_LIKE_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          postLikeListApi: {
            ...state.postLikeListApi,
            ...{ likeListLoading: true },
          },
        },
      };
    case POST_LIKE_LIST_DATA_SUCCESS:
      let likeListSucess = {
        likeListLoading: false,
        likeListStatus: action.response.data.status,
        likeListMessage: action.response.data.message,
      };
      let likeLists = {
        likeList: !!action.response.data.list
          ? [...state.LikeListDetail.likeList, ...action.response.data.list]
          : [],
        totalLikes: action.response.data.total,
      };
      for (let i in state.allPostDetail.allPost) {
        if (
          state.allPostDetail.allPost[i].post_id == state.LikeListDetail.postId
        ) {
          state.allPostDetail.allPost[i].post_like = !!action.response.data
            .total
            ? action.response.data.total
            : "";
        }
      }
      return {
        ...state,
        ...{
          postLikeListApi: { ...state.postLikeListApi, ...likeListSucess },
          LikeListDetail: { ...state.LikeListDetail, ...likeLists },
        },
      };
    case POST_LIKE_LIST_DATA_FAILURE:
      let likeListFailure = {
        likeListLoading: false,
        likeListStatus: action.status,
        likeListMessage: action.response.data.message,
      };
      let likeListFail = {
        likeList: [],
        totalLikes: "",
      };
      return {
        ...state,
        ...{
          postLikeListApi: { ...state.postLikeListApi, ...likeListFailure },
          LikeListDetail: { ...state.LikeListDetail, ...likeListFail },
        },
      };
    case CLEAR_POST_LIKE_LIST_RESPONSE:
      let clearLikeList = {
        likeListStatus: "",
        likeListMessage: "",
      };
      let clearLikeDetail = {
        likeListPage: 0,
        postId: "",
        likeList: [],
        totalLikes: "",
      };
      return {
        ...state,
        ...{
          postLikeListApi: { ...state.postLikeListApi, ...clearLikeList },
          LikeListDetail: { ...state.LikeListDetail, ...clearLikeDetail },
        },
      };
    case CHANGE_LIKE_LIST_PAGE:
      return {
        ...state,
        ...{ LikeListDetail: { ...state.LikeListDetail, ...action.newState } },
      };
    case SINGLE_LIKE_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          SingleLikeListApi: {
            ...state.SingleLikeListApi,
            ...{ singleLikeListLoading: true },
          },
        },
      };
    case SINGLE_LIKE_LIST_DATA_SUCCESS:
      let singlelikeListSucess = {
        singleLikeListLoading: false,
        singleLikeListStatus: action.response.data.status,
        singleLikeListMessage: action.response.data.message,
      };
      let SinglelikeLists = {
        likeList: !!action.response.data.list
          ? [...state.LikeListDetail.likeList, ...action.response.data.list]
          : [],
        totalLikes: action.response.data.total,
      };
      return {
        ...state,
        ...{
          SingleLikeListApi: {
            ...state.SingleLikeListApi,
            ...singlelikeListSucess,
          },
          LikeListDetail: { ...state.LikeListDetail, ...SinglelikeLists },
        },
      };
    case SINGLE_LIKE_LIST_DATA_FAILURE:
      let SinglelikeListFailure = {
        singleLikeListLoading: false,
        singleLikeListStatus: action.status,
        singleLikeListMessage: action.response.data.message,
      };
      let SinglelikeListFail = {
        likeList: [],
        totalLikes: "",
      };
      return {
        ...state,
        ...{
          SingleLikeListApi: {
            ...state.SingleLikeListApi,
            ...SinglelikeListFailure,
          },
          LikeListDetail: { ...state.LikeListDetail, ...SinglelikeListFail },
        },
      };
    case CLEAR_SINGLE_LIKE_LIST_RESPONSE:
      let clearSingleLikeList = {
        singleLikeListStatus: "",
        singleLikeListMessage: "",
      };
      let clearSingleLikeDetail = {
        fileId: "",
        singleLikeListpage: 0,
        likeList: [],
        totalLikes: "",
      };
      return {
        ...state,
        ...{
          SingleLikeListApi: {
            ...state.SingleLikeListApi,
            ...clearSingleLikeList,
          },
          LikeListDetail: { ...state.LikeListDetail, ...clearSingleLikeDetail },
        },
      };
    case POST_REACTION_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          reactListApi: {
            ...state.reactListApi,
            ...{ reactListLoading: true },
          },
        },
      };
    case POST_REACTION_LIST_DATA_SUCCESS:
      let reactListSucess = {
        reactListLoading: false,
        reactListStatus: action.response.data.status,
        reactListMessage: action.response.data.message,
      };
      let reactLists = {
        reactList: !!action.response.data.list
          ? [
            ...state.reactionListDetail.reactList,
            ...action.response.data.list,
          ]
          : [],
        totalReact: !!action.response.data.reaction_listing
          ? action.response.data.reaction_listing
          : [],
      };
      for (let i in state.allPostDetail.allPost) {
        if (
          state.allPostDetail.allPost[i].post_id ==
          state.reactionListDetail.reactionPostId
        ) {
          state.allPostDetail.allPost[i].post_react = !!action.response.data
            .total
            ? action.response.data.total
            : "";
          state.allPostDetail.allPost[i].reaction_list = !!action.response.data
            .reaction_listing
            ? action.response.data.reaction_listing
            : [];
        }
      }
      return {
        ...state,
        ...{
          reactListApi: { ...state.reactListApi, ...reactListSucess },
          reactionListDetail: { ...state.reactionListDetail, ...reactLists },
        },
      };
    case POST_REACTION_LIST_DATA_FAILURE:
      let reactListFailure = {
        reactListLoading: false,
        reactListStatus: action.status,
        reactListMessage: action.response.data.message,
      };
      let reactListFail = {
        reactList: [],
        totalReact: "",
      };
      return {
        ...state,
        ...{
          reactListApi: { ...state.reactListApi, ...reactListFailure },
          reactionListDetail: { ...state.reactionListDetail, ...reactListFail },
        },
      };
    case CLEAR_POST_REACTION_LIST_RESPONSE:
      let clearReactList = {
        reactListStatus: "",
        reactListMessage: "",
      };
      let clearReactDetail = {
        reactList: [],
        totalReact: "",
        reactListPage: 0,
        reactionPostId: "",
      };
      return {
        ...state,
        ...{
          reactListApi: { ...state.reactListApi, ...clearReactList },
          reactionListDetail: {
            ...state.reactionListDetail,
            ...clearReactDetail,
          },
        },
      };
    case CHANGE_REACTION_LIST_DETAIL:
      return {
        ...state,
        ...{
          reactionListDetail: {
            ...state.reactionListDetail,
            ...action.newState,
          },
        },
      };
    case SINGLE_REACTION_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singleReactListApi: {
            ...state.singleReactListApi,
            ...{ singleReactListLoading: true },
          },
        },
      };
    case SINGLE_REACTION_LIST_DATA_SUCCESS:
      let singleReactListSucess = {
        singleReactListLoading: false,
        singleReactListStatus: action.response.data.status,
        singleReactListMessage: action.response.data.message,
      };
      let SingleReactLists = {
        reactList: !!action.response.data.list
          ? [
            ...state.reactionListDetail.reactList,
            ...action.response.data.list,
          ]
          : [],
        totalReact: !!action.response.data.reaction_listing
          ? action.response.data.reaction_listing
          : [],
      };
      for (let i in state.activeCarousalDetail.activeCarousal) {
        if (i == state.activeCarousalDetail.activeIndex) {
          state.activeCarousalDetail.activeCarousal[i].react_count = !!action
            .response.data.total
            ? action.response.data.total
            : "";
          state.activeCarousalDetail.activeCarousal[i].reaction_list = !!action
            .response.data.reaction_listing
            ? action.response.data.reaction_listing
            : [];
        }
      }
      return {
        ...state,
        ...{
          singleReactListApi: {
            ...state.singleReactListApi,
            ...singleReactListSucess,
          },
          reactionListDetail: {
            ...state.reactionListDetail,
            ...SingleReactLists,
          },
        },
      };
    case SINGLE_REACTION_LIST_DATA_FAILURE:
      let SingleReactListFailure = {
        singleReactListLoading: false,
        singleReactListStatus: action.status,
        singleReactListMessage: action.response.data.message,
      };
      let SingleReactListFail = {
        reactList: [],
        totalReact: "",
      };
      return {
        ...state,
        ...{
          singleReactListApi: {
            ...state.singleReactListApi,
            ...SingleReactListFailure,
          },
          reactionListDetail: {
            ...state.reactionListDetail,
            ...SingleReactListFail,
          },
        },
      };
    case CLEAR_SINGLE_REACTION_LIST_RESPONSE:
      let clearSingleReactList = {
        singleReactListLoading: false,
        singleReactListStatus: "",
        singleReactListMessage: "",
      };
      let clearSingleReactDetail = {
        reactionFileId: "",
        singleReactListpage: 0,
        reactList: [],
        totalReact: "",
      };
      return {
        ...state,
        ...{
          singleReactListApi: {
            ...state.singleReactListApi,
            ...clearSingleReactList,
          },
          reactionListDetail: {
            ...state.reactionListDetail,
            ...clearSingleReactDetail,
          },
        },
      };
    case CHANGE_COMMENT_INPUT_DETAIL:
      return {
        ...state,
        ...{ commentDetails: { ...state.commentDetails, ...action.newState } },
      };
    case POST_COMMENT_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          commentListApi: {
            ...state.commentListApi,
            ...{ commentListLoading: true },
          },
        },
      };
    case POST_COMMENT_LIST_DATA_SUCCESS:
      let commentListSucess = {
        commentListLoading: false,
        commentListStatus: action.response.data.status,
        commentListMessage: action.response.data.message,
      };
      for (let i in state.allPostDetail.allPost) {
        if (
          state.allPostDetail.allPost[i].post_id ==
          state.commentDetails.commentPostId
        ) {
          if (!!state.allPostDetail.allPost[i].commentList) {
            state.allPostDetail.allPost[i].commentList = !!action.response.data
              .list
              ? [
                ...action.response.data.list.reverse(),
                ...state.allPostDetail.allPost[i].commentList,
              ]
              : [];
          }
          state.allPostDetail.allPost[i].comment_count = !!action.response.data
            .total
            ? action.response.data.total
            : 0;
        }
      }
      return {
        ...state,
        ...{
          commentListApi: { ...state.commentListApi, ...commentListSucess },
        },
      };
    case POST_COMMENT_LIST_DATA_FAILURE:
      let commentListFailure = {
        commentListLoading: false,
        commentListStatus: action.status,
        commentListMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          commentListApi: { ...state.commentListApi, ...commentListFailure },
        },
      };
    case CLEAR_POST_COMMENT_LIST_RESPONSE:
      let clearCommentList = {
        commentListStatus: "",
        commentListMessage: "",
      };
      return {
        ...state,
        ...{
          commentListApi: { ...state.commentListApi, ...clearCommentList },
        },
      };
    case POST_COMMENT_DATA_REQUEST:
      return {
        ...state,
        ...{ commentApi: { ...state.commentApi, ...{ commentLoading: true } } },
      };
    case POST_COMMENT_DATA_SUCCESS:
      let commentSucess = {
        commentLoading: false,
        commentStatus: action.response.data.status,
        commentMessage: action.response.data.message,
        commentResponse: action.response.data.data,
      };

      return {
        ...state,
        ...{
          commentApi: { ...state.commentApi, ...commentSucess },
        },
      };
    case POST_COMMENT_DATA_FAILURE:
      let commentFailure = {
        commentLoading: false,
        commentStatus: action.status,
        commentMessage: action.response.data.message,
        commentResponse: "",
      };
      return {
        ...state,
        ...{
          commentApi: { ...state.commentApi, ...commentFailure },
        },
      };
    case CLEAR_POST_COMMENT_RESPONSE:
      let clearComment = {
        commentStatus: "",
        commentMessage: "",
        commentResponse: "",
      };

      return {
        ...state,
        ...{
          commentApi: { ...state.commentApi, ...clearComment },
        },
      };
    case POST_COMMENT_REPLY_DATA_REQUEST:
      return {
        ...state,
        ...{
          commentReplyApi: {
            ...state.commentReplyApi,
            ...{ commentReplyLoading: true },
          },
        },
      };
    case POST_COMMENT_REPLY_DATA_SUCCESS:
      let commentReplySucess = {
        commentReplyLoading: false,
        commentReplyStatus: action.response.data.status,
        commentReplyMessage: action.response.data.message,
        commentReplyResponse: action.response.data.data,
      };

      return {
        ...state,
        ...{
          commentReplyApi: { ...state.commentReplyApi, ...commentReplySucess },
        },
      };
    case POST_COMMENT_REPLY_DATA_FAILURE:
      let commentReplyFailure = {
        commentReplyLoading: false,
        commentReplyStatus: action.status,
        commentReplyMessage: action.response.data.message,
        commentReplyResponse: "",
      };
      return {
        ...state,
        ...{
          commentReplyApi: { ...state.commentReplyApi, ...commentReplyFailure },
        },
      };
    case CLEAR_COMMENT_REPLY_RESPONSE:
      let clearCommentReply = {
        commentReplyMessage: "",
        commentReplyStatus: "",
        commentReplyResponse: "",
      };

      return {
        ...state,
        ...{
          commentReplyApi: { ...state.commentReplyApi, ...clearCommentReply },
        },
      };
    case POST_COMMENT_REPLY_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          commentReplyListApi: {
            ...state.commentReplyListApi,
            ...{ commentReplyListLoading: true },
          },
        },
      };
    case POST_COMMENT_REPLY_LIST_DATA_SUCCESS:
      let commentReplyListSucess = {
        commentReplyListLoading: false,
        commentReplyListStatus: action.response.data.status,
        commentReplyListMessage: action.response.data.message,
      };
      for (let i in state.allPostDetail.allPost) {
        const innerPost = state.allPostDetail.allPost[i].commentList;
        for (let j in innerPost) {
          if (innerPost[j].comment_id == state.commentDetails.commentId) {
            innerPost[j].subComment = !!action.response.data.list.length
              ? [...new Set([...action.response.data.list.reverse()])]
              : [];

            innerPost[j].reply = !!action.response.data.total
              ? action.response.data.total
              : 0;
          }
        }
      }
      return {
        ...state,
        ...{
          commentReplyListApi: {
            ...state.commentReplyListApi,
            ...commentReplyListSucess,
          },
        },
      };
    case POST_COMMENT_REPLY_LIST_DATA_FAILURE:
      let commentReplyListFailure = {
        commentReplyListLoading: false,
        commentReplyListStatus: action.status,
        commentReplyListMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          commentReplyListApi: {
            ...state.commentReplyListApi,
            ...commentReplyListFailure,
          },
        },
      };
    case CLEAR_POST_COMMENT_REPLY_LIST_RESPONSE:
      let clearCommentReplyList = {
        commentReplyListMessage: "",
        commentReplyListStatus: "",
      };
      return {
        ...state,
        ...{
          commentReplyListApi: {
            ...state.commentReplyListApi,
            ...clearCommentReplyList,
          },
        },
      };
    case SINGLE_POST_COMMENT_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostCommentApi: {
            ...state.singlePostCommentApi,
            ...{ SingleCommentLoading: true },
          },
        },
      };
    case SINGLE_POST_COMMENT_DATA_SUCCESS:
      let singleCommentSucess = {
        SingleCommentLoading: false,
        SingleCommentStatus: action.response.data.status,
        SingleCommentMessage: action.response.data.message,
        SingleCommentResponse: action.response.data.data,
      };

      return {
        ...state,
        ...{
          singlePostCommentApi: {
            ...state.singlePostCommentApi,
            ...singleCommentSucess,
          },
        },
      };
    case SINGLE_POST_COMMENT_DATA_FAILURE:
      let singleCommentFailure = {
        SingleCommentLoading: false,
        SingleCommentStatus: action.status,
        SingleCommentMessage: action.response.data.message,
        SingleCommentResponse: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentApi: {
            ...state.singlePostCommentApi,
            ...singleCommentFailure,
          },
        },
      };
    case CLEAR_SINGLE_POST_COMMENT_RESPONSE:
      let clearSingleComment = {
        SingleCommentStatus: "",
        SingleCommentMessage: "",
        SingleCommentResponse: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentApi: {
            ...state.singlePostCommentApi,
            ...clearSingleComment,
          },
        },
      };
    case SINGLE_POST_COMMENT_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostCommentListApi: {
            ...state.singlePostCommentListApi,
            ...{ singleCommentListLoading: true },
          },
        },
      };
    case SINGLE_POST_COMMENT_LIST_DATA_SUCCESS:
      let singleCommentListSucess = {
        singleCommentListLoading: false,
        singleCommentListStatus: action.response.data.status,
        singleCommentListMessage: action.response.data.message,
      };
      for (let i in state.activeCarousalDetail.activeCarousal) {
        if (i == state.activeCarousalDetail.activeIndex) {
          if (
            !!state.activeCarousalDetail.activeCarousal[i].singlePostCommentList
          ) {
            state.activeCarousalDetail.activeCarousal[i].singlePostCommentList =
              !!action.response.data.list
                ? [
                  ...action.response.data.list.reverse(),
                  ...state.activeCarousalDetail.activeCarousal[i]
                    .singlePostCommentList,
                ]
                : [];
          }
          state.activeCarousalDetail.activeCarousal[i].comment_count = !!action
            .response.data.total
            ? action.response.data.total
            : 0;
        }
      }
      return {
        ...state,
        ...{
          singlePostCommentListApi: {
            ...state.singlePostCommentListApi,
            ...singleCommentListSucess,
          },
        },
      };
    case SINGLE_POST_COMMENT_LIST_DATA_FAILURE:
      let singleCommentListFailure = {
        singleCommentListLoading: false,
        singleCommentListStatus: action.status,
        singleCommentListMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          singlePostCommentListApi: {
            ...state.singlePostCommentListApi,
            ...singleCommentListFailure,
          },
        },
      };
    case CLEAR_SINGLE_POST_COMMENT_LIST_RESPONSE:
      let clearSingleCommentList = {
        singleCommentListStatus: "",
        singleCommentListMessage: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentListApi: {
            ...state.singlePostCommentListApi,
            ...clearSingleCommentList,
          },
        },
      };
    case SINGLE_POST_CMT_REPLY_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostCommentReplyApi: {
            ...state.singlePostCommentReplyApi,
            ...{ singleCommentReplyLoading: true },
          },
        },
      };
    case SINGLE_POST_CMT_REPLY_DATA_SUCCESS:
      let singleCommentReplySucess = {
        singleCommentReplyLoading: false,
        singleCommentReplyStatus: action.response.data.status,
        singleCommentReplyMessage: action.response.data.message,
        singleCommentReplyResponse: action.response.data.data,
      };

      return {
        ...state,
        ...{
          singlePostCommentReplyApi: {
            ...state.singlePostCommentReplyApi,
            ...singleCommentReplySucess,
          },
        },
      };
    case SINGLE_POST_CMT_REPLY_DATA_FAILURE:
      let singleCommentReplyFailure = {
        singleCommentReplyLoading: false,
        singleCommentReplyStatus: action.status,
        singleCommentReplyMessage: action.response.data.message,
        singleCommentReplyResponse: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentReplyApi: {
            ...state.singlePostCommentReplyApi,
            ...singleCommentReplyFailure,
          },
        },
      };
    case CLEAR_SINGLE_POST_CMT_REPLY_RESPONSE:
      let clearSingleCommentReply = {
        singleCommentReplyStatus: "",
        singleCommentReplyMessage: "",
        singleCommentReplyResponse: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentReplyApi: {
            ...state.singlePostCommentReplyApi,
            ...clearSingleCommentReply,
          },
        },
      };
    case SINGLE_POST_CMT_REPLY_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singleCommentReplyListApi: {
            ...state.singleCommentReplyListApi,
            ...{ singleReplyListLoading: true },
          },
        },
      };
    case SINGLE_POST_CMT_REPLY_LIST_DATA_SUCCESS:
      let singleReplyListSucess = {
        singleReplyListLoading: false,
        singleReplyListStatus: action.response.data.status,
        singleReplyListMessage: action.response.data.message,
      };
      for (let i in state.activeCarousalDetail.activeCarousal) {
        if (i == state.activeCarousalDetail.activeIndex) {
          let innerSubComment =
            state.activeCarousalDetail.activeCarousal[i].singlePostCommentList;

          for (let j in innerSubComment) {
            if (
              innerSubComment[j].comment_id ==
              state.activeCarousalDetail.singleCommentId
            ) {
              if (!!innerSubComment[j].singleSubComment) {
                innerSubComment[j].singleSubComment = !!action.response.data
                  .list
                  ? [
                    ...action.response.data.list.reverse(),
                    ...innerSubComment[j].singleSubComment,
                  ]
                  : [];
              }
              innerSubComment[j].reply = !!action.response.data.total
                ? action.response.data.total
                : innerSubComment[j].reply;
            }
          }
        }
      }
      return {
        ...state,
        ...{
          singleCommentReplyListApi: {
            ...state.singleCommentReplyListApi,
            ...singleReplyListSucess,
          },
        },
      };
    case SINGLE_POST_CMT_REPLY_LIST_DATA_FAILURE:
      let singleReplyListFailure = {
        singleReplyListLoading: false,
        singleReplyListStatus: action.status,
        singleReplyListMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          singleCommentReplyListApi: {
            ...state.singleCommentReplyListApi,
            ...singleReplyListFailure,
          },
        },
      };
    case CLEAR_SINGLE_POST_CMT_REPLY_LIST_RESPONSE:
      let clearSingleReplyList = {
        singleReplyListStatus: "",
        singleReplyListMessage: "",
      };
      return {
        ...state,
        ...{
          singleCommentReplyListApi: {
            ...state.singleCommentReplyListApi,
            ...clearSingleReplyList,
          },
        },
      };
    case POST_VIEW_DATA_REQUEST:
      return {
        ...state,
        ...{
          postViewApi: { ...state.postViewApi, ...{ postViewLoading: true } },
        },
      };
    case POST_VIEW_DATA_SUCCESS:
      let postViewSucess = {
        postViewLoading: false,
        postViewStatus: action.response.data.status,
        postViewMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          postViewApi: { ...state.postViewApi, ...postViewSucess },
        },
      };
    case POST_VIEW_DATA_FAILURE:
      let postViewFailure = {
        postViewLoading: false,
        postViewStatus: action.status,
        postViewMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          postViewApi: { ...state.postViewApi, ...postViewFailure },
        },
      };
    case CLEAR_POST_VIEW_RESPONSE:
      let clearPostView = {
        postViewLoading: false,
        postViewStatus: "",
        postViewMessage: "",
      };
      return {
        ...state,
        ...{
          postViewApi: { ...state.postViewApi, ...clearPostView },
        },
      };
    case SINGLE_POST_VIEW_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostViewApi: {
            ...state.singlePostViewApi,
            ...{ singleViewLoading: true },
          },
        },
      };
    case SINGLE_POST_VIEW_DATA_SUCCESS:
      let singlePostViewSucess = {
        singleViewLoading: false,
        SingleViewStatus: action.response.data.status,
        SingleViewMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          singlePostViewApi: {
            ...state.singlePostViewApi,
            ...singlePostViewSucess,
          },
        },
      };
    case SINGLE_POST_VIEW_DATA_FAILURE:
      let singlePostViewFailure = {
        singleViewLoading: false,
        SingleViewStatus: action.status,
        SingleViewMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          singlePostViewApi: {
            ...state.singlePostViewApi,
            ...singlePostViewFailure,
          },
        },
      };
    case CLEAR_SINGLE_POST_VIEW_RESPONSE:
      let clearSinglePostView = {
        SingleViewStatus: "",
        SingleViewMessage: "",
      };
      return {
        ...state,
        ...{
          singlePostViewApi: {
            ...state.singlePostViewApi,
            ...clearSinglePostView,
          },
        },
      };
    case CHANGE_POST_VIEW_DETAIL:
      return {
        ...state,
        ...{
          postViewListDetail: {
            ...state.postViewListDetail,
            ...action.newState,
          },
        },
      };
    case POST_VIEW_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          postViewListApi: {
            ...state.postViewListApi,
            ...{ viewListLoading: true },
          },
        },
      };
    case POST_VIEW_LIST_DATA_SUCCESS:
      let ViewSucess = {
        viewListLoading: false,
        viewListStatus: action.response.data.status,
        viewListmessage: action.response.data.message,
      };
      let viewLists = {
        viewList: !!action.response.data.list
          ? [...state.postViewListDetail.viewList, ...action.response.data.list]
          : [],
        totalViews: action.response.data.total,
      };
      for (let i in state.allPostDetail.allPost) {
        if (
          state.allPostDetail.allPost[i].post_id ==
          state.postViewListDetail.viewPostId
        ) {
          state.allPostDetail.allPost[i].post_views = !!action.response.data
            .total
            ? action.response.data.total
            : 0;
        }
      }
      return {
        ...state,
        ...{
          postViewListApi: { ...state.postViewListApi, ...ViewSucess },
          postViewListDetail: { ...state.postViewListDetail, ...viewLists },
        },
      };
    case POST_VIEW_LIST_DATA_FAILURE:
      let ViewFailure = {
        viewListLoading: false,
        viewListStatus: action.status,
        viewListmessage: action.response.data.message,
      };
      let viewListFail = {
        viewList: [],
        totalViews: "",
      };
      return {
        ...state,
        ...{
          postViewListApi: { ...state.postViewListApi, ...ViewFailure },
          postViewListDetail: { ...state.postViewListDetail, ...viewListFail },
        },
      };
    case CLEAR_POST_VIEW_LIST_RESPONSE:
      let clearViewList = {
        viewListStatus: "",
        viewListmessage: "",
      };
      let clearViewDetail = {
        viewList: [],
        totalViews: "",
        viewListPage: 0,
        viewPostId: "",
      };
      return {
        ...state,
        ...{
          postViewListApi: { ...state.postViewListApi, ...clearViewList },
          postViewListDetail: {
            ...state.postViewListDetail,
            ...clearViewDetail,
          },
        },
      };
    case SINGLE_POST_VIEW_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostViewListApi: {
            ...state.singlePostViewListApi,
            ...{ singleViewListLoading: true },
          },
        },
      };
    case SINGLE_POST_VIEW_LIST_DATA_SUCCESS:
      let singleViewListSucess = {
        singleViewListLoading: false,
        singleViewListStatus: action.response.data.status,
        singleViewListMessage: action.response.data.message,
      };
      let SingleViewLists = {
        viewList: !!action.response.data.list
          ? [...state.postViewListDetail.viewList, ...action.response.data.list]
          : [],
        totalViews: action.response.data.total,
      };
      for (let i in state.activeCarousalDetail.activeCarousal) {
        if (i == state.activeCarousalDetail.activeIndex) {
          state.activeCarousalDetail.activeCarousal[i].view_count = !!action
            .response.data.total
            ? action.response.data.total
            : "";
        }
      }
      return {
        ...state,
        ...{
          singlePostViewListApi: {
            ...state.singlePostViewListApi,
            ...singleViewListSucess,
          },
          postViewListDetail: {
            ...state.postViewListDetail,
            ...SingleViewLists,
          },
        },
      };
    case SINGLE_POST_VIEW_LIST_DATA_FAILURE:
      let SingleViewListFailure = {
        singleViewListLoading: false,
        singleViewListStatus: action.status,
        singleViewListMessage: action.response.data.message,
      };
      let SingleViewListFail = {
        viewList: [],
        totalViews: "",
      };
      return {
        ...state,
        ...{
          singlePostViewListApi: {
            ...state.singlePostViewListApi,
            ...SingleViewListFailure,
          },
          postViewListDetail: {
            ...state.postViewListDetail,
            ...SingleViewListFail,
          },
        },
      };
    case CLEAR_SINGLE_POST_LIST_RESPONSE:
      let clearSingleViewList = {
        singleViewListStatus: "",
        singleViewListMessage: "",
      };
      let clearSingleViewDetail = {
        viewList: [],
        totalViews: "",
        viewPostId: "",
        viewFileId: "",
        singleViewListpage: 0,
      };
      return {
        ...state,
        ...{
          singlePostViewListApi: {
            ...state.singlePostViewListApi,
            ...clearSingleViewList,
          },
          postViewListDetail: {
            ...state.postViewListDetail,
            ...clearSingleViewDetail,
          },
        },
      };
    case POST_SHARE_DATA_REQUEST:
      return {
        ...state,
        ...{
          shareFeedPostApi: {
            ...state.shareFeedPostApi,
            ...{ shareFeedPostLoading: true },
          },
        },
      };
    case POST_SHARE_DATA_SUCCESS:
      let sharePostSucess = {
        shareFeedPostLoading: false,
        shareFeedPostStatus: action.response.data.status,
        shareFeedPostMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          shareFeedPostApi: { ...state.shareFeedPostApi, ...sharePostSucess },
        },
      };
    case POST_SHARE_DATA_FAILURE:
      let sharePostFailure = {
        shareFeedPostLoading: false,
        shareFeedPostStatus: action.status,
        shareFeedPostMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          shareFeedPostApi: { ...state.shareFeedPostApi, ...sharePostFailure },
        },
      };
    case CLEAR_POST_SHARE_RESPONSE:
      let clearSharePost = {
        shareFeedPostLoading: false,
        shareFeedPostStatus: "",
        shareFeedPostMessage: "",
      };

      return {
        ...state,
        ...{
          shareFeedPostApi: { ...state.shareFeedPostApi, ...clearSharePost },
        },
      };
    case SINGLE_POST_SHARE_DATA_REQUEST:
      return {
        ...state,
        ...{
          shareSingleFeedPostApi: {
            ...state.shareSingleFeedPostApi,
            ...{ shareSingleFeedLoading: true },
          },
        },
      };
    case SINGLE_POST_SHARE_DATA_SUCCESS:
      let singleShareSucess = {
        shareSingleFeedLoading: false,
        shareSingleFeedStatus: action.response.data.status,
        shareSingleFeedMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          shareSingleFeedPostApi: {
            ...state.shareSingleFeedPostApi,
            ...singleShareSucess,
          },
        },
      };
    case SINGLE_POST_SHARE_DATA_FAILURE:
      let singleShareFailure = {
        shareSingleFeedLoading: false,
        shareSingleFeedStatus: action.status,
        shareSingleFeedMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          shareSingleFeedPostApi: {
            ...state.shareSingleFeedPostApi,
            ...singleShareFailure,
          },
        },
      };
    case CLEAR_SINGLE_POST_SHARE_RESPONSE:
      let clearSingleShare = {
        shareSingleFeedStatus: "",
        shareSingleFeedMessage: "",
      };

      return {
        ...state,
        ...{
          shareSingleFeedPostApi: {
            ...state.shareSingleFeedPostApi,
            ...clearSingleShare,
          },
        },
      };
    case CHANGE_SHARE_LIST_DETAIL:
      return {
        ...state,
        ...{
          postShareListDetail: {
            ...state.postShareListDetail,
            ...action.newState,
          },
        },
      };
    case POST_SHARE_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          feedShareListApi: {
            ...state.feedShareListApi,
            ...{ feedShareListLoading: true },
          },
        },
      };
    case POST_SHARE_LIST_DATA_SUCCESS:
      let shareListSucess = {
        feedShareListLoading: false,
        feedShareListStatus: action.response.data.status,
        feedShareListMessage: action.response.data.message,
      };
      let shareLists = {
        shareList: !!action.response.data.list
          ? [
            ...state.postShareListDetail.shareList,
            ...action.response.data.list,
          ]
          : [],
        totalShare: action.response.data.total,
      };
      for (let i in state.allPostDetail.allPost) {
        if (
          state.allPostDetail.allPost[i].post_id ==
          state.postShareListDetail.sharePostId
        ) {
          state.allPostDetail.allPost[i].share_count = !!action.response.data
            .total
            ? action.response.data.total
            : 0;
        }
      }
      return {
        ...state,
        ...{
          feedShareListApi: { ...state.feedShareListApi, ...shareListSucess },
          postShareListDetail: { ...state.postShareListDetail, ...shareLists },
        },
      };
    case POST_SHARE_LIST_DATA_FAILURE:
      let shareFailure = {
        feedShareListLoading: false,
        feedShareListStatus: action.status,
        feedShareListMessage: action.response.data.message,
      };
      let shareListFail = {
        shareList: [],
        totalShare: "",
      };
      return {
        ...state,
        ...{
          feedShareListApi: { ...state.feedShareListApi, ...shareFailure },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...shareListFail,
          },
        },
      };
    case CLEAR_POST_SHARE_LIST_RESPONSE:
      let clearShareList = {
        feedShareListStatus: "",
        feedShareListMessage: "",
      };
      let clearShareDetail = {
        shareList: [],
        totalShare: "",
        shareListPage: 0,
        sharePostId: "",
      };
      return {
        ...state,
        ...{
          feedShareListApi: { ...state.feedShareListApi, ...clearShareList },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...clearShareDetail,
          },
        },
      };
    case SINGLE_POST_SHARE_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlefeedShareListApi: {
            ...state.singlefeedShareListApi,
            ...{ singleShareListLoading: true },
          },
        },
      };
    case SINGLE_POST_SHARE_LIST_DATA_SUCCESS:
      let singleShareListSucess = {
        singleShareListLoading: false,
        singleShareListStatus: action.response.data.status,
        singleShareListMessage: action.response.data.message,
      };
      let SingleShareLists = {
        shareList: !!action.response.data.list
          ? [
            ...state.postShareListDetail.shareList,
            ...action.response.data.list,
          ]
          : [],
        totalShare: action.response.data.total,
      };
      for (let i in state.activeCarousalDetail.activeCarousal) {
        if (i == state.activeCarousalDetail.activeIndex) {
          state.activeCarousalDetail.activeCarousal[i].share_count = !!action
            .response.data.total
            ? action.response.data.total
            : 0;
        }
      }
      return {
        ...state,
        ...{
          singlefeedShareListApi: {
            ...state.singlefeedShareListApi,
            ...singleShareListSucess,
          },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...SingleShareLists,
          },
        },
      };
    case SINGLE_POST_SHARE_LIST_DATA_FAILURE:
      let SingleShareListFailure = {
        singleShareListLoading: false,
        singleShareListStatus: action.status,
        singleShareListMessage: action.response.data.message,
      };
      let SingleShareListFail = {
        shareList: [],
        totalShare: "",
      };
      return {
        ...state,
        ...{
          singlefeedShareListApi: {
            ...state.singlefeedShareListApi,
            ...SingleShareListFailure,
          },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...SingleShareListFail,
          },
        },
      };
    case CLEAR_SINGLE_POST_SHARE_LIST_RESPONSE:
      let clearSingleShareList = {
        singleShareListStatus: "",
        singleShareListMessage: "",
      };
      let clearSingleShareDetail = {
        shareList: [],
        totalShare: "",
        sharePostId: "",
        shareFileId: "",
        singleShareListpage: 0,
      };
      return {
        ...state,
        ...{
          singlefeedShareListApi: {
            ...state.singlefeedShareListApi,
            ...clearSingleShareList,
          },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...clearSingleShareDetail,
          },
        },
      };
    default:
      return state;
  }
};
