import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  acceptRequestApi,
  allNotificationsApi,
  declineMembersRequestApi,
  declineRequestApi,
} from "../../library/api/NotificationApiService";
import { clearParticularPostResponse } from "../Post/PostAction";
import {

  clearAcceptRequestResponse,
  clearDeclineRequestResponse,
  getAllNotificationList,
} from "./NotificationAction";
import NotificationList from "./NotificationList";
import { acceptMembersRequestApi } from "../../library/api/NotificationApiService";
import {
  clearMembersAcceptRequestResponse,
  clearMembersDeclineRequestResponse,
} from "../../modules/AllMembersNotifications/MembersNotificationAction";
import SideBarProfileMenu from "../Home/common/SideBarProfileMenu";

let new_page = 1;
const AllNotification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationState = useSelector((state) => state.NotificationReducer);
  const [isHaveValueOnNotification, setIsHaveValueOnNotification] = useState(false)
  const [count, setCount] = useState(2);
  const [stopNotificationSideEffect, setStopNotificationSideEffect] =
    useState(true);

  const membersNotificationState = useSelector(
    (state) => state.MembersNotificationReducer
  );
  const {
    allNotificationApi: {
      allNotificationResponse,
      notificationCurrentPage,
      allNotificationStatus
    },
    notificationPage,
    NotificationCount,
    notificationData,
  } = notificationState;

  const {
    acceptMembersRequestApi: { acceptMembersRequestStatus },
  } = membersNotificationState;

  const {
    declineMembersRequestApi: { declineMembersRequestStatus },
  } = membersNotificationState;
  const {
    acceptRequestApi: { acceptRequestStatus },
  } = notificationState;
  const {
    declineRequestApi: { declineRequestStatus },
  } = notificationState;
  new_page = notificationPage;
  let NotificationCloneObj = {
    ...notificationData,
  };


  useEffect(() => {
    if (!!declineRequestStatus) {
      dispatch(clearDeclineRequestResponse());
    }
  }, [declineRequestStatus]);

  useEffect(() => {
    if (!!acceptRequestStatus) {
      dispatch(clearAcceptRequestResponse());
    }
  }, [acceptRequestStatus]);

  useEffect(() => {
    if (!!acceptMembersRequestStatus) {
      dispatch(clearMembersAcceptRequestResponse());
    }
  }, [acceptMembersRequestStatus]);

  useEffect(() => {
    if (!!declineMembersRequestStatus) {
      dispatch(clearMembersDeclineRequestResponse());
    }
  }, [declineMembersRequestStatus]);

  //this function is used to accept friend request
  const handleAccept = (data, index) => {
    const bodyParameter = {
      id: data.sender_id,
    };

    // member_request_id
    dispatch(acceptRequestApi(bodyParameter)).then(() => {
      dispatch(allNotificationsApi(notificationPage));
      dispatch(onScreenNotificationsApi());
    });
    Object.keys(notificationData).map((item, no) => {
      for (let i in notificationData[item]) {
        if (data === notificationData[item][i]) {
          if (i == index) {
            notificationData[item].splice(i, 1);
          }
        }
      }
    });
    dispatch(
      getAllNotificationList({
        Today: [...notificationData.Today],
        Earlier: [
          ...notificationData.Earlier,
        ],
      })
    );
  };

  const handleMembersAccept = (data, index) => {
    const bodyParameter = {
      member_request_id: data.check.post_id,
    };

    dispatch(acceptMembersRequestApi(bodyParameter));
    Object.keys(notificationData).map((item, no) => {
      for (let i in notificationData[item]) {
        if (data === notificationData[item][i]) {
          if (i == index) {
            notificationData[item].splice(i, 1);
            dispatch(getAllNotificationList(notificationData));
            dispatch(
              getAllNotificationList({
                Today: [...notificationData.Today],
                Earlier: [
                  ...notificationData.Earlier,
                  // ...resp.response.data.list.Earlier,
                ],
              })
            );
          }
        }
      }
    });
  };

  const handleMembersDecline = (data, index) => {
    const bodyParameter = {
      member_request_id: data.check.post_id,
    };
    dispatch(declineMembersRequestApi(bodyParameter));
    Object.keys(notificationData).map((item, no) => {
      for (let i in notificationData[item]) {
        if (data === notificationData[item][i]) {
          if (i == index) {
            notificationData[item].splice(i, 1);
            dispatch(getAllNotificationList(notificationData));
            dispatch(
              getAllNotificationList({
                Today: [...notificationData.Today],
                Earlier: [
                  ...notificationData.Earlier,
                  // ...resp.response.data.list.Earlier,
                ],
              })
            );
          }
        }
      }
    });
  };

  //this function is used to decline friend request
  const handleDecline = (data, index) => {
    const bodyParameter = {
      id: data.sender_id,
    };
    dispatch(declineRequestApi(bodyParameter)).then(() => {
      dispatch(allNotificationsApi(notificationPage));
      dispatch(onScreenNotificationsApi());
    });
    Object.keys(notificationData).map((item, no) => {
      for (let i in notificationData[item]) {
        if (data === notificationData[item][i]) {
          if (i == index) {
            notificationData[item].splice(i, 1);
          }
        }
      }
    });
  };
  //this function is used when notifications are related to react post, share post, comment post and then redirected to post page .
  const handleRedirect = (data) => {
    dispatch(clearParticularPostResponse());
    if (!data.check.file) {
      history.push("/post?post_id=" + data.check.post_id);
    } else {
      history.push(
        "/post?post_id=" + data.check.post_id + "&file_id=" + data.check.file_id
      );
    }
  };
  const handleOpenProfile = (data) => {
    if (profileData.id == data.sender_id) {

      history.push(`/account/${profileData?.user_slug}`);
    } else {
      history.push(
        `/search-profile/${data.sender_id}
      }`
      // ?individual=${!!data.is_individual ? true : false
      );
    }
  };

  useEffect(() => {
    setCount(count - 1);
    if (notificationCurrentPage == "1" && count == 1) {
      if (allNotificationResponse.Today && stopNotificationSideEffect) {
        dispatch(
          getAllNotificationList({
            Today: [...allNotificationResponse.Today],
            Earlier: [...allNotificationResponse.Earlier],
          })
        );
      }
    }
  }, [allNotificationResponse.Today, allNotificationResponse.Earlier]);

  useEffect(() => {
    if (notificationCurrentPage == "1") {
      dispatch(allNotificationsApi(notificationPage));
    }
  }, [notificationCurrentPage]);

  function getData() {
    if (window.location.pathname == "/all-notification") {
      if (
        NotificationCount !==
        notificationData.Today?.length + notificationData.Earlier?.length
      ) {
        dispatch(allNotificationsApi(notificationPage + 1)).then((resp) => {
          dispatch(
            getAllNotificationList({
              Today: [
                ...notificationData.Today,
                ...resp.response.data.list.Today,
              ],
              Earlier: [
                ...notificationData.Earlier,
                ...resp.response.data.list.Earlier,
              ],
            })
          );
        });
      }
    }
  }

  const fetchMoreData = useCallback(() => {
    setStopNotificationSideEffect(false);
    getData();
  }, [getData]);
  useEffect(() => {
    if (!!notificationData.Today?.length || !!notificationData.Earlier?.length) {
      setIsHaveValueOnNotification(true)
    }
  }, [notificationData])
  return (
    <>
      <section className="search-users bg-primary spacer notification-result">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <SideBarProfileMenu />
            </div>
            <div className="col-md-6 full-md">
              <h4 className="mb-4">Notifications</h4>
              {isHaveValueOnNotification && <div className="search-user-listing card">
                {!!Object.keys(notificationData)?.length && (
                  <div className="card-body">
                    {Object.keys(notificationData).map((item, i) => {
                      return (
                        notificationData[item].length > 0 && (
                          <>
                            <div className="search-users__wrapper bg-white py-3 test">
                              {<h5 className="mb-0">{item}</h5>}
                            </div>
                            <NotificationList
                              NotificationCount={NotificationCount}
                              fetchMoreData={fetchMoreData}
                              allNotificationResponse={notificationData}
                              list={notificationData[item]}
                              handleRedirect={handleRedirect}
                              handleAccept={handleAccept}
                              handleDecline={handleDecline}
                              handleMembersAccept={handleMembersAccept}
                              handleMembersDecline={handleMembersDecline}
                            />
                          </>
                        )
                      );
                    })}
                  </div>
                )}
              </div>
              }
              {!Object.keys(notificationData)?.length && allNotificationStatus && <p className="font-weight-bold h4  h-75 d-flex align-items-center justify-content-end">No Result Found</p>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AllNotification;
