import React, { useEffect, useState } from "react";
import Feature from "../HomeSection/Feature";
import Homepage from "../HomeSection/Homepage";
import "./landingpage.css";
import AOS from "aos";
import "aos/dist/aos.css";

import { useDispatch, useSelector } from "react-redux";
import {
  getLandingPageContentApi,
  getLandingPageIndustries,
} from "../landingContentAction";
import Footer from "../HomeSection/Footer";
import AboutLanding from "../HomeSection/AboutLanding";
import Industries from "./Industries";

export default function index() {
  const dispatch = useDispatch();
  const landingPageContentData = useSelector(
    (state) => state.LandingPageReducer
  );
  const { landingPageContent } = landingPageContentData;
  // STYLE FOR LOADER
  const [loading, setLoading] = useState(true);


  // API CALLS
  useEffect(() => {
    dispatch(getLandingPageContentApi());
    dispatch(getLandingPageIndustries());
  }, []);

  // INITIALIZE AOS PACKAGE FOR ANIMATION ON SCROLL 

  useEffect(() => {
    AOS.init({
      duration: 900,
    });
    AOS.refresh();
  }, [])



  // FOR SET LOADER FALSE
  useEffect(() => {
    if (
      !!landingPageContent?.getLandingPageStatus
    ) {
      setLoading(false)
    } else {
      setLoading(true);
    }
  }, [landingPageContent?.getLandingPageData]);
  return (
    <div className="landing-wrapper">
      {loading === false &&
        <>
          {
            !!landingPageContent?.getLandingPageData &&
            <>
              <Homepage />
              <AboutLanding />
              <Feature />
              <Industries />
              <Footer />
            </>
          }
        </>
      }
    </div>
  );
}
