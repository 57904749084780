import {
  ADD_AVAIALABILITY_API,
  DELETE_AVAILABILITY_API,
  EVENT_NOTIFICATIONS,
  GET_ALL_AVAILABILITY_API,
  GET_ALL_SHARE_MEDIA,
  GET_SINGLE_GROUP_API,
  UPDATE_USER_AVAILABILITY,
} from "../../library/urls";
import { GET_API, POST_API } from "../../main/fetch";
import {
  CHANGE_CHAT_DETAILS,
  CHANGE_FRIEND_LIST_PAGE,
  CLEAR_CHAT_DETAILS,
  CHANGE_SHARE_ATTACHMENTS,
  ALL_FRIEND_AND_GROUP_LIST,
  GET_SINGLE_GROUP_DATA_REQUEST,
  GET_SINGLE_GROUP_DATA_SUCCESS,
  GET_SINGLE_GROUP_DATA_FAILURE,
  CLEAR_CHAT_LIST,
  GET_ALL_SCHEDULE_EVENTS,
  GET_ALL_SHARE_MEDIA_REQUEST,
  GET_ALL_SHARE_MEDIA_SUCCESS,
  GET_ALL_SHARE_MEDIA_FAILURE,
  CLEAR_SCHEDULE_LIST,
  CLEAR_ALL_MEDIA,
  SEND_EVENTS_REQUEST,
  SEND_EVENTS_REQUEST_SUCCESS,
  SEND_EVENTS_REQUEST_FAILURE,
  ADD_AVAILABILITY_REQEST,
  ADD_AVAILABILITY_SUCCESS,
  ADD_AVAILABILITY_FAILURE,
  GET_ALL_AVAILABILITY_REQEST,
  GET_ALL_AVAILABILITY_SUCCESS,
  GET_ALL_AVAILABILITY_FAILURE,
  DELETE_AVAILABILITY_REQUEST,
  DELETE_AVAILABILITY_SUCCESS,
  DELETE_AVAILABILITY_FAILURE,
  CANCEL_EVENT_REQUEST,
  CANCEL_EVENT_SUCCESS,
  CANCEL_EVENT_FAILURE,
  ACCEPT_EVENT_REQUEST,
  ACCEPT_EVENT_SUCCESS,
  ACCEPT_EVENT_FAILURE,
  RESCHEDULE_EVENT_SUCCESS,
  RESCHEDULE_EVENT_FAILURE,
  RESCHEDULE_EVENT_REQUEST,
  DECLINE_EVENT_REQUEST,
  DECLINE_EVENT_SUCCESS,
  DECLINE_EVENT_FAILURE,
  DECLINE_SCHEDUE_EVENTS,
  ACCEPT_SCHEDULE_EVENTS,
  UPDATE_USER_AVAILABILITY_REQUEST,
  UPDATE_USER_AVAILABILITY_SUCCESS,
  UPDATE_USER_AVAILABILITY_FAILURE,
} from "./PoolsChatConstant";

//this function is used for change all message box details like meesage list , message input
const changeChatDetails = (newState) => {

  return { type: CHANGE_CHAT_DETAILS, newState };
};
//this function is used to change friend list page in chat page
const changeFriendListPage = (newState) => {
  return { type: CHANGE_FRIEND_LIST_PAGE, newState };
};
const clearChatDetails = (newState) => {
  return { type: CLEAR_CHAT_DETAILS, newState };
};

const changeShareAttachments = (newState) => {
  return { type: CHANGE_SHARE_ATTACHMENTS, newState };
};

// -----new-merged-action ----//
const allFriendandGroupList = (newState) => {
  return { type: ALL_FRIEND_AND_GROUP_LIST, newState };
};

const getAllScheduleList = (newState) => {
  return { type: GET_ALL_SCHEDULE_EVENTS, newState };
};

const declineSchedule = (newState) => {
  return { type: DECLINE_SCHEDUE_EVENTS, newState };
};

const acceptSchedule = (newState) => {
  return { type: ACCEPT_SCHEDULE_EVENTS, newState };
};

const clearScheduleList = (newState) => {
  return { type: CLEAR_SCHEDULE_LIST, newState };
};

const clearChatList = (newState) => {
  return { type: CLEAR_CHAT_LIST, newState };
};

const clearPreviousMediaData = (newState) => {
  return { type: CLEAR_ALL_MEDIA, newState };
};

const deleteAvailabilityApi = (body) => {
  return {
    [POST_API]: {
      endpoint: `${DELETE_AVAILABILITY_API}`,
      types: [
        DELETE_AVAILABILITY_REQUEST,
        DELETE_AVAILABILITY_SUCCESS,
        DELETE_AVAILABILITY_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};

const addAvailability = (body) => {
  return {
    [POST_API]: {
      endpoint: ADD_AVAIALABILITY_API,
      types: [
        ADD_AVAILABILITY_REQEST,
        ADD_AVAILABILITY_SUCCESS,
        ADD_AVAILABILITY_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};

const getSingleGroupApi = (id) => {
  return {
    [GET_API]: {
      endpoint: GET_SINGLE_GROUP_API(id),
      types: [
        GET_SINGLE_GROUP_DATA_REQUEST,
        GET_SINGLE_GROUP_DATA_SUCCESS,
        GET_SINGLE_GROUP_DATA_FAILURE,
      ],
    },
  };
};

const getAllshareMedia = (body, mediaPage) => {
  return {
    [POST_API]: {
      endpoint: `${GET_ALL_SHARE_MEDIA}?page=${mediaPage}`,
      types: [
        GET_ALL_SHARE_MEDIA_REQUEST,
        GET_ALL_SHARE_MEDIA_SUCCESS,
        GET_ALL_SHARE_MEDIA_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};

const getAllAvailabilityApi = (id) => {
  return {
    [GET_API]: {
      endpoint: `${GET_ALL_AVAILABILITY_API}?user_id=${id}`,
      types: [
        GET_ALL_AVAILABILITY_REQEST,
        GET_ALL_AVAILABILITY_SUCCESS,
        GET_ALL_AVAILABILITY_FAILURE,
      ],
    },
  };
};

const sendEventRequest = (body) => {
  return {
    [POST_API]: {
      endpoint: `${EVENT_NOTIFICATIONS}`,
      types: [
        SEND_EVENTS_REQUEST,
        SEND_EVENTS_REQUEST_SUCCESS,
        SEND_EVENTS_REQUEST_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};

// decline request event //
const declineEventRequest = (body) => {
  return {
    [POST_API]: {
      endpoint: `${EVENT_NOTIFICATIONS}`,
      types: [
        DECLINE_EVENT_REQUEST,
        DECLINE_EVENT_SUCCESS,
        DECLINE_EVENT_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};

const cancleEventRequestApi = (body) => {
  return {
    [POST_API]: {
      endpoint: `${EVENT_NOTIFICATIONS}`,
      types: [CANCEL_EVENT_REQUEST, CANCEL_EVENT_SUCCESS, CANCEL_EVENT_FAILURE],
      body,
      is_form_data: false,
    },
  };
};

const acceptEventRequestApi = (body) => {
  return {
    [POST_API]: {
      endpoint: `${EVENT_NOTIFICATIONS}`,
      types: [ACCEPT_EVENT_REQUEST, ACCEPT_EVENT_SUCCESS, ACCEPT_EVENT_FAILURE],
      body,
      is_form_data: false,
    },
  };
};

const rescheduleEventRequestApi = (body) => {
  return {
    [POST_API]: {
      endpoint: `${EVENT_NOTIFICATIONS}`,
      types: [
        RESCHEDULE_EVENT_REQUEST,
        RESCHEDULE_EVENT_SUCCESS,
        RESCHEDULE_EVENT_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};

const updateUserAvailability = (body) => {
  return {
    [POST_API]: {
      endpoint: `${UPDATE_USER_AVAILABILITY}`,
      types: [
        UPDATE_USER_AVAILABILITY_REQUEST,
        UPDATE_USER_AVAILABILITY_SUCCESS,
        UPDATE_USER_AVAILABILITY_FAILURE,
      ],
      body,
      is_form_data: false,
    },
  };
};
export {
  deleteAvailabilityApi,
  rescheduleEventRequestApi,
  cancleEventRequestApi,
  acceptEventRequestApi,
  addAvailability,
  changeChatDetails,
  changeFriendListPage,
  clearChatDetails,
  changeShareAttachments,
  allFriendandGroupList,
  getSingleGroupApi,
  clearChatList,
  getAllScheduleList,
  getAllshareMedia,
  clearScheduleList,
  sendEventRequest,
  clearPreviousMediaData,
  declineEventRequest,
  declineSchedule,
  acceptSchedule,
  getAllAvailabilityApi,
  updateUserAvailability
};
