const PARTICULAR_POST_DATA_REQUEST = "PARTICULAR_POST_DATA_REQUEST";
const PARTICULAR_POST_DATA_SUCCESS = "PARTICULAR_POST_DATA_SUCCESS";
const PARTICULAR_POST_DATA_FAILURE = "PARTICULAR_POST_DATA_FAILURE";

const CLEAR_PARTICULAR_POST_RESPONSE = "CLEAR_PARTICULAR_POST_RESPONSE";

const CHANGE_PARTICULAR_POST_DETAIL = "CHANGE_PARTICULAR_POST_DETAIL";

const PARTICULAR_POST_REACT_DATA_REQUEST = "PARTICULAR_POST_REACT_DATA_REQUEST";
const PARTICULAR_POST_REACT_DATA_SUCCESS = "PARTICULAR_POST_REACT_DATA_SUCCESS";
const PARTICULAR_POST_REACT_DATA_FAILURE = "PARTICULAR_POST_REACT_DATA_FAILURE";

const CLEAR_POST_REACTION_RESPONSE = "CLEAR_POST_REACTION_RESPONSE";

const PARTICULAR_FILE_REACT_DATA_REQUEST = "PARTICULAR_FILE_REACT_DATA_REQUEST";
const PARTICULAR_FILE_REACT_DATA_SUCCESS = "PARTICULAR_FILE_REACT_DATA_SUCCESS";
const PARTICULAR_FILE_REACT_DATA_FAILURE = "PARTICULAR_FILE_REACT_DATA_FAILURE";

const CLEAR_FILE_REACT_RESPONSE = "CLEAR_FILE_REACT_RESPONSE";

const CHANGE_PARTICULAR_REACT_LIST_DETAILS = "CHANGE_REACT_LIST_DETAILS";

const PARTICULAR_POST_REACT_LIST_DATA_REQUEST =
  "PARTICULAR_POST_REACT_LIST_DATA_REQUEST";
const PARTICULAR_POST_REACT_LIST_DATA_SUCCESS =
  "PARTICULAR_POST_REACT_LIST_DATA_SUCCESS";
const PARTICULAR_POST_REACT_LIST_DATA_FAILURE =
  "PARTICULAR_POST_REACT_LIST_DATA_FAILURE";

const CLEAR_POST_REACT_LIST_RESPONSE = "CLEAR_POST_REACT_LIST_RESPONSE";

const PARTICULAR_FILE_REACT_LIST_DATA_REQUEST =
  "PARTICULAR_FILE_REACT_LIST_DATA_REQUEST";
const PARTICULAR_FILE_REACT_LIST_DATA_SUCCESS =
  "PARTICULAR_FILE_REACT_LIST_DATA_SUCCESS";
const PARTICULAR_FILE_REACT_LIST_DATA_FAILURE =
  "PARTICULAR_FILE_REACT_LIST_DATA_FAILURE";

const CLEAR_SINGLE_FILE_REACT_LIST_RESPONSE =
  "CLEAR_SINGLE_REACTION_LIST_RESPONSE";

const PARTICULAR_POST_COMMENT_LIST_DATA_REQUEST =
  "PARTICULAR_POST_COMMENT_LIST_DATA_REQUEST";
const PARTICULAR_POST_COMMENT_LIST_DATA_SUCCESS =
  "PARTICULAR_POST_COMMENT_LIST_DATA_SUCCESS";
const PARTICULAR_POST_COMMENT_LIST_DATA_FAILURE =
  "PARTICULAR_POST_COMMENT_LIST_DATA_FAILURE";

const CLEAR_PARTICULAR_POST_COMMENT_LIST_RESPONSE =
  "CLEAR_PARTICULAR_POST_COMMENT_LIST_RESPONSE";

const PARTICULAR_POST_COMMENT_DATA_REQUEST =
  "PARTICULAR_POST_COMMENT_DATA_REQUEST";
const PARTICULAR_POST_COMMENT_DATA_SUCCESS =
  "PARTICULAR_POST_COMMENT_DATA_SUCCESS";
const PARTICULAR_POST_COMMENT_DATA_FAILURE =
  "PARTICULAR_POST_COMMENT_DATA_FAILURE";

const CLEAR_PARTICULAR_POST_COMMENT_RESPONSE =
  "CLEAR_PARTICULAR_POST_COMMENT_RESPONSE";

const PARTICULAR_POST_CMT_REPLY_DATA_REQUEST =
  "PARTICULAR_POST_CMT_REPLY_DATA_REQUEST";
const PARTICULAR_POST_CMT_REPLY_DATA_SUCCESS =
  "PARTICULAR_POST_CMT_REPLY_DATA_SUCCESS";
const PARTICULAR_POST_CMT_REPLY_DATA_FAILURE =
  "PARTICULAR_POST_CMT_REPLY_DATA_FAILURE";

const CLEAR_PARTICULAR_CMT_REPLY_RESPONSE =
  "CLEAR_PARTICULAR_CMT_REPLY_RESPONSE";

const PARTICULAR_POST_CMT_REPLY_LIST_DATA_REQUEST =
  "PARTICULAR_POST_CMT_REPLY_LIST_DATA_REQUEST";
const PARTICULAR_POST_CMT_REPLY_LIST_DATA_SUCCESS =
  "PARTICULAR_POST_CMT_REPLY_LIST_DATA_SUCCESS";
const PARTICULAR_POST_CMT_REPLY_LIST_DATA_FAILURE =
  "PARTICULAR_POST_CMT_REPLY_LIST_DATA_FAILURE";

const CLEAR_PARTICULAR_POST_CMT_REPLY_LIST_RESPONSE =
  "CLEAR_PARTICULAR_POST_CMT_REPLY_LIST_RESPONSE";

const CHANGE_PARTICULAR_COMMENT_LIST_DETAILS =
  "CHANGE_PARTICULAR_COMMENT_LIST_DETAILS";

const PARTICULAR_FILE_CMT_DATA_REQUEST = "PARTICULAR_FILE_CMT_DATA_REQUEST";
const PARTICULAR_FILE_CMT_DATA_SUCCESS = "PARTICULAR_FILE_CMT_DATA_SUCCESS";
const PARTICULAR_FILE_CMT_DATA_FAILURE = "PARTICULAR_FILE_CMT_DATA_FAILURE";

const CLEAR_FILE_COMMENT_RESPONSE = "CLEAR_FILE_COMMENT_RESPONSE";

const FILE_COMMENT_LIST_DATA_REQUEST = "FILE_COMMENT_LIST_DATA_REQUEST";
const FILE_COMMENT_LIST_DATA_SUCCESS = "FILE_COMMENT_LIST_DATA_SUCCESS";
const FILE_COMMENT_LIST_DATA_FAILURE = "FILE_COMMENT_LIST_DATA_FAILURE";

const CLEAR_FILE_COMMENT_LIST_RESPONSE = "CLEAR_FILE_COMMENT_LIST_RESPONSE";

const FILE_CMT_REPLY_DATA_REQUEST = "FILE_CMT_REPLY_DATA_REQUEST";
const FILE_CMT_REPLY_DATA_SUCCESS = "FILE_CMT_REPLY_DATA_SUCCESS";
const FILE_CMT_REPLY_DATA_FAILURE = "FILE_CMT_REPLY_DATA_FAILURE";

const CLEAR_FILE_CMT_REPLY_RESPONSE = "CLEAR_SINGLE_POST_CMT_REPLY_RESPONSE";

const FILE_CMT_REPLY_LIST_DATA_REQUEST =
  "SINGLE_POST_CMT_REPLY_LIST_DATA_REQUEST";
const FILE_CMT_REPLY_LIST_DATA_SUCCESS =
  "SINGLE_POST_CMT_REPLY_LIST_DATA_SUCCESS";
const FILE_CMT_REPLY_LIST_DATA_FAILURE =
  "SINGLE_POST_CMT_REPLY_LIST_DATA_FAILURE";

const CLEAR_FILE_CMT_REPLY_LIST_RESPONSE =
  "CLEAR_SINGLE_POST_CMT_REPLY_LIST_RESPONSE";

const PARTICULAR_POST_VIEW_LIST_DATA_REQUEST =
  "PARTICULAR_POST_VIEW_LIST_DATA_REQUEST";
const PARTICULAR_POST_VIEW_LIST_DATA_SUCCESS =
  "PARTICULAR_POST_VIEW_LIST_DATA_SUCCESS";
const PARTICULAR_POST_VIEW_LIST_DATA_FAILURE = "POST_VIEW_LIST_DATA_FAILURE";

const CLEAR_POST_VIEW_LIST_RESPONSE = "CLEAR_POST_VIEW_LIST_RESPONSE";

const CHANGE_PARTICULAR_POST_VIEW_DETAIL = "CHANGE_PARTICULAR_POST_VIEW_DETAIL";

const FILE_VIEW_LIST_DATA_REQUEST = "FILE_VIEW_LIST_DATA_REQUEST";
const FILE_VIEW_LIST_DATA_SUCCESS = "FILE_VIEW_LIST_DATA_SUCCESS";
const FILE_VIEW_LIST_DATA_FAILURE = "FILE_VIEW_LIST_DATA_FAILURE";

const CLEAR_FILE_VIEW_LIST_RESPONSE = "CLEAR_FILE_VIEW_LIST_RESPONSE";

const PARTICULAR_POST_SHARE_DATA_REQUEST = "PARTICULAR_POST_SHARE_DATA_REQUEST";
const PARTICULAR_POST_SHARE_DATA_SUCCESS = "PARTICULAR_POST_SHARE_DATA_SUCCESS";
const PARTICULAR_POST_SHARE_DATA_FAILURE = "PARTICULAR_POST_SHARE_DATA_FAILURE";

const CLEAR_PARTICULAR_POST_SHARE_RESPONSE =
  "CLEAR_PARTICULAR_POST_SHARE_RESPONSE";

const PARTICULAR_FILE_SHARE_DATA_REQUEST = "PARTICULAR_FILE_SHARE_DATA_REQUEST";
const PARTICULAR_FILE_SHARE_DATA_SUCCESS = "PARTICULAR_FILE_SHARE_DATA_SUCCESS";
const PARTICULAR_FILE_SHARE_DATA_FAILURE = "PARTICULAR_FILE_SHARE_DATA_FAILURE";

const CLEAR_FILE_SHARE_RESPONSE = "CLEAR_FILE_SHARE_RESPONSE";

const CHANGE_PARTICULAR_SHARE_LIST_DETAIL =
  "CHANGE_PARTICULAR_SHARE_LIST_DETAIL";

const PARTICULAR_POST_SHARE_LIST_DATA_REQUEST =
  "PARTICULAR_POST_SHARE_LIST_DATA_REQUEST";
const PARTICULAR_POST_SHARE_LIST_DATA_SUCCESS =
  "PARTICULAR_POST_SHARE_LIST_DATA_SUCCESS";
const PARTICULAR_POST_SHARE_LIST_DATA_FAILURE =
  "PARTICULAR_POST_SHARE_LIST_DATA_FAILURE";

const CLEAR_PARTICULAR_POST_SHARE_LIST_RESPONSE =
  "CLEAR_PARTICULAR_POST_SHARE_LIST_RESPONSE";

const FILE_SHARE_LIST_DATA_REQUEST = "FILE_SHARE_LIST_DATA_REQUEST";
const FILE_SHARE_LIST_DATA_SUCCESS = "FILE_SHARE_LIST_DATA_SUCCESS";
const FILE_SHARE_LIST_DATA_FAILURE = "FILE_SHARE_LIST_DATA_FAILURE";

const CLEAR_FILE_SHARE_LIST_RESPONSE = "CLEAR_FILE_SHARE_LIST_RESPONSE";

export {
  PARTICULAR_POST_DATA_REQUEST,
  PARTICULAR_POST_DATA_SUCCESS,
  PARTICULAR_POST_DATA_FAILURE,
  CLEAR_PARTICULAR_POST_RESPONSE,
  CHANGE_PARTICULAR_POST_DETAIL,
  PARTICULAR_POST_REACT_DATA_REQUEST,
  PARTICULAR_POST_REACT_DATA_SUCCESS,
  PARTICULAR_POST_REACT_DATA_FAILURE,
  CLEAR_POST_REACTION_RESPONSE,
  PARTICULAR_FILE_REACT_DATA_REQUEST,
  PARTICULAR_FILE_REACT_DATA_SUCCESS,
  PARTICULAR_FILE_REACT_DATA_FAILURE,
  CLEAR_FILE_REACT_RESPONSE,
  CHANGE_PARTICULAR_REACT_LIST_DETAILS,
  PARTICULAR_POST_REACT_LIST_DATA_REQUEST,
  PARTICULAR_POST_REACT_LIST_DATA_SUCCESS,
  PARTICULAR_POST_REACT_LIST_DATA_FAILURE,
  CLEAR_POST_REACT_LIST_RESPONSE,
  PARTICULAR_FILE_REACT_LIST_DATA_REQUEST,
  PARTICULAR_FILE_REACT_LIST_DATA_SUCCESS,
  PARTICULAR_FILE_REACT_LIST_DATA_FAILURE,
  CLEAR_SINGLE_FILE_REACT_LIST_RESPONSE,
  PARTICULAR_POST_COMMENT_LIST_DATA_REQUEST,
  PARTICULAR_POST_COMMENT_LIST_DATA_SUCCESS,
  PARTICULAR_POST_COMMENT_LIST_DATA_FAILURE,
  CLEAR_PARTICULAR_POST_COMMENT_LIST_RESPONSE,
  PARTICULAR_POST_COMMENT_DATA_REQUEST,
  PARTICULAR_POST_COMMENT_DATA_SUCCESS,
  PARTICULAR_POST_COMMENT_DATA_FAILURE,
  CLEAR_PARTICULAR_POST_COMMENT_RESPONSE,
  PARTICULAR_POST_CMT_REPLY_DATA_REQUEST,
  PARTICULAR_POST_CMT_REPLY_DATA_SUCCESS,
  PARTICULAR_POST_CMT_REPLY_DATA_FAILURE,
  CLEAR_PARTICULAR_CMT_REPLY_RESPONSE,
  PARTICULAR_POST_CMT_REPLY_LIST_DATA_REQUEST,
  PARTICULAR_POST_CMT_REPLY_LIST_DATA_SUCCESS,
  PARTICULAR_POST_CMT_REPLY_LIST_DATA_FAILURE,
  CLEAR_PARTICULAR_POST_CMT_REPLY_LIST_RESPONSE,
  CHANGE_PARTICULAR_COMMENT_LIST_DETAILS,
  PARTICULAR_FILE_CMT_DATA_REQUEST,
  PARTICULAR_FILE_CMT_DATA_SUCCESS,
  PARTICULAR_FILE_CMT_DATA_FAILURE,
  CLEAR_FILE_COMMENT_RESPONSE,
  FILE_COMMENT_LIST_DATA_REQUEST,
  FILE_COMMENT_LIST_DATA_SUCCESS,
  FILE_COMMENT_LIST_DATA_FAILURE,
  CLEAR_FILE_COMMENT_LIST_RESPONSE,
  FILE_CMT_REPLY_DATA_REQUEST,
  FILE_CMT_REPLY_DATA_SUCCESS,
  FILE_CMT_REPLY_DATA_FAILURE,
  CLEAR_FILE_CMT_REPLY_RESPONSE,
  FILE_CMT_REPLY_LIST_DATA_REQUEST,
  FILE_CMT_REPLY_LIST_DATA_SUCCESS,
  FILE_CMT_REPLY_LIST_DATA_FAILURE,
  CLEAR_FILE_CMT_REPLY_LIST_RESPONSE,
  PARTICULAR_POST_VIEW_LIST_DATA_REQUEST,
  PARTICULAR_POST_VIEW_LIST_DATA_SUCCESS,
  PARTICULAR_POST_VIEW_LIST_DATA_FAILURE,
  CLEAR_POST_VIEW_LIST_RESPONSE,
  CHANGE_PARTICULAR_POST_VIEW_DETAIL,
  FILE_VIEW_LIST_DATA_REQUEST,
  FILE_VIEW_LIST_DATA_SUCCESS,
  FILE_VIEW_LIST_DATA_FAILURE,
  CLEAR_FILE_VIEW_LIST_RESPONSE,
  PARTICULAR_POST_SHARE_DATA_REQUEST,
  PARTICULAR_POST_SHARE_DATA_SUCCESS,
  PARTICULAR_POST_SHARE_DATA_FAILURE,
  CLEAR_PARTICULAR_POST_SHARE_RESPONSE,
  PARTICULAR_FILE_SHARE_DATA_REQUEST,
  PARTICULAR_FILE_SHARE_DATA_SUCCESS,
  PARTICULAR_FILE_SHARE_DATA_FAILURE,
  CLEAR_FILE_SHARE_RESPONSE,
  CHANGE_PARTICULAR_SHARE_LIST_DETAIL,
  PARTICULAR_POST_SHARE_LIST_DATA_REQUEST,
  PARTICULAR_POST_SHARE_LIST_DATA_SUCCESS,
  PARTICULAR_POST_SHARE_LIST_DATA_FAILURE,
  CLEAR_PARTICULAR_POST_SHARE_LIST_RESPONSE,
  FILE_SHARE_LIST_DATA_REQUEST,
  FILE_SHARE_LIST_DATA_SUCCESS,
  FILE_SHARE_LIST_DATA_FAILURE,
  CLEAR_FILE_SHARE_LIST_RESPONSE,
};
