import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { scroolTop } from "../../library/utilities/functions";

const TermsServices = () => {
    const history = useHistory()
    useEffect(() => {
        scroolTop();
        $(document).ready(function () {
            document.body.className = 'bg-primary';
            $('.toc__links a[href*=\\#]:not([href=\\#])').on('click', function () {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.substr(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 30
                    }, 1000);
                    return false;
                }
            });
        });
        return () => {
            document.body.className = ''
        }
    }, [])
    return (
        <>
            <div className="container spacer px-3 px-md-0">
                <div className="row mb-3">
                    <div className="col-md-12">
                        <a href="javascript:;" onClick={() => history.goBack()} className="btn btn-sm btn-secondary">Back</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="toc-blk bg-white br-20 p-4">
                            <small className="mb-3 d-block">Effective: December 31, 2021</small>
                            <h1 className="h3">Contract</h1>
                            <p><strong>Disclaimer:</strong> When you open an account with PoolsMagnic, you agree to all our Terms of Service,  Privacy Policy, and Cookie Use.</p>
                            <p>The Terms of Service is a binding contract between the user “you” and “PoolsMagnic”. You agree when you click on “Sign Up”. If you do not agree to this Contract or Terms of Services, you will not be able to open an account with PoolsMagnic if you wish to terminate this Contract at any time. You can close your account.</p>
                            <p>The collection, use, and sharing of your personal data are subject to this Privacy Policy and future updates.</p>


                            <div className="toc-blk__content mb-5" id="future-updates">
                                <h2 className="h4 mb-3">Future Updates</h2>
                                <p>PoolsMagnic is an online Social workplace productivity tool and platform. Individuals’ or businesses using our services to find and be found for business opportunities enhance the work environment. Our Privacy Policy applies to anyone who uses our services. Our registered users share their identities, engage with their network,  find business opportunities or services from service providers, and post and view relevant content. </p>
                                <p>PoolsMagnic will make future updates to the Contract “Terms of Service''. We will modify some sections to the Contract, Privacy Policy,  and Cookies Policy from time to time. We will notify you of any updates to material changes through different means in your email, notification, and  Poolschat before the effective date. So, you can review it. If you continue using your account and our Service, you consent to the updated terms as of their effective date.</p>
                            </div>

                            <div className="toc-blk__content mb-5" id="obligations">
                                <h2 className="h4 mb-3">Obligations:</h2>
                                <p>Using our Services under these conditions:</p>
                                <ul>
                                    <li>
                                        <h4 className="h5">To open an account with us</h4>
                                        <ol>
                                            <li>You must be at least 16 years old. If you provide a Service in PoolsMagnic, you should be of the legal working age of 18 years or older.</li>
                                            <li>You agree to have only one account under your real full name. If you have a business, you can open another account under your business.</li>
                                            <li>You agree not to create an account with false information. Creating such an account is a violation of our terms.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <h4 className="h5">Your PoolsMagnic Account</h4>
                                        <p>You agree to </p>
                                        <ol>
                                            <li>Use a strong password, keep the password confidential, and not share your account with anyone else.</li>
                                            <li>Follow our rules and the relevant laws applicable in your country.</li>
                                            <li>Your responsibility is to fix anything that happens in your account, close your account,  and report misuse. </li>
                                        </ol>
                                    </li>

                                    <li>
                                        <h4 className="h5">Your PoolsMagnic Account</h4>
                                        <ol>
                                            <li>PoolsMagnic send notices through the platform, messages to your phone, and emails.</li>
                                            <li>You agree that:</li>
                                        </ol>
                                        <ul>
                                            <li>We will provide you with notices in various ways through notifications on our platform, Emails, and text messages to your phone.</li>
                                            <li>You will keep your information up to date, so you do not miss any important notices from us. </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <h4 className="h5">Sharing</h4>
                                        <ul>
                                            <li>When you post, comment, and react, other users can see your action. Our Service allows you to reshare your post to a newsfeed, to PoolsChat, or offsite. You are not obligated to share anything you do not want others to see.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                            <div className="toc-blk__content mb-5" id="your-rights-and-limits">
                                <h2 className="h4 mb-3">Your Rights and Limits:</h2>
                                <ul>
                                    <li>
                                        <h4 className="h5">Your license to PoolsMagnic:</h4>
                                        <ol>
                                            <li>Between you and PoolsMagnic,  you own all of the content, posts, and information you provide to us, but you also grant us a non-exclusive license to it.</li>
                                            <li>A worldwide, transferable, and sublicensable right to use, copy, modify, distribute, publish, and process information and content that you provide through our Services and the services of others, without any further consent, notice and/or compensation to you or others. You can end this license for specific content by deleting such content from the Services. You can generally delete this content by closing your account, except (a) to the extent you shared it with others as part of the Service, and they copied, re-shared it, or stored it and (b) for the reasonable time it takes to remove it from backup and other systems.</li>
                                            <li>You agree to provide information and content that you have the right to share and that your PoolsMagnic profile will be truthful. You also agree not to share content or information that violates the law nor anyone’s rights, including intellectual property rights. PoolsMagnic may be required by law to remove certain information or content that violates the law in certain countries.</li>
                                            <li>Between you and PoolsMagnic, you agree that if your content includes personal data, we may access, store, process, and use any information or personal data that you provide to Poolmagnic in accordance with terms of the Privacy Policy.</li>
                                        </ol>
                                    </li>
                                </ul>
                            </div>


                            <div className="toc-blk__content mb-5" id="third-party-content-Sites-and-apps">
                                <h2 className="h4 mb-3">Third-Party Content, Sites, and Apps</h2>
                                <ul>

                                    <li>Your use of third-party content and information posted on PoolsMagnic is at your own risk.</li>
                                    <li>Third-parties may offer their own products and services through our Services, and we are not responsible for their activities.</li>
                                    <li>Using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive, or otherwise harmful. PoolsMagnic generally does not review content provided by our users. You agree that we are not responsible for other users' content or information. We cannot always prevent this misuse of our Services, and you agree that we are not responsible for any such misuse. </li>
                                    <li>PoolsMagnic may help you link up with other users who are offering their services (translation, coding accounting, etc.) or with other users who are seeking your services. PoolsMagnic does not perform or employ individuals or companies to perform these services. You must be at least 18 years of age to offer, perform, or procure these services. You acknowledge that PoolsMagnic does not supervise, direct, control, or monitor users in the performance of these services and agree to the following:
                                        <ul className="mt-3">
                                            <li>PoolsMagnic is not responsible for the offering, performance, or procurement of these services;</li>
                                            <li>PoolsMagnic does not endorse any particular services offered by a user;</li>
                                            <li>Nothing shall create an employment, agency, or joint venture relationship between PoolsMagnic and any user offering services. If you are a user offering services, you represent and warrant that you have all the required licenses and that you will provide services consistent with our Professional Community Policies.</li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>

                            <div className="toc-blk__content mb-5" id="intellectual-property-rights">
                                <h2 className="h4 mb-3">Intellectual Property Rights</h2>
                                <ul>
                                    <li>PoolsMagnic reserves all of its intellectual property rights stipulated in the Services. Trademarks and Logos used in connections with the Services are the trademarks of their respective owners. PoolsMagnic, name, logo, and other PoolsMagnic trademarks, Service marks, graphics, and logos used for our Services are trademarks or registered trademarks of PoolMagnic.</li>
                                </ul>
                            </div>

                            <div className="toc-blk__content mb-5" id="limit-of-liability">
                                <h2 className="h4 mb-3">Limit of Liability:</h2>

                                <ul>
                                    <li><h4 className="h5">No Warranty</h4>
                                        <p><strong>Disclaimer:</strong>  PoolsMagnic makes no representation or warranty about the Services, including any representation that our Service will be uninterrupted or Error-Free, and provides the Services (including content and information) on an “As is'' and “as available” basis. To the fullest extent permitted under applicable law, PoolsMagnic disclaims any implied or statutory warranty, including any implied warranty of title, the accuracy of data, non-infringement, merchantability, or fitness for a  particular purpose.</p>
                                    </li>

                                    <li id="exclusion-of-liability">
                                        <h4 className="h5">Exclusion of Liability:</h4>
                                        <p><strong>Disclaimer:</strong> To the fullest extent permitted by law (and unless PoolsMagnic has entered into a separate written agreement that overrides this Contract), PoolsMagnic will not be liable in connection with this Contract for lost profits or lost business opportunities, reputation, loss of data, or changes to your information or content or any indirect, incidental, consequential, special or punitive damages.</p>
                                        <ul>
                                            <li>PoolsMagnic will not be liable to you in connection with this Contract for any amount that exceeds the total fees paid or payable by you to PoolsMagnic for the Services during the term of this Contract.</li>
                                        </ul>
                                    </li>

                                </ul>
                            </div>

                            <div className="toc-blk__content mb-5" id="basis-of-the-bargain-exclusions">
                                <h2 className="h4 mb-3">Basis of the Bargain; Exclusions</h2>
                                <ul>
                                    <li>The limitations of liability constitute part of the basis of the bargain between you and PoolsMagnic. These limitations shall apply to all liability claims even if PoolsMagnic has been notified of the possibility of any such damage and even if these remedies fail for their essential purpose.</li>
                                    <li>The limitations of liability do not apply to liability for death, personal injury, fraud, gross negligence, or intentional misconduct. Nor do they apply in cases of negligence where a material obligation has been breached. A material obligation is defined as an obligation that forms a prerequisite to our delivery of Services on which you may reasonably rely, but only to the extent that the damages were directly caused by the breach and were foreseeable upon conclusion of this Contract and to the extent that they are typical in the context of this Contract.</li>
                                </ul>
                            </div>

                            <div className="toc-blk__content mb-5" id="termination">
                                <h2 className="h4 mb-3">Termination </h2>
                                <p><strong>Disclaimer:</strong> You and PoolsMagnic may terminate this Contract at any time without the need of providing a notice. On termination, you lose the right to access or use the Services. </p>
                            </div>

                            <div className="toc-blk__content mb-5" id="governing-law-and-dispute-resolution">
                                <h2 className="h4 mb-3">Governing Law and Dispute resolution </h2>
                                <ul>
                                    <li>In the unlikely event we end up in a legal dispute, you and PoolsMagnic agree to resolve based on country or state law. </li>
                                    <li>This section shall not deprive you of any mandatory consumer protections under the laws of the country at which we direct services to you, where you have your habitual residence. </li>
                                    <li>If a court with authority over this Contract finds any part of it unenforceable, you and PoolMagnic agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and PoolsMagnic agree to ask the court to remove that unenforceable part and still enforce the rest of this Contract.</li>
                                    <li>This Contract (including additional terms that we may provide when you engage with a feature of the Services) is the only agreement between us regarding the Services. It supersedes all prior agreements for the Services.</li>
                                    <li>If PoolsMagnic does not act to enforce a breach of this Contract, that does not mean that PoolsMagnic has waived its right to enforce this Contract. You may not assign or transfer this Contract (or your membership or use of Services) to anyone without PoolsMagnic’s consent. However, you agree that PoolsMagnic may assign this Contract to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to this Contract.</li>
                                </ul>
                            </div>


                            <div className="toc-blk__content mb-5" id="community-guidelines">
                                <h2 className="h4 mb-3">Community Guidelines; PoolsMagnic “ Dos and Don’ts”: </h2>
                                <ul>
                                    <li><h4 className="h5"><strong>Do:</strong> You agree that you will:</h4>
                                        <ol>
                                            <li>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;</li>
                                            <li>Provide accurate information to us and keep it updated;</li>
                                            <li>Use your real name on your profile; </li>
                                            <li>Professionally use the Services.</li>
                                        </ol>

                                    </li>

                                    <li><h4 className="h5"><strong>Don’ts:</strong> You agree that you will not:</h4>
                                        <ol>
                                            <li>Create a false identity on PoolsMagnic, misrepresent your identity, create a profile for anyone other than yourself (a real person), or use or attempt to use another’s account;</li>
                                            <li>Develop, support, or use software, devices, scripts, robots, or any other means or processes (including crawlers, browser plugins, and add-ons or any other technology) to scrape the Services or otherwise copy profiles and other data from the Services;</li>
                                            <li>Override any security feature or bypass or circumvent any access controls or use limits of the Service (such as caps on keyword searches or profile views);</li>
                                            <li>Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of PoolsMagnic;</li>
                                            <li>Disclose information that you do not have the consent to disclose (such as confidential information of others (including your employer);</li>
                                            <li>Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets, or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts or other content of others without their permission, which they may give by posting under a Creative Commons license;</li>
                                            <li>Violate the intellectual property or other rights of PoolsMagnic, including, without limitation, (i) copying or distributing our learning videos or other materials or (ii) copying or distributing our technology, unless it is released under open-source licenses; (iii) using the word “PoolsMagnic” or our logos in any business name, email, or URL except as provided in the Brand Guidelines;</li>
                                            <li>Post anything that contains software viruses, worms, or any other harmful code;</li>
                                            <li>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;</li>
                                            <li>Imply or state that you are affiliated with or endorsed by PoolsMagnic without our express consent (e.g., representing yourself as an accredited PoolsMagnic trainer);</li>
                                            <li>Rent, lease, loan, trade, sell/ resell or otherwise monetize the Services or related data or access to the same, without PoolsMagnic’s consent;</li>
                                            <li>Deep-link to our Services for any purpose other than to promote your profile or a group on our Services, without PoolsMagnic’s consent;</li>
                                            <li>Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages;</li>
                                            <li>Monitor the Services’ availability, performance, or functionality for any competitive purpose;</li>
                                            <li>Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services;</li>
                                            <li>Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included in the Services);</li>
                                            <li>Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms); and/or</li>
                                            <li>Violate the Professional Community Policies or any additional terms concerning a specific Service provided when you sign up for or start using such Service, and the Bing Maps terms where applicable.</li>

                                        </ol>
                                    </li>

                                </ul>
                            </div>

                            <div className="toc-blk__content mb-5" id="complaints-regarding-content">
                                <h2 className="h4 mb-3">Complaints Regarding Content: </h2>
                                <ul>
                                    <li>We respect the intellectual property rights of others. We require that information posted by users be accurate and not in violation of the intellectual property rights or other rights of third parties. We provide a policy and process for complaints concerning content posted by our users.</li>
                                </ul>
                            </div>

                            <div className="toc-blk__content mb-5" id="how-to-contact-us">
                                <h2 className="h4 mb-3">How to contact us: </h2>
                                <ul>
                                    <li>You can email us at <a href="mailto:pools.support@poolsmagnic.com" className="highlight-txt">pools.support@poolsmagnic.com</a> or mail us at 1662 Coughlin St, Laramie, WY 82072, USA.</li>
                                </ul>
                            </div>

                        </div>

                    </div>

                    <div className="col-md-4">
                        <div className="bg-white br-20 p-4 sticky-widget">
                            <h3 className="h4 mb-3">Useful Information</h3>
                            <div className="toc__links">
                                <ul>
                                    <li><a href="#future-updates" className="link-color">Future Updates</a></li>
                                    <li><a href="#obligations" className="link-color">Obligations</a></li>
                                    <li><a href="#your-rights-and-limits" className="link-color">Your Rights and Limits</a></li>
                                    <li><a href="#third-party-content-Sites-and-apps" className="link-color">Third-Party Content, Sites, and Apps</a></li>
                                    <li><a href="#intellectual-property-rights" className="link-color">Intellectual Property Rights</a></li>
                                    <li><a href="#limit-of-liability" className="link-color">Limit of Liability</a></li>
                                    <li><a href="#exclusion-of-liability" className="link-color">Exclusion of Liability</a></li>
                                    <li><a href="#basis-of-the-bargain-exclusions" className="link-color">Basis of the Bargain; Exclusions</a></li>
                                    <li><a href="#termination" className="link-color">Termination </a></li>
                                    <li><a href="#governing-law-and-dispute-resolution" className="link-color">Governing Law and Dispute resolution </a></li>
                                    <li><a href="#community-guidelines" className="link-color">Community Guidelines; PoolsMagnic “ Dos and Don’ts”:</a></li>
                                    <li><a href="#complaints-regarding-content" className="link-color">Complaints Regarding Content</a></li>
                                    <li><a href="#how-to-contact-us" className="link-color">How to contact us:</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default TermsServices