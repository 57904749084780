import axios from "axios";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import {
  getManageAccountApi,
  getProfileApi,
} from "../../library/api/AccountApiService";
import { clearCookies, getCookie } from "../../library/utilities/functions";
import { API_BASE_URL, SOCKET } from "../../library/urls";
import { changeLoginIndividualInput } from "../Signup/SignupActions";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ManageAccount = () => {
  let profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const history = useHistory();
  const manageAccount = useSelector((state) => state.AccountReducer);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [businessName, setBusinessName] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneVisibility, setPhoneVisibility] = useState(0);
  const [profileVisibility, setProfileVisibility] = useState(0);
  const [error, setError] = useState(false);
  const [isValidphoneValue, setIsValidPhoneValue] = useState(false);
  const [countryTelNum, setCountryTelNum] = useState("");
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [openConfirmModel, setConfirmModel] = useState(false);
  const [phoneValidationError, setPhoneValidationError] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    getMangeAccountApi: { getMangeAccountData },
  } = manageAccount;

  const handleInputChange = (telNumber) => {
    if (telNumber !== undefined) {
      if (isValidPhoneNumber(telNumber) === false) {
        setIsValidPhoneValue(true);
      } else {
        setIsValidPhoneValue(false);
      }
      setPhoneValidationError(false);
      let phoneNumber = parsePhoneNumber(telNumber);
      if (phoneNumber !== undefined) {
        var { countryCallingCode, nationalNumber } = phoneNumber;
        setCountryTelNum("+" + countryCallingCode + nationalNumber);
        dispatch(changeLoginIndividualInput({ isShowPhone: 1 }));
        dispatch(changeLoginIndividualInput({ cntCode: countryCallingCode }));
        if (telNumber.split("+" + countryCallingCode).length > 1) {
          dispatch(
            changeLoginIndividualInput({
              phoneNo: telNumber.split("+" + countryCallingCode)[1],
            })
          );
        }
      }
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    if (countryTelNum?.length !== 0 && isValidphoneValue) {
      setShowPhoneError(true);
    } else {
      setShowPhoneError(false);
    }
    const validation =
      getMangeAccountData.account_type == "business"
        ? !!businessName?.length &&
        !!name?.length &&
        !!phone?.length &&
        !isValidphoneValue
        : !!name?.length && !!phone?.length && !isValidphoneValue;
    if (validation) {
      const data = new FormData();
      data.append("name", name);
      data.append("phone", countryTelNum);
      if (getMangeAccountData.account_type == "business") {
        data.append("business_name", businessName);
      }
      data.append("phone_visibility", phoneVisibility);
      data.append("profile_visibility", profileVisibility);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      axios(API_BASE_URL + "/update-manage-account", config).then((res) => {
        dispatch(getProfileApi());
        addToast(res.data.message, {
          appearance: "success",
          autoDismiss: true,
     
        });
        history.push("/newsfeeds");
        setLoading(false);
      });
    } else {
      setError(true);
      setPhoneValidationError(true);
      setLoading(false);
    }
  };
  const handleDeleteAccount = () => {
    let config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
    };
    axios(API_BASE_URL + "/account-delete", config).then((res) => {
      if (!!res.data.message.length) {
        addToast(" You have successfully deactivated your account", {
          appearance: "success",
          autoDismiss: true,
     
        });
        SOCKET.emit("is_frnd_active", {
          user_id: profileData.id,
          is_online: 0,
        });
        SOCKET.emit("getFrndOnlineList", {
          user_id: profileData.id,
        });
        setTimeout(() => {
          window.location.replace("/");
          clearCookies();
        }, 2000);
      }
    });
  };
  useEffect(() => {
    if (!!Object.keys(getMangeAccountData)?.length) {
      setBusinessName(getMangeAccountData.business_name);
      setName(getMangeAccountData.name);
      setPhone(getMangeAccountData.phone);
      setPhoneVisibility(getMangeAccountData.phone_visibility);
      setProfileVisibility(getMangeAccountData.profile_visibility);
      if (!!getMangeAccountData?.phone) {
        if (getMangeAccountData?.phone?.charAt(0) !== "+") {
          setCountryTelNum(
            getMangeAccountData.country_code + getMangeAccountData.phone
          );
        } else {
          setCountryTelNum(getMangeAccountData.phone);
        }
      } else {
        setCountryTelNum("+1");
      }

    }
  }, [getMangeAccountData]);
  useEffect(() => {
    dispatch(getManageAccountApi());
  }, []);


  return (
    <>
      {!!Object.keys(getMangeAccountData)?.length && (
        <section className="manage-account spacer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="profile__box custom-shadow">
                  <div className="profile__box__heading">
                    <h3 className="h4">Manage My Account</h3>
                  </div>
                  <div className="manage-account__items">
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="h5">Account Setting</span>
                      <button
                        className="btn btn-sm btn-secondary px-3 d-none d-sm-block"
                        onClick={() => {
                          setConfirmModel(true);
                        }}
                      >
                        Delete Account
                      </button>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Full Name</label>
                          <input
                            type="text"
                            name="f-name"
                            className="form-control"
                            placeholder="Enter your name"
                            value={name.slice(0, 24)}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        {name.length > 24 ? (
                          <p className="error-message" style={{ color: "red" }}>
                            You can not exceed limit above 24 character
                          </p>
                        ) : null}
                        {error && name?.length == 0 ? (
                          <p className="error-message" style={{ color: "red" }}>
                            Please add the name{" "}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <div className="country text-left">
                            <PhoneInput
                              className="signup-input "
                              value={countryTelNum}
                              onChange={(value) => handleInputChange(value)}
                              placeholder="Phone Number"
                            />
                            {error &&
                              countryTelNum &&
                              showPhoneError &&
                              phoneValidationError ? (
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                Please add the valid phone number{" "}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {getMangeAccountData.account_type == "business" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Company Name</label>
                            <input
                              type="text"
                              name="company-name"
                              className="form-control"
                              placeholder="Enter your company name"
                              value={businessName.slice(0, 24)}
                              onChange={(e) => setBusinessName(e.target.value)}
                            />
                          </div>
                          {businessName.length > 24 ? (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              You can not exceed limit above 24 character
                            </p>
                          ) : null}
                          {error && businessName?.length == 0 ? (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              Please add the business name{" "}
                            </p>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="manage-account__items mt-4 customise-profile">
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="h5">Privacy Center</span>
                    </div>
                    <div className="row mt-3 toggle">
                      <div className="col-md-12 mb-3">
                        <span>Phone number</span>
                        <div className="switch">
                          <input
                            type="checkbox"
                            id="phone-privacy"
                            name="phone-privacy"
                            value={phoneVisibility}
                            onChange={(e) =>
                              e.target.checked
                                ? setPhoneVisibility(1)
                                : setPhoneVisibility(0)
                            }
                            checked={phoneVisibility}
                          />
                          <label
                            htmlFor="phone-privacy"
                            className="ml-0"
                          ></label>
                        </div>
                      </div>

                      {getMangeAccountData.account_type == "business" && (
                        <div className="col-md-12">
                          <span>Your Profile Photo & Name Visibility</span>
                          <div className="switch">
                            <input
                              type="checkbox"
                              id="profile-privacy"
                              name="profile-privacy"
                              checked={profileVisibility}
                              onChange={(e) =>
                                e.target.checked
                                  ? setProfileVisibility(1)
                                  : setProfileVisibility(0)
                              }
                            />
                            <label
                              htmlFor="profile-privacy"
                              className="ml-0"
                            ></label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-right mt-3 btn-wrapper">
                    <button
                      className="btn btn-secondary mr-3 d-sm-none"
                      onClick={() => {
                        setConfirmModel(true);
                      }}
                    >
                      Delete Account
                    </button>
                    <button
                      className="btn btn-blue"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Modal
        show={openConfirmModel}
        onHide={() => setConfirmModel(false)}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-size"
      >
        <div className="modal-body text-center">
          <button
            onClick={() => {
              setConfirmModel(false);
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <p className="pt-4">Are you sure you want to delete your account?</p>
          <div className="alert-btn mt-3 pb-4">
            <a
              href="javascript:;"
              onClick={() => handleDeleteAccount()}
              className="btn btn-secondary btn-sm mr-3"
            >
              Yes
            </a>
            <a
              href="javascript:;"
              onClick={() => {
                setConfirmModel(false);
              }}
              className="btn btn-blue btn-sm"
            >
              No
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManageAccount;
