import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../../urls";
import parse from "html-react-parser";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Controller, EffectCards, EffectFade } from "swiper";
import { Swiper, SwiperSlide, SwiperOptions } from "swiper/react";
import { Keyboard, Scrollbar, Navigation, Autoplay, Pagination } from "swiper";

import AOS from "aos";
import "aos/dist/aos.css";
import { ClipLoader } from "react-spinners";
export default function Homepage() {
  const landingPageContentData = useSelector(
    (state) => state.LandingPageReducer.landingPageContent
  );
  const toolbarState = useSelector((state) => state.ToolbarReducer);
  const [firstSwiper, setFirstSwiper] = useState();
  const [secondSwiper, setSecondSwiper] = useState(null);
  const [sliderData, setSliderData] = useState([]);
  const { getLandingPageData } = landingPageContentData;
  const {
    onScreenNotificationApi: { onScreenNotificationStatus },
  } = toolbarState;

  useEffect(() => {
    AOS.init({
      duration: 900,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    if (!!getLandingPageData) {
      setSliderData(getLandingPageData?.slider);
    }
  }, [getLandingPageData]);

  useEffect(() => {
    if (!!sliderData) {
      const target = document.querySelectorAll(
        ".hero__textslider__items__inner .heading-h1"
      );
      Splitting({ target: target, by: "words" });
    }
  }, [sliderData]);

  return (
    <div id="home">
      <section className="hero spacer body-spacer">
        {!!getLandingPageData && (
          <>
            <div className="container">
              <div className="row align-items-center  flex-column-reverse flex-lg-row">
                <div className="col-lg-7 col-md-12 pr-lg-5">
                  <Swiper
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: true,
                    }}
                    speed={1000}
                    draggable={false}
                    allowTouchMove={false}
                    pagination={{
                      el: ".swiper-pagination",
                      clickable: true,
                    }}
                    effect="fade"
                    fadeEffect={{
                      crossFade: true,
                    }}
                    modules={[Controller, EffectFade, Pagination, Autoplay]}
                    onSwiper={setFirstSwiper}
                    controller={{ control: secondSwiper }}
                    className="mySwiper"
                  >
                    <div id="hero__textslider" className="swiper">
                      <div className="swiper-wrapper">
                        {!!sliderData &&
                          sliderData.map((item, index) => (
                            <SwiperSlide key={index}>
                              <div className="swiper-slide  hero__textslider__items mt-4 mt-lg-0">
                                <div
                                  className="hero__textslider__items__inner splitting"
                                  data-splitting
                                >
                                  <h1 className="heading-h1 mb-0 font-typo-bold">
                                    <span className="theme-text-primary">
                                      {item?.title_first_word}
                                      {
                                        <div className="line-image">
                                          <img
                                            src="/assets/images/line-shape.svg"
                                            alt="line"
                                          />
                                        </div>
                                      }
                                    </span>{" "}
                                    {item?.title}
                                  </h1>
                                  <div className="my-3">
                                    {item && parse(item?.description)}
                                  </div>
                                  <div className="hero__textslider__btn py-2">
                                    <Link
                                      to="/signup"
                                      className="btn btn-primary mt-3 d-none d-md-inline-block"
                                    >
                                     Get Started
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                      </div>
                    </div>
                  </Swiper>
                </div>

                <div className="col-lg-5 col-md-12">
                  <Swiper
                    pagination={{
                      el: ".swiper-pagination",
                      clickable: true,
                    }}
                    effect={"fade"}
                    fadeEffect={{
                      crossFade: true,
                    }}
                    draggable={false}
                    allowTouchMove={false}
                    speed={1000}
                    modules={[Controller, EffectFade, Autoplay]}
                    onSwiper={setSecondSwiper}
                    controller={{ control: firstSwiper }}
                    className="mySwiper2"
                  >
                    <div className="hero__media slider swiper">
                      <div className="swiper-wrapper">
                        {!!sliderData &&
                          sliderData.map((item, index) => (
                            <SwiperSlide key={index}>
                              <div className="swiper-slide hero__mediaslider__items">
                                <img
                                  className="w-100"
                                  src={IMAGE_BASE_URL + item.image}
                                  alt="slide"
                                />
                              </div>
                            </SwiperSlide>
                          ))}
                      </div>
                    </div>
                  </Swiper>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="hero__controls">
                    <div className="swiper-pagination"></div>
                  </div>
                </div>
              </div>
              <div className="hero__controls d-block d-md-none">
                <Link to="/signup" className="btn btn-primary mt-3 d-block">
                  {" "}
                Get Started
                </Link>
                <Link to="/signup" className="btn btn-outline mt-3 d-block">
                  {" "}
                  Sign Up
                </Link>

                <Link to="/login" className="btn btn-outline mt-3 d-block">
                  {" "}
                  Login
                </Link>
              </div>
            </div>
          </>
        )}
      </section>
    </div>
  );
}
