import { useDispatch, useSelector } from "react-redux";
import "../LandingPage/HomeSection/landingpage.css";
import "./searchBlogdate.css";
import { IMAGE_BASE_URL } from "../../../urls";
import {
  getAllBlogsApi,
  getRecentBlogApi,
  getSearchBlogsApi,
} from "./landingContentAction";
import Footer from "./HomeSection/Footer";
import { ClipLoader } from "react-spinners";
import parse from "html-react-parser";
import RecentBlog from "./RecentBlog";
import { useEffect, useState } from "react";

import Allblogs from "./Allblogs";

import { DateRangePicker, Stack } from "rsuite";
import moment from "moment";

export default function BlogPage() {
  const dispatch = useDispatch();
  const [bolgsData, setBlogsData] = useState({});
  const [recentBlogs, setRecentBlogs] = useState([]);
  const allBlogPageContent = useSelector((state) => state.blogPageReducer);
  const [searchCLick, setSearchClick] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { allBlogsData, recentBlogsData, searchBlogsDetails } =
    allBlogPageContent;


  // SET RESPONSE COMING FROM REDUCER's TO STATE //

  useEffect(() => {
    if (
      Object.keys(allBlogsData?.getAllBlogsData)?.length > 0 &&
      !!recentBlogsData?.getRecentBlogsData
    ) {
      setBlogsData(allBlogsData?.getAllBlogsData);
      setRecentBlogs(recentBlogsData?.getRecentBlogsData);
    }
  }, [allBlogsData?.getAllBlogsData, recentBlogsData?.getRecentBlogsData]);

  useEffect(() => {
    if (!!Object.keys(bolgsData)?.length > 0 && !!recentBlogs) {
      setLoading(false)
    } else {
      setLoading(true);
    }
  }, [bolgsData]);

  // SUBMIT SEARCH DATA ON BLOG SEARCH CLICK
  const handleSumbit = (e) => {
    e.preventDefault();
    dispatch(getSearchBlogsApi(searchData, startDate, endDate));

    setSearchData("");
    if (searchData !== "" || (startDate !== "" && endDate !== "")) {
      setSearchClick(true);
    } else {
      setSearchClick(false);
    }
  };

  // SET STATE IF USER CLICK ON SEARCH//
  useEffect(() => {
    if (searchCLick === true) {
      setBlogsData(searchBlogsDetails?.getSearchBlogData);
    }
  }, []);

  // SET START & END DATE TO SEARCH BLOG
  const handleDateInput = (e) => {
    if (e?.length) {
      if (e.length > 0) {
        setStartDate(moment(e[0]).format("YYYY-MM-DD"));
      }

      if (e.length === 2) {
        setEndDate(moment(e[1]).format("YYYY-MM-DD"));
      }
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  return (
    <div className="landing-wrapper">
      {loading === false &&
        <>
          <section className="spacer pb-0 hero">
            <section className="blog-spacer-top theme-primary-bg text-white section-bright body-spacer search-wrap body-margin pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-lg-9 text-center mx-auto">
                    <div className="recent-blog__descp"></div>
                    <h1 className="font-weight-bold h3">
                      {!!bolgsData?.blogTopSection &&
                        bolgsData?.blogTopSection?.blog_heading}
                    </h1>
                    <div className="px-0 px-sm-4 px-md-0 mx-auto my-3">
                      {bolgsData?.blogTopSection &&
                        parse(bolgsData?.blogTopSection.blogDescription)}

                      <form method="post"
                        
                        className="mt-4 row gap-y">
                        <div className="col-sm-6 col-md-5 px-sm-1">
                          <input
                            type="text"
                            name="search"
                            className="form-control text-dark w-100"
                            value={searchData}
                            onChange={(e) => setSearchData(e.target.value)}
                            placeholder="Search"
                          />
                        </div>

                        <div className="col-sm-6 col-md-5 px-sm-1">
                          <DateRangePicker
                            appearance="default"
                            className="date-picker-field"
                            onChange={handleDateInput}
                            showOneCalendar
                            format="yyyy-MM-dd"
                            ranges={[]}
                            placeholder="yyyy-mm-dd - yyyy-mm-dd"
                          />
                        </div>

                        <div className="col-sm-12 col-md-2 px-sm-1">
                          <button className="btn w-100" onClick={handleSumbit}>
                            Search
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>

          {searchCLick === false && <RecentBlog recentBlogs={recentBlogs} />}

          <Allblogs setSearchClick={setSearchClick} searchCLick={searchCLick} />

          <Footer />
        </>
      }
    </div>
  );
}
