import React, { useEffect, useRef, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { IMAGE_BASE_URL, MEDIA_BASE_URL, SOCKET } from "../../library/urls";
import { getCookie } from "../../library/utilities/functions";
import { clearScheduleList, getAllScheduleList } from "./PoolsChatAction";
import { useDispatch, useSelector } from "react-redux";
// import Scrollbars from "react-custom-scrollbars";

import { TabView, TabPanel } from "primereact/tabview";

import "react-perfect-scrollbar/dist/css/styles.css";

import AppointMentDetails from "./AppointMentDetails";
import MediaPage from "./MediaPage";
import { ListData } from "../Account/common/SellerStatusListData";

export default function MediaBox({
  poolsChatState,
  searchProfileResponse,
  handleOpenRequestModal,
  messageDetails,
  changEventstate,
  setMediaPage,
  mediaPage,
}) {
  // redux //
  const dispatch = useDispatch();
  const {
    chatDetails: { currenUserId },
  } = poolsChatState;
  const allChatList = useSelector((state) => state.chatListReducer);
  const getAllMedia = useSelector((state) => state.PoolsChatReducer);

  const {
    getAllShareMedia: {
      getShareMediaData,
      totalCounts,
      getShareMediaDataLoading,
    },
  } = getAllMedia;

  const {
    getScheduleListData: { getScheduleList, getScheduleListCounts },
  } = allChatList;

  const { profile_details, member_list } = searchProfileResponse;

  // browser storage//
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  // states//
  const [scheduleList, setScheduleList] = useState([]);
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [scheduleListPage, setScheduleListPage] = useState(1);
  const [totalEventsCounts, setTotalEventsCount] = useState(0);

  //fetch the schedule list checking ----//
  useEffect(() => {
    SOCKET.emit("get-schedule-events", {
      sender_id: profileData.id,
      reciever_id: !!profile_details ? profile_details.id : currenUserId,
      page: scheduleListPage,
      action: false,
    });
  }, [scheduleListPage]);

  useEffect(() => {
    setScheduleListPage(1);
  }, [currenUserId]);


// get the seller status of the user if user is seller//
  useEffect(() => {
    ListData.map((item, i) => {
      if (
        item.name == profile_details?.seller_status &&
        profile_details?.seller_status != "Custom"
      ) {
        setIcon(item.imageOne);
        setColor(item.color);
      } else if (
        profile_details?.seller_status == undefined ||
        profile_details?.seller_status == ""
      ) {
        setIcon("");
      } else if (profile_details?.seller_status == "Custom") {
        setIcon(profile_details.status_image);

        setColor(item.color);
      }
    });
  }, [profile_details]);


  // listen socket and disptach action send event list in  argument//
  useEffect(() => {
    SOCKET.off("set-schedule-events").on("set-schedule-events", (response) => {
      dispatch(getAllScheduleList(response));
    });
  }, [getScheduleList]);

  // remove duplicate item in schedule list //
  useEffect(() => {
    const filteredScheduleList = getScheduleList.filter(
      (item) => item.declined === 0
    );

    const uniqueScheduleList = filteredScheduleList.reduce(
      (accumulator, currentObj) => {
        // Check if an object with the same id exists in the accumulator
        const existingObjIndex = accumulator.findIndex(
          (obj) => obj.event_id === currentObj.event_id
        );
        // If not, add the current object to the accumulator
        if (existingObjIndex === -1) {
          accumulator.push(currentObj);
        } else {
          // If an object with the same id exists, compare the updatedAt property
          if (currentObj.updatedAt > accumulator[existingObjIndex].updatedAt) {
            // If the current object has a later updatedAt, replace the existing object
            accumulator[existingObjIndex] = currentObj;
          }
        }
        return accumulator;
      },
      []
    );
    uniqueScheduleList.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );

    setScheduleList(uniqueScheduleList);
    setTotalEventsCount(getScheduleListCounts);
  }, [getScheduleList]);

  return (
    <>
      {/* {currenUserId !== 176 && ( */}
      <div className="col-md-3 bg-white full-width px-0 border-left">
        <>
          <div className="user_profile-wrap p-2">
            {profile_details?.type === "user" ? (
              <img
                onError={(ev) =>
                  (ev.target.src = "/assets/images/member-default.svg")
                }
                src={
                  !!profile_details?.is_individual
                    ? IMAGE_BASE_URL + profile_details?.profile_image
                    : IMAGE_BASE_URL + profile_details?.business_image ||
                      "/assets/images/member-default.svg"
                }
                className="user_logo"
                alt="logo"
              />
            ) : (
              <img
                onError={(ev) =>
                  (ev.target.src = "/assets/images/group_logo.png")
                }
                src={IMAGE_BASE_URL + profile_details?.cover_image}
                className="group_logo"
                alt="logo"
              />
            )}
            <div className="user_profile-info mt-0">
              <div className="user_profile-name">
                <h6 className="mb-1">
                  {profile_details !== undefined &&
                    profile_details?.type === "user" && (
                      <>
                        {`${
                          !!profile_details?.is_individual
                            ? profile_details?.name
                            : profile_details?.business_name
                        }`}
                      </>
                    )}

                  {profile_details !== undefined &&
                    profile_details?.type === "group" && (
                      <>{`${profile_details?.name}`}</>
                    )}
                </h6>

                {profile_details?.type === "user" &&
                  profile_details?.seller_status !== "" && (
                    <img
                      src={
                        profile_details?.seller_status != "Custom"
                          ? icon
                          : !!profile_details?.status_image
                          ? IMAGE_BASE_URL + icon
                          : "/assets/images/custom-icon.svg"
                      }
                    />
                  )}
              </div>
              {!profile_details?.is_individual &&
                profile_details?.type === "user" && (
                  <div className="user_profile-subimg">
                    {member_list?.original?.list?.length > 0 &&
                      member_list?.original?.list?.map((item, index) => (
                        <div key={index}>
                          <img
                            onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/member-default.svg")
                            }
                            src={IMAGE_BASE_URL + item?.user_profile}
                            alt="logo"
                          />
                        </div>
                      ))}
                  </div>
                )}
            </div>
          </div>

          {currenUserId != 176 && messageDetails?.type !== "group" ? (
            <TabView>
              <TabPanel header={"Details"}>
                {scheduleList?.length > 0 ? (
                  <AppointMentDetails
                    totalEventsCounts={totalEventsCounts}
                    scheduleList={scheduleList}
                    changEventstate={changEventstate}
                    setScheduleList={setScheduleList}
                    setScheduleListPage={setScheduleListPage}
                    scheduleListPage={scheduleListPage}
                    handleOpenRequestModal={handleOpenRequestModal}
                    messageDetails={messageDetails}
                  />
                ) : (
                  <strong>“No tasks available to show”</strong>
                )}
              </TabPanel>

              <TabPanel header={"Media"}>
                {getShareMediaData?.length > 0 && (
                  <MediaPage
                    getShareMediaDataLoading={getShareMediaDataLoading}
                    getShareMediaData={getShareMediaData}
                    mediaPage={mediaPage}
                    setMediaPage={setMediaPage}
                    totalCounts={totalCounts}
                  />
                )}
              </TabPanel>
            </TabView>
          ) : (
            <TabView>
              <TabPanel header={"Media"}>
                {getShareMediaData?.length > 0 && (
                  <MediaPage
                    getShareMediaDataLoading={getShareMediaDataLoading}
                    getShareMediaData={getShareMediaData}
                    mediaPage={mediaPage}
                    setMediaPage={setMediaPage}
                    totalCounts={totalCounts}
                  />
                )}
              </TabPanel>
            </TabView>
          )}
        </>
      </div>
      {/* )} */}
    </>
  );
}
