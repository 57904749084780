import { GET_API, POST_API } from "../../main/fetch";
import {
  ACCEPT_REQUEST_DATA_FAILURE,
  ACCEPT_REQUEST_DATA_REQUEST,
  ACCEPT_REQUEST_DATA_SUCCESS,
  ALL_NOTIFICATION_DATA_FAILURE,
  ALL_NOTIFICATION_DATA_REQUEST,
  ALL_NOTIFICATION_DATA_SUCCESS,
  DECLINE_REQUEST_DATA_FAILURE,
  DECLINE_REQUEST_DATA_REQUEST,
  DECLINE_REQUEST_DATA_SUCCESS,
} from "../../modules/Allnotifications/NotificationConstant";
import {
  ACCEPT_MEMBERS_DATA_REQUEST,
  ACCEPT_MEMBERS_DATA_SUCCESS,
  ACCEPT_MEMBERS_DATA_FAILURE,
  DECLINE_MEMBERS_DATA_REQUEST,
  DECLINE_MEMBERS_DATA_SUCCESS,
  DECLINE_MEMBERS_DATA_FAILURE,
} from "../../modules/AllMembersNotifications/MembersNotificationConstant";

import {
  LOGOUT_API_DATA_FAILURE,
  LOGOUT_API_DATA_REQUEST,
  LOGOUT_API_DATA_SUCCESS,
  ONSCREEN_NOTIFICATION_DATA_FAILURE,
  ONSCREEN_NOTIFICATION_DATA_REQUEST,
  ONSCREEN_NOTIFICATION_DATA_SUCCESS,
  READALL_NOTIFICATION_DATA_FAILURE,
  READALL_NOTIFICATION_DATA_REQUEST,
  READALL_NOTIFICATION_DATA_SUCCESS,
} from "../common/components/Toolbar/ToolbarConstants";
import {
  ACCEPT_REQUEST_API,
  ALL_NOTIFICATION_API,
  DECLINE_REQUEST_API,
  LOGOUT_API,
  ONSCREEN_NOTIFICATION_API,
  READALL_NOTIFICATION_API,
  ACCEPT_REQUEST_MEMBERS_API,
  DECLINE_REQUEST_MEMBER_API,
} from "../urls";

//this function is used to accept friend request
const acceptRequestApi = (body) => {
  return {
    [POST_API]: {
      endpoint: ACCEPT_REQUEST_API,
      types: [
        ACCEPT_REQUEST_DATA_REQUEST,
        ACCEPT_REQUEST_DATA_SUCCESS,
        ACCEPT_REQUEST_DATA_FAILURE,
      ],
      body,
    },
  };
};

const acceptMembersRequestApi = (body) => {
  return {
    [POST_API]: {
      endpoint: ACCEPT_REQUEST_MEMBERS_API,
      types: [
        ACCEPT_MEMBERS_DATA_REQUEST,
        ACCEPT_MEMBERS_DATA_SUCCESS,
        ACCEPT_MEMBERS_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to decline friend request
const declineRequestApi = (body) => {
  return {
    [POST_API]: {
      endpoint: DECLINE_REQUEST_API,
      types: [
        DECLINE_REQUEST_DATA_REQUEST,
        DECLINE_REQUEST_DATA_SUCCESS,
        DECLINE_REQUEST_DATA_FAILURE,
      ],
      body,
    },
  };
};

const declineMembersRequestApi = (body) => {
  return {
    [POST_API]: {
      endpoint: DECLINE_REQUEST_MEMBER_API,
      types: [
        DECLINE_MEMBERS_DATA_REQUEST,
        DECLINE_MEMBERS_DATA_SUCCESS,
        DECLINE_MEMBERS_DATA_FAILURE,
      ],
      body,
    },
  };
};

//this function is used to fetch all notification list
const allNotificationsApi = (page) => {
  return {
    [GET_API]: {
      endpoint: ALL_NOTIFICATION_API + "?page=" + page,
      types: [
        ALL_NOTIFICATION_DATA_REQUEST,
        ALL_NOTIFICATION_DATA_SUCCESS,
        ALL_NOTIFICATION_DATA_FAILURE,
      ],
    },
  };
};
//this function is used to fetch bell notification list
const onScreenNotificationsApi = () => {
  return {
    [GET_API]: {
      endpoint: ONSCREEN_NOTIFICATION_API,
      types: [
        ONSCREEN_NOTIFICATION_DATA_REQUEST,
        ONSCREEN_NOTIFICATION_DATA_SUCCESS,
        ONSCREEN_NOTIFICATION_DATA_FAILURE,
      ],
    },
  };
};
//this function is used to read all notifications.
const readAllNotificationsApi = (body) => {
  return {
    [POST_API]: {
      endpoint: READALL_NOTIFICATION_API,
      types: [
        READALL_NOTIFICATION_DATA_REQUEST,
        READALL_NOTIFICATION_DATA_SUCCESS,
        READALL_NOTIFICATION_DATA_FAILURE,
      ],
      body,
    },
  };
};
const logOutApi = () => {
  return {
    [GET_API]: {
      endpoint: LOGOUT_API,
      types: [
        LOGOUT_API_DATA_REQUEST,
        LOGOUT_API_DATA_SUCCESS,
        LOGOUT_API_DATA_FAILURE,
      ],
    },
  };
};
export {
  acceptRequestApi,
  acceptMembersRequestApi,
  declineRequestApi,
  declineMembersRequestApi,
  allNotificationsApi,
  onScreenNotificationsApi,
  readAllNotificationsApi,
  logOutApi,
};
