
const CHANGE_USER_ALL_POST_DETAIL = "CHANGE_USER_ALL_POST_DETAIL"

const GET_USER_ALL_POST_DATA_REQUEST = "GET_USER_ALL_POST_DATA_REQUEST";
const GET_USER_ALL_POST_DATA_SUCCESS = "GET_USER_ALL_POST_DATA_SUCCESS";
const GET_USER_ALL_POST_DATA_FAILURE = "GET_USER_ALL_POST_DATA_FAILURE";


const CLEAR_ALL_PROFILE_POST_RESPONSE = "CLEAR_ALL_PROFILE_POST_RESPONSE";

const CHANGE_SINGLE_ACTIVE_CAROUSAL_POST = "CHANGE_SINGLE_ACTIVE_CAROUSAL_POST";

const USER_POST_REACT_DATA_REQUEST = "USER_POST_REACT_DATA_REQUEST";
const USER_POST_REACT_DATA_SUCCESS = "USER_POST_REACT_DATA_SUCCESS";
const USER_POST_REACT_DATA_FAILURE = "USER_POST_REACT_DATA_FAILURE";

const CLEAR_USER_POST_REACT_RESPONSE = "CLEAR_USER_POST_REACT_RESPONSE"

const SINGLE_USER_POST_REACT_DATA_REQUEST = "SINGLE_USER_POST_REACT_DATA_REQUEST";
const SINGLE_USER_POST_REACT_DATA_SUCCESS = "SINGLE_USER_POST_REACT_DATA_SUCCESS";
const SINGLE_USER_POST_REACT_DATA_FAILURE = "SINGLE_USER_POST_REACT_DATA_FAILURE";

const CLEAR_SINGLE_USER_POST_REACT_RESPONSE = "CLEAR_SINGLE_USER_POST_REACT_RESPONSE"

const USER_POST_REACT_LIST_DATA_REQUEST = "USER_POST_REACT_LIST_DATA_REQUEST";
const USER_POST_REACT_LIST_DATA_SUCCESS = "USER_POST_REACT_LIST_DATA_SUCCESS";
const USER_POST_REACT_LIST_DATA_FAILURE = "USER_POST_REACT_LIST_DATA_FAILURE";

const CLEAR_USER_POST_REACT_LIST_RESPONSE = "CLEAR_USER_POST_REACT_LIST_RESPONSE";

const CHANGE_USER_REACT_LIST_DETAIL = "CHANGE_USER_REACT_LIST_DETAIL"

const SINGLE_USER_REACT_LIST_DATA_REQUEST = "SINGLE_USER_REACT_LIST_DATA_REQUEST";
const SINGLE_USER_REACT_LIST_DATA_SUCCESS = "SINGLE_USER_REACT_LIST_DATA_SUCCESS";
const SINGLE_USER_REACT_LIST_DATA_FAILURE = "SINGLE_USER_REACT_LIST_DATA_FAILURE";

const CLEAR_SINGLE_USER_REACT_LIST_RESPONSE = "CLEAR_SINGLE_USER_REACT_LIST_RESPONSE"

const USER_POST_COMMENT_LIST_DATA_REQUEST = "USER_POST_COMMENT_LIST_DATA_REQUEST";
const USER_POST_COMMENT_LIST_DATA_SUCCESS = "USER_POST_COMMENT_LIST_DATA_SUCCESS";
const USER_POST_COMMENT_LIST_DATA_FAILURE = "USER_POST_COMMENT_LIST_DATA_FAILURE";

const CLEAR_USER_POST_COMMENT_LIST_RESPONSE = "CLEAR_USER_POST_COMMENT_LIST_RESPONSE";

const CHANGE_USER_COMMENT_INPUT_DETAIL = "CHANGE_USER_COMMENT_INPUT_DETAIL";

const USER_POST_COMMENT_DATA_REQUEST = "USER_POST_COMMENT_DATA_REQUEST";
const USER_POST_COMMENT_DATA_SUCCESS = "USER_POST_COMMENT_DATA_SUCCESS";
const USER_POST_COMMENT_DATA_FAILURE = "USER_POST_COMMENT_DATA_FAILURE";

const CLEAR_USER_POST_COMMENT_RESPONSE = "CLEAR_USER_POST_COMMENT_RESPONSE";

const USER_POST_COMMENT_REPLY_DATA_REQUEST = "USER_POST_COMMENT_REPLY_DATA_REQUEST";
const USER_POST_COMMENT_REPLY_DATA_SUCCESS = "USER_POST_COMMENT_REPLY_DATA_SUCCESS";
const USER_POST_COMMENT_REPLY_DATA_FAILURE = "USER_POST_COMMENT_REPLY_DATA_FAILURE";

const CLEAR_USER_COMMENT_REPLY_RESPONSE = "CLEAR_USER_COMMENT_REPLY_RESPONSE";

const USER_POST_COMMENT_REPLY_LIST_DATA_REQUEST = "USER_POST_COMMENT_REPLY_LIST_DATA_REQUEST";
const USER_POST_COMMENT_REPLY_LIST_DATA_SUCCESS = "USER_POST_COMMENT_REPLY_LIST_DATA_SUCCESS";
const USER_POST_COMMENT_REPLY_LIST_DATA_FAILURE = "USER_POST_COMMENT_REPLY_LIST_DATA_FAILURE";

const CLEAR_USER_POST_COMMENT_REPLY_LIST_RESPONSE = "CLEAR_USER_POST_COMMENT_REPLY_LIST_RESPONSE";

const SINGLE_USER_POST_COMMENT_DATA_REQUEST = "SINGLE_USER_POST_COMMENT_DATA_REQUEST";
const SINGLE_USER_POST_COMMENT_DATA_SUCCESS = "SINGLE_USER_POST_COMMENT_DATA_SUCCESS";
const SINGLE_USER_POST_COMMENT_DATA_FAILURE = "SINGLE_USER_POST_COMMENT_DATA_FAILURE";

const CLEAR_SINGLE_USER_POST_COMMENT_RESPONSE = "CLEAR_SINGLE_USER_POST_COMMENT_RESPONSE"

const SINGLE_USER_POST_COMMENT_LIST_DATA_REQUEST = "SINGLE_USER_POST_COMMENT_LIST_DATA_REQUEST";
const SINGLE_USER_POST_COMMENT_LIST_DATA_SUCCESS = "SINGLE_USER_POST_COMMENT_LIST_DATA_SUCCESS";
const SINGLE_USER_POST_COMMENT_LIST_DATA_FAILURE = "SINGLE_USER_POST_COMMENT_LIST_DATA_FAILURE";

const CLEAR_SINGLE_USER_POST_COMMENT_LIST_RESPONSE = "CLEAR_SINGLE_USER_POST_COMMENT_LIST_RESPONSE";

const SINGLE_USER_POST_CMT_REPLY_DATA_REQUEST = "SINGLE_USER_POST_CMT_REPLY_DATA_REQUEST";
const SINGLE_USER_POST_CMT_REPLY_DATA_SUCCESS = "SINGLE_USER_POST_CMT_REPLY_DATA_SUCCESS";
const SINGLE_USER_POST_CMT_REPLY_DATA_FAILURE = "SINGLE_USER_POST_CMT_REPLY_DATA_FAILURE";

const CLEAR_SINGLE_USER_POST_CMT_REPLY_RESPONSE = "CLEAR_SINGLE_USER_POST_CMT_REPLY_RESPONSE"

const SINGLE_USER_POST_CMT_REPLY_LIST_DATA_REQUEST = "SINGLE_USER_POST_CMT_REPLY_LIST_DATA_REQUEST";
const SINGLE_USER_POST_CMT_REPLY_LIST_DATA_SUCCESS = "SINGLE_USER_POST_CMT_REPLY_LIST_DATA_SUCCESS";
const SINGLE_USER_POST_CMT_REPLY_LIST_DATA_FAILURE = "SINGLE_USER_POST_CMT_REPLY_LIST_DATA_FAILURE";

const CLEAR_SINGLE_USER_POST_CMT_REPLY_LIST_RESPONSE = "CLEAR_SINGLE_USER_POST_CMT_REPLY_LIST_RESPONSE";

const USER_POST_VIEW_DATA_REQUEST = "USER_POST_VIEW_DATA_REQUEST";
const USER_POST_VIEW_DATA_SUCCESS = "USER_POST_VIEW_DATA_SUCCESS";
const USER_POST_VIEW_DATA_FAILURE = "USER_POST_VIEW_DATA_FAILURE";

const CLEAR_USER_POST_VIEW_RESPONSE = "CLEAR_USER_POST_VIEW_RESPONSE";

const SINGLE_USER_POST_VIEW_DATA_REQUEST = "SINGLE_USER_POST_VIEW_DATA_REQUEST";
const SINGLE_USER_POST_VIEW_DATA_SUCCESS = "SINGLE_USER_POST_VIEW_DATA_SUCCESS";
const SINGLE_USER_POST_VIEW_DATA_FAILURE = "SINGLE_USER_POST_VIEW_DATA_FAILURE";

const CLEAR_SINGLE_USER_POST_VIEW_RESPONSE = "CLEAR_SINGLE_USER_POST_VIEW_RESPONSE"

const USER_POST_VIEW_LIST_DATA_REQUEST = "USER_POST_VIEW_LIST_DATA_REQUEST";
const USER_POST_VIEW_LIST_DATA_SUCCESS = "USER_POST_VIEW_LIST_DATA_SUCCESS";
const USER_POST_VIEW_LIST_DATA_FAILURE = "USER_POST_VIEW_LIST_DATA_FAILURE";

const CLEAR_USER_POST_VIEW_LIST_RESPONSE = "CLEAR_USER_POST_VIEW_LIST_RESPONSE";

const CHANGE_USER_POST_VIEW_DETAIL = "CHANGE_USER_POST_VIEW_DETAIL"

const SINGLE_USER_POST_VIEW_LIST_DATA_REQUEST = "SINGLE_USER_POST_VIEW_LIST_DATA_REQUEST";
const SINGLE_USER_POST_VIEW_LIST_DATA_SUCCESS = "SINGLE_USER_POST_VIEW_LIST_DATA_SUCCESS";
const SINGLE_USER_POST_VIEW_LIST_DATA_FAILURE = "SINGLE_USER_POST_VIEW_LIST_DATA_FAILURE";

const CLEAR_SINGLE_USER_POST_LIST_RESPONSE = "CLEAR_SINGLE_USER_POST_LIST_RESPONSE"

const USER_POST_SHARE_DATA_REQUEST = "USER_POST_SHARE_DATA_REQUEST";
const USER_POST_SHARE_DATA_SUCCESS = "USER_POST_SHARE_DATA_SUCCESS";
const USER_POST_SHARE_DATA_FAILURE = "USER_POST_SHARE_DATA_FAILURE";

const CLEAR_USER_POST_SHARE_RESPONSE = "CLEAR_USER_POST_SHARE_RESPONSE";

const SINGLE_USER_POST_SHARE_DATA_REQUEST = "SINGLE_USER_POST_SHARE_DATA_REQUEST";
const SINGLE_USER_POST_SHARE_DATA_SUCCESS = "SINGLE_USER_POST_SHARE_DATA_SUCCESS";
const SINGLE_USER_POST_SHARE_DATA_FAILURE = "SINGLE_USER_POST_SHARE_DATA_FAILURE";

const CLEAR_SINGLE_USER_POST_SHARE_RESPONSE = "CLEAR_SINGLE_USER_POST_SHARE_RESPONSE";

const CHANGE_USER_SHARE_LIST_DETAIL = "CHANGE_USER_SHARE_LIST_DETAIL";

const USER_POST_SHARE_LIST_DATA_REQUEST = "USER_POST_SHARE_LIST_DATA_REQUEST";
const USER_POST_SHARE_LIST_DATA_SUCCESS = "USER_POST_SHARE_LIST_DATA_SUCCESS";
const USER_POST_SHARE_LIST_DATA_FAILURE = "USER_POST_SHARE_LIST_DATA_FAILURE";

const CLEAR_USER_POST_SHARE_LIST_RESPONSE = "CLEAR_USER_POST_SHARE_LIST_RESPONSE"

const SINGLE_USER_POST_SHARE_LIST_DATA_REQUEST = "SINGLE_USER_POST_SHARE_LIST_DATA_REQUEST";
const SINGLE_USER_POST_SHARE_LIST_DATA_SUCCESS = "SINGLE_USER_POST_SHARE_LIST_DATA_SUCCESS";
const SINGLE_USER_POST_SHARE_LIST_DATA_FAILURE = "SINGLE_USER_POST_SHARE_LIST_DATA_FAILURE";

const CLEAR_SINGLE_USER_POST_SHARE_LIST_RESPONSE = "CLEAR_SINGLE_USER_POST_SHARE_LIST_RESPONSE";

const DELETE_MY_POST_DATA_REQUEST = "DELETE_MY_POST_DATA_REQUEST";
const DELETE_MY_POST_DATA_SUCCESS = "DELETE_MY_POST_DATA_SUCCESS";
const DELETE_MY_POST_DATA_FAILURE = "DELETE_MY_POST_DATA_FAILURE";

const CLEAR_DELETE_MY_POST_RESPONSE = "CLEAR_DELETE_MY_POST_RESPONSE";

const GET_SELLER_STATUS_DATA_REQUEST = 'GET_SELLER_STATUS_DATA_REQUEST'
const GET_SELLER_STATUS_DATA_SUCCESS = 'GET_SELLER_STATUS_DATA_SUCCESS'
const GET_SELLER_STATUS_DATA_FAILURE = 'GET_SELLER_STATUS_DATA_FAILURE'

export {
    GET_SELLER_STATUS_DATA_REQUEST, GET_SELLER_STATUS_DATA_SUCCESS, GET_SELLER_STATUS_DATA_FAILURE,
    CHANGE_USER_ALL_POST_DETAIL, GET_USER_ALL_POST_DATA_REQUEST, GET_USER_ALL_POST_DATA_SUCCESS, GET_USER_ALL_POST_DATA_FAILURE, CLEAR_ALL_PROFILE_POST_RESPONSE,
    CHANGE_SINGLE_ACTIVE_CAROUSAL_POST, USER_POST_REACT_DATA_REQUEST, USER_POST_REACT_DATA_SUCCESS, USER_POST_REACT_DATA_FAILURE,
    CLEAR_USER_POST_REACT_RESPONSE, SINGLE_USER_POST_REACT_DATA_REQUEST, SINGLE_USER_POST_REACT_DATA_SUCCESS, SINGLE_USER_POST_REACT_DATA_FAILURE,
    CLEAR_SINGLE_USER_POST_REACT_RESPONSE, USER_POST_REACT_LIST_DATA_REQUEST, USER_POST_REACT_LIST_DATA_SUCCESS, USER_POST_REACT_LIST_DATA_FAILURE,
    CLEAR_USER_POST_REACT_LIST_RESPONSE, CHANGE_USER_REACT_LIST_DETAIL, SINGLE_USER_REACT_LIST_DATA_REQUEST, SINGLE_USER_REACT_LIST_DATA_SUCCESS,
    SINGLE_USER_REACT_LIST_DATA_FAILURE, CLEAR_SINGLE_USER_REACT_LIST_RESPONSE, USER_POST_COMMENT_LIST_DATA_REQUEST, USER_POST_COMMENT_LIST_DATA_SUCCESS,
    USER_POST_COMMENT_LIST_DATA_FAILURE, CLEAR_USER_POST_COMMENT_LIST_RESPONSE, CHANGE_USER_COMMENT_INPUT_DETAIL, USER_POST_COMMENT_DATA_REQUEST,
    USER_POST_COMMENT_DATA_SUCCESS, USER_POST_COMMENT_DATA_FAILURE, CLEAR_USER_POST_COMMENT_RESPONSE, USER_POST_COMMENT_REPLY_DATA_REQUEST,
    USER_POST_COMMENT_REPLY_DATA_SUCCESS, USER_POST_COMMENT_REPLY_DATA_FAILURE, CLEAR_USER_COMMENT_REPLY_RESPONSE, USER_POST_COMMENT_REPLY_LIST_DATA_REQUEST,
    USER_POST_COMMENT_REPLY_LIST_DATA_SUCCESS, USER_POST_COMMENT_REPLY_LIST_DATA_FAILURE, CLEAR_USER_POST_COMMENT_REPLY_LIST_RESPONSE,
    SINGLE_USER_POST_COMMENT_DATA_REQUEST, SINGLE_USER_POST_COMMENT_DATA_SUCCESS, SINGLE_USER_POST_COMMENT_DATA_FAILURE, CLEAR_SINGLE_USER_POST_COMMENT_RESPONSE,
    SINGLE_USER_POST_COMMENT_LIST_DATA_REQUEST, SINGLE_USER_POST_COMMENT_LIST_DATA_SUCCESS, SINGLE_USER_POST_COMMENT_LIST_DATA_FAILURE, CLEAR_SINGLE_USER_POST_COMMENT_LIST_RESPONSE,
    SINGLE_USER_POST_CMT_REPLY_DATA_REQUEST, SINGLE_USER_POST_CMT_REPLY_DATA_SUCCESS, SINGLE_USER_POST_CMT_REPLY_DATA_FAILURE, CLEAR_SINGLE_USER_POST_CMT_REPLY_RESPONSE,
    SINGLE_USER_POST_CMT_REPLY_LIST_DATA_REQUEST, SINGLE_USER_POST_CMT_REPLY_LIST_DATA_SUCCESS, SINGLE_USER_POST_CMT_REPLY_LIST_DATA_FAILURE, CLEAR_SINGLE_USER_POST_CMT_REPLY_LIST_RESPONSE,
    USER_POST_VIEW_DATA_REQUEST, USER_POST_VIEW_DATA_SUCCESS, USER_POST_VIEW_DATA_FAILURE, CLEAR_USER_POST_VIEW_RESPONSE, SINGLE_USER_POST_VIEW_DATA_REQUEST,
    SINGLE_USER_POST_VIEW_DATA_SUCCESS, SINGLE_USER_POST_VIEW_DATA_FAILURE, CLEAR_SINGLE_USER_POST_VIEW_RESPONSE, USER_POST_VIEW_LIST_DATA_REQUEST,
    USER_POST_VIEW_LIST_DATA_SUCCESS, USER_POST_VIEW_LIST_DATA_FAILURE, CLEAR_USER_POST_VIEW_LIST_RESPONSE, CHANGE_USER_POST_VIEW_DETAIL,
    SINGLE_USER_POST_VIEW_LIST_DATA_REQUEST, SINGLE_USER_POST_VIEW_LIST_DATA_SUCCESS, SINGLE_USER_POST_VIEW_LIST_DATA_FAILURE, CLEAR_SINGLE_USER_POST_LIST_RESPONSE,
    USER_POST_SHARE_DATA_REQUEST, USER_POST_SHARE_DATA_SUCCESS, USER_POST_SHARE_DATA_FAILURE, CLEAR_USER_POST_SHARE_RESPONSE, SINGLE_USER_POST_SHARE_DATA_REQUEST,
    SINGLE_USER_POST_SHARE_DATA_SUCCESS, SINGLE_USER_POST_SHARE_DATA_FAILURE, CLEAR_SINGLE_USER_POST_SHARE_RESPONSE, CHANGE_USER_SHARE_LIST_DETAIL,
    USER_POST_SHARE_LIST_DATA_REQUEST, USER_POST_SHARE_LIST_DATA_SUCCESS, USER_POST_SHARE_LIST_DATA_FAILURE, CLEAR_USER_POST_SHARE_LIST_RESPONSE,
    SINGLE_USER_POST_SHARE_LIST_DATA_REQUEST, SINGLE_USER_POST_SHARE_LIST_DATA_SUCCESS, SINGLE_USER_POST_SHARE_LIST_DATA_FAILURE, CLEAR_SINGLE_USER_POST_SHARE_LIST_RESPONSE,
    DELETE_MY_POST_DATA_REQUEST, DELETE_MY_POST_DATA_SUCCESS, DELETE_MY_POST_DATA_FAILURE, CLEAR_DELETE_MY_POST_RESPONSE,
}