import { Route, Redirect } from "react-router-dom";
import { PrivateRoute, ProtectedRoute } from ".";
import { loginLogo } from "../../library/urls/images";
import { useLocation } from "react-router-dom";
import {
  is_page_exist_app,
  is_page_exist_private,
  is_page_exist_protected,
} from "../../library/utilities/functions";
import {
  Account,
  AllNotification,
  ForgetPassword,
  Home,
  Login,
  Main,
  MyNetwork,
  PoolsChat,
  Post,
  ResetPassword,
  SearchList,
  SearchProfile,
  Signup,
} from "../../modules";
import {
  ProfileGallery,
  ProfileMembers,
  ProfilePosts,
  Toolbar,
} from "../../library/common/components";
import { FirstTimeLogin } from "../../modules/Home/common";
import { PrivacyPolicy, TermsServices } from "../../modules/Signup";
import SearchListMembers from "../../modules/SearchMembersList";
import SellerSteps from "../../modules/SellerSteps";
import BecomeSeller from "../../library/common/components/BecomeSeller";

import HomeSection from "../../library/common/components/LandingPage/HomeSection";
import BlogPage from "../../library/common/components/LandingPage/BlogPage";
import { useEffect, useState } from "react";
import Aboutpage from "../../library/common/components/LandingPage/Aboutpage";
import BlogDetail from "../../library/common/components/LandingPage/BlogDetail";
import Navbar from "../../library/common/components/LandingPage/Navbar";
import ManageAccount from "../../modules/ManageAccount/Index";
import Members from "../../modules/Members";
import ServicesTab from "../../library/common/components/ServicesTab";
import ShareProfile from "../../modules/ShareProfile";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import WorkandEarn from "../../library/common/components/LandingPage/WorkandEarn";
import ServiceProvider from "../../library/common/components/LandingPage/ServiceProvider";

const PoolsRoutes = ({ is_auth }) => {
  const [isAuthLogoShow, setIsAuthLogoShow] = useState(false);
  const [isShowNavbar, setShowNavbar] = useState(false);
  let { pathname } = useLocation();
  const history = useHistory();
  const isPageProtected = is_page_exist_protected();
  const isPagePrivate = is_page_exist_private();
  const is_page_exist = is_page_exist_app();
  const param = useParams();

  if (pathname && is_auth == false) {
    if (pathname.includes("account")) {
      let url = pathname.split("/");
      if (url?.length > 0) {
        let username = url[2].split("-");
        return window.location.replace(`/search-user/${username[1]}`);
      }
    } else if (pathname.includes("search-profile")) {
      let url = pathname.split("/");

      return window.location.replace(`/search-user/${url[2]}`);
    }
  }

  useEffect(() => {
    if (
      pathname == "/" ||
      pathname == "/blog" ||
      pathname == "/our-story" ||
      pathname.includes("/blog-detail/") ||
      pathname==="/work-and-earn" ||
      pathname === "/service-provider"
    ) {
      return setIsAuthLogoShow(false);
    } else setIsAuthLogoShow(true);
  }, [pathname]);

  useEffect(() => {
    if (
      pathname === "/" ||
      pathname === "/blog" ||
      pathname === "/our-story" ||
      pathname.includes("/blog-detail/")||
      pathname==="/work-and-earn" ||
      pathname === "/service-provider"
    ) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  }, [pathname]);

  if (is_page_exist) {
    if (isPageProtected) {
      return (
        <Route>
          {isShowNavbar && (
            <div className="landing-wrapper">
              <Navbar />
            </div>
          )}
          {pathname != "/privacy-policy" && pathname != "/term-service" ? (
            <div>
              <ProtectedRoute
                exact
                path="/"
                isauth={is_auth}
                isPageProtected={isPageProtected}
                component={HomeSection}
              />

              <ProtectedRoute
                path="/blog"
                isauth={is_auth}
                isPageProtected={isPageProtected}
                component={BlogPage}
              />

              <ProtectedRoute
                path="/our-story"
                isauth={is_auth}
                isPageProtected={isPageProtected}
                component={Aboutpage}
              />

              <ProtectedRoute
                path="/work-and-earn"
                isauth={is_auth}
                isPageProtected={isPageProtected}
                component={WorkandEarn}
              />

              <ProtectedRoute
                path="/service-provider"
                isauth={is_auth}
                isPageProtected={isPageProtected}
                component={ServiceProvider}
              />
              <ProtectedRoute
                path="/blog-detail/:slug"
                isauth={is_auth}
                isPageProtected={isPageProtected}
                component={BlogDetail}
                blog-detail
              />
              {pathname != "/" &&
                pathname != "/blog" &&
                pathname != "/our-story" &&
                !pathname.includes("/blog-detail/") && (
                  <div
                    className={
                      pathname != "/" &&
                      pathname != "/blog" &&
                      pathname != "/our-story" &&
                      !pathname.includes("/blog-detail/")
                        ? "login-wrapper hero"
                        : null
                    }
                  >
                    {isAuthLogoShow && loginLogo()}
                    <ProtectedRoute
                      path="/login"
                      isauth={is_auth}
                      isPageProtected={isPageProtected}
                      component={Login}
                    />
                    <ProtectedRoute
                      path={["/signup", "/signup-member"]}
                      isauth={is_auth}
                      isPageProtected={isPageProtected}
                      component={Signup}
                    />
                    <ProtectedRoute
                      path="/forget-password"
                      isauth={is_auth}
                      isPageProtected={isPageProtected}
                      component={ForgetPassword}
                    />
                    <ProtectedRoute
                      path="/change-password"
                      isauth={is_auth}
                      isPageProtected={isPageProtected}
                      component={ResetPassword}
                    />

                    <ProtectedRoute
                      path={"/search-user/:userid"}
                      isauth={is_auth}
                      isPageProtected={isPageProtected}
                      component={ShareProfile}
                    />
                  </div>
                )}
            </div>
          ) : (
            <>
              <ProtectedRoute
                path="/privacy-policy"
                isauth={is_auth}
                isPageProtected={isPageProtected}
                component={PrivacyPolicy}
              />
              <ProtectedRoute
                path="/term-service"
                isauth={is_auth}
                isPageProtected={isPageProtected}
                component={TermsServices}
              />
            </>
          )}
        </Route>
      );
    }

    if (isPagePrivate || isPageProtected) {
      return (
        <Route>
          <Toolbar />

          <PrivateRoute
            exact
            path="/"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={Home}
          />
          <PrivateRoute
            path="/newsfeeds"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={Home}
          />
          <PrivateRoute
            path="/account/:username"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={Account}
          />
          <PrivateRoute
            path="/account/:username/profile-post"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={ProfilePosts}
          />

          <PrivateRoute
            path="/account/:username/gallery"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={ProfileGallery}
          />
          <PrivateRoute
            path="/account/:username/services"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={ServicesTab}
          />

          <PrivateRoute
            path="/account/:username/members"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={ProfileMembers}
          />

          <PrivateRoute
            path="/manage-account"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={ManageAccount}
          />
          <PrivateRoute
            path="/search-list"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={SearchList}
          />

          <PrivateRoute
            path="/search-list-members"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={SearchListMembers}
          />

          <PrivateRoute
            path="/all-notification"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={AllNotification}
          />
          <PrivateRoute
            path="/first-time-login"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={FirstTimeLogin}
          />
          <PrivateRoute
            exact
            path="/network"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={MyNetwork}
          />

          <PrivateRoute
            path="/search-profile/:userId"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={SearchProfile}
          />

          <PrivateRoute
            path="/network/:userId"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={MyNetwork}
          />

          <PrivateRoute
            exact
            path="/members"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={Members}
          />

          {/* <PrivateRoute
            path="/members/:userId"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            // component={MyNetwork}
          /> */}

          <PrivateRoute
            path="/post"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={Post}
          />
          <PrivateRoute
            path={["/chat", "/contact-us"]}
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={PoolsChat}
          />
          <PrivateRoute
            path="/become-seller"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={BecomeSeller}
          />
          <PrivateRoute
            path="/become-seller-steps"
            isauth={is_auth}
            isPagePrivate={isPagePrivate}
            component={SellerSteps}
          />
        </Route>
      );
    }
  } else {
    return (
      <Route>{/* { <Redirect to={is_auth ? "/newsfeeds" : "/"} /> } */}</Route>
    );
  }
};
export default PoolsRoutes;
