import {
  SHARE_USER_PROFILE_FAILURE,
  SHARE_USER_PROFILE_REQUEST,
  SHARE_USER_PROFILE_SUCCESS,
} from "./ShareProfileActionConstant";

const initialState = {
  getShareProfileData: {
    getShareProfileLoading: false,
    getShareProfileStatus: "",
    getShareProfileSuccess: "",
    getShareProfileMessage: "",
    getShareProfileResponse: {},
  },
};

export const ShareUserProfileReducer = (state = initialState, action) => {


  switch (action.type) {
    case SHARE_USER_PROFILE_REQUEST:
      
    
      return {
        ...state,
        ...{
          getShareProfileData: {
            ...state.getShareProfileData,
            ...{ getShareProfileLoading: true },
          },
        },
      };

    case SHARE_USER_PROFILE_SUCCESS:
      let getShareProfileDataSuccess = {
        getShareProfileLoading: false,
        getShareProfileStatus: 200,
        getShareProfileSuccess: action.response.data.success,
        getShareProfileMessage: action.response.data.message,
        getShareProfileResponse: action.response.data
          ? action.response?.data?.data
          : {},
      };

      return {
        ...state,
        ...{
          getShareProfileData: {
            ...state.getShareProfileData,
            ...getShareProfileDataSuccess,
          },
        },
      };

    case SHARE_USER_PROFILE_FAILURE:
      let getShareProfileDataFailure = {
        getShareProfileLoading: false,
        getShareProfileStatus: action.status,
        getShareProfileSuccess: false,
        getShareProfileMessage: action.response.data.message,
        getShareProfileResponse: {},
      };

      return {
        ...state,
        ...{
          getShareProfileData: {
            ...state.getShareProfileData,
            ...getShareProfileDataFailure,
          },
        },
      };

    default:
      return state;
  }
};
