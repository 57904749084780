import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { IMAGE_BASE_URL } from "../../../../urls";
import Marquee from "react-fast-marquee";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
export default function Industries() {
  const [allIndustries, setIndustries] = useState([]);

  const landingPageContentData = useSelector(
    (state) => state.LandingPageReducer
  );
  const [isShow, setIsShow] = useState(false);
  const { landingPageContent, landingPageIndustries } = landingPageContentData;
  useEffect(() => {
    if (!!landingPageIndustries?.getLandingIndustriesPageData) {
      setIndustries(landingPageIndustries?.getLandingIndustriesPageData?.list);
    }
  }, [landingPageContentData]);
  const { getLandingPageData } = landingPageContent;

  // HIDE AND SHOW INDUSTRIES//
  // const handleShowIndustries = () => {
  //   setIsShow(!isShow);
  // };
  return (
    <section className="industries spacer theme-primary-bg text-white section-bright">
      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-md-12 mx-auto text-center ">
            <span className="section__label">Industries</span>

            <h2 className="section__subheading mb-0">
              {!!getLandingPageData && getLandingPageData?.industry?.title}
            </h2>
          </div>
        </div>
        <div className="row mt-5" data-aos="fade-up">
          <div className="col-lg-6 col-md-12 order-2 order-lg-1">
            {!!getLandingPageData?.industry &&
              parse(getLandingPageData?.industry?.description)}
            <Link
              // onClick={handleShowIndustries}
              className="btn mt-3 icon-btn"
              // href="javascript:;"
   to="/signup"
   >
              Get Started
            </Link>
          </div>
          <div
            className="col-lg-6 col-md-12 mb-4 mb-lg-0 order-1 order-lg-2"
            data-aos="fade-up"
          >
            {!!getLandingPageData?.industry && (
              <img
                src={`${IMAGE_BASE_URL + getLandingPageData?.industry?.image}`}
                className="br-20 w-100"
              />
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-md-12 p-0">
            <>
              {!!allIndustries && (
                <Marquee gradient={false} speed={20}>
                  {allIndustries.map((item, index) => (
                    <div className="industries__slider pr-3" key={index}>
                      <span>{item?.industries_name}</span>
                    </div>
                  ))}
                </Marquee>
              )}
            </>

            <>
              {!!allIndustries && (
                <Marquee direction="right" gradient={false} speed={20}>
                  {allIndustries.map((item, index) => (
                    <div className="industries__slider pr-3 mt-4" key={index}>
                      <span>{item?.industries_name}</span>
                    </div>
                  ))}
                </Marquee>
              )}
            </>
          </div>
        </div>
      </div>
    </section>
  );
}
