import React from "react";
import { IMAGE_BASE_URL } from "../../../urls";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
export default function RecentBlog({ recentBlogs }) {
  return (
    <div>
      <section className="spacer recent-blog">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="h4 font-weight-bold">Recent Blog Posts</h2>
            </div>
            <div className="col-md-12 mt-4">
              <div>
                {!!recentBlogs?.length > 0 && (
                  <div>
                    <div className="recent-blog__grid">
                      {!!recentBlogs && recentBlogs?.length && (
                        <div className="recent-blog__lg">
                          <Link
                            to={`/blog-detail/${recentBlogs[0].title_slug}`}
                          >
                            {
                              <img
                                src={IMAGE_BASE_URL + recentBlogs[0].image}
                              />
                            }
                          </Link>
                          <div className="recent-blog__content">
                            <span className="blog-category">
                              {recentBlogs[0].main_category}
                            </span>
                            <Link
                              to={`/blog-detail/${recentBlogs[0].title_slug}`}
                            >
                              <h2 className="font-weight-bold h4">
                                {recentBlogs[0].title.slice(0, 30)}
                                {recentBlogs[0]?.title?.length >= 30 && (
                                  <span>...</span>
                                )}
                              </h2>
                            </Link>
                            <div className="recent-blog__descp">
                              {recentBlogs[0] &&
                                parse(recentBlogs[0]?.summary.slice(0, 120))}
                              {recentBlogs[0]?.summary?.length > 120 && (
                                <span>...</span>
                              )}
                              <Link
                                to={`/blog-detail/${recentBlogs[0].title_slug}`}
                                className="blog-link"
                              >
                                Read more{" "}
                                <i className="ri-arrow-right-circle-fill"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}

                      {!!recentBlogs && recentBlogs?.length > 1 && (
                        <div>
                          <Link
                            to={`/blog-detail/${recentBlogs[1].title_slug}`}
                          >
                            <div
                              className="recent-blog__thumb"
                              style={{
                                backgroundImage: `url( ${IMAGE_BASE_URL}${recentBlogs[1].image})`,
                              }}
                            ></div>
                          </Link>
                          <div className="recent-blog__content">
                            <span className="blog-category">
                              {recentBlogs[1].main_category}
                            </span>

                            <Link
                              to={`/blog-detail/${recentBlogs[1].title_slug}`}
                            >
                              <h2 className="font-weight-bold h4">
                                {recentBlogs[1].title.slice(0, 20)}
                                {recentBlogs[1]?.title?.length > 20 && (
                                  <span>...</span>
                                )}
                              </h2>
                            </Link>
                            <div className="recent-blog__descp">
                              {recentBlogs[1] &&
                                parse(recentBlogs[1]?.summary.slice(0, 120))}
                              {recentBlogs[1]?.summary?.length > 120 && (
                                <span>...</span>
                              )}

                              <div className="mt-2">
                                <Link
                                  to={`/blog-detail/${recentBlogs[1].title_slug}`}
                                  className="blog-link"
                                >
                                  Read more{" "}
                                  <i className="ri-arrow-right-circle-fill"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {!!recentBlogs && recentBlogs?.length == 3 && (
                        <div>
                          <Link
                            to={`/blog-detail/${recentBlogs[2].title_slug}`}
                          >
                            <div
                              className="recent-blog__thumb"
                              style={{
                                backgroundImage: `url(${IMAGE_BASE_URL}${recentBlogs[2].image})`,
                              }}
                            ></div>
                          </Link>
                          <div className="recent-blog__content">
                            <span className="blog-category">
                              {recentBlogs[2].main_category}
                            </span>
                            <Link
                              to={`/blog-detail/${recentBlogs[2].title_slug}`}
                            >
                              <h2 className="font-weight-bold h4">
                                {recentBlogs[2].title.slice(0, 20)}
                                {recentBlogs[2]?.title?.length > 20 && (
                                  <span>...</span>
                                )}
                              </h2>
                            </Link>
                            <div className="recent-blog__descp">
                              {recentBlogs[2] && parse(recentBlogs[2]?.summary)}

                              {recentBlogs[2]?.summary?.length > 120 && (
                                <span>...</span>
                              )}
                              <Link
                                to={`/blog-detail/${recentBlogs[2].title_slug}`}
                                className="blog-link"
                              >
                                Read more{" "}
                                <i className="ri-arrow-right-circle-fill"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
