const SEARCH_PROFILE_DATA_REQUEST = "SEARCH_PROFILE_DATA_REQUEST";
const SEARCH_PROFILE_DATA_SUCCESS = "SEARCH_PROFILE_DATA_SUCCESS";
const SEARCH_PROFILE_DATA_FAILURE = "SEARCH_PROFILE_DATA_FAILURE";

const CLEAR_SEARCH_PROFILE_RESPONSE = "CLEAR_SEARCH_PROFILE_RESPONSE";

const CANCEL_REQUEST_DATA_REQUEST = "CANCEL_REQUEST_DATA_REQUEST";
const CANCEL_REQUEST_DATA_SUCCESS = "CANCEL_REQUEST_DATA_SUCCESS";
const CANCEL_REQUEST_DATA_FAILURE = "CANCEL_REQUEST_DATA_FAILURE";

const CLEAR_CANCEL_REQUEST_RESPONSE = "CLEAR_CANCEL_REQUEST_RESPONSE";

const UNFRIEND_DATA_REQUEST = "UNFRIEND_DATA_REQUEST";
const UNFRIEND_DATA_SUCCESS = "UNFRIEND_DATA_SUCCESS";
const UNFRIEND_DATA_FAILURE = "UNFRIEND_DATA_FAILURE";

const CLEAR_UNFRIEND_RESPONSE = "CLEAR_UNFRIEND_RESPONSE";

const CHECK_IS_POST_CLICKED = "CHECK_IS_POST_CLICKED";
const CHANGE_FRIEND_DETAIL = "CHANGE_FRIEND_DETAIL";

export {
  SEARCH_PROFILE_DATA_REQUEST,
  SEARCH_PROFILE_DATA_SUCCESS,
  SEARCH_PROFILE_DATA_FAILURE,
  CLEAR_SEARCH_PROFILE_RESPONSE,
  CANCEL_REQUEST_DATA_REQUEST,
  CANCEL_REQUEST_DATA_SUCCESS,
  CANCEL_REQUEST_DATA_FAILURE,
  CLEAR_CANCEL_REQUEST_RESPONSE,
  UNFRIEND_DATA_REQUEST,
  UNFRIEND_DATA_SUCCESS,
  UNFRIEND_DATA_FAILURE,
  CLEAR_UNFRIEND_RESPONSE,
  CHECK_IS_POST_CLICKED,
  CHANGE_FRIEND_DETAIL,
};
