import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "../LandingPage/HomeSection/landingpage.css";
import { getRecentBlogApi, getSingleBlogApi } from "./landingContentAction";
import parse from "html-react-parser";
import { css } from "@emotion/react";
import moment from "moment";
import { IMAGE_BASE_URL } from "../../../urls";
import { ClipLoader } from "react-spinners";
import RecentBlog from "./RecentBlog";
import Footer from "./HomeSection/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

export default function BlogDetail() {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const [singleBlogData, setSingleBlogData] = useState({});
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const blogDetails = useSelector((state) => state.blogPageReducer);

  const { recentBlogsData, singleBlogDetails } = blogDetails;

  useEffect(() => {
    if (
      singleBlogDetails?.getSingleBlogData &&
      recentBlogsData?.getRecentBlogsData
    ) {
      setSingleBlogData(singleBlogDetails?.getSingleBlogData);
      setRecentBlogs(recentBlogsData?.getRecentBlogsData);
      if (!!recentBlogsData) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  }, [blogDetails, recentBlogs, slug]);

  // TO FILTER SUB CATEGORY WITH MAIN CATEGORY
  let filterSubCategory;
  if (singleBlogData?.main_category && singleBlogData?.sub_categories) {
    filterSubCategory = singleBlogData?.sub_categories.filter((item) => {
      return item !== singleBlogData?.main_category;
    });
  }
  useEffect(() => {
    AOS.init({
      duration: 900,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    // if (Object.keys(slugParam).length) {

    dispatch(getSingleBlogApi(slug));
    dispatch(getRecentBlogApi());
    // }
    setLoading(true);
  }, [slug]);


  return (
    <div className="landing-wrapper">
      {loading ? (
        <ClipLoader
          color={"#2F2529"}
          loading={loading}
          size={80}
          css={{
            position: "absolute",
            top: "45%",
            left: "47%",
            transform: "translate(-50%,-50%)",
          }}
        />
      ) : (
        <>
          <Helmet>
        
        <meta property="og:title" content={singleBlogData?.title} />
        <meta property="og:description" content={singleBlogData?.description} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={IMAGE_BASE_URL + singleBlogData?.image} />
        <meta name="description" content={singleBlogData?.description} />
      </Helmet>
          <section className="spacer  blog-hero body-spacer search-wrap blog-details">
            <div className="container">
              <div className="row">
                {!!Object.keys(singleBlogData).length && (
                  <div className="col-md-12">
                    <div
                      className="text-center category-blk"
                      data-aos="fade-up"
                    >
                      {/* CATEGORIES */}
                      <span className="blog-category bg-pink">
                        {singleBlogData?.main_category}
                      </span>
                      {!!filterSubCategory &&
                        filterSubCategory.map((item, i) => (
                          <span className="blog-category" key={i}>
                            {item}
                          </span>
                        ))}
                    </div>
                    <div className="blog-summary" data-aos="fade-up">
                      {/*  TITLE & SUMMARY */}
                      <div className="text-center">
                        <h1 className="font-weight-bold">
                          {singleBlogData?.title}
                        </h1>
                        <p>
                          {singleBlogData?.summary &&
                            parse(singleBlogData?.summary)}
                        </p>
                        {/* AUTHOR NAME & CREATED AT */}
                        <div className="author-details">
                          <span>
                            <i className="ri-user-line"></i>
                            <p className="mb-0">
                              {singleBlogData?.author_name}
                            </p>
                          </span>
                          <span>
                            <i className="ri-calendar-2-line"></i>
                            <p className="mb-0">
                              {singleBlogData &&
                                moment(singleBlogData?.created_at).format(
                                  "DD/MM/YYYY"
                                )}
                            </p>
                          </span>
                        </div>
                      </div>
                      {/* BLOG IMAGE */}
                      <div className="blog-post-img my-4">
                        <img src={IMAGE_BASE_URL + singleBlogData?.image} />
                      </div>
                    </div>
                    {/* DESCRIPTION AND BOTTOM SECTION */}
                    <div className="blog-desc" data-aos="fade-up">
                      {singleBlogData?.description &&
                        parse(singleBlogData?.description)}
                    </div>
                    <div
                      className="blog-conclusion bg-light-grey p-4"
                      data-aos="fade-up"
                    >
                      <h4 className="font-weight-bold">Conclusion</h4>
                      {!!singleBlogData?.conclusion &&
                        parse(singleBlogData?.conclusion)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
          <div className="bg-light-grey" data-aos="fade-up">
            <RecentBlog recentBlogs={recentBlogs} />
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
