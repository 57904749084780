import {
  CLEAR_MEMBERS_ACCEPT_REQUEST_RESPONSE,
  CLEAR_MEMBERS_DECLINE_REQUEST_RESPONSE,
} from "./MembersNotificationConstant";

const clearMembersAcceptRequestResponse = (newState) => {
  return { type: CLEAR_MEMBERS_ACCEPT_REQUEST_RESPONSE, newState };
};

const clearMembersDeclineRequestResponse = (newState) => {
  return { type: CLEAR_MEMBERS_DECLINE_REQUEST_RESPONSE, newState };
};

export {
  clearMembersAcceptRequestResponse,
  clearMembersDeclineRequestResponse,
};
