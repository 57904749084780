import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { updateAdditinalApi } from "../../../library/api/AccountApiService";
import { Input } from "../../../library/common/components";
import { replceMultiStringWithSIngle } from "../../../library/utilities/functions";
import { changeGeneralInfoInput } from "../AccountAction";
import { useToasts } from "react-toast-notifications";
import { getCookie } from "../../../library/utilities/functions";
import { Scrollbars } from "react-custom-scrollbars";
import HoursOfOperation from "../../../library/common/components/HoursOfOperation";

const ProfileBox = ({ AccountState, dispatch, check_individual }) => {
  const selectInputModalRef = useRef();
  const selectInputModalTwoRef = useRef();
  const { addToast } = useToasts();
  const [infoLength, setInfoLength] = useState(0);
  const hideGeneralEl = useRef(null);
  const profile_info_validation = useRef(null);
  const [skillsData, setSkillsData] = useState([]);
  const [allSelectedLanguage, setAllSelectedLanguage] = useState([]);
  const [expertiseData, setExpertiseData] = useState([]);
  const [filterExpertiseOptions, setFilterExpertiseOptions] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [fileterLanguageOptions, setFilterLanguageOptions] = useState([]);
  const [filterSkillsOptions, setFilterSkillsOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [showAddLangusgeError, setShowAddLangusgeError] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");

  const {
    basicInfo: { profileImage, coverImage, businessImage },
  } = AccountState;
  const {
    GeneralInput: {
      intro,
      specialization,
      inputSpecialization,
      expertise,
      address,
      language,
      work,
      skills,
    },
  } = AccountState;

  const {
    getProfileApi: { getProfileResponse },
  } = AccountState;
  const handleChange = (e, type) => {
    let replaceMultiString = replceMultiStringWithSIngle(e.target.value);
    setInfoLength(replaceMultiString.length);
    if (type === "work" || type === "specialization") {
      dispatch(
        changeGeneralInfoInput({ [e.target.name]: e.target.value.slice(0, 40) })
      );
    } else {
      dispatch(
        changeGeneralInfoInput({
          [e.target.name]: e.target.value.slice(0, 299),
        })
      );
    }
  };

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#deebff" : null,
        color: "#333333",
      };
    },
  };
  const Proficiency = [
    { value: 1, label: "Native" },
    { value: 2, label: "Intermediate" },
    { value: 3, label: "Expert" },
  ];

  //this function is used to save profile details.
  const handleUpdate = () => {
    if (
      !!intro.length ||
      work !== "" ||
      address !== ""
    ) {
      const bodyParameters = new FormData();
      if (check_individual == "true") {
        bodyParameters.append("specialization", "");
        bodyParameters.append(
          "work",
          "" + replceMultiStringWithSIngle(work) == "" ||
            replceMultiStringWithSIngle(work) == " "
            ? ""
            : replceMultiStringWithSIngle(work)
        );
        skills.map((data, i) => {
          return bodyParameters.append(`skill[${i}][skill_id]`, data.value);
        });
      } else {
        expertise.map((data, i) =>
          bodyParameters.append(`expertise['${i}'][expertise_ids]`, data.value)
        );
        bodyParameters.append("specialization", inputSpecialization);
      }

      if (!!languageData) {
        allSelectedLanguage.forEach((item, i) =>
          bodyParameters.append(`languages[${i}][value]`, item.value)
        );
        allSelectedLanguage.forEach((item, index) =>
          bodyParameters.append(
            `languages[${index}][proficiency_level]`,
            item.proficiency_id
          )
        );
        allSelectedLanguage.forEach((item, i) =>
          bodyParameters.append(`languages[${i}][id]`, item.id || "")
        );
      }
      bodyParameters.append(
        "business_intro",
        intro || "");

      bodyParameters.append(
        "address",
        "" + replceMultiStringWithSIngle(address) == "" ||
          replceMultiStringWithSIngle(address) == " "
          ? ""
          : replceMultiStringWithSIngle(address)
      );
      bodyParameters.append(
        "latitude",
        +getCookie("latitude") ? getCookie("latitude") : ""
      );
      bodyParameters.append(
        "longitude",
        +getCookie("longitude") ? getCookie("longitude") : ""
      );
      bodyParameters.append("profile_image", profileImage);
      bodyParameters.append("business_image", businessImage);
      bodyParameters.append("cover_image", coverImage);

      dispatch(updateAdditinalApi(bodyParameters));
      hideGeneralEl.current.click();
    } else {
      addToast("Intro field can't be empty!", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
    }
  };
  //this function is used when edit the profile details than first add all previous details in the input field
  const showGeneralModel = () => {
    $(".edit-general").toggleClass("show-general");
    $(".user-info").toggleClass("hide-info");
    $(".general-setting").toggleClass("general-setting-show");

    window.setTimeout(() => {
      const showProjectsDialog = document.querySelector(".show-general");

      if (!!showProjectsDialog) {
        let replacessString = replceMultiStringWithSIngle(
          getProfileResponse.business_intro
        );
        setInfoLength(replacessString.length);
        let generaldata = {
          intro: getProfileResponse.business_intro,
          inputSpecialization: getProfileResponse.specialization,
          expertise: !!getProfileResponse.expertise
            ? getProfileResponse.expertise.map((data) => {
              return { label: data.label, value: data.value };
            })
            : [],
          address: getProfileResponse.address,
          work: !!getProfileResponse.work ? getProfileResponse.work : "",
          skills: !!getProfileResponse.skills
            ? getProfileResponse.skills.map((data) => {
              return { label: data.label, value: data.value };
            })
            : [],

          language: getProfileResponse?.languages?.map((data) => {
            return {
              id: data.id,
              value: data.value,
              label: data.label,
              proficiency_level: data?.proficiency_level,
              proficiency_id: data?.proficiency_id,
            };
          }),
        };
        dispatch(
          changeGeneralInfoInput({
            ...AccountState.GeneralInput,
            ...generaldata,
          })
        );
      } else {
        profile_info_validation.current.style.display = "none";
      }
    }, 0);
  };

  const removeActiveTabs = () => {
    const activeTabs = document.querySelectorAll(".profileTab");
    for (let i in activeTabs) {
      if (!!activeTabs[i] && !!activeTabs[i].classList) {
        activeTabs[i].classList.remove("active");
      }
    }
  };

  const profileTabHideShow = (e, pill) => {
    removeActiveTabs();
    const target = e.target;
    const introElm = document.getElementById("intro");
    const workElm = document.getElementById("work");
    const specializionElm = document.getElementById("specialization");
    const skillElm = document.getElementById("Skills");
    const expertiseElm = document.getElementById("Expertise");
    const addressElm = document.getElementById("Address");
    const languageElm = document.getElementById("Language");

    if (pill == "intro") {
      target.classList.add("active");
      introElm.classList.add("show");
      introElm.classList.add("active");

      if (check_individual == "true") {
        workElm.classList.remove("active");
        workElm.classList.remove("show");

        skillElm.classList.remove("active");
        skillElm.classList.remove("show");
      } else {
        specializionElm.classList.remove("active");
        specializionElm.classList.remove("show");

        expertiseElm.classList.remove("active");
        expertiseElm.classList.remove("show");
      }

      addressElm.classList.remove("active");
      addressElm.classList.remove("show");

      languageElm.classList.remove("active");
      languageElm.classList.remove("show");
    } else if (pill == "work") {
      target.classList.add("active");

      introElm.classList.remove("show");
      introElm.classList.remove("active");

      workElm.classList.add("active");
      workElm.classList.add("show");

      skillElm.classList.remove("active");
      skillElm.classList.remove("show");

      addressElm.classList.remove("active");
      addressElm.classList.remove("show");

      languageElm.classList.remove("active");
      languageElm.classList.remove("show");
    } else if (pill == "specialization") {
      target.classList.add("active");
      introElm.classList.remove("show");
      introElm.classList.remove("active");

      specializionElm.classList.add("active");
      specializionElm.classList.add("show");

      expertiseElm.classList.remove("active");
      expertiseElm.classList.remove("show");

      addressElm.classList.remove("active");
      addressElm.classList.remove("show");

      languageElm.classList.remove("active");
      languageElm.classList.remove("show");
    } else if (pill == "skills") {
      target.classList.add("active");
      introElm.classList.remove("show");
      introElm.classList.remove("active");

      workElm.classList.remove("active");
      workElm.classList.remove("show");

      skillElm.classList.add("active");
      skillElm.classList.add("show");

      addressElm.classList.remove("active");
      addressElm.classList.remove("show");

      languageElm.classList.remove("active");
      languageElm.classList.remove("show");
    } else if (pill == "expertise") {
      target.classList.add("active");
      introElm.classList.remove("show");
      introElm.classList.remove("active");

      specializionElm.classList.remove("active");
      specializionElm.classList.remove("show");

      expertiseElm.classList.add("active");
      expertiseElm.classList.add("show");

      addressElm.classList.remove("active");
      addressElm.classList.remove("show");

      languageElm.classList.remove("active");
      languageElm.classList.remove("show");
    } else if (pill == "address") {
      target.classList.add("active");
      introElm.classList.remove("show");
      introElm.classList.remove("active");

      if (check_individual == "true") {
        workElm.classList.remove("active");
        workElm.classList.remove("show");

        skillElm.classList.remove("active");
        skillElm.classList.remove("show");
      } else {
        specializionElm.classList.remove("active");
        specializionElm.classList.remove("show");

        expertiseElm.classList.remove("active");
        expertiseElm.classList.remove("show");
      }

      addressElm.classList.add("active");
      addressElm.classList.add("show");

      languageElm.classList.remove("active");
      languageElm.classList.remove("show");
    } else {
      target.classList.add("active");
      introElm.classList.remove("show");
      introElm.classList.remove("active");

      if (check_individual == "true") {
        workElm.classList.remove("active");
        workElm.classList.remove("show");

        skillElm.classList.remove("active");
        skillElm.classList.remove("show");
      } else {
        specializionElm.classList.remove("active");
        specializionElm.classList.remove("show");

        expertiseElm.classList.remove("active");
        expertiseElm.classList.remove("show");
      }

      addressElm.classList.remove("active");
      addressElm.classList.remove("show");

      languageElm.classList.add("active");
      languageElm.classList.add("show");
    }
  };

  useEffect(() => {
    setSkillsData(skills);
    setExpertiseData(expertise);
    setLanguageData(language);
  }, [skills, expertise, language]);

  // TO FILTER SKILLS//
  useEffect(() => {
    if (getProfileResponse?.skills !== undefined) {
      const option = AccountState.skillList?.filter((itemA) => {
        if (typeof skillsData !== "string") {
          return !skillsData.find((itemB) => {
            return itemA.name === itemB.label;
          });
        }
      });
      const newOptiom = option?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setFilterSkillsOptions(newOptiom);
    }
  }, [AccountState.skillList, skillsData]);

  // TO FILTER EXPERTISE //
  useEffect(() => {
    if (getProfileResponse?.expertise !== undefined) {
      const option = AccountState.expetiseList?.filter((itemA) => {
        if (typeof expertiseData !== "string") {
          return !expertiseData.find((itemB) => {
            return itemA.name === itemB.label;
          });
        }
      });
      const newOptiom = option?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setFilterExpertiseOptions(newOptiom);
    }
  }, [AccountState.expetiseList, expertiseData]);

  // TO FILTER LANGUAGES //
  useEffect(() => {
    const option = AccountState?.languageList?.filter((itemA) => {
      if (typeof languageData !== "string") {
        return !allSelectedLanguage.find((itemB) => {
          return itemA.name === itemB.label;
        });
      }
    });
    const newOption = option?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });

    setFilterLanguageOptions(newOption);
  }, [AccountState?.languageList, allSelectedLanguage]);

  const handleLangauage = (data) => {
    setSelectedLanguage(data);
  };
  const handleDelete = (id) => {
    const newShowSelectedLanguage = allSelectedLanguage.filter(
      (item, index) => index !== id
    );
    setAllSelectedLanguage(newShowSelectedLanguage);
  };
  const submitLanguage = () => {
    if (
      selectedLanguage != "" &&
      selectedLevel != "" &&
      selectedLanguage != null &&
      selectedLevel != null
    ) {
      let dataArray = [...allSelectedLanguage];
      dataArray.push({
        label: selectedLanguage.label,
        value: selectedLanguage.value,
        proficiency_level: selectedLevel.label,
        proficiency_id: selectedLevel.value,
        id: "",
      });
      setAllSelectedLanguage(dataArray);
      setShowAddLangusgeError(false);
      $("#addlanguageModal").modal("hide");
      selectInputModalRef.current.select.clearValue();
      selectInputModalTwoRef.current.select.clearValue();
    } else {
      setShowAddLangusgeError(true);
    }
  };
  const handleDropDownChange = (e, i) => {
    /* add particular proficiency_level value in api and id with onchange */
    const getProficiencyId = Proficiency.find(
      (item) => item.label === e.target.value
    ).value;
    let clone = [...allSelectedLanguage];
    let objData = clone[i];
    objData.proficiency_level = e.target.value;
    objData.proficiency_id = getProficiencyId;
    clone[i] = objData;
  };
  useEffect(() => {
    setAllSelectedLanguage(languageData);
  }, [languageData]);
  const handleCloseModel = () => {
    selectInputModalRef.current.select.clearValue();
    selectInputModalTwoRef.current.select.clearValue();
    setShowAddLangusgeError(false);
  };

  return (
    <>
      <div className="profile__box custom-shadow">
        <div className="profile__box__heading d-flex align-items-center justify-content-between">
          <h3 className="text-uppercase h4 general-settings">
            General
            {!!getProfileResponse && (
              <i
                className="ri-edit-box-line general-edit karan"
                ref={hideGeneralEl}
                onClick={showGeneralModel}
              />
            )}
          </h3>
          <Input
            type="button"
            name="save"
            className="btn btn-primary general-setting"
            value="Save"
            onClick={handleUpdate}
          />
        </div>
        <div className="edit-general">
          <div className="profile__box__options">
            {/* Repo Tabs -*/}
            <ul className="nav nav-tabs py-3 mb-4" id="repoTabs">
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  className="profileTab"
                  onClick={(e) => {
                    profileTabHideShow(e, "intro");
                  }}
                  data-toggle="tab"
                >
                  <i className="ri-draft-line" />
                  intro
                </a>
              </li>
              {check_individual == "true" ? (
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    className="profileTab"
                    onClick={(e) => {
                      profileTabHideShow(e, "work");
                    }}
                    data-toggle="tab"
                  >
                    <i className="ri-settings-line" />
                    Work
                  </a>
                </li>
              ) : (
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    className="profileTab"
                    onClick={(e) => {
                      profileTabHideShow(e, "specialization");
                    }}
                    data-toggle="tab"
                  >
                    <i className="ri-settings-line" />
                    Specialization
                  </a>
                </li>
              )}
              {check_individual == "true" ? (
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    className="profileTab"
                    onClick={(e) => {
                      profileTabHideShow(e, "skills");
                    }}
                    data-toggle="tab"
                  >
                    <i className="ri-user-line" />
                    Skills
                  </a>
                </li>
              ) : (
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    className="profileTab"
                    onClick={(e) => {
                      profileTabHideShow(e, "expertise");
                    }}
                    data-toggle="tab"
                  >
                    <i className="ri-user-line" />
                    Expertise
                  </a>
                </li>
              )}
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  className="profileTab"
                  onClick={(e) => {
                    profileTabHideShow(e, "address");
                  }}
                  data-toggle="tab"
                >
                  <i className="ri-map-pin-line" />
                  Address
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  className="profileTab"
                  onClick={(e) => {
                    profileTabHideShow(e, "language");
                  }}
                  data-toggle="tab"
                >
                  <i className="ri-global-line" />
                  Language(s) Spoken
                </a>
              </li>
            </ul>
          </div>
          {/* Repo Tabs */}
          <div className="tab-content">
            <div className="tab-pane active" id="intro">
              <h6 className="text-uppercase mb-3">Enter the information</h6>
              <textarea
                name="intro"
                
                className="form-control"
                value={intro}
                onChange={(e) => handleChange(e, "intro")}
                placeholder="Brief Intro about your business"
              />
              <p
                style={{ display: "none" }}
                ref={profile_info_validation}
                className="error-message"
              >
                Maximum length is 300
              </p>
              {infoLength}/300
            </div>
            {!!check_individual && check_individual == "true" ? (
              <div className="tab-pane" id="work">
                <h6 className="text-uppercase mb-3">Enter the Work</h6>
                <Input
                  type="text"
                  name="work"
                  className="form-control"
                  value={work}
                  placeholder="Your Job"
                  onChange={(e) => handleChange(e, "work")}
                />
              </div>
            ) : (
              <div className="tab-pane" id="specialization">
                <h6 className="text-uppercase mb-3">
                  Enter the Specialization
                </h6>
                <Input
                  type="text"
                  placeholder="Company specialization"
                  name="inputSpecialization"
                  className="form-control"
                  value={inputSpecialization}
                  onChange={(e) => handleChange(e, "specialization")}
                />
              </div>
            )}
            {!!check_individual && check_individual == "true" ? (
              <div className="tab-pane" id="Skills">
                <h6 className="text-uppercase mb-3">Enter the Skills</h6>
                <Select
                  value={skills}
                  options={filterSkillsOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Your skills"
                  isClearable
                  onChange={(newValue) =>
                    dispatch(changeGeneralInfoInput({ skills: newValue }))
                  }
                  isMulti
                />
              </div>
            ) : (
              <div className="tab-pane" id="Expertise">
                <h6 className="text-uppercase mb-3">Enter the Expertise</h6>
                <Select
                  value={expertise}
                  options={filterExpertiseOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Business field of expertise"
                  isClearable
                  onChange={(newValue) =>
                    dispatch(changeGeneralInfoInput({ expertise: newValue }))
                  }
                  isMulti
                />
              </div>
            )}
            <div className="tab-pane" id="Address">
              <h6 className="text-uppercase mb-3">Enter the Address</h6>
              <Input
                type="text"
                name="address"
                className="form-control"
                value={address}
                onChange={(e) => handleChange(e, "address")}
              />
            </div>
            <div className="tab-pane" id="Language">
              <div
                className="tab-pane"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="form-group">
                  <h5>Tell us which languages your company members speak.</h5>
                  <p>
                    PoolsMagnic is global, So clients are often interested to
                    know what languages you speak.
                  </p>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead id="language-table">
                        <tr>
                          <th>Language(s)</th>
                          <th>Proficiency Level</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!allSelectedLanguage.length &&
                          allSelectedLanguage.map((items, i) => {
                            return (
                              <tr
                                style={{ alignItems: "center" }}
                                key={items.value}
                              >
                                <td>{items.label} </td>
                                <td>
                                  <select
                                    onChange={(e) => handleDropDownChange(e, i)}
                                    className="form-control select-ui level-select"
                                  >
                                    <option>{items.proficiency_level}</option>
                                    <option>
                                      {items.proficiency_level !== "Native"
                                        ? "Native"
                                        : "Expert"
                                          ? "Intermediate"
                                          : "Expert"}
                                    </option>
                                    <option>
                                      {items.proficiency_level !== "Expert"
                                        ? "Expert"
                                        : "Intermediate"}
                                    </option>
                                  </select>
                                </td>

                                <td>
                                  <button
                                    className="btn-blank d-flex aliign-items-center link-color"
                                    onClick={() => handleDelete(i)}
                                  >
                                    <i className="ri-delete-bin-line align-middle mr-1" />
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  <a
                    href="javascript:;"
                    className="link-color"
                    data-toggle="modal"
                    data-target="#addlanguageModal"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <i className="ri-add-fill align-middle"></i> Add a Language
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="user-info">
          <div className="user-info__blk">
            <h5 className="user-info__heading mb-3">
              <i className="ri-draft-line" /> intro
            </h5>
            <p>
              {!!getProfileResponse.business_intro
                ? getProfileResponse.business_intro
                : ""}
            </p>
          </div>
          {!!check_individual && check_individual == "true" ? (
            <div className="user-info__blk">
              <h5 className="user-info__heading mb-3">
                <i className="ri-settings-line" /> Work
              </h5>
              <p>{!!getProfileResponse.work ? getProfileResponse.work : ""}</p>
            </div>
          ) : (
            <div className="user-info__blk">
              <h5 className="user-info__heading mb-3">
                <i className="ri-settings-line" /> Specialization
              </h5>
              <p>
                {!!getProfileResponse.specialization
                  ? getProfileResponse.specialization
                  : ""}
              </p>
            </div>
          )}
          {!!check_individual && check_individual == "true" ? (
            <div className="user-info__blk">
              <h5 className="user-info__heading mb-3">
                <i className="ri-user-line" /> Skills
              </h5>

              <div className="vertically-list text-break">
                {!!getProfileResponse &&
                  getProfileResponse?.skills
                    ?.slice(0, 6)
                    ?.map((data, index) => {
                      return index !=
                        getProfileResponse?.skills?.slice(0, 6).length - 1
                        ? data.label + ", "
                        : data.label + "";
                    })}
              </div>
              {getProfileResponse?.skills?.length > 6 && (
                <div className="viewall-list position-relative d-inline-block">
                  <span role="button" className="text-secondary">
                    See More
                  </span>
                  <div className="skills-listing-wrap">
                    <Scrollbars style={{ height: 250 }}>
                      {getProfileResponse?.skills?.map((item, index) => (
                        <p key={index}>{item.label}</p>
                      ))}
                    </Scrollbars>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="user-info__blk">
              <h5 className="user-info__heading mb-3">
                <i className="ri-user-line" /> Expertise
              </h5>

              <div className="vertically-list text-break">
                <p>
                  {getProfileResponse?.expertise
                    ?.slice(0, 6)
                    ?.map((data, index) => {
                      return index !=
                        getProfileResponse?.expertise?.slice(0, 6).length - 1
                        ? data.label + ", "
                        : data.label + "";
                    })}
                </p>
                {getProfileResponse?.expertise?.length > 6 && (
                  <div className="viewall-list position-relative d-inline-block">
                    <span role="button" className="text-secondary">
                      See More
                    </span>
                    <div className="skills-listing-wrap skills-index">
                      <Scrollbars style={{ height: 250 }}>
                        {getProfileResponse?.expertise?.map((item, index) => (
                          <p key={index}>{item.label}</p>
                        ))}
                      </Scrollbars>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/*  show today open or not if open show time if not show closed */}
          <HoursOfOperation hours_details={getProfileResponse} />
          <div className="user-info__blk">
            <h5 className="user-info__heading mb-3">
              <i className="ri-map-pin-line" /> Address
            </h5>
            <p>
              {!!getProfileResponse.address ? getProfileResponse.address : ""}
            </p>
          </div>
          <div className="user-info__blk">
            <h5 className="user-info__heading mb-3">
              <i className="ri-global-line" /> Language(s) Spoken
            </h5>
            {!!getProfileResponse?.languages ? (
              <div className="languages d-flex flex-wrap">
                {getProfileResponse?.languages
                  .slice(0, 6)
                  .map((data, index) => {
                    const { proficiency_level } = data;
                    return (
                      <>
                        <div key={index}>
                          {data.label + " "}
                          <span
                            className="mb-2"
                            title={proficiency_level}
                            key={index}
                          >
                            <span
                              className={`${proficiency_level == "Expert"
                                ? "expert"
                                : proficiency_level == "Intermediate"
                                  ? "interminate"
                                  : proficiency_level == "Native"
                                    ? "native"
                                    : null
                                }`}
                            >
                              {proficiency_level.charAt(0)}
                            </span>
                            <span>
                              {index !=
                                getProfileResponse.languages.slice(0, 6).length -
                                1
                                ? ","
                                : " "}
                            </span>
                          </span>
                        </div>
                      </>
                    );
                  })}
              </div>
            ) : (
              ""
            )}
            {getProfileResponse.languages?.length > 6 && (
              <div className="viewall-list position-relative d-inline-block mt-2">
                <span role="button" className="text-secondary">
                  See More
                </span>
                <div className="skills-listing-wrap">
                  <Scrollbars style={{ height: 250 }}>
                    {getProfileResponse.languages?.map((item, index) => (
                      <div className="d-flex">
                        <p  key={index} style={{ minWidth: 70 }}>{item.label} </p>
                        <span
                          className="mb-2"
                          title={item.proficiency_level}
                          key={index}
                        >
                          <p
                            className={`${item.proficiency_level == "Expert"
                              ? "expert"
                              : item.proficiency_level == "Intermediate"
                                ? "interminate"
                                : item.proficiency_level == "Native"
                                  ? "native"
                                  : null
                              }`}
                          >
                            {item.proficiency_level.charAt(0)}
                          </p>
                        </span>
                      </div>
                    ))}
                  </Scrollbars>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addlanguageModal"
        tabIndex="-1"
        aria-labelledby="languageModalModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="languageModalLabel">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon"
                />{" "}
                Add Language
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Language</label>
                    <Select
                      ref={selectInputModalRef}
                      options={fileterLanguageOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      styles={customStyles}
                      placeholder="Add your Language(s)"
                      onChange={(newState) => handleLangauage(newState)}
                    />
                    {showAddLangusgeError &&
                      selectedLanguage?.label?.length == undefined && (
                        <p className="error-message" style={{ color: "red" }}>
                          Please select the Language
                        </p>
                      )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Proficiency Level</label>
                    <Select
                      ref={selectInputModalTwoRef}
                      styles={customStyles}
                      placeholder="Select Level"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={Proficiency}
                      onChange={(newValueData) =>
                        setSelectedLevel(newValueData)
                      }
                    />
                    {showAddLangusgeError &&
                      selectedLevel?.label?.length == undefined && (
                        <p className="error-message" style={{ color: "red" }}>
                          Please select the Proficiency Level
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={submitLanguage}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileBox;
