const INVITE_MODEL_INPUT = "INVITE_MODEL_INPUT";

const INVITE_DATA_REQUEST = "INVITE_DATA_REQUEST";
const INVITE_DATA_SUCCESS = "INVITE_DATA_SUCCESS";
const INVITE_DATA_FAILURE = "INVITE_DATA_FAILURE";

const CLEAR_INVITE_RESPONSE = "CLEAR_INVITE_RESPONSE";

const CHANGE_SHARE_INPUT = "CHANGE_SHARE_INPUT";

const POST_UPLOAD_REQUEST = "POST_UPLOAD_REQUEST";
const POST_UPLOAD_SUCCESS = "POST_UPLOAD_SUCCESS";
const POST_UPLOAD_FAILURE = "POST_UPLOAD_FAILURE";

const POST_UPDATE_REQUEST = "POST_UPDATE_REQUEST";
const POST_UPDATE_SUCCESS = "POST_UPDATE_SUCCESS";
const POST_UPDATE_FAILURE = "POST_UPDATE_FAILURE";

const CLEAR_POST_UPLOAD_RESPONSE = "CLEAR_POST_UPLOAD_RESPONSE";

const CLEAR_POST_UPDATE_RESPONSE = "CLEAR_POST_UPDATE_RESPONSE";

const GET_POST_REQUEST = "GET_POST_REQUEST";
const GET_POST_SUCCESS = "GET_POST_SUCCESS";
const GET_POST_FAILURE = "GET_POST_FAILURE";

const GET_PROMOTIONAL_REQUEST = "GET_PROMOTIONAL_REQUEST";
const GET_PROMOTIONAL_SUCCESS = "GET_PROMOTIONAL_SUCCESS";
const GET_PROMOTIONAL_FAIULRE = "GET_PROMOTIONAL_FAIULRE";

const CLEAR_GET_POST_RESPONSE = "CLEAR_GET_POST_RESPONSE";

const CHANGE_ACTIVA_CAROUSAL = "CHANGE_ACTIVA_CAROUSAL";

const CHANGE_ALL_POST_DETAIL = "CHANGE_ALL_POST_DETAIL";

const LIKE_DISLIKE_POST_DATA_REQUEST = "LIKE_DISLIKE_POST_DATA_REQUEST";
const LIKE_DISLIKE_POST_DATA_SUCCESS = "LIKE_DISLIKE_POST_DATA_SUCCESS";
const LIKE_DISLIKE_POST_DATA_FAILURE = "LIKE_DISLIKE_POST_DATA_FAILURE";

const CLEAR_LIKE_DISLIKE_RESPONSE = "CLEAR_LIKE_DISLIKE_RESPONSE";

const SINGLE_POST_LIKE_DATA_REQUEST = "SINGLE_POST_LIKE_DATA_REQUEST";
const SINGLE_POST_LIKE_DATA_SUCCESS = "SINGLE_POST_LIKE_DATA_SUCCESS";
const SINGLE_POST_LIKE_DATA_FAILURE = "SINGLE_POST_LIKE_DATA_FAILURE";

const CLEAR_SINGLE_LIKE_POST_RESPONSE = "CLEAR_SINGLE_LIKE_POST_RESPONSE";

const POST_REACTION_DATA_REQUEST = "POST_REACTION_DATA_REQUEST";
const POST_REACTION_DATA_SUCCESS = "POST_REACTION_DATA_SUCCESS";
const POST_REACTION_DATA_FAILURE = "POST_REACTION_DATA_FAILURE";

const CLEAR_POST_REACTION_RESPONSE = "CLEAR_POST_REACTION_RESPONSE";

const SINGLE_POST_REACTION_DATA_REQUEST = "SINGLE_POST_REACTION_DATA_REQUEST";
const SINGLE_POST_REACTION_DATA_SUCCESS = "SINGLE_POST_REACTION_DATA_SUCCESS";
const SINGLE_POST_REACTION_DATA_FAILURE = "SINGLE_POST_REACTION_DATA_FAILURE";

const CLEAR_SINGLE_POST_REACTION_RESPONSE =
  "CLEAR_SINGLE_POST_REACTION_RESPONSE";

const POST_LIKE_LIST_DATA_REQUEST = "POST_LIKE_LIST_DATA_REQUEST";
const POST_LIKE_LIST_DATA_SUCCESS = "POST_LIKE_LIST_DATA_SUCCESS";
const POST_LIKE_LIST_DATA_FAILURE = "POST_LIKE_LIST_DATA_FAILURE";

const CLEAR_POST_LIKE_LIST_RESPONSE = "CLEAR_POST_LIKE_LIST_RESPONSE";

const CHANGE_LIKE_LIST_PAGE = "CHANGE_LIKE_LIST_PAGE";

const SINGLE_LIKE_LIST_DATA_REQUEST = "SINGLE_LIKE_LIST_DATA_REQUEST";
const SINGLE_LIKE_LIST_DATA_SUCCESS = "SINGLE_LIKE_LIST_DATA_SUCCESS";
const SINGLE_LIKE_LIST_DATA_FAILURE = "SINGLE_LIKE_LIST_DATA_FAILURE";

const CLEAR_SINGLE_LIKE_LIST_RESPONSE = "CLEAR_SINGLE_LIKE_LIST_RESPONSE";

const POST_REACTION_LIST_DATA_REQUEST = "POST_REACTION_LIST_DATA_REQUEST";
const POST_REACTION_LIST_DATA_SUCCESS = "POST_REACTION_LIST_DATA_SUCCESS";
const POST_REACTION_LIST_DATA_FAILURE = "POST_REACTION_LIST_DATA_FAILURE";

const CLEAR_POST_REACTION_LIST_RESPONSE = "CLEAR_POST_REACTION_LIST_RESPONSE";

const CHANGE_REACTION_LIST_DETAIL = "CHANGE_REACTION_LIST_DETAIL";

const SINGLE_REACTION_LIST_DATA_REQUEST = "SINGLE_REACTION_LIST_DATA_REQUEST";
const SINGLE_REACTION_LIST_DATA_SUCCESS = "SINGLE_REACTION_LIST_DATA_SUCCESS";
const SINGLE_REACTION_LIST_DATA_FAILURE = "SINGLE_REACTION_LIST_DATA_FAILURE";

const CLEAR_SINGLE_REACTION_LIST_RESPONSE =
  "CLEAR_SINGLE_REACTION_LIST_RESPONSE";

const POST_COMMENT_LIST_DATA_REQUEST = "POST_COMMENT_LIST_DATA_REQUEST";
const POST_COMMENT_LIST_DATA_SUCCESS = "POST_COMMENT_LIST_DATA_SUCCESS";
const POST_COMMENT_LIST_DATA_FAILURE = "POST_COMMENT_LIST_DATA_FAILURE";

const CLEAR_POST_COMMENT_LIST_RESPONSE = "CLEAR_POST_COMMENT_LIST_RESPONSE";

const CHANGE_COMMENT_INPUT_DETAIL = "CHANGE_COMMENT_INPUT_DETAIL";

const POST_COMMENT_DATA_REQUEST = "POST_COMMENT_DATA_REQUEST";
const POST_COMMENT_DATA_SUCCESS = "POST_COMMENT_DATA_SUCCESS";
const POST_COMMENT_DATA_FAILURE = "POST_COMMENT_DATA_FAILURE";

const CLEAR_POST_COMMENT_RESPONSE = "CLEAR_POST_COMMENT_RESPONSE";

const POST_COMMENT_REPLY_DATA_REQUEST = "POST_COMMENT_REPLY_DATA_REQUEST";
const POST_COMMENT_REPLY_DATA_SUCCESS = "POST_COMMENT_REPLY_DATA_SUCCESS";
const POST_COMMENT_REPLY_DATA_FAILURE = "POST_COMMENT_REPLY_DATA_FAILURE";

const CLEAR_COMMENT_REPLY_RESPONSE = "CLEAR_COMMENT_REPLY_RESPONSE";

const POST_COMMENT_REPLY_LIST_DATA_REQUEST =
  "POST_COMMENT_REPLY_LIST_DATA_REQUEST";
const POST_COMMENT_REPLY_LIST_DATA_SUCCESS =
  "POST_COMMENT_REPLY_LIST_DATA_SUCCESS";
const POST_COMMENT_REPLY_LIST_DATA_FAILURE =
  "POST_COMMENT_REPLY_LIST_DATA_FAILURE";

const CLEAR_POST_COMMENT_REPLY_LIST_RESPONSE =
  "CLEAR_POST_COMMENT_REPLY_LIST_RESPONSE";

const SINGLE_POST_COMMENT_DATA_REQUEST = "SINGLE_POST_COMMENT_DATA_REQUEST";
const SINGLE_POST_COMMENT_DATA_SUCCESS = "SINGLE_POST_COMMENT_DATA_SUCCESS";
const SINGLE_POST_COMMENT_DATA_FAILURE = "SINGLE_POST_COMMENT_DATA_FAILURE";

const CLEAR_SINGLE_POST_COMMENT_RESPONSE = "CLEAR_SINGLE_POST_COMMENT_RESPONSE";

const SINGLE_POST_COMMENT_LIST_DATA_REQUEST =
  "SINGLE_POST_COMMENT_LIST_DATA_REQUEST";
const SINGLE_POST_COMMENT_LIST_DATA_SUCCESS =
  "SINGLE_POST_COMMENT_LIST_DATA_SUCCESS";
const SINGLE_POST_COMMENT_LIST_DATA_FAILURE =
  "SINGLE_POST_COMMENT_LIST_DATA_FAILURE";

const CLEAR_SINGLE_POST_COMMENT_LIST_RESPONSE =
  "CLEAR_SINGLE_POST_COMMENT_LIST_RESPONSE";

const SINGLE_POST_CMT_REPLY_DATA_REQUEST = "SINGLE_POST_CMT_REPLY_DATA_REQUEST";
const SINGLE_POST_CMT_REPLY_DATA_SUCCESS = "SINGLE_POST_CMT_REPLY_DATA_SUCCESS";
const SINGLE_POST_CMT_REPLY_DATA_FAILURE = "SINGLE_POST_CMT_REPLY_DATA_FAILURE";

const CLEAR_SINGLE_POST_CMT_REPLY_RESPONSE =
  "CLEAR_SINGLE_POST_CMT_REPLY_RESPONSE";

const SINGLE_POST_CMT_REPLY_LIST_DATA_REQUEST =
  "SINGLE_POST_CMT_REPLY_LIST_DATA_REQUEST";
const SINGLE_POST_CMT_REPLY_LIST_DATA_SUCCESS =
  "SINGLE_POST_CMT_REPLY_LIST_DATA_SUCCESS";
const SINGLE_POST_CMT_REPLY_LIST_DATA_FAILURE =
  "SINGLE_POST_CMT_REPLY_LIST_DATA_FAILURE";

const CLEAR_SINGLE_POST_CMT_REPLY_LIST_RESPONSE =
  "CLEAR_SINGLE_POST_CMT_REPLY_LIST_RESPONSE";

const POST_VIEW_DATA_REQUEST = "POST_VIEW_DATA_REQUEST";
const POST_VIEW_DATA_SUCCESS = "POST_VIEW_DATA_SUCCESS";
const POST_VIEW_DATA_FAILURE = "POST_VIEW_DATA_FAILURE";

const CLEAR_POST_VIEW_RESPONSE = "CLEAR_POST_VIEW_RESPONSE";

const SINGLE_POST_VIEW_DATA_REQUEST = "SINGLE_POST_VIEW_DATA_REQUEST";
const SINGLE_POST_VIEW_DATA_SUCCESS = "SINGLE_POST_VIEW_DATA_SUCCESS";
const SINGLE_POST_VIEW_DATA_FAILURE = "SINGLE_POST_VIEW_DATA_FAILURE";

const CLEAR_SINGLE_POST_VIEW_RESPONSE = "CLEAR_SINGLE_POST_VIEW_RESPONSE";

const POST_VIEW_LIST_DATA_REQUEST = "POST_VIEW_LIST_DATA_REQUEST";
const POST_VIEW_LIST_DATA_SUCCESS = "POST_VIEW_LIST_DATA_SUCCESS";
const POST_VIEW_LIST_DATA_FAILURE = "POST_VIEW_LIST_DATA_FAILURE";

const CLEAR_POST_VIEW_LIST_RESPONSE = "CLEAR_POST_VIEW_LIST_RESPONSE";

const CHANGE_POST_VIEW_DETAIL = "CHANGE_POST_VIEW_DETAIL";

const SINGLE_POST_VIEW_LIST_DATA_REQUEST = "SINGLE_POST_VIEW_LIST_DATA_REQUEST";
const SINGLE_POST_VIEW_LIST_DATA_SUCCESS = "SINGLE_POST_VIEW_LIST_DATA_SUCCESS";
const SINGLE_POST_VIEW_LIST_DATA_FAILURE = "SINGLE_POST_VIEW_LIST_DATA_FAILURE";

const CLEAR_SINGLE_POST_LIST_RESPONSE = "CLEAR_SINGLE_POST_LIST_RESPONSE";

const POST_SHARE_DATA_REQUEST = "POST_SHARE_DATA_REQUEST";
const POST_SHARE_DATA_SUCCESS = "POST_SHARE_DATA_SUCCESS";
const POST_SHARE_DATA_FAILURE = "POST_SHARE_DATA_FAILURE";

const CLEAR_POST_SHARE_RESPONSE = "CLEAR_POST_SHARE_RESPONSE";

const SINGLE_POST_SHARE_DATA_REQUEST = "SINGLE_POST_SHARE_DATA_REQUEST";
const SINGLE_POST_SHARE_DATA_SUCCESS = "SINGLE_POST_SHARE_DATA_SUCCESS";
const SINGLE_POST_SHARE_DATA_FAILURE = "SINGLE_POST_SHARE_DATA_FAILURE";

const CLEAR_SINGLE_POST_SHARE_RESPONSE = "CLEAR_SINGLE_POST_SHARE_RESPONSE";

const CHANGE_SHARE_LIST_DETAIL = "CHANGE_SHARE_LIST_DETAIL";

const POST_SHARE_LIST_DATA_REQUEST = "POST_SHARE_LIST_DATA_REQUEST";
const POST_SHARE_LIST_DATA_SUCCESS = "POST_SHARE_LIST_DATA_SUCCESS";
const POST_SHARE_LIST_DATA_FAILURE = "POST_SHARE_LIST_DATA_FAILURE";

const CLEAR_POST_SHARE_LIST_RESPONSE = "CLEAR_POST_SHARE_LIST_RESPONSE";

const SINGLE_POST_SHARE_LIST_DATA_REQUEST =
  "SINGLE_POST_SHARE_LIST_DATA_REQUEST";
const SINGLE_POST_SHARE_LIST_DATA_SUCCESS =
  "SINGLE_POST_SHARE_LIST_DATA_SUCCESS";
const SINGLE_POST_SHARE_LIST_DATA_FAILURE =
  "SINGLE_POST_SHARE_LIST_DATA_FAILURE";

const CLEAR_SINGLE_POST_SHARE_LIST_RESPONSE =
  "CLEAR_SINGLE_POST_SHARE_LIST_RESPONSE";
const POST_EDIT_DATA = "POST_EDIT_DATA";

export {
  INVITE_MODEL_INPUT,
  INVITE_DATA_REQUEST,
  INVITE_DATA_SUCCESS,
  INVITE_DATA_FAILURE,
  CLEAR_INVITE_RESPONSE,
  CHANGE_SHARE_INPUT,
  POST_UPLOAD_REQUEST,
  POST_UPLOAD_SUCCESS,
  POST_UPLOAD_FAILURE,
  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_FAILURE,
  CLEAR_POST_UPLOAD_RESPONSE,
  CLEAR_POST_UPDATE_RESPONSE,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  CLEAR_GET_POST_RESPONSE,
  CHANGE_ACTIVA_CAROUSAL,
  CHANGE_ALL_POST_DETAIL,
  LIKE_DISLIKE_POST_DATA_REQUEST,
  LIKE_DISLIKE_POST_DATA_SUCCESS,
  LIKE_DISLIKE_POST_DATA_FAILURE,
  CLEAR_LIKE_DISLIKE_RESPONSE,
  SINGLE_POST_LIKE_DATA_REQUEST,
  SINGLE_POST_LIKE_DATA_SUCCESS,
  SINGLE_POST_LIKE_DATA_FAILURE,
  CLEAR_SINGLE_LIKE_POST_RESPONSE,
  POST_REACTION_DATA_REQUEST,
  POST_REACTION_DATA_SUCCESS,
  POST_REACTION_DATA_FAILURE,
  CLEAR_POST_REACTION_RESPONSE,
  SINGLE_POST_REACTION_DATA_REQUEST,
  SINGLE_POST_REACTION_DATA_SUCCESS,
  SINGLE_POST_REACTION_DATA_FAILURE,
  CLEAR_SINGLE_POST_REACTION_RESPONSE,
  POST_LIKE_LIST_DATA_REQUEST,
  POST_LIKE_LIST_DATA_SUCCESS,
  POST_LIKE_LIST_DATA_FAILURE,
  CLEAR_POST_LIKE_LIST_RESPONSE,
  CHANGE_LIKE_LIST_PAGE,
  SINGLE_LIKE_LIST_DATA_REQUEST,
  SINGLE_LIKE_LIST_DATA_SUCCESS,
  SINGLE_LIKE_LIST_DATA_FAILURE,
  CLEAR_SINGLE_LIKE_LIST_RESPONSE,
  POST_REACTION_LIST_DATA_REQUEST,
  POST_REACTION_LIST_DATA_SUCCESS,
  POST_REACTION_LIST_DATA_FAILURE,
  CLEAR_POST_REACTION_LIST_RESPONSE,
  CHANGE_REACTION_LIST_DETAIL,
  SINGLE_REACTION_LIST_DATA_REQUEST,
  SINGLE_REACTION_LIST_DATA_SUCCESS,
  SINGLE_REACTION_LIST_DATA_FAILURE,
  CLEAR_SINGLE_REACTION_LIST_RESPONSE,
  POST_COMMENT_LIST_DATA_REQUEST,
  POST_COMMENT_LIST_DATA_SUCCESS,
  POST_COMMENT_LIST_DATA_FAILURE,
  CLEAR_POST_COMMENT_LIST_RESPONSE,
  CHANGE_COMMENT_INPUT_DETAIL,
  POST_COMMENT_DATA_REQUEST,
  POST_COMMENT_DATA_SUCCESS,
  POST_COMMENT_DATA_FAILURE,
  CLEAR_POST_COMMENT_RESPONSE,
  POST_COMMENT_REPLY_DATA_REQUEST,
  POST_COMMENT_REPLY_DATA_SUCCESS,
  POST_COMMENT_REPLY_DATA_FAILURE,
  CLEAR_COMMENT_REPLY_RESPONSE,
  POST_COMMENT_REPLY_LIST_DATA_REQUEST,
  POST_COMMENT_REPLY_LIST_DATA_SUCCESS,
  POST_COMMENT_REPLY_LIST_DATA_FAILURE,
  CLEAR_POST_COMMENT_REPLY_LIST_RESPONSE,
  CLEAR_SINGLE_POST_COMMENT_RESPONSE,
  SINGLE_POST_COMMENT_DATA_REQUEST,
  SINGLE_POST_COMMENT_DATA_SUCCESS,
  SINGLE_POST_COMMENT_DATA_FAILURE,
  SINGLE_POST_COMMENT_LIST_DATA_REQUEST,
  SINGLE_POST_COMMENT_LIST_DATA_SUCCESS,
  SINGLE_POST_COMMENT_LIST_DATA_FAILURE,
  CLEAR_SINGLE_POST_COMMENT_LIST_RESPONSE,
  SINGLE_POST_CMT_REPLY_DATA_REQUEST,
  SINGLE_POST_CMT_REPLY_DATA_SUCCESS,
  SINGLE_POST_CMT_REPLY_DATA_FAILURE,
  CLEAR_SINGLE_POST_CMT_REPLY_RESPONSE,
  SINGLE_POST_CMT_REPLY_LIST_DATA_REQUEST,
  SINGLE_POST_CMT_REPLY_LIST_DATA_SUCCESS,
  SINGLE_POST_CMT_REPLY_LIST_DATA_FAILURE,
  CLEAR_SINGLE_POST_CMT_REPLY_LIST_RESPONSE,
  POST_VIEW_DATA_REQUEST,
  POST_VIEW_DATA_SUCCESS,
  POST_VIEW_DATA_FAILURE,
  CLEAR_POST_VIEW_RESPONSE,
  SINGLE_POST_VIEW_DATA_REQUEST,
  SINGLE_POST_VIEW_DATA_SUCCESS,
  SINGLE_POST_VIEW_DATA_FAILURE,
  CLEAR_SINGLE_POST_VIEW_RESPONSE,
  POST_VIEW_LIST_DATA_REQUEST,
  POST_VIEW_LIST_DATA_SUCCESS,
  POST_VIEW_LIST_DATA_FAILURE,
  CLEAR_POST_VIEW_LIST_RESPONSE,
  CHANGE_POST_VIEW_DETAIL,
  SINGLE_POST_VIEW_LIST_DATA_REQUEST,
  SINGLE_POST_VIEW_LIST_DATA_SUCCESS,
  SINGLE_POST_VIEW_LIST_DATA_FAILURE,
  CLEAR_SINGLE_POST_LIST_RESPONSE,
  POST_SHARE_DATA_REQUEST,
  POST_SHARE_DATA_SUCCESS,
  POST_SHARE_DATA_FAILURE,
  CLEAR_POST_SHARE_RESPONSE,
  SINGLE_POST_SHARE_DATA_REQUEST,
  SINGLE_POST_SHARE_DATA_SUCCESS,
  SINGLE_POST_SHARE_DATA_FAILURE,
  CLEAR_SINGLE_POST_SHARE_RESPONSE,
  CHANGE_SHARE_LIST_DETAIL,
  POST_SHARE_LIST_DATA_REQUEST,
  POST_SHARE_LIST_DATA_SUCCESS,
  POST_SHARE_LIST_DATA_FAILURE,
  CLEAR_POST_SHARE_LIST_RESPONSE,
  SINGLE_POST_SHARE_LIST_DATA_REQUEST,
  SINGLE_POST_SHARE_LIST_DATA_SUCCESS,
  SINGLE_POST_SHARE_LIST_DATA_FAILURE,
  CLEAR_SINGLE_POST_SHARE_LIST_RESPONSE,
  POST_EDIT_DATA,
  GET_PROMOTIONAL_REQUEST,
  GET_PROMOTIONAL_SUCCESS,
  GET_PROMOTIONAL_FAIULRE,
};
