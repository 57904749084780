import io from "socket.io-client";

/*  URL */
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
const MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL;
const ADMIN = process.env.REACT_APP_ADMIN;
let SOCKET = null;
const establishSocket = (userid) => {
  SOCKET = io(process.env.REACT_APP_SOCKET, {
    transports: ["websocket", "polling"],
    autoConnect: false,
    query: { userid: userid },
    secure: true,
  });
  if (userid) {
    SOCKET.connect();
  }
};
establishSocket();
// ---------------------- login API's start ----------------------------------//

const getApiUrl = (endpoint) => API_BASE_URL + endpoint;
const AUTH_CHECK = getApiUrl("/auth-check");
const LOGIN_DATA_API = getApiUrl("/login");
const SIGNUP_DATA_API = getApiUrl("/register");
const FORGOT_PASSWORD_DATA_API = getApiUrl("/forgot-password");
const RESET_PASSWORD_DATA_API = getApiUrl("/change-password");
const INVITE_DATA_API = getApiUrl("/invite");
const GET_PROFILE_API = getApiUrl("/get-profile");
const UPDATE_ADDITIONAL_INFO = getApiUrl("/additional-info");
const LANGUAGE_LIST_API = getApiUrl("/language-list");
const SKILLS_LIST_API = getApiUrl("/skills-list");
const EXPERTISE_LIST_API = getApiUrl("/expertise-list");
const ADD_SOCIAL_ICON_API = getApiUrl("/add-social");
const GET_SOCIAL_ICON_API = getApiUrl("/get-social");
const SPECIALIZATION_LIST_API = getApiUrl("/specializations-list");
const GET_MANAGE_ACCOUNT_API = getApiUrl("/get-manage-account");
/**
 * projects urls
 */
const GET_PROJECTS_API = getApiUrl("/projects-list");
const SAVE_MY_PROJECTS_API = getApiUrl("/add-projects");
const DELETE_PROJECT_API = getApiUrl("/delete-project");
const UPDATE_PROJECT_API = getApiUrl("/update-project");
const DELETE_PROJECT_IMAGE =getApiUrl("/delete-project-image")

/**
 * employments urls
 */
const GET_EMPLOYMENTS_API = getApiUrl("/employment-list");
const SAVE_MY_EMPLOYMENTS_API = getApiUrl("/add-employment");
const DELETE_EMPLOYMENT_API = getApiUrl("/delete-employment");
const UPDATE_EMPLOYMENT_API = getApiUrl("/update-employment");
/**
 * search list
 */

const GOOGLE_MEET_INTEGRATION = getApiUrl("/googleLogin");
const MINESTONE_SEARCH = getApiUrl("/milestone-search");
const GET_SELLER_STATUS = getApiUrl("/get-seller-status");
const DELETE_USER_SERVICE = getApiUrl("/user-services/delete");
const SEARCH_LIST_API = getApiUrl("/search-user");
const SEARCH_INDIVIDUAL_API = getApiUrl("/members/search-individuals");
const ADD_MEMBERS = getApiUrl("/members/add");
const MEMBERS = getApiUrl("/members");
const SEARCH_PROFILE_DATA_API = getApiUrl("/get-additional-details");
const SEND_REQUEST_API = getApiUrl("/send-request");
const MEMBERS_REMOVE = getApiUrl("/members/remove");
const CANCEL_REQUEST_API = getApiUrl("/delete-request");
const ACCEPT_REQUEST_API = getApiUrl("/accept-request");
const DECLINE_REQUEST_API = getApiUrl("/cancel-request");
const ACCEPT_REQUEST_MEMBERS_API = getApiUrl("/members/accept-request");
const DECLINE_REQUEST_MEMBER_API = getApiUrl("/members/reject-request");
const ALL_NOTIFICATION_API = getApiUrl("/notifications");
const UNFRIEND_USER_API = getApiUrl("/unfriend");
const POST_UPLOAD_API = getApiUrl("/post-something");
const ONSCREEN_NOTIFICATION_API = getApiUrl("/onScreenNotification");
const READALL_NOTIFICATION_API = getApiUrl("/readnotificaton");
const READ_SERVICE_NOTIFICATION = getApiUrl("/seller-notification-clear");
const GET_POST_API = getApiUrl("/getpost");
const GET_PROMOTIONAL_HUB = getApiUrl("/promotional_hub");
const LIKE_DISLIKE_POST_API = getApiUrl("/thumbsupdown");
const SINGLE_POST_LIKE_DISLIKE_API = getApiUrl("/singlethumbsupdown");
const POST_REACTION_API = getApiUrl("/postreaction");
const SINGLE_POST_REACTION_API = getApiUrl("/singlepostreaction");
const POST_LIKE_LIST_API = getApiUrl("/likelisting");
const SINGLE_POST_LIKE_LIST_API = getApiUrl("/singlelikelisting");
const POST_REACTION_LIST_API = getApiUrl("/reactionlisting");
const SINGLE_POST_REACTION_LIST_API = getApiUrl("/singlereactionlisting");
const COMMENT_LIST_API = getApiUrl("/postcommentlisting");
const POST_COMMENT_API = getApiUrl("/postcomment");
const POST_COMMENT_REPLY_API = getApiUrl("/postcommentreply");
const POST_COMMENT_REPLY_LIST_API = getApiUrl("/postcommentreplylisting");
const SINGLE_POST_COMMENT_API = getApiUrl("/filecomment");
const SINGLE_POST_COMMENT_LIST_API = getApiUrl("/filecommentlisting");
const SINGLE_POST_COMMENT_REPLY_API = getApiUrl("/filecommentreply");
const SINGLE_POST_COMMENT_REPLY_LIST_API = getApiUrl(
  "/filecommentreplylisting"
);
const POST_VIEW_API = getApiUrl("/postviewed");
const GET_SEARCHED_MEMBERS = getApiUrl("/search-member-list");
const SINGLE_POST_VIEW_API = getApiUrl("/fileviewed");
const POST_VIEW_LIST_API = getApiUrl("/postviewlisting");
const SINGLE_POST_VIEW_LIST_API = getApiUrl("/fileviewlisting");
const POST_SHARE_API = getApiUrl("/postshare");
const SINGLE_POST_SHARE_API = getApiUrl("/fileshare");
const POST_SHARE_LIST_API = getApiUrl("/postsharelist");
const SINGLE_POST_SHARE_LIST_API = getApiUrl("/filesharelist");
const FRIEND_LIST_API = getApiUrl("/friendlist");
const SEARCH_FRIEND_LIST_API = getApiUrl("/friendsearch");
const BUSINESS_FRIEND_LIST_API = getApiUrl("/business-friend-list");
const INDIVIDUAL_FRIEND_LIST_API = getApiUrl("/individual-friend-list");
const GET_USER_ALL_POST_API = getApiUrl("/getowposts"); //user_id=123 optional
const GET_USER_GALLERY_API = getApiUrl("/gallery");
const DELETE_GALLERY_ITEMS_API = getApiUrl("/deletegallery");
const MAIN_SLIDER_API = getApiUrl("/slider");
const GET_PARTICULAR_POST_API = getApiUrl("/getsinglepost");
const LOGOUT_API = getApiUrl("/logout");
const DELETE_POST_API = getApiUrl("/deletePost");
const SUGGESTED_USER_POST_API = getApiUrl("/suggested-user");

const CREATE_GROUP = getApiUrl("/group/create");
const GET_CREATE_GROUP = getApiUrl("/groups");
const GET_CREATE_MEDIA = getApiUrl("/group/upload-document");
const GET_GROUP_USERS = getApiUrl("/group-users");
const GROUP_DELETE_API = getApiUrl("/group/delete");
const GROUP_UPDATE_API = getApiUrl("/group/update");
const GROUP_LEAVE_API = getApiUrl("/group/leave");
const GROUP_ADD_USER = getApiUrl("/group/add-user");
const POST_UPDATE_API = getApiUrl("/update/postsomething");
const SELLER_SIGNUP_API = getApiUrl("/seller/signup");
const SELLER_GETINDUSTRIESLIST = getApiUrl("/seller/getIndustriesList");
const SELLER_SERVICE_LIST = getApiUrl("/getServicesList");
const SELLER_UPDATE_STATUS = getApiUrl("/seller/update-status");
const GET_SELLER_API = getApiUrl("/get-seller-status");
const GET_SELLER_DETAIL = getApiUrl("/getSeller");
const CREATE_MAIN_SERVICES = getApiUrl("/addUserMainServices");
const CREATE_SECONDARY_SERVICES = getApiUrl("/addUserSecondaryServices");
const UPDATE_SELLER_SERVICE = getApiUrl(`/user-services/update/`);

const GET_LANDING_PAGE_CONTENT = getApiUrl("/landing-page");
const GET_RECENT_BLOGS = getApiUrl("/get-recent-blogs");
const GET_SINGLE_BLOG = getApiUrl("/view-blog");
const GET_LANDINGPAGE_INDUSTRIES = getApiUrl("/get-industries");
const GET_FOOTER_CONTENT = getApiUrl("/footer-content");
const GET_ABOUTUS_PAGE_CONTENT = getApiUrl("/main-about");
const ADD_AVAIALABILITY_API = getApiUrl("/add-availability");
const GET_ALL_AVAILABILITY_API=getApiUrl("/get-availability")
const DELETE_AVAILABILITY_API=getApiUrl("/delete-availability")
const GET_ALL_BLOGS = (currentPage = 1) =>
  getApiUrl(`/get-blogs?page=${currentPage}`);

const SEARCH_BLOGS_DATA = (searched, startDate, endDate) =>
  getApiUrl(
    `/blog-search?search=${searched}&start_date=${startDate}&end_date=${endDate}`
  );

const GET_ALL_POPULAR_SERVICES = getApiUrl(`/get-popular-services`);
const GET_ALL_SERVICES_PROVIDER = getApiUrl("/get-services-provider");
const EVENT_NOTIFICATIONS = getApiUrl(`/event-notification`);
const SEARCH_PROVIDER_DATA = getApiUrl(`/providers-search`);
// const GET_SHARE_USER_PROFILE = (id)=> getApiUrl(`/get-unauth-user?user_id=${id}`)

const GET_EARN_WORK_CONTENT = getApiUrl(`/work-and-earn`);
const FIND_SERVICE_PROVIDER=getApiUrl(`/find-service-provider`)
const UPDATE_USER_AVAILABILITY = getApiUrl(`/update-availability`);
const GET_SHARE_USER_PROFILE = getApiUrl(`/get-unauth-user`);
const GET_ALL_SHARE_MEDIA = getApiUrl("/get-user-media");
const GET_SINGLE_GROUP_API = (id) => getApiUrl(`/find-group/${id}`);
export {
  API_BASE_URL,
  ADD_AVAIALABILITY_API,
  AUTH_CHECK,
  GET_ALL_AVAILABILITY_API,
  LOGIN_DATA_API,
  SIGNUP_DATA_API,
  FORGOT_PASSWORD_DATA_API,
  RESET_PASSWORD_DATA_API,
  INVITE_DATA_API,
  GET_PROFILE_API,
  IMAGE_BASE_URL,
  MEDIA_BASE_URL,
  UPDATE_ADDITIONAL_INFO,
  LANGUAGE_LIST_API,
  SKILLS_LIST_API,
  GET_PROJECTS_API,
  SAVE_MY_PROJECTS_API,
  DELETE_PROJECT_API,
  UPDATE_PROJECT_API,
  GET_EMPLOYMENTS_API,
  SAVE_MY_EMPLOYMENTS_API,
  DELETE_EMPLOYMENT_API,
  DELETE_USER_SERVICE,
  UPDATE_EMPLOYMENT_API,
  ADD_SOCIAL_ICON_API,
  GET_SOCIAL_ICON_API,
  SPECIALIZATION_LIST_API,
  SEARCH_LIST_API,
  SEARCH_PROFILE_DATA_API,
  SEND_REQUEST_API,
  CANCEL_REQUEST_API,
  ACCEPT_REQUEST_API,
  ACCEPT_REQUEST_MEMBERS_API,
  DECLINE_REQUEST_MEMBER_API,
  DECLINE_REQUEST_API,
  ALL_NOTIFICATION_API,
  UNFRIEND_USER_API,
  POST_UPLOAD_API,
  ONSCREEN_NOTIFICATION_API,
  READALL_NOTIFICATION_API,
  GET_POST_API,
  GET_SELLER_STATUS,
  GET_PROMOTIONAL_HUB,
  LIKE_DISLIKE_POST_API,
  SINGLE_POST_LIKE_DISLIKE_API,
  POST_REACTION_API,
  SINGLE_POST_REACTION_API,
  POST_LIKE_LIST_API,
  SINGLE_POST_LIKE_LIST_API,
  POST_REACTION_LIST_API,
  SINGLE_POST_REACTION_LIST_API,
  COMMENT_LIST_API,
  POST_COMMENT_API,
  POST_COMMENT_REPLY_API,
  POST_COMMENT_REPLY_LIST_API,
  SINGLE_POST_COMMENT_API,
  SINGLE_POST_COMMENT_LIST_API,
  SINGLE_POST_COMMENT_REPLY_API,
  SINGLE_POST_COMMENT_REPLY_LIST_API,
  POST_VIEW_API,
  SINGLE_POST_VIEW_API,
  POST_VIEW_LIST_API,
  SINGLE_POST_VIEW_LIST_API,
  POST_SHARE_API,
  SINGLE_POST_SHARE_API,
  POST_SHARE_LIST_API,
  SINGLE_POST_SHARE_LIST_API,
  FRIEND_LIST_API,
  SEARCH_FRIEND_LIST_API,
  GET_USER_ALL_POST_API,
  GET_USER_GALLERY_API,
  DELETE_GALLERY_ITEMS_API,
  MAIN_SLIDER_API,
  GET_PARTICULAR_POST_API,
  SOCKET,
  establishSocket,
  LOGOUT_API,
  DELETE_POST_API,
  SUGGESTED_USER_POST_API,
  EXPERTISE_LIST_API,
  CREATE_GROUP,
  GET_CREATE_GROUP,
  GET_CREATE_MEDIA,
  GET_GROUP_USERS,
  GROUP_DELETE_API,
  GROUP_UPDATE_API,
  GROUP_LEAVE_API,
  GROUP_ADD_USER,
  POST_UPDATE_API,
  SEARCH_INDIVIDUAL_API,
  ADD_MEMBERS,
  MEMBERS,
  ADMIN,
  MEMBERS_REMOVE,
  SELLER_SIGNUP_API,
  SELLER_GETINDUSTRIESLIST,
  SELLER_SERVICE_LIST,
  SELLER_UPDATE_STATUS,
  GET_SELLER_API,
  GET_SELLER_DETAIL,
  MINESTONE_SEARCH,
  BUSINESS_FRIEND_LIST_API,
  INDIVIDUAL_FRIEND_LIST_API,
  UPDATE_SELLER_SERVICE,
  GET_LANDING_PAGE_CONTENT,
  READ_SERVICE_NOTIFICATION,
  GET_ALL_BLOGS,
  GET_RECENT_BLOGS,
  GET_SINGLE_BLOG,
  GET_LANDINGPAGE_INDUSTRIES,
  GET_FOOTER_CONTENT,
  GET_ABOUTUS_PAGE_CONTENT,
  CREATE_MAIN_SERVICES,
  SEARCH_BLOGS_DATA,
  GET_MANAGE_ACCOUNT_API,
  GET_SINGLE_GROUP_API,
  CREATE_SECONDARY_SERVICES,
  GOOGLE_MEET_INTEGRATION,
  GET_SEARCHED_MEMBERS,
  GET_SHARE_USER_PROFILE,
  GET_ALL_POPULAR_SERVICES,
  GET_ALL_SHARE_MEDIA,
  EVENT_NOTIFICATIONS,
  GET_ALL_SERVICES_PROVIDER,
  SEARCH_PROVIDER_DATA,
  GET_EARN_WORK_CONTENT,
  DELETE_PROJECT_IMAGE,
  DELETE_AVAILABILITY_API,
  UPDATE_USER_AVAILABILITY,
  FIND_SERVICE_PROVIDER
};
