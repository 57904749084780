import {
  CHANGE_CHAT_DETAILS,
  CHANGE_FIRST_TIME_FRIEND,
  CHANGE_FRIEND_LIST_PAGE,
  CLEAR_CHAT_DETAILS,
  CHANGE_SHARE_ATTACHMENTS,
  GET_ALL_SHARE_MEDIA_REQUEST,
  GET_ALL_SHARE_MEDIA_SUCCESS,
  GET_ALL_SHARE_MEDIA_FAILURE,
  CLEAR_ALL_MEDIA,
  SEND_EVENTS_REQUEST,
  SEND_EVENTS_REQUEST_SUCCESS,
  SEND_EVENTS_REQUEST_FAILURE,
  ADD_AVAILABILITY_REQEST,
  ADD_AVAILABILITY_SUCCESS,
  ADD_AVAILABILITY_FAILURE,
  GET_ALL_AVAILABILITY_REQEST,
  GET_ALL_AVAILABILITY_SUCCESS,
  GET_ALL_AVAILABILITY_FAILURE,
  DELETE_AVAILABILITY_REQUEST,
  DELETE_AVAILABILITY_SUCCESS,
  DELETE_AVAILABILITY_FAILURE,
  CANCEL_EVENT_SUCCESS,
  CANCEL_EVENT_REQUEST,
  CANCEL_EVENT_FAILURE,
  ACCEPT_EVENT_REQUEST,
  ACCEPT_EVENT_SUCCESS,
  ACCEPT_EVENT_FAILURE,
  DECLINE_EVENT_REQUEST,
  DECLINE_EVENT_SUCCESS,
  DECLINE_EVENT_FAILURE,
  UPDATE_USER_AVAILABILITY_REQUEST,
  UPDATE_USER_AVAILABILITY_SUCCESS,
  UPDATE_USER_AVAILABILITY_FAILURE,
} from "./PoolsChatConstant";

const initialState = {
  chatDetails: {
    userMessage: "",
    completeMessageList: [],
    currenUserId: "",
    is_loading: false,
    is_scroll: true,
    page: 1,
    page_scroll: true,
    pagination_loading: false,
    typingUser: "",
  },

  ShareAttachment: {
    shareText: "",
    mediaFile: [],
    mediaUrl: [],
    fileVisibility: 0,
  },
  chatFriendListPage: 1,

  getAllShareMedia: {
    getShareMediaDataLoading: false,
    getShareMediaDataStatus: "",
    getShareMediaDataSuccess: "",
    getShareMediaMessage: "",
    getShareMediaData: [],
    totalCounts: 0,
  },

  sendEventRequestData: {
    getEventRquestDataLoading: false,
    getEventMediaDataStatus: "",
    getEventMediaDataSuccess: "",
    getEventMediaDataMessage: "",
  },

  cancelEventRequestData: {
    cancelEventRequestLoading: false,
    cancelEventRequestStatus: "",
    cancelEventRequestSuccess: "",
    cancleEventRequestMessage: "",
  },

  acceptEventRequestData: {
    acceptEventRequestLoading: false,
    acceptEventRequestStatus: "",
    acceptEventRequestSuccess: "",
    acceptEventRequestMessage: "",
  },

  addAvailabilityData: {
    availabilityDataLoading: false,
    availabilityDataStatus: "",
    availabilityDataSuccess: "",
    availabilityDataMessage: "",
    availabilityDataFetch: [],
  },

  getAllAvailabilitySlots: {
    allAvailabilitySlotsLoading: false,
    allAvailabilitySlotsStatus: "",
    allAvailabilitySlotsSuccess: "",
    allAvailabilitySlotsMessage: "",
    allAvailabilitySlotsData: [],
  },

  declineEventRequestData: {
    declineEventRequestLoading: false,
    declineEventRequestStatus: "",
    declineEventRequestSuccess: "",
    declineEventRequestMessage: "",
  },

  deleteAvailabilitySlots: {
    deleteAvailabilitySlotsLoading: false,
    deleteAvailabilitySlotsStatus: "",
    deleteAvailabilitySlotsSuccess: "",
    deleteAvailabilitySlotsMessage: "",
    deleteAvailabilityData: [],
  },
};
export const PoolsChatReducer = (state = initialState, action) => {

  switch (action.type) {
    case CHANGE_CHAT_DETAILS:

      return {
        ...state,
        ...{ chatDetails: { ...state.chatDetails, ...action.newState } },
      };
    case CHANGE_FRIEND_LIST_PAGE:
      return {
        ...state,
        ...action.newState,
      };
    case CHANGE_SHARE_ATTACHMENTS:
      return {
        ...state,
        ...{
          ShareAttachment: { ...state.ShareAttachment, ...action.newState },
        },
      };
    case CLEAR_CHAT_DETAILS:
      let clearMessageDetail = {
        userMessage: "",
        completeMessageList: [],
        is_loading: false,
        is_scroll: true,
        page: 1,
        page_scroll: true,
        pagination_loading: false,
        typingUser: "",
      };
      return {
        ...state,
        ...{
          chatDetails: { ...state.chatDetails, ...clearMessageDetail },
        },
      };

    case CLEAR_ALL_MEDIA:
      let clearMediaData = {
        getShareMediaDataLoading: false,
        getShareMediaDataStatus: "",
        getShareMediaDataSuccess: "",
        getShareMediaMessage: "",
        getShareMediaData: [],
        totalCounts: 0,
      };

      return {
        ...state,
        ...{
          getAllShareMedia: {
            ...state.getAllShareMedia,
            ...clearMediaData,
          },
        },
      };

    case GET_ALL_SHARE_MEDIA_REQUEST:
      return {
        ...state,
        ...{
          getAllShareMedia: {
            ...state.getAllShareMedia,
            ...{ getShareMediaDataLoading: true },
          },
        },
      };

    case GET_ALL_SHARE_MEDIA_SUCCESS:
      const {
        response: {
          data: { list },
        },
      } = action;

      let getShareMediaDataSuccess = {
        getShareMediaDataLoading: false,
        getShareMediaDataStatus: action.response?.status,
        getShareMediaDataSuccess: true,
        getShareMediaMessage: action.response.data?.message,
        getShareMediaData: !!list
          ? [...state.getAllShareMedia.getShareMediaData, ...list]
          : [],
      };

      return {
        ...state,
        ...{
          getAllShareMedia: {
            ...state.getAllShareMedia,
            ...getShareMediaDataSuccess,
          },
        },
      };

    case GET_ALL_SHARE_MEDIA_FAILURE:
      let getShareMediaDataFailure = {
        getShareMediaDataLoading: false,
        getShareMediaDataStatus: action.response?.status,
        getShareMediaDataSuccess: false,
        getShareMediaMessage: action.response?.message,
        getShareMediaData: [],
        totalCounts: 0,
      };
      return {
        ...state,
        ...{
          getAllShareMedia: {
            ...state.getShareMediaData,
            ...getShareMediaDataFailure,
          },
        },
      };

    case SEND_EVENTS_REQUEST:
      return {
        ...state,
        ...{
          sendEventRequestData: {
            ...state.sendEventRequestData,
            ...{ getEventRquestDataLoading: true },
          },
        },
      };

    case SEND_EVENTS_REQUEST_SUCCESS:
      let sendEventRequestSuccess = {
        getEventRquestDataLoading: false,
        getEventMediaDataStatus: action.response.status,
        getEventMediaDataSuccess: true,
        getEventMediaDataMessage: action.response.message,
      };

      return {
        ...state,
        ...{
          sendEventRequestData: {
            ...state.sendEventRequestData,
            ...sendEventRequestSuccess,
          },
        },
      };

    case SEND_EVENTS_REQUEST_FAILURE:
      let sendEventRequestFailure = {
        getEventRquestDataLoading: false,
        getEventMediaDataStatus: action.response.status,
        getEventMediaDataSuccess: false,
        getEventMediaDataMessage: action.response.message,
      };

      return {
        ...state,
        ...{
          sendEventRequestData: {
            ...state.sendEventRequestData,
            sendEventRequestFailure,
          },
        },
      };

    case CANCEL_EVENT_REQUEST:
      return {
        ...state,
        ...{
          cancelEventRequestData: {
            ...state.cancelEventRequestData,
            ...{ cancelEventRequestLoading: true },
          },
        },
      };

    case CANCEL_EVENT_SUCCESS:
      let cancelEventRequestDataSuccess = {
        cancelEventRequestLoading: false,
        cancelEventRequestStatus: action.response.status,
        cancelEventRequestDataSuccess: true,
        cancleEventRequestMessage: action.response.message,
      };
      return {
        ...state,
        ...{
          cancelEventRequestData: {
            ...state.cancelEventRequestData,
            cancelEventRequestDataSuccess,
          },
        },
      };

    case CANCEL_EVENT_FAILURE:
      let cancelEventRequestDataFailure = {
        cancelEventRequestLoading: false,
        cancelEventRequestStatus: action.response.status,
        cancelEventRequestSuccess: false,
        cancleEventRequestMessage: action.response.message,
      };
      return {
        ...state,
        ...{
          cancelEventRequestData: {
            ...state.cancelEventRequestData,
            cancelEventRequestDataFailure,
          },
        },
      };

    case ACCEPT_EVENT_REQUEST:
      return {
        ...state,
        ...{
          acceptEventRequestData: {
            ...state.acceptEventRequestData,
            ...{ acceptEventRequestLoading: true },
          },
        },
      };

    case ACCEPT_EVENT_SUCCESS:
      let acceptEventRequestDataSuccess = {
        acceptEventRequestLoading: false,
        acceptEventRequestStatus: action.response.status,
        acceptEventRequestSuccess: true,
        acceptEventRequestMessage: action.response.message,
      };
      return {
        ...state,
        ...{
          acceptEventRequestData: {
            ...state.acceptEventRequestData,
            acceptEventRequestDataSuccess,
          },
        },
      };

    case ACCEPT_EVENT_FAILURE:
      let acceptEventRequestDataFailure = {
        acceptEventRequestLoading: false,
        acceptEventRequestStatus: action.response.status,
        acceptEventRequestSuccess: false,
        acceptEventRequestMessage: action.response.message,
      };
      return {
        ...state,
        ...{
          acceptEventRequestData: {
            ...state.acceptEventRequestData,
            acceptEventRequestDataFailure,
          },
        },
      };
    case ADD_AVAILABILITY_REQEST:
      return {
        ...state,
        ...{
          addAvailabilityData: {
            ...state.addAvailabilityData,
            ...{ availabilityDataLoading: true },
          },
        },
      };

    case ADD_AVAILABILITY_SUCCESS:
      let addAvailabilityDataSuccess = {
        availabilityDataLoading: false,
        availabilityDataStatus: action.response?.status,
        availabilityDataSuccess: true,
        availabilityDataMessage: action.response.data?.message,
        availabilityDataFetch:
          action?.response?.data !== undefined
            ? action?.response?.data?.data
            : [],
      };
      return {
        ...state,
        ...{
          addAvailabilityData: {
            ...state.addAvailabilityData,
            ...addAvailabilityDataSuccess,
          },
        },
      };

    case ADD_AVAILABILITY_FAILURE:
      let addAvailabilityDataFailure = {
        availabilityDataLoading: false,
        availabilityDataStatus: action.response?.status,
        availabilityDataSuccess: false,
        availabilityDataMessage: action.response.data?.message,
        availabilityDataFetch: [],
      };
      return {
        ...state,
        ...{
          addAvailabilityData: {
            ...state.addAvailabilityData,
            ...addAvailabilityDataFailure,
          },
        },
      };

    case GET_ALL_AVAILABILITY_REQEST:
      return {
        ...state,
        ...{
          getAllAvailabilitySlots: {
            ...state.getAllAvailabilitySlots,
            ...{ allAvailabilitySlotsLoading: true },
          },
        },
      };

    case GET_ALL_AVAILABILITY_SUCCESS:
      let allAvailabilitySlotsSuccess = {
        allAvailabilitySlotsLoading: false,
        allAvailabilitySlotsStatus: action?.response.status,

        allAvailabilitySlotsSuccess: true,
        allAvailabilitySlotsMessage: action?.response.message,
        allAvailabilitySlotsData:
          action?.response?.data !== undefined
            ? action?.response?.data?.data
            : [],
      };

      return {
        ...state,
        ...{
          getAllAvailabilitySlots: {
            ...state.getAllAvailabilitySlots,
            ...allAvailabilitySlotsSuccess,
          },
        },
      };

    case GET_ALL_AVAILABILITY_FAILURE:
      let allAvailabilitySlotsFailure = {
        allAvailabilitySlotsLoading: false,
        allAvailabilitySlotsStatus: action?.response.status,

        allAvailabilitySlotsSuccess: false,
        allAvailabilitySlotsMessage: action?.response.message,
        allAvailabilitySlotsData: [],
      };

      return {
        ...state,
        ...{
          getAllAvailabilitySlots: {
            ...state.getAllAvailabilitySlots,
            ...allAvailabilitySlotsFailure,
          },
        },
      };

    case UPDATE_USER_AVAILABILITY_REQUEST:
      return {
        ...state,
        ...{
          getAllAvailabilitySlots: {
            ...state.getAllAvailabilitySlots,
            ...{ allAvailabilitySlotsLoading: true },
          },
        },
      };

    case UPDATE_USER_AVAILABILITY_SUCCESS:
      let updatedAvailabilitySlotsSuccess = {
        allAvailabilitySlotsLoading: false,
        allAvailabilitySlotsStatus: action?.response.status,

        allAvailabilitySlotsSuccess: true,
        allAvailabilitySlotsMessage: action?.response.message,
        allAvailabilitySlotsData:
          action?.response?.data !== undefined
            ? action?.response?.data?.data
            : [],
      };

      return {
        ...state,
        ...{
          getAllAvailabilitySlots: {
            ...state.getAllAvailabilitySlots,
            ...updatedAvailabilitySlotsSuccess,
          },
        },
      };

    case UPDATE_USER_AVAILABILITY_FAILURE:
      let updatedAvailabilitySlotsFailure = {
        allAvailabilitySlotsLoading: false,
        allAvailabilitySlotsStatus: action?.response.status,

        allAvailabilitySlotsSuccess: false,
        allAvailabilitySlotsMessage: action?.response.message,
        allAvailabilitySlotsData: [],
      };

      return {
        ...state,
        ...{
          getAllAvailabilitySlots: {
            ...state.getAllAvailabilitySlots,
            ...updatedAvailabilitySlotsFailure,
          },
        },
      };

    case DELETE_AVAILABILITY_REQUEST:
      return {
        ...state,
        ...{
          deleteAvailabilitySlots: {
            ...state.deleteAvailabilitySlots,
            ...{ deleteAvailabilitySlotsLoading: true },
          },
        },
      };

    case DELETE_AVAILABILITY_SUCCESS:
      let deleteAvailabilitySuccess = {
        deleteAvailabilitySlotsLoading: false,
        deleteAvailabilitySlotsStatus: action?.response.status,
        deleteAvailabilitySlotsSuccess: action?.response.success,
        deleteAvailabilitySlotsMessage: action?.response.message,
        deleteAvailabilityData: [],
      };

      return {
        ...state,
        ...{
          deleteAvailabilitySlots: {
            ...state.deleteAvailabilitySlots,
            ...deleteAvailabilitySuccess,
          },
        },
      };

    case DELETE_AVAILABILITY_FAILURE:
      let deleteAvailabilityFailure = {
        deleteAvailabilitySlotsLoading: false,
        deleteAvailabilitySlotsStatus: action.response.status,
        deleteAvailabilitySuccess: false,
        deleteAvailabilitySlotsMessage: action.response.message,
      };
      return {
        ...state,
        ...{
          deleteAvailabilitySlots: {
            ...state.deleteAvailabilitySlots,
            ...deleteAvailabilityFailure,
          },
        },
      };

    case DECLINE_EVENT_REQUEST:
      return {
        ...state,
        ...{
          declineEventRequestData: {
            ...state.declineEventRequestData,
            ...{ declineEventRequestLoading: true },
          },
        },
      };

    case DECLINE_EVENT_SUCCESS:
      let declineEventRequestSuccess = {
        declineEventRequestLoading: false,
        declineEventRequestStatus: action.response.status,
        declineEventRequestSuccess: true,
        declineEventRequestMessage: action.response.message,
      };

      return {
        ...state,
        ...{
          declineEventRequestData: {
            ...state.declineEventRequestData,
            declineEventRequestSuccess,
          },
        },
      };

    case DECLINE_EVENT_FAILURE:
      let declineEventRequestFailure = {
        declineEventRequestLoading: false,
        declineEventRequestStatus: action.response.status,
        declineEventRequestSuccess: false,
        declineEventRequestMessage: action.response.message,
      };

      return {
        ...state,
        ...{
          declineEventRequestData: {
            ...state.declineEventRequestData,
            declineEventRequestFailure,
          },
        },
      };

    default:
      return state;
  }
};
