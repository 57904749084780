import {
  CLEAR_SEARCH_LIST_RESPONSE,
  CLEAR_SEND_REQUEST_RESPONSE,
} from "./SearchListConstant";
//this function is used to clear search list api response
const clearSearchListResponse = (newState) => {
  return { type: CLEAR_SEARCH_LIST_RESPONSE, newState };
};
//this function is used to clear send friend request api response
const clearSendRequestResponse = (newState) => {
  return { type: CLEAR_SEND_REQUEST_RESPONSE, newState };
};
//this function is used to get loading state
const isServiceLoading = (data) => {
  return { type: "GET_BOOL_VAL", payload: data };
};
//this function is used to get search_list_data
const serviceList = (data) => {
  return { type: "GET_SERVICE_LIST", payload: data };
};

export {
  clearSearchListResponse,
  clearSendRequestResponse,
  isServiceLoading,
  serviceList,
};
