import React from "react";
import { useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { returnDefaultImage } from "../../../library/utilities/functions";
// import { IMAGE_BASE_URL } from "../../../urls";
// import { changeGalleryDetail } from "./ProfileGalleryAction";

const LargeImageSlide = ({ projects, sliderDetails, setSliderDetails }) => {
  const { activeIndexFile, sliderDataFile } = sliderDetails;
  const dispatch = useDispatch();

  //this function is used to close the large image model.
  const handleCloseCarousal = () => {
    setSliderDetails({
      activeIndexFile: "",
      sliderDataFile: [],
    });
  };

  return (
    <>
      <div
        className="modal fade"
        id="popover-modal"
        tabIndex={-1}
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content h-75">
            <div className="modal-body h-75">
              <button
                onClick={handleCloseCarousal}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>

              <Carousel
                useKeyboardArrows={true}
                emulateTouch={true}
                selectedItem={activeIndexFile}
                showIndicators={false}
                swipeable={false}
                showStatus={false}
                showThumbs={false}
                autoPlay={false}
              >
                {!!sliderDataFile.length > 0 &&
                  sliderDataFile.map((data, i) => (
                    <div className="carousel-inner">
                      {
                        <img
                          className="d-block"
                          style={{maxHeight:"400px",objectFit:"contain"}}
                          src={
                            !!data?.name?.file_name
                              && data?.name?.file_name
                                                        }
                          alt="First slide"
                        />
                      }
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LargeImageSlide;
