import { CHANGE_PARTICULAR_COMMENT_LIST_DETAILS, CHANGE_PARTICULAR_POST_DETAIL, CHANGE_PARTICULAR_POST_VIEW_DETAIL, CHANGE_PARTICULAR_REACT_LIST_DETAILS, CHANGE_PARTICULAR_SHARE_LIST_DETAIL, CHANGE_POST_VIEW_DETAIL, CLEAR_FILE_CMT_REPLY_LIST_RESPONSE, CLEAR_FILE_CMT_REPLY_RESPONSE, CLEAR_FILE_COMMENT_LIST_RESPONSE, CLEAR_FILE_COMMENT_RESPONSE, CLEAR_FILE_REACT_RESPONSE, CLEAR_FILE_SHARE_LIST_RESPONSE, CLEAR_FILE_SHARE_RESPONSE, CLEAR_FILE_VIEW_LIST_RESPONSE, CLEAR_PARTICULAR_CMT_REPLY_RESPONSE, CLEAR_PARTICULAR_POST_CMT_REPLY_LIST_RESPONSE, CLEAR_PARTICULAR_POST_COMMENT_LIST_RESPONSE, CLEAR_PARTICULAR_POST_COMMENT_RESPONSE, CLEAR_PARTICULAR_POST_RESPONSE, CLEAR_PARTICULAR_POST_SHARE_LIST_RESPONSE, CLEAR_PARTICULAR_POST_SHARE_RESPONSE, CLEAR_POST_REACTION_RESPONSE, CLEAR_POST_REACT_LIST_RESPONSE, CLEAR_POST_VIEW_LIST_RESPONSE, CLEAR_SINGLE_FILE_REACT_LIST_RESPONSE } from "./PostConstant"

//this function is used to change single post list. 
const changeParticularPostDetail = (newState) => {
    return { type: CHANGE_PARTICULAR_POST_DETAIL, newState }
}
//this function is used to clear single post api response
const clearParticularPostResponse = (newState) => {
    return { type: CLEAR_PARTICULAR_POST_RESPONSE, newState }
}
//this function is used to change single post react list details like file id page , post page etc.
const changeParticularReactListDetail = (newState) => {
    return { type: CHANGE_PARTICULAR_REACT_LIST_DETAILS, newState }
}
//this function is used to clear post react api response
const clearParticularPostReactResponse = (newState) => {
    return { type: CLEAR_POST_REACTION_RESPONSE, newState }
}
//this function is used to clear react api response on file
const clearparticularFileResponse = (newState) => {
    return { type: CLEAR_FILE_REACT_RESPONSE, newState }
}
//this function is used to clear file react list api response 
const clearPostReactListResponse = (newState) => {
    return { type: CLEAR_POST_REACT_LIST_RESPONSE, newState }
}
//this function is used to clear file react list api response 
const clearFileReactListResponse = (newState) => {
    return { type: CLEAR_SINGLE_FILE_REACT_LIST_RESPONSE, newState }
}
const clearParticularCommentListResponse = (newState) => {
    return { type: CLEAR_PARTICULAR_POST_COMMENT_LIST_RESPONSE, newState }
}
//this function is used when clear comments api response on post 
const clearParticularPostCommentResponse = (newState) => {
    return { type: CLEAR_PARTICULAR_POST_COMMENT_RESPONSE, newState }
}
const changeParticularCommentListDetail = (newState) => {
    return { type: CHANGE_PARTICULAR_COMMENT_LIST_DETAILS, newState }
}
const clearParticularReplyListResponse = (newState) => {
    return { type: CLEAR_PARTICULAR_POST_CMT_REPLY_LIST_RESPONSE, newState }
}
const clearParticularReplyCmtResponse = (newState) => {
    return { type: CLEAR_PARTICULAR_CMT_REPLY_RESPONSE, newState }
}
const clearParticularFileCmtResponse = (newState) => {
    return { type: CLEAR_FILE_COMMENT_RESPONSE, newState }
}
const clearParticularFileCmtListResponse = (newState) => {
    return { type: CLEAR_FILE_COMMENT_LIST_RESPONSE, newState }
}
const clearFileCmtReplyResponse = (newState) => {
    return { type: CLEAR_FILE_CMT_REPLY_RESPONSE, newState }
}
const clearFileCmtReplyListResponse = (newState) => {
    return { type: CLEAR_FILE_CMT_REPLY_LIST_RESPONSE, newState }
}
const clearPostViewListResponse = (newState) => {
    return { type: CLEAR_POST_VIEW_LIST_RESPONSE, newState }
}
const changeParticularViewDetail = (newState) => {
    return { type: CHANGE_PARTICULAR_POST_VIEW_DETAIL, newState }
}
const clearFileViewListResponse = (newState) => {
    return { type: CLEAR_FILE_VIEW_LIST_RESPONSE, newState }
}
const clearParticularSharePostResponse = (newState) => {
    return { type: CLEAR_PARTICULAR_POST_SHARE_RESPONSE, newState }
}
const clearParticularShareFileResponse = (newState) => {
    return { type: CLEAR_FILE_SHARE_RESPONSE, newState }
}
const changeParticularShareListDetil = (newState) => {
    return { type: CHANGE_PARTICULAR_SHARE_LIST_DETAIL, newState }
}
const clearParticularPostShareListResponse = (newState) => {
    return { type: CLEAR_PARTICULAR_POST_SHARE_LIST_RESPONSE, newState }
}

const clearShareFileListResponse = (newState) => {
    return { type: CLEAR_FILE_SHARE_LIST_RESPONSE, newState }
}
export {
    changeParticularPostDetail, clearParticularPostResponse, changeParticularReactListDetail, clearParticularPostReactResponse, clearparticularFileResponse,
    clearPostReactListResponse, clearFileReactListResponse, clearParticularCommentListResponse, clearParticularPostCommentResponse, changeParticularCommentListDetail,
    clearParticularReplyListResponse, clearParticularReplyCmtResponse, clearParticularFileCmtResponse, clearParticularFileCmtListResponse, clearFileCmtReplyResponse,
    clearFileCmtReplyListResponse, clearPostViewListResponse, changeParticularViewDetail, clearFileViewListResponse, clearParticularSharePostResponse, clearParticularShareFileResponse,
    changeParticularShareListDetil, clearParticularPostShareListResponse, clearShareFileListResponse,
}