import {
  ACCEPT_MEMBERS_DATA_REQUEST,
  ACCEPT_MEMBERS_DATA_SUCCESS,
  ACCEPT_MEMBERS_DATA_FAILURE,
  CLEAR_MEMBERS_ACCEPT_REQUEST_RESPONSE,
  DECLINE_MEMBERS_DATA_REQUEST,
  DECLINE_MEMBERS_DATA_SUCCESS,
  DECLINE_MEMBERS_DATA_FAILURE,
  CLEAR_MEMBERS_DECLINE_REQUEST_RESPONSE,
} from "./MembersNotificationConstant";

const initialState = {
  acceptMembersRequestApi: {
    acceptMembersRequestLoading: false,
    acceptMembersRequestStatus: "",
    acceptMembersRequestSuccess: "",
  },
  declineMembersRequestApi: {
    declineMembersRequestLoading: false,
    declineMembersRequestStatus: "",
    declineMembersRequestSuccess: "",
  },
};
export const MembersNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCEPT_MEMBERS_DATA_REQUEST:
      return {
        ...state,
        ...{
          acceptMembersRequestApi: {
            ...state.acceptMembersRequestApi,
            ...{ acceptMembersRequestLoading: true },
          },
        },
      };

    case ACCEPT_MEMBERS_DATA_SUCCESS:
      const {
        response: {
          data: { success, status },
        },
      } = action;

      let acceptMembersSuccess = {
        acceptMembersRequestLoading: false,
        acceptMembersRequestStatus: status,
        acceptMembersRequestSuccess: success,
      };
      return {
        ...state,
        ...{
          acceptMembersRequestApi: {
            ...state.acceptMembersRequestApi,
            ...acceptMembersSuccess,
          },
        },
      };

    case ACCEPT_MEMBERS_DATA_FAILURE:
      let acceptMembersFailure = {
        acceptMembersRequestLoading: false,
        acceptMembersRequestStatus: action.response.status,
        acceptMembersRequestSuccess: "",
      };
      return {
        ...state,
        ...{
          acceptMembersRequestApi: {
            ...state.acceptMembersRequestApi,
            ...acceptMembersFailure,
          },
        },
      };

    case CLEAR_MEMBERS_ACCEPT_REQUEST_RESPONSE:
      let clearMembersAcceptResponse = {
        acceptMembersRequestStatus: "",
        acceptMembersRequestSuccess: "",
      };
      return {
        ...state,
        ...{
          acceptMembersRequestApi: {
            ...state.acceptMembersRequestApi,
            ...clearMembersAcceptResponse,
          },
        },
      };

    case DECLINE_MEMBERS_DATA_REQUEST:
      return {
        ...state,
        ...{
          declineMembersRequestApi: {
            ...state.declineMembersRequestApi,
            ...{ declineMembersRequestLoading: true },
          },
        },
      };

    case DECLINE_MEMBERS_DATA_SUCCESS:
      const {
        response: { data },
      } = action;

      let declineMembersSuccess = {
        declineMembersRequestLoading: false,
        declineMembersRequestStatus: data.status,
        declineMembersRequestSuccess: data.success,
      };
      return {
        ...state,
        ...{
          declineMembersRequestApi: {
            ...state.declineMembersRequestApi,
            ...declineMembersSuccess,
          },
        },
      };

    case DECLINE_MEMBERS_DATA_FAILURE:
      let declineMembersFailure = {
        declineMembersRequestLoading: false,
        declineMembersRequestStatus: action.response.status,
        declineMembersRequestSuccess: "",
      };

      return {
        ...state,
        ...{
          declineMembersRequestApi: {
            ...state.declineMembersRequestApi,
            ...declineMembersFailure,
          },
        },
      };

    case CLEAR_MEMBERS_DECLINE_REQUEST_RESPONSE:
      let clearDeclineResponse = {
        declineMembersRequestStatus: "",
        declineMembersRequestSuccess: "",
      };

      return {
        ...state,
        ...{
          declineMembersRequestApi: {
            ...state.declineMembersRequestApi,
            ...clearDeclineResponse,
          },
        },
      };

    default:
      return state;
  }
};
