import React, { useEffect, useState } from 'react'
import { getAllAvailabilityApi } from './PoolsChatAction';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import timeGridPlugin from "@fullcalendar/timegrid";
function ViewAvailability({currenUserId,message_details}) {
    const dispatch=useDispatch()
    const allAvailabilitySlots = useSelector((state) => state.PoolsChatReducer);
const [totalSlots,setTotalSlots]=useState([])
    const {
        getAllAvailabilitySlots: { allAvailabilitySlotsData },
      } = allAvailabilitySlots;




useEffect(() => {
    if (allAvailabilitySlotsData?.length > 0) {
      const slotsArr = allAvailabilitySlotsData.map((item) => {
        return {
          title: item?.title,
          start: item?.start_date,
          end: item?.end_date,
          created_from: item?.created_from,
        };
      });
      if (slotsArr?.length > 0) {
        setTotalSlots(slotsArr);
      }
    }
    else{
        setTotalSlots([])
    }
  }, [allAvailabilitySlotsData]);

  return (
   
        <div
        
        >
          <div
            className="modal fade "
            id="view-availablity-modal"
            tabIndex={-1}
            role="dialog"
            data-backdrop="static"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close position-static p-0 m-0 ml-auto"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div
                    style={{
                      minHeight: "500px",
                      width: "100%",
                  
                    }}
                  >
                    {
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        eventDisplay="block"
                     
                     contentHeight="auto"
                      
                        editable={false}
                        initialView="timeGridWeek"
                        events={totalSlots}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "timeGridWeek,list",
                        }}
                        eventContent={(eventInfo) => {
                          // Customize the event rendering
                          return (
                            <div
                              style={{
                                backgroundColor: "#3498ff",
                                color: "white",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              <b>{eventInfo.timeText}</b>
                              <p>{eventInfo.event.title}</p>
                            </div>
                          );
                        }}
                      />
                    }
                  </div>
                </div>

                <div className="modal-footer">
              
                </div>
              </div>
            </div>
          </div>
      
    </div>
  )
}

export default ViewAvailability
