const CHANGE_GALLERY_SHARE_INPUT = "CHANGE_GALLERY_SHARE_INPUT";

const SHARE_GALLERY_DATA_REQUEST = "SHARE_GALLERY_DATA_REQUEST";
const SHARE_GALLERY_DATA_SUCCESS = "SHARE_GALLERY_DATA_SUCCESS";
const SHARE_GALLERY_DATA_FAILURE = "SHARE_GALLERY_DATA_FAILURE";

const CLEAR_SHARE_GALLERY_RESPONSE = "CLEAR_SHARE_GALLERY_RESPONSE";

const CHANGE_GALLERY_FILE_DETAIL = "CHANGE_GALLERY_FILE_DETAIL"

const GET_GALLERY_PHOTO_DATA_REQUEST = "GET_GALLERY_PHOTO_DATA_REQUEST";
const GET_GALLERY_PHOTO_DATA_SUCCESS = "GET_GALLERY_PHOTO_DATA_SUCCESS";
const GET_GALLERY_PHOTO_DATA_FAILURE = "GET_GALLERY_PHOTO_DATA_FAILURE";

const CLEAR_GET_GALLERY_PHOTO_RESPONSE = "CLEAR_GET_GALLERY_PHOTO_RESPONSE";

const GET_GALLERY_VIDEO_DATA_REQUEST = "GET_GALLERY_VIDEO_DATA_REQUEST";
const GET_GALLERY_VIDEO_DATA_SUCCESS = "GET_GALLERY_VIDEO_DATA_SUCCESS";
const GET_GALLERY_VIDEO_DATA_FAILURE = "GET_GALLERY_VIDEO_DATA_FAILURE";

const CLEAR_GET_GALLERY_VIDEO_RESPONSE = "CLEAR_GET_GALLERY_VIDEO_RESPONSE";

const DELETE_GALLERY_DATA_REQUEST = "DELETE_GALLERY_DATA_REQUEST";
const DELETE_GALLERY_DATA_SUCCESS = "DELETE_GALLERY_DATA_SUCCESS";
const DELETE_GALLERY_DATA_FAILURE = "DELETE_GALLERY_DATA_FAILURE";
const CLEAR_DELETE_GALLERY_ITEM_RESPONSE = "DELETE_GALLERY_ITEM_RESPONSE"

export {
    CHANGE_GALLERY_SHARE_INPUT, SHARE_GALLERY_DATA_REQUEST, SHARE_GALLERY_DATA_SUCCESS, SHARE_GALLERY_DATA_FAILURE, CLEAR_SHARE_GALLERY_RESPONSE,
    GET_GALLERY_PHOTO_DATA_REQUEST, GET_GALLERY_PHOTO_DATA_SUCCESS, GET_GALLERY_PHOTO_DATA_FAILURE, CLEAR_GET_GALLERY_PHOTO_RESPONSE,
    GET_GALLERY_VIDEO_DATA_REQUEST, GET_GALLERY_VIDEO_DATA_SUCCESS, GET_GALLERY_VIDEO_DATA_FAILURE, CLEAR_GET_GALLERY_VIDEO_RESPONSE, CHANGE_GALLERY_FILE_DETAIL,
    DELETE_GALLERY_DATA_REQUEST, DELETE_GALLERY_DATA_SUCCESS, DELETE_GALLERY_DATA_FAILURE, CLEAR_DELETE_GALLERY_ITEM_RESPONSE,
}