import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCookie, setCookie } from "../../../library/utilities/functions";
import SlidingButton from "./SlidingButton";
import { getProfileApi } from "../../../library/api/AccountApiService";
import { useToasts } from "react-toast-notifications";
import DatePicker from "react-date-picker";
import moment from "moment";
import {
  API_BASE_URL,
  IMAGE_BASE_URL,
  SELLER_UPDATE_STATUS,
} from "../../../library/urls";
import axios from "axios";
import { ListData } from "./SellerStatusListData";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import "./ProfileInfo.css";
import { HiOutlineThumbUp } from "react-icons/hi";

const override = css`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  right: 47%;
  z-index: 9999;
`;

const tabsData = [
  {
    id: 1,
    tabId: "pills-cover-tab",
    title: "Cover Photo",
    href: "pills-cover",
    ariaSelected: "true",
    icon: "ri-window-line ",
  },
  {
    id: 2,
    tabId: "pills-business-tab",
    title: "Business Logo",
    href: "pills-business",
    ariaSelected: "false",
    icon: "ri-building-fill",
  },
  {
    id: 3,
    tabId: "pills-picture-tab",
    title: "Profile Picture",
    href: "pills-picture",
    ariaSelected: "false",
    icon: "ri-user-line",
  },
];
const Basicinfo = ({ AccountState, check_individual }) => {
  const imageRef = useRef(null);
  const history = useHistory();
  const openProfileEl = useRef(null);
  const openBusinessEl = useRef(null);
  const openCoverEl = useRef(null);
  const [showStatus, setShowStatus] = useState("");
  const [color, setColor] = useState("");
  const [getStatus, setGetStatus] = useState("");
  const [statusImage, setStatusImage] = useState("");
  const [getColor, setGetColor] = useState("");
  const [icon, setIcon] = useState("");
  const [ActiveSeller, setActiveSeller] = useState(true);
  const [isActiveSeller, setIsActiveSeller] = useState();
  const [popUpDescription, setPopUpDescription] = useState("");
  const [isCurrentJob, setIsCurrentJob] = useState(0);
  const [timeDataFrom, setTimeDataFrom] = useState({});
  const [timeDataTo, setTimeDataTo] = useState({});
  const [statusId, setStatusId] = useState(0);
  const [errorMsg, showErrorMsg] = useState(false);
  const [colorOnClick, setColorOnClick] = useState("");
  const [imageOnClick, setImageOnClick] = useState("");
  const [customIcon, setCustomIcon] = useState("");
  const [sendIconInApi, setSendIconInApi] = useState();
  const [customStatus, setCustomStatus] = useState("");
  const [isErrorShow, setIsErrorShow] = useState(false);
  const [currentTab, setCurrentTab] = useState("Cover Photo");
  const [elCoverImage, setElCoverImage] = useState("");
  const [elBusinessImage, setElBusinessImage] = useState("");
  const [elProfileImage, setElProfileImage] = useState("");
  const [coverBinaryImg, setCoverBinaryImg] = useState({});
  const [businessBinaryImg, setBusinessBinaryImg] = useState({});
  const [profileBinaryImg, setProfileBinaryImg] = useState({});
  const [isImgVisible, setIsImgVisible] = useState(0);
  const [isCrop, setIsCrop] = useState(false);
  const [coverImgCropper, setCoverImgCropper] = useState();
  const [businessImgCropper, setBusinessImgCropper] = useState();
  const [profileImgCropper, setProfileImgCropper] = useState();
  const [picturesTab, setPicturesTab] = useState();
  const [filterDescription, setFilterDescription] = useState("");
  const [ProfileCustomizeLoading, setProfileCustomizeLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [isDataDisbled, setIsDataDisbled] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const [pictureError, setPicturError] = useState(false);
  const [isCropApiHit, setIsCropApiHit] = useState("");
  const [border, setBorder] = useState("");
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const getSellerData = useSelector((state) => state.AccountReducer);
  const {
    basicInfo: {
      profileImgData,
      coverImgData,
      businessImgData,
      businessName,
      name,
      seller_status,
      coverImage,
      businessImage,
      members,
      profileImage,
    },
  } = AccountState;
  const {
    GeneralInput: { specialization, work },
  } = AccountState;
  const {
    getAllSellerDataApi: { getAllSellerDatas },
  } = getSellerData;
  let firstTimeLogin = !!getCookie("firstTimeLogin")
    ? getCookie("firstTimeLogin")
    : "";
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const handleNext = () => {
    setCookie("firstTimeLogin", false, 1);
    history.push("/newsfeeds");
  };

  const isSellerActive = useCallback((isState, isActive) => {
    setIsActiveSeller(isState);
    setActiveSeller(isActive);
  }, []);

  /* get status name ,image,color,id,error,data to
   CHILD to PARENT component with function */
  const getSeller = useCallback((statusName, image, colors, id, error) => {
    setShowStatus(statusName);
    setImageOnClick(image);
    setColorOnClick(colors);
    setStatusId(id);
    setIsErrorShow(error);
    if (statusName == "Active Seller") {
      setGetStatus(statusName);
    }
  }, []);
  /* show status notifiction FOR 6 sec */
  const getNotificationMessage = useCallback((msg, msgType, num) => {
    const successMsg = msg.split(".")[0];
    const message = msg.substring(msg.indexOf(".") + 1);
    if (num == 1) {
      addToast(
        <p>
          <span
            style={{ color: "#000", fontWeight: 700 }}
          >{`${successMsg}.`}</span>
          <br />
          <br />
          <span>{message}</span>
        </p>,
        {
          appearance: msgType,
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        }
      );
    } else if (num == 2) {
      addToast(
        <p>
          <span
            style={{ color: "#000", fontWeight: 700 }}
          >{`${successMsg}.`}</span>
          <br />
          <br />
          <span>{message}</span>
        </p>,
        {
          appearance: "success",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        }
      );
    } else {
      addToast("Updated Successfully", {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
    }
  }, []);

  /*add validation on current job checkbox if check true set today time on time_to input null */
  useEffect(() => {
    if (isCurrentJob) {
      if (
        (timeDataTo?.target?.value > 0 && customStatus?.trim().length > 0) ||
        (timeDataFrom?.target?.value > 0 &&
          timeDataTo?.target?.value > 0 &&
          showStatus != "Custom") ||
        (timeDataTo?.target?.value > 0 &&
          isCurrentJob &&
          showStatus != "Custom")
      ) {
        setIsDataDisbled(true);
        setTimeDataFrom("");
        setIsDataError(false);
      } else {
        setIsDataDisbled(false);
        setIsDataError(true);
      }
    } else {
      if (
        (timeDataFrom?.target?.value > 0 &&
          timeDataTo?.target?.value > 0 &&
          customStatus.trim()?.length > 0) ||
        (timeDataFrom?.target?.value > 0 &&
          timeDataTo?.target?.value > 0 &&
          showStatus != "Custom" &&
          !customStatus.trim()?.length)
      ) {
        setIsDataDisbled(true);
      } else {
        setIsDataDisbled(false);
        setIsDataError(true);
      }
    }
  }, [isCurrentJob, timeDataFrom, timeDataTo, customStatus]);

  let toDate = timeDataFrom?.target?.value;
  let checkingDate = new Date();

  const submitStatus = () => {
    if (popUpDescription?.trim().length > 0 && isDataDisbled) {
      setStatusLoading(true);
      setGetColor("");
      const data = new FormData();
      if (showStatus == "Custom") {
        if (customStatus.trim()?.length > 0) {
          if (sendIconInApi == undefined) {
            data.append("status_image", "");
          } else {
            data.append("status_image", sendIconInApi);
          }
          data.append("custom_title", customStatus?.slice(0, 23));
        } else {
          showErrorMsg(true);
        }
      }
      data.append("seller_status", statusId);
      data.append("current_status", isCurrentJob);
      data.append("description", popUpDescription);
      if (timeDataFrom?.target?.value) {
        data.append(
          "from_date",
          moment(timeDataFrom?.target?.value).format("DD/MM/YYYY")
        );
      }
      if (timeDataTo?.target?.value) {
        data.append(
          "to_date",
          moment(timeDataTo?.target?.value).format("DD/MM/YYYY")
        );
      }

      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      axios(SELLER_UPDATE_STATUS, config)
        .then((response) => {
          getNotificationMessage(response.data.message, "success", 3);
          return response;
        })
        .then(() => dispatch(getProfileApi()))
        .then(() => setStatusLoading(false))
        .then(() => $("#languageModal").modal("hide"))
        .then(() => {
          setTimeout(() => {
            setPopUpDescription("");
            setTimeDataFrom("");
            setTimeDataTo("");
            setIsCurrentJob(0);
            setCoverBinaryImg("");
            setBusinessBinaryImg("");
            setProfileBinaryImg("");
            setActiveNotificationCount(1);
          }, 200);
        });
      setGetStatus(showStatus);
      setBorder(colorOnClick);
      setGetColor(colorOnClick);
      if (customIcon?.length > 0) {
        setStatusImage(profileData?.status_image);
      } else {
        setStatusImage(imageOnClick);
      }
    } else {
      setIsErrorShow(true);
      showErrorMsg(true);
    }
  };
  /*set api data in state */

  const handleIconChange = (e) => {
    e.preventDefault();
    var file = e.target.files[0];
    const fileName = file.name.split(".");
    const imageFormat = fileName[fileName?.length - 1];
    if (e.target.files[0]) {
      if (
        imageFormat === "png" ||
        imageFormat === "jpg" ||
        imageFormat === "jpeg" ||
        imageFormat === "SVG" ||
        imageFormat === "svg" ||
        imageFormat === "PNG" ||
        imageFormat === "JPG" ||
        imageFormat === "JPEG"
      ) {
        const uploadUrl = URL.createObjectURL(e.target.files[0]);
        setCustomIcon(uploadUrl);
        setSendIconInApi(file);
      } else {
        addToast("Only .png, .jpg, .jpeg image formats supported.", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      }
    }
  };

  const handlePillsTabs = (title) => {
    setCurrentTab(title);
    setTimeout(() => setIsCrop(false), 200);
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    var file = e.target.files[0];
    const fileName = file.name.split(".");
    const imageFormat = fileName[fileName?.length - 1];
    if (e.target.files[0]) {
      if (
        imageFormat === "png" ||
        imageFormat === "jpg" ||
        imageFormat === "jpeg" ||
        imageFormat === "PNG" ||
        imageFormat === "JPG" ||
        imageFormat === "JPEG"
      ) {
        const uploadUrl = URL.createObjectURL(e.target.files[0]);
        if (currentTab == "Cover Photo") {
          setElCoverImage(uploadUrl);
          setCoverBinaryImg(file);
        } else if (currentTab == "Business Logo") {
          setElBusinessImage(uploadUrl);
          setBusinessBinaryImg(file);
        } else if (currentTab === "Profile Picture") {
          setElProfileImage(uploadUrl);
          setProfileBinaryImg(file);
        }
        e.target.value = null;
      } else {
        addToast("Only .png, .jpg, .jpeg image formats supported.", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      }
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      setPopUpDescription("");
      setTimeDataFrom("");
      setTimeDataTo("");
      setIsCurrentJob(0);
      setCustomIcon("");
      setIsCrop(false);
      setElCoverImage(coverImgData);
      setElBusinessImage(businessImgData);
      setElProfileImage(profileImgData);
      setCoverBinaryImg("");
      setBusinessBinaryImg("");
      setProfileBinaryImg("");
      setPicturError(false);
    }, 200);
  };

  /* minimize status  */
  function statusShow() {
    var statuss = document.querySelector(".status-label");
    var statusCurrent = document.querySelector(".status-current");
    statuss?.classList?.add("clip-status");
    statusCurrent?.classList?.add("hide-braces");
    $(".status-icons").addClass("braces-visibility");
  }

  const handleProfileImages = () => {
    if (currentTab === "Cover Photo") {
      openCoverEl.current.click();
    } else if (currentTab === "Business Logo") {
      openBusinessEl.current.click();
    } else if (currentTab === "Profile Picture") {
      openProfileEl.current.click();
    }
  };
  const getCropFunc = (type) => {
    if (type === "cover_image" && typeof coverImgCropper !== "undefined") {
      setIsCrop(false);
      return coverImgCropper.getCroppedCanvas().toDataURL();
    } else if (
      type === "business_image" &&
      typeof businessImgCropper !== "undefined"
    ) {
      setIsCrop(false);
      return businessImgCropper.getCroppedCanvas().toDataURL();
    } else if (
      type === "profile_image" &&
      typeof profileImgCropper !== "undefined"
    ) {
      setIsCrop(false);
      return profileImgCropper.getCroppedCanvas().toDataURL();
    }
  };

  /* convert to base64 to file */
  const base64tofile = (x, binaryFile) => {
    var byteSting = atob(x.split(",")[1]);
    var ab = new ArrayBuffer(byteSting?.length);
    var ia = new Uint8Array(ab);
    for (let i = 0; i < byteSting?.length; i++) {
      ia[i] = byteSting.charCodeAt(i);
    }
    return new File([ab], binaryFile.name, { type: binaryFile.type });
  };
  /* get binary file to base64 url with function */
  const getBinaryFile = (data) => {
    if (data == "cover") {
      let cover;
      let coverBaseUrl;
      if (!!coverBinaryImg.name) {
        cover = getCropFunc("cover_image");
        coverBaseUrl = base64tofile(cover, coverBinaryImg);
      } else {
        const coverBinaryImg = { name: coverImage, type: "image/png" };
        cover = getCropFunc("cover_image");
        coverBaseUrl = base64tofile(cover, coverBinaryImg);
      }
      setCoverBinaryImg(coverBaseUrl);
      setElCoverImage(cover);
      return coverBaseUrl;
    }
    if (data == "business") {
      let business;
      let businessBaseUrl;
      if (!!businessBinaryImg.name) {
        business = getCropFunc("business_image");
        businessBaseUrl = base64tofile(business, businessBinaryImg);
      } else {
        const businessBinaryImg = { name: businessImage, type: "image/png" };
        business = getCropFunc("business_image");
        businessBaseUrl = base64tofile(business, businessBinaryImg);
      }
      setBusinessBinaryImg(businessBaseUrl);
      setElBusinessImage(business);
      return businessBaseUrl;
    }
    if (data == "profile") {
      let profile;
      let profileBaseUrl;
      if (!!profileBinaryImg.name) {
        profile = getCropFunc("profile_image");
        profileBaseUrl = base64tofile(profile, profileBinaryImg);
      } else {
        const profileBinaryImg = { name: profileImage, type: "image/png" };
        profile = getCropFunc("profile_image");
        profileBaseUrl = base64tofile(profile, profileBinaryImg);
      }
      setProfileBinaryImg(profileBaseUrl);
      setElProfileImage(profile);
      return profileBaseUrl;
    }
  };
  /* get cropped image  on Click  */
  const getCropData = (data) => {
    getBinaryFile(data);
  };

  const handleReposition = () => {
    if (
      (!!coverImage && currentTab === "Cover Photo") ||
      (!!coverBinaryImg.name && currentTab === "Cover Photo") ||
      (!!businessImage && currentTab === "Business Logo") ||
      (!!businessBinaryImg.name && currentTab === "Business Logo") ||
      (!!profileImage && currentTab === "Profile Picture") ||
      (!!profileBinaryImg.name && currentTab === "Profile Picture")
    ) {
      setIsCrop(true);
    } else {
      setIsCrop(false);
    }
  };
  useEffect(() => {
    let ListdataNewArray = tabsData.filter((obj) => {
      if (check_individual == "true" && !profileData.is_supporter) {
        return obj.title !== "Business Logo";
      } else {
        return tabsData;
      }
    });
    setPicturesTab(ListdataNewArray);
  }, [tabsData]);
  /*check seller_status who coming from api when comming to set in setIcon */
  useEffect(() => {
    ListData.map((item, i) => {
      if (
        profileData?.seller_status === item.name &&
        ListData[i].name == "Custom" &&
        profileData?.status_image?.length > 0
      ) {
        ListData[i].imageOne = profileData?.status_image;
        setIcon(item.imageOne);
        setColor(item.color);
      } else if (item.name == seller_status) {
        setIcon(item.imageOne);
        setColor(item.color);
      }
    });
  }, [
    profileData?.status_image,
    icon,
    color,
    seller_status,
    ListData,
    ListData[4]?.imageOne,
  ]);

  useEffect(() => {
    if (profileData) setCustomStatus(profileData?.custom_title);
  }, []);
  useEffect(() => {
    let description = popUpDescription?.slice(0, 200);
    setFilterDescription(description);
  }, [popUpDescription]);

  useEffect(() => {
    if (
      profileData?.status_image?.length > 0 &&
      profileData?.seller_status == "Custom"
    ) {
      setIcon(profileData?.status_image);
    }
  }, [profileData, icon]);

  useEffect(() => {
    if (isCurrentJob) {
      const getDataString = new Date();
      setTimeDataTo({ target: { name: "to", value: getDataString } });
    }
  }, [isCurrentJob]);

  useEffect(() => {
    setElCoverImage(coverImgData);
    setElBusinessImage(businessImgData);
    setElProfileImage(profileImgData);
  }, [coverImgData, businessImgData, profileImgData]);

  useEffect(() => {
    if (showStatus != "Active Seller") {
      setTimeout(() => {
        setActiveSeller(true);
      }, 200);
    } else {
      setActiveSeller(true);
    }
  });

  useEffect(() => {
    if (
      !!coverBinaryImg.name ||
      !!businessBinaryImg.name ||
      !!profileBinaryImg.name
    ) {
      setPicturError(false);
    }
  }, [coverBinaryImg, businessBinaryImg, profileBinaryImg]);

  const handleProfileSumbit = () => {
    if (currentTab === "Cover Photo" && isCrop) {
      setCoverBinaryImg(getBinaryFile("cover"));
    }
    if (currentTab === "Business Logo" && isCrop) {
      setBusinessBinaryImg(getBinaryFile("business"));
    }
    if (currentTab === "Profile Picture" && isCrop) {
      setProfileBinaryImg(getBinaryFile("profile"));
    }
    setTimeout(() => setIsCropApiHit("save"), 500);
  };

  /*add circle on StatusBar after 5 sec*/
  const addCircleInStatus = () => {
    const getStatusImg = document.querySelector(".status-current");
    if (getStatusImg) {
      let fourChildNode = getStatusImg.querySelector("img");
      if (!!fourChildNode) {
        if (fourChildNode.parentNode.matches(":hover")) {
          fourChildNode.style.border = `0px solid #fff`;
        } else {
          if (getStatus != "Active Seller") {
            fourChildNode.style.border = `2px solid ${border}`;
          } else if (
            profileData?.seller_status != "Active Seller" &&
            getStatus != "Active Seller"
          ) {
            fourChildNode.style.border = `2px solid ${border}`;
          } else {
            fourChildNode.style.border = "2px solid green";
          }
        }
      }
    }
  };
  /*add circle on onHover */
  const handleHover = (data) => {
    const getStatusImg = document.querySelector(".status-current");
    let fourChildNode = getStatusImg.querySelector("img");
    if (fourChildNode.parentNode.matches(":hover")) {
      fourChildNode.style.border = `0px solid #fff`;
      statusShow();
    } else if (data == "hover") {
      fourChildNode.style.border = `0px solid #fff`;
      fourChildNode.style.padding = `1px`;
    } else {
      fourChildNode.style.border = `2px solid ${border}`;
      fourChildNode.style.padding = `px`;
    }
  };
  useEffect(() => {
    if (!border && !getStatus) {
      setTimeout(() => {
        addCircleInStatus();
        statusShow();
      }, 5000);
    } else {
      if (colorOnClick == "green") {
        setTimeout(() => {
          addCircleInStatus();
          statusShow();
        }, 200);
      } else {
        setTimeout(() => {
          addCircleInStatus();
          statusShow();
        }, 1000);
      }
    }
    if (colorOnClick == "green") {
      setBorder(colorOnClick);
    }
  }, [profileData, colorOnClick]);
  useEffect(() => {
    setIsImgVisible(profileData.profile_visibility);
  }, [profileData.profile_visibility]);

  useEffect(() => {
    if (isCropApiHit == "save") {
      setProfileCustomizeLoading(true);
      if (
        !!coverBinaryImg.name ||
        !!businessBinaryImg.name ||
        !!profileBinaryImg.name ||
        coverImage ||
        businessImage ||
        profileImage
      ) {
        setProfileCustomizeLoading(true);
        const data = new FormData();
        if (!check_individual || profileData.is_supporter) {
          data.append("business_image", businessBinaryImg);
          data.append("profile_visibility", isImgVisible);
        }
        data.append("cover_image", coverBinaryImg);
        data.append("profile_image", profileBinaryImg);

        let config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getCookie("token_id"),
          },
          data: data,
        };
        axios(API_BASE_URL + "/profile-visibility", config)
          .then((res) => {
            dispatch(getProfileApi());
          })
          .then(() => $("#profile_custom").modal("hide"))
          .then(() => {
            addToast("Profile Pictures Successfully Updated", {
              appearance: "success",
              autoDismiss: true,
              // autoDismissTimeout: 2000,
            });
            setProfileCustomizeLoading(false);
            setIsCrop(false);
            setIsCropApiHit("");
          });
      } else {
        setPicturError(true);
        setIsCropApiHit("");
        setProfileCustomizeLoading(false);
      }
    }
  }, [isCropApiHit]);

  return (
    <>
      {!!firstTimeLogin ? (
        <section className="welcome py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="welcome__content">
                  <h1 className="font-weight-bold">Welcome to PoolsMagnic</h1>
                  {check_individual === "true" ? (
                    <p>
                      Build and personalize your profile.
                      <br /> So, it helps you grow your network and be found by
                      others.
                    </p>
                  ) : (
                    <p>
                      Build and customise your business profile.
                      <br /> So, it helps you to grow your network and found by
                      others.{" "}
                    </p>
                  )}
                </div>
                <a
                  href="javascript:;"
                  className="btn btn-secondary back-btn"
                  onClick={() => history.push("/newsfeeds")}
                >
                  <i className="ri-arrow-left-s-line"></i> Back
                </a>
                <a
                  href="javascript:;"
                  onClick={handleNext}
                  className="btn next-btn btn-blue"
                >
                  {" "}
                  Next <i className="ri-arrow-right-s-line"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      <section
        className="profile-header spacer bg-primary my-profile"
        style={{
          backgroundImage: `url("${
            coverImgData ? coverImgData : "/assets/images/pools_cover_image.png"
          }")`,
        }}
      >
        <div className="container">
          <div className="col-md-12">
            <a
              data-toggle="modal"
              data-target="#profile_custom"
              data-backdrop="static"
              data-keyboard="false"
              href="javascript:;"
              className="cover-edit btn btn_fix"
            >
              <i className="ri-pencil-line" /> update Profile pictures
            </a>

            <input
              type="file"
              id="coverImgData"
              ref={openCoverEl}
              onChange={handleFileChange}
              name="coverImage"
              className="d-none"
              accept="image/*"
            />
          </div>
        </div>
        <div className="container profile-area">
          <div className="row">
            <div className="col-md-12">
              {/* BEGIN profile-header-content */}
              <div className="profile-header-content">
                <div className="profile-header-content__inner  d-flex align-items-center">
                  {check_individual === "true" && !profileData.is_supporter ? (
                    ""
                  ) : (
                    <div className="business-logo position-relative">
                      <img
                        onError={(ev) =>
                          (ev.target.src = "/assets/images/business_logo.svg")
                        }
                        src={
                          !!businessImgData
                            ? businessImgData
                            : "/assets/images/business_logo.svg"
                        }
                        alt=""
                      />
                      <input
                        type="file"
                        id="businessImgData"
                        ref={openBusinessEl}
                        onChange={handleFileChange}
                        name="businessImage"
                        className="d-none"
                        accept="image/*"
                      />
                    </div>
                  )}
                  <div className="profile-header-img">
                    <img
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/member-default.svg")
                      }
                      src={
                        !!profileImgData
                          ? profileImgData
                          : "/assets/images/member-default.svg"
                      }
                      alt="Profile-img da"
                    />
                    <input
                      type="file"
                      id="profileImgData"
                      ref={openProfileEl}
                      onChange={handleFileChange}
                      name="profileImage"
                      className="d-none"
                      accept="image/*"
                    />
                  </div>
                  {/* END profile-header-img */}
                  {/* BEGIN profile-header-info */}
                  {check_individual === "true" ? (
                    <div className="profile-header-info">
                      <h3 className="d-inline">
                        {name?.charAt(0).toUpperCase() +
                          name?.slice(1).substr(0, 23)}
                      </h3>
                      <div
                        className="name-status d-inline position-relative"
                        style={{ cursor: "no-drop" }}
                      >
                        <span
                          className={`d-inline-block status-current  font-weight-bold h6 ml-1 mb-0 ${getStatus==="Active Seller" && "point" }`}
                          style={{
                            color:
                              getStatus === "Active Seller"
                                ? "green"
                                : getColor?.length > 0
                                ? getColor
                                : color,
                          }}
                          onMouseEnter={() => handleHover("hover")}
                          onMouseLeave={() => handleHover("down")}
                        >
                          {isActiveSeller > 148 ? (
                            getStatus?.length > 0 && profileData.name ? (
                              <div>
                                <span className="braces-left status-icons ">
                                  (
                                </span>
                                <img
                                  className="status-active-icon"
                                  src={
                                    getStatus === "Active Seller"
                                      ? "/assets/images/active-icon.svg"
                                      : getStatus?.length !== 0
                                      ? getStatus !== "Custom"
                                        ? statusImage
                                        : profileData?.status_image?.length > 0
                                        ? IMAGE_BASE_URL + icon
                                        : "/assets/images/custom-icon.svg"
                                      : null
                                  }
                                />{" "}
                                <span className="status-label">
                                  {getStatus?.length === 0
                                    ? seller_status
                                      ? seller_status
                                      : ""
                                    : showStatus == "Active Seller"
                                    ? showStatus
                                    : getStatus == "Custom"
                                    ? profileData?.custom_title
                                    : getStatus}
                                  {""}
                                  <span className="braces-right status-icons">
                                    )
                                  </span>
                                </span>
                              </div>
                            ) : (
                              <>
                                {seller_status?.length > 0 ? (
                                  <div className="status-tip">
                                    <span className="braces-left status-icons">
                                      (
                                    </span>
                                    <img
                                      src={
                                        getStatus === "Active Seller"
                                          ? imageOnClick
                                          : getStatus?.length !== 0
                                          ? statusImage
                                          : seller_status == "Custom"
                                          ? profileData?.status_image?.length >
                                            0
                                            ? IMAGE_BASE_URL + icon
                                            : "/assets/images/custom-icon.svg"
                                          : icon
                                      }
                                    />
                                    <span className="status-label pl-1">
                                      {getStatus?.length === 0
                                        ? getStatus == "Active Seller"
                                          ? "Active Seller"
                                          : seller_status == "Custom"
                                          ? profileData?.custom_title
                                          : seller_status
                                        : null}
                                      <span className="braces-right status-icons">
                                        )
                                      </span>
                                    </span>
                                  </div>
                                ) : null}
                              </>
                            )
                          ) : null}
                        </span>

                        {profileData?.seller_status != "Active Seller" &&
                        profileData?.seller_status != undefined &&
                        getStatus?.length == 0 ? (
                          <p>
                            <span style={{ fontWeight: 600 }}>
                              {" "}
                              {profileData?.seller_status}
                            </span>
                            <span style={{ fontWeight: 600 }}>
                              {!!profileData?.from_date
                                ? ` (${profileData?.from_date})  to (${profileData?.to_date})`
                                : ` to (${profileData?.to_date})`}
                            </span>
                            <br />
                            {profileData?.description}
                          </p>
                        ) : getStatus?.length > 0 &&
                          getStatus != "Active Seller" ? (
                          <p>
                            <span style={{ fontWeight: 600 }}>
                              {profileData?.seller_status}
                            </span>
                            <span style={{ fontWeight: 600 }}>
                              {!!profileData?.from_date
                                ? ` (${profileData?.from_date}) to (${profileData?.to_date})`
                                : ` to (${profileData?.to_date})`}
                            </span>
                            <br />
                            {profileData?.description}
                          </p>
                        ) : null}
                      </div>
                      <div>
                        <span
                          className="mb-0 mr-1"
                          style={{ fontStyle: "italic" }}
                        >
                          {work}
                        </span>
                      </div>
                      <div className="mb-0 mt-2">
                        {!!members?.length &&
                        members[0]?.business_name?.length ? (
                          <div className="d-flex align-items-center w-100">
                            {!!members?.length && (
                              <div
                                onClick={() =>
                                  history.push(
                                    `/search-profile/${members[0]?.id}
`
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className="rounded"
                                  style={{ width: "25px", height: "25px" }}
                                  onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/business_logo.svg")
                                  }
                                  src={
                                    !!members[0]?.business_image
                                      ? IMAGE_BASE_URL +
                                        members[0]?.business_image
                                      : "/assets/images/business_logo.svg"
                                  }
                                />
                                <span className="mb-0 ml-2">
                                  {!!members ? members[0]?.business_name : null}
                                </span>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="profile-header-info business-profile w-100">
                      <div>
                        <h3 className="d-inline">
                          {businessName?.charAt(0).toUpperCase() +
                            businessName?.slice(1).substr(0, 23)}
                        </h3>
                        <div
                          className="name-status d-inline position-relative"
                          style={{ cursor: "no-drop" }}
                        >
                          <span
                            className={`d-inline-block status-current font-weight-bold h6 ml-1 mb-0 ${getStatus==="Active Seller" && "point"}`}
                            style={{
                              color:
                                getStatus === "Active Seller"
                                  ? "green"
                                  : getColor?.length > 0
                                  ? getColor
                                  : color,
                            }}
                            onMouseEnter={() => handleHover("hover")}
                            onMouseLeave={() => handleHover("down")}
                          >
                            {isActiveSeller > 148 && ActiveSeller ? (
                              getStatus?.length > 0 && profileData.name ? (
                                <div >
                                  <span className="braces-left status-icons">
                                    (
                                  </span>
                                  <img
                                    src={
                                      getStatus === "Active Seller"
                                        ? "/assets/images/active-icon.svg"
                                        : getStatus?.length !== 0
                                        ? getStatus !== "Custom"
                                          ? statusImage
                                          : profileData?.status_image?.length >
                                            0
                                          ? IMAGE_BASE_URL + icon
                                          : "/assets/images/custom-icon.svg"
                                        : null
                                    }
                                  />{" "}
                                  <span className="status-label">
                                    {getStatus?.length === 0
                                      ? seller_status
                                      : showStatus == "Active Seller"
                                      ? showStatus
                                      : getStatus == "Custom"
                                      ? profileData?.custom_title
                                      : getStatus}
                                    <span className="braces-right status-icons">
                                      )
                                    </span>
                                  </span>
                                  {""}
                                </div>
                              ) : (
                                <div>
                                  {seller_status?.length > 0 && ActiveSeller ? (
                                    <div>
                                      {""}
                                      <span className="braces-left status-icons">
                                        (
                                      </span>
                                      <img
                                        src={
                                          getStatus === "Active Seller"
                                            ? imageOnClick
                                            : getStatus?.length !== 0
                                            ? statusImage
                                            : seller_status == "Custom"
                                            ? profileData?.status_image
                                                ?.length > 0
                                              ? IMAGE_BASE_URL + icon
                                              : "/assets/images/custom-icon.svg"
                                            : icon
                                        }
                                      />{" "}
                                      <span className="status-label">
                                        {getStatus?.length === 0
                                          ? getStatus == "Active Seller"
                                            ? "Active Seller"
                                            : seller_status == "Custom"
                                            ? profileData?.custom_title
                                            : seller_status
                                          : null}
                                        <span className="braces-right status-icons">
                                          )
                                        </span>
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              )
                            ) : null}
                          </span>
                          {profileData?.seller_status != "Active Seller" &&
                          profileData?.seller_status != undefined &&
                          getStatus?.length == 0 &&
                          profileData?.seller_status?.length != 0 ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>
                                {profileData?.seller_status}
                              </span>
                              <span style={{ fontWeight: 600 }}>
                                {!!profileData?.from_date
                                  ? ` (${profileData?.from_date}) to (${profileData?.to_date})`
                                  : ` to (${profileData?.to_date})`}
                              </span>
                              <br />
                              {profileData?.description}
                            </p>
                          ) : getStatus?.length > 0 &&
                            getStatus != "Active Seller" ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>
                                {profileData?.seller_status}
                              </span>
                              <span style={{ fontWeight: 600 }}>
                                {!!profileData?.from_date
                                  ? ` (${profileData?.from_date}) to (${profileData?.to_date})`
                                  : ` to (${profileData?.to_date})`}
                              </span>
                              <br />
                              {profileData?.description}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <span className="font-weight-bold text-capitalize d-inline">
                          {specialization ? name + "," : name}
                        </span>{" "}
                        {specialization}
                      </div>
                    </div>
                  )}
                  {/* END profile-header-info */}
                </div>
                {!profileData.is_supporter && (
                  <div>
                    {check_individual === "trueimage.png" ? (
                      <SlidingButton
                        seller_status={seller_status}
                        getSeller={getSeller}
                        showStatus={showStatus}
                        getAllDatas={profileData}
                        isSellerActive={isSellerActive}
                        getNotificationMessage={getNotificationMessage}
                        getStatus={getStatus}
                        profileData={profileData}
                        getAllSellerDatas={getAllSellerDatas}
                      />
                    ) : (
                      <SlidingButton
                        seller_status={seller_status}
                        showStatus={showStatus}
                        getSeller={getSeller}
                        getAllDatas={profileData}
                        isSellerActive={isSellerActive}
                        getNotificationMessage={getNotificationMessage}
                        getStatus={getStatus}
                        profileData={profileData}
                        getAllSellerDatas={getAllSellerDatas}
                      />
                    )}
                  </div>
                )}
              </div>

              {/* end profile */}
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="languageModal"
        tabIndex="-1"
        aria-labelledby="languageModalModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="languageModalLabel"
                style={{
                  color: colorOnClick?.length > 0 ? colorOnClick : color,
                  fontWeight: 500,
                }}
              >
                <img
                  src={showStatus?.length !== 0 ? imageOnClick : icon}
                  alt="icon"
                  className="site-icon"
                />{" "}
                {showStatus}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="p-3">
              <form>
                <>
                  {showStatus == "Custom" && (
                    <div className="row">
                      <ClipLoader
                        color={"#000"}
                        loading={statusLoading}
                        css={override}
                      />
                      <div className="col-md-12 form-group">
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="profile-header-img custom-status-thumb">
                            <img
                              onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/member-default.svg")
                              }
                              src={customIcon}
                              alt="Profile-img da"
                            />
                            <a
                              href="javascript:;"
                              onClick={() => openProfileEl.current.click()}
                            >
                              <i className="ri-pencil-line" />
                            </a>
                            <input
                              type="file"
                              id="file"
                              ref={openProfileEl}
                              onChange={handleIconChange}
                              name="profileImage"
                              style={{ display: "none" }}
                              accept="image/*"
                            />
                          </div>

                          <div className="custom-status-input">
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              value={customStatus.slice(0, 23)}
                              onChange={(e) => setCustomStatus(e.target.value)}
                              placeholder="Type your Custom status"
                            />
                            {errorMsg &&
                            isErrorShow &&
                            customStatus?.trim().length == 0 ? (
                              <p className="error-message">
                                Status is required{" "}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <ClipLoader
                      color={"#000"}
                      loading={statusLoading}
                      css={override}
                    />
                    <div className="col-md-12 ">
                      <div className="form-group tab-content">
                        <textarea
                          name="description"
                          className="form-control"
                          value={filterDescription || ""}
                          onChange={(e) => {
                            setPopUpDescription(e.target.value);
                          }}
                          placeholder="Write a note for others about your status"
                        />
                        {filterDescription?.length}
                        /200
                        {errorMsg &&
                        isErrorShow &&
                        popUpDescription?.trim().length == 0 ? (
                          <p className="error-message">
                            Description is required{" "}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group mb-1">
                        <input
                          type="checkbox"
                          name="current"
                          onChange={(e) => {
                            setIsCurrentJob(e.target.checked ? 1 : 0);
                          }}
                          checked={isCurrentJob && 1}
                          id={0}
                          className="h-auto"
                        />
                        <span className="ml-2">Current</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-5">
                          <label htmlFor="from">from</label>
                          <DatePicker
                            minDate={checkingDate}
                            className="bg-trsp"
                            id="dateob"
                            name="from"
                            format="dd/MM/yyyy"
                            value={timeDataFrom?.target?.value || ""}
                            selected={
                              timeDataFrom?.target?.name
                                ? moment(timeDataFrom?.target?.value).format(
                                    "L"
                                  )
                                : null
                            }
                            disabled={isCurrentJob}
                            monthPlaceholder="MM"
                            onChange={(date) =>
                              setTimeDataFrom({
                                target: { name: "from", value: date },
                              })
                            }
                            dayPlaceholder="DD"
                            yearPlaceholder="YYYY"
                          />
                          {errorMsg &&
                            isDataError &&
                            isErrorShow &&
                            timeDataFrom?.target?.value == undefined &&
                            isCurrentJob != true && (
                              <p className="error-message">
                                From date is required
                              </p>
                            )}
                        </div>
                        <div className="col-md-5">
                          <label htmlFor="To">To</label>
                          <DatePicker
                            minDate={toDate}
                            className="bg-trsp"
                            id="dateob"
                            name="to"
                            value={timeDataTo?.target?.value || ""}
                            format="dd/MM/yyyy"
                            monthPlaceholder="MM"
                            onChange={(date) =>
                              setTimeDataTo({
                                target: { name: "to", value: date },
                              })
                            }
                            dayPlaceholder="DD"
                            yearPlaceholder="YYYY"
                          />
                          {errorMsg &&
                            isDataError &&
                            isErrorShow &&
                            timeDataTo?.target?.value == undefined && (
                              <p className="error-message">
                                To date is required
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </form>
            </div>

            <div
              className="text-right
             mr-4 mb-3"
            >
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={submitStatus}
              >
                Change Status
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="profile_custom"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon"
                />
                Edit Profile Photos
              </h5>
              <button
                type="button"
                onClick={handleClose}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0 customise-profile">
              <ul className="nav " id="pills-tab" role="tablist">
                {picturesTab?.map((items) => {
                  return (
                    <li
                      className="nav-item "
                      role="presentation"
                      key={items.id}
                      onClick={() => handlePillsTabs(items.title)}
                    >
                      <a
                        className={`nav-link ${
                          items.title === "Cover Photo" ? "active" : null
                        } d-flex align-items-center justify-content-center `}
                        id="pills-cover-tab"
                        data-toggle="pill"
                        href={`#${items.href}`}
                        role="tab"
                        aria-controls={items.href}
                        aria-selected={items.ariaSelected}
                      >
                        <i className={`${items.icon} mr-1 `}></i> {items.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-cover"
                  role="tabpanel"
                  aria-labelledby="pills-cover-tab"
                >
                  <ClipLoader
                    color={"#000"}
                    loading={ProfileCustomizeLoading}
                    css={override}
                  />
                  <div>
                    {isCrop ? (
                      <>
                        <Cropper
                          style={{ height: 400, width: "100%" }}
                          initialAspectRatio={0}
                          preview=".img-preview"
                          src={elCoverImage}
                          ref={imageRef}
                          viewMode={2}
                          minCropBoxWidth={200}
                          minCropBoxHeight={200}
                          background={true}
                          responsive={true}
                          dragMode="move"
                          rounded={true}
                          withCredentials={true}
                          checkOrientation={false}
                          onInitialized={(instance) => {
                            setCoverImgCropper(instance);
                          }}
                          guides={true}
                        />
                        <div className="w-100 text-center p-1">
                          <button
                            onClick={() => getCropData("cover")}
                            className="btn btn-secondary btn-sm cropper_button"
                          >
                            {" "}
                            Crop
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="profileCropImage">
                        <img
                          onError={(ev) =>
                            (ev.target.src = "/assets/images/cover_img.png")
                          }
                          name="cover_image"
                          src={
                            elCoverImage
                              ? elCoverImage
                              : "/assets/images/cover_img.png"
                          }
                          alt=""
                        />
                      </div>
                    )}
                    {pictureError ? (
                      <p className="error-message pl-4 pb-2">
                        Select atleast one picture
                      </p>
                    ) : null}
                  </div>
                </div>
                {check_individual == "true" ? null : (
                  <div
                    className="tab-pane fade"
                    id="pills-business"
                    role="tabpanel"
                    aria-labelledby="pills-business-tab"
                  >
                    <ClipLoader
                      color={"#fff"}
                      loading={ProfileCustomizeLoading}
                      css={override}
                    />
                    <div>
                      {isCrop ? (
                        <>
                          <Cropper
                            style={{ height: 400, width: "100%" }}
                            initialAspectRatio={0}
                            preview=".img-preview"
                            src={elBusinessImage}
                            viewMode={2}
                            rounded={true}
                            minCropBoxWidth={200}
                            minCropBoxHeight={200}
                            dragMode="move"
                            background={true}
                            responsive={true}
                            autoCropArea={1}
                            withCredentials={true}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                              setBusinessImgCropper(instance);
                            }}
                            guides={true}
                          />
                          <div className="w-100 text-center p-1">
                            <button
                              onClick={() => getCropData("business")}
                              className="btn btn-secondary btn-sm cropper_button"
                            >
                              {" "}
                              Crop
                            </button>
                          </div>
                        </>
                      ) : (
                        <div className="text-center">
                          <div className="profileCropImage">
                            <img
                              onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/business_logo.svg")
                              }
                              src={
                                elBusinessImage
                                  ? elBusinessImage
                                  : "/assets/images/business_logo.svg"
                              }
                              alt="Profile-img da"
                            />
                          </div>
                        </div>
                      )}
                      {pictureError ? (
                        <p className="error-message pl-4 pb-2">
                          Select atleast one picture
                        </p>
                      ) : null}
                    </div>
                  </div>
                )}

                <div
                  className="tab-pane fade"
                  id="pills-picture"
                  role="tabpanel"
                  aria-labelledby="pills-picture-tab"
                >
                  <ClipLoader
                    color={"#fff"}
                    loading={ProfileCustomizeLoading}
                    css={override}
                  />
                  <div>
                    {isCrop ? (
                      <>
                        <Cropper
                          style={{ height: 400, width: "100%" }}
                          initialAspectRatio={0}
                          preview=".img-preview"
                          src={elProfileImage}
                          viewMode={2}
                          rounded={true}
                          minCropBoxWidth={200}
                          minCropBoxHeight={200}
                          dragMode="move"
                          background={true}
                          responsive={true}
                          autoCropArea={0}
                          crossorigin="anonymous"
                          withCredentials={true}
                          checkOrientation={false}
                          onInitialized={(instance) => {
                            setProfileImgCropper(instance);
                          }}
                          guides={true}
                        />
                        <div className="w-100 text-center p-2">
                          <button
                            onClick={() => getCropData("profile")}
                            className="btn btn-secondary btn-sm cropper_button"
                          >
                            {" "}
                            Crop
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className=" toggle">
                        <div className="profileCropImage">
                          <img
                            onError={(ev) =>
                              (ev.target.src =
                                "/assets/images/member-default.svg")
                            }
                            src={
                              elProfileImage
                                ? elProfileImage
                                : "/assets/images/member-default.svg"
                            }
                            alt="Profile-img"
                          />
                        </div>
                        {check_individual == "true" ? null : (
                          <div className="d-flex ml-4 mb-2">
                            Your Profile Photo & Name Visibility
                            <span className="switch">
                              <input
                                type="checkbox"
                                name="visibility"
                                id="visibility"
                                onChange={(e) => {
                                  setIsImgVisible(e.target.checked ? 1 : 0);
                                }}
                                checked={isImgVisible && 1}
                              />
                              <label
                                htmlFor="visibility"
                                className="mr-3 ml-2"
                              ></label>
                            </span>
                            <div className="visibility-instruction position-relative pr-3">
                              <i className="ri-question-line"></i>
                              <div className="visibility-txt ">
                                <p>
                                  Your profile photo and name would be visible
                                  to others when they view your business profile
                                </p>
                                <p>
                                  <HiOutlineThumbUp
                                    size={20}
                                    color={"#fff"}
                                    padding={10}
                                    style={{
                                      transform: "rotate(-25deg)",
                                      background: "green",
                                      borderRadius: "50%",
                                    }}
                                  />{" "}
                                  As small business, you may keep your personal
                                  photo visible to others as part of the
                                  business identity
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {pictureError ? (
                    <p className="error-message pl-4 pb-2">
                      Select atleast one picture
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-md-9 px-0">
                  <div className="feeds-post__type d-flex justify-content-start w-100">
                    <div className="feeds-post__category">
                      <a href="javascript:;" onClick={handleProfileImages}>
                        <i className="ri-image-fill pictures-option" /> Upload
                        Photo
                      </a>
                    </div>
                    <div className="feeds-post__category">
                      {" "}
                      <a href="javascript:;" onClick={handleReposition}>
                        <i className="ri-drag-move-2-line video-option" />{" "}
                        Reposition
                        <input
                          type="file"
                          name="file"
                          id="upload_fle"
                          className="d-none"
                          accept=" video/*"
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-3 text-right btn-spacer"
                  onClick={() => handleProfileSumbit("save")}
                >
                  <button
                    href="javascript:;"
                    className="btn btn-primary btn-sm btn-full"
                    disabled={ProfileCustomizeLoading}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Basicinfo;
