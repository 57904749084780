const SEARCH_LIST_DATA_REQUEST = "SEARCH_LIST_DATA_REQUEST";
const SEARCH_LIST_DATA_SUCCESS = "SEARCH_LIST_DATA_SUCCESS";
const SEARCH_LIST_DATA_FAILURE = "SEARCH_LIST_DATA_FAILURE";

const CLEAR_SEARCH_LIST_RESPONSE = "CLEAR_SEARCH_LIST_RESPONSE";

const SEND_REQUEST_DATA_REQUEST = "SEND_REQUEST_DATA_REQUEST";
const SEND_REQUEST_DATA_SUCCESS = "SEND_REQUEST_DATA_SUCCESS";
const SEND_REQUEST_DATA_FAILURE = "SEND_REQUEST_DATA_FAILURE";

const SEARCH_SERVICE_DATA_REQUEST = "SEARCH_SERVICE_DATA_REQUEST";
const SEARCH_SERVICE_DATA_SUCCESS = "SEARCH_SERVICE_DATA_REQUEST";
const SEARCH_SERVICE_DATA_FAILURE = "SEARCH_SERVICE_DATA_REQUEST";

const CLEAR_SEND_REQUEST_RESPONSE = "CLEAR_SEND_REQUEST_RESPONSE";

export {
  SEARCH_LIST_DATA_REQUEST,
  SEARCH_LIST_DATA_SUCCESS,
  SEARCH_LIST_DATA_FAILURE,
  CLEAR_SEARCH_LIST_RESPONSE,
  SEND_REQUEST_DATA_REQUEST,
  SEND_REQUEST_DATA_SUCCESS,
  SEND_REQUEST_DATA_FAILURE,
  CLEAR_SEND_REQUEST_RESPONSE,
  SEARCH_SERVICE_DATA_REQUEST,
  SEARCH_SERVICE_DATA_SUCCESS,
  SEARCH_SERVICE_DATA_FAILURE,
};
