import React, { memo } from "react";
import { Redirect } from "react-router";
import { SELLER_UPDATE_STATUS } from "../../../library/urls";
import axios from "axios";
import { getCookie } from "../../../library/utilities/functions";




const profileData = !!getCookie("profile_data")
  ? JSON.parse(getCookie("profile_data"))
  : "";
class SlidingButton extends React.Component {
  constructor(props) {
    super(props);
    this.unlockStartHandler = this.unlockStartHandler.bind(this);
    this.unlockEndHandler = this.unlockEndHandler.bind(this);
    this.animateHandler = this.animateHandler.bind(this);
    this.successHandler = this.successHandler.bind(this);
    this.maxValue = 150;
    this.speed = 12;
    this.currValue = 0;
    this.rafID = null;
    // this.activeSeller = true;
  }

  state = {
    navigate: false,
    selectedItem: "",
    defaultValues: 150,
    withValue: 0,
    activeSeller: true,
    arrowState: null,
    updatedStatus: true,
    getStatus: '',
    errorShowing: false,
    swipeValue: 0,
    isStatusClicked: false
  };

  componentDidMount() {
    // bind events
    this.inputRange.addEventListener(
      "mousedown",
      this.unlockStartHandler,
      false
    );
    this.inputRange.addEventListener(
      "touchstart",
      this.unlockStartHandler,
      false
    );
    this.inputRange.addEventListener("mouseup", this.unlockEndHandler, false);
    this.inputRange.addEventListener("touchend", this.unlockEndHandler, false);
  }

  // listen for unlock
  unlockStartHandler(e) {

    // clear raf if trying again
    window.cancelAnimationFrame(this.rafID);
    // set to desired value
    this.currValue = +e.target.value;
    if (e.target.value < 150) {
      this.setState({
        arrowState: "right",
      });
    } else {
      this.setState({
        arrowState: "left",
      });
    }
  }

  unlockEndHandler(e) {
    $(".swipe-input ul").removeClass("show");
    if (this.state.defaultValues < 145 && this.state.arrowState != 'right' && !!this.state.arrowState) {
      const data = new FormData();
      data.append("seller_status", 0);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      axios(SELLER_UPDATE_STATUS, config).then((response) => {
        this.props.getNotificationMessage(response.data.message, 'success', 2)
      });
      this.setState({ updatedStatus: false });
    } else if (this.state.withValue > 149 && this.state.arrowState != "left" && this.state.defaultValues > 149 && this.props.getAllSellerDatas.user_id) {
      this.props.getSeller("Active Seller", "/assets/images/active-icon.svg", 'green', 0, this.state.errorShowing);
      const data = new FormData();
      data.append("seller_status", 1);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      this.setState({ getStatus: 'Active Seller' })
      axios(SELLER_UPDATE_STATUS, config).then((response) => {
        if (response.data.message) {
          this.props.getNotificationMessage(response.data.message, 'success', 1)
        }
        return response;
      });
    }
    this.currValue = +e.target.value;
    if (this.currValue >= this.maxValue) {
      this.successHandler();
      this.setState({
        arrowState: "down",
      });
    } else {
      this.rafID = window.requestAnimationFrame(this.animateHandler);
    }
  }

  // handle range animation
  animateHandler(e) {
    $(".swipe-input").removeClass("swipeleft");
    this.inputRange.value = this.currValue;
    // determine if we need to continue
    if (this.currValue > -1) {
      window.requestAnimationFrame(this.animateHandler);
    }
    setTimeout(() => {
      this.setState({ withValue: 0 })
      this.setState({ defaultValues: 0 })
    }, 100)
    // decrement value
    this.currValue = this.currValue - this.speed;

  }

  // handle successful unlock
  successHandler() {
    const BecomeData = !!getCookie("become_seller");
    if (BecomeData || this.props.seller_status) {
      $(".swipe-input ul").addClass("show");
      setTimeout(() => {
        $(".swipe-input ul").removeClass("show");
      }, 5000)
    } else {
      if (!!this.props.getAllSellerDatas.user_id) {
        this.setState({ navigate: false });
        $(".swipe-input ul").addClass("show");
        setTimeout(() => {
          $(".swipe-input ul").removeClass("show");
        }, 5000)
      } else {
        this.setState({ navigate: true });
      }
    }

    this.setState({ swipeValue: 1 })
  }

  selectItem = (item) => {
    this.setState({ activeSeller: true });
    this.setState({ updatedStatus: true });
    this.setState({ errorShowing: false });
    this.setState({ isStatusClicked: true });
    const data = new FormData();
    // Adding files to the formdata
    this.props.getSeller(item.name, item.imageOne, item.color, item.id, this.state.errorShowing);
    this.setState({ getStatus: item.name })
    data.append("seller_status", item.id);
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };
    axios(SELLER_UPDATE_STATUS, config).then((response) => {
      if (response.data.message && this.state.getStatus == 'Active Seller') {
        this.props.getNotificationMessage(response.data.message, 'success', 1)
      }
      this.setState({ updatedStatus: response.data.data?.status })
      return response;
    });
    $(".swipe-input ul").removeClass("show");
    setTimeout(() => this.setState({ isStatusClicked: false }), 3000)
  };
  componentDidUpdate() {
    this.props.isSellerActive(
      this.state.defaultValues,
      this.state.activeSeller
    );
  }


  render() {

    const { navigate } = this.state;
    if (navigate) {
      return <Redirect to="/become-seller" push={true} />;
    }
    const ListData = [
      {
        id: 1,
        name: "Active Seller",
        icon: "ri-chat-check-line",
        imageOne: "/assets/images/active-icon.svg",
        color: 'green',

      },
      {
        id: 2,
        name: "Fully Booked",
        icon: "ri-ancient-gate-line",
        imageOne: "/assets/images/booking-icon.svg",
        color: 'red',

      },
      {
        id: 3,
        name: "Holiday",
        icon: "ri-ancient-gate-line",
        imageOne: "/assets/images/summer-holidays.png",
        color: 'purple',

      },
      {
        id: 5,
        name: "Temporarily Closed",
        icon: "ri-ancient-gate-line",
        imageOne: "/assets/images/temporarly.svg",
        color: '#fa0707',

      },
      {
        id: 4,
        name: "Custom",
        icon: "ri-ancient-gate-line",
        imageOne: "/assets/images/custom-icon.svg",
        color: 'brown',

      },
    ];
    let ListdataNewArray = ListData.filter((obj) => {
      if (profileData.business_name) {
        return obj.name !== 'Holiday';
      } else {
        return obj.name !== 'Temporarily Closed';
      }
    });
    return (
      <div
        className={`swipe-input
           ${this.state.defaultValues > 149 && !!this.props.getAllDatas.seller_status
            ? "swipedown"
            : this.state.withValue < 149 ? this.state.arrowState == "left" ? "swipeleft" : "swiperight" : this.props.getAllSellerDatas.user_id ? "swipedown" : "swipeleft"
          } `}>
        <div className="status-wrapper d-flex">
          <label className="d-block font-weight-bold">Status</label>
          <div className="question_hover mx-1">
            <i className="ri-question-line"></i>
            <p>
              Are you ready to become a seller? By swiping, you will be providing
              your services and make yourself visible to others who are seeking
              your service.
            </p>
          </div>
        </div>
        <div
          className={`${this.state.defaultValues > 145 &&
            !!this.props.getAllDatas.seller_status
            ? "center-xy"
            : null
            } position-relative slide-status`}
        >
          <input
            type="range"
            value={
              this.state.defaultValues > 140 &&
                !!this.props.getAllDatas.seller_status
                ? this.state.defaultValues
                : this.state.withValue
            }
            min={0}
            max={150}
            className="pullee"
            ref={(el) => {
              this.inputRange = el;
            }}
            onChange={(e) =>
              this.setState({
                defaultValues: e.target.value,
                withValue: e.target.value,
              })} />
          <div className="swipe-input-values">
            <ul className="dropdown-list hover-list">
              {ListdataNewArray.map((item, i) => {
                return (
                  <li onClick={() => this.selectItem(item)} key={i} className={this.state.isStatusClicked && item.name === this.state.getStatus && this.state.getStatus === "Active Seller" ? 'disabled' : null}>
                    <a href="javascript:;" data-toggle={this.state.getStatus !== "Active Seller" ? "modal" : null}
                      data-target="#languageModal"
                      data-backdrop="static"
                      data-keyboard="false">
                      <span className="status-icon">
                        <img src={item.imageOne} alt="status" />
                      </span>
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default memo(SlidingButton);

