import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import moment, { now } from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../library/common/components";
import { useToasts } from "react-toast-notifications";

import Microlink from "@microlink/react";
import { searchProfileDataApi } from "../../library/api/SearchApiService";
import {
  GET_GROUP_USERS,
  IMAGE_BASE_URL,
  SOCKET,
  MEDIA_BASE_URL,
  ADMIN,
  GOOGLE_MEET_INTEGRATION,
} from "../../library/urls";
import {
  getCookie,
  isElement,
  replceMultiStringWithSIngle,
  setCookie,
  useForceUpdate,
} from "../../library/utilities/functions";
import {
  allFriendandGroupList,
  cancleEventRequestApi,
  changeChatDetails,
  changeShareAttachments,
  clearScheduleList,
  declineEventRequest,
  declineSchedule,
  getAllAvailabilityApi,
  getAllScheduleList,
  getSingleGroupApi,
  acceptEventRequestApi,
  acceptSchedule,
} from "./PoolsChatAction";
import { useHistory } from "react-router";
import { css } from "@emotion/core";
import { BarLoader, ClipLoader } from "react-spinners";
import {
  clearProfileResponse,
  clearUnfriendResponse,
} from "../SearchProfile/SearchProfileAction";
import { unreadUserMessages } from "../../library/common/components/Toolbar/ToolbarActions";

import { unfriendUserApi } from "../../library/api/SearchApiService";
import { Modal, Button, Form } from "react-bootstrap";
import {
  GET_CREATE_MEDIA,
  GROUP_DELETE_API,
  GROUP_UPDATE_API,
  GROUP_LEAVE_API,
  GROUP_ADD_USER,
} from "../../library/urls/index";

import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";

import { ListData } from "../Account/common/SellerStatusListData";
import { SiGooglemeet } from "react-icons/si";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import RequestModal from "./RequestModal";
import MediaBox from "./MediaBox";
import ViewAvailability from "./ViewAvailability";
import { EVENT_REQUEST_NAME } from "../../library/common/constants/NameConstant";

const override = css`
  display: block;
  margin: auto;
  border-radius: 40px !important;
  width: 100%;
  background: rgb(31, 141, 205);
  background: linear-gradient(
    90deg,
    rgba(31, 141, 205, 1) 0%,
    rgba(109, 57, 125, 1) 53%,
    rgba(187, 82, 138, 1) 100%
  );
`;

const overridePagination = css`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 47%;
`;

// VARIABLE TO STORE DATA OF MESSAGE LIST,CHAT LIST & GROUPLIST FROM API
let messageList = [];
let chatListApiList = [];
let groupListApiList = [];

let newChatList = [];
// CREATE OBJECT OF FRIEND MESSAGE STATUS WITH DEFAULT VALUES
var frdMesageStatus = {
  friend_id: "",
  online: { read: false, unread: true, reciever_id: "" },
  offline: false,
};

// ----------------SORT CHAT LIST ACCORDING TO NEW UNREAD MESSAGE-------------//
function sortByDate(a, b) {
  if (a.dateTimestamp > b.dateTimestamp) {
    return -1;
  }
  if (a.dateTimestamp < b.dateTimestamp) {
    return 1;
  }
  return 0;
}

//----------------This function is to remove Dublicate Frnd from friend list-------------//
const removeDublicateFrd = (chatList) => {
  chatList.forEach((data_outer, i) => {
    let count = 0;
    chatList.forEach((data_inner, j) => {
      if (data_inner.type == "user") {
        if (data_inner.user_id == data_outer.user_id) {
          count += 1;
          if (count > 1) {
            chatList.splice(j, 1);
          }
        }
      }
    });
  });
  return chatList;
};

let cookieData = 0;

//this function is to scroll down in inbox//
const scrollToBottom = () => {
  var div = document.getElementById("message-body-scrool");
  if (!!div) {
    div.childNodes[0].scroll({
      top: div.childNodes[0].scrollHeight,
      behavior: "smooth",
    });
  }
};

// VARIBALE TO STORE DATA OF CURRENT USER ID, BASEIMAGES, LINKS AND FILES
let currentUserId = "";
let allBaseImages = [];
let links = [];
let files = [];
let message_details = {};

const MessageBox = ({
  poolsChatState,
  searchProfileResponse,
  setMediaPage,
  mediaPage,
}) => {
  //refs//
  const requestModalRef = useRef();
  const viewAvailabilityModal = useRef();
  const openGroupProfile = useRef();
  const closeAddEl = useRef();

  // states//
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [sendOnlyPicture, setSendOnlyPicture] = useState(true);
  const [sendOnlyVideo, setSendOnlyVideo] = useState(true);
  const [sendOnlyDocs, setSendOnlyDocs] = useState(true);
  const [uploadImage, setUploadImage] = useState(false);
  const [myUrls, setUrls] = useState([]);
  const [image, setImage] = useState([]);
  const [previewImage, setPreviewImage] = useState();
  const [previewVideo, setPreviewVideo] = useState();
  const [previewDoc, setPreviewDoc] = useState();
  const [scheduleListPage, setScheduleListPage] = useState(1);
  const [editAppointent, setEditAppointment] = useState(false);
  const [uploadGroupImage, setUploadGroupImageUrl] = useState(null);
  const [uploadGroupImg, setUploadGroupImg] = useState("");
  const [showFrdListModal, setShowFrdListModal] = useState(false);
  const [addSelectedUserId, setAddSelectedUserId] = useState([]);
  const [unlink, setUnlink] = useState("");
  const [showNewList, setShowNewList] = useState([]);
  const [uplodImageData, setuplodImageData] = useState(0);
  const [grouopUserList, setGrouopUserList] = useState([]);
  const [buttonState, setButtonState] = useState(false);
  const [uploadDocumentFile, setUploadDocumentFile] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [eventSocketState, setEventSocketState] = useState(false);
  const [allFriendandGroup, setAllFriendAndGroup] = useState([]);
  const [requestData, setRequestData] = useState({
    date: new Date(),
    request_nature: "",
    description: "",
    reschedule: false,
    iscancel: false,
    title: "",
    is_accepted: false,
    is_declined: false,
  });

  const [requestCancleLoader, setRequestCancleLoader] = useState(false);
  const [requestActionLoading, setRequestActionLoading] = useState(false);
  const [storeEventDetails, setStoreEventDetails] = useState({});
  const [editGroupName, setEditGroupName] = useState("");
  const [noMoreMessage, setNoMoreMessage] = useState(false);

  const [currentOpenChat, setCurrentOpenChat] = useState({});

  const [isHaveMember, setIsHaveMember] = useState(false);
  const { profile_details } = searchProfileResponse;

  // hooks//
  const history = useHistory();
  const forceUpdate = useForceUpdate();

  // notifaction toast//
  const { addToast } = useToasts();

  // Redux//
  const dispatch = useDispatch();
  const toolbarState = useSelector((state) => state.ToolbarReducer);
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const searchState = useSelector((state) => state.SearchProfileReducer);
  const poolsChatData = useSelector((state) => state.PoolsChatReducer);
  const allChatList = useSelector((state) => state.chatListReducer);

  const { getChatandGroupList } = allChatList;

  const {
    friendListDetail: { friendList, groupList },
  } = networkState;
  const {
    unfriendApi: { unfriendStatus, unfriendMessage },
  } = searchState;

  const {
    chatDetails: {
      currenUserId,
      userMessage,
      completeMessageList,
      is_loading,
      is_scroll,
      typingUser,
      page,
      page_scroll,
      pagination_loading,
    },
    ShareAttachment: { mediaFile, mediaUrl, shareText, fileVisibility },
  } = poolsChatState;

  currentUserId = currenUserId;

  const { unreadUserMessageCount } = toolbarState;

  messageList = completeMessageList;
  chatListApiList = friendList;
  groupListApiList = groupList;

  // browser storage//
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  // get all friend and group list//
  useEffect(() => {
    if (getChatandGroupList?.length > 0) {
      setAllFriendAndGroup(getChatandGroupList);
    }
  }, [getChatandGroupList]);

  // store single chat details//
  useEffect(() => {
    if (!!allFriendandGroup) {
      allFriendandGroup.map((item) => {
        if (item.type === "user") {
          if (item.user_id === currenUserId) {
            message_details = item;
          }
        } else if (item.type === "group") {
          if (item.id === currenUserId) {
            message_details = item;
          }
        } else {
          message_details = {};
        }
      });
    }
  }, [allFriendandGroup, currenUserId]);

  //Authenicating user on open friend chat//

  const DetermineUser = () => {
    SOCKET.emit("authenticate_pools", {
      user_id: profileData?.id,
      reciever_id: currenUserId,
      socket_id: getCookie("socket_id"),
      type: !!getCookie("chatType") ? getCookie("chatType") : "",
    });
  };

  // FETCH ALL FRIEND LIST IN NEW STATE

  if (getChatandGroupList.length > 0) {
    newChatList = getChatandGroupList;
  }

  // WHEN USER TYPE  ON MESSAGE BAR//
  const handleChange = (e) => {
    links = [];

    dispatch(changeChatDetails({ userMessage: e.target.value }));
    e.target.value.trim().replace(/\s\s+/g, " ");
    let splStrg = e.target.value.split(" ");

    for (let i = 0; i < splStrg.length; i++) {
      if (
        splStrg[i].match(
          "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
        )
      ) {
        links.push(splStrg[i]);
      }
    }

    SOCKET.emit("typing", {
      user_id: profileData.id,
      typing_user: !!profileData.is_individual
        ? profileData.name
        : profileData.business_name,
      reciever_id: currenUserId,
      frds_acknowledged: 1,
      type: !!getCookie("chatType") ? getCookie("chatType") : "",
    });
  };
  //-------------- --------------/

  // Open request modal function //
  const handleOpenRequestModal = useCallback(
    (e, value, eventDetails = "", callFromMessage = "") => {
      if (callFromMessage !== "") {
        setStoreEventDetails(eventDetails);
        let parsedData = JSON.parse(eventDetails?.message);

        if (value === "edit") {
          setEditAppointment(true);
          setRequestData({
            ...requestData,
            iscancel: false,
            date: parsedData?.date,
            title: parsedData?.title,
            description: parsedData?.description,
            request_nature: parsedData?.request_nature,
            event_id: parsedData?.event_id,
            reschedule: true,
          });

          setMessageId(eventDetails?.message_id);
        } else {
          setEditAppointment(false);
          setRequestData({
            date: new Date(),
            request_nature: "",
            description: "",

            reschedule: false,
            iscancel: false,
            title: "",
            is_accepted: false,
            is_declined: false,
          });
        }
      } else {
        if (value === "edit") {
          setEditAppointment(true);
          setRequestData({
            ...requestData,
            iscancel: false,
            date: eventDetails?.date,
            title: eventDetails?.title,
            description: eventDetails?.description,
            request_nature: eventDetails?.request_nature,
            event_id: eventDetails?.event_id,
            is_accepted: false,
            is_declined: false,
          });
        } else if (value === "no-edit") {
          setEditAppointment(false);
          setRequestData({
            date: new Date(),
            request_nature: "",
            description: "",

            reschedule: false,
            iscancel: false,
            title: "",
            is_accepted: false,
            is_declined: false,
          });
        }
      }
    },
    []
  );

  // update event message function
  const updateEventMessage = (paramMessage) => {
    const updatedMessageList = messageList.map((item) => {
      // Check if the item's ID matches the valueId
      if (item.message_id === paramMessage?.message_id) {
        // If there's a match, update the item's data
        return {
          ...item, // Keep all existing properties

          message: JSON.stringify(paramMessage?.message),
        };
      } else {
        // If there's no match, keep the item unchanged
        return item;
      }
    });

    dispatch(changeChatDetails({ completeMessageList: updatedMessageList }));
  };

  // update receiver side event
  const updateEventReceiverEvent = (paramMessage) => {
    const updatedMessageList = messageList.map((item) => {
      // Check if the item's ID matches the valueId
      if (item.message_id === paramMessage?.message_id) {
        // If there's a match, update the item's data
        return {
          ...item, // Keep all existing properties

          message: paramMessage?.message,
        };
      } else {
        // If there's no match, keep the item unchanged
        return item;
      }
    });

    dispatch(changeChatDetails({ completeMessageList: updatedMessageList }));
  };

  // function to cancel event request //
  const handleCancelEventRequest = (e, data) => {
    setRequestCancleLoader(true);

    e.preventDefault();
    var frdMesageStatus = {
      friend_id: "",
      online: { read: false, unread: true, reciever_id: "" },
      offline: false,
    };

    let parsedData = JSON.parse(data?.message);
    parsedData.iscancel = true;
    var message = {
      session_id: getCookie("token_id"),
      message: JSON.stringify(parsedData),
      sender_name: !!profileData.is_individual
        ? profileData.name
        : profileData.business_name,
      device_token: null,
      created_at: new Date(),
      frds_acknowledged: 1,
      message_id: data?.message_id,
      event_id: parsedData.event_id,
      messageStatus: frdMesageStatus,
      sender_id: Number(profileData?.id),
      ignore_user_from_id: 1,
      ignore_user_to_id: 1,
      is_individual: profileData?.is_individual === 1 ? 1 : 0,
      is_business: profileData?.is_individual === 0 ? 1 : 0,
      type: message_details?.type,
      message_type: 3,
      isCancel: 1,
      for_cancel: true,
      reciever_id:
        message_details?.type === "user"
          ? history?.location?.pathname.includes("/search-profile")
            ? message_details?.id
            : message_details?.user_id
          : message_details?.id,
    };

    if (message_details?.type === "group") {
      message.group_id = message_details?.id;
    }

    // CASE :- if request is cancel//
    function isCancelSuccess(value) {
      // check status of acknowledgement//
      if (value?.status === 400) {
        addToast(value?.message, {
          appearance: "error",
          autoDismiss: true,
        });
        // setRequestActionLoading(false);
      } else if (value?.status === 200) {
        // if status 200 hit api  cancel request notification//
        dispatch(
          cancleEventRequestApi({
            event_id: parsedData?.event_id,
            notification_type: EVENT_REQUEST_NAME.CANCEL_EVENT_REQUEST,
            from: profileData?.id,
            to: currenUserId,
          })
        )
          .then((res) => {
            if (res.response.data.status === 200) {
              const updatedMessageList = messageList.map((item) => {
                // Check if the item's ID matches the valueId

                if (item.message_id === data?.message_id) {
                  // If there's a match, update the item's data
                  return {
                    ...item, // Keep all existing properties
                    isCancel: 1,
                    // Update the data you want to change
                    // For example, if you want to update the `data` property, you can do:
                    message: JSON.stringify(parsedData),
                  };
                } else {
                  // If there's no match, keep the item unchanged
                  return item;
                }
              });
              dispatch(
                changeChatDetails({ completeMessageList: updatedMessageList })
              );

              SOCKET.emit("get-schedule-events", {
                sender_id: profileData.id,
                reciever_id: data?.user_to_id,

                page: 1,
                action: true,
              });
              SOCKET.off("set-schedule-events").on(
                "set-schedule-events",
                (response) => {
                  //  setAllEventsList(response.list)
                  dispatch(getAllScheduleList(response));
                }
              );

              addToast("Request cancel successfull.", {
                appearance: "success",
                autoDismiss: true,
              });

              setRequestCancleLoader(false);
            }
          })
          .catch((err) => {
            console.log(err, "err");
            addToast("Request not cancelled try again.", {
              appearance: "success",
              autoDismiss: true,
            });
            setRequestCancleLoader(false);
          });
      }
    }

    // emit event of cancel with msg obj and callback//
    SOCKET.emit("cancel-schedule-event", message, isCancelSuccess);
    changEventstate();
  };

  useEffect(() => {
    if (!!unfriendStatus) {
      dispatch(clearUnfriendResponse());
    }
  }, [unfriendStatus]);

  // get group details if chat type is group //
  useEffect(() => {
    if (message_details) {
      if (message_details?.type === "group") {
        setEditGroupName(message_details?.title);

        dispatch(getSingleGroupApi(message_details?.id));
      }
    }
  }, [message_details]);

  const handleGroupTitle = (e) => {
    setEditGroupName(e);
  };

  const hideShowLinkDrop = (is_currentUserIdClick) => {
    const dropDown = document.getElementById("dropdown");
    if (dropDown && !is_currentUserIdClick) {
      if (dropDown.style.opacity == "1") {
        dropDown.style.opacity = 0;
        dropDown.style.visibility = "hidden";
      } else {
        dropDown.style.opacity = 1;
        dropDown.style.visibility = "visible";
      }
    }
    if (dropDown && is_currentUserIdClick) {
      dropDown.style.opacity = 0;
      dropDown.style.visibility = "hidden";
    }
  };

  useEffect(() => {
    try {
      dispatch(clearScheduleList([]));
      setScheduleListPage(1);
      SOCKET.emit("get-schedule-events", {
        sender_id: profileData.id,
        reciever_id: !!profile_details ? profile_details.id : currenUserId,
        page: 1,
        action: false,
      });
    } catch (error) {
      throw error;
    }
  }, [profile_details?.id]);

  useEffect(() => {
    if (!!currenUserId) {
      const is_currentUserIdClick = true;
      hideShowLinkDrop(is_currentUserIdClick);
      const dropDown = document.getElementById("dropdown");
      if (dropDown) dropDown.classList.remove("profile-action-active");
    }
  }, [currenUserId]);

  // WHEN USER CLICK ON 3 DOTS
  const handleDotAction = (event) => {
    hideShowLinkDrop();
    const dropDown = document.getElementById("dropdown");
    dropDown.classList.toggle("profile-action-active");
  };

  useEffect(() => {
    $("body").click(function (event) {
      const chatListElm = event.target.offsetParent;
      const dropDown = document.getElementById("dropdown");
      if (
        !!chatListElm &&
        !chatListElm.className.match("chat__listing position-relative") &&
        !event.target.className.match("dot-icon") &&
        !!dropDown
      ) {
        dropDown.style.opacity = 0;
        dropDown.style.visibility = "hidden";
      }
    });
  }, []);

  // save message details of current user in object

  //this function is rendered when sumbit message.For submit message emit the socket//
  const handleSumbitMessage = (e) => {
    links = [];
    e.preventDefault();
    if (!!replceMultiStringWithSIngle(userMessage)) {
      var message = {
        session_id: getCookie("token_id"),
        message: replceMultiStringWithSIngle(userMessage),
        sender_name: !!profileData.is_individual
          ? profileData.name
          : profileData.business_name,
        device_token: null,
        created_at: new Date(),
        frds_acknowledged: 1,
        message_id: uuidv4(),
        messageStatus: frdMesageStatus,
        sender_id: Number(profileData.id),
        ignore_user_from_id: 1,
        ignore_user_to_id: 1,
        type: !!message_details ? message_details.type : null,
        is_individual: profileData.is_individual,
        is_business: profileData.is_business,
        message_type: 1,
      };

      message.reciever_id =
        message_details.type === "user"
          ? message_details.user_id
          : message_details.id;

      if (message_details.type === "group") {
        message.group_id = message_details
          ? message_details.id
          : Number(currenUserId);
      }
      SOCKET.emit("send_message", message);

      SOCKET.emit("set_count_unread_message", {
        senderID: message_details?.type == "user" ? profileData?.id : null,
        receiverID:
          message_details?.type == "user"
            ? message_details.user_id
            : message_details.id,
        type: message_details?.type,
      });

      if (message_details.type === "user") {
        const lastMessage = {
          user_id: profileData.id,
          frd_id: Number(currenUserId),
          message: replceMultiStringWithSIngle(userMessage),
        };
        SOCKET.emit("set_last_message", lastMessage);
      }

      if (message_details.type === "group") {
        const lastMessageGroup = {
          user_id: profileData.id,
          message: replceMultiStringWithSIngle(userMessage),
          grp_id: Number(currenUserId),
          sender_name: profileData.business_name
            ? profileData.business_name
            : profileData.name,
        };
      }

      dispatch(changeChatDetails({ userMessage: "" }));
    }
  };

  // --- to send google meet request --- //
  const handleStartMeeting = async (messageDetails) => {
    if (
      profileData?.refresh_token == null ||
      profileData?.refresh_token == "null" ||
      profileData?.refresh_token == ""
    ) {
      try {
        let config = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getCookie("token_id"),
          },
        };
        await axios(
          `${GOOGLE_MEET_INTEGRATION}?user_id=${profileData?.id}`,
          config
        )
          .then((response) => {
            if (response?.data?.data)
              window.open(response?.data?.data.auth_url, "_blank");
          })
          .catch((err) => {
            addToast(err, {
              appearance: "error",
              autoDismiss: true,
            });
          });
      } catch (error) {
        addToast(error, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      var message = {
        session_id: getCookie("token_id"),
        message: "",
        sender_name: !!profileData.is_individual
          ? profileData.name
          : profileData.business_name,
        device_token: null,
        created_at: new Date(),
        messageStatus: frdMesageStatus,
        frds_acknowledged: 1,
        message_id: uuidv4(),
        messageStatus: frdMesageStatus,
        sender_id: Number(profileData.id),
        ignore_user_from_id: 1,
        ignore_user_to_id: 1,
        type: !!message_details ? message_details.type : null,
        is_individual: profileData?.is_individual,
        is_business: profileData?.is_business,
        refresh_token: profileData.refresh_token,
        message_type: 1,
      };

      message.reciever_id =
        messageDetails.type === "user"
          ? messageDetails.user_id
          : messageDetails.id;

      if (messageDetails.type === "group") {
        message.group_id = messageDetails
          ? messageDetails.id
          : Number(currenUserId);
      }

      SOCKET.emit("google_meet", message);
    }

    SOCKET.off("google_auth_error").on(
      "google_auth_error",
      async (response) => {
        if (response) {
          try {
            let config = {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + getCookie("token_id"),
              },
            };
            await axios(
              `${GOOGLE_MEET_INTEGRATION}?user_id=${profileData?.id}`,
              config
            )
              .then((response) => {
                if (response?.data?.data)
                  window.open(response?.data?.data.auth_url, "_blank");
              })
              .catch((err) => {
                addToast(err, {
                  appearance: "error",
                  autoDismiss: true,
                });
              });
          } catch (error) {
            addToast(error, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      }
    );
  };

  useEffect(() => {
    if (!!page_scroll && completeMessageList?.length <= 12) {
      window.setTimeout(() => {
        scrollToBottom();
      }, 800);
    }
  }, [completeMessageList]);

  //------ GO TO USER PROFILE ------//
  const handleUserProfile = () => {
    if (!message_details?.user_deleted && message_details.type !== "group") {
      // history.push(
      //   `/search-profile/${message_details.user_id}?individual=${
      //     !!message_details?.is_individual ? true : false
      //   }`
      // );
      history.push(`/search-profile/${message_details.user_id}`);
    }
  };

  const handleViewAvailability = () => {
    if (currenUserId !== undefined && currenUserId !== "") {
      try {
        dispatch(getAllAvailabilityApi(currenUserId));
      } catch (err) {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  // FUNCTION USE TO CHECK PROFILE OF GROUP USER
  const handleGroupProfileUser = () => {
    let config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
    };
  };

  useEffect(() => {
    if (
      message_details?.id &&
      currenUserId &&
      message_details?.type == "group"
    ) {
      let config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
      };

      fetch(GET_GROUP_USERS + "/" + currenUserId, config)
        .then((response) => {
          response.json().then((data) => {
            setGrouopUserList(data.data);
          });
        })
        .catch((err) => ({
          data: { error: true, message: "Internal Server Error" },
          status: 500,
        }));
    }
  }, [currenUserId]);

  //this function is rendered when the user scrolling in the message box .
  const handleScroolMessage = (response) => {
    if (response.top == 0) {
      if (noMoreMessage == false) {
        setNoMoreMessage(false);
        dispatch(changeChatDetails({ page: page + 1 }));
        if (page_scroll) {
          let pagination_detail = {
            page_scroll: false,
            pagination_loading: true,
          };

          dispatch(changeChatDetails(pagination_detail));
          let get_messages_pagination = {
            sender_id: profileData?.id,
            reciever_id: currenUserId,
            page: page + 1,
            type: !!getCookie("chatType") ? getCookie("chatType") : "",
          };

          SOCKET.emit("get_message_pagination", get_messages_pagination);
        }
      }
    }
  };

  // CALL WHEN USER CLICK ON CHAT PATH//
  useEffect(() => {
    const friendId = !!getCookie("friendId")
      ? Number(getCookie("friendId"))
      : null;

    // WHEN USER LOGIN AND CLICK ON CHAT COMPONENT THIS SOCKET IS CALLED

    // SEND MY MESSAGE STATUS WHEN ON MY PAGE CHANGE
    SOCKET.emit("send_my_message_status", {
      sender_id: profileData.id,
      reciever_id: null,
    });

    // STORE OLD FRIEND ID  FROM COOKIES
    const oldCookieData = !!getCookie("oldFriendId")
      ? getCookie("oldFriendId")
      : !!getCookie("friendId")
      ? Number(getCookie("friendId"))
      : 0;

    if (!!oldCookieData) {
      SOCKET.emit("send_my_frd_i_am_on_another_chat", {
        reciever_id: oldCookieData,
        sessionId: getCookie("token_id"),
        sender_id: profileData.id,
      });
    }
  }, []);

  useEffect(() => {
    // IF USER OPEN HIS FRIEND CHAT //
    if (!!currenUserId) {
      cookieData = !!getCookie("friendId") ? Number(getCookie("friendId")) : 0;
      setCookie("oldFriendId", cookieData, 1);

      // IF OLD_FRIEND ID CALL THIS SOCKET
      if (cookieData) {
        SOCKET.emit("send_my_frd_i_am_on_another_chat", {
          reciever_id: cookieData,
          sessionId: getCookie("token_id"),
          sender_id: profileData.id,
        });
      }
      setCookie("friendId", currenUserId, 1);

      dispatch(changeChatDetails({ is_loading: true }));

      DetermineUser();

      const friendId = !!getCookie("friendId")
        ? Number(getCookie("friendId"))
        : null;

      // show to my frds ----> my-online + read/unread on click
      SOCKET.emit("send_my_message_status", {
        sender_id: profileData.id,
        reciever_id: message_details?.user_id,
      });

      // get my frd  ------> his online (read/unread)/offline on click
      SOCKET.emit("check_receiver_message_status", {
        reciever_id: message_details.user_id,
        sessionId: getCookie("token_id"),
        sender_id: profileData.id,
      });
    }
  }, [currentUserId]);

  useEffect(() => {
    newChatList = getChatandGroupList;
  }, [window.location.pathname == "/contact-us"]);

  useEffect(() => {
    //----------- REMOVE UNREAD MESSAGE COUNT FROM TOOLBAR ----------//
    SOCKET.off("remove_unread_message_count").on(
      "remove_unread_message_count",
      (unreadData) => {
        // newChatList = getChatandGroupList;

        if (unreadData) {
          let chat_List = newChatList;

          if (profileData.id == unreadData.user_id) {
            for (let j in chat_List) {
              if (unreadData.frnd_id == chat_List[j].user_id) {
                chat_List[j].counts = 0;
              }
            }

            dispatch(allFriendandGroupList(chat_List));
          }
        }
      }
    );
    // ------------REMOVE UNREAD MESSAGE COUNT FROM TOOLBAR END ------------------//
  }, [currenUserId]);

  useEffect(() => {
    // CALL WHEN USER OPEN MESSAGE CHAT
    const friendId = !!getCookie("friendId")
      ? Number(getCookie("friendId"))
      : null;

    const oldCookieData = !!getCookie("oldFriendId")
      ? Number(getCookie("oldFriendId"))
      : null;
    // IF OLD_FRIEND ID CALL THIS SOCKET
    if (!!oldCookieData) {
      SOCKET.emit("send_my_frd_i_am_on_another_chat", {
        reciever_id: oldCookieData,
        sessionId: getCookie("token_id"),
        sender_id: profileData.id,
      });
    }

    if (!!oldCookieData) {
      SOCKET.emit("message_read_unread_status", {
        session_id: getCookie("token_id"),
        receiver_id: oldCookieData,
        is_read: 0,
        sender_id: !!profileData ? profileData.id : "",
      });
    }

    //get all messages only one time
    SOCKET.off("getMessage").on("getMessage", (messages) => {
      // only one time

      setNoMoreMessage(false);
      let getMessage = {
        is_loading: false,
        completeMessageList: messages.message_list,
        page: 1,
      };

      dispatch(changeChatDetails(getMessage));
      // UNREAD MESSAGE COUNT NOTIFICATIOS //

      if (unreadUserMessageCount.length > 0) {
        unreadUserMessageCount.map((item, index) => {
          if (
            item?.id == message_details?.user_id &&
            message_details?.type == "user"
          ) {
            unreadUserMessageCount.splice(index, 1);
          }
          if (
            item.id == message_details.id &&
            message_details.type == "group"
          ) {
            unreadUserMessageCount.splice(index, 1);
          }
        });
      }

      dispatch(
        unreadUserMessages({ unreadUserMessageCount: unreadUserMessageCount })
      );

      // GET UNREAD MESSAGE OF FRNDS ON REALOAD AND LOGIN //

      SOCKET.emit("get_unread_frd_messages", {
        user_id: profileData.id,
        check_user: profileData.id,
        toMe: true,
      });

      SOCKET.emit("get_all_unread_group_messages_for_login_user", {
        user_id: profileData.id,
        grp_id: currentUserId,
        toMe: true,
      });

      // if (!message_details.user_id==176) {
      SOCKET.emit("remove_unread_message_count", {
        user_id: profileData.id,
        frnd_id: message_details.user_id,
      });
      // }
    });

    // GET UNREAD MESSAGE OF GROUP FOR MYSELF

    //get messages with pagination
    SOCKET.off("get_message_pagination").on(
      "get_message_pagination",
      function (data) {
        let newList = [];
        setNoMoreMessage(false);

        const completeMessageList = messageList;
        const newPageList = data.message_list;
        newList = [...newPageList, ...completeMessageList];
        if (data.message_list.length <= 0) {
          let addedMessage = {
            completeMessageList: newList,
            pagination_loading: false,
            page_scroll: true,
          };
          dispatch(changeChatDetails(addedMessage));
          setNoMoreMessage(true);
        }

        let addedMessage = {
          completeMessageList: newList,
          pagination_loading: false,
          page_scroll: true,
        };
        dispatch(changeChatDetails(addedMessage));
        messageList = newList;
        var div = document.getElementById("message-body-scrool");
        if (!!div) {
          div.childNodes[0].scroll({ top: 10, behavior: "smooth" });
        }
      }
    );

    // Checking the typing user
    SOCKET.off("typing").on("typing", (typing) => {
      if (!!typing) {
        if (typing.type === "group") {
          if (typing.reciever_id == currentUserId) {
            dispatch(changeChatDetails({ typingUser: typing.typing_user }));
            window.setTimeout(() => {
              dispatch(changeChatDetails({ typingUser: "" }));
            }, 1500);
          }
        }

        if (typing.type === "user") {
          if (
            (typing.user_id == profileData.id &&
              typing.reciever_id == currentUserId) ||
            (typing.user_id == currentUserId &&
              typing.reciever_id == profileData.id)
          ) {
            if (typing.user_id !== profileData.id) {
              dispatch(changeChatDetails({ typingUser: typing.typing_user }));
              window.setTimeout(() => {
                dispatch(changeChatDetails({ typingUser: "" }));
              }, 1500);
            }
          }
        }
      }
    });

    //append message in message list when send new message
    SOCKET.off("message_data").on("message_data", (messages) => {
      if (!!messages) {
        // set message read ----> //
        if (currenUserId == messages.obj.user_from_id) {
          if (
            window.location.pathname == "/chat" ||
            window.location.pathname == "/contact-us"
          ) {
            if (messages.obj?.type === "user") {
              SOCKET.emit("set_message_is_read", {
                senderID: messages.obj.user_from_id,
                receiverID: messages.obj.user_to_id,
              });
            }
          }
        }

        if (
          (!messages.obj.is_acknowledge &&
            message_details.id == messages.obj.user_to_id) ||
          messages.obj.is_acknowledge
        ) {
          let messagesList = messageList;
          // MESSAGE SEND TO SINGLE USER//

          if (messages.obj?.user_to_id && messages.obj?.type === "user") {
            if (
              (messages.obj.user_from_id == profileData.id &&
                messages.obj.user_to_id == message_details?.user_id) ||
              (messages.obj.user_from_id == currentUserId &&
                messages.obj.user_to_id == profileData.id)
            ) {
              messagesList.push(messages.obj);

              dispatch(
                changeChatDetails({ completeMessageList: messagesList })
              );

              scrollToBottom();

              SOCKET.emit("ping_frd_my_unread_message", {
                user_id: currentUserId,
                toMe: false,
              });
              // on send message
              SOCKET.emit("get_unread_frd_messages", {
                user_id: message_details?.user_id,
                check_user: message_details?.user_id,
                toMe: false,
              });
            }
          }

          // MESSAGE SENDS IN GROUP
          if (messages.obj.group_id && messages.obj.type === "group") {
            if (messages.obj.group_id == message_details?.id) {
              messagesList.push(messages.obj);
              dispatch(
                changeChatDetails({ completeMessageList: messagesList })
              );

              scrollToBottom();

              // EMIT READ MESSAGE OF GROUP ON CLICK ---> USED TO UPDATE READ STATUS OF CURRENT GROUP//
              SOCKET.emit("read_group_messsages_by_user_id", {
                user_id: profileData.id,
                group_id: currenUserId,
              });

              SOCKET.emit("ping_frd_my_unread_message", {
                user_id: currentUserId,
                toMe: false,
              });

              // EMIT FOR FRIEND WHEN SEND MESSAGE
              if (!!currentUserId && profileData.id) {
                SOCKET.emit("get_all_unread_group_messages", {
                  user_id: profileData.id,
                  grp_id: currentUserId,
                  toMe: false,
                });
              }
            }
          }
        } else {
          if (profileData.id == messages.obj.user_from_id) {
            alert(
              "Your friend has not acknowledged your Message ,Please wait for response"
            );
          }
        }
      }
    });

    // LISTEN CHECK RECEIVER MESG STATUS
    SOCKET.off("check_receiver_message_status").on(
      "check_receiver_message_status",
      function (list) {
        if (list.friend_id == message_details?.user_id) {
          if (list.offline) {
            list.online.read = false;
            list.online.unread = false;
            list.online.reciever_id = "";
          }
          if (list.online) {
            list.online.read =
              list.online.reciever_id == profileData.id ? true : false;
            list.online.unread =
              list.online.reciever_id == profileData.id ? false : true;
          }

          frdMesageStatus = list;
        }

        let apiData = messageList;

        if (list.online.read) {
          for (let i in apiData) {
            if (!apiData[i].message_is_read) {
              apiData[i].message_is_read = 1;
              apiData[i].message_is_sent = 0;
              apiData[i].message_is_not_seen = 0;
            }
          }
        }
        if (list.online.unread) {
          for (let i in apiData) {
            if (apiData[i].message_is_sent) {
              apiData[i].message_is_read = 0;
              apiData[i].message_is_sent = 0;
              apiData[i].message_is_not_seen = 1;
            }
          }
        }

        dispatch(changeChatDetails({ completeMessageList: apiData }));
      }
    );

    // SEND I AM ON ANOTHER CHAT
    SOCKET.off("send_my_frd_i_am_on_another_chat").on(
      "send_my_frd_i_am_on_another_chat",
      function (list) {
        if (list.online.reciever_id == currentUserId) {
          frdMesageStatus = list;
          let apiData = messageList;
          if (list.online.read) {
            for (let i in apiData) {
              if (!apiData[i].message_is_read) {
                apiData[i].message_is_read = 1;
                apiData[i].message_is_sent = 0;
                apiData[i].message_is_not_seen = 0;
              }
            }
          }
          if (list.online.unread) {
            for (let i in apiData) {
              if (apiData[i].message_is_sent) {
                apiData[i].message_is_read = 0;
                apiData[i].message_is_sent = 0;
                apiData[i].message_is_not_seen = 1;
              }
            }
          }
          dispatch(changeChatDetails({ completeMessageList: apiData }));
        }
      }
    );

    // ---------------------GET UNREAD GROUP MESSAGE ON RELOAD PAGE----------------//
    if (currenUserId) {
      SOCKET.off("get_unread_frd_messages").on(
        "get_unread_frd_messages",
        (unreadData) => {
          if (!!unreadData) {
            let total_unread_frds = 0;

            // let call_future = store.getState().auth.futureCall
            unreadData.unreadMessagesList;
            if (unreadData.check_user == profileData.id) {
              let chat_List = newChatList;
              let unreadList = unreadData.unreadMessagesList;

              for (let i in unreadList) {
                total_unread_frds += unreadList[i].counts > 0 ? 1 : 0;
                for (let j in chat_List) {
                  if (unreadList[i].friend_id == chat_List[j].user_id) {
                    chat_List[j].counts = unreadList[i].counts;
                    chat_List[j].dateTimestamp = unreadList[i]?.created_at
                      ? unreadList[i].created_at
                      : 0;
                  }
                }
              }

              for (let i in chat_List) {
                chat_List[i].dateTimestamp = !!chat_List[i]?.dateTimestamp
                  ? chat_List[i]?.dateTimestamp
                  : chat_List[i]?.created_at;
              }
              if (!!chat_List?.length) {
                chat_List.sort(sortByDate);
              }

              dispatch(allFriendandGroupList(chat_List));
            }
          }
        }
      );

      SOCKET.off("get_all_unread_group_messages_for_login_user").on(
        "get_all_unread_group_messages_for_login_user",
        () => {
          SOCKET.emit("fetch_all_unread_group_messages", {
            user_id: profileData.id,
          });

          SOCKET.off("fetch_all_unread_group_messages").on(
            "fetch_all_unread_group_messages",
            (unreadGroupList) => {
              let group_List = newChatList;

              for (let i in unreadGroupList) {
                for (let j in group_List) {
                  if (
                    unreadGroupList[i].group_id == group_List[j].id &&
                    unreadGroupList[i].user_id == profileData.id &&
                    unreadGroupList[i].group_id !== currenUserId
                  ) {
                    group_List[j].counts = unreadGroupList[i].count;
                    group_List[j].dateTimestamp = unreadGroupList[i].created_at;
                  }
                }
              }

              group_List.sort(sortByDate);
              dispatch(allFriendandGroupList(group_List));
            }
          );
        }
      );

      SOCKET.off("get_all_unread_group_messages").on(
        "get_all_unread_group_messages",
        () => {
          SOCKET.emit("fetch_all_unread_group_messages", {
            user_id: profileData.id,
          });
          SOCKET.off("fetch_all_unread_group_messages").on(
            "fetch_all_unread_group_messages",
            (unreadGroupData) => {
              let group_List = newChatList;

              for (let i in unreadGroupData) {
                for (let j in group_List) {
                  if (
                    unreadGroupData[i].group_id == group_List[j].id &&
                    unreadGroupData[i].user_id == profileData.id &&
                    unreadGroupData[i].group_id !== currenUserId
                  ) {
                    group_List[j].counts = unreadGroupData[i].count;
                    group_List[j].dateTimestamp = unreadGroupData[i].created_at;
                  }
                }
              }
              group_List.sort(sortByDate);
              dispatch(allFriendandGroupList(group_List));
            }
          );
        }
      );
    }

    // --------REMOVE FRIEND SOCKET-----------//
    SOCKET.off("remove_friend").on("remove_friend", function (data) {
      for (let i in newChatList) {
        if (newChatList[i].user_id == data.user_id) {
          newChatList.splice(i, 1);
          forceUpdate();

          dispatch(changeChatDetails({ currenUserId: "" }));
          SOCKET.emit("get_friend_and_group_list", {
            u_id: profileData?.id,
            socket_id: SOCKET.id,
          });

          SOCKET.off("get_friend_and_group_list").on(
            "get_friend_and_group_list",
            (chatList) => {
              dispatch(allFriendandGroupList(chatList));
            }
          );

          SOCKET.emit("get_all_unread_group_messages", {
            user_id: profileData.id,
            grp_id: currenUserId,
            toMe: true,
          });
        }
      }

      let vipIds = document.querySelectorAll(".chat__listing");
      for (let i in vipIds) {
        if (isElement(vipIds[i])) {
          vipIds[i].classList.remove("active");
        }
      }
      if (profileData.id === data.user_id) {
        addToast("You Unfriend" + " " + data.receiver_name, {
          appearance: "info",
          autoDismiss: true,
          // autoDismissTimeout: 3000,
        });
      }
    });
    // --------REMOVE FRIEND SOCKET END-----------//
  }, [currenUserId]);

  // ---------CLOSE ALL MODAL ---------//
  const handleAllCloseButton = () => {
    setUploadImage(false);
    setSendOnlyDocs(true);
    setSendOnlyVideo(true);
    setSendOnlyPicture(true);
    setImage(null);
    setUrls([]);

    let clearAttachment = {
      shareText: "",
      mediaFile: [],
      mediaUrl: [],
    };
    dispatch(
      changeShareAttachments({
        ...poolsChatState.SharePostInput,
        ...clearAttachment,
      })
    );
    (allBaseImages = []), (files = []);
  };
  // ---------CLOSE ALL MODAL END ---------//

  //this function is used when the application opens on a mobile phone then used the back button for clearing all data//
  const handleSendImages = () => {
    if (mediaFile) {
      const data = new FormData();
      mediaFile.forEach((tag) => data.append("document[]", tag));
      data.append("document_type", "media");
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      setButtonState(true);

      axios(GET_CREATE_MEDIA, config).then((response) => {
        if (response.status == 200) {
          var msg = {};

          if (response.data.data) {
            for (let i = 0; i < response.data.data.length; i++) {
              msg.fileName = response.data.data[i].file_name;
              msg.session_id = getCookie("token_id");
              if (message_details?.type === "user") {
                msg.reciever_id = message_details
                  ? message_details.user_id
                  : Number(currenUserId);
              }
              if (message_details.type === "group") {
                msg.group_id = message_details
                  ? message_details?.id
                  : Number(currenUserId);
              }
              msg.sender_name = !!profileData.is_individual
                ? profileData.name
                : profileData.business_name;
              msg.message = null;
              msg.message_id = uuidv4();
              msg.frds_acknowledged = 1;
              msg.sender_id = Number(profileData.id);
              msg.ignore_user_from_id = 1;
              msg.ignore_user_to_id = 1;
              msg.created_at = new Date();
              msg.type = !!message_details ? message_details.type : null;
              msg.message_type = 2;
              SOCKET.emit("send_media", msg);

              msg = {};
            }
          }
          let clearAttachment = {
            shareText: "",
            mediaFile: [],
            mediaUrl: [],
          };
          dispatch(
            changeShareAttachments({
              ...poolsChatState.SharePostInput,
              ...clearAttachment,
            })
          );
          (allBaseImages = []), (files = []);

          setUrls([]);
          setButtonState(false);
          setUploadImage(false);
          setSendOnlyDocs(true);
          setSendOnlyVideo(true);
          setImage(null);
        } else {
          addToast("Something went wrong. Try again!", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 6000,
          });
          setButtonState(false);
          setUploadImage(false);
        }
      });
    }
  };

  useEffect(() => {
    if (uplodImageData === 1) {
      addToast("You can upload maximum 5 items", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 6000,
      });
      setuplodImageData(0);
    }
  }, [uplodImageData]);

  const handleSendAll = () => {
    if (mediaFile.length > 0 || mediaUrl.length > 0) {
      handleSendImages();
    } else {
      addToast("Please Select Media!", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 6000,
      });
    }
  };

  const handleBackChat = () => {
    const removeClass = document.querySelector(".chat-open");
    const removeToggleClass = document.querySelector(".chat-toggle");

    if (removeClass || removeToggleClass) {
      removeClass.classList.remove("chat-open");
      removeToggleClass.classList.remove("chat-toggle");
    }

    dispatch(changeChatDetails({ currenUserId: "" }));
    dispatch(clearProfileResponse());
  };

  //----this function is used to unfriend the user----//
  const handleUnfriend = () => {
    let vipIds = document.querySelectorAll(".chat__listing");
    for (let i in vipIds) {
      if (isElement(vipIds[i])) {
        vipIds[i].classList.remove("active");
      }
    }
    const bodyParameter = {
      id: profile_details.id,
    };
    dispatch(unfriendUserApi(bodyParameter));
    for (let i in newChatList) {
      if (newChatList[i].user_id == currenUserId) {
        newChatList.splice(i, 1);

        dispatch(allFriendandGroupList(newChatList));

        SOCKET.emit("get_friend_and_group_list", {
          u_id: profileData?.id,
          socket_id: SOCKET.id,
        });
      }
    }
    dispatch(changeChatDetails({ currenUserId: "" }));

    SOCKET.emit("remove_friend", {
      user_id: profileData.id,
      sender_name: !!profileData.is_individual
        ? profileData.name
        : profileData.business_name,
      reciever_id: profile_details.id,
      receiver_name: profile_details.is_individual
        ? profile_details.name
        : profile_details.business_name,
    });
  };
  //---- handle unfriend user end----//

  //Send file,image and document code
  const hiddenFileInput = React.useRef(null);
  const handleVideoInput = React.useRef(null);
  const handleDocumentInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // SUBMIT VIDEO
  const handleVideoSubmit = (event) => {
    handleVideoInput.current.click();
  };

  // SUBMIT DOCUMENT
  const handleDocumentSubmit = (event) => {
    handleDocumentInput.current.click();
  };

  // REMOVE FILE
  const removeFile = (file) => {
    mediaUrl.splice(file, 1);
    dispatch(changeShareAttachments({ mediaUrl: mediaUrl }));
    for (let i in mediaFile) {
      if (i == file) {
        mediaFile.splice(i, 1);
        dispatch(changeShareAttachments({ mediaFile: mediaFile }));
      }
    }

    if (file === uploadDocumentFile) {
      setUploadDocument("");
      setUploadDocumentFile(null);
    }
  };

  // get the user seller status//
  useEffect(() => {
    ListData.map((item, i) => {
      if (
        item.name == profile_details?.seller_status &&
        profile_details?.seller_status != "Custom"
      ) {
        setIcon(item.imageOne);
        setColor(item.color);
      } else if (profile_details?.seller_status == "Custom") {
        setIcon(profile_details.status_image);

        setColor(item.color);
      }
    });
  }, [profile_details]);

  // ------------ TO SEND VIDEO IN CHAT ---------//
  const handleVideoFileChange = (e) => {
    var data = e.target.files[0];
    if (!!data) {
      const fileName = data.name.split(".");
      const imageFormat = fileName[fileName.length - 1];

      if (
        imageFormat === "mp4" ||
        imageFormat === "MP4" ||
        imageFormat === "mov" ||
        imageFormat === "MOV" ||
        imageFormat === "webm" ||
        imageFormat === "WEBM"
      ) {
        if (data.size < 10485760) {
          files.push(...e.target.files);

          dispatch(changeShareAttachments({ mediaFile: files }));
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            allBaseImages.push(reader.result);
            dispatch(changeShareAttachments({ mediaUrl: allBaseImages }));
          });
          reader.readAsDataURL(e.target.files[0]);
        } else {
          addToast("Maximum upload video limit is 10 mb", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 6000,
          });
        }
      } else {
        addToast("Please , Select the video", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 6000,
        });
      }
    }
  };

  //this function is to send Document in chat //
  const handleDocumentChange = (e) => {
    var dat = [...e.target.files];

    for (var i = 0; i < dat.length; i++) {
      const docUpload = e.target.files[i];
      if (docUpload) {
        const docFileName = docUpload.name.split(".");
        const docFormat = docFileName[docFileName.length - 1];
        if (
          docFormat === "docx" ||
          docFormat === "ppt" ||
          docFormat === "doc" ||
          docFormat === "pdf" ||
          docFormat === "pptx" ||
          docFormat === "xls" ||
          docFormat === "csv" ||
          docFormat === "xlsx"
        ) {
          if (dat.length + mediaUrl.length < 6) {
            files.push(e.target.files[i]);
          } else {
          }

          dispatch(changeShareAttachments({ mediaFile: files }));

          if (dat.length + mediaUrl.length < 6) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              allBaseImages.push(docUpload.name);
              dispatch(changeShareAttachments({ mediaUrl: allBaseImages }));
            });
            reader.readAsDataURL(e.target.files[i]);
          } else {
            setuplodImageData(1);
          }
        } else {
          addToast("Please , Select the Document", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 6000,
          });
        }
      }
    }
  };

  // Used on Select Image //
  const handleChangeFile = (e) => {
    var selectedFile = [...e.target.files];

    for (var i = 0; i < selectedFile.length; i++) {
      const data = e.target.files[i];

      if (!!data) {
        // split name and image extension//

        const fileName = data.name.split(".");
        const imageFormat = fileName[fileName.length - 1];
        const imageExtensions = ["png", "jpg", "JPG", "jpeg", "JPEG"];
        if (imageExtensions.includes(imageFormat)) {
          if (selectedFile.length + mediaUrl.length < 6) {
            files.push(e.target.files[i]);
          }

          dispatch(changeShareAttachments({ mediaFile: files }));

          // using inform with base64
          if (selectedFile.length + mediaUrl.length < 6) {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
              allBaseImages.push(reader.result);

              dispatch(changeShareAttachments({ mediaUrl: allBaseImages }));
            });
            reader.readAsDataURL(e.target.files[i]);
          } else {
            setuplodImageData(1);
          }
        } else {
          addToast("Only .png, .jpg, .jpeg image formats supported.", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 6000,
          });
        }
      }
    }
  };

  // EDIT GROUP
  const handleGroupEdit = () => {
    const data = new FormData();
    data.append("id", Number(currenUserId));
    data.append("group_image", uploadGroupImg);
    data.append("title", editGroupName);
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };

    axios(GROUP_UPDATE_API, config)
      .then((response) => {
        if (editGroupName !== "" || uploadGroupImg !== "") {
          if (response.data.success === true) {
            $("#modal-group-profile").modal("hide");
            addToast(response.data.message, {
              appearance: "success",
              autoDismiss: true,
            });

            setUploadGroupImg("");
            setEditGroupName("");
            setUploadGroupImageUrl(null);

            window.location.reload("/chat");
          } else {
            addToast(response.data.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        } else {
          addToast("Please add group name", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  // CHANGE PROFILE OF GROUP
  const handleGroupProfileChange = (e) => {
    const uploadImg = e.target.files[0];
    if (!!uploadImg) {
      const imageSplit = uploadImg.name.split(".");
      const imageFormat = imageSplit[imageSplit.length - 1];
      if (
        imageFormat === "png" ||
        imageFormat === "jpg" ||
        imageFormat === "jpeg" ||
        imageFormat === "PNG" ||
        imageFormat === "JPG" ||
        imageFormat === "JPEG"
      ) {
        setUploadGroupImageUrl(URL.createObjectURL(e.target.files[0]));
        setUploadGroupImg(e.target.files[0]);
      } else {
        addToast("Only .png, .jpg, .jpeg image formats supported", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  const handleUserAdd = () => {
    setShowFrdListModal(!showFrdListModal);
  };

  //this fuction is to get date and day from completeMessageList
  function groupedDays(messages) {
    return messages.reduce((acc, el, i) => {
      const messageDay = moment(el.created_at).format("YYYY-MM-DD");

      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  }

  // // ---accept event request handler ---//
  const handleAcceptEventRequst = (e, params) => {
    setRequestActionLoading(true);
    let parsedData = JSON.parse(params?.message);
    parsedData["is_accepted"] = true;
    let msgObj = { ...params, message: parsedData };

    dispatch(
      acceptEventRequestApi({
        event_id: parsedData?.event_id,
        notification_type: EVENT_REQUEST_NAME.ACCEPT_EVENT_REQUEST,
        from: params?.user_from_id,
        to: params?.user_to_id,
      })
    )
      .then((res) => {
        addToast("Request accepted successful.", {
          appearance: "success",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });

        SOCKET.emit("event-accept-decline", msgObj);
        setRequestActionLoading(false);
        updateEventMessage(msgObj);
        if (res.response.data.status === 200) {
          SOCKET.emit("get-schedule-events", {
            sender_id: profileData.id,
            reciever_id: params?.user_from_id,
            page: 1,
            action: true,
          });

          SOCKET.off("set-schedule-events").on(
            "set-schedule-events",
            (response) => {
              dispatch(acceptSchedule(response));
            }
          );
        }
      })
      .catch((err) => {
        addToast("Something went wrong try again.", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
        setRequestActionLoading(false);
      });
  };

  const changEventstate = () => {
    setEventSocketState(!eventSocketState);
  };

  // // -- decline request handler emit socket and call api for notification//
  const handleRequestDecline = (e, params) => {
    setRequestActionLoading(true);
    let parsedData = JSON.parse(params?.message);
    parsedData["is_declined"] = true;
    let msgObj = { ...params, message: parsedData };

    dispatch(
      declineEventRequest({
        event_id: parsedData?.event_id,
        notification_type: EVENT_REQUEST_NAME.DECLINE_EVENT_REQUEST,
        from: params?.user_from_id,
        to: params?.user_to_id,
      })
    )
      .then((res) => {
        addToast("Decline request successful.", {
          appearance: "success",
          autoDismiss: true,
        });

        SOCKET.emit("event-accept-decline", msgObj);
        setRequestActionLoading(false);

        updateEventMessage(msgObj);
        if (res.response.data.status === 200) {
          SOCKET.emit("get-schedule-events", {
            sender_id: profileData.id,
            reciever_id: params?.user_from_id,
            page: 1,
            action: true,
          });

          SOCKET.off("set-schedule-events").on(
            "set-schedule-events",
            (response) => {
              dispatch(declineSchedule(response));
            }
          );
        }
      })
      .catch((err) => {
        console.log(err, "err");
        addToast("Something went wrong try again.", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
        setRequestActionLoading(false);
      });
  };

  useEffect(() => {
    SOCKET.off("event_message_update").on(
      "event_message_update",
      (response) => {
        updateEventReceiverEvent(response?.udpatedMessage);
      }
    );
  }, [eventSocketState, completeMessageList]);

  //this fuction is to sort completeMessageList with date and day
  function generateItems(messages) {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days)
      .sort(
        (x, y) =>
          moment(y, "YYYY-MM-DD").unix() - moment(x, "YYYY-MM-DD").unix()
      )
      .reverse();
    const items = sortedDays.reduce((acc, date) => {
      const sortedMessages = days[date];

      return acc.concat([{ type: "day", date, id: date }, ...sortedMessages]);
    }, []);
    return items;
  }

  //this fuction is to render messages with date and day on UI
  function renderItem(data) {
    if (data.type && data.type === "day") {
      return moment(data.date).format("L") === moment().format("L") ? (
        <div className="last-msg-date">Today</div>
      ) : moment(data.date).format("L") ==
        moment().subtract(1, "days").format("L") ? (
        <div className="last-msg-date">Yesterday</div>
      ) : (
        <div className="last-msg-date">{moment(data.date).format("L")} </div>
      );
    }

    return data.user_from_id == currenUserId ? (
      <div className="chat-msg">
        <div
          className={`chat-msg__blk ${
            typeof data?.message === "string" &&
            data?.message.includes("request_nature")
              ? "event-style-css"
              : ""
          }`}
        >
          {data.message &&
          data.message.match(
            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
          ) ? (
            <div className="chat-link-send">
              <p>
                {data.sender_name &&
                (data?.type === "group" || data?.type === 2) ? (
                  <div>
                    <small>
                      <b style={{ fontSize: "16px", marginTop: "5px" }}>
                        <i className="text-capitalize">
                          {data.is_business === 1
                            ? data.business_name
                            : data.sender_name}
                        </i>
                      </b>
                    </small>
                  </div>
                ) : (
                  ""
                )}

                <a
                  className="chat-link-cta"
                  href={data.message}
                  target="_blank"
                >
                  {data?.message_type === 3 && (
                    <i
                      // className="ri-more-2-fill dot-icon"
                      ref={requestModalRef}
                      // href="javascript:;"
                      // onClick={(e) => handleOpenRequestModal(e, "edit")}
                      data-toggle="modal"
                      data-placement="top"
                      title="Request"
                      data-target="#request-modal"
                    ></i>
                  )}

                  {data.message}
                </a>

                <Microlink
                  url={data.message}
                  size="large"
                  media="video"
                  autoPlay
                />
                <span>{moment(data.created_at).format("LT")}</span>
              </p>
            </div>
          ) : (
            ""
          )}
          {data.message &&
          data.message !== " " &&
          !data.message.match(
            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
          ) ? (
            <p
              className={`${
                data?.message_type === 3 ? "schedule-msg-style" : ""
              }`}
            >
              {data.sender_name &&
              (data?.type === "group" || data?.type === 2) ? (
                <div>
                  <small>
                    <b>
                      <i className="text-capitalize">
                        {data.is_business === 1
                          ? data.business_name
                          : data.sender_name}
                      </i>
                    </b>
                  </small>
                </div>
              ) : (
                ""
              )}

              {data?.message_type === 3 ? (
                <div className="align-items-center">
                  {data?.message &&
                    JSON.parse(data.message).is_declined == true && (
                      <div className="text-dark mb-0 pending-approval">
                        <span>Request Declined</span>
                      </div>
                    )}

                  {data?.message &&
                    JSON.parse(data.message).is_accepted == true && (
                      <div className="text-dark mb-0 pending-approval">
                        <span>Request Accepted</span>
                      </div>
                    )}

                  <div className="icon-wrap theme-icon">
                    <i
                      class="ri-calendar-2-line display-4 mb-0 mr-2 bg-white "
                      style={{ color: "orange" }}
                    ></i>
                    <div className="d-block flex-fill">
                      <div className="d-flex justify-content-between align-items-center mb-1">
                        <p className="p-0 text-white">
                          {`${
                            data?.user_from_id === message_details?.user_id &&
                            message_details?.is_individual
                              ? message_details?.user_name
                              : message_details?.business_name
                          } : `}
                        </p>
                      </div>
                      <b className="mb-1 d-block text-white">
                        {`  requested a ${
                          data?.message &&
                          JSON.parse(data.message).request_nature === "Custom"
                            ? JSON.parse(data.message).title
                            : JSON.parse(data.message).request_nature
                        } on ${
                          data?.message &&
                          moment(JSON.parse(data.message).date).format("lll")
                        }`}
                      </b>

                      <div className="msg__content text-white">
                        {data?.message &&
                        JSON.parse(data?.message)?.iscancel == 1 ? (
                          <p style={{ padding: "0" }}>
                            {" "}
                            The request has been cancelled.
                          </p>
                        ) : (
                          <p style={{ padding: "0", maxWidth: "100%" }}>
                            {`${
                              data?.message &&
                              JSON.parse(data.message).description
                            }`}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {data?.message &&
                    JSON.parse(data.message).is_declined != true &&
                    data?.message &&
                    JSON.parse(data.message).is_accepted != true &&
                    JSON.parse(data.message).iscancel != 1 && (
                      <div className="d-flex btn-wrapbox">
                        <span
                          onClick={(e) =>
                            !requestActionLoading &&
                            handleAcceptEventRequst(e, data)
                          }
                          className="act_btn approve_btn text-primary"
                        >
                          Approve
                        </span>
                        <span
                          onClick={(e) =>
                            !requestActionLoading &&
                            handleRequestDecline(e, data)
                          }
                          className="act_btn approve_btn text-danger"
                        >
                          Decline
                        </span>
                      </div>
                    )}
                </div>
              ) : (
                <>{data.message}</>
              )}

              <span
                className={`${data?.message_type === 3 ? "text-white" : ""} `}
              >
                {moment(data.created_at).format("LT")}
              </span>
            </p>
          ) : (
            ""
          )}
          {!!data.media && (
            <>
              {data.media.match(".mp4") ||
              data.media.match(".web") ||
              data.media.match(".mov") ? (
                <p>
                  {data.sender_name &&
                  (data?.type === "group" || data?.type === 2) ? (
                    <div>
                      <small>
                        <b>
                          <i className="text-capitalize">
                            {data.is_business === 1
                              ? data.business_name
                              : data.sender_name}
                          </i>
                        </b>
                      </small>
                    </div>
                  ) : (
                    ""
                  )}

                  {!!data?.media && (
                    <video
                      id={"videoPlayer" + data.message_id}
                      onClick={() => handleFullVideo(data)}
                      style={{ cursor: "pointer" }}
                      data-toggle="modal"
                      data-target="#large-gallery-modal-chat"
                      src={MEDIA_BASE_URL + data.media}
                    />
                  )}

                  <i className="ri-play-fill play-video"></i>
                  <span>{moment(data.created_at).format("LT")}</span>
                </p>
              ) : (
                ""
              )}
            </>
          )}

          {!!data?.media && (
            <>
              {data.media.match(".png") ||
              data.media.match(".jpg") ||
              data.media.match(".jpeg") ? (
                <>
                  <div className="media__receive" style={{ width: "200px" }}>
                    {data.sender_name &&
                    (data?.changeFriendListDetailype === "group" ||
                      data?.type == 2) ? (
                      <div>
                        <span className="text-left d-block mb-1">
                          <b>
                            <i className="text-capitalize">
                              {data.is_business === 1
                                ? data.business_name
                                : data.sender_name}
                            </i>
                          </b>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {!!data.media && (
                      <img
                        onClick={() => handleFullImage(data)}
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#large-gallery-modal-chat"
                        src={MEDIA_BASE_URL + data.media}
                      />
                    )}

                    <span className="d-block mt-1">
                      {moment(data.created_at).format("LT")}
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}

          {!!data.media && (
            <>
              {data.media.match(".docx") ||
              data.media.match(".doc") ||
              data.media.match(".pdf") ||
              data.media.match(".pptx") ||
              data.media.match(".ppt") ||
              data.media.match(".csv") ||
              data.media.match(".xls") ? (
                <>
                  <p>
                    {data.sender_name &&
                    (data?.type === "group" || data?.type === 2) ? (
                      <div>
                        <small>
                          <b>
                            <i className="text-capitalize">
                              {data.is_business === 1
                                ? data.business_name
                                : data.sender_name}
                            </i>
                          </b>
                        </small>
                      </div>
                    ) : (
                      ""
                    )}

                    <a
                      href={MEDIA_BASE_URL + data.media}
                      style={{ cursor: "pointer" }}
                      data-toggle="modal"
                      data-target="#large-gallery-modal-chat"
                      onClick={() => handleFullDocPreview(data)}
                    >
                      <div className="d-flex justify-content-center mt-4">
                        <div>
                          <i
                            style={{ fontSize: "2.5rem" }}
                            className={
                              data.media.match("doc") ||
                              data.media.match("docx")
                                ? "ri-file-word-2-line"
                                : data.media.match("ppt") ||
                                  data.media.match("pptx")
                                ? "ri-file-ppt-2-line"
                                : data.media.match("pdf")
                                ? "ri-file-pdf-line"
                                : data.media.match("csv") ||
                                  data.media.match("xlsx") ||
                                  data.media.match("xls")
                                ? "ri-file-excel-2-line"
                                : ""
                            }
                          ></i>
                        </div>

                        <div>{data.media}</div>
                      </div>
                    </a>
                    <span>{moment(data.created_at).format("LT")}</span>
                  </p>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    ) : data.user_from_id == profileData.id ? (
      <div className="chat-msg sender">
        <div
          className={`chat-msg__blk ${
            typeof data?.message === "string" &&
            data?.message.includes("request_nature")
              ? "event-style-css"
              : ""
          } `}
          // style={{}}
        >
          {data.message &&
          data.message.match(
            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
          ) ? (
            <div className={`chat-link-send `}>
              <p
                className={`${
                  data?.type === 3
                    ? "custom-event-style schedule-msg-style"
                    : ""
                }`}
              >
                {data.sender_name &&
                (data?.type === "group" || data?.type === 2) ? (
                  <div>
                    <small>
                      <b>
                        <i className="text-capitalize">
                          {data.is_business === 1
                            ? data.business_name
                            : data.sender_name}
                        </i>
                      </b>
                    </small>
                  </div>
                ) : (
                  ""
                )}
                <a
                  className="chat-link-cta text-white"
                  href={data.message}
                  target="_blank"
                >
                  {data.message}

                  {data?.message_type === 3 && (
                    <i
                      ref={requestModalRef}
                      data-toggle="modal"
                      data-placement="top"
                      title="Request"
                      data-target="#request-modal"
                    ></i>
                  )}
                </a>

                <Microlink
                  url={data.message}
                  size="large"
                  media="video"
                  autoPlay
                />
                <span>{moment(data.created_at).format("LT")}</span>
              </p>
            </div>
          ) : (
            ""
          )}
          {data.message &&
          data.message !== " " &&
          !data.message.match(
            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
          ) ? (
            <p
              className={`${
                data?.message_type === 3
                  ? "custom-event-style schedule-msg-style"
                  : ""
              }`}
            >
              {data.sender_name &&
              (data?.type === "group" || data?.type === 2) ? (
                <div>
                  <small>
                    <b>
                      <i className="text-capitalize">
                        {data.is_business === 1
                          ? data.business_name
                          : data.sender_name}
                      </i>
                    </b>
                  </small>
                </div>
              ) : (
                ""
              )}

              {data?.message_type === 3 ? (
                <div className="align-items-center">
                  {data?.message &&
                    JSON.parse(data?.message).iscancel != 1 &&
                    data?.message &&
                    JSON.parse(data?.message).is_accepted != 1 &&
                    data?.message &&
                    JSON.parse(data?.message).is_declined != 1 && (
                      <div className="text-dark mb-0 pending-approval">
                        <i class="ri-time-fill"></i>
                        <span>Pending Approval</span>
                      </div>
                    )}

                  {data?.message &&
                    JSON.parse(data.message).is_declined == true && (
                      <div className="text-dark mb-0 pending-approval">
                        <span>Request Declined</span>
                      </div>
                    )}

                  {data?.message &&
                    JSON.parse(data.message).is_accepted == true && (
                      <div className="text-dark mb-0 pending-approval">
                        <span>Request Accept</span>
                      </div>
                    )}

                  <div className="icon-wrap theme-icon">
                    <i
                      class="ri-calendar-2-line display-4 mb-0 mr-2"
                      style={{ color: "orange" }}
                    ></i>

                    <div className="d-block flex-fill">
                      <div className="d-flex justify-content-between align-items-center mb-1">
                        <p className="p-0">You:</p>
                      </div>

                      <b className="mb-1 d-block">
                        {`requested a ${
                          data?.message &&
                          JSON.parse(data.message).request_nature === "Custom"
                            ? JSON.parse(data.message).title
                            : JSON.parse(data.message).request_nature
                        } on ${
                          data?.message &&
                          moment(JSON.parse(data.message).date).format("lll")
                        }`}
                      </b>

                      <div className="msg__content">
                        <p className="p-0" style={{ maxWidth: "100%" }}>
                          {`${
                            data?.message &&
                            JSON.parse(data.message).description
                          }`}
                        </p>

                        {data?.message &&
                          JSON.parse(data?.message).iscancel == 1 && (
                            <p className="p-0">You cancelled this request.</p>
                          )}
                      </div>
                    </div>
                  </div>

                  {JSON.parse(data?.message).iscancel !== true &&
                    JSON.parse(data?.message).is_accepted != true &&
                    JSON.parse(data?.message).is_declined != true && (
                      <>
                        <i className="ri-more-2-fill dot-icon position-absolute text-dark"></i>
                        <ul className="request_opt_wrap list-unstyled m-0">
                          <li
                            onClick={(e) =>
                              handleOpenRequestModal(
                                e,
                                "edit",
                                data,
                                "callFromMessage"
                              )
                            }
                            ref={requestModalRef}
                            data-toggle="modal"
                            data-placement="top"
                            title="Request"
                            data-target="#request-modal"
                            role="button"
                          >
                            <i class="ri-edit-2-line"></i> Modify Request
                          </li>
                          <li
                            onClick={(e) =>
                              !requestCancleLoader &&
                              handleCancelEventRequest(
                                e,
                                data,
                                "callFromMessage"
                              )
                            }
                            role="button"
                          >
                            <i class="ri-close-circle-line text-danger"></i>{" "}
                            Cancel Request
                          </li>
                        </ul>
                      </>
                    )}
                </div>
              ) : (
                <>{data.message}</>
              )}

              <span>{moment(data.created_at).format("LT")}</span>
            </p>
          ) : (
            ""
          )}
          {!!data.media && (
            <>
              {data.media.match(".mp4") ||
              data.media.match(".webm") ||
              data.media.match(".mov") ? (
                <p>
                  {data.sender_name &&
                  (data?.type === "group" || data?.type === 2) ? (
                    <div>
                      <small>
                        <b>
                          <i className="text-capitalize">
                            {data.is_business === 1
                              ? data.business_name
                              : data.sender_name}
                          </i>
                        </b>
                      </small>
                    </div>
                  ) : (
                    ""
                  )}

                  {!!data.media && (
                    <video
                      onClick={() => handleFullVideo(data)}
                      style={{ cursor: "pointer" }}
                      data-toggle="modal"
                      data-target="#large-gallery-modal-chat"
                      id={"videoPlayer" + data.message_id}
                      src={MEDIA_BASE_URL + data.media}
                    />
                  )}

                  <i className="ri-play-fill play-video"></i>
                  <span>{moment(data.created_at).format("LT")}</span>
                </p>
              ) : (
                ""
              )}
            </>
          )}
          {!!data?.media && (
            <>
              {data.media.match(".png") ||
              data.media.match(".jpg") ||
              data.media.match(".jpeg") ? (
                <>
                  <div className="media__sent" style={{ width: "200px" }}>
                    {data.sender_name &&
                    data.media &&
                    (data.type === "group" || data?.type === 2) ? (
                      <div>
                        <span className="text-white text-left d-block mb-1">
                          <b>
                            <i className="text-capitalize">
                              {data.is_business === 1
                                ? data.business_name
                                : data.sender_name}
                            </i>
                          </b>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {!!data?.media && (
                      <img
                        src={MEDIA_BASE_URL + data.media}
                        onClick={() => handleFullImage(data)}
                        style={{ cursor: "pointer", width: "150" }}
                        data-toggle="modal"
                        data-target="#large-gallery-modal-chat"
                      />
                    )}

                    <span className="text-white d-block mt-1">
                      {moment(data.created_at).format("LT")}
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}

          {!!data.media && (
            <>
              {data.media.match(".docx") ||
              data.media.match(".doc") ||
              data.media.match(".pdf") ||
              data.media.match(".pptx") ||
              data.media.match(".ppt") ||
              data.media.match(".csv") ||
              data.media.match(".xls") ? (
                <>
                  <p>
                    {data.sender_name &&
                    (data?.type === "group" || data?.type === 2) ? (
                      <div>
                        <small>
                          <b>
                            <i className="text-capitalize">
                              {data.is_business === 1
                                ? data.business_name
                                : data.sender_name}
                            </i>
                          </b>
                        </small>
                      </div>
                    ) : (
                      ""
                    )}

                    <a
                      className="text-white"
                      style={{ cursor: "pointer" }}
                      data-toggle="modal"
                      data-target="#large-gallery-modal-chat"
                      onClick={() => handleFullDocPreview(data)}
                    >
                      <div className="d-flex justify-content-center mt-4">
                        <div>
                          <div>
                            <i
                              style={{ fontSize: "2.5rem" }}
                              className={
                                data.media.match("doc") ||
                                data.media.match("docx")
                                  ? "ri-file-word-2-line"
                                  : data.media.match("ppt") ||
                                    data.media.match("pptx")
                                  ? "ri-file-ppt-2-line"
                                  : data.media.match("pdf")
                                  ? "ri-file-pdf-line"
                                  : data.media.match("csv") ||
                                    data.media.match("xlsx") ||
                                    data.media.match("xls")
                                  ? "ri-file-excel-2-line"
                                  : ""
                              }
                            ></i>
                          </div>
                        </div>

                        <div>{data.media}</div>
                      </div>
                    </a>
                    <span>{moment(data.created_at).format("LT")}</span>
                  </p>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    ) : (
      <div className="chat-msg">
        <div className="chat-msg__blk">
          {data.message &&
          data.message.match(
            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
          ) ? (
            <div className="chat-link-send">
              <p>
                {data.sender_name &&
                (data?.type === "group" || data?.type === 2) ? (
                  <div>
                    <small>
                      <b>
                        <i className="text-capitalize">
                          {data.is_business === 1
                            ? data.business_name
                            : data.sender_name}
                        </i>
                      </b>
                    </small>
                  </div>
                ) : (
                  ""
                )}
                <a
                  className="chat-link-cta"
                  href={data.message}
                  target="_blank"
                >
                  {data.message}
                </a>

                <Microlink
                  url={data.message}
                  size="large"
                  media="video"
                  autoPlay
                />
                <span>{moment(data.created_at).format("LT")}</span>
              </p>
            </div>
          ) : (
            ""
          )}

          {data.message &&
          data.message !== " " &&
          !data.message.match(
            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
          ) ? (
            <p>
              {data.sender_name &&
              (data?.type === "group" || data?.type === 2) ? (
                <div>
                  <small>
                    <b>
                      <i className="text-capitalize">
                        {data?.is_business === 1
                          ? data.business_name
                          : data.sender_name}
                      </i>
                    </b>
                  </small>
                </div>
              ) : (
                ""
              )}

              {data?.message}
              <span>{moment(data?.created_at).format("LT")}</span>
            </p>
          ) : (
            ""
          )}

          {!!data.media && (
            <>
              {data.media.match(".webm") ||
              data.media.match(".mp4") ||
              data.media.match(".mov") ? (
                <p>
                  {data.sender_name &&
                  (data?.type === "group" || data?.type === 2) ? (
                    <div>
                      <small>
                        <b>
                          <i className="text-capitalize">
                            {data.is_business === 1
                              ? data.business_name
                              : data.sender_name}
                          </i>
                        </b>
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                  {!!data.media && (
                    <video
                      onClick={() => handleFullVideo(data)}
                      style={{ cursor: "pointer" }}
                      data-toggle="modal"
                      data-target="#large-gallery-modal-chat"
                      id={"videoPlayer" + data.message_id}
                      src={MEDIA_BASE_URL + data.media}
                    />
                  )}

                  <i className="ri-play-fill play-video"></i>
                  <span>{!!data && moment(data?.created_at).format("LT")}</span>
                </p>
              ) : (
                ""
              )}
            </>
          )}

          {!!data.media && (
            <>
              {data.media.match(".png") ||
              data.media.match(".jpg") ||
              data.media.match(".jpeg") ? (
                <>
                  <div className="media__receive" style={{ width: "200px" }}>
                    {data.sender_name &&
                    (data?.type === "group" || data?.type === 2) ? (
                      <div>
                        <span className="text-left d-block mb-1">
                          <b>
                            <i className="text-capitalize">
                              {data.is_business === 1
                                ? data.business_name
                                : data.sender_name}
                            </i>
                          </b>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    {!!data.media && (
                      <img
                        src={MEDIA_BASE_URL + data.media}
                        onClick={() => handleFullImage(data)}
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#large-gallery-modal-chat"
                      />
                    )}

                    <span className="d-block mt-1">
                      {moment(data.created_at).format("LT")}
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
          {!!data.media && (
            <>
              {data.media.match(".docx") ||
              data.media.match(".doc") ||
              data.media.match(".pdf") ||
              data.media.match(".pptx") ||
              data.media.match(".ppt") ||
              data.media.match(".xlsx") ||
              data.media.match(".csv") ||
              data.media.match(".xls") ? (
                <>
                  <p>
                    {data.sender_name &&
                    (data?.type === "group" || data?.type === 2) ? (
                      <div>
                        <small>
                          <b>
                            <i className="text-capitalize">
                              {data.is_business === 1
                                ? data.business_name
                                : data.sender_name}
                            </i>
                          </b>
                        </small>
                      </div>
                    ) : (
                      ""
                    )}
                    <a
                      style={{ cursor: "pointer" }}
                      data-toggle="modal"
                      data-target="#large-gallery-modal-chat"
                      onClick={() => handleFullDocPreview(data)}
                    >
                      <div className="d-flex justify-content-center mt-4">
                        <div>
                          <i
                            style={{ fontSize: "2.5rem" }}
                            className={
                              data.media.match("doc") ||
                              data.media.match("docx")
                                ? "ri-file-word-2-line"
                                : data.media.match("ppt") ||
                                  data.media.match("pptx")
                                ? "ri-file-ppt-2-line"
                                : data.media.match("pdf")
                                ? "ri-file-pdf-line"
                                : data.media.match("csv") ||
                                  data.media.match("xlsx") ||
                                  data.media.match("xls")
                                ? "ri-file-excel-2-line"
                                : ""
                            }
                          ></i>
                        </div>

                        <div>{data.media}</div>
                      </div>
                    </a>
                    <span>{moment(data.created_at).format("LT")}</span>
                  </p>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  //this function is to display image
  const handleFullImage = (data) => {
    setPreviewImage(data.media);
  };

  const setPreviewModelClose = () => {
    $("#large-gallery-modal-chat").modal("hide");
    setPreviewVideo("");
    setPreviewImage("");
    setPreviewDoc("");
  };

  const handleFullVideo = (data) => {
    setPreviewVideo(data.media);
  };

  const handleFullDocPreview = (dataPreview) => {
    setPreviewDoc(dataPreview.media);
  };

  //this function is to close the Group profile popup

  const handleAddUserClose = () => {
    // setGrouopUserList([]);
    setIsHaveMember(false);
    $("#modal-group-profile").modal("hide");
    if (profile_details) {
      setEditGroupName(profile_details.name);
    }
  };

  const handleAddUser = () => {
    const groupMembers = friendList.filter(
      (item) => item.user_deleted !== true
    );

    let Users = groupMembers.filter((itemA) => {
      return !grouopUserList.find((itemB) => {
        if (!itemA.user_deleted) {
          return itemA.user_id === itemB.user_id;
        }
      });
    });
    if (Users.length === 0) {
      addToast("No Members Left to add in the Group", {
        appearance: "success",
        autoDismiss: true,
      });
      setIsHaveMember(true);
    }

    setShowNewList(Users);
  };

  const handleAddUserClosedModal = () => {
    $("#group-user").modal("hide");
    $("#modal-group-profile").modal("hide");
    setShowNewList([]);
  };

  // THIS FUNCTION IS CALLED ON ADD MEMBER
  const handleAddUserClosed = () => {
    $("#group-user").modal("hide");
    $("#modal-group-profile").modal("hide");

    const dataFormat = new FormData();
    dataFormat.append("group_id", Number(currenUserId));

    addSelectedUserId.forEach((tag) =>
      dataFormat.append("user_id[]", Number(tag))
    );

    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: dataFormat,
    };

    axios(GROUP_ADD_USER, config).then((response) => {
      if (response.data.success === true) {
        addToast("Friend added successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setShowNewList([]);

        const bodyParameter = {
          id: currenUserId,
        };

        dispatch(searchProfileDataApi(bodyParameter));
        if (message_details?.id) {
          let config = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + getCookie("token_id"),
            },
          };

          fetch(GET_GROUP_USERS + "/" + currenUserId, config)
            .then((response) => {
              response.json().then((data) => {
                setGrouopUserList(data.data);
              });
            })
            .catch((err) => ({
              data: { error: true, message: "Internal Server Error" },
              status: 500,
            }));
        }

        SOCKET.emit("manage_group_users", {
          group_id: currenUserId,
          event: "add_user",
          title: message_details.title,
          users: addSelectedUserId.map((str) => {
            return parseInt(str);
          }),
        });
      } else {
        addToast("Please Select the Members.", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 6000,
        });
      }
    });
  };

  // FUNCTION TO ADD MEMEBERS IN GROUP//
  const handleGroupMemberd = () => {
    let doc = $(".add-memeber-group").find("input:checked");

    if (doc.length > 0) {
      let membersId = [];
      for (let i = 0; i < doc.length; i++) {
        membersId.push(doc[i].id);
      }
      setAddSelectedUserId(membersId);
    } else {
      setAddSelectedUserId([]);
    }
  };

  // -----------REMOVE USER FROM GROUP BY ADMIN----------------- //
  const handleRemoveGroupUser = (id) => {
    const friendId = !!getCookie("friendId") ? getCookie("friendId") : null;

    //APPEND USER ID TO REMOVE USER FROM GROUP AND GROUP ID FROM WHICH GROUP
    const data = new FormData();
    data.append("user_id", id);
    data.append("group_id", Number(currenUserId));
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };

    axios(GROUP_LEAVE_API, config).then((response) => {
      if (response.data.success === true) {
        setGrouopUserList(grouopUserList.filter((user) => user.user_id !== id));
        addToast("Group member removed successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        const bodyParameter = {
          id: currenUserId,
        };
        dispatch(searchProfileDataApi(bodyParameter));

        SOCKET.emit("user_removed_from_group", {
          user_id: id,
          group_id: currenUserId,
        });
        setIsHaveMember(false);
        SOCKET.emit("manage_group_users", {
          group_id: currenUserId,
          event: "remove_user",
          title: message_details.title,
          users: [id],
        });
      } else {
        addToast(response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  SOCKET.off("admin_support").on("admin_support", (result) => {
    message_details = result;
  });

  // --------------FOR DELETE GROUP BY GROUP ADMIN------------//
  const handleDeleteGroup = () => {
    //APPEND GROUP ID TO DELETE GROUP//
    const data = new FormData();

    data.append("id", Number(currenUserId));
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };

    axios(GROUP_DELETE_API, config).then((response) => {
      // IF RESPONSE IS TRUE//
      if (response.data.success === true) {
        // HIDE THE OPEN MODEL//
        $("#modal-group-profile").modal("hide");
        addToast("Group delete successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        let newUserList = [];
        grouopUserList.forEach((user) => {
          if (user.user_id != profileData.id) {
            newUserList.push(user.user_id);
          }
        });
        SOCKET.emit("manage_group", {
          group_id: currenUserId,
          event: "delete",
          title: "",
          users: newUserList,
        });

        setTimeout(() => {
          SOCKET.emit("get_all_unread_group_messages", {
            user_id: profileData.id,
            grp_id: currenUserId,
            toMe: true,
          });
          SOCKET.emit("get_all_unread_group_messages", {
            user_id: profileData.id,
            grp_id: currenUserId,
            toMe: false,
          });
          SOCKET.emit("get_all_unread_group_messages_for_login_user", {
            user_id: profileData.id,
            grp_id: currenUserId,
            toMe: true,
          });
        }, 3500);

        SOCKET.emit("get_friend_and_group_list", {
          u_id: profileData?.id,
          socket_id: SOCKET.id,
        });

        SOCKET.off("get_friend_and_group_list").on(
          "get_friend_and_group_list",
          (chatList) => {
            message_details = null;

            dispatch(allFriendandGroupList(chatList));
          }
        );
      } else {
        addToast(response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  let link;
  useEffect(() => {
    let friendsId = !!getCookie("friendId") ? getCookie("friendId") : null;
    for (let i = 0; i < newChatList?.length; i++) {
      link = newChatList[i].user_id === Number(friendsId);

      if (link == true) {
        setUnlink(link);
      }
    }
  }, [currenUserId]);

  // TO CONVERT UTC TIME INTO LOCAL TIME //

  let localTime = moment(message_details?.online_status_time).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  if (localTime) {
    localTime = moment.utc(localTime).toDate();
  }

  //--------------------------LISTEN WHEN USER ADD OR REMOVED FROM GROUP--------------------//
  SOCKET.off("manage_group_users").on("manage_group_users", (data) => {
    if (data.event === "remove_user") {
      // --------------if message box of removed user is not open set user_active =0 --------------//

      newChatList.map((item) => {
        if (item.id === data.group_id) {
          item.is_user_active = 0;
        }
      });

      // -----------if message box of removed user is open set user_active=0------------ //

      grouopUserList.map((item, index) => {
        if (item.user_id === data.user_id) {
          grouopUserList.splice(index, 1);

          message_details.is_user_active = 0;
        }
        setGrouopUserList(
          grouopUserList.filter((user) => user.user_id !== data.user_id)
        );
      });
    }
    if (data.event === "add_user") {
      message_details.is_user_active = 1;

      newChatList.map((item) => {
        if (item.id === data.group_id) {
          item.is_user_active = 1;
        }
      });

      setGrouopUserList([...grouopUserList, data]);

      SOCKET.emit("get_friend_and_group_list", {
        u_id: profileData?.id,
        socket_id: SOCKET.id,
      });

      SOCKET.off("get_friend_and_group_list").on(
        "get_friend_and_group_list",
        (chatList) => {
          dispatch(allFriendandGroupList(chatList));
        }
      );

      SOCKET.emit("get_all_unread_group_messages", {
        user_id: profileData.id,
        grp_id: currenUserId,
        toMe: true,
      });
    }
  });

  // --------when create or delete group---------//
  SOCKET.off("manage_group").on("manage_group", (data) => {
    if (data.event === "create") {
      // -----------------------if create new group emit socket to fetch new list for added users-------------- //

      SOCKET.emit("get_friend_and_group_list", {
        u_id: profileData?.id,
        socket_id: SOCKET.id,
      });

      SOCKET.off("get_friend_and_group_list").on(
        "get_friend_and_group_list",
        (chatList) => {
          dispatch(allFriendandGroupList(chatList));
        }
      );

      setTimeout(() => {
        SOCKET.emit("get_all_unread_group_messages", {
          user_id: profileData.id,
          grp_id: currenUserId,
          toMe: true,
        });

        SOCKET.emit("get_all_unread_group_messages_for_login_user", {
          user_id: profileData.id,
          grp_id: currenUserId,
          toMe: true,
        });
      }, 2000);
    } else if (data.event === "delete") {
      //--------------- if delete group remove group from chat of added user------------ //
      newChatList.map((item, index) => {
        if (item.id === data.group_id) {
          newChatList.splice(index, 1);
          return newChatList;
        } else {
          return newChatList;
        }
      });
    }
    dispatch(allFriendandGroupList(newChatList));
  });

  useEffect(() => {
    setCurrentOpenChat(message_details);
  }, [message_details]);

  return (
    <>
      <div
        className={`col-md-6 px-0 bg-white full-width ${
          !currenUserId ? "chat-stretch" : ""
        }`}
      >
        {!!message_details &&
        !!Object.keys(message_details).length > 0 &&
        currenUserId ? (
          <div className="chat-area position-relative h-100">
            <div className="chat__header p-3">
              <a
                href="javascript:void(0)"
                className="back-chat link-color mt-2 d-inline-block pr-1"
                onClick={() => handleBackChat()}
              >
                <i className="ri-arrow-left-s-line align-middle dot-icon"></i>
              </a>
              {/* ---show online away--- */}
              <div
                className={`chat-area__header ${
                  !!message_details?.user_deleted && "text-fade"
                }  ${
                  message_details.type === "user" &&
                  !message_details?.user_deleted
                    ? message_details.online_status === 1
                      ? "active-point"
                      : message_details.online_status === 0
                      ? ""
                      : "active-point-orange"
                    : ""
                }`}
              >
                {profile_details?.profile_visibility == 0 &&
                message_details?.is_individual ? (
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={handleUserProfile}
                    className="individual-user"
                    onError={(ev) =>
                      (ev.target.src = "/assets/images/member-default.svg")
                    }
                    src={
                      !!message_details.user_deleted
                        ? "/assets/images/defaultImage.jpg"
                        : !!message_details && !!message_details.profile
                        ? IMAGE_BASE_URL + message_details?.profile
                        : "/assets/images/member-default.svg"
                    }
                    alt="logo"
                  />
                ) : (
                  <>
                    {!!Object.keys(message_details).length &&
                    message_details?.type === "user" ? (
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={handleUserProfile}
                        className="business-user"
                        onError={(ev) =>
                          (ev.target.src = "/assets/images/business_logo.svg")
                        }
                        src={
                          !!message_details?.user_deleted
                            ? "/assets/images/company-logo-default.jpg"
                            : !!message_details &&
                              !!message_details?.business_profile
                            ? IMAGE_BASE_URL + message_details?.business_profile
                            : "/assets/images/business_logo.svg"
                        }
                        alt="logo"
                      />
                    ) : (
                      <img
                        style={{ cursor: "pointer" }}
                        className={`business-user ${
                          message_details.type === "group" ? "group-user" : ""
                        }`}
                        onError={(ev) =>
                          (ev.target.src = "/assets/images/group_logo.png")
                        }
                        src={
                          !!message_details.profile
                            ? MEDIA_BASE_URL + message_details.profile
                            : "/poolsmagnic/assets/images/group_logo.png"
                        }
                        alt="logo"
                      />
                    )}
                  </>
                )}

                <div
                  className="chat__listing__users__details d-inline"
                  style={{ width: "auto" }}
                >
                  {message_details?.type === "user" ? (
                    <>
                      <h6
                        onClick={handleUserProfile}
                        style={{ cursor: "pointer" }}
                        className="mb-0 text-capitalize link-color"
                      >
                        {message_details?.is_individual
                          ? message_details.user_name
                          : message_details.business_name}
                      </h6>
                      {!!message_details?.user_deleted && (
                        <span className="account-deactivate">Deactivated</span>
                      )}
                    </>
                  ) : (
                    <>
                      <h6
                        style={{ cursor: "pointer" }}
                        className="mb-0 text-capitalize link-color"
                      >
                        {message_details?.title}
                      </h6>
                    </>
                  )}

                  {message_details.is_individual === 1 &&
                  message_details.type === "user" &&
                  !message_details?.user_deleted ? (
                    message_details.online_status === 0 ? (
                      <small className="last-seen-css">
                        {message_details?.online_status_time !== null &&
                          "Active " + moment(localTime).fromNow()}
                      </small>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {/* <span
                    ref={viewAvailabilityModal}
                    href="javascript:;"
                    data-toggle="modal"
                    data-placement="top"
                    title="Availablity"
                    data-target="#view-availablity-modal"
                    onClick={() => handleViewAvailability()}
                    className="small cursor-pointer"
                  >
                    view availability
                  </span> */}
                </div>

                {!!profile_details &&
                  Object.keys(profile_details).length > 0 &&
                  profile_details?.seller_status !== "" &&
                  profile_details.type === "user" && (
                    <>
                      {profile_details?.seller_status != "Active Seller" &&
                        !message_details?.user_deleted && (
                          <div
                            className="user-status-header ms-5 me-3"
                            style={{ background: color }}
                          >
                            <span style={{ fontWeight: 600 }}>
                              <span className="text-white">
                                <img
                                  src={
                                    profile_details?.seller_status != "Custom"
                                      ? icon
                                      : !!profile_details?.status_image
                                      ? IMAGE_BASE_URL + icon
                                      : "/assets/images/custom-icon.svg"
                                  }
                                />{" "}
                                {profile_details?.seller_status != "Custom"
                                  ? profile_details?.seller_status
                                  : profile_details?.custom_title}
                              </span>
                            </span>

                            {profile_details?.seller_status !=
                              "Active Seller" && (
                              <p>
                                <span className="chatData">
                                  {profile_details?.seller_status} to{" "}
                                  {profile_details?.from_dhandleSendDucumentate
                                    ? `(${profile_details?.from_date}) to (${profile_details?.to_date})`
                                    : `(${profile_details?.to_date})`}{" "}
                                </span>
                                <br />
                                <span>{profile_details?.description}</span>
                              </p>
                            )}
                          </div>
                        )}
                    </>
                  )}
              </div>

              {/* ---show call button and action menu----- */}
              <div className="chat-options d-flex align-items-center">
                {message_details?.type !== "group" &&
                  currenUserId != 176 &&
                  !message_details?.user_deleted && (
                    <a
                      ref={requestModalRef}
                      href="javascript:;"
                      onClick={(e) => handleOpenRequestModal(e, "no-edit")}
                      data-toggle="modal"
                      data-placement="top"
                      title="Request"
                      data-target="#request-modal"
                      className="link-color network-options btn-request"
                    >
                      <i
                        className="ri-calendar-2-line align-middle text-white"
                        alt="request"
                      />
                    </a>
                  )}

                {!message_details?.user_deleted && currenUserId != 176 && (
                  <a
                    href="javascript:;"
                    tabIndex="0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Google meet"
                    className="link-color network-options btn-meet"
                    onClick={() => handleStartMeeting(message_details)}
                  >
                    <i className="ri-vidicon-line align-middle text-white" />
                  </a>
                )}

                {message_details.phone &&
                !message_details?.user_deleted &&
                !profile_details?.phone_visibility ? (
                  <div
                    className="call-info position-relative"
                    style={{ height: "40px" }}
                  >
                    <span className="network-options btn-blue call-info">
                      <i className="ri-phone-fill text-white"></i>
                      <ul className="list-unstyled">
                        <li className="my-3">
                          <div className="call-info-modal">
                            <span className="d-block border-0">
                              <a
                                className="link-color"
                                href={`tel:${message_details.phone}`}
                              >
                                <i className="ri-phone-fill align-middle text-primary"></i>{" "}
                                Phone Number
                              </a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </span>
                  </div>
                ) : (
                  ""
                )}

                {!!message_details &&
                  profileData?.id !== ADMIN &&
                  message_details.type !== "group" &&
                  currenUserId != 176 &&
                  !message_details?.user_deleted && (
                    <>
                      <i
                        className="ri-more-2-fill dot-icon"
                        onClick={(event) => handleDotAction(event)}
                      ></i>

                      <ul id="dropdown" className="dropdown-list">
                        {unlink ? (
                          <li>
                            {" "}
                            <a
                              href="javascript:void(0)"
                              onClick={handleUnfriend}
                            >
                              <i className="ri-user-unfollow-fill" /> Un-Link
                            </a>
                          </li>
                        ) : (
                          <li></li>
                        )}
                      </ul>
                    </>
                  )}
              </div>
              {/* ---show call button and action menu end----- */}
            </div>
            {!message_details.is_individual &&
              !message_details?.user_deleted && (
                <>
                  {
                    <div className="profile-business-header d-flex align-items-center py-2 pl-5">
                      {/* show online status of business only  */}
                      <div>
                        {message_details.type === "group" &&
                          message_details.is_user_active === 1 && (
                            <div
                              className="groud-users d-flex"
                              onClick={() =>
                                handleGroupProfileUser(profile_details)
                              }
                              data-toggle="modal"
                              data-target={
                                message_details.type === "group"
                                  ? "#modal-group-profile"
                                  : ""
                              }
                            >
                              {/* ------show user image and user status in group ----- */}
                              {!!grouopUserList &&
                                message_details.is_user_active === 1 &&
                                grouopUserList.map((item, index) =>
                                  index < 3 ? (
                                    <span
                                      className={
                                        item?.online_status === 1
                                          ? "active-point"
                                          : item?.online_status === 0
                                          ? ""
                                          : "active-point-orange"
                                      }
                                    >
                                      <img
                                        src={
                                          item.business_image
                                            ? IMAGE_BASE_URL +
                                              item.business_image
                                            : IMAGE_BASE_URL +
                                              item.profile_image
                                        }
                                        onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/member-default.svg")
                                        }
                                        alt="user"
                                        title={
                                          item.business_name
                                            ? item.business_name
                                            : item.name
                                        }
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )
                                )}

                              {/* ------show user image and user status in group end ----- */}

                              {/* --------- show name of members in  group ------- */}
                              <div className="groud-users__name align-self-center pl-2">
                                <h6 className="mb-0 text-capitalize small">
                                  {!!grouopUserList &&
                                    message_details.is_user_active === 1 &&
                                    grouopUserList.map((item, index) =>
                                      newFunction(index, grouopUserList, item)
                                    )}

                                  {!!grouopUserList &&
                                  message_details.is_user_active === 1 &&
                                  grouopUserList.length > 3
                                    ? " & more"
                                    : ""}
                                </h6>
                              </div>
                              {/* --------- show name of members in group end ------- */}
                            </div>
                          )}

                        {/* -----display business profile image on business account start ----- */}
                        {message_details?.type !== "group" &&
                          profile_details?.profile_visibility == 0 &&
                          !message_details?.user_deleted && (
                            <img
                              className="individual-user"
                              onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/member-default.svg")
                              }
                              src={
                                !profile_details?.profile_visibility &&
                                !!message_details &&
                                !!message_details.profile &&
                                !message_details?.user_deleted
                                  ? IMAGE_BASE_URL + message_details.profile
                                  : "/assets/images/member-default.svg"
                              }
                              alt="logo"
                            />
                          )}
                        {/* -----display business profile image on business account end----- */}
                      </div>

                      {/* -----display name and last seen of business profile on business account start ----- */}
                      {message_details?.type !== "group" && (
                        <div className="chat__listing__users__details">
                          <h6 className="mb-0 text-capitalize">
                            {message_details?.type === "user" &&
                            profile_details?.profile_visibility == 0 &&
                            !message_details?.user_deleted
                              ? message_details?.user_name
                              : ""}
                          </h6>
                          {!!message_details &&
                            !message_details?.user_deleted &&
                            (message_details.online_status === 0 ? (
                              <small className="last-seen-css">
                                {message_details.online_status_time !== null &&
                                  "Active " + moment(localTime).fromNow()}
                              </small>
                            ) : (
                              ""
                            ))}
                        </div>
                      )}
                      {/* -----display name and last seen of business profile on business account end----- */}
                    </div>
                  }
                </>
              )}

            <div
              className={
                message_details.is_individual
                  ? "chat-body bg-white p-2 chat-body-individual"
                  : message_details.type === "group"
                  ? "chat-body-group bg-white p-2"
                  : "chat-body-group bg-white p-2"
              }
            >
              {message_details?.type === "user" &&
              !message_details?.user_deleted &&
              completeMessageList?.length === 0 &&
              !is_loading ? (
                <>
                  <b className="d-flex w-100 justify-content-center mt-5">
                    "Now, you are Linked-up with{" "}
                    {!!message_details.is_individual
                      ? message_details.user_name
                      : message_details.business_name}
                    "
                  </b>
                </>
              ) : (
                <>
                  {!!completeMessageList && completeMessageList.length > 0 && (
                    <Scrollbars
                      id="message-body-scrool"
                      onScrollFrame={handleScroolMessage}
                      autoHide
                    >
                      <ClipLoader
                        color={"#00A8F3"}
                        loading={pagination_loading}
                        css={overridePagination}
                      />

                      {/* For Message List */}
                      {!!completeMessageList.length &&
                        is_loading === false &&
                        generateItems(completeMessageList).map((item) =>
                          renderItem(item)
                        )}
                    </Scrollbars>
                  )}
                </>
              )}
            </div>
            <div
              className={
                !!message_details?.user_deleted
                  ? "chat-footer-deactivate"
                  : "chat-footer"
              }
            >
              <div className="sweet-loading">
                <BarLoader
                  color={"#b23ebb"}
                  loading={is_loading}
                  css={override}
                />
              </div>
              {message_details.type === "group" &&
                !!message_details.is_user_active && (
                  <form onSubmit={handleSumbitMessage} autoComplete="off">
                    <div className="plus-box d-flex justify-content-center align-items-center">
                      <label
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => setUploadImage(true)}
                      >
                        <i className="ri-add-line textLinkPre-white"></i>
                      </label>
                    </div>

                    <Input
                      type="text"
                      name="userMessage"
                      value={userMessage}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Write a message"
                    ></Input>

                    <button
                      type="submit"
                      disabled={!!is_loading ? true : false}
                    >
                      <i className="ri-send-plane-2-fill"></i>
                    </button>
                  </form>
                )}

              {message_details.type === "group" &&
                message_details.is_user_active === 0 && (
                  <p style={{ width: "100%", textAlign: "center" }}>
                    {"You were removed from group"}
                  </p>
                )}

              {message_details.type === "user" &&
              !message_details?.user_deleted ? (
                <form onSubmit={handleSumbitMessage} autoComplete="off">
                  <div className="plus-box d-flex justify-content-center align-items-center">
                    <label
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => setUploadImage(true)}
                    >
                      <i className="ri-add-line textLinkPre-white"></i>
                    </label>
                  </div>

                  <Input
                    type="text"
                    name="userMessage"
                    value={userMessage}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Write a message"
                  ></Input>

                  <button
                    type="submit"
                    disabled={!!is_loading ? true : false}
                    style={{
                      cursor: `${
                        userMessage.trim().length === 0 ? "not-allowed" : ""
                      }`,
                    }}
                  >
                    <i className="ri-send-plane-2-fill"></i>
                  </button>
                </form>
              ) : (
                message_details?.type !== "group" && (
                  <div>
                    <p
                      className="h5"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      Account is deactivated
                    </p>
                  </div>
                )
              )}

              <div className="typing">
                {!!typingUser && (
                  <>
                    <strong>{typingUser}</strong> is typing...
                  </>
                )}{" "}
              </div>
            </div>
          </div>
        ) : (
          <div className="default-chat text-center">
            <img src="/assets/images/default-chat-screen.svg" alt="default" />
            <p className="w-75 mx-auto mb-0"></p>
          </div>
        )}
      </div>

      {/* Modal to send media in chat*/}
      <Modal
        show={uploadImage}
        onHide={handleAllCloseButton}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">
              <img
                src="/assets/images/icon.svg"
                alt="icon"
                className="site-icon"
              />
              Add an Attachment
            </h5>
          </Modal.Title>
        </Modal.Header>

        {/* MODAL BODY START  */}
        <Modal.Body>
          <div className="grid upload-files">
            {mediaUrl.length > 0 &&
              mediaUrl.map((data, index) => (
                <div className="media-box" id={index}>
                  <a
                    href="javascript:void(0)"
                    className="rejected"
                    onClick={() => removeFile(index)}
                  >
                    <i className="ri-close-fill"></i>
                  </a>
                  {(data.match("data:video/mp4") ||
                    data.match("data:video/webm") ||
                    data.match("data:video/mov") ||
                    data.includes(".mp4", ".webm")) && <video src={data} />}
                  {(data.match("data:image/") ||
                    data.includes(".png") ||
                    data.includes(".jpg") ||
                    data.includes(".JPG") ||
                    data.includes(".PNG") ||
                    data.includes(".JPEG") ||
                    data.includes(".jpeg")) && <img src={data} alt="image" />}

                  {(data.match("data:application/") ||
                    data.includes(".docx") ||
                    data.includes(".ppt") ||
                    data.includes(".doc") ||
                    data.includes(".pdf") ||
                    data.includes(".pptx") ||
                    data.includes(".xls") ||
                    data.includes(".csv") ||
                    data.includes(".xlsx")) && <p>File : {data}</p>}
                </div>
              ))}
          </div>
        </Modal.Body>
        {/* MODAL BODY END  */}

        {/* MODAL FOOTER START */}
        <Modal.Footer className="justify-content-md-center">
          <div className="row w-100">
            <div className="col-md-9 px-0">
              <div className="feeds-post__type d-flex justify-content-start w-100">
                {sendOnlyPicture && (
                  <div className="feeds-post__category">
                    <a href="javascript:void(0)" onClick={handleClick}>
                      <i className="ri-image-fill pictures-option" /> Pictures
                      <input
                        id="uploadfile"
                        type="file"
                        className="d-none"
                        multiple={true}
                        accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                        ref={hiddenFileInput}
                        onChange={(e) => handleChangeFile(e)}
                        style={{ display: "none" }}
                      />
                    </a>
                  </div>
                )}
                {sendOnlyVideo && (
                  <div className="feeds-post__category">
                    {" "}
                    <a href="javascript:void(0)" onClick={handleVideoSubmit}>
                      <i className="ri-movie-line video-option" /> Videos
                      <input
                        type="file"
                        name="file"
                        value=""
                        id="upload_fle"
                        className="d-none"
                        accept=" video/*"
                        multiple="true"
                        ref={handleVideoInput}
                        onChange={(e) => handleVideoFileChange(e)}
                        style={{ display: "none" }}
                      />
                    </a>
                  </div>
                )}
                {sendOnlyDocs && (
                  <div className="feeds-post__category">
                    <a href="javascript:void(0)" onClick={handleDocumentSubmit}>
                      <i className="ri-links-line link-option"></i> Document
                      <input
                        type="file"
                        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        name="file-document"
                        value=""
                        id="upload_fle-document"
                        className="d-none"
                        multiple="true"
                        ref={handleDocumentInput}
                        onChange={(e) => handleDocumentChange(e)}
                        style={{ display: "none" }}
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3 text-right btn-spacer">
              <button
                href="javascript:void(0)"
                className={
                  !!buttonState
                    ? "btn btn-primary btn-sm btn-full disabled"
                    : "btn btn-primary btn-sm btn-full"
                }
                onClick={handleSendAll}
                disabled={!mediaFile.length}
              >
                Send
              </button>
            </div>
          </div>
        </Modal.Footer>
        {/* MODAL FOOTER END  */}
      </Modal>

      {/*START ---- Modal For Group Profile */}

      <div
        className="modal fade"
        id="modal-group-profile"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog add-group-member-modal modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="group-header text-center w-100">
                <div className="group-image">
                  <img
                    className="business-user group-user"
                    src={
                      uploadGroupImage
                        ? uploadGroupImage
                        : profile_details && profile_details.type === "group"
                        ? MEDIA_BASE_URL + profile_details.cover_image
                        : !!profile_details && !!profile_details.business_image
                        ? IMAGE_BASE_URL + profile_details.business_image
                        : "/poolsmagnic/assets/images/company-logo-default.jpg"
                    }
                    onError={(ev) =>
                      (ev.target.src = "/assets/images/member-default.svg")
                    }
                  />

                  <a
                    href="javascript:void(0)"
                    onClick={() => openGroupProfile.current.click()}
                  >
                    <i className="ri-pencil-line" />
                  </a>
                </div>
                <input
                  type="file"
                  id="profileImgData"
                  ref={openGroupProfile}
                  onChange={handleGroupProfileChange}
                  name="profileImage"
                  className="d-none"
                  accept="image/*"
                />

                <h5
                  className="modal-title editable-text mt-3"
                  id="exampleModalLabel0"
                >
                  <div className="d-flex">
                    {" "}
                    <EditText
                      name="textbox1"
                      value={editGroupName}
                      onChange={(e) => handleGroupTitle(e)}
                      showEditButton
                    />
                  </div>
                </h5>
              </div>

              <button
                type="button"
                onClick={handleAddUserClose}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  className="dont close If Group Name is empty"
                >
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body mt-4">
              <form action="#" method="post">
                {!!grouopUserList &&
                  grouopUserList.map(
                    (item, index) =>
                      !item?.user_deleted && (
                        <div className="add-member-block " key={index}>
                          <div className="add-memeber-group d-block">
                            <label className="d-flex align-items-center  default-label">
                              <img
                                src={
                                  !!item.business_image &&
                                  item.business_image !== " "
                                    ? IMAGE_BASE_URL + item.business_image
                                    : IMAGE_BASE_URL + item.profile_image
                                }
                                onError={(ev) =>
                                  (ev.target.src =
                                    "/assets/images/member-default.svg")
                                }
                                alt="user"
                                className="user-profile"
                              />
                              <p className="mb-0 ml-3">
                                {item.business_name
                                  ? item.business_name
                                  : item.name}
                              </p>

                              {!!profile_details &&
                                profileData.id ===
                                  profile_details.created_by_id &&
                                (item.user_id ===
                                profile_details.created_by_id ? (
                                  <>
                                    <p className="mb-0 ml-auto py-1 px-2 border">
                                      Group Admin
                                    </p>
                                  </>
                                ) : (
                                  <div
                                    onClick={() =>
                                      handleRemoveGroupUser(item.user_id)
                                    }
                                    className="mb-0 ml-auto ri-delete-bin-line text-dark delete-icon"
                                  ></div>
                                ))}
                            </label>
                          </div>
                        </div>
                      )
                  )}
              </form>
            </div>

            <div className="modal-footer border-0 pt-4">
              <div className="row w-100">
                <div className="col-md-12 text-center">
                  {!!profile_details &&
                  profileData.id === profile_details.created_by_id ? (
                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary btn-sm btn-full px-3 mx-1 mt-2"
                      onClick={handleDeleteGroup}
                    >
                      Delete Group
                    </a>
                  ) : (
                    ""
                  )}

                  {(editGroupName !== "" || uploadGroupImg !== "") && (
                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary btn-sm btn-full px-3 mx-1 mt-2"
                      onClick={handleGroupEdit}
                    >
                      Save
                    </a>
                  )}

                  {!!profile_details &&
                  profileData.id === profile_details.created_by_id ? (
                    <button
                      disabled={isHaveMember}
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#group-user"
                      className="btn btn-primary btn-sm btn-full px-3 mx-1 mt-2"
                      onClick={handleAddUser}
                    >
                      Add user
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*END ----  Modal For Group Profile */}

      {/*START ---- Modal For Display Media */}
      <div
        className="modal fade chat-media-modal"
        id="large-gallery-modal-chat"
        tabIndex={-1}
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content h-100">
            <div className="modal-body h-100">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={setPreviewModelClose}
              >
                <span aria-hidden="true">×</span>
              </button>

              <div className="carousel-inner flex-wrap">
                {previewVideo && (
                  <video
                    width="200"
                    height="200"
                    controls
                    className="d-block w-100"
                    src={MEDIA_BASE_URL + previewVideo}
                  />
                )}
                {previewImage && (
                  <img
                    className="d-block w-100"
                    src={MEDIA_BASE_URL + previewImage}
                  />
                )}
                {previewDoc && previewDoc.includes(".pdf") && (
                  <>
                    <iframe
                      className="doc"
                      style={{ height: "500px", width: "100%" }}
                      seamless=""
                      frameborder="0"
                      scrolling="no"
                      src={MEDIA_BASE_URL + previewDoc}
                    ></iframe>
                  </>
                )}
                {previewDoc && !previewDoc.includes(".pdf") && (
                  <>
                    <a
                      className="link-color mb-2"
                      href={MEDIA_BASE_URL + previewDoc}
                    >
                      <i className="ri-download-line"></i> Click Here to
                      Download
                    </a>
                    <iframe
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${
                        MEDIA_BASE_URL + previewDoc
                      }`}
                      title="File"
                      style={{ height: "500px", width: "100%" }}
                      frameborder="1"
                      seamless="no"
                    ></iframe>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*END ------ Modal For Display Media */}

      {/* 
      Start ---- Modal For add User Group 
      */}
      {showNewList.length > 0 ? (
        <div
          className="modal fade upper-modal"
          id="group-user"
          data-backdrop="static"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog add-group-member-modal modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img
                    src="/assets/images/icon.svg"
                    alt="icon"
                    className="site-icon add"
                  />
                  Add Group Chat Member
                </h5>
                <button
                  type="button"
                  onClick={handleAddUserClosedModal}
                  ref={closeAddEl}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body mt-4">
                <form action="#" method="post">
                  {showNewList.map(
                    (item, index) =>
                      !item?.user_deleted && (
                        <div className="add-member-block" key={index}>
                          <div
                            className="add-memeber-group"
                            onClick={() => handleGroupMemberd(item)}
                          >
                            <input type="checkbox" id={item.user_id} />
                            <label
                              className="d-flex align-items-center"
                              htmlFor={item.user_id}
                            >
                              <img
                                src={
                                  !!item.user_profile &&
                                  item.user_profile !== " "
                                    ? IMAGE_BASE_URL + item.user_profile
                                    : "/assets/images/member-default.svg"
                                }
                                onError={(ev) =>
                                  (ev.target.src =
                                    "/assets/images/member-default.svg")
                                }
                                alt="user"
                                className="user-profile"
                              />
                              <p className="mb-0 ml-3">
                                {item.is_individual
                                  ? item.user_name
                                  : item.business_name}
                              </p>{" "}
                              <i className="ri-checkbox-circle-fill"></i>
                            </label>
                          </div>
                        </div>
                      )
                  )}
                </form>
              </div>

              <div className="modal-footer border-0 pt-4">
                <div className="row w-100">
                  <div className="col-md-12 px-0"></div>
                  <div
                    className="col-md-12 text-right btn-spacer"
                    onClick={handleAddUserClosed}
                  >
                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary btn-sm btn-full"
                    >
                      Add
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <RequestModal
        updateEventMessage={updateEventMessage}
        editAppointent={editAppointent}
        setEditAppointment={setEditAppointment}
        messageDetails={message_details}
        addToast={addToast}
        dispatch={dispatch}
        messageId={messageId}
        requestData={requestData}
        storeEventDetails={storeEventDetails}
        setStoreEventDetails={setStoreEventDetails}
        setRequestData={setRequestData}
      />

      <ViewAvailability
        message_details={message_details}
        currenUserId={currenUserId}
      />

      {/* {currenUserId && Object.keys(message_details).length > 0 && ( */}
      {currenUserId && (
        <MediaBox
          changEventstate={changEventstate}
          poolsChatState={poolsChatState}
          editAppointent={editAppointent}
          messageDetails={message_details}
          requestData={requestData}
          setRequestData={setRequestData}
          mediaPage={mediaPage}
          scheduleListPage={scheduleListPage}
          setScheduleListPage={setScheduleListPage}
          setMediaPage={setMediaPage}
          addToast={addToast}
          handleOpenRequestModal={handleOpenRequestModal}
          setEditAppointment={setEditAppointment}
          searchProfileResponse={searchProfileResponse}
        />
      )}

      {/* )} */}

      {/* ---- START request modal ----- */}
    </>
  );
};
export default MessageBox;

function newFunction(index, grouopUserList, item) {
  return index < 3
    ? grouopUserList.length === 1 && index === 0
      ? item.business_name
        ? item.business_name
        : item.name
      : grouopUserList.length === 2
      ? index === 0
        ? item.business_name
          ? item.business_name + " , "
          : item.name + " , "
        : item.business_name
        ? item.business_name
        : item.name
      : grouopUserList.length === 3
      ? index === 2
        ? item.business_name
          ? item.business_name
          : item.name
        : item.business_name
        ? item.business_name + " , "
        : item.name + " , "
      : index === 2
      ? item.business_name
        ? item.business_name
        : item.name
      : item.business_name
      ? item.business_name + " ,"
      : item.name + " ,"
    : "";
}
