import React, { useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { API_BASE_URL, IMAGE_BASE_URL } from "../../library/urls";
import {
  filterSelectedValueOfArr,
  getCookie,
  onlyUniqueArrOfObject,
  scroolTop,
} from "../../library/utilities/functions";
import { useToasts } from "react-toast-notifications";
import chroma from "chroma-js";
import { SELLER_UPDATE_STATUS } from "../../library/urls";
import { useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { changeGeneralInfoInput } from "../Account/AccountAction";
import { MdLocationPin } from "react-icons/md";
import {
  createNewMainServices,
  createNewSecondaryServices,
  fetchServiceListApi,
  signUpAddDataApi,
} from "../../library/api/AccountApiService";

import { changeBasicInfoInput } from "../Account/AccountAction";

const selectService = [
  {
    id: "1",
    title: "Face To Face",
    uniqueId: "face",
    className: "btn-secondary",
  },
  { id: "2", title: "Remote", uniqueId: "remote", className: "btn-blue" },
  { id: "3", title: "Both", uniqueId: "both", className: "btn-primary" },
];
const IndividualOverView = ({ isEdit, profileData }) => {
  const [workName, setWorkName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectMainServices, setSelectMainServices] = useState([]);
  const [selectSecondryServices, setSelectSecondryServices] = useState([]);
  const [uploadSellerImageUrl, setUploadSellerImageUrl] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [getAllIndividualData, setGetAllIndividualData] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [error, setError] = useState(false);
  const [uploadImage, setUploadImage] = useState("");
  const [isCreatedMainServices, setIsCreatedMainServices] = useState([]);
  const [isCreateSecondaryService, setIsCreateSecondaryService] = useState([]);
  const [filterMainServiceOption, setFilterMainServiceOption] = useState([]);
  const [filterSecondaryOption, setfilterSecondaryOption] = useState([]);
  const openPencileEl = useRef(null);
  const [page, setPage] = useState(1);

  const [cloneMainService, setCloneMainService] = useState([]);
  const [cloneSecondaryService, setCloneSecondaryService] = useState([]);
  const { addToast } = useToasts();

  // const {
  //   formState: { errors },
  // } = useForm();

  const dispatch = useDispatch();
  const AccountStateData = useSelector((state) => state.AccountReducer);

  const {
    getProfileApi: { getProfileResponse },
  } = AccountStateData;

  const addAndUpdateApiFunc = () => {
    const data = new FormData();
    data.append("form_step", 1);
    data.append("profile_image", uploadImage);
    data.append("type_individual", 1);
    data.append("seller_status", 1);
    data.append("work_name", workName);

    data.append("user_id", profileData.id);

    selectMainServices.forEach((item, i) => {
      if (
        item.value !== undefined &&
        (item?.status === 1 || item.status == "Active")
      ) {
        data.append(
          `main_service[${i}][value]`,
          item.value !== undefined &&
            (item?.status === 1 || item.status == "Active")
            ? item.value
            : ""
        );
      }
    });

    selectMainServices.forEach((item, i) => {
      if (item.status !== 0 && item.status !== undefined) {
        data.append(
          `main_service[${i}][id]`,
          item.id === undefined ? "" : item.id
        );
      }
    });

    selectSecondryServices.forEach((item, i) => {
      if (
        item.value !== undefined &&
        (item?.status === 1 || item.status == "Active")
      ) {
        data.append(
          `secondary_service[${i}][value]`,
          item.value !== undefined &&
            (item?.status === 1 || item.status == "Active")
            ? item.value
            : ""
        );
      }
    });

    selectSecondryServices.forEach((item, i) => {
      if (item.status !== 0 && item.status !== undefined) {
        data.append(
          `secondary_service[${i}][id]`,
          item.id === undefined ? "" : item.id
        );
      }
    });

    data.append("has_office_address", 0);
    data.append("zipcode", zipCode);

    data.append("service_provide_type", selectedOption);
    if (selectedOption != 2) {
      data.append("area", selectedArea);
      data.append("lat", latitude);
      data.append("long", longitude);
    }

    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };

    Swal.fire({
      title: "<h5 style='color:#212529'>" + "Do you want to save?" + "</h5>",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Save",
      confirmButtonColor: "#00a8f3",
      denyButtonColor: "#88001b",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(signUpAddDataApi(data)).then(() =>
          axios(SELLER_UPDATE_STATUS, config).then((response) => {})
        );
        var next = jQuery(".seller-tabs  .active").parent("li").next("li");
        next.find("a").removeClass("disabled");
        next.find("a").trigger("click");
        scroolTop();
        window.setTimeout(() => {
          let generaldata = {
            skills: !!getProfileResponse.skills
              ? getProfileResponse.skills.map((data) => {
                  return { label: data.skill_name, value: data.id };
                })
              : [],
          };
          dispatch(
            changeGeneralInfoInput({
              ...AccountStateData.GeneralInput,
              ...generaldata,
            })
          );
        }, 0);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  useEffect(() => {
    setUploadSellerImageUrl(IMAGE_BASE_URL + profileData.profile_image);
  }, [profileData?.profile_image?.length > 0]);

  const handleSaveStepOne = (id) => {
    if (id === 1) {
      if (
        isEdit &&
        workName.length > 0 &&
        selectMainServices.length > 0 &&
        selectSecondryServices.length > 0 &&
        selectMainServices?.some((item) => item.status == 1) &&
        selectSecondryServices?.some((item) => item.status == 1)&&
        selectedOption != "null"
      ) {
        /* if select remote option then data add in api else given error  */
        if (
          selectedOption !== 2 && selectedOption == 2
            ? true
            : selectedArea.length > 0
        ) {
          /*all api function */
          addAndUpdateApiFunc();
        }
      } else {
        setError(true);
      }
    } else if (id === 2) {
      if (
        isEdit &&
        workName.length > 0 &&
        selectMainServices.length > 0 &&
        selectSecondryServices.length > 0 &&
        selectedOption.length > 0 &&
        selectMainServices?.some((item) => item.status == 1) &&
        selectSecondryServices?.some((item) => item.status == 1)
      ) {
        /* if select remote option then data add in api else given error  */
        if (
          selectedOption !== 2 && selectedOption == 2
            ? true
            : selectedArea.length > 0
        ) {
          /*all api function */
          addAndUpdateApiFunc();
        } else {
          setError(true);
        }
      } else if (
        isEdit === false &&
        workName.length > 0 &&
        selectMainServices.length > 0 &&
        selectSecondryServices.length > 0 &&
        selectMainServices?.some((item) => item.status == 1) &&
        selectSecondryServices?.some((item) => item.status == 1)&&
        selectedOption != "null"
      ) {
        /* if select remote option then data add in api else given error  */
        if (
          selectedOption !== 2 && selectedOption == 2
            ? true
            : selectedArea.length > 0
        ) {
          var next = jQuery(".seller-tabs  .active").parent("li").next("li");
          next.find("a").removeClass("disabled");
          next.find("a").trigger("click");
          scroolTop();
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
  };

  const handleAddress = async (placeId) => {
    setSelectedArea(placeId.label);
    const results = await geocodeByAddress(placeId.label);
    const { lat, lng } = await getLatLng(results[0]);

    if (lat) setLatitude(lat);
    setLongitude(lng);
    const { place_id } = results[0];
    const place = await geocodeByPlaceId(place_id);
    const { long_name: postalCode = "" } =
      place[0].address_components.find((c) =>
        c.types.includes("postal_code")
      ) || {};
    setZipCode(postalCode);
  };

  /* add profile image in individual */
  const handleFileChange = (e) => {
    var data = e.target.files[0];
    if (!!data) {
      const fileName = data.name.split(".");
      const imageFormat = fileName[fileName.length - 1];
      if (e.target.files[0]) {
        if (
          imageFormat === "png" ||
          imageFormat === "jpg" ||
          imageFormat === "jpeg" ||
          imageFormat === "SVG" ||
          imageFormat === "svg" ||
          imageFormat === "PNG" ||
          imageFormat === "JPG" ||
          imageFormat === "JPEG"
        ) {
          dispatch(
            changeBasicInfoInput({ [e.target.name]: e.target.files[0] })
          );
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            dispatch(changeBasicInfoInput({ [e.target.id]: reader.result }));
          });
          reader.readAsDataURL(e.target.files[0]);
          setUploadImage(e.target.files[0]);
          const uploadUrl = URL.createObjectURL(e.target.files[0]);
          setUploadSellerImageUrl(uploadUrl);
        } else {
          addToast("Only .png, .jpg, .jpeg image formats supported.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    }
  };
  const handleWork = (e) => {
    setWorkName(e.target.value.slice(0, 40));
  };
  const onValueChange = (e) => {
    setSelectedOption(e.target.value);
  };

  /* set all individual data in state */
  useEffect(() => {
    if (!!Object.keys(getAllIndividualData).length) {
      setSelectMainServices(getAllIndividualData?.main_service);
      setSelectSecondryServices(getAllIndividualData?.secondary_service);
      setSelectedArea(getAllIndividualData?.area);
      setSelectedOption("" + getAllIndividualData?.service_provide_type);
      setUploadSellerImageUrl(IMAGE_BASE_URL + profileData?.profile_image);
      setLatitude(getAllIndividualData?.lat);
      setLongitude(getAllIndividualData?.long);
    }
  }, [getAllIndividualData]);

  /* get all individual data */
  useEffect(async () => {
    setGetAllIndividualData(
      AccountStateData?.getAllSellerDataApi?.getAllSellerDatas
    );
  }, [AccountStateData]);

  useEffect(() => {
    if (getAllIndividualData?.work_name) {
      setWorkName(getAllIndividualData?.work_name);
    } else {
      setWorkName(profileData.work);
    }
  }, [profileData.work, getAllIndividualData]);

  const servicesFunc = (opt, services) => {
    if (!!services?.length) {
      if (opt.length - 1 === services?.length) {
        const x = opt[opt.length - 1];
        let arr = [
          ...services,
          {
            label: x.label,
            id: x.id,
            value: x.value,
            user_id: services.user_id,
            status: x.status,
          },
        ];
        return arr;
      } else {
        let res = services?.filter(
          (x1) => !opt.some((x2) => x1.label === x2.label)
        );
        const deleteSingleVal = services?.filter(
          (item) => res[0].label != item.label
        );
        return deleteSingleVal;
      }
    } else {
      return opt;
    }
  };

  const handleServices = (opt, data, actionMeta) => {
    if (data === "main") {
      if (actionMeta.action === "create-option") {
        const newOption = {
          value: actionMeta.option?.value,
          label: actionMeta.option?.label,
          __isNew__: true,
          status: 0,
        };

        if (getAllIndividualData?.main_service.length > 0) {
          setIsCreatedMainServices([
            ...getAllIndividualData?.main_service,
            ...isCreatedMainServices,
            newOption,
          ]);
        } else {
          setIsCreatedMainServices([...isCreatedMainServices, newOption]);
        }
        dispatch(
          createNewMainServices({ service_name: actionMeta.option?.value })
        )
          .then((res) => {
            if (res.response.data.status === 400) {
              addToast(res.response.data.message, {
                appearance: "error",
                autoDismiss: true,
              });
            } else {
              addToast(res.response.data.message, {
                appearance: "success",
                autoDismiss: true,
              });
            }
          })
          .catch((err) => {
            addToast(err, {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }

      const arr = servicesFunc(opt, selectMainServices);
      setSelectMainServices(arr);
    } else {
      if (actionMeta.action === "create-option") {
        const newOption = {
          value: actionMeta.option?.value,
          label: actionMeta.option?.label,
          __isNew__: true,
          status: 0,
        };
        if (getAllIndividualData?.secondary_service.length > 0) {
          setIsCreateSecondaryService([
            ...getAllIndividualData?.secondary_service,
            ...isCreateSecondaryService,
            newOption,
          ]);
        } else {
          setIsCreateSecondaryService([...isCreateSecondaryService, newOption]);
        }
        dispatch(
          createNewSecondaryServices({ service_name: actionMeta.option?.value })
        )
          .then((res) => {
            if (res.response.data.status === 400) {
              addToast(res.response.data.message, {
                appearance: "error",
                autoDismiss: true,
              });
            } else {
              addToast(res.response.data.message, {
                appearance: "success",
                autoDismiss: true,
              });
            }
          })
          .catch((err) => {
            addToast(err, {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }
      const arr = servicesFunc(opt, selectSecondryServices);
      setSelectSecondryServices(arr);
    }
  };
  const fetchMoreOptions = () => {
    setPage(page + 1);
  };
  useEffect(() => {
    if (page != 1) {
      const data = {
        page: page,
      };
      dispatch(fetchServiceListApi(data));
    }
  }, [page]);

  const handleInputChange = (character, type) => {
    const data = new FormData();
    data.append("search", character || null);
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };
    axios(API_BASE_URL + "/searchServicesAndIndustry", config).then((res) => {
      if (res.data.list) {
        if (!!res.data.list.length) {
          if (type == "service") {
            const mainServices = filterSelectedValueOfArr(
              res.data.list,
              selectMainServices
            );
            setFilterMainServiceOption(mainServices);
          } else if (type == "secondaryService") {
            const newSecondaryOption = filterSelectedValueOfArr(
              res.data.list,
              selectSecondryServices
            );
            setfilterSecondaryOption(newSecondaryOption);
          }
        } else {
          setFilterMainServiceOption(cloneMainService);
          setfilterSecondaryOption(cloneSecondaryService);
        }
      }
    });
  };

  useEffect(() => {
    const newMainOption = filterSelectedValueOfArr(
      AccountStateData?.servicesList,
      selectMainServices
    );

    const uniqueValue = onlyUniqueArrOfObject(newMainOption);
    setFilterMainServiceOption(uniqueValue);
    setCloneMainService(uniqueValue);
  }, [AccountStateData?.servicesList, selectMainServices]);

  useEffect(() => {
    const newSecondaryOption = filterSelectedValueOfArr(
      AccountStateData?.servicesList,
      selectSecondryServices
    );
    const uniqueValue = onlyUniqueArrOfObject(newSecondaryOption);
    setfilterSecondaryOption(uniqueValue);
    setCloneSecondaryService(uniqueValue);
  }, [AccountStateData?.servicesList, selectSecondryServices]);

  // when created new main services by user apply this style//
  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: isCreatedMainServices.some(
        (item) => item.value === state.data.value && item.status === 0
      )
        ? "#ffbcc2"
        : "#e6e6e6",
    }),
  };

  // once main services is created after reload page this style apply //
  const mainServicestyle = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor:
        getAllIndividualData?.main_service.length > 0 &&
        getAllIndividualData?.main_service.some(
          (item) => item.label === state.data.label && item.status === 0
        )
          ? "#ffbcc2"
          : "#e6e6e6",
    }),
  };

  // when user created new secondary services apply this style//
  const customStyleForSecondary = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: isCreateSecondaryService.some(
        (item) => item.value === state.data.value && item.status === 0
      )
        ? "#ffbcc2"
        : "#e6e6e6",
    }),
  };

  // once secondary services is created by user after page reload apply this style//
  const secondaryServicestyle = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor:
        getAllIndividualData?.secondary_service.length > 0 &&
        getAllIndividualData?.secondary_service.some(
          (item) => item.label === state.data.label && item.status === 0
        )
          ? "#ffbcc2"
          : "#e6e6e6",
    }),
  };



  return (
    <div
      className="tab-pane fade  show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <h5>Tell us more about yourself?</h5>
      <p>
        If you haven’t already done it, put a face to your name ! Upload a
        profile picture that clearly shows your face. Tell the world about the
        kind of work you do .
      </p>
      <form method="post">
        <div className="form-group">
          <div className="seller-info d-flex">
            <div className="profile-header-img seller-img">
              {!!profileData.is_individual && (
                <img
                  onError={(ev) =>
                    (ev.target.src = "/assets/images/member-default.svg")
                  }
                  src={
                    isEdit
                      ? uploadSellerImageUrl
                      : IMAGE_BASE_URL + profileData.profile_image
                  }
                  alt="Profile-img da"
                />
              )}
              {isEdit ? (
                <a href="javascript:;">
                  <i
                    className="ri-pencil-line"
                    onClick={() => openPencileEl.current.click()}
                  ></i>
                </a>
              ) : (
                ""
              )}
              <input
                src={getAllIndividualData?.industry}
                type="file"
                id="profileImgData"
                name="profileImage"
                className="d-none"
                accept={getAllIndividualData?.industry}
                ref={openPencileEl}
                onChange={handleFileChange}
              />
            </div>
            <div className="seller-name">
              <label>
                {" "}
                {!!profileData.is_individual && !!profileData
                  ? profileData.name
                  : profileData.business_name}
              </label>
              <input
                type="text"
                value={isEdit ? workName : getAllIndividualData?.work_name}
                className="form-control"
                disabled={isEdit ? false : true}
                placeholder="Work"
                onChange={handleWork}
              />
              {/* {errors.firstName && <p>Please check the First Name</p>} */}
              {error && !workName?.length ? (
                <p className="error-message" style={{ color: "red" }}>
                  Please add the Work{" "}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group create-select-field">
          <h5 className="mb-3">
            Tell us about the work you do. What is the main service and other
            secondary services you are willing to offer?
          </h5>
          <div className="mb-3">
            <label>Main Services</label>
            <CreatableSelect
              options={filterMainServiceOption}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Services"
              isDisabled={isEdit ? false : true}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onInputChange={(text) => handleInputChange(text, "service")}
              onMenuScrollToBottom={fetchMoreOptions}
              isClearable
              isMulti
              formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
              styles={
                isCreatedMainServices?.length > 0
                  ? customStyles
                  : mainServicestyle
              }
              value={
                isEdit
                  ? selectMainServices &&
                    selectMainServices?.map((items) => {
                      return {
                        label: items.label,
                        value: items.value,
                        __isNew__: true,
                      };
                    })
                  : getAllIndividualData?.main_service?.length !== 0
                  ? getAllIndividualData?.main_service?.map((data) => {
                      return {
                        label: data.label,
                        value: data.value,
                        __isNew__: true,
                      };
                    })
                  : null
              }
              onChange={(opt, actionMeta) =>
                handleServices(opt, "main", actionMeta)
              }
            />

            {error &&
              (selectMainServices?.length === 0 ||
                selectMainServices?.every((item) => item.status === 0)) && (
                <p className="error-message" style={{ color: "red" }}>
                  Please select the active Main Services
                </p>
              )}

            {/* {isCreateSecondaryService?.length > 0 && (
              <p className="text-danger">
                *The service(s) highlighted with a red background are not
                currently included in our existing list. We will conduct a
                diligent evaluation and promptly notify you upon granting our
                approval.
              </p>
            )} */}

            {/* {
            (
              isCreatedMainServices?.length > 0 ||
              (
              
     selectMainServices?.some((item) => item.status ===0))
     
     (
      && (
                  <p className="text-danger">
                    *The service(s) highlighted with a red background are not
                    currently included in our existing list. We will conduct a
                    diligent evaluation and promptly notify you upon granting
                    our approval.
                  </p>
                )} */}

            {(isCreatedMainServices?.length > 0 ||
              selectMainServices?.some((item) => item.status === 0)) && (
              <p className="text-danger">
                *The service(s) highlighted with a red background are not
                currently included in our existing list. We will conduct a
                diligent evaluation and promptly notify you upon granting our
                approval.
              </p>
            )}
          </div>
          <div className="mb-3">
            <label>Secondary Services</label>
            <CreatableSelect
              options={filterSecondaryOption}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Services"
              onInputChange={(text) =>
                handleInputChange(text, "secondaryService")
              }
              onMenuScrollToBottom={fetchMoreOptions}
              isClearable
              styles={
                isCreateSecondaryService?.length > 0
                  ? customStyleForSecondary
                  : secondaryServicestyle
              }
              isDisabled={isEdit ? false : true}
              value={
                isEdit
                  ? selectSecondryServices?.map((items) => {
                      return {
                        label: items.label,
                        value: items.value,
                        status: items.status,
                      };
                    })
                  : getAllIndividualData?.secondary_service?.length !== 0
                  ? getAllIndividualData?.secondary_service?.map((data) => {
                      return {
                        label: data.label,
                        value: data.value,
                        __isNew__: true,
                        status: data.status,
                      };
                    })
                  : null
              }
              isMulti
              onChange={(opt, actionMeta) =>
                handleServices(opt, "secondary", actionMeta)
              }
            />

            {error &&
              (selectSecondryServices?.length === 0 ||
                selectSecondryServices?.every((item) => item.status === 0)) && (
                <p className="error-message" style={{ color: "red" }}>
                  Please select the active Secondary Services
                </p>
              )}

            {(isCreateSecondaryService?.length > 0 ||
              selectSecondryServices?.some((item) => item.status === 0)) && (
              <p className="text-danger">
                *The service(s) highlighted with a red background are not
                currently included in our existing list. We will conduct a
                diligent evaluation and promptly notify you upon granting our
                approval.
              </p>
            )}
          </div>
        </div>
        <div className="form-group">
          <h5 className="mb-3">
            How would you like to provide your services ?{" "}
          </h5>
          <div className="custom-input d-flex">
            {selectService.map((items, i) => {
              let { id } = items;
              return (
                <div className="custom-input__inner">
                  <input
                    id={items.uniqueId}
                    type="radio"
                    name="service-method"
                    value={id}
                    onChange={(e) => onValueChange(e)}
                    disabled={isEdit ? false : true}
                    checked={
                      isEdit
                        ? id == selectedOption
                        : id == getAllIndividualData?.service_provide_type
                    }
                  />
                  <label htmlFor={items.uniqueId} className={items.className}>
                    {items.title}
                  </label>
                </div>
              );
            })}
          </div>
          {error && selectedOption == "null" && (
            <p className="error-message" style={{ color: "red" }}>
              Please select the Service
            </p>
          )}
        </div>
        <div className="form-group">
          {selectedOption == 2 ? (
            ""
          ) : (
            <>
              <h5 className="mb-3">
                Add the areas you are willing travel to serve your customers?
              </h5>
              <GooglePlacesAutocomplete
                apiKey={"AIzaSyDjlDbFVEXKgnmrVYYLZEMlL0Wt7FEMO9M"}
                value={selectedArea}
                selectProps={{
                  placeholder: "Area",
                  isDisabled: isEdit ? false : true,
                  onChange: (place) => {
                    handleAddress(place);
                  },
                }}
              />
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="mt-2"
              >
                <p style={{ fontSize: 20 }}>Location:</p>
                <p
                  className="ml-2 "
                  style={{ fontSize: 20, fontWeight: "bolder" }}
                >
                  {isEdit ? selectedArea : getAllIndividualData?.area}
                  <MdLocationPin color="#EA4335" />
                </p>
              </div>

              {error && selectedOption != 2 && !selectedArea?.length && (
                <p className="error-message" style={{ color: "red" }}>
                  Please select the area
                </p>
              )}
            </>
          )}
        </div>
        <div className="form-group d-flex justify-content-end flex-wrap btn-wrapper mt-5">
          {isEdit && (
            <a
              href="javascript:;"
              className="btn btn-blue mr-3"
              onClick={() => handleSaveStepOne(1)}
              type="submit"
            >
              Save
            </a>
          )}
          <a
            href="javascript:;"
            className="btn btn-primary"
            onClick={() => handleSaveStepOne(2)}
          >
            Next to Skills &amp; Expertise
          </a>
        </div>
      </form>
    </div>
  );
};
export default IndividualOverView;
