import moment from 'moment';
import React, { useEffect, useState } from 'react'
const HoursOfOperation = (props) => {
    const weekday = [
        { id: 1, day: "Sunday", isOpen: ["Closed"] },
        { id: 2, day: "Monday", isOpen: ["Closed"] },
        { id: 3, day: "Tuesday", isOpen: ["Closed"] },
        { id: 4, day: "Wednesday", isOpen: ["Closed"] },
        { id: 5, day: "Thursday", isOpen: ["Closed"] },
        { id: 6, day: "Friday", isOpen: ["Closed"] },
        { id: 7, day: "Saturday", isOpen: ["Closed"] },
    ];
    const [todayStatus, setTodayStatus] = useState('')
    const [weekendArr, setWeekendArr] = useState([])
    const TodayDate = new Date().getDay()

    const dat = new Date()
    function getTwentyFourHourTime(amPmString) {
        var date = new Date("1/1/2013 " + amPmString);
        return `${dat.getFullYear()}-${(dat.getMonth() + 1).toString().length < 2
            ? "0" + (dat.getMonth() + 1).toString()
            : dat.getMonth() + 1
            }-${dat.getDate().toString().length < 2
                ? "0" + dat.getDate().toString()
                : dat.getDate()
            }T${date.getHours().toString().length < 2
                ? "0" + date.getHours().toString()
                : date.getHours()}:${date.getMinutes().toString().length < 2
                    ? "0" + date.getMinutes().toString()
                    : date.getMinutes()}`;
    }
    useEffect(() => {
        const arr = []
        const TodayDate = new Date().getDay();
        let weekendArrayClone = weekday;
        !!props.hours_details && props.hours_details.availability.map((item, i) => {
            weekendArrayClone[TodayDate].day = weekendArrayClone[TodayDate].day
            if (item.day_of_week == weekendArrayClone[TodayDate].day) {
                const date1 = new Date(getTwentyFourHourTime(item.time_from));
                const date2 = new Date(getTwentyFourHourTime(item.time_to));
                if (dat.getTime() <= date1.getTime() && item.time_from !== "OPEN 24 HOURS") {
                    weekendArrayClone[TodayDate].day = "Today"
                    weekendArrayClone[TodayDate].isOpen = ['Closed', `Open ${item.time_from}`]
                    arr.push(weekendArrayClone[TodayDate].isOpen)
                } else if (dat.getTime() < date2.getTime() && item.time_from != "OPEN 24 HOURS") {
                    weekendArrayClone[TodayDate].day = "Today"
                    weekendArrayClone[TodayDate].isOpen = [`Open`, `Closes ${item.time_to}`]
                    arr.push(weekendArrayClone[TodayDate].isOpen)
                } if (item.time_from === "OPEN 24 HOURS") {
                    weekendArrayClone[TodayDate].isOpen = [item.time_from]
                    arr.push(weekendArrayClone[TodayDate].isOpen)
                }
            }
            if (!!weekendArrayClone[TodayDate + 1] && item.day_of_week == weekendArrayClone[TodayDate + 1]?.day) {
                weekendArrayClone[TodayDate + 1].isOpen = [item.time_from, item.time_to]
            } else {
                const indexdata = weekday?.findIndex(
                    (data) => data.day === item.day_of_week
                );
                if (!!weekendArrayClone[indexdata] && item.day_of_week !== weekendArrayClone[TodayDate].day) {
                    weekendArrayClone[indexdata].isOpen = [item.time_from, item.time_to]
                }
            }
        })
        setTodayStatus(...arr)
        setWeekendArr(weekendArrayClone)
    }, [props.hours_details])
    useEffect(() => {
        let clone = weekendArr;
        var x = moment().isoWeekday()
        if (!!weekendArr && !!weekendArr[TodayDate]?.day && !!weekendArr[x]?.day) {
            if (weekendArr[TodayDate]?.day == weekendArr[x]?.day && !todayStatus || todayStatus[0] === 'OPEN 24 HOURS') {
                clone[TodayDate].day = "Today"
            }
            if (!!weekday[TodayDate + 1] && weekendArr[TodayDate + 1]?.day == weekendArr[x + 1]?.day
            ) {
                clone[TodayDate + 1].day = "Tomorrow"
            } else {
                clone[TodayDate - 6].day = "Tomorrow"
            }
            setWeekendArr(clone)
        }
    }, [todayStatus, weekendArr])


    return (
        <div className="user-info__blk">
            <h5 className="user-info__heading mb-3">
                <i className="ri-time-line"></i> Hours of Operation
            </h5>
            {!!props.hours_details?.availability?.length && <div className="main-availibility">
                {!!todayStatus && todayStatus[0] ?
                    <span className="availibility-status">
                        <span className={`${todayStatus[0] == "Open" || todayStatus[0] === 'OPEN 24 HOURS' ? "text-success" : "text-secondary"}`}>{todayStatus[0].charAt(0).toUpperCase() + todayStatus[0].slice(1).toLowerCase()}</span>
                        <span >{`${todayStatus[1] ? "-" + todayStatus[1] : ""} `}<i className="ri-arrow-down-s-line align-middle" /></span>
                    </span> :
                    <span className="text-secondary availibility-status">
                        Closed
                        <i className="ri-arrow-down-s-line align-middle " />
                    </span>}
                <ul className="availibility-wrapper">
                    {!!weekendArr && weekendArr.map((item) => {
                        return (
                            <li key={item.id} className="d-flex">
                                <div className="weekly-day" style={{ color: item.day == "Today" ? "#684cf5" : item.day == "Tomorrow" ? "#96082e" : null }} >
                                    {item.day}
                                </div>
                                <div className={`${item.isOpen[0] == "Open" ? "text-success" : ""} `}>
                                    {item.isOpen[0] == 'OPEN 24 HOURS' ? item.isOpen[0].charAt(0).toUpperCase() + item.isOpen[0].slice(1).toLowerCase() : item.isOpen[0]}
                                </div>
                                <div>
                                    {!!item.isOpen[1] && "-" + item.isOpen[1]}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>}
        </div>
    )
}
export default HoursOfOperation;