import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../../urls";
import parse from "html-react-parser";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

export default function AboutLanding() {
  const landingPageContentData = useSelector(
    (state) => state.LandingPageReducer.landingPageContent
  );
  const [description, setDescription] = useState();
  const { getLandingPageData } = landingPageContentData;
  useEffect(() => {
    AOS.init({
      duration: 900,

    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    if (!!getLandingPageData?.about_us) {
      setDescription(getLandingPageData?.about_us?.description.split(" . "));
    }
  }, [getLandingPageData?.about_us]);

  return (
    <div id="about">
      <section className="spacer theme-primary-bg text-white section-bright">
        <div className="container">
          <div className="row align-items-center" data-aos="fade-up">
            {!!getLandingPageData && (
              <>
                <div className="col-md-6 mb-4 mb-md-0">
                  <img
                    src={`${IMAGE_BASE_URL + getLandingPageData?.about_us?.image
                      }`}
                    alt="about"
                    className="br-20"
                  />
                </div>

                <div className="col-md-6 ps-4">
                  <span className="section__label">About Us</span>
                  <h2 className="section__subheading mb-0">
                    {!!getLandingPageData?.about_us &&
                      getLandingPageData?.about_us?.title}
                  </h2>
                  <div className="section__content my-3">
                    <p>
                      {!!getLandingPageData?.about_us &&
                        !!description &&
                        description.map((item, i) => (
                          <div key={i}>
                            {parse(item)}
                          </div>
                        )
                        )}
                    </p>
                  </div>
                  <Link to="/signup" className="btn mt-3">
                    Get Started
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
