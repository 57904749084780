import React from "react";
import { Feeds, FirstTimeLogin } from "./common";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  clearCookies,
  getCookie,
  scroolTop,
} from "../../library/utilities/functions";
import { useToasts } from "react-toast-notifications";
import $ from "jquery";
import { SOCKET } from "../../library/urls";
import { changeIsAuth } from "../../library/common/actions/AuthActions";
import PromotionalHub from "./common/PromotionalHub";

const Home = () => {
  const HomeState = useSelector((state) => state.HomeReducer);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  useEffect(() => {
    scroolTop();
    $("#feeds-content").on("keypress", function (e) {
      var that = $(this),
        textLength = that.val().length;

      if (textLength < 230) {
        that.css("font-size", "20px");
      } else if (textLength < 250) {
        that.css("font-size", "16px");
      } else if (textLength < 400) {
        that.css("font-size", "15px");
      }
    });
  }, []);

  // if user logged in with same account on multiple device or browser logged out that user from previous device or browser//
  useEffect(() => {
    SOCKET.off("logout").on("logout", () => {
      dispatch(changeIsAuth({ is_auth: false }));
      addToast("Your account is logged in on another browser or device.", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2500,
      });

      clearCookies();
      SOCKET.close();
      window.localStorage.removeItem("servicenotification");
      window.localStorage.removeItem("clickServiceNotification");
      window.localStorage.removeItem("ClickOnSeachProfile");
      window.localStorage.removeItem("tabActive");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("isSession");
      window.sessionStorage.clear();

      setTimeout(() => {
        // window.location.replace("/");
      }, 3000);
    });
  }, []);

  let firstTimeLogin = !!getCookie("firstTimeLogin")
    ? getCookie("firstTimeLogin")
    : "";
  return (
    <div>
      {!!firstTimeLogin ? (
        <FirstTimeLogin />
      ) : (
        <>
          <Feeds HomeState={HomeState} />

        
        </>
      )}
    </div>
  );
};
export default Home;
