import {
  ACCEPT_SCHEDULE_EVENTS,
  ALL_FRIEND_AND_GROUP_LIST,
  CLEAR_CHAT_LIST,
  CLEAR_SCHEDULE_LIST,
  DECLINE_SCHEDUE_EVENTS,
  GET_ALL_SCHEDULE_EVENTS,
  GET_SINGLE_GROUP_DATA_FAILURE,
  GET_SINGLE_GROUP_DATA_REQUEST,
  GET_SINGLE_GROUP_DATA_SUCCESS,
} from "./PoolsChatConstant";

const initialState = {
  getChatandGroupList: [],
  singleGroupData: {
    getSingleGroupDataLoading: false,
    getSignleGroupDataStatus: "",
    getSingleGroupSuccess: "",
    getSingleGroupMessage: "",
    getSingleGroupData: {},
  },

  getScheduleListData: {
    getScheduleList: [],
    getScheduleListCounts: 0,
  },
};

export const chatListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_FRIEND_AND_GROUP_LIST:
      return {
        ...state,
        getChatandGroupList: action?.newState,
      };

    case CLEAR_SCHEDULE_LIST:
      let clearScheduleListData = {
        getScheduleList: [],
        getScheduleListCounts: 0,
      };

      return {
        ...state,
        ...{
          getScheduleListData: {
            ...state.getScheduleListData,
            ...clearScheduleListData,
          },
        },
      };

      case DECLINE_SCHEDUE_EVENTS:
        let declineScheduleListData = {
          getScheduleList: action.newState?.list,
          getScheduleListCounts:
          !!action.newState?.list?.length > 0
            ? action.newState.list.length
            : [],
        }

        return {
          ...state,...{
            getScheduleListData: {
              ...state.getScheduleListData,
              ...declineScheduleListData,
            },
          }
        }


        case ACCEPT_SCHEDULE_EVENTS:
          let acceptScheduleListData = {
            getScheduleList: action.newState?.list,
            getScheduleListCounts:
            !!action.newState?.list?.length > 0
              ? action.newState.list.length
              : [],
          }
  
          return {
            ...state,...{
              getScheduleListData: {
                ...state.getScheduleListData,
                ...acceptScheduleListData,
              },
            }
          }

    case GET_ALL_SCHEDULE_EVENTS:
      if (action?.newState) {
        // let scheduleListSuccess = {

        //   getScheduleList: [
        //     ...state.getScheduleListData.getScheduleList,
        //     ...action.newState?.list,
        //   ],

        //   getScheduleListCounts:
        //     !!action.newState?.list?.length > 0
        //       ? action.newState.list.length
        //       : [],
        // };


        const combinedList = [
          ...state.getScheduleListData.getScheduleList,
          ...action.newState?.list,
        ];
    
        // Create a Map to store the unique items based on event_id
        const uniqueMap = new Map();
        combinedList.forEach(item => {
          if (!uniqueMap.has(item.event_id) || item.updatedAt > uniqueMap.get(item.event_id).updatedAt) {
            uniqueMap.set(item.event_id, item);
          }
        });
    
        // Convert the Map values back to an array to get the unique items
        const uniqueList = Array.from(uniqueMap.values());
        return {
          ...state,
          ...{
            getScheduleListData: {
              getScheduleList: uniqueList,
              getScheduleListCounts: uniqueList.length
            },
          },
        };
      }

    case GET_SINGLE_GROUP_DATA_REQUEST:
      return {
        ...state,
        ...{
          singleGroupData: {
            ...state.singleGroupData,
            ...{ getSingleGroupDataLoading: true },
          },
        },
      };
    case GET_SINGLE_GROUP_DATA_SUCCESS:
      let getSingleGroupDetailsSuccess = {
        getSingleGroupDataLoading: false,
        getSignleGroupDataStatus: 200,
        getSingleGroupSuccess: action.response.data.success,
        getSingleGroupMessage: action.response.data.message,
        getSingleGroupData: action.response.data
          ? action.response.data?.data
          : {},
      };
      return {
        ...state,
        ...{
          singleGroupData: {
            ...state.singleGroupData,
            ...getSingleGroupDetailsSuccess,
          },
        },
      };
    case GET_SINGLE_GROUP_DATA_FAILURE:
      let getSingleGroupFailure = {
        getSingleGroupDataLoading: false,
        getSignleGroupDataStatus: action?.status,
        getSingleGroupSuccess: false,
        getSingleGroupMessage: action.response.data.message,
        getSingleGroupData: {},
      };
      return {
        ...state,
        ...{
          singleGroupData: {
            ...state.singleGroupData,
            ...getSingleGroupFailure,
          },
        },
      };

    case CLEAR_CHAT_LIST:
      return {
        getChatandGroupList: action.newState,
      };

    default:
      return state;
  }
};
