import React, { useState, useEffect, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

import { API_BASE_URL, SELLER_UPDATE_STATUS } from "../../library/urls";
import axios, { Axios } from "axios";
import {
  filterSelectedValueOfArr,
  getCookie,
  onlyUniqueArrOfObject,
  scroolTop,
} from "../../library/utilities/functions";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { changeGeneralInfoInput } from "../Account/AccountAction";
import { MdLocationPin } from "react-icons/md";
import { IMAGE_BASE_URL } from "../../library/urls";
import {
  createNewMainServices,
  createNewSecondaryServices,
  fetchServiceListApi,
  signUpAddDataApi,
} from "../../library/api/AccountApiService";

const proviceService = [
  {
    id: 1,
    inputNameid: "face",
    value: "true",
    label: "Face to Face",
    className: "btn-secondary",
  },
  {
    id: 2,
    inputNameid: "remote",
    value: "true",
    label: "Remote",
    className: "btn-blue",
  },
  {
    id: 3,
    inputNameid: "both",
    value: "true",
    label: "Both",
    className: "btn-primary",
  },
];
const BusinessOverView = ({ isEdit, profileData }) => {
  const openPencileEl = useRef(null);
  const [bussineseName, setBussineseName] = useState("");
  const [SelectedmainService, setSelectedMainService] = useState([]);
  const [SecondaryService, setSecondaryService] = useState([]);
  const [provideSeriveId, setProvideServiceId] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [getLocationValue, setGetLocationValue] = useState(0);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [error, setError] = useState(false);
  const [uploadSellerImageUrl, setUploadSellerImageUrl] = useState("");
  const [getAllBusinessData, setGetAllBusinessData] = useState([]);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [long, setLong] = useState("");
  const [lat, setLat] = useState("");
  const [zipCodeSec, setSecZipCode] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [filterMainServiceOption, setFilterMainServiceOption] = useState([]);
  const [officeStoreAddress, setOfficeStoreAddress] = useState("");
  const [page, setPage] = useState(1);

  const [isCreatedMainServices, setIsCreatedMainServices] = useState([]);
  const [isCreateSecondaryService, setIsCreateSecondaryService] = useState([]);

  const [filterSecondaryServiceOption, setFilterSecondaryServiceOption] =
    useState([]);
  const [filterIndustryOption, setFilterIndustryOption] = useState([]);
  const { addToast } = useToasts();
  const AccountStateData = useSelector((state) => state.AccountReducer);
  const [allList, setAllList] = useState({
    industry: [],
    secondaryService: [],
  });
  const [cloneMainService, setCloneMainService] = useState([]);
  const dispatch = useDispatch();

  const {
    getProfileApi: { getProfileResponse },
  } = AccountStateData;
  const handleProvideService = (e) => {
    setProvideServiceId(e.target.value);
  };
  const {
    GeneralInput: { specialization },
  } = AccountStateData;

  // set the address when address filed change//
  const handleAddress = async (placeid) => {
    setSelectedArea(placeid.label);
    const results = await geocodeByAddress(placeid.label);
    const { lat, lng } = await getLatLng(results[0]);
    if (lat) setLatitude(lat);
    setLongitude(lng);
    const { place_id } = results[0];
    const [place] = await geocodeByPlaceId(place_id);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    setZipCode(postalCode);
  };

  // to set sec Address field//
  const handleAddress2 = async (place) => {
    setOfficeStoreAddress(place.label);
    const areaResult = await geocodeByAddress(place.label);
    const { lat, lng } = await getLatLng(areaResult[0]);
    if (lat) setLat(lat);
    setLong(lng);
    const { place_id } = areaResult[0];
    const [places] = await geocodeByPlaceId(place_id);
    const { long_name: postalCode = "" } =
      places.address_components.find((e) => e.types.includes("postal_code")) ||
      {};
    setSecZipCode(postalCode);
  };

  const handleLocation = (e) => {
    setOfficeStoreAddress("");
    setGetLocationValue(e.target.value);
  };

  // used for sec address field
  const addAndUpdateApiFunc = () => {
    const data = new FormData();
    data.append("form_step", 1);
    data.append("type_individual", 0);
    data.append("seller_status", 1);
    data.append("work_name", bussineseName);
    data.append("has_office_address", getLocationValue);
    data.append("user_id", profileData?.id);

    SelectedmainService.forEach((item, i) => {
      if (
        item.value !== undefined &&
        (item?.status === 1 || item.status == "Active")
      ) {
        data.append(
          `main_service[${i}][value]`,
          item.value !== undefined &&
            (item?.status === 1 || item.status == "Active")
            ? item.value
            : ""
        );
      }
    });

    SelectedmainService.forEach((item, i) => {
      if (item.status !== 0 && item.status !== undefined) {
        data.append(
          `main_service[${i}][id]`,
          item.id === undefined ? "" : item.id
        );
      }
    });

    SecondaryService.forEach((item, i) => {
      if (
        item.value !== undefined &&
        (item?.status === 1 || item.status == "Active")
      ) {
        data.append(
          `secondary_service[${i}][value]`,
          item.value !== undefined &&
            (item?.status === 1 || item.status == "Active")
            ? item.value
            : ""
        );
      }
    });

    SecondaryService.forEach((item, i) => {
      if (item.status !== 0 && item.status !== undefined) {
        data.append(
          `secondary_service[${i}][id]`,
          item.id === undefined ? "" : item.id
        );
      }
    });

    selectedIndustry.forEach((item, i) =>
      data.append(`industry[${i}][value]`, item.value)
    );
    selectedIndustry.forEach((item, i) =>
      data.append(`industry[${i}][id]`, item.id || "")
    );

    // for second address field//
    if (getLocationValue == 1) {
      data.append("has_office_address", getLocationValue);
      data.append("office_address", officeStoreAddress);
      data.append("office_lat", lat);
      data.append("office_long", long);
    }
    if (getLocationValue == 0) {
      data.append("has_office_address", getLocationValue);
    }
    // for first address field
    data.append("service_provide_type", provideSeriveId);
    if (provideSeriveId != 2) {
      data.append("area", selectedArea);
      data.append("lat", latitude);
      data.append("long", longitude);
    }
    data.append("business_image", uploadImage);
    data.append("zipcode", zipCode);
    data.append("office_zipcode", zipCodeSec);
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };
    Swal.fire({
      title: "<h5 style='color:#212529'>" + "Do you want to save?" + "</h5>",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Save",
      confirmButtonColor: "#00a8f3",
      denyButtonColor: "#88001b",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(signUpAddDataApi(data)).then(() =>
          axios(SELLER_UPDATE_STATUS, config).then((response) => {})
        );
        // .catch((err)=>
        // {
        //   addToast(err, {
        //     appearance: "error",
        //     autoDismiss: true,
        //     autoDismissTimeout: 2000,
        //   });
        // });
        var next = jQuery(".seller-tabs  .active").parent("li").next("li");
        next.find("a").removeClass("disabled");
        next.find("a").trigger("click");
        scroolTop();
        window.setTimeout(() => {
          let generaldata = {
            skills: !!getProfileResponse.skills
              ? getProfileResponse.skills.map((data) => {
                  return { label: data.skill_name, value: data.id };
                })
              : [],
          };
          dispatch(
            changeGeneralInfoInput({
              ...AccountStateData.GeneralInput,
              ...generaldata,
            })
          );
        }, 0);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };


  const handleSaveStepOne = (id) => {
    const __area_validation =
      provideSeriveId != 2 && selectedArea.length > 0
        ? true
        : provideSeriveId == 2
        ? true
        : false;
    const __Office_validation =
      getLocationValue == 1 && officeStoreAddress.length > 0
        ? true
        : getLocationValue == 0
        ? true
        : false;

    if (id === 1) {
      if (
        bussineseName.length > 0 &&
        SelectedmainService.length > 0 &&
        SecondaryService.length > 0 &&
        provideSeriveId.length > 0 &&
        selectedIndustry.length > 0 &&
        SecondaryService?.some((item) => item.status == 1) &&
        SelectedmainService?.some((item) => item.status == 1)&&
        getLocationValue != undefined
      ) {
        if (__area_validation && __Office_validation) {
          addAndUpdateApiFunc();
        } else {
        
          setError(true);
        }
      } else {
       
        setError(true);
      }
    } else if (id === 2) {
      if (
        isEdit == false &&
        bussineseName?.length > 0 &&
        SelectedmainService?.length > 0 &&
        SelectedmainService?.some((item) => item.status == 1) &&
        SecondaryService?.some((item) => item.status == 1) &&
        SecondaryService?.length > 0 &&
        provideSeriveId?.length > 0 &&
        selectedIndustry?.length > 0
      ) {
        /* if select remote option then data add in api else given error  */
        if (__area_validation && __Office_validation) {
          /*all api function */
          var next = jQuery(".seller-tabs  .active").parent("li").next("li");
          next.find("a").removeClass("disabled");
          next.find("a").trigger("click");
          scroolTop();
        } else {
          setError(true);
        }
      } else if (
        isEdit &&
        bussineseName?.length > 0 &&
        SelectedmainService?.length > 0 &&
        SecondaryService?.length > 0 &&
        provideSeriveId?.length > 0 &&
        SelectedmainService?.some((item) => item.status == 1) &&
        SecondaryService?.some((item) => item.status == 1) &&
        selectedIndustry?.length > 0 &&
        getLocationValue != undefined
      ) {
        /* if select remote option then data add in api else given error  */
        if (__area_validation && __Office_validation) {
          /*all api function */
          addAndUpdateApiFunc();
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
  };

  //for industry list//
  useEffect(() => {
    const option = AccountStateData?.industryList?.filter((itemA) => {
      return !selectedIndustry?.find((itemB) => {
        return itemA.industries_name === itemB.label;
      });
    });
    const newOptiom = option.map((item) => {
      return {
        label: item.industries_name,
        value: item.id,
        status: item.status,
      };
    });
    setFilterIndustryOption(newOptiom);
    setAllList({ ...allList, industry: newOptiom });
  }, [AccountStateData?.industryList, selectedIndustry]);

  // main services list//
  useEffect(() => {
    if (!!AccountStateData?.servicesList) {
      const _MAINSERVICE = filterSelectedValueOfArr(
        AccountStateData?.servicesList,
        SelectedmainService
      );
      const uniqueValue = onlyUniqueArrOfObject(_MAINSERVICE);
      setFilterMainServiceOption(uniqueValue);
      setCloneMainService(uniqueValue);
    }
  }, [AccountStateData, SelectedmainService]);

  //secondary service list//
  useEffect(() => {
    if (!!AccountStateData?.servicesList) {
      const newSecondaryOption = filterSelectedValueOfArr(
        AccountStateData?.servicesList,
        SecondaryService
      );
      const uniqueValue = onlyUniqueArrOfObject(newSecondaryOption);
      setFilterSecondaryServiceOption(uniqueValue);
      setAllList({ ...allList, secondaryService: uniqueValue });
    }
  }, [AccountStateData?.servicesList, SecondaryService]);

  /* add and sent in api business image */
  const handleFileChange = (e) => {
    var data = e.target.files[0];

    if (!!data) {
      const fileName = data.name.split(".");
      const imageFormat = fileName[fileName.length - 1];
      if (e.target.files[0]) {
        if (
          imageFormat === "png" ||
          imageFormat === "jpg" ||
          imageFormat === "jpeg" ||
          imageFormat === "SVG" ||
          imageFormat === "svg" ||
          imageFormat === "PNG" ||
          imageFormat === "JPG" ||
          imageFormat === "JPEG"
        ) {
          const reader = new FileReader();

          reader.readAsDataURL(e.target.files[0]);
          setUploadImage(e.target.files[0]);
          const uploadUrl = URL.createObjectURL(e.target.files[0]);
          setUploadSellerImageUrl(uploadUrl);
        } else {
          addToast("Only .png, .jpg, .jpeg image formats supported.", {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 6000,
          });
        }
      }
    }
  };

  /* get all data from api  */

  useEffect(async () => {
    setGetAllBusinessData(
      AccountStateData?.getAllSellerDataApi?.getAllSellerDatas
    );
  }, [AccountStateData]);

  /* add all api data when component render */
  useEffect(() => {
    if (!!Object.keys(getAllBusinessData).length) {
      setSelectedMainService(getAllBusinessData?.main_service);
      setSecondaryService(getAllBusinessData?.secondary_service);
      setSelectedArea(getAllBusinessData?.area);
      setProvideServiceId("" + getAllBusinessData?.service_provide_type);
      setSelectedIndustry(getAllBusinessData?.industry);
      setGetLocationValue(getAllBusinessData?.has_office_address);
      setUploadSellerImageUrl(IMAGE_BASE_URL + profileData.business_image);
      setUploadImage(IMAGE_BASE_URL + profileData.business_image);
      setLatitude(getAllBusinessData?.lat);
      setLongitude(getAllBusinessData?.long);
      setOfficeStoreAddress(getAllBusinessData?.office_address);
      setLat(getAllBusinessData?.office_lat);
      setLong(getAllBusinessData?.office_long);
    }
  }, [getAllBusinessData]);

  useEffect(() => {
    if (getAllBusinessData?.work_name) {
      setBussineseName(getAllBusinessData?.work_name);
    } else {
      setBussineseName(specialization);
    }
  }, [specialization, getAllBusinessData]);

  useEffect(() => {
    setUploadSellerImageUrl(IMAGE_BASE_URL + profileData.business_image);
  }, [profileData]);

  const servicesAndIndustryFunc = (opt, services) => {
    if (!!services?.length) {
      if (opt.length - 1 === services?.length) {
        const x = opt[opt.length - 1];
        let arr = [
          ...services,
          {
            label: x.label,
            id: x.id,
            value: x.value,
            user_id: services.user_id,
            status: x.status,
          },
        ];
        return arr;
      } else {
        let res = services?.filter(
          (x1) => !opt.some((x2) => x1.label === x2.label)
        );
        const deleteSingleVal = services?.filter(
          (item) => res[0].label != item.label
        );
        return deleteSingleVal;
      }
    } else {
      return opt;
    }
  };
  const handleServicesAndIndustry = async (opt, data, actionMeta) => {
    // for industries//
    if (data === "industry") {
      const arr = servicesAndIndustryFunc(opt, selectedIndustry);
      setSelectedIndustry(arr);
    }
    // for main services --->//
    else if (data === "main") {
      if (actionMeta.action === "create-option") {
        const newOption = {
          value: actionMeta.option?.value,
          label: actionMeta.option?.label,
          __isNew__: true,
          status: 0,
        };

        if (getAllBusinessData?.main_service.length > 0) {
          setIsCreatedMainServices([
            ...getAllBusinessData?.main_service,
            ...isCreatedMainServices,
            newOption,
          ]);
        } else {
          setIsCreatedMainServices([...isCreatedMainServices, newOption]);
        }
        dispatch(
          createNewMainServices({ service_name: actionMeta.option?.value })
        )
          .then((res) => {
            if (res.response.data.status === 400) {
              addToast(res.response.data.message, {
                appearance: "error",
                autoDismiss: true,
                // autoDismissTimeout: 6000,
              });
            } else {
              addToast(res.response.data.message, {
                appearance: "success",
                autoDismiss: true,
                // autoDismissTimeout: 6000,
              });
            }
          })
          .catch((err) => {
            addToast(err, {
              appearance: "error",
              autoDismiss: true,
              // autoDismissTimeout: 6000,
            });
          });
      }

      const arr = servicesAndIndustryFunc(opt, SelectedmainService);
      setSelectedMainService(arr);
    }
    // secondary services--->//
    else {
      if (actionMeta.action === "create-option") {
        const newOption = {
          value: actionMeta.option?.value,
          label: actionMeta.option?.label,
          __isNew__: true,
          status: 0,
        };

        if (getAllBusinessData?.secondary_service.length > 0) {
          setIsCreateSecondaryService([
            ...getAllBusinessData?.secondary_service,
            ...isCreateSecondaryService,
            newOption,
          ]);
        } else {
          setIsCreateSecondaryService([...isCreateSecondaryService, newOption]);
        }
        dispatch(
          createNewSecondaryServices({ service_name: actionMeta.option?.value })
        )
          .then((res) => {
            if (res.response.data.status === 400) {
              addToast(res.response.data.message, {
                appearance: "error",
                autoDismiss: true,
                // autoDismissTimeout: 6000,
              });
            } else {
              addToast(res.response.data.message, {
                appearance: "success",
                autoDismiss: true,
                // autoDismissTimeout: 6000,
              });
            }
          })
          .catch((err) => {
            addToast(err, {
              appearance: "error",
              autoDismiss: true,
              // autoDismissTimeout: 6000,
            });
          });
      }
      const arr = servicesAndIndustryFunc(opt, SecondaryService);
      setSecondaryService(arr);
    }
  };
  const fetchMoreOptions = () => {
    setPage(page + 1);
  };
  useEffect(() => {
    if (page != 1) {
      const data = {
        page: page,
      };
      dispatch(fetchServiceListApi(data));
    }
  }, [page]);

  const handleInputChange = (character, type) => {
    const data = new FormData();
    data.append("search", character || null);
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };
    axios(API_BASE_URL + "/searchServicesAndIndustry", config).then((res) => {
      if (!!res.data.list.length) {
        if (type == "service") {
          const mainServices = filterSelectedValueOfArr(
            res.data.list,
            SelectedmainService
          );
          setFilterMainServiceOption(mainServices);
        } else if (type == "secondaryService") {
          const newSecondaryOption = filterSelectedValueOfArr(
            res.data.list,
            SecondaryService
          );
          setFilterSecondaryServiceOption(newSecondaryOption);
        }
      } else {
        setFilterMainServiceOption(cloneMainService);
        setFilterSecondaryServiceOption(allList.secondaryService);
      }
    });
  };

  // custome style for main services //
  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: isCreatedMainServices.some(
        (item) => item.label === state.data.label && item.status === 0
      )
        ? "#ffbcc2"
        : "#e6e6e6",
    }),
  };

  // customStyleForSecondary //
  const customStyleForSecondary = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: isCreateSecondaryService.some(
        (item) => item.value === state.data.value && item.status === 0
      )
        ? "#ffbcc2"
        : "#e6e6e6",
    }),
  };

  const secondaryServicestyle = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor:
        getAllBusinessData?.secondary_service.length > 0 &&
        getAllBusinessData?.secondary_service.some(
          (item) => item.label === state.data.label && item.status === 0
        )
          ? "#ffbcc2"
          : "#e6e6e6",
    }),
  };

  const mainServicestyle = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor:
        getAllBusinessData?.main_service.length > 0 &&
        getAllBusinessData?.main_service.some(
          (item) => item.label === state.data.label && item.status === 0
        )
          ? "#ffbcc2"
          : "#e6e6e6",
    }),
  };

  return (
    <div
      className="tab-pane fade  show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <h5>Build your business profile.</h5>
      <p>
        This will help your business to be visible and get discovered by
        customers on PoolsMagnic search and other tools.
      </p>
      <h5>Tell us about your business ?</h5>
      <p>
        The very first thing clients see is your logo and expertise. If you
        didn’t add it already, you can do it now.
      </p>
      <form method="post" enctype="multipart/form-data">
        <div className="form-group">
          <div className="seller-info d-flex">
            <div className="business-logo position-relative seller-img">
              <img
                onError={(ev) =>
                  (ev.target.src = "/assets/images/business_logo.svg")
                }
                src={
                  isEdit
                    ? uploadSellerImageUrl
                    : IMAGE_BASE_URL + profileData.business_image
                }
                alt="Profile-img da"
              />
              {isEdit ? (
                <a href="javascript:;">
                  <i
                    className="ri-pencil-line"
                    onClick={() => openPencileEl.current.click()}
                  ></i>
                </a>
              ) : (
                ""
              )}
              <input
                type="file"
                id="profileImgData"
                name="businessImage"
                className="d-none"
                accept="image/*"
                ref={openPencileEl}
                onChange={handleFileChange}
              />
            </div>
            <div className="seller-name">
              <label>
                {profileData ? profileData.business_name : "business_name"}
              </label>
              <input
                type="text"
                className="form-control"
                name="businessName"
                placeholder="Business Work"
                value={isEdit ? bussineseName : getAllBusinessData?.work_name}
                onChange={(e) => setBussineseName(e.target.value.slice(0, 40))}
                disabled={isEdit ? false : true}
              />

              {error && bussineseName?.length == 0 ? (
                <p className="error-message" style={{ color: "red" }}>
                  Please add the Work{" "}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group create-select-field">
          <h5 className="mb-3">
            Tell us about your company’s industry, the main service and other
            secondary services you provide ?
          </h5>
          <div className="mb-3">
            <label>Industries</label>
            <Select
              options={filterIndustryOption}
              placeholder="Select Industries"
              className="basic-multi-select"
              classNamePrefix="select"
              isClearable
              isMulti
              isDisabled={isEdit ? false : true}
              onChange={(opt) => handleServicesAndIndustry(opt, "industry")}
              value={
                isEdit
                  ? selectedIndustry &&
                    selectedIndustry?.map((items) => {
                      return {
                        label: items.label,
                        value: items.value,
                      };
                    })
                  : getAllBusinessData?.industry?.length !== 0
                  ? getAllBusinessData?.industry?.map((data) => {
                      return {
                        label: data.label,
                        value: data.value,
                        __isNew__: true,
                      };
                    })
                  : null
              }
            />
            {error && selectedIndustry?.length === 0 && (
              <p className="error-message" style={{ color: "red" }}>
                Please select the industries
              </p>
            )}
          </div>
          <div className="mb-3">
            <label>Main Services</label>
            <CreatableSelect
              options={filterMainServiceOption}
              onInputChange={(text) => handleInputChange(text, "service")}
              placeholder="Select Services"
              className="basic-multi-select"
              classNamePrefix="select"
              isClearable
              isMulti
              isDisabled={isEdit ? false : true}
              styles={
                isCreatedMainServices?.length > 0
                  ? customStyles
                  : mainServicestyle
              }
              onChange={(opt, actionMeta) =>
                handleServicesAndIndustry(opt, "main", actionMeta)
              }
              onMenuScrollToBottom={fetchMoreOptions}
              value={
                isEdit
                  ? SelectedmainService &&
                    SelectedmainService?.map((items) => {
                      return {
                        label: items.label,
                        value: items.value,
                      };
                    })
                  : getAllBusinessData?.main_service?.length !== 0
                  ? getAllBusinessData?.main_service?.map((data) => {
                      return {
                        label: data.label,
                        value: data.value,
                        __isNew__: true,
                        status: data.status,
                      };
                    })
                  : null
              }
            />
            {error &&
              (SelectedmainService?.length === 0 ||
                SelectedmainService?.every((item) => item.status === 0)) && (
                <p className="error-message" style={{ color: "red" }}>
                  Please select the active Main Services
                </p>
              )}

            {(isCreatedMainServices?.length > 0 ||
              SelectedmainService?.some((item) => item.status === 0)) && (
              <p className="text-danger">
                *The service(s) highlighted with a red background are not
                currently included in our existing list. We will conduct a
                diligent evaluation and promptly notify you upon granting our
                approval.
              </p>
            )}
          </div>
          <label>Secondary Services</label>
          <CreatableSelect
            options={filterSecondaryServiceOption}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Services"
            onInputChange={(text) =>
              handleInputChange(text, "secondaryService")
            }
            isClearable
            isMulti
            onChange={(opt, actionMeta) =>
              handleServicesAndIndustry(opt, "secondary", actionMeta)
            }
            isDisabled={isEdit ? false : true}
            onMenuScrollToBottom={fetchMoreOptions}
            styles={
              isCreateSecondaryService?.length > 0
                ? customStyleForSecondary
                : secondaryServicestyle
            }
            value={
              isEdit
                ? SecondaryService?.map((items) => {
                    return {
                      label: items.label,
                      value: items.value,
                      status: items.status,
                    };
                  })
                : getAllBusinessData?.secondary_service?.length !== 0
                ? getAllBusinessData?.secondary_service?.map((data) => {
                    return {
                      label: data.label,
                      value: data.value,
                      __isNew__: true,
                      status: data.status,
                    };
                  })
                : null
            }
          />
        

          {error &&
            (SecondaryService?.length === 0 ||
              SecondaryService?.every((item) => item.status === 0)) && (
              <p className="error-message" style={{ color: "red" }}>
                Please select the active Secondary Services
              </p>
            )}

          {(isCreateSecondaryService?.length > 0 ||
            SecondaryService?.some((item) => item.status === 0)) && (
            <p className="text-danger">
              *The service(s) highlighted with a red background are not
              currently included in our existing list. We will conduct a
              diligent evaluation and promptly notify you upon granting our
              approval.
            </p>
          )}
        </div>

        <div className="form-group">
          <h5 className="mb-3">How do your company provide their services ?</h5>
          <div className="custom-input d-flex">
            {proviceService.map((items) => {
              return (
                <div className="custom-input__inner">
                  <input
                    id={items.inputNameid}
                    type="radio"
                    name="service-method"
                    value={items.id}
                    onChange={handleProvideService}
                    disabled={isEdit ? false : true}
                    checked={
                      isEdit
                        ? items.id == provideSeriveId
                        : items.id == getAllBusinessData?.service_provide_type
                    }
                  />
                  <label className={items.className} for={items.inputNameid}>
                    {items.label}
                  </label>
                </div>
              );
            })}
          </div>
          {error && provideSeriveId == "null" && (
            <p className="error-message" style={{ color: "red" }}>
              Please select the Service
            </p>
          )}
        </div>

        <div className="form-group">
          {provideSeriveId == 2 ? (
            ""
          ) : (
            <>
              <h5 className="mb-3">
                Add the areas you are willing travel to serve your customers?
              </h5>
              <GooglePlacesAutocomplete
                apiKey={"AIzaSyDjlDbFVEXKgnmrVYYLZEMlL0Wt7FEMO9M"}
                value={selectedArea}
                selectProps={{
                  placeholder: "Area",
                  isDisabled: isEdit ? false : true,
                  onChange: (place) => {
                    handleAddress(place);
                  },
                }}
              />
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="mt-2"
              >
                <p style={{ fontSize: 20 }}>Location:</p>
                <p
                  className="ml-2 "
                  style={{ fontSize: 20, fontWeight: "bolder" }}
                >
                  {isEdit ? selectedArea : getAllBusinessData?.area}
                  <MdLocationPin color="#EA4335" />
                </p>
              </div>
              {error && !selectedArea?.length && (
                <p className="error-message" style={{ color: "red" }}>
                  Please select the area
                </p>
              )}
            </>
          )}
        </div>

        <div className="form-group">
          <h5 className="mb-3">
            Do you want to add a location customers can visit like an office or
            a store?
          </h5>
          <div className="custom-input d-flex">
            <div className="custom-input__inner">
              <input
                id="yes"
                type="radio"
                name="location"
                value={1}
                disabled={isEdit ? false : true}
                checked={
                  isEdit
                    ? getLocationValue == 1
                    : getAllBusinessData?.has_office_address == 1
                }
                onChange={(e) => handleLocation(e)}
              />
              <label className="btn-secondary" htmlFor="yes">
                Yes
              </label>
            </div>

            <div className="custom-input__inner">
              <input
                id="no"
                type="radio"
                name="location"
                value={0}
                disabled={isEdit ? false : true}
                checked={
                  isEdit
                    ? getLocationValue == 0
                    : getAllBusinessData?.has_office_address == 0
                }
                onChange={(e) => handleLocation(e)}
              />
              <label className="btn-blue" for="no">
                No
              </label>
            </div>
          </div>
        </div>

        {/* start----- */}
        {getLocationValue == 0 ? (
          ""
        ) : (
          <div className="form-group" id="formcheck">
            <>
              <GooglePlacesAutocomplete
                apiKey={"AIzaSyDjlDbFVEXKgnmrVYYLZEMlL0Wt7FEMO9M"}
                value={officeStoreAddress}
                selectProps={{
                  placeholder: "Area",
                  isDisabled: isEdit ? false : true,
                  onChange: (place) => {
                    handleAddress2(place);
                  },
                }}
              />
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="mt-2"
              >
                <p style={{ fontSize: 20 }}>Location:</p>
                <p
                  className="ml-2 "
                  style={{ fontSize: 20, fontWeight: "bolder" }}
                >
                  {isEdit
                    ? officeStoreAddress
                    : getAllBusinessData?.office_address}
                  <MdLocationPin color="#EA4335" />
                </p>
              </div>
              {/* </div> */}
              {error && getLocationValue != 0 && !officeStoreAddress.length && (
                <p className="error-message" style={{ color: "red" }}>
                  Please select the office or store area
                </p>
              )}
            </>
          </div>
        )}

        {/* ----end--- */}
        <div className="form-group d-flex justify-content-end mt-5 flex-wrap btn-wrapper">
          {isEdit && (
            <a
              href="javascript:;"
              className="btn btn-blue mr-3"
              onClick={() => handleSaveStepOne(1)}
            >
              Save
            </a>
          )}
          <a
            href="javascript:;"
            className="btn btn-primary"
            onClick={() => handleSaveStepOne(2)}
          >
            Next to Skills &amp; Expertise
          </a>
        </div>
      </form>
    </div>
  );
};

export default BusinessOverView;
