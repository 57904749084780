import { CHANGE_SINGLE_ACTIVE_CAROUSAL_POST, CHANGE_USER_ALL_POST_DETAIL, CHANGE_USER_COMMENT_INPUT_DETAIL, CHANGE_USER_POST_VIEW_DETAIL, CHANGE_USER_REACT_LIST_DETAIL, CHANGE_USER_SHARE_LIST_DETAIL, CLEAR_ALL_PROFILE_POST_RESPONSE, CLEAR_DELETE_MY_POST_RESPONSE, CLEAR_SINGLE_USER_POST_CMT_REPLY_LIST_RESPONSE, CLEAR_SINGLE_USER_POST_CMT_REPLY_RESPONSE, CLEAR_SINGLE_USER_POST_COMMENT_LIST_RESPONSE, CLEAR_SINGLE_USER_POST_COMMENT_RESPONSE, CLEAR_SINGLE_USER_POST_LIST_RESPONSE, CLEAR_SINGLE_USER_POST_REACT_RESPONSE, CLEAR_SINGLE_USER_POST_SHARE_LIST_RESPONSE, CLEAR_SINGLE_USER_POST_SHARE_RESPONSE, CLEAR_SINGLE_USER_POST_VIEW_RESPONSE, CLEAR_SINGLE_USER_REACT_LIST_RESPONSE, CLEAR_USER_COMMENT_REPLY_RESPONSE, CLEAR_USER_POST_COMMENT_LIST_RESPONSE, CLEAR_USER_POST_COMMENT_REPLY_LIST_RESPONSE, CLEAR_USER_POST_COMMENT_RESPONSE, CLEAR_USER_POST_REACT_LIST_RESPONSE, CLEAR_USER_POST_REACT_RESPONSE, CLEAR_USER_POST_SHARE_LIST_RESPONSE, CLEAR_USER_POST_SHARE_RESPONSE, CLEAR_USER_POST_VIEW_LIST_RESPONSE, CLEAR_USER_POST_VIEW_RESPONSE } from "./ProfilePostConstant"

const changeUserAllPostDetail = (newState) => {
    return { type: CHANGE_USER_ALL_POST_DETAIL, newState }
}
const clearProfilePostResponse = (newState) => {
    return { type: CLEAR_ALL_PROFILE_POST_RESPONSE, newState }
}
const changeUserPostActiveCarousal = (newState) => {
    return { type: CHANGE_SINGLE_ACTIVE_CAROUSAL_POST, newState }
}
const clearUserPostReactResponse = (newState) => {
    return { type: CLEAR_USER_POST_REACT_RESPONSE, newState }
}
const clearSingleUserPostReactResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_POST_REACT_RESPONSE, newState }
}
const clearUserPostReactListResponse = (newState) => {
    return { type: CLEAR_USER_POST_REACT_LIST_RESPONSE, newState }
}
//this function is used when change post reaction details
const changeUserPostReactDetails = (newState) => {
    return { type: CHANGE_USER_REACT_LIST_DETAIL, newState }
}
//this function is used when clear single post reaction list api response
const clearSingleUserPostReactListResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_REACT_LIST_RESPONSE, newState }
}
//this function is used when change comment details
const changeUserCommentDetailInput = (newState) => {
    return { type: CHANGE_USER_COMMENT_INPUT_DETAIL, newState }
}
//this function is used when clear comment list api response
const clearUserCommentListResponse = (newState) => {
    return { type: CLEAR_USER_POST_COMMENT_LIST_RESPONSE, newState }
}
//this function is used when clear comments api response on post 
const clearUserPostCommentResponse = (newState) => {
    return { type: CLEAR_USER_POST_COMMENT_RESPONSE, newState }
}
//this function is used when clear reply on comment api response in post 
const clearUserCommentReplyResponse = (newState) => {
    return { type: CLEAR_USER_COMMENT_REPLY_RESPONSE, newState }
}
//this function is used when clear reply list api response in comments 
const clearUserCommentReplyListResponse = (newState) => {
    return { type: CLEAR_USER_POST_COMMENT_REPLY_LIST_RESPONSE, newState }
}
//this function is used when clear comments on particular file api response in post
const clearSingleUserPostCommentResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_POST_COMMENT_RESPONSE, newState }
}
//this function is used when clear particular file comment list api response in post
const clearSingleUserPostCommentListResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_POST_COMMENT_LIST_RESPONSE, newState }
}
//this function is used when clear reply on comments in particular file api response in post
const clearSingleUserPostCmtReplyResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_POST_CMT_REPLY_RESPONSE, newState }
}
//this function is used when clear comments reply list in particular file api response 
const clearSingleUserPostCmtReplyListResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_POST_CMT_REPLY_LIST_RESPONSE, newState }
}
//this function is used when clear view post api response
const clearUserPostViewResponse = (newState) => {
    return { type: CLEAR_USER_POST_VIEW_RESPONSE, newState }
}
//this function is used when clear view particular file api response 
const clearSingleUserPostViewResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_POST_VIEW_RESPONSE, newState }
}
//this function is used when change view details like view page , view list api response
const changeUserViewsDetails = (newState) => {
    return { type: CHANGE_USER_POST_VIEW_DETAIL, newState }
}
//this function is used when clear post view list api response
const clearUserPostViewListResponse = (newState) => {
    return { type: CLEAR_USER_POST_VIEW_LIST_RESPONSE, newState }
}
//this function is used when clear particular file view list api response in post
const clearSingleUserPostViewListResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_POST_LIST_RESPONSE, newState }
}
const clearUserPostShareResponse = (newState) => {
    return { type: CLEAR_USER_POST_SHARE_RESPONSE, newState }
}
const clearSingleUserPostShareResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_POST_SHARE_RESPONSE, newState }
}
const changeUserShareListDetail = (newState) => {
    return { type: CHANGE_USER_SHARE_LIST_DETAIL, newState }
}
const clearUserShareListResponse = (newState) => {
    return { type: CLEAR_USER_POST_SHARE_LIST_RESPONSE, newState }
}
const clearSingleUserShareListResponse = (newState) => {
    return { type: CLEAR_SINGLE_USER_POST_SHARE_LIST_RESPONSE, newState }
}
const clearMyDetelePostResponse = (newState) => {
    return { type: CLEAR_DELETE_MY_POST_RESPONSE, newState }
}

export {
    changeUserAllPostDetail, clearProfilePostResponse, changeUserPostActiveCarousal, clearUserPostReactResponse, clearSingleUserPostReactResponse,
    clearUserPostReactListResponse, changeUserPostReactDetails, clearSingleUserPostReactListResponse, changeUserCommentDetailInput, clearUserCommentListResponse,
    clearUserPostCommentResponse, clearUserCommentReplyResponse, clearUserCommentReplyListResponse, clearSingleUserPostCommentResponse, clearSingleUserPostCommentListResponse,
    clearSingleUserPostCmtReplyResponse, clearSingleUserPostCmtReplyListResponse, clearUserPostViewResponse, clearSingleUserPostViewResponse, changeUserViewsDetails,
    clearUserPostViewListResponse, clearSingleUserPostViewListResponse, clearUserPostShareResponse, clearSingleUserPostShareResponse, changeUserShareListDetail,
    clearUserShareListResponse, clearSingleUserShareListResponse, clearMyDetelePostResponse,
}