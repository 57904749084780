import { CHANGE_LOGIN_INPUT, CLEAR_LOGIN_API_RESPONSE, CLEAR_LOGIN_INPUT } from "./LoginConstants"

//this function is used when login input changed
const changeLoginInput = (newState) => {
    return { type: CHANGE_LOGIN_INPUT, newState }
}
//this function is used when clear login input data
const clearLoginInput = (newState) => {
    return { type: CLEAR_LOGIN_INPUT, newState }
}
//this function is used when clear login api response
const clearLoginApiResponse = (newState) => {
    return { type: CLEAR_LOGIN_API_RESPONSE, newState }
}
export { changeLoginInput, clearLoginInput , clearLoginApiResponse }