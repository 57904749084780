import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  businessFriendListApi,
  friendListApi,
  individualFriendListApi,
  searchFriendListApi,
} from "../../library/api/MyNetworkApiService";

import { Input } from "../../library/common/components";
import {
  getCookie,
  replceMultiStringWithSIngle,
  scroolTop,

} from "../../library/utilities/functions";
import {
  changeFriendListDetail,
  clearFriendListApiResponse,
} from "./MyNetworkAction";
import AllNetworkListNetwork from "./AllNetworkListNetwork";
import BusinessNetwork from "./BusinessNetworkList";
import IndividualNetwork from "./IndividualNetwork";
import { FiUser, FiUsers } from 'react-icons/fi';
import { BsBuilding } from 'react-icons/bs';
import { css } from "@emotion/react";
import SideBarProfileMenu from "../Home/common/SideBarProfileMenu";
const override = css`
  position: absolute;
  top: 400%;
  right: 50%;
  z-index:9999;
  display:block
`;


let name = "";
const tabsDatas = [
  {
    id: 1,
    tabName: "All",
    tabIcon: <FiUsers></FiUsers>,
  },
  {
    id: 2,
    tabName: "Individuals",
    tabIcon: <FiUser></FiUser>,
  },
  {
    id: 3,
    tabName: "Businesses",
    tabIcon: <BsBuilding></BsBuilding>,
  },
];
const MyNetwork = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [loading, setLoadilng] = useState(false)

  let { pathname } = useLocation();
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const [netWorkTabs, setNetWorkTabs] = useState("All");
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const {
    friendListDetail: { searchFriendName, searchFriendPage },
  } = networkState;

  const [isSearch, setIsSearch] = useState(false);

  //this function is used to search for friends in friends list
  const handleSearchSubmit = (e) => {

    e.preventDefault();
    name = replceMultiStringWithSIngle(searchFriendName);

    if (!!searchFriendName.trim().length) {
      setIsSearch(true);
      dispatch(clearFriendListApiResponse());

      dispatch(changeFriendListDetail({ searchFriendPage: 1 }));
    } else {
      setIsSearch(false)
    }
  };

  const handleOnChange = (e) => {
    var regex = /^[a-zA-Z ]*$/;
    if (regex.test(e.target.value)) {
      dispatch(changeFriendListDetail({ searchFriendName: e.target.value }));
    }
    if (e.target.value.trim().length == 0) {
      const data = new FormData();
      data.append("user_id", profileData.id);
      data.append("page", 1);
      if (netWorkTabs == "Individuals") {
        dispatch(individualFriendListApi(data));
      } else if (netWorkTabs == "Businesses") {

        dispatch(businessFriendListApi(data));
      } else {

        const body = {
          user_id: userId,
          page: 1,
          support_customer: false
        };
        dispatch(friendListApi(body))
      }
    }

  };
  useEffect(() => {
    if (!!searchFriendPage && !!isSearch) {
      setIsSearch(false);
      const bodyParameters = {
        user_id: userId,
        page: searchFriendPage,
        search: !!searchFriendName
          ? replceMultiStringWithSIngle(searchFriendName)
          : "",
        user_type: netWorkTabs == "All" ? "" : netWorkTabs
      };
      dispatch(searchFriendListApi(bodyParameters))
    }
  }, [searchFriendPage, isSearch]);


  useEffect(() => {
    const data = new FormData();
    data.append("user_id", profileData.id);
    data.append("page", 1);
    dispatch(businessFriendListApi(data));
    dispatch(individualFriendListApi(data));
  }, []);

  const handleTabs = (tabname) => {
    setNetWorkTabs(tabname);
    name = replceMultiStringWithSIngle("");
    dispatch(changeFriendListDetail({ searchFriendName: "" }));
  };
  
  useEffect(() => {
    if (pathname != "network") {
      dispatch(changeFriendListDetail({ searchFriendName: "" }));
    }
    scroolTop();
  }, [pathname])
  return (
    <>
      <section className="network spacer bg-primary">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-sm-12">
              <SideBarProfileMenu />
            </div>
            <div className="col-lg-9 col-sm-12">
              <div className="row">
                <div className="col-md-12 d-flex  justify-content-end">
                  <form
                    className="search-form network-fliter"
                    onSubmit={handleSearchSubmit}
                    autoComplete="off"
                  >
                    <div className="dropdown">
                      <button
                        className="dropdown-toggle form-control mt-0"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        disabled={pathname !== "/network"}
                      >
                        {netWorkTabs}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        {tabsDatas.map((items) => {
                          const { tabIcon, tabName, id } = items
                          return (
                            <a
                              className="dropdown-item"
                              type="button"
                              onClick={() => handleTabs(tabName)}
                              key={id}>
                              {tabIcon}{" "}
                              {tabName}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    <div className="form-group mb-0 position-relative">
                      <Input
                        type="text"
                        name="searchFriendName"
                        onChange={(e) => handleOnChange(e)}
                        placeholder="Search Network"
                        className="form-control bg-white mt-0"
                        value={searchFriendName}
                      />
                      <i
                        className="ri-search-line"
                        onClick={handleSearchSubmit}
                      ></i>
                    </div>
                  </form>
                </div>
              </div>
              {netWorkTabs == "All" && (
                <AllNetworkListNetwork
                  pathname={pathname}
                  setIsSearch={setIsSearch}
                  isSearch={isSearch}
                  userId={userId}
                  name={name}
                  loading={loading}
                  isAllNetwork={netWorkTabs}
                  override={override}
                />
              )}
              {netWorkTabs == "Businesses" && (
                <BusinessNetwork
                  pathname={pathname}
                  loading={loading}
                  name={name}
                  override={override}
                />
              )}
              {netWorkTabs == "Individuals" && (
                <IndividualNetwork
                  pathname={pathname}
                  name={name}
                  loading={loading}
                  override={override}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MyNetwork;
