import React from "react";
import { IMAGE_BASE_URL } from "../../library/urls";
import { Link } from "react-router-dom";
import { sendRequestDataApi } from "../../library/api/SearchApiService";
import { useDispatch } from "react-redux";


const PeopleAndBusinessList = ({ searchList }) => {
  const dispatch = useDispatch();
  const sendRequest = (Id, i) => {
    searchList[i].isSendReq = true;
    const bodyParameter = {
      id: Id,
    };
    dispatch(sendRequestDataApi(bodyParameter));
  };
  return (
    <div>
      <section className="search-users bg-primary spacer search-result tab2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto full-md">
              <h1 className="text-black  text-capitalize mb-0 h5  mb-3">
                {searchList?.length} Search result found
              </h1>
              {searchList.map((data, i) => (
                <div
                  className="search-users__wrapper d-flex flex-wrap align-items-center bg-white p-4 br-20 position-relative mb-3"
                  key={i}
                >
                  {!!data.is_individual ? (
                    <div className="search-users__img d-flex align-items-center">
                      <img
                        onError={(ev) =>
                          (ev.target.src = "/assets/images/member-default.svg")
                        }
                        src={
                          !!data
                            ? IMAGE_BASE_URL + data?.profile_image
                            : "/assets/images/member-default.svg"
                        }
                        alt="user"
                      ></img>
                    </div>
                  ) : (
                    <div className="search-users__img d-flex align-items-center business-user">
                      <img
                        onError={(ev) =>
                          (ev.target.src = "/assets/images/business_logo.svg")
                        }
                        src={
                          !!data
                            ? IMAGE_BASE_URL + data?.business_image
                            : "/assets/images/business_logo.svg"
                        }
                        alt="user"
                      ></img>
                    </div>
                  )}
                  <div className="search-users__detail">
                    <Link
                      // to={`/search-profile/${data.id}?individual=${!!data.is_individual ? true : false
                      //   }`}

                        to={`/search-profile/${data.id}`}
                      className="link-color"
                      onClick={() => {
                        localStorage.setItem("ClickOnSeachProfile", false);
                      }}
                    >
                      <h5 className="text-capitalize mb-0">
                        {data.is_individual == 1
                          ? data.name.substr(0, 23)
                          : data.business_name.substr(0, 23)}
                      </h5>
                    </Link>
                    {!data.is_supporter && (
                      <div>
                        {data.friends == true ? (
                          <a
                            className="btn btn-primary view-profile frnd-btn"
                            href="javascript:;"
                            role="button"
                            title="Part of my network"
                          >
                            <i className="ri-user-follow-line"></i>
                          </a>
                        ) : !!data.request_sent ? (
                          <a
                            role="button"
                            className="btn bg-success view-profile"
                            href="javascript:;"
                          >
                            <i className="ri-user-follow-line" /> Request sent
                          </a>
                        ) : (
                          <button
                            className="btn btn-blue view-profile"
                            href="javascript:;"
                            role="button"
                            disabled={data.isSendReq}
                            onClick={() => sendRequest(data.id, i)}
                          >
                            <i className="ri-user-add-line"></i> Link-Up
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PeopleAndBusinessList;
