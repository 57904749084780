import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { forgetPasswordDataApi } from "../../library/api/ForgetPasswordApiService";
import { Input } from "../../library/common/components";
import {
  addDocumentTitle,
  addValidation,
} from "../../library/utilities/functions";
import {
  changeForgetPasswordInput,
  clearForgetApiResponse,
  clearForgetPasswordInput,
} from "./ForgetPasswordActions";
import { forgetValidation } from "./validation";
import Footer from "../../library/common/components/LandingPage/HomeSection/Footer";

const override = css`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 82%;
  right: 47%;
`;
const ForgetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const emailEl = useRef(null);
  const [isClickedForget,setIsClickedForget] = useState(false)
  const emailElValidation = useRef(null);
  const [error, setError] = useState(false);
  const forgetPasswordState = useSelector(
    (state) => state.ForgetPasswordReducer
  );
  const {
    forgetPasswordInput: { email },
  } = forgetPasswordState;
  const {
    forgetApiResponse: {
      forgetApiSuccess,
      forgetApiStatus,
      forgetMessage,
      forgetApiLoading,
    },
  } = forgetPasswordState;

  useEffect(() => {
    if (!!forgetApiSuccess) {
      addToast(forgetMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });

      dispatch(clearForgetApiResponse());
    }
    if (!forgetApiSuccess && !!forgetApiStatus) {
      addToast(forgetMessage, {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearForgetApiResponse());
    }
  }, [forgetApiSuccess]);

  useEffect(() => {
    addDocumentTitle("Forget Password");
    emailEl.current.focus();
    return () => {
      dispatch(clearForgetApiResponse());
      dispatch(clearForgetPasswordInput());
    };
  }, []);
  //this function is used when the forgot password input field changing.
  const handleChange = (e) => {
    const target = e.target;
    dispatch(changeForgetPasswordInput({ [target.name]: target.value }));
    emailElValidation.current.style.display = "none";
  };
  //this function is used to submit details according to validations for forgot password and hit the api
  const handleSumbit = (e) => {
    e.preventDefault()
    if (!!email.length) {
      let validation = {
        is_valid_email: { status: false, validation: emailElValidation },
      };
      validation = forgetValidation(
        validation,
        forgetPasswordState.forgetPasswordInput
      );
      let { is_valid_email } = validation;
      addValidation(validation);
      if (is_valid_email.status) {
        setIsClickedForget(true)
        const bodyParameter = {
          email: email,
        };
        dispatch(forgetPasswordDataApi(bodyParameter)).then(()=>{
          setTimeout(()=>setIsClickedForget(false),2000)
        });
      }
    } else {
      setError(true);
    }
  };
  return (
    <>
      <div className="container">
        <div className="row align-items-center my-3 my-lg-4 pb-5">
          <div className="col-md-6 col-lg-6 mb-4 mb-md-0 d-none d-md-block">
            <div className="login-svg">
              <img
                src="/assets/images/forgot-vector.svg"
                alt="forgot password"
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-5 ml-lg-auto">
            <div className="form mt-4 bg-white">
              <div className="form__header d-flex align-items-center mb-4 justify-content-center flex-wrap">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon"
                />
                <h1 className="font-weight-bold mb-0 h3 w-100 text-center">
                  Forgot Password
                </h1>
              </div>
              <form>
                <div className="form-group">
                  <label>Email ID</label>
                  <Input
                    type="text"
                    name="email"
                    ref={emailEl}
                    className="form-control"
                    value={email}
                    onChange={handleChange}
                    placeholder="Email ID"
                  />
                  {error && !email.length && (
                    <p className="error-message">Please enter email id. </p>
                  )}
                  <p
                    style={{ display: "none" }}
                    ref={emailElValidation}
                    className="error-message"
                  >
                    Please enter valid email id.{" "}
                  </p>
                </div>
                <div className="form-group mb-0">
                  <div className="d-flex flex-column mt-4">
                    <button
                      href="javascript:;"
                      onClick={(e)=>handleSumbit(e)}
                      className={
                        !!forgetApiLoading
                          ? "btn btn-secondary disabled"
                          : "btn btn-secondary btn-primary"
                      }
                      disabled={isClickedForget}
                    >
                      Next
                    </button>
                    <ClipLoader
                      color={"#fff"}
                      loading={!!forgetApiLoading ? true : false}
                      css={override}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ForgetPassword;
