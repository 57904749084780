import {
  CHANGE_SINGLE_ACTIVE_CAROUSAL_POST,
  CHANGE_USER_ALL_POST_DETAIL,
  CHANGE_USER_COMMENT_INPUT_DETAIL,
  CHANGE_USER_POST_VIEW_DETAIL,
  CHANGE_USER_REACT_LIST_DETAIL,
  CHANGE_USER_SHARE_LIST_DETAIL,
  CLEAR_ALL_PROFILE_POST_RESPONSE,
  CLEAR_DELETE_MY_POST_RESPONSE,
  CLEAR_SINGLE_USER_POST_CMT_REPLY_LIST_RESPONSE,
  CLEAR_SINGLE_USER_POST_CMT_REPLY_RESPONSE,
  CLEAR_SINGLE_USER_POST_COMMENT_LIST_RESPONSE,
  CLEAR_SINGLE_USER_POST_COMMENT_RESPONSE,
  CLEAR_SINGLE_USER_POST_LIST_RESPONSE,
  CLEAR_SINGLE_USER_POST_REACT_RESPONSE,
  CLEAR_SINGLE_USER_POST_SHARE_LIST_RESPONSE,
  CLEAR_SINGLE_USER_POST_SHARE_RESPONSE,
  CLEAR_SINGLE_USER_POST_VIEW_RESPONSE,
  CLEAR_SINGLE_USER_REACT_LIST_RESPONSE,
  CLEAR_USER_COMMENT_REPLY_RESPONSE,
  CLEAR_USER_POST_COMMENT_LIST_RESPONSE,
  CLEAR_USER_POST_COMMENT_REPLY_LIST_RESPONSE,
  CLEAR_USER_POST_COMMENT_RESPONSE,
  CLEAR_USER_POST_REACT_LIST_RESPONSE,
  CLEAR_USER_POST_REACT_RESPONSE,
  CLEAR_USER_POST_SHARE_LIST_RESPONSE,
  CLEAR_USER_POST_SHARE_RESPONSE,
  CLEAR_USER_POST_VIEW_LIST_RESPONSE,
  CLEAR_USER_POST_VIEW_RESPONSE,
  DELETE_MY_POST_DATA_FAILURE,
  DELETE_MY_POST_DATA_REQUEST,
  DELETE_MY_POST_DATA_SUCCESS,
  GET_USER_ALL_POST_DATA_FAILURE,
  GET_USER_ALL_POST_DATA_REQUEST,
  GET_USER_ALL_POST_DATA_SUCCESS,
  SINGLE_USER_POST_CMT_REPLY_DATA_FAILURE,
  SINGLE_USER_POST_CMT_REPLY_DATA_REQUEST,
  SINGLE_USER_POST_CMT_REPLY_DATA_SUCCESS,
  SINGLE_USER_POST_CMT_REPLY_LIST_DATA_FAILURE,
  SINGLE_USER_POST_CMT_REPLY_LIST_DATA_REQUEST,
  SINGLE_USER_POST_CMT_REPLY_LIST_DATA_SUCCESS,
  SINGLE_USER_POST_COMMENT_DATA_FAILURE,
  SINGLE_USER_POST_COMMENT_DATA_REQUEST,
  SINGLE_USER_POST_COMMENT_DATA_SUCCESS,
  SINGLE_USER_POST_COMMENT_LIST_DATA_FAILURE,
  SINGLE_USER_POST_COMMENT_LIST_DATA_REQUEST,
  SINGLE_USER_POST_COMMENT_LIST_DATA_SUCCESS,
  SINGLE_USER_POST_REACT_DATA_FAILURE,
  SINGLE_USER_POST_REACT_DATA_REQUEST,
  SINGLE_USER_POST_REACT_DATA_SUCCESS,
  SINGLE_USER_POST_SHARE_DATA_FAILURE,
  SINGLE_USER_POST_SHARE_DATA_REQUEST,
  SINGLE_USER_POST_SHARE_DATA_SUCCESS,
  SINGLE_USER_POST_SHARE_LIST_DATA_FAILURE,
  SINGLE_USER_POST_SHARE_LIST_DATA_REQUEST,
  SINGLE_USER_POST_SHARE_LIST_DATA_SUCCESS,
  SINGLE_USER_POST_VIEW_DATA_FAILURE,
  SINGLE_USER_POST_VIEW_DATA_REQUEST,
  SINGLE_USER_POST_VIEW_DATA_SUCCESS,
  SINGLE_USER_POST_VIEW_LIST_DATA_FAILURE,
  SINGLE_USER_POST_VIEW_LIST_DATA_REQUEST,
  SINGLE_USER_POST_VIEW_LIST_DATA_SUCCESS,
  SINGLE_USER_REACT_LIST_DATA_FAILURE,
  SINGLE_USER_REACT_LIST_DATA_REQUEST,
  SINGLE_USER_REACT_LIST_DATA_SUCCESS,
  USER_POST_COMMENT_DATA_FAILURE,
  USER_POST_COMMENT_DATA_REQUEST,
  USER_POST_COMMENT_DATA_SUCCESS,
  USER_POST_COMMENT_LIST_DATA_FAILURE,
  USER_POST_COMMENT_LIST_DATA_REQUEST,
  USER_POST_COMMENT_LIST_DATA_SUCCESS,
  USER_POST_COMMENT_REPLY_DATA_FAILURE,
  USER_POST_COMMENT_REPLY_DATA_REQUEST,
  USER_POST_COMMENT_REPLY_DATA_SUCCESS,
  USER_POST_COMMENT_REPLY_LIST_DATA_FAILURE,
  USER_POST_COMMENT_REPLY_LIST_DATA_REQUEST,
  USER_POST_COMMENT_REPLY_LIST_DATA_SUCCESS,
  USER_POST_REACT_DATA_FAILURE,
  USER_POST_REACT_DATA_REQUEST,
  USER_POST_REACT_DATA_SUCCESS,
  USER_POST_REACT_LIST_DATA_FAILURE,
  USER_POST_REACT_LIST_DATA_REQUEST,
  USER_POST_REACT_LIST_DATA_SUCCESS,
  USER_POST_SHARE_DATA_FAILURE,
  USER_POST_SHARE_DATA_REQUEST,
  USER_POST_SHARE_DATA_SUCCESS,
  USER_POST_SHARE_LIST_DATA_FAILURE,
  USER_POST_SHARE_LIST_DATA_REQUEST,
  USER_POST_SHARE_LIST_DATA_SUCCESS,
  USER_POST_VIEW_DATA_FAILURE,
  USER_POST_VIEW_DATA_REQUEST,
  USER_POST_VIEW_DATA_SUCCESS,
  USER_POST_VIEW_LIST_DATA_FAILURE,
  USER_POST_VIEW_LIST_DATA_REQUEST,
  USER_POST_VIEW_LIST_DATA_SUCCESS,
} from "./ProfilePostConstant";

const initialState = {
  getProfilePostDetail: {
    profilePostPage: 1,
    getProfilePost: [],
    network_count: "",
    network_list: [],
  },
  getProfilePostApi: {
    getProfilePostLoading: false,
    getProfilePostStatus: "",
    getProfilePostMessage: "",
  },
  activeCarousalPostDetail: {
    activeCarousalPost: [],
    activeIndexPost: "",
  },
  postReactionApi: {
    reactionLoading: false,
    reactionStatus: "",
    reactionSucess: "",
    reactionMessage: "",
  },
  singlePostReactionApi: {
    singleReactionLoading: false,
    singleReactionStatus: "",
    singleReactionSucess: "",
    singleReactionMessage: "",
  },
  postLikeListApi: {
    likeListLoading: false,
    likeListStatus: "",
    likeListMessage: "",
  },
  LikeListDetail: {
    likeList: [],
    totalLikes: "",
    likeListPage: 0,
    postId: "",
    fileId: "",
    singleLikeListpage: 0,
  },
  SingleLikeListApi: {
    singleLikeListLoading: false,
    singleLikeListStatus: "",
    singleLikeListMessage: "",
  },
  reactionListDetail: {
    reactList: [],
    totalReact: "",
    reactListPage: 0,
    reactionPostId: "",
    reactionFileId: "",
    singleReactListpage: 0,
  },
  reactListApi: {
    reactListLoading: false,
    reactListStatus: "",
    reactListMessage: "",
  },
  singleReactListApi: {
    singleReactListLoading: false,
    singleReactListStatus: "",
    singleReactListMessage: "",
  },
  commentDetails: {
    commentPostId: "",
    commentListPage: "",
    commentId: "",
  },
  commentListApi: {
    commentListLoading: false,
    commentListStatus: "",
    commentListMessage: "",
  },
  commentApi: {
    commentLoading: false,
    commentStatus: "",
    commentMessage: "",
    commentResponse: "",
  },
  commentReplyApi: {
    commentReplyLoading: false,
    commentReplyMessage: "",
    commentReplyStatus: "",
    commentReplyResponse: "",
  },
  commentReplyListApi: {
    commentReplyListLoading: false,
    commentReplyListMessage: "",
    commentReplyListStatus: "",
  },
  singlePostCommentApi: {
    SingleCommentLoading: false,
    SingleCommentStatus: "",
    SingleCommentMessage: "",
    SingleCommentResponse: "",
  },
  singlePostCommentListApi: {
    singleCommentListLoading: false,
    singleCommentListStatus: "",
    singleCommentListMessage: "",
  },
  singlePostCommentReplyApi: {
    singleCommentReplyLoading: false,
    singleCommentReplyStatus: "",
    singleCommentReplyMessage: "",
    singleCommentReplyResponse: "",
  },
  singleCommentReplyListApi: {
    singleReplyListLoading: false,
    singleReplyListStatus: "",
    singleReplyListMessage: "",
  },
  postViewApi: {
    postViewLoading: false,
    postViewStatus: "",
    postViewMessage: "",
  },
  singlePostViewApi: {
    singleViewLoading: false,
    SingleViewStatus: "",
    SingleViewMessage: "",
  },
  postViewListApi: {
    viewListLoading: false,
    viewListStatus: "",
    viewListmessage: "",
  },
  singlePostViewListApi: {
    singleViewListLoading: false,
    singleViewListStatus: "",
    singleViewListMessage: "",
  },
  postViewListDetail: {
    viewList: [],
    totalViews: "",
    viewListPage: 0,
    viewPostId: "",
    viewFileId: "",
    singleViewListpage: 0,
  },
  shareFeedPostApi: {
    shareFeedPostLoading: false,
    shareFeedPostStatus: "",
    shareFeedPostMessage: "",
  },
  shareSingleFeedPostApi: {
    shareSingleFeedLoading: false,
    shareSingleFeedStatus: "",
    shareSingleFeedMessage: "",
  },
  postShareListDetail: {
    shareList: [],
    totalShare: "",
    shareListPage: 0,
    sharePostId: "",
    shareFileId: "",
    singleShareListpage: 0,
  },
  feedShareListApi: {
    feedShareListLoading: false,
    feedShareListStatus: "",
    feedShareListMessage: "",
  },
  singlefeedShareListApi: {
    singleShareListLoading: false,
    singleShareListStatus: "",
    singleShareListMessage: "",
  },
  deletePostApi: {
    deletePostLoading: false,
    deletePostStatus: "",
    deletePostMessage: "",
  },
};
export const ProfilePostReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_USER_ALL_POST_DETAIL:
      return {
        ...state,
        ...{
          getProfilePostDetail: {
            ...state.getProfilePostDetail,
            ...action.newState,
          },
        },
      };
    case GET_USER_ALL_POST_DATA_REQUEST:
      return {
        ...state,
        ...{
          getProfilePostApi: {
            ...state.getProfilePostApi,
            ...{ getProfilePostLoading: true },
          },
        },
      };
    case GET_USER_ALL_POST_DATA_SUCCESS:
      let getProfileApiSucess = {
        getProfilePostLoading: false,
        getProfilePostStatus: action.response.data.status,
        getProfilePostMessage: action.response.data.message,
      };
      let UserPostSucess = {
        getProfilePost: !!action.response.data.list
          ? [
            ...state.getProfilePostDetail.getProfilePost,
            ...action.response.data.list,
          ]
          : [],
        network_count: action.response.data.network_count,
        network_list: !!action.response.data.networks
          ? action.response.data.networks
          : [],
      };
      return {
        ...state,
        ...{
          getProfilePostApi: {
            ...state.getProfilePostApi,
            ...getProfileApiSucess,
          },
          getProfilePostDetail: {
            ...state.getProfilePostDetail,
            ...UserPostSucess,
          },
        },
      };
    case GET_USER_ALL_POST_DATA_FAILURE:
      let getProfileApiFailure = {
        getProfilePostLoading: false,
        getProfilePostStatus: action.status,
        getProfilePostMessage: action.response.data.message,
      };
      let failUserPost = {
        getProfilePost: [],
        network_count: "",
        network_list: [],
      };
      return {
        ...state,
        ...{
          getProfilePostApi: {
            ...state.getProfilePostApi,
            ...getProfileApiFailure,
          },
          getProfilePostDetail: {
            ...state.getProfilePostDetail,
            ...failUserPost,
          },
        },
      };
    case CLEAR_ALL_PROFILE_POST_RESPONSE:
      let clearProfileResponse = {
        getProfilePostStatus: "",
        getProfilePostMessage: "",
      };
      let clearProfilePostDetail = {
        getProfilePost: [],
        profilePostPage: 1,
        network_count: "",
        network_list: [],
      };
      return {
        ...state,
        ...{
          getProfilePostApi: {
            ...state.getProfilePostApi,
            ...clearProfileResponse,
          },
          getProfilePostDetail: {
            ...state.getProfilePostDetail,
            ...clearProfilePostDetail,
          },
        },
      };
    case CHANGE_SINGLE_ACTIVE_CAROUSAL_POST:
      return {
        ...state,
        ...{
          activeCarousalPostDetail: {
            ...state.activeCarousalPostDetail,
            ...action.newState,
          },
        },
      };
    case USER_POST_REACT_DATA_REQUEST:
      return {
        ...state,
        ...{
          postReactionApi: {
            ...state.postReactionApi,
            ...{ reactionLoading: true },
          },
        },
      };
    case USER_POST_REACT_DATA_SUCCESS:
      let reactionSucess = {
        reactionLoading: false,
        reactionStatus: action.response.data.status,
        reactionMessage: action.response.data.message,
        reactionSucess: !!action.response.data.success
          ? action.response.data.success
          : "",
      };
      return {
        ...state,
        ...{
          postReactionApi: { ...state.postReactionApi, ...reactionSucess },
        },
      };
    case USER_POST_REACT_DATA_FAILURE:
      let postReactionFailure = {
        reactionLoading: false,
        reactionStatus: action.status,
        reactionMessage: action.response.data.message,
        reactionSucess: "",
      };
      return {
        ...state,
        ...{
          postReactionApi: { ...state.postReactionApi, ...postReactionFailure },
        },
      };
    case CLEAR_USER_POST_REACT_RESPONSE:
      let clearReactionPost = {
        reactionStatus: "",
        reactionSucess: "",
        reactionMessage: "",
      };
      return {
        ...state,
        ...{
          postReactionApi: { ...state.postReactionApi, ...clearReactionPost },
        },
      };
    case SINGLE_USER_POST_REACT_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostReactionApi: {
            ...state.singlePostReactionApi,
            ...{ singleReactionLoading: true },
          },
        },
      };
    case SINGLE_USER_POST_REACT_DATA_SUCCESS:
      let singleReactionSucess = {
        singleReactionLoading: false,
        singleReactionStatus: action.response.data.status,
        singleReactionMessage: action.response.data.message,
        singleReactionSucess: !!action.response.data.success
          ? action.response.data.success
          : "",
      };
      return {
        ...state,
        ...{
          singlePostReactionApi: {
            ...state.singlePostReactionApi,
            ...singleReactionSucess,
          },
        },
      };
    case SINGLE_USER_POST_REACT_DATA_FAILURE:
      let singleReactionFailure = {
        singleReactionLoading: false,
        singleReactionStatus: action.status,
        singleReactionMessage: action.response.data.message,
        singleReactionSucess: "",
      };
      return {
        ...state,
        ...{
          singlePostReactionApi: {
            ...state.singlePostReactionApi,
            ...singleReactionFailure,
          },
        },
      };
    case CLEAR_SINGLE_USER_POST_REACT_RESPONSE:
      let clearSingleReaction = {
        singleReactionStatus: "",
        singleReactionSucess: "",
        singleReactionMessage: "",
      };
      return {
        ...state,
        ...{
          singlePostReactionApi: {
            ...state.singlePostReactionApi,
            ...clearSingleReaction,
          },
        },
      };
    case USER_POST_REACT_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          reactListApi: {
            ...state.reactListApi,
            ...{ reactListLoading: true },
          },
        },
      };
    case USER_POST_REACT_LIST_DATA_SUCCESS:
      let reactListSucess = {
        reactListLoading: false,
        reactListStatus: action.response.data.status,
        reactListMessage: action.response.data.message,
      };
      let reactLists = {
        reactList: !!action.response.data.list
          ? [
            ...state.reactionListDetail.reactList,
            ...action.response.data.list,
          ]
          : [],
        totalReact: !!action.response.data.reaction_listing
          ? action.response.data.reaction_listing
          : [],
      };
      for (let i in state.getProfilePostDetail.getProfilePost) {
        if (
          state.getProfilePostDetail.getProfilePost[i].post_id ==
          state.reactionListDetail.reactionPostId
        ) {
          state.getProfilePostDetail.getProfilePost[i].post_react = !!action
            .response.data.total
            ? action.response.data.total
            : "";
          state.getProfilePostDetail.getProfilePost[i].reaction_list = !!action
            .response.data.reaction_listing
            ? action.response.data.reaction_listing
            : [];
        }
      }
      return {
        ...state,
        ...{
          reactListApi: { ...state.reactListApi, ...reactListSucess },
          reactionListDetail: { ...state.reactionListDetail, ...reactLists },
        },
      };
    case USER_POST_REACT_LIST_DATA_FAILURE:
      let reactListFailure = {
        reactListLoading: false,
        reactListStatus: action.status,
        reactListMessage: action.response.data.message,
      };
      let reactListFail = {
        reactList: [],
        totalReact: "",
      };
      return {
        ...state,
        ...{
          reactListApi: { ...state.reactListApi, ...reactListFailure },
          reactionListDetail: { ...state.reactionListDetail, ...reactListFail },
        },
      };
    case CLEAR_USER_POST_REACT_LIST_RESPONSE:
      let clearReactList = {
        reactListStatus: "",
        reactListMessage: "",
      };
      let clearReactDetail = {
        reactList: [],
        totalReact: "",
        reactListPage: 0,
        reactionPostId: "",
      };
      return {
        ...state,
        ...{
          reactListApi: { ...state.reactListApi, ...clearReactList },
          reactionListDetail: {
            ...state.reactionListDetail,
            ...clearReactDetail,
          },
        },
      };
    case CHANGE_USER_REACT_LIST_DETAIL:
      return {
        ...state,
        ...{
          reactionListDetail: {
            ...state.reactionListDetail,
            ...action.newState,
          },
        },
      };
    case SINGLE_USER_REACT_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singleReactListApi: {
            ...state.singleReactListApi,
            ...{ singleReactListLoading: true },
          },
        },
      };
    case SINGLE_USER_REACT_LIST_DATA_SUCCESS:
      let singleReactListSucess = {
        singleReactListLoading: false,
        singleReactListStatus: action.response.data.status,
        singleReactListMessage: action.response.data.message,
      };
      let SingleReactLists = {
        reactList: !!action.response.data.list
          ? [
            ...state.reactionListDetail.reactList,
            ...action.response.data.list,
          ]
          : [],
        totalReact: !!action.response.data.reaction_listing
          ? action.response.data.reaction_listing
          : [],
      };
      for (let i in state.activeCarousalPostDetail.activeCarousalPost) {
        if (i == state.activeCarousalPostDetail.activeIndexPost) {
          state.activeCarousalPostDetail.activeCarousalPost[i].react_count =
            !!action.response.data.total ? action.response.data.total : "";
          state.activeCarousalPostDetail.activeCarousalPost[i].reaction_list =
            !!action.response.data.reaction_listing
              ? action.response.data.reaction_listing
              : [];
        }
      }
      return {
        ...state,
        ...{
          singleReactListApi: {
            ...state.singleReactListApi,
            ...singleReactListSucess,
          },
          reactionListDetail: {
            ...state.reactionListDetail,
            ...SingleReactLists,
          },
        },
      };
    case SINGLE_USER_REACT_LIST_DATA_FAILURE:
      let SingleReactListFailure = {
        singleReactListLoading: false,
        singleReactListStatus: action.status,
        singleReactListMessage: action.response.data.message,
      };
      let SingleReactListFail = {
        reactList: [],
        totalReact: "",
      };
      return {
        ...state,
        ...{
          singleReactListApi: {
            ...state.singleReactListApi,
            ...SingleReactListFailure,
          },
          reactionListDetail: {
            ...state.reactionListDetail,
            ...SingleReactListFail,
          },
        },
      };
    case CLEAR_SINGLE_USER_REACT_LIST_RESPONSE:
      let clearSingleReactList = {
        singleReactListLoading: false,
        singleReactListStatus: "",
        singleReactListMessage: "",
      };
      let clearSingleReactDetail = {
        reactionFileId: "",
        singleReactListpage: 0,
        reactList: [],
        totalReact: "",
      };
      return {
        ...state,
        ...{
          singleReactListApi: {
            ...state.singleReactListApi,
            ...clearSingleReactList,
          },
          reactionListDetail: {
            ...state.reactionListDetail,
            ...clearSingleReactDetail,
          },
        },
      };
    case CHANGE_USER_COMMENT_INPUT_DETAIL:
      return {
        ...state,
        ...{ commentDetails: { ...state.commentDetails, ...action.newState } },
      };
    case USER_POST_COMMENT_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          commentListApi: {
            ...state.commentListApi,
            ...{ commentListLoading: true },
          },
        },
      };
    case USER_POST_COMMENT_LIST_DATA_SUCCESS:
      let commentListSucess = {
        commentListLoading: false,
        commentListStatus: action.response.data.status,
        commentListMessage: action.response.data.message,
      };
      for (let i in state.getProfilePostDetail.getProfilePost) {
        if (
          state.getProfilePostDetail.getProfilePost[i].post_id ==
          state.commentDetails.commentPostId
        ) {
          if (!!state.getProfilePostDetail.getProfilePost[i].commentList) {
            state.getProfilePostDetail.getProfilePost[i].commentList = !!action
              .response.data.list
              ? [
                ...action.response.data.list.reverse(),
                ...state.getProfilePostDetail.getProfilePost[i].commentList,
              ]
              : [];
          }
          state.getProfilePostDetail.getProfilePost[i].comment_count = !!action
            .response.data.total
            ? action.response.data.total
            : 0;
        }
      }
      return {
        ...state,
        ...{
          commentListApi: { ...state.commentListApi, ...commentListSucess },
        },
      };
    case USER_POST_COMMENT_LIST_DATA_FAILURE:
      let commentListFailure = {
        commentListLoading: false,
        commentListStatus: action.status,
        commentListMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          commentListApi: { ...state.commentListApi, ...commentListFailure },
        },
      };
    case CLEAR_USER_POST_COMMENT_LIST_RESPONSE:
      let clearCommentList = {
        commentListStatus: "",
        commentListMessage: "",
      };
      return {
        ...state,
        ...{
          commentListApi: { ...state.commentListApi, ...clearCommentList },
        },
      };
    case USER_POST_COMMENT_DATA_REQUEST:
      return {
        ...state,
        ...{ commentApi: { ...state.commentApi, ...{ commentLoading: true } } },
      };
    case USER_POST_COMMENT_DATA_SUCCESS:
      let commentSucess = {
        commentLoading: false,
        commentStatus: action.response.data.status,
        commentMessage: action.response.data.message,
        commentResponse: action.response.data.data,
      };

      return {
        ...state,
        ...{
          commentApi: { ...state.commentApi, ...commentSucess },
        },
      };
    case USER_POST_COMMENT_DATA_FAILURE:
      let commentFailure = {
        commentLoading: false,
        commentStatus: action.status,
        commentMessage: action.response.data.message,
        commentResponse: "",
      };
      return {
        ...state,
        ...{
          commentApi: { ...state.commentApi, ...commentFailure },
        },
      };
    case CLEAR_USER_POST_COMMENT_RESPONSE:
      let clearComment = {
        commentStatus: "",
        commentMessage: "",
        commentResponse: "",
      };

      return {
        ...state,
        ...{
          commentApi: { ...state.commentApi, ...clearComment },
        },
      };
    case USER_POST_COMMENT_REPLY_DATA_REQUEST:
      return {
        ...state,
        ...{
          commentReplyApi: {
            ...state.commentReplyApi,
            ...{ commentReplyLoading: true },
          },
        },
      };
    case USER_POST_COMMENT_REPLY_DATA_SUCCESS:
      let commentReplySucess = {
        commentReplyLoading: false,
        commentReplyStatus: action.response.data.status,
        commentReplyMessage: action.response.data.message,
        commentReplyResponse: action.response.data.data,
      };

      return {
        ...state,
        ...{
          commentReplyApi: { ...state.commentReplyApi, ...commentReplySucess },
        },
      };
    case USER_POST_COMMENT_REPLY_DATA_FAILURE:
      let commentReplyFailure = {
        commentReplyLoading: false,
        commentReplyStatus: action.status,
        commentReplyMessage: action.response.data.message,
        commentReplyResponse: "",
      };
      return {
        ...state,
        ...{
          commentReplyApi: { ...state.commentReplyApi, ...commentReplyFailure },
        },
      };
    case CLEAR_USER_COMMENT_REPLY_RESPONSE:
      let clearCommentReply = {
        commentReplyMessage: "",
        commentReplyStatus: "",
        commentReplyResponse: "",
      };

      return {
        ...state,
        ...{
          commentReplyApi: { ...state.commentReplyApi, ...clearCommentReply },
        },
      };
    case USER_POST_COMMENT_REPLY_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          commentReplyListApi: {
            ...state.commentReplyListApi,
            ...{ commentReplyListLoading: true },
          },
        },
      };
    case USER_POST_COMMENT_REPLY_LIST_DATA_SUCCESS:
      let commentReplyListSucess = {
        commentReplyListLoading: false,
        commentReplyListStatus: action.response.data.status,
        commentReplyListMessage: action.response.data.message,
      };
      for (let i in state.getProfilePostDetail.getProfilePost) {
        const innerPost =
          state.getProfilePostDetail.getProfilePost[i].commentList;
        for (let j in innerPost) {
          if (innerPost[j].comment_id == state.commentDetails.commentId) {
            innerPost[j].subComment = !!action.response.data.list
              ? [...new Set([...action.response.data.list.reverse()])]
              : [];

            innerPost[j].reply = !!action.response.data.total
              ? action.response.data.total
              : 0;
          }
        }
      }
      return {
        ...state,
        ...{
          commentReplyListApi: {
            ...state.commentReplyListApi,
            ...commentReplyListSucess,
          },
        },
      };
    case USER_POST_COMMENT_REPLY_LIST_DATA_FAILURE:
      let commentReplyListFailure = {
        commentReplyListLoading: false,
        commentReplyListStatus: action.status,
        commentReplyListMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          commentReplyListApi: {
            ...state.commentReplyListApi,
            ...commentReplyListFailure,
          },
        },
      };
    case CLEAR_USER_POST_COMMENT_REPLY_LIST_RESPONSE:
      let clearCommentReplyList = {
        commentReplyListMessage: "",
        commentReplyListStatus: "",
      };
      return {
        ...state,
        ...{
          commentReplyListApi: {
            ...state.commentReplyListApi,
            ...clearCommentReplyList,
          },
        },
      };
    case SINGLE_USER_POST_COMMENT_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostCommentApi: {
            ...state.singlePostCommentApi,
            ...{ SingleCommentLoading: true },
          },
        },
      };
    case SINGLE_USER_POST_COMMENT_DATA_SUCCESS:
      let singleCommentSucess = {
        SingleCommentLoading: false,
        SingleCommentStatus: action.response.data.status,
        SingleCommentMessage: action.response.data.message,
        SingleCommentResponse: action.response.data.data,
      };

      return {
        ...state,
        ...{
          singlePostCommentApi: {
            ...state.singlePostCommentApi,
            ...singleCommentSucess,
          },
        },
      };
    case SINGLE_USER_POST_COMMENT_DATA_FAILURE:
      let singleCommentFailure = {
        SingleCommentLoading: false,
        SingleCommentStatus: action.status,
        SingleCommentMessage: action.response.data.message,
        SingleCommentResponse: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentApi: {
            ...state.singlePostCommentApi,
            ...singleCommentFailure,
          },
        },
      };
    case CLEAR_SINGLE_USER_POST_COMMENT_RESPONSE:
      let clearSingleComment = {
        SingleCommentStatus: "",
        SingleCommentMessage: "",
        SingleCommentResponse: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentApi: {
            ...state.singlePostCommentApi,
            ...clearSingleComment,
          },
        },
      };
    case SINGLE_USER_POST_COMMENT_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostCommentListApi: {
            ...state.singlePostCommentListApi,
            ...{ singleCommentListLoading: true },
          },
        },
      };
    case SINGLE_USER_POST_COMMENT_LIST_DATA_SUCCESS:
      let singleCommentListSucess = {
        singleCommentListLoading: false,
        singleCommentListStatus: action.response.data.status,
        singleCommentListMessage: action.response.data.message,
      };
      for (let i in state.activeCarousalPostDetail.activeCarousalPost) {
        if (i == state.activeCarousalPostDetail.activeIndexPost) {
          if (
            !!state.activeCarousalPostDetail.activeCarousalPost[i]
              .singlePostCommentList
          ) {
            state.activeCarousalPostDetail.activeCarousalPost[
              i
            ].singlePostCommentList = !!action.response.data.list
                ? [
                  ...action.response.data.list.reverse(),
                  ...state.activeCarousalPostDetail.activeCarousalPost[i]
                    .singlePostCommentList,
                ]
                : [];
          }
          state.activeCarousalPostDetail.activeCarousalPost[i].comment_count =
            !!action.response.data.total ? action.response.data.total : 0;
        }
      }
      return {
        ...state,
        ...{
          singlePostCommentListApi: {
            ...state.singlePostCommentListApi,
            ...singleCommentListSucess,
          },
        },
      };
    case SINGLE_USER_POST_COMMENT_LIST_DATA_FAILURE:
      let singleCommentListFailure = {
        singleCommentListLoading: false,
        singleCommentListStatus: action.status,
        singleCommentListMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          singlePostCommentListApi: {
            ...state.singlePostCommentListApi,
            ...singleCommentListFailure,
          },
        },
      };
    case CLEAR_SINGLE_USER_POST_COMMENT_LIST_RESPONSE:
      let clearSingleCommentList = {
        singleCommentListStatus: "",
        singleCommentListMessage: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentListApi: {
            ...state.singlePostCommentListApi,
            ...clearSingleCommentList,
          },
        },
      };
    case SINGLE_USER_POST_CMT_REPLY_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostCommentReplyApi: {
            ...state.singlePostCommentReplyApi,
            ...{ singleCommentReplyLoading: true },
          },
        },
      };
    case SINGLE_USER_POST_CMT_REPLY_DATA_SUCCESS:
      let singleCommentReplySucess = {
        singleCommentReplyLoading: false,
        singleCommentReplyStatus: action.response.data.status,
        singleCommentReplyMessage: action.response.data.message,
        singleCommentReplyResponse: action.response.data.data,
      };

      return {
        ...state,
        ...{
          singlePostCommentReplyApi: {
            ...state.singlePostCommentReplyApi,
            ...singleCommentReplySucess,
          },
        },
      };
    case SINGLE_USER_POST_CMT_REPLY_DATA_FAILURE:
      let singleCommentReplyFailure = {
        singleCommentReplyLoading: false,
        singleCommentReplyStatus: action.status,
        singleCommentReplyMessage: action.response.data.message,
        singleCommentReplyResponse: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentReplyApi: {
            ...state.singlePostCommentReplyApi,
            ...singleCommentReplyFailure,
          },
        },
      };
    case CLEAR_SINGLE_USER_POST_CMT_REPLY_RESPONSE:
      let clearSingleCommentReply = {
        singleCommentReplyStatus: "",
        singleCommentReplyMessage: "",
        singleCommentReplyResponse: "",
      };
      return {
        ...state,
        ...{
          singlePostCommentReplyApi: {
            ...state.singlePostCommentReplyApi,
            ...clearSingleCommentReply,
          },
        },
      };
    case SINGLE_USER_POST_CMT_REPLY_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singleCommentReplyListApi: {
            ...state.singleCommentReplyListApi,
            ...{ singleReplyListLoading: true },
          },
        },
      };
    case SINGLE_USER_POST_CMT_REPLY_LIST_DATA_SUCCESS:
      let singleReplyListSucess = {
        singleReplyListLoading: false,
        singleReplyListStatus: action.response.data.status,
        singleReplyListMessage: action.response.data.message,
      };
      for (let i in state.activeCarousalPostDetail.activeCarousalPost) {
        if (i == state.activeCarousalPostDetail.activeIndexPost) {
          let innerSubComment =
            state.activeCarousalPostDetail.activeCarousalPost[i]
              .singlePostCommentList;

          for (let j in innerSubComment) {
            if (
              innerSubComment[j].comment_id ==
              state.activeCarousalPostDetail.singleCommentId
            ) {
              if (!!innerSubComment[j].singleSubComment) {
                innerSubComment[j].singleSubComment = !!action.response.data
                  .list
                  ? [
                    ...action.response.data.list.reverse(),
                    ...innerSubComment[j].singleSubComment,
                  ]
                  : [];
              }
              innerSubComment[j].reply = !!action.response.data.total
                ? action.response.data.total
                : innerSubComment[j].reply;
            }
          }
        }
      }
      return {
        ...state,
        ...{
          singleCommentReplyListApi: {
            ...state.singleCommentReplyListApi,
            ...singleReplyListSucess,
          },
        },
      };
    case SINGLE_USER_POST_CMT_REPLY_LIST_DATA_FAILURE:
      let singleReplyListFailure = {
        singleReplyListLoading: false,
        singleReplyListStatus: action.status,
        singleReplyListMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          singleCommentReplyListApi: {
            ...state.singleCommentReplyListApi,
            ...singleReplyListFailure,
          },
        },
      };
    case CLEAR_SINGLE_USER_POST_CMT_REPLY_LIST_RESPONSE:
      let clearSingleReplyList = {
        singleReplyListStatus: "",
        singleReplyListMessage: "",
      };
      return {
        ...state,
        ...{
          singleCommentReplyListApi: {
            ...state.singleCommentReplyListApi,
            ...clearSingleReplyList,
          },
        },
      };
    case USER_POST_VIEW_DATA_REQUEST:
      return {
        ...state,
        ...{
          postViewApi: { ...state.postViewApi, ...{ postViewLoading: true } },
        },
      };
    case USER_POST_VIEW_DATA_SUCCESS:
      let postViewSucess = {
        postViewLoading: false,
        postViewStatus: action.response.data.status,
        postViewMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          postViewApi: { ...state.postViewApi, ...postViewSucess },
        },
      };
    case USER_POST_VIEW_DATA_FAILURE:
      let postViewFailure = {
        postViewLoading: false,
        postViewStatus: action.status,
        postViewMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          postViewApi: { ...state.postViewApi, ...postViewFailure },
        },
      };
    case CLEAR_USER_POST_VIEW_RESPONSE:
      let clearPostView = {
        postViewLoading: false,
        postViewStatus: "",
        postViewMessage: "",
      };
      return {
        ...state,
        ...{
          postViewApi: { ...state.postViewApi, ...clearPostView },
        },
      };
    case SINGLE_USER_POST_VIEW_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostViewApi: {
            ...state.singlePostViewApi,
            ...{ singleViewLoading: true },
          },
        },
      };
    case SINGLE_USER_POST_VIEW_DATA_SUCCESS:
      let singlePostViewSucess = {
        singleViewLoading: false,
        SingleViewStatus: action.response.data.status,
        SingleViewMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          singlePostViewApi: {
            ...state.singlePostViewApi,
            ...singlePostViewSucess,
          },
        },
      };
    case SINGLE_USER_POST_VIEW_DATA_FAILURE:
      let singlePostViewFailure = {
        singleViewLoading: false,
        SingleViewStatus: action.status,
        SingleViewMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          singlePostViewApi: {
            ...state.singlePostViewApi,
            ...singlePostViewFailure,
          },
        },
      };
    case CLEAR_SINGLE_USER_POST_VIEW_RESPONSE:
      let clearSinglePostView = {
        SingleViewStatus: "",
        SingleViewMessage: "",
      };
      return {
        ...state,
        ...{
          singlePostViewApi: {
            ...state.singlePostViewApi,
            ...clearSinglePostView,
          },
        },
      };
    case CHANGE_USER_POST_VIEW_DETAIL:
      return {
        ...state,
        ...{
          postViewListDetail: {
            ...state.postViewListDetail,
            ...action.newState,
          },
        },
      };
    case USER_POST_VIEW_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          postViewListApi: {
            ...state.postViewListApi,
            ...{ viewListLoading: true },
          },
        },
      };
    case USER_POST_VIEW_LIST_DATA_SUCCESS:
      let ViewSucess = {
        viewListLoading: false,
        viewListStatus: action.response.data.status,
        viewListmessage: action.response.data.message,
      };
      let viewLists = {
        viewList: !!action.response.data.list
          ? [...state.postViewListDetail.viewList, ...action.response.data.list]
          : [],
        totalViews: action.response.data.total,
      };
      for (let i in state.getProfilePostDetail.getProfilePost) {
        if (
          state.getProfilePostDetail.getProfilePost[i].post_id ==
          state.postViewListDetail.viewPostId
        ) {
          state.getProfilePostDetail.getProfilePost[i].post_views = !!action
            .response.data.total
            ? action.response.data.total
            : 0;
        }
      }
      return {
        ...state,
        ...{
          postViewListApi: { ...state.postViewListApi, ...ViewSucess },
          postViewListDetail: { ...state.postViewListDetail, ...viewLists },
        },
      };
    case USER_POST_VIEW_LIST_DATA_FAILURE:
      let ViewFailure = {
        viewListLoading: false,
        viewListStatus: action.status,
        viewListmessage: action.response.data.message,
      };
      let viewListFail = {
        viewList: [],
        totalViews: "",
      };
      return {
        ...state,
        ...{
          postViewListApi: { ...state.postViewListApi, ...ViewFailure },
          postViewListDetail: { ...state.postViewListDetail, ...viewListFail },
        },
      };
    case CLEAR_USER_POST_VIEW_LIST_RESPONSE:
      let clearViewList = {
        viewListStatus: "",
        viewListmessage: "",
      };
      let clearViewDetail = {
        viewList: [],
        totalViews: "",
        viewListPage: 0,
        viewPostId: "",
      };
      return {
        ...state,
        ...{
          postViewListApi: { ...state.postViewListApi, ...clearViewList },
          postViewListDetail: {
            ...state.postViewListDetail,
            ...clearViewDetail,
          },
        },
      };
    case SINGLE_USER_POST_VIEW_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlePostViewListApi: {
            ...state.singlePostViewListApi,
            ...{ singleViewListLoading: true },
          },
        },
      };
    case SINGLE_USER_POST_VIEW_LIST_DATA_SUCCESS:
      let singleViewListSucess = {
        singleViewListLoading: false,
        singleViewListStatus: action.response.data.status,
        singleViewListMessage: action.response.data.message,
      };
      let SingleViewLists = {
        viewList: !!action.response.data.list
          ? [...state.postViewListDetail.viewList, ...action.response.data.list]
          : [],
        totalViews: action.response.data.total,
      };
      for (let i in state.activeCarousalPostDetail.activeCarousalPost) {
        if (i == state.activeCarousalPostDetail.activeIndexPost) {
          state.activeCarousalPostDetail.activeCarousalPost[i].view_count =
            !!action.response.data.total ? action.response.data.total : "";
        }
      }
      return {
        ...state,
        ...{
          singlePostViewListApi: {
            ...state.singlePostViewListApi,
            ...singleViewListSucess,
          },
          postViewListDetail: {
            ...state.postViewListDetail,
            ...SingleViewLists,
          },
        },
      };
    case SINGLE_USER_POST_VIEW_LIST_DATA_FAILURE:
      let SingleViewListFailure = {
        singleViewListLoading: false,
        singleViewListStatus: action.status,
        singleViewListMessage: action.response.data.message,
      };
      let SingleViewListFail = {
        viewList: [],
        totalViews: "",
      };
      return {
        ...state,
        ...{
          singlePostViewListApi: {
            ...state.singlePostViewListApi,
            ...SingleViewListFailure,
          },
          postViewListDetail: {
            ...state.postViewListDetail,
            ...SingleViewListFail,
          },
        },
      };
    case CLEAR_SINGLE_USER_POST_LIST_RESPONSE:
      let clearSingleViewList = {
        singleViewListStatus: "",
        singleViewListMessage: "",
      };
      let clearSingleViewDetail = {
        viewList: [],
        totalViews: "",
        viewPostId: "",
        viewFileId: "",
        singleViewListpage: 0,
      };
      return {
        ...state,
        ...{
          singlePostViewListApi: {
            ...state.singlePostViewListApi,
            ...clearSingleViewList,
          },
          postViewListDetail: {
            ...state.postViewListDetail,
            ...clearSingleViewDetail,
          },
        },
      };
    case USER_POST_SHARE_DATA_REQUEST:
      return {
        ...state,
        ...{
          shareFeedPostApi: {
            ...state.shareFeedPostApi,
            ...{ shareFeedPostLoading: true },
          },
        },
      };
    case USER_POST_SHARE_DATA_SUCCESS:
      let sharePostSucess = {
        shareFeedPostLoading: false,
        shareFeedPostStatus: action.response.data.status,
        shareFeedPostMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          shareFeedPostApi: { ...state.shareFeedPostApi, ...sharePostSucess },
        },
      };
    case USER_POST_SHARE_DATA_FAILURE:
      let sharePostFailure = {
        shareFeedPostLoading: false,
        shareFeedPostStatus: action.status,
        shareFeedPostMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          shareFeedPostApi: { ...state.shareFeedPostApi, ...sharePostFailure },
        },
      };
    case CLEAR_USER_POST_SHARE_RESPONSE:
      let clearSharePost = {
        shareFeedPostLoading: false,
        shareFeedPostStatus: "",
        shareFeedPostMessage: "",
      };

      return {
        ...state,
        ...{
          shareFeedPostApi: { ...state.shareFeedPostApi, ...clearSharePost },
        },
      };
    case SINGLE_USER_POST_SHARE_DATA_REQUEST:
      return {
        ...state,
        ...{
          shareSingleFeedPostApi: {
            ...state.shareSingleFeedPostApi,
            ...{ shareSingleFeedLoading: true },
          },
        },
      };
    case SINGLE_USER_POST_SHARE_DATA_SUCCESS:
      let singleShareSucess = {
        shareSingleFeedLoading: false,
        shareSingleFeedStatus: action.response.data.status,
        shareSingleFeedMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          shareSingleFeedPostApi: {
            ...state.shareSingleFeedPostApi,
            ...singleShareSucess,
          },
        },
      };
    case SINGLE_USER_POST_SHARE_DATA_FAILURE:
      let singleShareFailure = {
        shareSingleFeedLoading: false,
        shareSingleFeedStatus: action.status,
        shareSingleFeedMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          shareSingleFeedPostApi: {
            ...state.shareSingleFeedPostApi,
            ...singleShareFailure,
          },
        },
      };
    case CLEAR_SINGLE_USER_POST_SHARE_RESPONSE:
      let clearSingleShare = {
        shareSingleFeedStatus: "",
        shareSingleFeedMessage: "",
      };

      return {
        ...state,
        ...{
          shareSingleFeedPostApi: {
            ...state.shareSingleFeedPostApi,
            ...clearSingleShare,
          },
        },
      };
    case CHANGE_USER_SHARE_LIST_DETAIL:
      return {
        ...state,
        ...{
          postShareListDetail: {
            ...state.postShareListDetail,
            ...action.newState,
          },
        },
      };
    case USER_POST_SHARE_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          feedShareListApi: {
            ...state.feedShareListApi,
            ...{ feedShareListLoading: true },
          },
        },
      };
    case USER_POST_SHARE_LIST_DATA_SUCCESS:
      let shareListSucess = {
        feedShareListLoading: false,
        feedShareListStatus: action.response.data.status,
        feedShareListMessage: action.response.data.message,
      };
      let shareLists = {
        shareList: !!action.response.data.list
          ? [
            ...state.postShareListDetail.shareList,
            ...action.response.data.list,
          ]
          : [],
        totalShare: action.response.data.total,
      };
      for (let i in state.getProfilePostDetail.getProfilePost) {
        if (
          state.getProfilePostDetail.getProfilePost[i].post_id ==
          state.postShareListDetail.sharePostId
        ) {
          state.getProfilePostDetail.getProfilePost[i].share_count = !!action
            .response.data.total
            ? action.response.data.total
            : 0;
        }
      }
      return {
        ...state,
        ...{
          feedShareListApi: { ...state.feedShareListApi, ...shareListSucess },
          postShareListDetail: { ...state.postShareListDetail, ...shareLists },
        },
      };
    case USER_POST_SHARE_LIST_DATA_FAILURE:
      let shareFailure = {
        feedShareListLoading: false,
        feedShareListStatus: action.status,
        feedShareListMessage: action.response.data.message,
      };
      let shareListFail = {
        shareList: [],
        totalShare: "",
      };
      return {
        ...state,
        ...{
          feedShareListApi: { ...state.feedShareListApi, ...shareFailure },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...shareListFail,
          },
        },
      };
    case CLEAR_USER_POST_SHARE_LIST_RESPONSE:
      let clearShareList = {
        feedShareListStatus: "",
        feedShareListMessage: "",
      };
      let clearShareDetail = {
        shareList: [],
        totalShare: "",
        shareListPage: 0,
        sharePostId: "",
      };
      return {
        ...state,
        ...{
          feedShareListApi: { ...state.feedShareListApi, ...clearShareList },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...clearShareDetail,
          },
        },
      };
    case SINGLE_USER_POST_SHARE_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          singlefeedShareListApi: {
            ...state.singlefeedShareListApi,
            ...{ singleShareListLoading: true },
          },
        },
      };
    case SINGLE_USER_POST_SHARE_LIST_DATA_SUCCESS:
      let singleShareListSucess = {
        singleShareListLoading: false,
        singleShareListStatus: action.response.data.status,
        singleShareListMessage: action.response.data.message,
      };
      let SingleShareLists = {
        shareList: !!action.response.data.list
          ? [
            ...state.postShareListDetail.shareList,
            ...action.response.data.list,
          ]
          : [],
        totalShare: action.response.data.total,
      };
      for (let i in state.activeCarousalPostDetail.activeCarousalPost) {
        if (i == state.activeCarousalPostDetail.activeIndexPost) {
          state.activeCarousalPostDetail.activeCarousalPost[i].share_count =
            !!action.response.data.total ? action.response.data.total : 0;
        }
      }
      return {
        ...state,
        ...{
          singlefeedShareListApi: {
            ...state.singlefeedShareListApi,
            ...singleShareListSucess,
          },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...SingleShareLists,
          },
        },
      };
    case SINGLE_USER_POST_SHARE_LIST_DATA_FAILURE:
      let SingleShareListFailure = {
        singleShareListLoading: false,
        singleShareListStatus: action.status,
        singleShareListMessage: action.response.data.message,
      };
      let SingleShareListFail = {
        shareList: [],
        totalShare: "",
      };
      return {
        ...state,
        ...{
          singlefeedShareListApi: {
            ...state.singlefeedShareListApi,
            ...SingleShareListFailure,
          },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...SingleShareListFail,
          },
        },
      };
    case CLEAR_SINGLE_USER_POST_SHARE_LIST_RESPONSE:
      let clearSingleShareList = {
        singleShareListStatus: "",
        singleShareListMessage: "",
      };
      let clearSingleShareDetail = {
        shareList: [],
        totalShare: "",
        sharePostId: "",
        shareFileId: "",
        singleShareListpage: 0,
      };
      return {
        ...state,
        ...{
          singlefeedShareListApi: {
            ...state.singlefeedShareListApi,
            ...clearSingleShareList,
          },
          postShareListDetail: {
            ...state.postShareListDetail,
            ...clearSingleShareDetail,
          },
        },
      };
    case DELETE_MY_POST_DATA_REQUEST:
      return {
        ...state,
        ...{
          deletePostApi: {
            ...state.deletePostApi,
            ...{ deletePostLoading: true },
          },
        },
      };
    case DELETE_MY_POST_DATA_SUCCESS:
      let deletePostSucess = {
        deletePostLoading: false,
        deletePostStatus: action.response.data.status,
        deletePostMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          deletePostApi: { ...state.deletePostApi, ...deletePostSucess },
        },
      };
    case DELETE_MY_POST_DATA_FAILURE:
      let deletePostFailure = {
        deletePostLoading: false,
        deletePostStatus: action.status,
        deletePostMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          deletePostApi: { ...state.deletePostApi, ...deletePostFailure },
        },
      };
    case CLEAR_DELETE_MY_POST_RESPONSE:
      let clearDeletePost = {
        deletePostStatus: "",
        deletePostMessage: "",
      };

      return {
        ...state,
        ...{
          deletePostApi: { ...state.deletePostApi, ...clearDeletePost },
        },
      };
    default:
      return state;
  }
};
