import {
    GET_FOOTER_CONTENT_FAILURE,
  GET_FOOTER_CONTENT_REQUEST,
  GET_FOOTER_CONTENT_SUCCESS,
  GET_LANDINGPAGE_INDUSTRIES_FAILURE,
  GET_LANDINGPAGE_INDUSTRIES_REQUEST,
  GET_LANDINGPAGE_INDUSTRIES_SUCCESS,
  GET_LANDING_PAGE_CONTENT_FAILURE,
  GET_LANDING_PAGE_CONTENT_REQUEST,
  GET_LANDING_PAGE_CONTENT_SUCCESS,
} from "./LandingContentConstant";


const initialState = {
  // TO STORE LANDING PAGE CONTENT FROM RESPONSE
  landingPageContent: {
    getLandingPageLoading: false,
    getLandingPageStatus: "",
    getLandingSuccess: "",
    getLandingPageMessage: "",
    getLandingPageData: [],
  },

  // TO STORE INDUSTRIES CONTENT FROM RESPONSE
  landingPageIndustries: {
    getLandingPageIndustriesLoading: false,
    getLandingPageIndustriesStatus: "",
    getLandingIndustriesSuccess: "",
    getLandingIndustriesPageMessage: "",
    getLandingIndustriesPageData: [],
  },

  // TO STORE FOOTER RESPONSE
  landingPageFooter: {
    getLandingPageFooterLoading: false,
    getLandingPageFooterStatus: "",
    getLandingFooterSuccess: "",
    getLandingFooterMessage: "",
    getLandingFooterData: {},
  },

  
};

export const LandingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    // FOR LANDING PAGE CONTENT
    case GET_LANDING_PAGE_CONTENT_REQUEST:
      return {
        ...state,
        ...{
          landingPageContent: {
            ...state.landingPageContent,
            ...{ getLandingPageLoading: true },
          },
        
        },
      };
    case GET_LANDING_PAGE_CONTENT_SUCCESS:
      let getLandingPageContentSuccess = {
        getLandingPageLoading: false,
        getLandingPageStatus: 200,
        getLandingPageSuccess: action.response.data.success,
        getLandingPageMessage: action.response.data.message,
        getLandingPageData: action.response.data
          ? action.response.data?.data
          : [],
      };
      
      return {
        ...state,
        ...{
          landingPageContent: {
            ...state.landingPageContent,
            ...getLandingPageContentSuccess,
          },
        },
      };
    case GET_LANDING_PAGE_CONTENT_FAILURE:
      let getLandingPageContentFailure = {
        getLandingPageLoading: false,
        getLandingPageStatus: action?.status,
        getLandingPageSuccess: false,
        getLandingPageMessage: action.response.data.message,
        getLandingPageData: [],
      };
      return {
        ...state,
        ...{
          landingPageContent: {
            ...state.landingPageContent,
            ...getLandingPageContentFailure,
          },
        },
      };

      // FOR INDUSTRIES ON LANDING PAGE
    case GET_LANDINGPAGE_INDUSTRIES_REQUEST:
      return {
        ...state,
        ...{
          landingPageIndustries: {
            ...state.landingPageIndustries,
            ...{ getLandingPageIndustriesLoading: true },
          },
        },
      };

    case GET_LANDINGPAGE_INDUSTRIES_SUCCESS:
      let landingPageIndustriesSuccess = {
        getLandingPageIndustriesLoading: false,
        getLandingPageIndustriesStatus: 200,
        getLandingIndustriesSuccess: action.response.data.success,
        getLandingIndustriesPageMessage: action.response.data.message,
        getLandingIndustriesPageData: action?.response.data,
      };

      return {
        ...state,
        ...{
          landingPageIndustries: {
            ...state.landingPageIndustries,
            ...landingPageIndustriesSuccess,
          },
        },
      };

    case GET_LANDINGPAGE_INDUSTRIES_FAILURE:
      let landingPageIndustriesFailure = {
        getLandingPageIndustriesLoading: false,
        getLandingPageIndustriesStatus: action.status,
        getLandingIndustriesSuccess: false,
        getLandingIndustriesPageMessage: action.response.data.message,
        getLandingIndustriesPageData: [],
      };

      return {
        ...state,
        ...{
          landingPageIndustries: {
            ...state.landingPageIndustries,
            ...landingPageIndustriesFailure,
          },
        },
      };

      // FOR FOOTER CONTENT
    case GET_FOOTER_CONTENT_REQUEST:
      return {
        ...state,
        ...{
          landingPageFooter: {
            ...state.landingPageFooter,
            ...{ getLandingPageFooterLoading: true },
          },
        },
      };

    case GET_FOOTER_CONTENT_SUCCESS:
      let landingPageFooterSuccess = {
        getLandingPageFooterLoading: false,
        getLandingPageFooterStatus: 200,
        getLandingFooterSuccess: action.response?.data.success,
        getLandingFooterMessage: action.response?.message,
        getLandingFooterData: action.response?.data,
      };
      return {
        ...state,
        ...{
          landingPageFooter: {
            ...state.landingPageFooter,
            ...landingPageFooterSuccess,
          },
        },
      };

      case GET_FOOTER_CONTENT_FAILURE:
        let landingPageFooterFailure={
            getLandingPageFooterLoading: false,
            getLandingPageFooterStatus: action.status,
            getLandingFooterSuccess: action.response?.data.success,
            getLandingFooterMessage: action.response?.message,
            getLandingFooterData:{}
        }
        return {
            ...state,
            ...{
              landingPageFooter: {
                ...state.landingPageFooter,
                ...landingPageFooterFailure,
              },
            },
          };
    default:
      return state;
  }
};
