import { DELETE_API, GET_API, POST_API } from "../../main/fetch"
import {
    DELETE_GALLERY_DATA_FAILURE, DELETE_GALLERY_DATA_REQUEST, DELETE_GALLERY_DATA_SUCCESS,
    GET_GALLERY_PHOTO_DATA_FAILURE, GET_GALLERY_PHOTO_DATA_REQUEST, GET_GALLERY_PHOTO_DATA_SUCCESS, GET_GALLERY_VIDEO_DATA_FAILURE,
    GET_GALLERY_VIDEO_DATA_REQUEST, GET_GALLERY_VIDEO_DATA_SUCCESS, SHARE_GALLERY_DATA_FAILURE, SHARE_GALLERY_DATA_REQUEST, SHARE_GALLERY_DATA_SUCCESS
} from "../common/components/ProfileGallery/ProfileGalleryConstant"
import {
    DELETE_MY_POST_DATA_FAILURE, DELETE_MY_POST_DATA_REQUEST, DELETE_MY_POST_DATA_SUCCESS, GET_USER_ALL_POST_DATA_FAILURE,
    GET_USER_ALL_POST_DATA_REQUEST, GET_USER_ALL_POST_DATA_SUCCESS, SINGLE_USER_POST_CMT_REPLY_DATA_FAILURE, SINGLE_USER_POST_CMT_REPLY_DATA_REQUEST,
    SINGLE_USER_POST_CMT_REPLY_DATA_SUCCESS, SINGLE_USER_POST_CMT_REPLY_LIST_DATA_FAILURE, SINGLE_USER_POST_CMT_REPLY_LIST_DATA_REQUEST,
    SINGLE_USER_POST_CMT_REPLY_LIST_DATA_SUCCESS, SINGLE_USER_POST_COMMENT_DATA_FAILURE, SINGLE_USER_POST_COMMENT_DATA_REQUEST,
    SINGLE_USER_POST_COMMENT_DATA_SUCCESS, SINGLE_USER_POST_COMMENT_LIST_DATA_FAILURE, SINGLE_USER_POST_COMMENT_LIST_DATA_REQUEST,
    SINGLE_USER_POST_COMMENT_LIST_DATA_SUCCESS, SINGLE_USER_POST_REACT_DATA_FAILURE, SINGLE_USER_POST_REACT_DATA_REQUEST,
    SINGLE_USER_POST_REACT_DATA_SUCCESS, SINGLE_USER_POST_SHARE_DATA_FAILURE, SINGLE_USER_POST_SHARE_DATA_REQUEST, SINGLE_USER_POST_SHARE_DATA_SUCCESS,
    SINGLE_USER_POST_SHARE_LIST_DATA_FAILURE, SINGLE_USER_POST_SHARE_LIST_DATA_REQUEST, SINGLE_USER_POST_SHARE_LIST_DATA_SUCCESS,
    SINGLE_USER_POST_VIEW_DATA_FAILURE, SINGLE_USER_POST_VIEW_DATA_REQUEST, SINGLE_USER_POST_VIEW_DATA_SUCCESS, SINGLE_USER_POST_VIEW_LIST_DATA_FAILURE, SINGLE_USER_POST_VIEW_LIST_DATA_REQUEST,
    SINGLE_USER_POST_VIEW_LIST_DATA_SUCCESS, SINGLE_USER_REACT_LIST_DATA_FAILURE, SINGLE_USER_REACT_LIST_DATA_REQUEST, SINGLE_USER_REACT_LIST_DATA_SUCCESS,
    USER_POST_COMMENT_DATA_FAILURE, USER_POST_COMMENT_DATA_REQUEST, USER_POST_COMMENT_DATA_SUCCESS, USER_POST_COMMENT_LIST_DATA_FAILURE, USER_POST_COMMENT_LIST_DATA_REQUEST, USER_POST_COMMENT_LIST_DATA_SUCCESS,
    USER_POST_COMMENT_REPLY_DATA_FAILURE, USER_POST_COMMENT_REPLY_DATA_REQUEST, USER_POST_COMMENT_REPLY_DATA_SUCCESS, USER_POST_COMMENT_REPLY_LIST_DATA_FAILURE, USER_POST_COMMENT_REPLY_LIST_DATA_REQUEST, USER_POST_COMMENT_REPLY_LIST_DATA_SUCCESS,
    USER_POST_REACT_DATA_FAILURE, USER_POST_REACT_DATA_REQUEST, USER_POST_REACT_DATA_SUCCESS, USER_POST_REACT_LIST_DATA_FAILURE, USER_POST_REACT_LIST_DATA_REQUEST, USER_POST_REACT_LIST_DATA_SUCCESS, USER_POST_SHARE_DATA_FAILURE, USER_POST_SHARE_DATA_REQUEST,
    USER_POST_SHARE_DATA_SUCCESS, USER_POST_SHARE_LIST_DATA_FAILURE, USER_POST_SHARE_LIST_DATA_REQUEST, USER_POST_SHARE_LIST_DATA_SUCCESS, USER_POST_VIEW_DATA_FAILURE, USER_POST_VIEW_DATA_REQUEST, USER_POST_VIEW_DATA_SUCCESS, USER_POST_VIEW_LIST_DATA_FAILURE,
    USER_POST_VIEW_LIST_DATA_REQUEST, USER_POST_VIEW_LIST_DATA_SUCCESS
} from "../common/components/ProfilePosts/ProfilePostConstant"
import {
    COMMENT_LIST_API, DELETE_GALLERY_ITEMS_API, DELETE_POST_API, GET_SELLER_STATUS, GET_USER_ALL_POST_API, GET_USER_GALLERY_API, POST_COMMENT_API, POST_COMMENT_REPLY_API, POST_COMMENT_REPLY_LIST_API, POST_REACTION_API, POST_REACTION_LIST_API, POST_SHARE_API, POST_SHARE_LIST_API, POST_UPLOAD_API, POST_VIEW_API, POST_VIEW_LIST_API, SINGLE_POST_COMMENT_API,
    SINGLE_POST_COMMENT_LIST_API, SINGLE_POST_COMMENT_REPLY_API, SINGLE_POST_COMMENT_REPLY_LIST_API, SINGLE_POST_REACTION_API, SINGLE_POST_REACTION_LIST_API, SINGLE_POST_SHARE_API, SINGLE_POST_SHARE_LIST_API, SINGLE_POST_VIEW_API, SINGLE_POST_VIEW_LIST_API
} from "../urls"

//this function is used to fetch all posts for the particular user
const getAllProfilePostApi = (body) => {
    return {
        [POST_API]: {
            endpoint: GET_USER_ALL_POST_API,
            types: [GET_USER_ALL_POST_DATA_REQUEST,
                GET_USER_ALL_POST_DATA_SUCCESS,
                GET_USER_ALL_POST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to react on post 
const userPostReactApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_REACTION_API,
            types: [USER_POST_REACT_DATA_REQUEST,
                USER_POST_REACT_DATA_SUCCESS,
                USER_POST_REACT_DATA_FAILURE],
            body
        }
    }
}
//this function is used to react on a file in post
const singleUserPostReactApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_REACTION_API,
            types: [SINGLE_USER_POST_REACT_DATA_REQUEST,
                SINGLE_USER_POST_REACT_DATA_SUCCESS,
                SINGLE_USER_POST_REACT_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch post react list
const userPostReactListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_REACTION_LIST_API,
            types: [USER_POST_REACT_LIST_DATA_REQUEST,
                USER_POST_REACT_LIST_DATA_SUCCESS,
                USER_POST_REACT_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch particular file reaction list 
const singleUserPostReactListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_REACTION_LIST_API,
            types: [SINGLE_USER_REACT_LIST_DATA_REQUEST,
                SINGLE_USER_REACT_LIST_DATA_SUCCESS,
                SINGLE_USER_REACT_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch post comment list 
const userPostCommentListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: COMMENT_LIST_API,
            types: [USER_POST_COMMENT_LIST_DATA_REQUEST,
                USER_POST_COMMENT_LIST_DATA_SUCCESS,
                USER_POST_COMMENT_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to add comment on post
const userPostCommentApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_COMMENT_API,
            types: [USER_POST_COMMENT_DATA_REQUEST,
                USER_POST_COMMENT_DATA_SUCCESS,
                USER_POST_COMMENT_DATA_FAILURE],
            body
        }
    }
}
//this function is used to reply the comment on post 
const userPostCommentReplyApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_COMMENT_REPLY_API,
            types: [USER_POST_COMMENT_REPLY_DATA_REQUEST,
                USER_POST_COMMENT_REPLY_DATA_SUCCESS,
                USER_POST_COMMENT_REPLY_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch reply comment list 
const userPostCommentReplyListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_COMMENT_REPLY_LIST_API,
            types: [USER_POST_COMMENT_REPLY_LIST_DATA_REQUEST,
                USER_POST_COMMENT_REPLY_LIST_DATA_SUCCESS,
                USER_POST_COMMENT_REPLY_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to comment on particular file
const singleUserPostCommentApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_COMMENT_API,
            types: [SINGLE_USER_POST_COMMENT_DATA_REQUEST,
                SINGLE_USER_POST_COMMENT_DATA_SUCCESS,
                SINGLE_USER_POST_COMMENT_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch particular file comment list
const singleUserPostCommentListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_COMMENT_LIST_API,
            types: [SINGLE_USER_POST_COMMENT_LIST_DATA_REQUEST,
                SINGLE_USER_POST_COMMENT_LIST_DATA_SUCCESS,
                SINGLE_USER_POST_COMMENT_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to reply the comment on particular file 
const singleUserPostCommentReplyApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_COMMENT_REPLY_API,
            types: [SINGLE_USER_POST_CMT_REPLY_DATA_REQUEST,
                SINGLE_USER_POST_CMT_REPLY_DATA_SUCCESS,
                SINGLE_USER_POST_CMT_REPLY_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch the comment reply list on the particular file 
const singleUserPostCommentReplyListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_COMMENT_REPLY_LIST_API,
            types: [SINGLE_USER_POST_CMT_REPLY_LIST_DATA_REQUEST,
                SINGLE_USER_POST_CMT_REPLY_LIST_DATA_SUCCESS,
                SINGLE_USER_POST_CMT_REPLY_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to view the post
const userPostViewApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_VIEW_API,
            types: [USER_POST_VIEW_DATA_REQUEST,
                USER_POST_VIEW_DATA_SUCCESS,
                USER_POST_VIEW_DATA_FAILURE],
            body
        }
    }
}
//this function is used to view the particular file
const singleUserPostViewApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_VIEW_API,
            types: [SINGLE_USER_POST_VIEW_DATA_REQUEST,
                SINGLE_USER_POST_VIEW_DATA_SUCCESS,
                SINGLE_USER_POST_VIEW_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch the view list on post
const userPostViewListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_VIEW_LIST_API,
            types: [USER_POST_VIEW_LIST_DATA_REQUEST,
                USER_POST_VIEW_LIST_DATA_SUCCESS,
                USER_POST_VIEW_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch the view list in particular file
const singleUserPostViewListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_VIEW_LIST_API,
            types: [SINGLE_USER_POST_VIEW_LIST_DATA_REQUEST,
                SINGLE_USER_POST_VIEW_LIST_DATA_SUCCESS,
                SINGLE_USER_POST_VIEW_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to share the post
const userPostFeedShareApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_SHARE_API,
            types: [USER_POST_SHARE_DATA_REQUEST,
                USER_POST_SHARE_DATA_SUCCESS,
                USER_POST_SHARE_DATA_FAILURE],
            body
        }
    }
}
//this function is used to share the particular file
const singleUserPostShareApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_SHARE_API,
            types: [SINGLE_USER_POST_SHARE_DATA_REQUEST,
                SINGLE_USER_POST_SHARE_DATA_SUCCESS,
                SINGLE_USER_POST_SHARE_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch post share list
const userPostFeedShareListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_SHARE_LIST_API,
            types: [USER_POST_SHARE_LIST_DATA_REQUEST,
                USER_POST_SHARE_LIST_DATA_SUCCESS,
                USER_POST_SHARE_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to fetch particular file sshare list
const singleUserPostShareListApi = (body) => {
    return {
        [POST_API]: {
            endpoint: SINGLE_POST_SHARE_LIST_API,
            types: [SINGLE_USER_POST_SHARE_LIST_DATA_REQUEST,
                SINGLE_USER_POST_SHARE_LIST_DATA_SUCCESS,
                SINGLE_USER_POST_SHARE_LIST_DATA_FAILURE],
            body
        }
    }
}
//this function is used to share a post in gallery
const ShareGalleryFilesApi = (body) => {
    return {
        [POST_API]: {
            endpoint: POST_UPLOAD_API,
            types: [SHARE_GALLERY_DATA_REQUEST,
                SHARE_GALLERY_DATA_SUCCESS,
                SHARE_GALLERY_DATA_FAILURE],
            body,
            is_form_data: true
        }
    }
}
//this function is used to get gallery photos 
const getGalleryPhotoApi = ({ page, user_id, type }) => {
    return {
        [GET_API]: {
            endpoint: GET_USER_GALLERY_API + "?page=" + page + "&user_id=" + user_id + "&type=" + type,
            types: [GET_GALLERY_PHOTO_DATA_REQUEST,
                GET_GALLERY_PHOTO_DATA_SUCCESS,
                GET_GALLERY_PHOTO_DATA_FAILURE],

        }
    }
}
//this function is used to get all gallery videos
const getGalleryVideoApi = ({ page, user_id, type }) => {
    return {
        [GET_API]: {
            endpoint: GET_USER_GALLERY_API + "?page=" + page + "&user_id=" + user_id + "&type=" + type,
            types: [GET_GALLERY_VIDEO_DATA_REQUEST,
                GET_GALLERY_VIDEO_DATA_SUCCESS,
                GET_GALLERY_VIDEO_DATA_FAILURE],

        }
    }
}
//this function is used to delete gallery files like photos and videos.
const deleteGalleryFilesApi = (body) => {
    return {
        [DELETE_API]: {
            endpoint: DELETE_GALLERY_ITEMS_API,
            types: [DELETE_GALLERY_DATA_REQUEST,
                DELETE_GALLERY_DATA_SUCCESS,
                DELETE_GALLERY_DATA_FAILURE],
            body
        }
    }
}

const deleteMyPostApi = (body) => {
    return {
        [DELETE_API]: {
            endpoint: DELETE_POST_API,
            types: [DELETE_MY_POST_DATA_REQUEST,
                DELETE_MY_POST_DATA_SUCCESS,
                DELETE_MY_POST_DATA_FAILURE],
            body
        }
    }
}


export {

    getAllProfilePostApi, userPostReactApi, singleUserPostReactApi, userPostReactListApi, singleUserPostReactListApi, userPostCommentListApi,
    userPostCommentApi, userPostCommentReplyApi, userPostCommentReplyListApi, singleUserPostCommentApi, singleUserPostCommentListApi,
    singleUserPostCommentReplyApi, singleUserPostCommentReplyListApi, userPostViewApi, singleUserPostViewApi, userPostViewListApi, singleUserPostViewListApi,
    userPostFeedShareApi, singleUserPostShareApi, userPostFeedShareListApi, singleUserPostShareListApi, ShareGalleryFilesApi, getGalleryPhotoApi, getGalleryVideoApi,
    deleteGalleryFilesApi, deleteMyPostApi,

}