import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { friendListApi } from "../../library/api/MyNetworkApiService";
import {
  ADMIN,
  IMAGE_BASE_URL,
  MEDIA_BASE_URL,
  SOCKET,
} from "../../library/urls";
import { Scrollbars } from "react-custom-scrollbars";
import { allFriendandGroupList, changeChatDetails } from "./PoolsChatAction";

import { getCookie, setCookie } from "../../library/utilities/functions";
import { useHistory } from "react-router-dom";
import CreateGroupModal from "./CreateGroupModal";

import SearchFriendModal from "./SearchFriendModal";
// new merged import----//
import { css } from "@emotion/core";
import { BarLoader } from "react-spinners";
let count = 0;

let newList = [];
let newChatList = [];
let currentUserId = "";
const ChatList = ({ poolsChatState, searchState, searchProfileResponse }) => {
  const dispatch = useDispatch();
  const networkState = useSelector((state) => state.MyNetworkReducer);
  // new merged selecter
  const allChatList = useSelector((state) => state.chatListReducer);
  const authState = useSelector((state) => state.Authreducer);

  const { getChatandGroupList } = allChatList;
  // ------------//
  newChatList = getChatandGroupList;
  const {
    friendListDetail: { friendList, groupList },
  } = networkState;

  // const [indexedDBChatList, setDBChatList] = useState([]);
  const { chatFriendListPage } = poolsChatState;
  const {
    chatDetails: { currenUserId },
  } = poolsChatState;
  const {
    friendListApi: { friendListLoading },
  } = networkState;

  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  const history = useHistory();
  const [searchFriendModal, setSearchFriendModal] = useState(false);

  const [isCreateGroup, setIsCreateGroup] = useState(false);

  // new merged state----//
  const [page, setPage] = useState(1);
  const [noMoreUser, setNoMoreUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);
  const isClickOutSide = useRef(null);
  // loader css//

  const override = css`
    display: block;
    margin: auto;
    border-radius: 40px !important;
    width: 100%;
    background: rgb(31, 141, 205);
    background: linear-gradient(
      90deg,
      rgba(31, 141, 205, 1) 0%,
      rgba(109, 57, 125, 1) 53%,
      rgba(187, 82, 138, 1) 100%
    );
  `;

  // function connectIndexed() {
  //   return indexedDB.open("ChatDatabase", 1);
  // }

  useEffect(() => {
    if (!!chatFriendListPage) {
      if (!friendListLoading) {
        const body = {
          page: chatFriendListPage,
          chat: true,
        };
        dispatch(friendListApi(body));
      }
    }
  }, [chatFriendListPage]);

  // GET ONLINE FRIEND ON CHAT OPEN RENDER ONLY IF USER IS ON CHAT PATH//
  currentUserId = currenUserId;
  useEffect(() => {
    return () => {
      count = 0;
    };
  }, []);

  //this function is used when clicking on chat list user then showing user profile and highlight that user row
  newList = getChatandGroupList;

  function sortByDate(a, b) {
    if (a.dateTimestamp > b.dateTimestamp) {
      return -1;
    }
    if (a.dateTimestamp < b.dateTimestamp) {
      return 1;
    }
    return 0;
  }

  //this function is rendered when the user scrolling chat list.
  const handleScrollChat = (response) => {
    if (response.top == 1) {
      if (!!getChatandGroupList && getChatandGroupList.length > 9) {
        if (!noMoreUser) {
          setPage(page + 1);

          SOCKET.emit("get_next_friend_list", {
            u_id: profileData?.id,
            page: page + 1,
          });
        }
      }
    }
    let url = window?.location.href;
    if (url.split("/")[url.split("/").length - 1] === "contact-us") {
      history.push("/chat");
    }
    const dropDown = document.getElementById("create-group");
    dropDown.style.visibility = "hidden";
    dropDown.style.opacity = 0;
  };

  // ------------- USED FOR PAGINTION ON CHAT LIST ----------------- //
  useEffect(() => {
    SOCKET.off("get_next_friend_list").on("get_next_friend_list", (list) => {
      if (!list.length) {
        setPage(page - 1);
        setNoMoreUser(true);
      } else {
        let newArr = [...getChatandGroupList, ...list];
        dispatch(allFriendandGroupList(newArr));
      }
      SOCKET.emit("get_unread_frd_messages", {
        user_id: profileData.id,
        check_user: profileData.id,
        toMe: true,
      });
    });
  }, [page]);

  //----------------this function is used to cut the first name----------//
  const handleDisplayFirstNmae = (name) => {
    if (!!name) {
      const splitname = name.split(" ");
      return splitname[0];
    }
  };
  //this function is used to go to invite page.
  const goToInvite = () => {
    history.push("/first-time-login");
  };

  const handleMenuAction = () => {
    hideShowLinkDrop();
    const dropDown = document.getElementById("create-group");
    dropDown.classList.toggle("profile-action-active");
  };

  const handleCreateGroupAction = () => {
    const dropDown = document.getElementById("create-group");
    dropDown.style.visibility = "hidden";
    dropDown.style.opacity = 0;
    setIsCreateGroup(true);
  };

  const hideShowLinkDrop = (is_currentUserIdClick) => {
    const dropDown = document.getElementById("create-group");
    if (dropDown && !is_currentUserIdClick) {
      if (dropDown.style.opacity == "1") {
        dropDown.style.opacity = 0;
        dropDown.style.visibility = "hidden";
      } else {
        dropDown.style.opacity = 1;
        dropDown.style.visibility = "visible";
      }
    }
    if (dropDown && is_currentUserIdClick) {
      dropDown.style.opacity = 0;
      dropDown.style.visibility = "hidden";
    }
  };

  const handleClose = () => {
    setIsCreateGroup(false);
  };

  // HANDLE OPEN MESSAGE PROFILE
  const handleOpenMessageProfile = (e, item) => {
    //---CONDITION ACCORDING TO NEW SOCKET---//

    if (!!item) {
      setCookie("chatType", item.type, 1);

      const friendId = !!getCookie("friendId")
        ? Number(getCookie("friendId"))
        : "";

      if (friendId) {
        // emitt when user click on message box//
        SOCKET.emit("message_read_unread_status", {
          session_id: getCookie("token_id"),
          receiver_id: friendId,
          is_read: 0,
          sender_id: !!profileData ? profileData.id : "",
        });
      }

      //------------- IF TYPE GROUP READ GROUP MESSAGES-----------//

      if (item.type === "group") {
        SOCKET.emit("read_group_messsages_by_user_id", {
          user_id: profileData.id,
          group_id: item.id,
        });
      }

      // send clicked so we can get id in message component
      let messageOpen = {
        currenUserId: item.type === "user" ? item.user_id : item.id,
      };
      dispatch(changeChatDetails(messageOpen));

      // ------------ADD STYLE TO SELECTED CHAT--------------- //

      getChatandGroupList.map((itemB) => {
        if (itemB.type === "user") {
          if (itemB.user_id === item.user_id) {
            setSelectedChat(item.user_id);
          }
        } else if (itemB.type === "group") {
          if (itemB.id === item.id) {
            setSelectedChat(item.id);
          }
        }
      });
    }
    // ----if pathname is contact and user click on friend chat the path will  be changed----//
    let url = window?.location.href;
    if (url.split("/")[url.split("/").length - 1] === "contact-us") {
      history.push("/chat");
    }
    // ---- close group create div when user clicked on friend chat ----//
    const dropDown = document.getElementById("create-group");
    dropDown.style.visibility = "hidden";
    dropDown.style.opacity = 0;
    // -----------------CONDITION END-----------------//
  };

  // --------------------------CALL SOCKET TO GET UNREAD MESSAGE COUNTS OF USER AND GROUP ON CHAT PAGE FIRST TIME-------------- //
  useEffect(() => {
    SOCKET.emit("get_unread_frd_messages", {
      user_id: profileData.id,
      check_user: profileData.id,
      toMe: true,
    });

    SOCKET.emit("get_all_unread_group_messages_for_login_user", {
      user_id: profileData.id,
      grp_id: null,
      toMe: true,
    });
  }, [newList]);

  // ------------get online away and offline status of user-----------//

  useEffect(() => {
    if (!!getChatandGroupList.length) {
      SOCKET.off("get_user_status").on("get_user_status", (user) => {
        let newArr = [];

        !!getChatandGroupList &&
          getChatandGroupList.map((item) => {
            if (item.user_id === user.user_id && item.type === "user") {
              item.online_status = user.status;
              item.online_status_time = user.time;

              newArr.push(item);
            } else {
              newArr.push(item);
            }
          });

        dispatch(allFriendandGroupList(newArr));
      });
    }
  }, [getChatandGroupList]);

  // --------------GET LAST MESSAGE AND NAME OF FRIEND IN CHAT START------------//

  useEffect(() => {
    SOCKET.off("get_unread_frd_messages").on(
      "get_unread_frd_messages",
      (unreadData) => {
        let total_unread_frds = 0;
        if (unreadData.check_user == profileData.id) {
          let chat_List = newChatList;

          let unreadList = unreadData.unreadMessagesList;

          for (let i in unreadList) {
            for (let j in chat_List) {
              if (unreadList[i].friend_id == chat_List[j].user_id) {
                chat_List[j].counts = unreadList[i].counts;
                chat_List[j].dateTimestamp = unreadList[i].created_at
                  ? unreadList[i].created_at
                  : 0;
              }
            }
          }

          let frdList = unreadList.filter((el) => {
            return !chat_List.find((element) => {
              return element.user_id == el.friend_id;
            });
          });

          for (let i in chat_List) {
            chat_List[i].dateTimestamp = !!chat_List[i]?.dateTimestamp
              ? chat_List[i]?.dateTimestamp
              : chat_List[i]?.created_at;
          }

          if (!!chat_List?.length) {
            chat_List.sort(sortByDate);
          }

          if (window.location.pathname == "/chat") {
            dispatch(allFriendandGroupList(chat_List));
          }
        }
      }
    );

    SOCKET.off("get_all_unread_group_messages_for_login_user").on(
      "get_all_unread_group_messages_for_login_user",
      () => {
        SOCKET.emit("fetch_all_unread_group_messages", {
          user_id: profileData.id,
        });

        SOCKET.off("fetch_all_unread_group_messages").on(
          "fetch_all_unread_group_messages",
          (unreadGroupList) => {
            let group_List = newChatList;

            for (let i in unreadGroupList) {
              for (let j in group_List) {
                if (
                  unreadGroupList[i].group_id == group_List[j].id &&
                  unreadGroupList[i].user_id == profileData.id
                ) {
                  group_List[j].counts = unreadGroupList[i].count;
                  group_List[j].dateTimestamp = unreadGroupList[i].created_at;
                }
              }
            }

            if (window.location.pathname == "/chat") {
              dispatch(allFriendandGroupList(group_List));
            }
          }
        );
      }
    );

    SOCKET.off("get_all_unread_group_messages").on(
      "get_all_unread_group_messages",
      () => {
        SOCKET.emit("fetch_all_unread_group_messages", {
          user_id: profileData.id,
        });

        SOCKET.off("fetch_all_unread_group_messages").on(
          "fetch_all_unread_group_messages",
          (unreadGroupList) => {
            let group_List = newChatList;

            for (let i in unreadGroupList) {
              for (let j in group_List) {
                if (
                  unreadGroupList[i].group_id == group_List[j].id &&
                  unreadGroupList[i].user_id == profileData.id &&
                  unreadGroupList[i].group_id !== currentUserId
                ) {
                  group_List[j].counts = unreadGroupList[i].count;
                  group_List[j].dateTimestamp = unreadGroupList[i].created_at;
                }
              }
            }

            group_List.sort(sortByDate);

            if (window.location.pathname == "/chat") {
              dispatch(allFriendandGroupList(group_List));
            }
          }
        );
      }
    );

    setTimeout(() => {
      SOCKET.off("set_last_message").on(
        "set_last_message",
        (lastMessageData) => {
          if (lastMessageData.type === "user") {
            if (lastMessageData.user_id == profileData.id) {
              for (let i in newList) {
                if (newList[i]?.user_id == lastMessageData.frd_id) {
                  if (lastMessageData?.message_type === 3) {
                    let lastEvent = JSON.parse(lastMessageData?.message);
                    newList[i].last_message = lastEvent?.request_nature;
                    newList[i].message_sender_id = lastMessageData.user_id;
                    newList[i].showYou = true;
                  } else {
                    (newList[i].last_message = lastMessageData.message),
                      (newList[i].message_sender_id = lastMessageData.user_id);
                    newList[i].showYou = true;
                  }
                }
              }
            }
            if (lastMessageData.frd_id == profileData.id) {
              for (let i in newList) {
                newList;
                if (newList[i].user_id == lastMessageData.user_id) {
                  newList[i].last_message = lastMessageData.message;
                  newList[i].message_sender_id = lastMessageData.user_id;
                  newList[i].showYou = false;
                }
              }
            }
            dispatch(allFriendandGroupList(newList));
          }

          if (lastMessageData.type === "group") {
            if (lastMessageData.user_from_id == profileData.id) {
              for (let item of newList) {
                if (
                  item.id == lastMessageData.grp_id &&
                  item.id !== undefined
                ) {
                  if (lastMessageData?.message_type === 3) {
                    let lastEvent = JSON.parse(lastMessageData?.message);
                    item.last_message = lastEvent?.request_nature;
                    item.user_from_id = lastMessageData.user_from_id;
                    item.showYou = true;
                  } else {
                    item.last_message = lastMessageData.message;
                    item.user_from_id = lastMessageData.user_from_id;
                    item.showYou = true;
                  }
                }
              }
            } else if (lastMessageData.user_from_id !== profileData.id) {
              for (let item of newList) {
                if (
                  item.id == lastMessageData.grp_id &&
                  item.id !== undefined
                ) {
                  item.last_message = lastMessageData.message;
                  item.user_from_id = lastMessageData.user_from_id;
                  item.user_from_name = lastMessageData.sender_name;
                  item.showYou = false;
                }
              }
            }
            dispatch(allFriendandGroupList(newList));
          }
        }
      );
    }, 200);
  }, []);

  // --------------GET LAST MESSAGE AND NAME OF FRIEND IN CHAT END------------//

  //-------------FUNCTION USED TO SHOW LAST MESSAGE AND NAME IN CHAT---------------------//
  const showLastMessageName = (item) => {
    let type = item.type;
    let message = "";

    if (item?.last_message?.length > 0) {
      if (!!item.last_message || item.last_message == undefined) {
        if (type == "user") {
          if (item?.message_type === 3) {
            try {
              // Attempt to parse the value as JSON
              const lastEvent = JSON.parse(item?.last_message);
              profileData.id == item.message_sender_id || item.showYou
                ? (message = `You: ${lastEvent?.request_nature}`)
                : (message = `${item.user_name}:  ${lastEvent?.request_nature}`);
            } catch (error) {
              // If parsing fails, return the original string
              profileData.id == item.message_sender_id || item.showYou
                ? (message = `You: ${item.last_message}`)
                : (message = `${item.user_name}:  ${item.last_message}`);
            }
          } else {
            profileData.id == item.message_sender_id || item.showYou
              ? (message = `You: ${item.last_message}`)
              : (message = `${item.user_name}:  ${item.last_message}`);
          }
        }
        if (type == "group") {
          if (item?.message_type === 3) {
            try {
              // Attempt to parse the value as JSON
              const lastEvent = JSON.parse(item?.last_message);
              profileData.id == item.user_from_id
                ? (message = `You: ${lastEvent?.request_nature}`)
                : (message = `${item.user_from_name}: ${lastEvent?.request_nature}`);
            } catch (error) {
              // If parsing fails, return the original string
              profileData.id == item.message_sender_id || item.showYou
                ? (message = `You: ${item.last_message}`)
                : (message = `${item.user_name}:  ${item.last_message}`);
            }
          } else {
            profileData.id == item.user_from_id
              ? (message = `You: ${item.last_message}`)
              : (message = `${item.user_from_name}: ${item.last_message}`);
          }
        }
      }
    }
    return message;
  };

  // -----Close Search Modal start----//
  const closeSearchFriendModal = (
    setSearchFriend,
    setSearchFriendData,
    setSearchFriendsName,
    setUploadMedia,
    removeFile,
    setWriteMessage,
    setError,
    setFriendNotFound
  ) => {
    setSearchFriend({});
    setSearchFriendData([]);
    setSearchFriendsName("");
    setUploadMedia(false);
    removeFile("all");
    setWriteMessage("");
    setError(false);
    setSearchFriendModal(false);
    setFriendNotFound(false);
    dispatch(changeChatDetails({ userMessage: "" }));
  };
  //-----Close search modal end-----//

  const handleClickOutside = (e) => {
    if (!isClickOutSide.current.contains(e.target)) {
      const dropDown = document.getElementById("create-group");
      dropDown.style.visibility = "hidden";
      dropDown.style.opacity = 0;
    }
  };

  //----------------Loader on chat list when chat list length is 0-----------//
  useEffect(() => {
    if (getChatandGroupList?.length > 0) {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [getChatandGroupList]);

  useEffect(() => {
    if (getChatandGroupList.length > 0) {
      var device = $(window).width();
      if (device < 992) {
        $(document).on("click", ".chat_listing-wrap a", function () {
          $(this).parents(".col-md-3").addClass("chat-toggle");
          $(this).parents(".col-md-3").next(".col-md-6").addClass("chat-open");
        });
        /* mobile chat button */
        $(document).on("click", ".chat-icon", function () {
          $(".chat").find(".col-md-3").addClass("chat-toggle");
          $(".chat").find(".col-md-6").addClass("chat-open");
        });
      }
    }
  }, [getChatandGroupList]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <>
      <div className="col-md-3 bg-white full-width px-0" ref={isClickOutSide}>
        <div className="chat__header p-2 justify-content-between">
          <h2 className="h4 mb-0">PoolsChat</h2>
          {getChatandGroupList?.length > 0 ? (
            <>
              <i
                className="ri-edit-box-line write-msg"
                onClick={() => setSearchFriendModal(true)}
                data-toggle="modal"
                data-target="#search-friend-modal"
              />

              <i
                className="ri-more-2-fill dot-icon"
                onClick={handleMenuAction}
              ></i>

              <ul
                id="create-group"
                className="dropdown-list create-dropdown-list"
                onClick={handleCreateGroupAction}
                data-toggle="modal"
                data-target="#create-modal"
              >
                <li>
                  <a
                    className="border-0 d-flex justify-content-center"
                    href="javascript:void(0)"
                  >
                    <i className="ri-group-fill pr-1"></i> Create a new Group
                    Chat
                  </a>
                </li>
              </ul>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="bg-white chat_listing-wrap pt-2 px-2">
          <Scrollbars onScrollFrame={handleScrollChat} autoHide>
            {getChatandGroupList?.length > 0 ? (
              <>
                {getChatandGroupList?.map((item, index) => {
                  const onlineStatusStyle = !!item.user_deleted
                    ? null
                    : item.type == "user" && item?.online_status === 1
                    ? "active-point"
                    : item?.online_status === 0
                    ? ""
                    : "active-point-orange";
                  return item?.user_id === 176 && !item.last_message ? (
                    <>
                      {getChatandGroupList?.length === 1 &&
                        getChatandGroupList[0]?.last_message === undefined && (
                          <span>
                            "You currently don’t have anyone in your network,
                            {profileData.id !== ADMIN && (
                              <a style={{ color: "blue" }} onClick={goToInvite}>
                                view suggestions
                              </a>
                            )}
                            "
                          </span>
                        )}
                    </>
                  ) : (
                    <>
                      <a
                        key={index}
                        href="javascript:void(0)"
                        className={`link-color chat__listing position-relative d-block  p-2  ${
                          item.user_id === selectedChat
                            ? "active"
                            : item.id === selectedChat
                            ? "active"
                            : ""
                        }`}
                        onClick={(e) => handleOpenMessageProfile(e, item)}
                      >
                        <div className="chat__listing__users" key={index}>
                          {!!item.counts ? (
                            <span className="unread-msg">{item.counts}</span>
                          ) : (
                            ""
                          )}
                          <div
                            className={`${
                              item.type == "user" ? onlineStatusStyle : null
                            }`}
                          >
                            {!!item.is_individual ? (
                              <>
                                {" "}
                                <img
                                  className="individual-user"
                                  onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/member-default.svg")
                                  }
                                  src={
                                    !!item.user_deleted
                                      ? "/assets/images/defaultImage.jpg"
                                      : !!item.profile
                                      ? IMAGE_BASE_URL + item.profile
                                      : "/assets/images/member-default.svg"
                                  }
                                  alt="user"
                                />
                              </>
                            ) : (
                              <>
                                {item.type === "user" ? (
                                  <img
                                    className="business-user"
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/business_logo.svg")
                                    }
                                    src={
                                      !!item.business_profile
                                        ? IMAGE_BASE_URL + item.business_profile
                                        : !!item.user_deleted
                                        ? "/assets/images/company-logo-default.jpg"
                                        : "/assets/images/business_logo.svg"
                                    }
                                    alt="user"
                                  />
                                ) : (
                                  <img
                                    className={
                                      item.type === "group" &&
                                      " business-user group-user"
                                    }
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/group_logo.png")
                                    }
                                    src={
                                      !!item.profile
                                        ? MEDIA_BASE_URL + item?.profile
                                        : "/assets/images/group_logo.png"
                                    }
                                    alt="user"
                                  />
                                )}
                              </>
                            )}
                          </div>

                          <div
                            className={`chat__listing__users__details ${
                              item.user_deleted && "text-fade"
                            } `}
                          >
                            <h6 className="mb-0 text-capitalize">
                              {item?.is_individual && item.type == "user"
                                ? item?.user_name
                                : item?.business_name}
                            </h6>

                            <h6 className="mb-0 text-capitalize">
                              {item.type == "group" ? item?.title : null}
                            </h6>

                            <span className="small">
                              {!!item.user_deleted && (
                                <span className="account-deactivate">
                                  Deactivated
                                </span>
                              )}
                              {!item.user_deleted && showLastMessageName(item)}

                              {!item.last_message &&
                                item?.type !== "group" &&
                                item.user_deleted === false && (
                                  <>
                                    Now, you are Linked-up with{" "}
                                    {handleDisplayFirstNmae(
                                      !!item.is_individual
                                        ? item?.user_name
                                        : item?.business_name
                                    )}
                                  </>
                                )}
                            </span>
                          </div>
                        </div>
                      </a>
                    </>
                  );
                })}
                <div
                  style={{
                    width: "100%",
                    fontSize: "13px",
                    borderRadius: "10px",
                    backgroundColor: "#ededed",
                  }}
                >
                  {!!noMoreUser && (
                    <p
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        margin: "20px 0 20px 0",
                      }}
                    >
                      no more user
                    </p>
                  )}
                </div>
              </>
            ) : (
              <span>
                "You currently don’t have anyone in your network,
                {profileData.id !== ADMIN && (
                  <a style={{ color: "blue" }} onClick={goToInvite}>
                    view suggestions
                  </a>
                )}
                "
              </span>
            )}
            <div className="sweet-loading">
              <BarLoader color={"#b23ebb"} css={override} loading={loading} />
            </div>
          </Scrollbars>
        </div>

        {isCreateGroup ? (
          <CreateGroupModal handleClose={handleClose} friendList={friendList} />
        ) : (
          ""
        )}
      </div>
      <SearchFriendModal
        searchFriendModal={searchFriendModal}
        closeSearchFriendModal={closeSearchFriendModal}
        poolsChatState={poolsChatState}
        friendList={friendList}
        setSearchFriendModal={setSearchFriendModal}
      />
    </>
  );
};
export default ChatList;
