import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { SELLER_UPDATE_STATUS } from "../../library/urls";
import { getCookie } from "../../library/utilities/functions";
import axios from "axios";
import { signUpAddDataApi } from "../../library/api/AccountApiService";
import { useDispatch, useSelector } from "react-redux";
import { changeGeneralInfoInput } from "../Account/AccountAction";
import { Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

const SkillAndExpertise = ({ isEdit, profileData }) => {
  const selectInputRef = useRef();
  const selectInputModalRef = useRef();
  const selectInputModalTwoRef = useRef();

  const testSchema = Yup.object().shape({
    languageNameValue: Yup.string().required("Please Select Language"),
    languageLevelValue: Yup.string().required("Please Select Proficeny Level"),
  });
  const initialValues = {
    languageNameValue: "",
    languageLevelValue: "",
  };
  const [getAllSelectedSkills, setGetAllSelectedSkills] = useState([]);
  const [showSelectedLanguage, setShowSelectedLanguage] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [getAllIndividualData, setGetAllIndividualData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showAddLanguageError, setShowAddLangusgeError] = useState(false);
  const [filterOption, setFilterOption] = useState([]);
  const [filterLanguageOption, setFilterLanguageOption] = useState([]);
  const dispatch = useDispatch();
  const AccountStateData = useSelector((state) => state.AccountReducer);

  const languageData = [
    { value: 1, label: "Native" },
    { value: 2, label: "Intermediate" },
    { value: 3, label: "Expert" },
  ];

  const addAndUpdateSkillAndExertiseApifunc = () => {
    const data = new FormData();
    data.append("form_step", 2);
    data.append("type_individual", 1);
    data.append("expertise_ids", 1);
    data.append("skill_ids", 1);
    data.append("seller_status", 1);
    getAllSelectedSkills.forEach((item, index) =>
      data.append(`skill['${index}'][skill_id]`, item.value)
    );
    showSelectedLanguage.forEach((item, index) =>
      data.append(`language['${index}'][language_id]`, item.value)
    );
    showSelectedLanguage.forEach((item, index) =>
      data.append(
        `language['${index}'][proficiency_level]`,
        item.proficiency_id
      )
    );

    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };
    /* Read more about isConfirmed, isDenied below */
    Swal.fire({
      title: "<h5 style='color:#212529'>" + "Do you want to save?" + "</h5>",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Save",
      confirmButtonColor: "#00a8f3",
      denyButtonColor: "#88001b",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(signUpAddDataApi(data)).then(() =>
          axios(SELLER_UPDATE_STATUS, config).then((response) => { })
        );
        var next = jQuery(".seller-tabs  .active").parent("li").next("li");
        next.find("a").removeClass("disabled");
        next.find("a").trigger("click");
        scroolTop();
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleSaveStepTwo = (data) => {
    if (data === 1) {
      if (getAllSelectedSkills.length > 0 && showSelectedLanguage.length > 0) {
        addAndUpdateSkillAndExertiseApifunc();
      } else {
        setShowError(true);
      }
    } else if (data === 2) {
      if (
        isEdit === false &&
        getAllSelectedSkills.length > 0 &&
        showSelectedLanguage.length > 0
      ) {
        var next = jQuery(".seller-tabs  .active").parent("li").next("li");
        next.find("a").removeClass("disabled");
        next.find("a").trigger("click");
        scroolTop();
      } else if (
        isEdit &&
        getAllSelectedSkills.length > 0 &&
        showSelectedLanguage.length > 0
      ) {
        addAndUpdateSkillAndExertiseApifunc();
      } else {
        setShowError(true);
      }
    }
  };
  /*delete language  */
  const handleDelete = (id) => {
    const newShowSelectedLanguage = showSelectedLanguage.filter(
      (item, index) => index !== id
    );
    setShowSelectedLanguage(newShowSelectedLanguage);
  };

  const submitLanguage = () => {
    if (
      selectedLanguage != "" &&
      selectedLevel != "" &&
      selectedLanguage != null &&
      selectedLevel != null
    ) {
      let dataArray = [...showSelectedLanguage];
      dataArray.push({
        label: selectedLanguage?.label,
        value: selectedLanguage?.value,
        proficiency_level: selectedLevel?.label,
        proficiency_id: selectedLevel?.value,
      });
      setShowSelectedLanguage(dataArray);
      setShowAddLangusgeError(false);
      $("#languageModal").modal("hide");
      selectInputModalRef.current.select.clearValue();
      selectInputModalTwoRef.current.select.clearValue();
    } else {
      setShowAddLangusgeError(true);
    }
  };

  const handleSelectedLangusgeChange = (newData) => {
    setSelectedLanguage(newData);
  };

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#deebff" : null,
        color: "#333333",
      };
    },
  };

  const handleDropDownChange = (e, i) => {
    /* add particular proficiency_level and id into onchange */
    const getProficiencyId = languageData.find(
      (item) => item.label === e.target.value
    ).value;
    let clone = [...showSelectedLanguage];
    let objData = clone[i];
    objData.proficiency_level = e.target.value;
    objData.proficiency_id = getProficiencyId;
    clone[i] = objData;
  };

  const handleAddSkill = (opt) => {
    setGetAllSelectedSkills(opt);
    dispatch(changeGeneralInfoInput({ skills: opt }));
  };
  const handleBack = () => {
    $("#pills-tab > li:eq(0) a").trigger("click");
  };
  useEffect(async () => {
    setGetAllIndividualData(
      AccountStateData?.getAllSellerDataApi?.getAllSellerDatas
    );
  }, [AccountStateData]);

  useEffect(() => {
    const option = AccountStateData?.skillList.filter((itemA) => {
      return !getAllSelectedSkills?.find((itemB) => {
        return itemA.name === itemB.label;
      });
    });
    const newOption = option.map((data) => {
      return {
        label: data.name,
        value: data.id,
        __isNew__: true,
      };
    });

    setFilterOption(newOption);
  }, [AccountStateData?.skillList, getAllSelectedSkills]);

  useEffect(() => {
    const option = AccountStateData.languageList.filter((itemA) => {
      return !showSelectedLanguage?.find((itemB) => {
        return itemA.name === itemB.label;
      });
    });
    const newOptiom = option?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });

    setFilterLanguageOption(newOptiom);
  }, [AccountStateData.languageList, showSelectedLanguage]);
  /*add api data in states when component render first time */
  useEffect(() => {
    if (
      !!getAllIndividualData?.skills?.length ||
      !!getAllIndividualData.languages?.length
    ) {
      setGetAllSelectedSkills(getAllIndividualData?.skills);
      setShowSelectedLanguage(getAllIndividualData.languages);
    }
  }, [getAllIndividualData]);

  const handleCloseModel = () => {
    selectInputModalRef.current.select.clearValue();
    selectInputModalTwoRef.current.select.clearValue();
    setShowAddLangusgeError(false);
  };
  return (
    <Formik
      validationSchema={testSchema}
      initialValues={initialValues}
      onSubmit={(values) => { }}
    >
      {(props) => {
        return (
          <>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="form-group create-select-field">
                <h5>What are the various skills you have? </h5>
                <p className="mb-1">
                  Adding your skillset helps people know what you do
                </p>
                <p>
                  Your skills show what you can offer, and help us choose which
                  jobs to recommend to you.
                </p>
                <Select
                  ref={selectInputRef}
                  options={filterOption}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={
                    isEdit
                      ? getAllSelectedSkills?.map((items) => {
                        return {
                          label: items.label,
                          value: items.value,
                          __isNew__: true,
                        };
                      })
                      : getAllIndividualData?.skills !== undefined &&
                      getAllIndividualData.skills?.map((data) => {
                        return {
                          label: data.label,
                          value: data.value,
                          __isNew__: true,
                        };
                      })
                  }
                  isDisabled={isEdit ? false : true}
                  placeholder="Add your skills"
                  onChange={handleAddSkill}
                  isClearable
                  isMulti
                />
                {showError && getAllSelectedSkills?.length == 0 && (
                  <p className="error-message" style={{ color: "red" }}>
                    Please select the skills
                  </p>
                )}
              </div>
              <div className="form-group">
                <h5>Tell us about which languages you speak? </h5>
                <p>
                  So clients are often interested to know what languages you
                  speak and your proficiency levels.
                </p>
                <div className="table-wrapper w-100">
                  <table className="table table-striped" id="language-table">
                    <thead>
                      <tr>
                        <th>Language(s)</th>
                        <th>Proficiency Level</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {showSelectedLanguage?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.label} </td>
                            <td>
                              <select
                                onChange={(e) => handleDropDownChange(e, index)}
                                className="form-control select-ui level-select"
                                disabled={isEdit ? false : true}
                              >
                                <option>{item.proficiency_level}</option>
                                <option>
                                  {item.proficiency_level !== "Native"
                                    ? "Native"
                                    : "Expert"
                                      ? "Intermediate"
                                      : "Expert"}
                                </option>
                                <option>
                                  {item.proficiency_level !== "Expert"
                                    ? "Expert"
                                    : "Intermediate"}
                                </option>
                              </select>
                            </td>

                            <td>
                              <button
                                className="btn-blank d-flex aliign-items-center link-color"
                                onClick={() => handleDelete(index)}
                                disabled={isEdit ? false : true}
                              >
                                <i className="ri-delete-bin-line align-middle mr-1"></i>{" "}
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <a
                  href="javascript:;"
                  className="link-color"
                  data-toggle="modal"
                  data-target="#languageModal"
                  data-backdrop="static"
                  data-keyboard="false"
                  style={{ pointerEvents: isEdit ? null : "none" }}
                >
                  <i className="ri-add-fill align-middle"></i> Add a Language
                </a>
                {showError && showSelectedLanguage.length == 0 && (
                  <p className="error-message" style={{ color: "red" }}>
                    Please add the language
                  </p>
                )}
                <div className="form-group d-flex justify-content-end flex-wrap btn-wrapper mt-5">
                  {isEdit && (
                    <>
                      <a
                        href="javascript:;"
                        className="btn btn-secondary mr-3"
                        onClick={handleBack}
                      >
                        Start Over
                      </a>
                      <a
                        href="javascript:;"
                        className="btn btn-blue mr-3"
                        onClick={() => handleSaveStepTwo(1)}
                      >
                        Save
                      </a>{" "}
                    </>
                  )}
                  <a
                    href="javascript:;"
                    className="btn btn-primary"
                    onClick={() => handleSaveStepTwo(2)}
                  >
                    Next to Price &amp; Availability
                  </a>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="languageModal"
              tabIndex="-1"
              aria-labelledby="languageModalModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="languageModalLabel">
                      <img
                        src="/assets/images/icon.svg"
                        alt="icon"
                        className="site-icon"
                      />{" "}
                      Add Language
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModel}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Language</label>
                          <Select
                            ref={selectInputModalRef}
                            options={filterLanguageOption}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={customStyles}
                            placeholder="Add your Language(s)"
                            onChange={(newState) =>
                              handleSelectedLangusgeChange(newState)
                            }
                          />

                          {showAddLanguageError &&
                            selectedLanguage?.label?.length == undefined && (
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                Please select the Language
                              </p>
                            )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Proficiency Level</label>
                          <Select
                            ref={selectInputModalTwoRef}
                            styles={customStyles}
                            placeholder="Select Level"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={languageData}
                            onChange={(newValueData) =>
                              setSelectedLevel(newValueData)
                            }
                          />
                          {showAddLanguageError &&
                            selectedLevel?.label?.length == undefined && (
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                Please select the Proficiency Level
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={submitLanguage}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default SkillAndExpertise;
