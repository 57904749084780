import {
  CHANGE_FRIEND_LIST_DETAIL,
  CLEAR_FRIEND_LIST_RESPONSE,
  CHANGE_GROUP_LIST_DETAIL,
  CLEAR_SEARCH_FRIEND_RESPONSE,
  FRIEND_LIST_DATA_FAILURE,
  FRIEND_LIST_DATA_REQUEST,
  FRIEND_LIST_DATA_SUCCESS,
  SEARCH_FRIEND_LIST_DATA_FAILURE,
  SEARCH_FRIEND_LIST_DATA_REQUEST,
  SEARCH_FRIEND_LIST_DATA_SUCCESS,
  BUSINESS_FRIEND_LIST_REQUEST,
  BUSINESS_FRIEND_LIST_SUCCESS,
  BUSINESS_FRIEND_LIST_FAILURE,
  INDIVIDUAL_FRIEND_LIST_REQUEST,
  INDIVIDUAL_FRIEND_LIST_SUCCESS,
  INDIVIDUAL_FRIEND_LIST_FAILURE,
  SEARCH_FRIEND_ONCHAT_REQUEST,
  SEARCH_FRIEND_ONCHAT_SUCCESS,
  SEARCH_FRIEND_ONCHAT_FAILURE,
  SEARCH_MEMBER_REQUEST,
  SEARCH_MEMBER_SUCCESS,
  SEARCH_MEMBER_FAILURE,
} from "./MyNetworkConstant";

const initialState = {
  friendListDetail: {
    friendListPage: 1,
    friendList: [],
    searchFriendName: "",
    searchFriendPage: 0,
    groupList: [],
  },
  groupListDetail: {
    friendListPage: 1,
    friendList: [],
    searchFriendName: "",
    searchFriendPage: 0,
    groupList: [],
  },
  friendListApi: {
    friendListLoading: false,
    friendListStatus: "",
    friendListMessage: "",
    is_empty_friend: "",
    friendDetails: "",
    totalFriend: "",
  },
  searchFriendApi: {
    searchFriendLoading: false,
    searchFriendStatus: "",
    searchFriendMessage: "",
    is_empty_search: "",
  },
  businessFriendListDetail: {
    businessFriendListDetails: 1,
    businessFriendList: [],
    businessSearchFriendName: "",
    businessSearchFriendPage: 0,
    businessGroupList: [],
  },
  businessfriendListApi: {
    businessFriendListLoading: false,
    businessFriendListStatus: "",
    businessFriendListMessage: "",
    is_empty_friend: "",
    businessFriendDetails: "",
    businessTotalFriend: "",
  },

  individualFriendListDetail: {
    individualFriendListDetails: 1,
    individualFriendList: [],
    individualSearchFriendName: "",
    individualSearchFriendPage: 0,
    individualGroupList: [],
  },
  individualfriendListApi: {
    individualFriendListLoading: false,
    individualFriendListStatus: "",
    individualFriendListMessage: "",
    is_empty_friend: "",
    individualFriendDetails: "",
    individualTotalFriend: "",
  },

  searchFriendChatApi: {
    searchFriendChatLoading: false,
    searchFriendChatStatus: "",
    searchFriendChatMessage: "",
    is_empty_search: "",
  },
  searchFriendData: {
    searchFriendList: [],
    searchFriendName: "",
    searchFriendPage: 0,
  },

  searchMemberData: {
    searchMemberListLoading: false,
    searchMemberListLoadingStatus: "",
    searchMemberListMessage: "",
    searchMemberList: [],
  },
};
export const MyNetworkReducer = (state = initialState, action) => {

  switch (action.type) {
    case FRIEND_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          friendListApi: {
            ...state.friendListApi,
            ...{ friendListLoading: true },
          },
        },
      };
    case FRIEND_LIST_DATA_SUCCESS:
      const {
        response: {
          data: { message, status, user_details, user_groups, total },
        },
      } = action;




      let friendListSuccess = {
        friendListLoading: false,
        friendListStatus: status,
        friendListMessage: message,
        is_empty_friend:
          state.friendListDetail.friendList.length < 1 ? true : "",
        friendDetails: user_details,
        totalFriend: total,
      };
      return {
        ...state,
        ...{
          friendListApi: { ...state.friendListApi, ...friendListSuccess },
          ...{
            friendListDetail: {
              ...state.friendListDetail,
              ...{
                friendList: action?.response?.data?.list
                  ? [...state.friendListDetail.friendList, ...action?.response?.data?.list]
                  : [],
                groupList: !!user_groups
                  ? [...state.friendListDetail.groupList, ...user_groups]
                  : [],
              },
            },
          },
        },
      };

    case FRIEND_LIST_DATA_FAILURE:
      let friendListFailure = {
        friendListLoading: false,
        friendListStatus: action.response.status,
        friendListMessage: "",
        is_empty_friend: true,
        friendDetails: "",
      };
      return {
        ...state,
        ...{
          friendListApi: { ...state.friendListApi, ...friendListFailure },
          ...{
            friendListDetail: {
              ...state.friendListDetail,
              ...{ friendList: [] },
            },
          },
        },
      };

    case CLEAR_FRIEND_LIST_RESPONSE:
      let clearFriendResponse = {
        friendListStatus: "",
        friendListMessage: "",
        is_empty_friend: "",
        friendDetails: "",
      };
      let clearFriendDetail = {
        friendListPage: 1,
        friendList: [],
        groupList: [],
      };
      return {
        ...state,
        ...{
          friendListApi: { ...state.friendListApi, ...clearFriendResponse },
          friendListDetail: { ...state.friendListDetail, ...clearFriendDetail },
        },
      };
    case CHANGE_FRIEND_LIST_DETAIL:
      return {
        ...state,
        ...{
          friendListDetail: { ...state.friendListDetail, ...action.newState },
        },
      };
    case CHANGE_GROUP_LIST_DETAIL:
      return {
        ...state,
        ...{
          groupListDetail: { ...state.groupListDetail, ...action.newState },
        },
      };
    case SEARCH_FRIEND_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          searchFriendApi: {
            ...state.searchFriendApi,
            ...{ searchFriendLoading: true },
          },
        },
      };
    case SEARCH_FRIEND_LIST_DATA_SUCCESS:
      const {
        response: { data },
      } = action;
      let searchFriendSuccess = {
        searchFriendLoading: false,
        searchFriendStatus: data.status,
        searchFriendMessage: data.message,
        is_empty_search:
          state.friendListDetail.friendList.length < 1 ? true : "",
      };
      return {
        ...state,
        ...{
          searchFriendApi: { ...state.searchFriendApi, ...searchFriendSuccess },
          ...{
            friendListDetail: {
              ...state.friendListDetail,
              ...{
                friendList: !!data.list
                  ? [...state.friendListDetail.friendList, ...data.list]
                  : [],
              },
            },
          },
        },
      };
    case SEARCH_FRIEND_LIST_DATA_FAILURE:
      let searchFriendFailure = {
        searchFriendLoading: false,
        searchFriendStatus: action.response.status,
        searchFriendMessage: "",
        is_empty_search: true,
      };
      return {
        ...state,
        ...{
          searchFriendApi: { ...state.searchFriendApi, ...searchFriendFailure },
          ...{
            friendListDetail: {
              ...state.friendListDetail,
              ...{ friendList: [] },
            },
          },
        },
      };
    case CLEAR_SEARCH_FRIEND_RESPONSE:
      let clearSearchFriendResponse = {
        searchFriendLoading: false,
        searchFriendStatus: "",
        searchFriendMessage: "",
        is_empty_search: "",
      };
      let clearSearchFriendDetail = {
        searchFriendPage: 0,
        friendList: [],
        searchFriendName: "",
      };
      return {
        ...state,
        ...{
          searchFriendApi: {
            ...state.searchFriendApi,
            ...clearSearchFriendResponse,
          },
          friendListDetail: {
            ...state.friendListDetail,
            ...clearSearchFriendDetail,
          },
        },
      };

    case INDIVIDUAL_FRIEND_LIST_REQUEST:
      return {
        ...state,
        ...{
          individualfriendListApi: {
            ...state.individualfriendListApi,
            ...{ individualFriendListLoading: true },
          },
        },
      };
    case INDIVIDUAL_FRIEND_LIST_SUCCESS:
      const individualresponseData = action.response.data.list;
      const individualStatusData = action.response.data.status;
      const individualMsg = action.response.data.message;
      const individualTotalNetwork = action.response.data.total;
      const individualUser_detailsData = action.response.data.user_details;
      const individualUser_groups_data = action.response.data.user_groups;
      const individualBusiness_CurrentPage = action.response.data.current_page;
      let individualFriendListSuccess = {
        individualFriendListLoading: false,
        individualFriendListStatus: individualStatusData,
        individualFriendListMessage: individualMsg,
        individualFriendDetails: individualUser_detailsData,
        individualTotalFriend: individualTotalNetwork,
        individualFriendListDetails: individualBusiness_CurrentPage,
      };
      return {
        ...state,
        ...{
          individualfriendListApi: {
            ...state.individualfriendListApi,
            ...individualFriendListSuccess,
          },
          ...{
            individualFriendListDetail: {
              ...state.individualFriendListDetail,
              ...{
                individualFriendList: !!individualresponseData
                  ? [
                      ...state.individualFriendListDetail.individualFriendList,
                      ...individualresponseData,
                    ]
                  : [],
                individualGroupList: !!individualUser_groups_data
                  ? [
                      ...state.individualFriendListDetail.individualGroupList,
                      ...individualUser_groups_data,
                    ]
                  : [],
              },
            },
          },
        },
      };
    case INDIVIDUAL_FRIEND_LIST_FAILURE:
      let individualFriendListFailure = {
        individualFriendListLoading: false,
        individualFriendListStatus: action.response.status,
        individualFriendListMessage: "",

        individualFriendDetails: "",
      };
      return {
        ...state,
        ...{
          individualfriendListApi: {
            ...state.individualfriendListApi,
            ...individualFriendListFailure,
          },
          ...{
            individualFriendListDetail: {
              ...state.individualFriendListDetail,
              ...{ individualFriendList: [] },
            },
          },
        },
      };

    case BUSINESS_FRIEND_LIST_REQUEST:
      return {
        ...state,
        ...{
          businessfriendListApi: {
            ...state.businessfriendListApi,
            ...{ businessFriendListLoading: true },
          },
        },
      };
    case BUSINESS_FRIEND_LIST_SUCCESS:
      const responseData = action.response.data.list;
      const statusData = action.response.data.status;
      const msg = action.response.data.message;
      const totalNetwork = action.response.data.total;
      const user_detailsData = action.response.data.user_details;
      const user_groups_data = action.response.data.user_groups;
      const business_CurrentPage = action.response.data.current_page;
      let BusinessFriendListSuccess = {
        businessFriendListLoading: false,
        businessFriendListStatus: statusData,
        businessFriendListMessage: msg,
        businessFriendDetails: user_detailsData,
        businessTotalFriend: totalNetwork,
        businessFriendListDetails: business_CurrentPage,
      };
      return {
        ...state,
        ...{
          businessfriendListApi: {
            ...state.businessfriendListApi,
            ...BusinessFriendListSuccess,
          },
          ...{
            businessFriendListDetail: {
              ...state.businessFriendListDetail,
              ...{
                businessFriendList: !!responseData
                  ? [
                      ...state.businessFriendListDetail.businessFriendList,
                      ...responseData,
                    ]
                  : [],
                businessGroupList: !!user_groups_data
                  ? [
                      ...state.businessFriendListDetail.businessGroupList,
                      ...user_groups_data,
                    ]
                  : [],
              },
            },
          },
        },
      };

    case BUSINESS_FRIEND_LIST_FAILURE:
      let businessFriendListFailure = {
        businessFriendListLoading: false,
        businessFriendListStatus: action.response.status,
        businessFriendListMessage: "",

        businessFriendDetails: "",
      };
      return {
        ...state,
        ...{
          businessfriendListApi: {
            ...state.businessfriendListApi,
            ...businessFriendListFailure,
          },
          ...{
            businessFriendListDetail: {
              ...state.businessFriendListDetail,
              ...{ businessFriendList: [] },
            },
          },
        },
      };
    case SEARCH_FRIEND_ONCHAT_REQUEST:
      return {
        ...state,
        ...{
          searchFriendChatApi: {
            ...state.searchFriendChatApi,
            ...{ searchFriendChatLoading: true },
          },
        },
      };
    case SEARCH_FRIEND_ONCHAT_SUCCESS:
      let searchFriendChatSuccess = {
        searchFriendLoading: false,
        searchFriendStatus: action?.response?.data.status,
        searchFriendMessage: action?.response?.data.message,
        is_empty_search:
          state.searchFriendData.searchFriendList.length < 1 ? true : "",
      };
      return {
        ...state,
        ...{
          searchFriendApi: {
            ...state.searchFriendApi,
            ...searchFriendChatSuccess,
          },
          ...{
            searchFriendData: {
              ...state.searchFriendData,
              ...{
                searchFriendList:
                  !!action?.response.data && action?.response?.data.data
                    ? [...action?.response?.data.data]
                    : [],
              },
            },
          },
        },
      };

    case SEARCH_FRIEND_ONCHAT_FAILURE:
      let searchFriendChatFailure = {
        searchFriendLoading: false,
        searchFriendStatus: action.response.status,
        searchFriendMessage: "",
        is_empty_search: true,
      };
      return {
        ...state,
        ...{
          searchFriendApi: {
            ...state.searchFriendApi,
            ...searchFriendChatFailure,
          },
          ...{
            friendListDetail: {
              ...state.friendListDetail,
              ...{ friendList: [] },
            },
          },
        },
      };

    case CLEAR_SEARCH_FRIEND_RESPONSE:
      let clearSearchFriendChatResponse = {
        searchFriendLoading: false,
        searchFriendStatus: "",
        searchFriendMessage: "",
        is_empty_search: "",
      };
      let clearSearchFriendChatDetail = {
        searchFriendPage: 0,
        searchFriendList: [],
        searchFriendName: "",
      };
      return {
        ...state,
        ...{
          searchFriendApi: {
            ...state.searchFriendApi,
            ...clearSearchFriendChatResponse,
          },
          friendListDetail: {
            ...state.friendListDetail,
            ...clearSearchFriendChatDetail,
          },
        },
      };

    case SEARCH_MEMBER_REQUEST:
      return {
        ...state,
        ...{
          friendListApi: {
            ...state.friendListApi,
            ...{ friendListLoading: true },
          },
        },
      };

    case SEARCH_MEMBER_SUCCESS:

      let getSearchMemberSuccess = {
        searchMemberListLoading: false,
        searchMemberListLoadingStatus: 200,
        searchMemberListMessage: action.response.data.message,
        searchMemberList: action.response.data
          ? action.response.data.list
          : [],
      };

      return {
        ...state,
        ...{
          searchMemberData: {
            ...state.searchMemberData,
            ...getSearchMemberSuccess,
          },
        },
      };

    case SEARCH_MEMBER_FAILURE:
      let searchMemberFailure = {
        searchMemberListLoading: false,
        searchMemberListLoadingStatus: action?.status,
        searchMemberListMessage: action.response.data.message,
        searchMemberList: [],
      };

      return {
        ...state,
        ...{
          searchMemberData: {
            ...state.searchMemberData,
            searchMemberFailure,
          },
        },
      };

    default:
      return state;
  }
};
