const CHANGE_SIGNUP_INDIVIDUAL_INPUT = "CHANGE_SIGNUP_INDIVIDUAL_INPUT"

const CHECK_SIGNUP_INDIVIDUAL_INPUT = "CHECK_SIGNUP_INDIVIDUAL_INPUT"

const CHECK_ISFINAL_INDIVIDUAL_INPUT = "CHECK_SIGNUP_INDIVIDUAL_INPUT"

const CHANGE_SIGNUP_BUSSINESS_INPUT = "CHANGE_SIGNUP_BUSSINESS_INPUT"

const CLEAR_SIGNUP_PAGE = "CLEAR_SIGNUP_PAGE"

const SIGNUP_DATA_REQUEST="SIGNUP_DATA_REQUEST";
const SIGNUP_DATA_SUCCESS ="SIGNUP_DATA_SUCCESS";
const SIGNUP_DATA_FAILURE ="SIGNUP_DATA_FAILURE";

const CLEAR_SIGNUP_API_RESPONSE = "CLEAR_SIGNUP_API_RESPONSE"

export {CHANGE_SIGNUP_INDIVIDUAL_INPUT, CHANGE_SIGNUP_BUSSINESS_INPUT, CHECK_SIGNUP_INDIVIDUAL_INPUT ,CHECK_ISFINAL_INDIVIDUAL_INPUT , 
    CLEAR_SIGNUP_PAGE ,SIGNUP_DATA_REQUEST,SIGNUP_DATA_SUCCESS,SIGNUP_DATA_FAILURE ,CLEAR_SIGNUP_API_RESPONSE,
}
