import {
  GET_SELLER_STATUS_DATA_FAILURE,
  GET_SELLER_STATUS_DATA_REQUEST,
  GET_SELLER_STATUS_DATA_SUCCESS,
} from "../../library/common/components/ProfilePosts/ProfilePostConstant";
import { IMAGE_BASE_URL } from "../../library/urls";
import { setCookie } from "../../library/utilities/functions";
import {
  CHANGE_BASIC_INFO_INPUT,
  CHANGE_GENERAL_INFO_INPUT,
  CLEAR_PROFILE_RESPONSE,
  CLEAR_UPDATE_INFO_RESPONSE,
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  LANGUAGE_LIST_FAILURE,
  LANGUAGE_LIST_REQUEST,
  LANGUAGE_LIST_SUCCESS,
  SKILLS_LIST_FAILURE,
  SKILLS_LIST_REQUEST,
  SKILLS_LIST_SUCCESS,
  EXPERTISE_LIST_REQUEST,
  EXPERTISE_LIST_SUCCESS,
  EXPERTISE_LIST_FAILURE,
  UPDATE_ADDITIONAL_INFO_FAILURE,
  UPDATE_ADDITIONAL_INFO_REQUEST,
  UPDATE_ADDITIONAL_INFO_SUCCESS,
  ADD_NEW_PROJECT,
  CHANGE_MY_PROJECT_DETAIL_INFO,
  GET_PROJECTS_FAILURE,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  SAVE_MY_PROJECTS_FAILURE,
  SAVE_MY_PROJECTS_SUCCESS,
  SAVE_MY_PROJECTS_REQUEST,
  CLEAR_SAVE_MY_PROJECTS_RESPONSE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  CLEAR_DELETE_PROJECT_RESPONSE,
  CHANGE_EDIT_PROJECT,
  CLEAR_UPDATE_PROJECT_RESPONSE,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_REQUEST,
  ADD_NEW_EMPLOYMENT,
  CHANGE_MY_EMPLOYMENT_DETAIL_INFO,
  GET_EMPLOYMENTS_FAILURE,
  GET_EMPLOYMENTS_REQUEST,
  GET_EMPLOYMENTS_SUCCESS,
  SAVE_MY_EMPLOYMENTS_FAILURE,
  SAVE_MY_EMPLOYMENTS_SUCCESS,
  SAVE_MY_EMPLOYMENTS_REQUEST,
  CLEAR_SAVE_MY_EMPLOYMENTS_RESPONSE,
  DELETE_EMPLOYMENT_REQUEST,
  DELETE_EMPLOYMENT_SUCCESS,
  DELETE_EMPLOYMENT_FAILURE,
  CLEAR_DELETE_EMPLOYMENT_RESPONSE,
  CHANGE_EDIT_EMPLOYMENT,
  CLEAR_UPDATE_EMPLOYMENT_RESPONSE,
  UPDATE_EMPLOYMENT_FAILURE,
  UPDATE_EMPLOYMENT_SUCCESS,
  UPDATE_EMPLOYMENT_REQUEST,
  CHANGE_SOCIAL_ICON_INPUT,
  ADD_SOCIAL_ICON_REQUEST,
  ADD_SOCIAL_ICON_SUCCESS,
  ADD_SOCIAL_ICON_FAILURE,
  CLEAR_ADD_SOCIAL_RESPONSE,
  GET_SOCIAL_ICON_REQUEST,
  GET_SOCIAL_ICON_SUCCESS,
  GET_SOCIAL_ICON_FAILURE,
  SPECIALIZATION_LIST_REQUEST,
  SPECIALIZATION_LIST_SUCCESS,
  SPECIALIZATION_LIST_FAILURE,
  GET_ALL_BUSINESS_INDUSTRIES,
  INDUSTRIES_LIST_FAILURE,
  INDUSTRIES_LIST_SUCCESS,
  SELLER_SERVICE_LIST_REQUEST,
  SELLER_SERVICE_LIST_SUCCESS,
  SELLER_SERVICE_LIST_FAILURE,
  ALL_SELLER_DATA_REQUEST,
  ALL_SELLER_DATA_SUCCESS,
  ALL_SELLER_DATA_FAILURE,
  SELLER_ADD_DATA_REQUEST,
  SELLER_ADD_DATA_SUCCESS,
  SELLER_ADD_DATA_FAILURE,
  UPDATE_SELLER_SERVICE_REQUEST,
  UPDATE_SELLER_SERVICE_SUCCESS,
  DELETE_USER_SERVICE_REQUEST,
  DELETE_USER_SERVICE_SUCCESS,
  DELETE_USER_SERVICE_FAILURE,
  GET_MANAGE_ACCOUNT_SUCCESS,
  GET_MANAGE_ACCOUNT_REQUEST,
  GET_MANAGE_ACCOUNT_FAILURE,
  CREATE_MAIN_SERVICES_REQUEST,
  CREATE_MAIN_SERVICES_SUCCESS,
  CREATE_MAIN_SERVICES_FAILURE,
  CREATE_SECONDARY_SERVICES_REQUEST,
  CREATE_SECONDARY_SERVICES_SUCCESS,
  CREATE_SECONDARY_SERVICES_FAILURE,
} from "./AccountConstant";

const initialState = {
  GeneralInput: {
    intro: "",
    specialization: "",
    inputSpecialization: "",
    expertise: [],
    address: "",
    language: [],

    work: "",
    skills: [],

    individualSeller: [],
    email: "",
  },
  basicInfo: {
    profileImage: "",
    profileImgData: "",
    coverImage: "",
    coverImgData: "",
    businessImage: "",
    businessImgData: "",
    businessName: "",
    name: "",
    seller_status: "",
  },
  getProfileApi: {
    getPrifileLoading: false,
    getProfileStatus: "",
    getProfileSucess: "",
    getProfileResponse: "",
  },
  updateAdditionApi: {
    updateAdditionLoading: false,
    updateAdditionStatus: "",
    updateAdditionSucess: "",
    updateAdditionMessage: "",
  },
  addDataSignUpApi: {
    addDataSignUpLoading: false,
    addDataSignUpStatus: "",
    addDataSignUpSucess: "",
    addDataSignUpMessage: "",
  },
  languageList: [],
  languageListApi: {
    languageListLoading: false,
    languageListStatus: "",
    languageListSucess: "",
  },

  skillList: [],

  skillsListApi: {
    skillsListLoading: false,
    skillsListStatus: "",
    skillsListSucess: "",
  },

  expetiseList: [],
  expetiseListApi: {
    expetiseListLoading: false,
    expetiseListStatus: "",
    expetiseListSucess: "",
  },

  industryList: [],
  industryListApi: {
    industryListLoading: false,
    industryListStatus: "",
    industryListSucess: "",
  },
  servicesList: [],
  servicesListApi: {
    servicesListLoading: false,
    servicesListStatus: "",
    servicesListSucess: "",
    totalServices: "",
    lastPage: "",
    currentPage: "",
  },
  specializationList: [],
  specializationListApi: {
    specializationListLoading: false,
    specializationListStatus: "",
    specializationListSucess: "",
  },
  my_projects: [],

  getProjectsApi: {
    getProjectsLoading: false,
    getProjectsStatus: "",
    getProjectsSucess: "",
    getProjectsMessage: "",
    projects: [],
  },

  saveMyProjectsApi: {
    saveMyProjectsLoading: false,
    saveMyProjectsStatus: "",
    saveMyProjectsSucess: "",
    saveMyProjectsMessage: "",
  },
  deleteProjectApi: {
    deleteProjectLoading: false,
    deleteProjectStatus: "",
    deleteProjectSucess: "",
    deleteProjectMessage: "",
  },

  is_edit_project: false,

  is_edit_service: false,
  updateProjectApi: {
    updateProjectLoading: false,
    updateProjectStatus: "",
    updateProjectSucess: "",
    updateProjectMessage: "",
  },

  updateSellerServiceApi: {
    updateSellerServiceLoading: false,
    updateSellerServiceStatus: "",
    updateSellerServiceSuccess: "",
    updateSellerServiceMessage: "",
  },

  //   createMainSerices:{
  // createMainServicesLoading:false,
  // createMainSericesStatus:"",
  // createMainSericesSuccess:"",
  // createMainSericesMessage:""
  //   },

  createSecondaryServices: {
    createSecondaryServiceLoading: false,
    createSecondaryServicesStatus: "",
    createSeondaryServicesSuccess: "",
    createSecondaryServicesMessage: "",
  },

  my_employments: [],

  getEmploymentsApi: {
    getEmploymentsLoading: false,
    getEmploymentsStatus: "",
    getEmploymentsSucess: "",
    getEmploymentsMessage: "",
    employments: [],
  },
  saveMyEmploymentsApi: {
    saveMyEmploymentsLoading: false,
    saveMyEmploymentsStatus: "",
    saveMyEmploymentsSucess: "",
    saveMyEmploymentsMessage: "",
  },
  deleteEmploymentApi: {
    deleteEmploymentLoading: false,
    deleteEmploymentStatus: "",
    deleteEmploymentSucess: "",
    deleteEmploymentMessage: "",
  },

  is_edit_employment: false,

  updateEmploymentApi: {
    updateEmploymentLoading: false,
    updateEmploymentStatus: "",
    updateEmploymentSucess: "",
    updateEmploymentMessage: "",
  },
  socialIconInput: {
    youtube: "",
    twitter: "",
    linkedin: "",
    facebook: "",
  },
  addSocialIconApi: {
    addSocialLoading: false,
    addSocialStatus: "",
    addSocialSucess: "",
    addSocialMessage: "",
  },
  getSocialIcon: {
    SocialIconLoading: false,
    SocialIconStatus: "",
    SocialIconSucess: "",
    SocialIconResponse: "",
  },

  getAllSellerDataApi: {
    getAllSellerDataLoading: false,
    getAllSellerDataStatus: "",
    getAllSellerDataSucess: "",
    getAllSellerDataMessage: "",
    getAllSellerDatas: [],
  },
  getSellerStatusApi: {
    getSellerStatusLoading: false,
    getSellerStatus: "",
    getSellerStatusSucess: "",
    getSellerStatusMessage: "",
    getSellerStatusData: [],
  },
  deleteFilesApi: {
    deleteFilesLoading: "",
    deleteFileStatus: "",
    deleteFileMessage: "",
  },
  getMangeAccountApi: {
    getMangeAccountLoading: false,
    getMangeAccountStatus: "",
    getMangeAccountStatusSucess: "",
    getMangeAccountMessage: "",
    getMangeAccountData: [],
  },
};

export const AccountReducer = (state = initialState, action) => {


  switch (action.type) {
    case CHANGE_GENERAL_INFO_INPUT:
      return {
        ...state,
        ...{ GeneralInput: { ...state.GeneralInput, ...action.newState } },
      };
    /**
     *  profile section api response management
     */
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        ...{
          getProfileApi: {
            ...state.getProfileApi,
            ...{ getPrifileLoading: true },
          },
        },
      };

    case GET_PROFILE_SUCCESS:
      const {
        response: {
          data: { success, message, data, status },
        },
      } = action;
      

      let profileApiSucess = {
        getPrifileLoading: false,
        getProfileStatus: status,
        getProfileResponse: data,
      };

      let basicData = {
        profileImage: !!data?.profile_image ? data?.profile_image : "",
        profileImgData: !!data?.profile_image
          ? IMAGE_BASE_URL + data?.profile_image
          : "",
        coverImage: data?.cover_image,
        coverImgData: !!data?.cover_image
          ? IMAGE_BASE_URL + data?.cover_image
          : "",
        businessImage: data?.business_image,
        businessImgData: IMAGE_BASE_URL + data?.business_image,
        businessName: data?.business_name,
        name: data?.name,
        seller_status: data?.seller_status,
        members: data?.members,
        isIndividual: data?.is_individual,
        work: data?.work,
      };

      let generalInfo = {
        intro: data?.business_intro,
        specialization: data?.specialization,
        expertise: data?.expertise,
        address: data?.address,
        language: data?.languages,
        work: !!data?.work ? data?.work : "",
        skills: data?.skills,
        email: data?.email,
        user_id: data?.id,
      };

      let cookieData = {
        address: data?.address,
        availability: data?.availability,
        business_image: data?.business_image,
        business_intro: data?.business_intro,
        business_name: data?.business_name,
        country_code: data?.country_code,
        cover_image: data?.cover_image,
        current_status: data?.current_status,
        user_status: data?.user_status,
        custom_title: data?.custom_title,
        description: data?.description,
        email: data?.email,
        first_time: data?.first_time,
        from_date: data?.from_date,
        id: data?.id,
        is_individual: data?.is_individual,
        is_supporter: data?.is_supporter,
        latitude: data?.latitude,
        longitude: data?.longitude,
        name: data?.name,
        online_status: data?.online_status,
        online_status_time: data?.online_status_time,
        phone: data?.phone,
        phone_visibility: data?.phone_visibility,
        profile_image: data?.profile_image,
        profile_visibility: data?.profile_visibility,
        seller_status: data?.seller_status,
        specialization: data?.specialization,
        status_image: data?.status_image,
        to_date: data?.to_date,
        work: data?.work,
        is_seller: data?.is_seller,
        refresh_token : data?.refresh_token,
        user_slug:data?.user_slug
      };


      setCookie("profile_data", JSON.stringify(cookieData), 1);
      return {
        ...state,
        ...{
          getProfileApi: { ...state.getProfileApi, ...profileApiSucess },
          basicInfo: { ...state.basicInfo, ...basicData },
          GeneralInput: { ...state.GeneralInput, ...generalInfo },
        },
      };
    case GET_PROFILE_FAILURE:
      let profileApiFailure = {
        getPrifileLoading: false,
        getProfileStatus: action.status,
        getProfileResponse: "",
      };
      let FailBasicData = {
        profileImgData: "",
        coverImgData: "",
        businessImgData: "",
        businessName: "",
        name: "",
        specialization: "",
      };
      let FailgeneralInfo = {
        intro: "",
        specialization: "",
        expertise: "",
        address: "",
        language: "",
        work: "",
        skills: "",
      };
      return {
        ...state,
        ...{
          getProfileApi: { ...state.getProfileApi, ...profileApiFailure },
          basicInfo: { ...state.basicInfo, ...FailBasicData },
          GeneralInput: { ...state.GeneralInput, ...FailgeneralInfo },
        },
      };

    case ALL_SELLER_DATA_REQUEST:
      return {
        ...state,
        ...{
          getAllSellerDataApi: {
            ...state.getAllSellerDataApi,
            ...{ getAllSellerDataLoading: true },
          },
        },
      };

    case ALL_SELLER_DATA_SUCCESS:
      let getAllSellerDataSucess = {
        getAllSellerDataLoading: false,
        getAllSellerDataStatus: 200,
        getAllSellerDataSucess: action.response.data.success,
        getAllSellerDataMessage: action.response.data.message,
        getAllSellerDatas: action.response.data
          ? action.response.data.data
          : [],
      };
      return {
        ...state,
        ...{
          getAllSellerDataApi: {
            ...state.getAllSellerDataApi,
            ...getAllSellerDataSucess,
          },
        },
      };
    case ALL_SELLER_DATA_FAILURE:
      let getAllSellerDataFailure = {
        getAllSellerDataLoading: false,
        getAllSellerDataStatus: action.status,
        getAllSellerDataSucess: false,
        getAllSellerDataMessage: action.response.data.message,
        getAllSellerDatas: [],
      };
      return {
        ...state,
        ...{
          getAllSellerDataApi: {
            ...state.getAllSellerDataApi,
            ...getAllSellerDataFailure,
          },
        },
      };

    case GET_SELLER_STATUS_DATA_REQUEST:
      return {
        ...state,
        ...{
          getSellerStatusApi: {
            ...state.getSellerStatusApi,
            ...{ getSellerStatusLoading: true },
          },
        },
      };
    case GET_SELLER_STATUS_DATA_SUCCESS:
      let getSellerStatusSucesss = {
        getSellerStatusLoading: false,
        getSellerStatus: 200,
        getAllSellerDataSucess: action.response.data.success,
        getSellerStatusMessage: action.response.data.message,
        getSellerStatusData: action.response.data
          ? action.response.data.data
          : [],
      };
      return {
        ...state,
        ...{
          getSellerStatusApi: {
            ...state.getSellerStatusApi,
            ...getSellerStatusSucesss,
          },
        },
      };
    case GET_SELLER_STATUS_DATA_FAILURE:
      let getSellerStatusFailure = {
        getSellerStatusLoading: false,
        getSellerStatus: action.status,
        getAllSellerDataSucess: false,
        getSellerStatusMessage: action.response.data.message,
        getSellerStatusData: [],
      };
      return {
        ...state,
        ...{
          getSellerStatusApi: {
            ...state.getSellerStatusApi,
            ...getSellerStatusFailure,
          },
        },
      };

    case CLEAR_PROFILE_RESPONSE:
      let clearProfileApi = {
        getPrifileLoading: false,
        getProfileStatus: "",
        getProfileResponse: "",
      };
      let clearBasicData = {
        profileImage: "",
        profileImgData: "",
        coverImage: "",
        coverImgData: "",
        businessImage: "",
        businessImgData: "",
        businessName: "",
        name: "",
        seller_status: "",
        work: "",
      };
      let cleargeneralInfo = {
        intro: "",
        specialization: "",
        inputSpecialization: "",
        expertise: "",
        address: "",
        language: "",
        work: "",
        skills: "",
      };
      return {
        ...state,
        ...{
          getProfileApi: { ...state.getProfileApi, ...clearProfileApi },
          basicInfo: { ...state.basicInfo, ...clearBasicData },
          GeneralInput: { ...state.GeneralInput, ...cleargeneralInfo },
        },
      };
    case CHANGE_BASIC_INFO_INPUT:
      return {
        ...state,
        ...{ basicInfo: { ...state.basicInfo, ...action.newState } },
      };
    case UPDATE_ADDITIONAL_INFO_REQUEST:
      return {
        ...state,
        ...{
          updateAdditionApi: {
            ...state.updateAdditionApi,
            ...{ updateAdditionLoading: true },
          },
        },
      };
    case UPDATE_ADDITIONAL_INFO_SUCCESS:
      let updateSuccess = {
        updateAdditionLoading: false,
        updateAdditionStatus: action.response.data.status,
        updateAdditionMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          updateAdditionApi: { ...state.updateAdditionApi, ...updateSuccess },
        },
      };
    case UPDATE_ADDITIONAL_INFO_FAILURE:
      let updateFailure = {
        updateAdditionLoading: false,
        updateAdditionStatus: action.response.data.status,
        updateAdditionMessage: action.response.data.message,
        updateAdditionSucess: false,
      };
      return {
        ...state,
        ...{
          updateAdditionApi: { ...state.updateAdditionApi, ...updateFailure },
        },
      };

    case SELLER_ADD_DATA_REQUEST:
      return {
        ...state,
        ...{
          addDataSignUpApi: {
            ...state.addDataSignUpApi,
            ...{ addDataSignUpLoading: true },
          },
        },
      };
    case SELLER_ADD_DATA_SUCCESS:
      let addDataSuccess = {
        addDataSignUpLoading: false,
        addDataSignUpStatus: action.response.data.status,
        addDataSignUpMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          addDataSignUpApi: { ...state.addDataSignUpApi, ...addDataSuccess },
        },
      };
    case SELLER_ADD_DATA_FAILURE:
      let addDataFailure = {
        addDataSignUpLoading: false,
        addDataSignUpStatus: action.response.data.status,
        addDataSignUpMessage: action.response.data.message,
        addDataSignUpSucess: false,
      };
      return {
        ...state,
        ...{
          addDataSignUpApi: { ...state.addDataSignUpApi, ...addDataFailure },
        },
      };

    case CLEAR_UPDATE_INFO_RESPONSE:
      let clearResponse = {
        updateAdditionStatus: "",
        updateAdditionMessage: "",
      };
      return {
        ...state,
        ...{
          updateAdditionApi: { ...state.updateAdditionApi, ...clearResponse },
        },
      };
    case LANGUAGE_LIST_REQUEST:
      return {
        ...state,
        ...{
          languageListApi: {
            ...state.languageListApi,
            ...{ languageListLoading: true },
          },
        },
      };
    case LANGUAGE_LIST_SUCCESS:
      let languageSuccess = {
        languageListLoading: false,
        languageListStatus: 200,
        languageListSucess: action.response.data.success,
      };
      return {
        ...state,
        ...{
          languageListApi: { ...state.languageListApi, ...languageSuccess },
          languageList: !!action.response.data.data
            ? action.response.data.data
            : [],
        },
      };
    case LANGUAGE_LIST_FAILURE:
      let languageFailure = {
        languageListLoading: false,
        languageListStatus: action.status,
        languageListSucess: false,
      };
      return {
        ...state,
        ...{
          languageListApi: { ...state.languageListApi, ...languageFailure },
          languageList: [],
        },
      };
    case SKILLS_LIST_REQUEST:
      return {
        ...state,
        ...{
          skillsListApi: {
            ...state.skillsListApi,
            ...{ skillsListLoading: true },
          },
        },
      };
    case SKILLS_LIST_SUCCESS:
      let skillsSuccess = {
        skillsListLoading: false,
        skillsListStatus: 200,
        skillsListSucess: action.response.data.success,
      };
      return {
        ...state,
        ...{
          skillsListApi: { ...state.skillsListApi, ...skillsSuccess },
          skillList: !!action.response.data.data
            ? action.response.data.data
            : [],
        },
      };
    case SKILLS_LIST_FAILURE:
      let skillsFailure = {
        skillsListLoading: false,
        skillsListStatus: action.status,
        skillsListSucess: false,
      };
      return {
        ...state,
        ...{
          skillsListApi: { ...state.skillsListApi, ...skillsFailure },
          skillList: [],
        },
      };
    case EXPERTISE_LIST_REQUEST:
      return {
        ...state,
        ...{
          expetiseListApi: {
            ...state.expetiseListApi,
            ...{ expetiseListLoading: true },
          },
        },
      };
    case EXPERTISE_LIST_SUCCESS:
      let expertiseSuccess = {
        expetiseListLoading: false,
        expetiseListStatus: 200,
        expetiseListSucess: action.response.data.success,
      };
      return {
        ...state,
        ...{
          expetiseListApi: {
            ...state.skillsexpetiseListApiListApi,
            ...expertiseSuccess,
          },
          expetiseList: !!action.response.data.data
            ? action.response.data.data
            : [],
        },
      };
    case EXPERTISE_LIST_FAILURE:
      let expertiseFailure = {
        expetiseListLoading: false,
        expetiseListStatus: action.status,
        expetiseListSucess: false,
      };
      return {
        ...state,
        ...{
          expetiseListApi: { ...state.expetiseListApi, ...expertiseFailure },
          expetiseList: [],
        },
      };

    case GET_ALL_BUSINESS_INDUSTRIES:
      return {
        ...state,
        ...{
          industryListApi: {
            ...state.industryListApi,
            ...{ industryListLoading: true },
          },
        },
      };
    case INDUSTRIES_LIST_SUCCESS:
      let industrySuccess = {
        industryListLoading: false,
        industryListStatus: 200,
        industryListSucess: action.response.data.success,
      };
      return {
        ...state,
        ...{
          industryListApi: { ...state.industryListApi, ...industrySuccess },
          industryList: !!action.response.data.list
            ? action.response.data.list
            : [],
        },
      };

    case INDUSTRIES_LIST_FAILURE:
      let industryFailure = {
        skillsListLoading: false,
        skillsListStatus: action.status,
        skillsListSucess: false,
      };
      return {
        ...state,
        ...{
          industryListApi: { ...state.industryListApi, ...industryFailure },
          industryList: [],
        },
      };

    case SELLER_SERVICE_LIST_REQUEST:
      return {
        ...state,
        ...{
          servicesListApi: {
            ...state.servicesListApi,
            ...{ servicesListLoading: true },
          },
        },
      };

    case SELLER_SERVICE_LIST_SUCCESS:
      const {
        response: {
          data: { current_page, last_page, list, total },
        },
      } = action;
      let serviceSuccess = {
        servicesListLoading: false,
        servicesListStatus: 200,
        servicesListSucess: action.response.data.success,
        totalServices: total,
        currentPage: current_page,
        lastPage: last_page,
      };

      return {
        ...state,
        ...{
          servicesListApi: { ...state.servicesListApi, ...serviceSuccess },
          servicesList: !!action.response.data.list
            ? [...state.servicesList, ...list]
            : [],
        },
      };
    case SELLER_SERVICE_LIST_FAILURE:
      let serviceFailure = {
        servicesListLoading: false,
        servicesListStatus: action.status,
        servicesListSucess: false,
      };
      return {
        ...state,
        ...{
          servicesListApi: { ...state.servicesListApi, ...serviceFailure },
          industryList: [],
        },
      };

    case SPECIALIZATION_LIST_REQUEST:
      return {
        ...state,
        ...{
          specializationListApi: {
            ...state.specializationListApi,
            ...{ specializationListLoading: true },
          },
        },
      };
    case SPECIALIZATION_LIST_SUCCESS:
      let specializationSuccess = {
        specializationListLoading: false,
        specializationListStatus: 200,
        specializationListSucess: action.response.data.success,
      };
      return {
        ...state,
        ...{
          specializationListApi: {
            ...state.specializationListApi,
            ...specializationSuccess,
          },
          specializationList: !!action.response.data.data
            ? action.response.data.data
            : [],
        },
      };
    case SPECIALIZATION_LIST_FAILURE:
      let specializationFailure = {
        specializationListLoading: false,
        specializationListStatus: action.status,
        specializationListSucess: false,
      };
      return {
        ...state,
        ...{
          specializationListApi: {
            ...state.specializationListApi,
            ...specializationFailure,
          },
          specializationList: [],
        },
      };

    /**
     *  Business projects api response management
     */

    case GET_PROJECTS_REQUEST:
      return {
        ...state,
        ...{
          getProjectsApi: {
            ...state.getProjectsApi,
            ...{ getProjectsLoading: true },
          },
        },
      };
    case GET_PROJECTS_SUCCESS:
      let projectsApiSucess = {
        getProjectsLoading: false,
        getProjectsStatus: 200,
        getProjectsSucess: action.response.data.success,
        getProjectsMessage: action.response.data.message,
        projects: action.response.data.success ? action.response.data.data : [],
      };
      return {
        ...state,
        ...{
          getProjectsApi: { ...state.getProjectsApi, ...projectsApiSucess },
        },
      };
    case GET_PROJECTS_FAILURE:
      let projectsApiFailure = {
        getProjectsLoading: false,
        getProjectsStatus: action.status,
        getProjectsSucess: false,
        getProjectsMessage: action.response.data.message,
        projects: [],
      };
      return {
        ...state,
        ...{
          getProjectsApi: { ...state.getProjectsApi, ...projectsApiFailure },
        },
      };

    case ADD_NEW_PROJECT:

      const my_projects = [
        {
          current: false,
          title: "",
          from: "",
          to: "",
          project_icon: "",
          project_link: "",
          type_of_job: "",
          location: "",
          description: "",
          project_images: [],
        },
      ];
      
      return {
        ...state,
        ...{
          my_projects,
        },
      };


    case CHANGE_MY_PROJECT_DETAIL_INFO:

      return {
        ...state,
        ...{
          my_projects: action?.newState,
        },
      };
    /**
     *  save my projects api integration....
     */
    case SAVE_MY_PROJECTS_REQUEST:
      return {
        ...state,
        ...{
          saveMyProjectsApi: {
            ...state.saveMyProjectsApi,
            ...{ saveMyProjectsLoading: true },
          },
        },
      };
    case SAVE_MY_PROJECTS_SUCCESS:
      let saveMyProjectsSuccess = {
        saveMyProjectsLoading: false,
        saveMyProjectsStatus: action.response.data.status,
        saveMyProjectsMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          saveMyProjectsApi: {
            ...state.saveMyProjectsApi,
            ...saveMyProjectsSuccess,
          },
        },
      };
    case SAVE_MY_PROJECTS_FAILURE:
      let saveMyProjectsFailure = {
        saveMyProjectsLoading: false,
        saveMyProjectsStatus: action.status,
        saveMyProjectsMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          saveMyProjectsApi: {
            ...state.saveMyProjectsApi,
            ...saveMyProjectsFailure,
          },
        },
      };
    case CLEAR_SAVE_MY_PROJECTS_RESPONSE:
      let saveMyProjectsResp = {
        saveMyProjectsStatus: "",
        saveMyProjectsMessage: "",
        saveMyProjectsSucess: "",
      };
      return {
        ...state,
        ...{
          saveMyProjectsApi: {
            ...state.saveMyProjectsApi,
            ...saveMyProjectsResp,
          },
        },
      };

    /**
     *  delete project api integration....
     */
    case DELETE_PROJECT_REQUEST:
      return {
        ...state,
        ...{
          deleteProjectApi: {
            ...state.deleteProjectApi,
            ...{ deleteProjectLoading: true },
          },
        },
      };
    case DELETE_PROJECT_SUCCESS:
      let deleteProjectSuccess = {
        deleteProjectLoading: false,
        deleteProjectStatus: action.response.data.status,
        deleteProjectMessage: action.response.data.message,
        deleteProjectSucess: action.response.data.success,
      };

      return {
        ...state,
        ...{
          deleteProjectApi: {
            ...state.deleteProjectApi,
            ...deleteProjectSuccess,
          },
        },
      };
    case DELETE_PROJECT_FAILURE:
      let deleteProjectFailure = {
        deleteProjectLoading: false,
        deleteProjectStatus: action.status,
        deleteProjectMessage: action.response.data.message,
        deleteProjectSucess: false,
      };
      return {
        ...state,
        ...{
          deleteProjectApi: {
            ...state.deleteProjectApi,
            ...deleteProjectFailure,
          },
        },
      };
    case CLEAR_DELETE_PROJECT_RESPONSE:
      let deleteProjectResp = {
        deleteProjectStatus: "",
        deleteProjectMessage: "",
        deleteProjectSucess: "",
      };
      return {
        ...state,
        ...{
          deleteProjectApi: { ...state.deleteProjectApi, ...deleteProjectResp },
        },
      };
    case CHANGE_EDIT_PROJECT:
      return {
        ...state,
        ...{ is_edit_project: action.status },
      };

    /**
     *  update project api integration....
     */
    case UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        ...{
          updateProjectApi: {
            ...state.updateProjectApi,
            ...{ updateProjectLoading: true },
          },
        },
      };
    case UPDATE_PROJECT_SUCCESS:
      let updateProjectSuccess = {
        updateProjectLoading: false,
        updateProjectStatus: action.response.data.status,
        updateProjectMessage: action.response.data.message,
        updateProjectSucess: action.response.data.success,
      };

      return {
        ...state,
        ...{
          updateProjectApi: {
            ...state.updateProjectApi,
            ...updateProjectSuccess,
          },
        },
      };
    case UPDATE_PROJECT_FAILURE:
      let updateProjectFailure = {
        updateProjectLoading: false,
        updateProjectStatus: action.status,
        updateProjectMessage: action.response.data.message,
        updateProjectSucess: false,
      };
      return {
        ...state,
        ...{
          updateProjectApi: {
            ...state.updateProjectApi,
            ...updateProjectFailure,
          },
        },
      };
    case CLEAR_UPDATE_PROJECT_RESPONSE:
      let updateProjectResp = {
        updateProjectStatus: "",
        updateProjectMessage: "",
        updateProjectSucess: "",
      };
      return {
        ...state,
        ...{
          updateProjectApi: { ...state.updateProjectApi, ...updateProjectResp },
        },
      };

    // CASES OF UPDATE SELLER SERVICES
    // REQUEST CASE
    case UPDATE_SELLER_SERVICE_REQUEST:
      return {
        ...state,
        ...{
          updateSellerServiceApi: {
            ...state.updateSellerServiceApi,
            ...{ updateSellerServiceLoading: true },
          },
        },
      };

    // SUCCESS CASE
    case UPDATE_SELLER_SERVICE_SUCCESS:
      let updateSellerServiceApiSuccess = {
        updateSellerServiceLoading: false,
        updateSellerServiceStatus: action.response.data.status,
        updateSellerServiceMessage: action.response.data.message,
        updateSellerServiceSuccess: action.response.data.success,
      };

      return {
        ...state,
        ...{
          updateSellerServiceApi: {
            ...state.updateSellerServiceApi,
            ...updateSellerServiceApiSuccess,
          },
        },
      };

    //FAILURE CASE
    case UPDATE_PROJECT_FAILURE:
      let updateSellerServiceApiFailure = {
        updateSellerServiceLoading: false,
        updateSellerServiceStatus: action.status,
        updateSellerServiceMessage: action.response.data.message,
        updateSellerServiceSuccess: false,
      };
      return {
        ...state,
        ...{
          updateSellerServiceApi: {
            ...state.updateSellerServiceApi,
            ...updateSellerServiceApiFailure,
          },
        },
      };

    //--create service request case--//
    case CREATE_MAIN_SERVICES_REQUEST:
      return {
        ...state,
        ...{
          createMainServices: {
            ...state.createMainServices,
            ...{ createMainServicesLoading: true },
          },
        },
      };

    // create-main service request//

    case CREATE_MAIN_SERVICES_SUCCESS:
      let createdMainServiceSucces = {
        createMainServiceLoading: false,
        createMainServiceStatus: action.response.data.status,
        createMainServiceMessage: action.response.data.message,
        createMainServiceSuccess: action.response.data.success,
      };
      return {
        ...state,
        ...{
          createMainServices: {
            ...state.createMainServices,
            ...createdMainServiceSucces,
          },
        },
      };

    case CREATE_MAIN_SERVICES_FAILURE:
      let createdMainServiceFailure = {
        createMainServiceLoading: false,
        createMainServiceStatus: action.response.data.status,
        createMainServiceMessage: action.response.data.message,
        createMainServiceSuccess: false,
      };
      return {
        ...state,
        ...{
          createMainServices: {
            ...state.createMainServices,
            ...createdMainServiceFailure,
          },
        },
      };

    // case when new secondary service create//
    case CREATE_SECONDARY_SERVICES_REQUEST:
      return {
        ...state,
        ...{
          createSecondaryServices: {
            ...state.createSecondaryServices,
            ...{ createSecondaryServiceLoading: true },
          },
        },
      };

    case CREATE_SECONDARY_SERVICES_SUCCESS:
      let createSecondaryServiceSuccess = {
        createSecondaryServiceLoading: false,
        createSecondaryServicesStatus: action.response.data.status,
        createSecondaryServicesMessage: action.response.data.message,
        createSecondaryServiceSuccess: action.response.data.success,
      };
      return {
        ...state,
        ...{
          createSecondaryServices: {
            ...state.createSecondaryServices,
            ...createSecondaryServiceSuccess,
          },
        },
      };


      case CREATE_SECONDARY_SERVICES_FAILURE:
        let createSecondaryServiceFailure = {
          createSecondaryServiceLoading: false,
          createSecondaryServicesStatus: action.response.data.status,
          createSecondaryServicesMessage: action.response.data.message,
          createSecondaryServiceSuccess: false,
        };
        return {
          ...state,
          ...{
            createSecondaryServices: {
              ...state.createSecondaryServices,
              ...createSecondaryServiceFailure,
            },
          },
        };
    /**
     * employment
     */

    /**
     *  Business employments api response management
     */

    case GET_EMPLOYMENTS_REQUEST:
      return {
        ...state,
        ...{
          getEmploymentsApi: {
            ...state.getEmploymentsApi,
            ...{ getEmploymentsLoading: true },
          },
        },
      };
    case GET_EMPLOYMENTS_SUCCESS:
      let employmentsApiSucess = {
        getEmploymentsLoading: false,
        getEmploymentsStatus: action.response.data.status,
        getEmploymentsSucess: action.response.data.success,
        getEmploymentsMessage: action.response.data.message,
        employments: action.response.data.success
          ? action.response.data.data
          : [],
      };
      return {
        ...state,
        ...{
          getEmploymentsApi: {
            ...state.getEmploymentsApi,
            ...employmentsApiSucess,
          },
        },
      };
    case GET_EMPLOYMENTS_FAILURE:
      let employmentsApiFailure = {
        getEmploymentsLoading: false,
        getEmploymentsStatus: action.status,
        getEmploymentsSucess: false,
        getEmploymentsMessage: action.response.data.message,
        employments: [],
      };
      return {
        ...state,
        ...{
          getEmploymentsApi: {
            ...state.getEmploymentsApi,
            ...employmentsApiFailure,
          },
        },
      };
    case ADD_NEW_EMPLOYMENT:
      const my_employments = [
        {
          current: false,
          title: "",
          from: "",
          to: "",
          type_of_job: "",
          location: "",
          description: "",
        },
      ];
      return {
        ...state,
        ...{
          my_employments,
        },
      };
    case CHANGE_MY_EMPLOYMENT_DETAIL_INFO:
      return {
        ...state,
        ...{
          my_employments: action.newState,
        },
      };

    /**
     *  save my employments api integration....
     */
    case SAVE_MY_EMPLOYMENTS_REQUEST:
      return {
        ...state,
        ...{
          saveMyEmploymentsApi: {
            ...state.saveMyEmploymentsApi,
            ...{ saveMyEmploymentsLoading: true },
          },
        },
      };
    case SAVE_MY_EMPLOYMENTS_SUCCESS:
      let saveMyEmploymentsSuccess = {
        saveMyEmploymentsLoading: false,
        saveMyEmploymentsStatus: action.response.data.status,
        saveMyEmploymentsMessage: action.response.data.message,
        saveMyEmploymentsSucess: action.response.data.success,
      };

      return {
        ...state,
        ...{
          saveMyEmploymentsApi: {
            ...state.saveMyEmploymentsApi,
            ...saveMyEmploymentsSuccess,
          },
        },
      };
    case SAVE_MY_EMPLOYMENTS_FAILURE:
      let saveMyEmploymentsFailure = {
        saveMyEmploymentsLoading: false,
        saveMyEmploymentsStatus: action.status,
        saveMyEmploymentsMessage: action.response.data.message,
        saveMyEmploymentsSucess: false,
      };
      return {
        ...state,
        ...{
          saveMyEmploymentsApi: {
            ...state.saveMyEmploymentsApi,
            ...saveMyEmploymentsFailure,
          },
        },
      };
    case CLEAR_SAVE_MY_EMPLOYMENTS_RESPONSE:
      let saveMyEmploymentsResp = {
        saveMyEmploymentsStatus: "",
        saveMyEmploymentsMessage: "",
        saveMyEmploymentsSucess: "",
      };
      return {
        ...state,
        ...{
          saveMyEmploymentsApi: {
            ...state.saveMyEmploymentsApi,
            ...saveMyEmploymentsResp,
          },
        },
      };

    /**
     *  delete employment api integration....
     */
    case DELETE_EMPLOYMENT_REQUEST:
      return {
        ...state,
        ...{
          deleteEmploymentApi: {
            ...state.deleteEmploymentApi,
            ...{ deleteEmploymentLoading: true },
          },
        },
      };
    case DELETE_EMPLOYMENT_SUCCESS:
      let deleteEmploymentSuccess = {
        deleteEmploymentLoading: false,
        deleteEmploymentStatus: action.response.data.status,
        deleteEmploymentMessage: action.response.data.message,
        deleteEmploymentSucess: action.response.data.success,
      };

      return {
        ...state,
        ...{
          deleteEmploymentApi: {
            ...state.deleteEmploymentApi,
            ...deleteEmploymentSuccess,
          },
        },
      };
    case DELETE_EMPLOYMENT_FAILURE:
      let deleteEmploymentFailure = {
        deleteEmploymentLoading: false,
        deleteEmploymentStatus: action.status,
        deleteEmploymentMessage: action.response.data.message,
        deleteEmploymentSucess: false,
      };
      return {
        ...state,
        ...{
          deleteEmploymentApi: {
            ...state.deleteEmploymentApi,
            ...deleteEmploymentFailure,
          },
        },
      };
    case CLEAR_DELETE_EMPLOYMENT_RESPONSE:
      let deleteEmploymentResp = {
        deleteEmploymentStatus: "",
        deleteEmploymentMessage: "",
        deleteEmploymentSucess: "",
      };
      return {
        ...state,
        ...{
          deleteEmploymentApi: {
            ...state.deleteEmploymentApi,
            ...deleteEmploymentResp,
          },
        },
      };
    case CHANGE_EDIT_EMPLOYMENT:
      return {
        ...state,
        ...{ is_edit_employment: action.status },
      };

    /**
     *  update employment api integration....
     */
    case UPDATE_EMPLOYMENT_REQUEST:
      return {
        ...state,
        ...{
          updateEmploymentApi: {
            ...state.updateEmploymentApi,
            ...{ updateEmploymentLoading: true },
          },
        },
      };
    case UPDATE_EMPLOYMENT_SUCCESS:
      let updateEmploymentSuccess = {
        updateEmploymentLoading: false,
        updateEmploymentStatus: action.response.data.status,
        updateEmploymentMessage: action.response.data.message,
        updateEmploymentSucess: action.response.data.success,
      };

      return {
        ...state,
        ...{
          updateEmploymentApi: {
            ...state.updateEmploymentApi,
            ...updateEmploymentSuccess,
          },
        },
      };
    case UPDATE_EMPLOYMENT_FAILURE:
      let updateEmploymentFailure = {
        updateEmploymentLoading: false,
        updateEmploymentStatus: action.status,
        updateEmploymentMessage: action.response.data.message,
        updateEmploymentSucess: false,
      };
      return {
        ...state,
        ...{
          updateEmploymentApi: {
            ...state.updateEmploymentApi,
            ...updateEmploymentFailure,
          },
        },
      };
    case CLEAR_UPDATE_EMPLOYMENT_RESPONSE:
      let updateEmploymentResp = {
        updateEmploymentStatus: "",
        updateEmploymentMessage: "",
        updateEmploymentSucess: "",
      };
      return {
        ...state,
        ...{
          updateEmploymentApi: {
            ...state.updateEmploymentApi,
            ...updateEmploymentResp,
          },
        },
      };
    case CHANGE_SOCIAL_ICON_INPUT:
      return {
        ...state,
        ...{
          socialIconInput: { ...state.socialIconInput, ...action.newState },
        },
      };
    case ADD_SOCIAL_ICON_REQUEST:
      return {
        ...state,
        ...{
          addSocialIconApi: {
            ...state.addSocialIconApi,
            ...{ addSocialLoading: true },
          },
        },
      };
    case ADD_SOCIAL_ICON_SUCCESS:
      let addIconSuccess = {
        addSocialLoading: false,
        addSocialStatus: action.response.status,
        addSocialMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          addSocialIconApi: { ...state.addSocialIconApi, ...addIconSuccess },
        },
      };
    case ADD_SOCIAL_ICON_FAILURE:
      let addIconFailure = {
        addSocialLoading: false,
        addSocialStatus: action.status,
        addSocialMessage: "",
      };
      return {
        ...state,
        ...{
          addSocialIconApi: { ...state.addSocialIconApi, ...addIconFailure },
        },
      };
    case CLEAR_ADD_SOCIAL_RESPONSE:
      let clearIconResponse = {
        addSocialStatus: "",
        addSocialSucess: "",
        addSocialMessage: "",
      };
      return {
        ...state,
        ...{
          addSocialIconApi: { ...state.addSocialIconApi, ...clearIconResponse },
        },
      };
    case DELETE_USER_SERVICE_REQUEST:
      return {
        ...state,
        ...{
          deleteFilesApi: {
            ...state.deleteFilesApi,
            ...{ deleteFilesLoading: true },
          },
        },
      };
    case DELETE_USER_SERVICE_SUCCESS:
      let deleteSucess = {
        deleteFilesLoading: false,
        deleteFileStatus: action.response.data.status,
        deleteFileMessage: action.response.data.message,
      };
      return {
        ...state,
        ...{
          deleteFilesApi: { ...state.deleteFilesApi, ...deleteSucess },
        },
      };
    case DELETE_USER_SERVICE_FAILURE:
      let deleteServiceFailure = {
        deleteFilesLoading: false,
        deleteFileStatus: action.status,
        deleteFileMessage: action.response.data.message,
      };

      return {
        ...state,
        ...{
          deleteFilesApi: { ...state.deleteFilesApi, ...deleteServiceFailure },
        },
      };
    case GET_SOCIAL_ICON_REQUEST:
      return {
        ...state,
        ...{
          getSocialIcon: {
            ...state.getSocialIcon,
            ...{ SocialIconLoading: true },
          },
        },
      };
    case GET_SOCIAL_ICON_SUCCESS:
      let getIconSuccess = {
        SocialIconLoading: false,
        SocialIconStatus: action.response.data.status,
        SocialIconSucess: action.response.data.success,
        SocialIconResponse: action.response.data.data,
      };
      let socialIcon = {
        youtube: action.response.data.data.youtube,
        twitter: action.response.data.data.twitter,
        linkedin: action.response.data.data.linkedIn,
        facebook: action.response.data.data.facebook,
      };
      return {
        ...state,
        ...{
          getSocialIcon: { ...state.getSocialIcon, ...getIconSuccess },
          socialIconInput: { ...state.socialIconInput, ...socialIcon },
        },
      };
    case GET_SOCIAL_ICON_FAILURE:
      let getIconFailure = {
        SocialIconLoading: false,
        SocialIconStatus: action.status,
        SocialIconSucess: "",
        SocialIconResponse: "",
      };
      return {
        ...state,
        ...{
          getSocialIcon: { ...state.getSocialIcon, ...getIconFailure },
        },
      };
    case GET_MANAGE_ACCOUNT_REQUEST:
      return {
        ...state,
        ...{
          getMangeAccountApi: {
            ...state.getMangeAccountApi,
            ...{ getMangeAccountLoading: true },
          },
        },
      };
    case GET_MANAGE_ACCOUNT_SUCCESS:
      let getMangeAccountSucesss = {
        getMangeAccountLoading: false,
        getMangeAccountStatus: 200,
        getMangeAccountStatusSucess: action.response.data.success,
        getMangeAccountMessage: action.response.data.message,
        getMangeAccountData: action.response.data
          ? action.response.data.data
          : [],
      };
      return {
        ...state,
        ...{
          getMangeAccountApi: {
            ...state.getMangeAccountApi,
            ...getMangeAccountSucesss,
          },
        },
      };

    case GET_MANAGE_ACCOUNT_FAILURE:
      let getMangeAccountFailure = {
        getMangeAccountLoading: false,
        getMangeAccountStatus: action.status,
        getMangeAccountStatusSucess: false,
        getMangeAccountMessage: action.response.data.message,
        getMangeAccountData: [],
      };
      return {
        ...state,
        ...{
          getMangeAccountApi: {
            ...state.getMangeAccountApi,
            ...getMangeAccountFailure,
          },
        },
      };
    default:
      return state;
  }
};
