import { CHANGE_GALLERY_FILE_DETAIL, CHANGE_GALLERY_SHARE_INPUT, CLEAR_DELETE_GALLERY_ITEM_RESPONSE, CLEAR_GET_GALLERY_PHOTO_RESPONSE, CLEAR_GET_GALLERY_VIDEO_RESPONSE, CLEAR_SHARE_GALLERY_RESPONSE, DELETE_GALLERY_DATA_FAILURE, DELETE_GALLERY_DATA_REQUEST, DELETE_GALLERY_DATA_SUCCESS, GET_GALLERY_PHOTO_DATA_FAILURE, GET_GALLERY_PHOTO_DATA_REQUEST, GET_GALLERY_PHOTO_DATA_SUCCESS, GET_GALLERY_VIDEO_DATA_FAILURE, GET_GALLERY_VIDEO_DATA_REQUEST, GET_GALLERY_VIDEO_DATA_SUCCESS, SHARE_GALLERY_DATA_FAILURE, SHARE_GALLERY_DATA_REQUEST, SHARE_GALLERY_DATA_SUCCESS } from "./ProfileGalleryConstant"

const initialState = {
    galleryShareInput: {
        galleryFile: [],
        galleryUrl: [],
        isFeedShare: 1
    },
    shareGalleryApi: {
        shareGalleryLoading: "",
        shareGalleryStatus: "",
        shareGalleryMessage: "",
        shareGalleryResponse: ""
    },
    galleryDetails: {
        photoList: [],
        videoList: [],
        photoPage: 0,
        videoPage: 0,
        totalVideo: "",
        totalPhoto: "",
        activeIndexFile: "",
        carousalDataFile: [],
        deleteSelectedItem: [],
        unSelectedState: ""
    },
    getGalleryPhotoApi: {
        getPhotoLoading: "",
        getPhotoStatus: "",
        getPhotoMessage: ""
    },
    getGalleryVideoApi: {
        getVideoLoading: "",
        getVideoStatus: "",
        getVideoMessage: ""
    },
    deleteFilesApi: {
        deleteFilesLoading: "",
        deleteFileStatus: "",
        deleteFileMessage: ""
    }

}
export const ProfileGalleryReducer = (state = initialState, action) => {

    switch (action.type) {
        case CHANGE_GALLERY_SHARE_INPUT:
            return {
                ...state,
                ...{ galleryShareInput: { ...state.galleryShareInput, ...action.newState } }
            }
        case SHARE_GALLERY_DATA_REQUEST:
            return {
                ...state,
                ...{ shareGalleryApi: { ...state.shareGalleryApi, ...{ shareGalleryLoading: true } } }
            }
        case SHARE_GALLERY_DATA_SUCCESS:
            const { response: { data: { message, status } } } = action;

            let uploadSucess = {
                shareGalleryLoading: false,
                shareGalleryStatus: status,
                shareGalleryMessage: message,
                shareGalleryResponse: !!action.response.data.gallery ? action.response.data.gallery : ""
            }
            return {
                ...state,
                ...{
                    shareGalleryApi: { ...state.shareGalleryApi, ...uploadSucess }
                }
            }
        case SHARE_GALLERY_DATA_FAILURE:
            let galleryShareFailure = {
                shareGalleryLoading: false,
                shareGalleryStatus: action.status,
                shareGalleryMessage: action.response.data.message,
                shareGalleryResponse: ""

            }

            return {
                ...state,
                ...{
                    shareGalleryApi: { ...state.shareGalleryApi, ...galleryShareFailure }
                }
            }
        case CLEAR_SHARE_GALLERY_RESPONSE:
            let clearUploadGallery = {
                shareGalleryStatus: "",
                shareGalleryMessage: "",
                shareGalleryResponse: ""
            }
            return {
                ...state,
                ...{
                    shareGalleryApi: { ...state.shareGalleryApi, ...clearUploadGallery }
                }
            }
        case CHANGE_GALLERY_FILE_DETAIL:
            return {
                ...state,
                ...{ galleryDetails: { ...state.galleryDetails, ...action.newState } }
            }
        case GET_GALLERY_PHOTO_DATA_REQUEST:
        
            return {
                ...state,
                ...{ getGalleryPhotoApi: { ...state.getGalleryPhotoApi, ...{ getPhotoLoading: true } } }
            }
        case GET_GALLERY_PHOTO_DATA_SUCCESS:
        


            let getPhotoSucess = {
                getPhotoLoading: false,
                getPhotoStatus: action.response.data.status,
                getPhotoMessage: action.response.data.message,
            }
            let photoDetailSucess = {
                photoList: !!action.response.data.list ? action.response.data.list : [],
                totalPhoto: action.response.data.total
            }
            return {
                ...state,
                ...{
                    getGalleryPhotoApi: { ...state.getGalleryPhotoApi, ...getPhotoSucess },
                    galleryDetails: { ...state.galleryDetails, ...photoDetailSucess }
                }
            }
        case GET_GALLERY_PHOTO_DATA_FAILURE:

            let getPhotoFailure = {
                getPhotoLoading: false,
                getPhotoStatus: action.status,
                getPhotoMessage: action.response.data.message,

            }
            let photoDetailFail = {
                photoList: [],
                totalPhoto: ""
            }
            return {
                ...state,
                ...{
                    getGalleryPhotoApi: { ...state.getGalleryPhotoApi, ...getPhotoFailure },
                    galleryDetails: { ...state.galleryDetails, ...photoDetailFail }
                }
            }
        case CLEAR_GET_GALLERY_PHOTO_RESPONSE:
            let cleargetPhotoGallery = {
                getPhotoStatus: "",
                getPhotoMessage: ""
            }
            let clearPhotoDetail = {
                photoList: [],
                totalPhoto: "",
                photoPage: 0,
                fileActiveIndex: "",
                carousalDataFile: [],
                deleteSelectedItem: [],
                unSelectedState: ""
            }
            return {
                ...state,
                ...{
                    getGalleryPhotoApi: { ...state.getGalleryPhotoApi, ...cleargetPhotoGallery },
                    galleryDetails: { ...state.galleryDetails, ...clearPhotoDetail }
                }
            }
        case GET_GALLERY_VIDEO_DATA_REQUEST:
            return {
                ...state,
                ...{ getGalleryVideoApi: { ...state.getGalleryVideoApi, ...{ getVideoLoading: true } } }
            }
        case GET_GALLERY_VIDEO_DATA_SUCCESS:
            let getVideoSucess = {
                getVideoLoading: false,
                getVideoStatus: action.response.data.status,
                getVideoMessage: action.response.data.message,
            }
            let videoDetailSucess = {
                videoList: !!action.response.data.list ? [...state.galleryDetails.videoList, ...action.response.data.list] : [],
                totalVideo: action.response.data.total
            }
            return {
                ...state,
                ...{
                    getGalleryVideoApi: { ...state.getGalleryVideoApi, ...getVideoSucess },
                    galleryDetails: { ...state.galleryDetails, ...videoDetailSucess }
                }
            }
        case GET_GALLERY_VIDEO_DATA_FAILURE:
            let getVideoFailure = {
                getVideoLoading: false,
                getVideoStatus: action.status,
                getVideoMessage: action.response.data.message,

            }
            let videoDetailFail = {
                videoList: [],
                totalVideo: ""
            }
            return {
                ...state,
                ...{
                    getGalleryVideoApi: { ...state.getGalleryVideoApi, ...getVideoFailure },
                    galleryDetails: { ...state.galleryDetails, ...videoDetailFail }
                }
            }
        case CLEAR_GET_GALLERY_VIDEO_RESPONSE:
            let clearVideoGallery = {
                getVideoStatus: "",
                getVideoMessage: ""
            }
            let clearVideoDetail = {
                videoList: [],
                totalVideo: "",
                videoPage: 0,
                fileActiveIndex: "",
                carousalDataFile: [],
                deleteSelectedItem: [],
                unSelectedState: ""
            }
            return {
                ...state,
                ...{
                    getGalleryVideoApi: { ...state.getGalleryVideoApi, ...clearVideoGallery },
                    galleryDetails: { ...state.galleryDetails, ...clearVideoDetail }
                }
            }
        case DELETE_GALLERY_DATA_REQUEST:
            return {
                ...state,
                ...{ deleteFilesApi: { ...state.deleteFilesApi, ...{ deleteFilesLoading: true } } }
            }
        case DELETE_GALLERY_DATA_SUCCESS:
            let deleteSucess = {
                deleteFilesLoading: false,
                deleteFileStatus: action.response.data.status,
                deleteFileMessage: action.response.data.message
            }
            return {
                ...state,
                ...{
                    deleteFilesApi: { ...state.deleteFilesApi, ...deleteSucess }
                }
            }
        case DELETE_GALLERY_DATA_FAILURE:
            let deleteGalleryFailure = {
                deleteFilesLoading: false,
                deleteFileStatus: action.status,
                deleteFileMessage: action.response.data.message,

            }

            return {
                ...state,
                ...{
                    deleteFilesApi: { ...state.deleteFilesApi, ...deleteGalleryFailure }
                }
            }
        case CLEAR_DELETE_GALLERY_ITEM_RESPONSE:
            let clearDeleteGallery = {
                deleteFilesLoading: "",
                deleteFileStatus: "",
                deleteFileMessage: ""
            }
            return {
                ...state,
                ...{
                    deleteFilesApi: { ...state.deleteFilesApi, ...clearDeleteGallery }
                }
            }
        default:
            return state
    }
}