import {
  GET_BLOGS_DATA_REQUEST,
  GET_BLOGS_DATA_SUCCESS,
  GET_BLOGS_DATA_FAILURE,
  GET_RECENT_BLOGS_REQUEST,
  GET_ALL_BLOGS,
  GET_RECENT_BLOGS_SUCCESS,
  GET_RECENT_BLOGS_FAILURE,
  GET_SINGLE_BLOG_SUCCESS,
  GET_SINGLE_BLOG_FAILURE,
  GET_SINGLE_BLOG_REQUEST,
  SEARCH_BLOG_DATA_REQUEST,
  SEARCH_BLOG_DATA_SUCCESS,
  SEARCH_BLOG_DATA_FAILURE,
} from "./LandingContentConstant";


const initialState = {
// TO STORE ALL BLOGS RESPONSE
  allBlogsData: {
    getAllBlogLoading: false,
    getAllBlogStatus: "",
    getAllBlogSuccess: "",
    getAllBlogMessage: "",
    getAllBlogsData: {},
  },
// TO STORE RECENT BLOGS RESPONSE
  recentBlogsData: {
    getRecentBlogLoading: false,
    getRecentBlogStatus: "",
    getRecentBlogSuccess: "",
    getRecentBlogMessage: "",
    getRecentBlogsData: [],
  },

  // TO STORE SINGLE BLOG RESPONSE
  singleBlogDetails: {
    getSingleBlogLoading: false,
    getSingleBlogStatus: "",
    getSingleBlogSuccess: "",
    getSingleBlogMessage: "",
    getSingleBlogData: {},
  },

  searchBlogsDetails: {
    getSearchBlogLoading: false,
    getSearchBlogStatus: "",
    getSearchBlogSuccess: "",
    getSearchBlogMessage: "",
    getSearchBlogData: [],
  },
};



export const blogPageReducer = (state = initialState, action) => {

  switch (action?.type) {
    // CASES FOR ALL BLOGS
    case GET_BLOGS_DATA_REQUEST:
      return {
        ...state,
        ...{
          allBlogsData: {
            ...state.allBlogsData,
            ...{ getAllBlogLoading: true },
          },
        },
      };
    case GET_BLOGS_DATA_SUCCESS:
      let getAllBlogsDataSuccess = {
        getAllBlogLoading: false,
        getAllBlogStatus: 200,
        getAllBlogSuccess: action.response.data.success,
        getAllBlogMessage: action.response.data.message,
        getAllBlogsData: action?.response?.data,
      };
      return {
        ...state,
        ...{
          allBlogsData: {
            ...state.allBlogsData,
            ...getAllBlogsDataSuccess,
          },
        },
      };
    case GET_BLOGS_DATA_FAILURE:
      let getAllBlogsDataFailure = {
        getAllBlogLoading: false,
        getAllBlogStatus: action.status,
        getAllBlogSuccess: false,
        getAllBlogMessage: action.response.data.message,
        getAllBlogsData: {},
      }; 
      return {
        ...state,
        ...{
          allBlogsData: {
            ...state.allBlogsData,
            ...getAllBlogsDataFailure,
          },
        },
      };

    // FOR RECENTE BLOG

    case GET_RECENT_BLOGS_REQUEST:
      return {
        ...state,
        ...{
          recentBlogsData: {
            ...state.recentBlogsData,
            ...{ getRecentBlogLoading: true },
          },

        },
      };
    case GET_RECENT_BLOGS_SUCCESS:
      let getRecentBlogSuccess = {
        getRecentBlogLoading: false,
        getRecentBlogStatus: 200,
        getRecentBlogSuccess: action.response.data.success,
        getRecentBlogMessage: action.response.data.message,
        getRecentBlogsData: action?.response?.data?.data,
      };
      return {
        ...state,
        ...{
          recentBlogsData: {
            ...state.recentBlogsData,
            ...getRecentBlogSuccess,
          },
        },
      };
    case GET_RECENT_BLOGS_FAILURE:
      let getRecentBlogFailure = {
        getRecentBlogLoading: false,
        getRecentBlogStatus: action.status,
        getRecentBlogSuccess: false,
        getRecentBlogMessage: action.response.data.message,
        getRecentBlogsData: [],
      };
      return {
        ...state,
        ...{
          recentBlogsData: {
            ...state.recentBlogsData,
            ...getRecentBlogFailure,
          },
        },
      };

    // FOR DETAILS OF SINGLE BLOG

    case GET_SINGLE_BLOG_REQUEST:
      return {
        ...state,
        ...{
          singleBlogDetails: {
            ...state.singleBlogDetails,
            ...{ getSingleBlogLoading: true },
          },
        },
      };
    case GET_SINGLE_BLOG_SUCCESS:
      let getSingleBlogSuccess = {
        getSingleBlogLoading: false,
        getSingleBlogStatus: 200,
        getSingleBlogSuccess: action.response.data.success,
        getSingleBlogMessage: action.response.data.message,
        getSingleBlogData: action?.response?.data?.data,
      };
      return {
        ...state,
        ...{
          singleBlogDetails: {
            ...state.singleBlogDetails,
            ...getSingleBlogSuccess,
          },
        },
      };
    case GET_SINGLE_BLOG_FAILURE:
      let getSingleBlogFailure = {
        getSingleBlogLoading: false,
        getSingleBlogStatus: action.status,
        getSingleBlogSuccess: false,
        getSingleBlogMessage: action.response.data.message,
        getSingleBlogData: {},
      };
      return {
        ...state,
        ...{
          singleBlogDetails: {
            ...state.singleBlogDetails,
            ...getSingleBlogFailure,
          },
        },
      };

case SEARCH_BLOG_DATA_REQUEST:
  return {
    ...state,
    ...{
      searchBlogsDetails: {
        ...state.searchBlogsDetails,
        ...{ getSearchBlogLoading: true },
      },
    },
  };


  case SEARCH_BLOG_DATA_SUCCESS:
    let getSearchBlogSuccess = {
      getSearchBlogLoading: false,
      getSearchBlogStatus: 200,
      getSearchBlogSuccess: action.response.data.success,
      getSearchBlogMessage: action.response.data.message,
      getSearchBlogData: action?.response?.data,
    };
    return {
      ...state,
      ...{
        searchBlogsDetails: {
          ...state.searchBlogsDetails,
          ...getSearchBlogSuccess,
        },
      },
    };

    case SEARCH_BLOG_DATA_FAILURE:
      let getSearchBlogFailure = {
        getSearchBlogLoading: false,
        getSearchBlogStatus: action.status,
        getSearchBlogSuccess: false,
        getSearchBlogMessage: action.response.data.message,
        getSearchBlogData: {},
      };
      return {
        ...state,
        ...{
          searchBlogsDetails: {
            ...state.searchBlogsDetails,
            ...getSearchBlogFailure,
          },
        },
      };
    default:
      return state;
  }
};
