import React from "react";
import { useToasts } from "react-toast-notifications";

import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { UPDATE_SELLER_SERVICE } from "../../../urls";
import { getCookie, onlyUniqueArrOfObject } from "../../../utilities/functions";
import EditServices from "../../../../modules/Account/common/EditServices";
import {
  deleteServiceApi,
  fetchServiceListApi,
  getAllSellerDataApi,
  getProfileApi,
  industryListApi,
} from "../../../api/AccountApiService";
import IndustryTab from "./IndustryTab";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  useLocation,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
const ServicesTab = ({ searchProfileData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  let { pathname, state } = useLocation();

  const [isShow, setIsShow] = useState(false);
  const [mainServiceDescArr, setMainServiceDescArr] = useState([]);
  const [secondaryServiceDescArr, setSecondaryServiceDescArr] = useState([]);
  const [primaryServices, setPrimaryServices] = useState([]);
  const [secondaryService, setSecondaryService] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [isEditServiceOpen, setIsEditService] = useState(false);
  const [isUseEffectPlay, setIsUseEffectPlay] = useState(1);

  const [openConfirmModel, setConfirmModel] = useState(false);
  const [servicesTypes, setServicesType] = useState("");
  const [deteleItemObj, setDeteleItemObj] = useState({});
  const [industryDescArr, setIndustryDescArr] = useState([]);
  const [isShowIndustry, setIsShowIndustry] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [errorNotification, setErrorNotification] = useState(true);
  // const history = useHistory();
  const [whichServiceDel, setWhichServiceDel] = useState("");
  const AccountState = useSelector((state) => state.AccountReducer);
  const {
    getAllSellerDataApi: { getAllSellerDatas },
  } = AccountState;

  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  if (history.action === "POP") {
    history.push(`/account/${profileData?.user_slug}`);
  }

  // UPDATE DESCRIPTION OF SECONDARY AND PRIMARY SERVICES
  const onUpdateServiceSubmit = async (value, type) => {
    setIsUseEffectPlay(isUseEffectPlay + 1);
    setBtnDisable(true);
    if (!!value.description?.length) {
      const data = new FormData();
      if (type == "main_services") {
        data.append("service_type", type);
        data.append("description", value.description);
        data.append("id",value.id)
      } else if (type === "secondary_services") {
        data.append("service_type", type);
        data.append("description", value.description);
        data.append("id",value.id)
      } else if (type == "industry") {
        data.append("service_type", type);
        data.append("description", value.description);
        data.append("id",value.id)
      } else {
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      }
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      await axios(UPDATE_SELLER_SERVICE + value.id, config)
        .then((response) => {
          if (response?.data?.status === 200) {
            dispatch(getAllSellerDataApi()).then((res) => {
              if (type == "main_services") {
                const primaryArr = res.response.data.data.main_service.map(
                  (item) => {
                    return { id: item.id, description: item.description };
                  }
                );
                setMainServiceDescArr(primaryArr);
                setPrimaryServices(res.response.data.data.main_service);
                addToast(response?.data?.message, {
                  appearance: "success",
                  autoDismiss: true,
                  // autoDismissTimeout: 2000,
                });
                setIsShow(null);
              } else if (type == "secondary_services") {
                const secArr = res.response.data.data.secondary_service.map(
                  (item) => {
                    return { id: item.id, description: item.description };
                  }
                );
                setSecondaryServiceDescArr(secArr);
                setSecondaryService(res.response.data.data.secondary_service);
                addToast(response?.data?.message, {
                  appearance: "success",
                  autoDismiss: true,
                  // autoDismissTimeout: 2000,
                });
                setIsShow(null);
              } else {
                const indArr = res.response.data.data.industry.map((item) => {
                  return { id: item.id, description: item.description };
                });
                setIndustryDescArr(indArr);
                setIndustry(res.response.data.data.industry);
                addToast(response?.data?.message, {
                  appearance: "success",
                  autoDismiss: true,
                  // autoDismissTimeout: 2000,
                });
                setIsShow(null);
              }
            });
          }
          else{
            addToast("Service can't be edit please save your seller application.", {
              appearance: "error",
              autoDismiss: true,
              // autoDismissTimeout: 2000,
            });
          }
        })
        .catch((err) => {
          console.log(err,"err in updated services")
          throw err;
        });
    } else {
      addToast("The description field is required.", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
    }
    setTimeout(() => setBtnDisable(false), 2000);
  };

  // STORE DATA OF SELECTED MAIN AND SECODARY SERVICES IN STATE
  useEffect(() => {
    if (
      !!getAllSellerDatas?.main_service ||
      !!getAllSellerDatas?.secondary_service
    ) {
      // CREATE EMPTY ARRAY
      if (isUseEffectPlay == 1) {
        const serviceArr = getAllSellerDatas?.main_service.map((item) => ({
          id: item?.id,
          description: item?.description,
        }));
        const secondaryArr = getAllSellerDatas?.secondary_service.map(
          (item) => ({ id: item?.id, description: item?.description })
        );
        const industryArr = getAllSellerDatas?.industry.map((item) => ({
          id: item?.id,
          description: item?.description,
        }));
        setMainServiceDescArr(serviceArr);
        setSecondaryServiceDescArr(secondaryArr);
        setIndustryDescArr(industryArr);
        setPrimaryServices(getAllSellerDatas?.main_service);
        setSecondaryService(getAllSellerDatas?.secondary_service);
        setIndustry(getAllSellerDatas?.industry);
      }
    }
  }, [
    getAllSellerDatas?.main_service,
    getAllSellerDatas?.secondary_service,
    isUseEffectPlay,
  ]);

  //  MENU ICON TO OPEN EDIT SERVICE

  const handleMenuAction = () => {
    if (getAllSellerDatas?.user_id) {
      hideShowLinkDrop();
      const dropDown = document.getElementById("edit-service");

      dropDown.classList.toggle("profile-action-active");
    } else {
      if (errorNotification) {
        setErrorNotification(false);
        addToast(
          "To add services you need to become a seller on the poolsmagnic platform",
          {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
            transitionState: "entered",
          }
        );
        // setTimeout(() => {
        //   setErrorNotification(true);
        // }, 2000);
      }
    }
  };

  // FUNCTION TO OPEN EDIT SERVICE SECTION
  const handleOpenEditService = () => {
    const dropDown = document.getElementById("edit-service");
    dropDown.style.visibility = "hidden";
    dropDown.style.opacity = 0;
    setIsEditService(true);
    setIsShow(null);
    window.scrollTo(0,document.body.scrollHeight);
  };

  // SHOW  EDIT SERVICE
  const hideShowLinkDrop = (is_currentUserIdClick) => {
    const dropDown = document.getElementById("edit-service");
    if (dropDown && !is_currentUserIdClick) {
      if (dropDown.style.opacity == "1") {
        dropDown.style.opacity = 0;
        dropDown.style.visibility = "hidden";
      } else {
        dropDown.style.opacity = 1;
        dropDown.style.visibility = "visible";
      }
    }
    if (dropDown && is_currentUserIdClick) {
      dropDown.style.opacity = 0;
      dropDown.style.visibility = "hidden";
    }
  };

  /* get all updated services after onSubmit on EditServices component with function  */
  const getServiceDataFromEditService = (
    main_service,
    secondary_service,
    industry
  ) => {
    setIsUseEffectPlay(1);
    setPrimaryServices(main_service);
    setSecondaryService(secondary_service);
    setIndustry(industry);
  };

  /* delete services based on service type and values  */
  const handleServiceDelete = () => {
    setBtnDisable(true);
    try {
      const bodyParameter = {
        type: servicesTypes,
        value: deteleItemObj.value,
      };
      dispatch(deleteServiceApi(bodyParameter)).then((res) => {
        addToast("Service deleted successfully", {
          appearance: "success",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });

        if (servicesTypes === "main_services") {
          const removeDesc = mainServiceDescArr.filter(
            (x) => x.id != deteleItemObj.id
          );
          const deleteService = primaryServices.filter(
            (x) => x.value != deteleItemObj.value
          );
          setMainServiceDescArr(removeDesc);
          setPrimaryServices(deleteService);
        } else if (servicesTypes === "secondary_services") {
          const removeDesc = secondaryServiceDescArr.filter(
            (x) => x.id != deteleItemObj.id
          );
          const deleteService = secondaryService.filter(
            (x) => x.value != deteleItemObj.value
          );
          setSecondaryServiceDescArr(removeDesc);
          setSecondaryService(deleteService);
        } else {
          const removeDesc = industryDescArr.filter(
            (x) => x.id != deteleItemObj.id
          );
          const deleteService = industry.filter(
            (x) => x.value != deteleItemObj.value
          );
          setIndustryDescArr(removeDesc);
          setIndustry(deleteService);
        }

        setConfirmModel(false);
      });
    } catch (err) {
      throw err;
    }

    setTimeout(() => setBtnDisable(false), 2000);
  };
  /* open delete modal on onClick  */
  const openDeleteConfirmModal = (item, type, serviceType) => {
    setConfirmModel(true);
    setDeteleItemObj(item);
    setServicesType(type);
    setWhichServiceDel(serviceType);
  };

  /* open descript based on user_id */
  const editServicesDescription = (item) => {
    if (isShow === item.id) {
      return setIsShow(null);
    }
    setIsShow(item.id);
  };
  /* if have a business acc so showing industry section else not showing  */
  useEffect(() => {
    if (
      getAllSellerDatas?.is_individual == 0 ||
      getAllSellerDatas?.acc_type == "business"
    ) {
      setIsShowIndustry(true);
    } else {
      setIsShowIndustry(false);
    }
  }, [getAllSellerDatas, isShowIndustry]);

  useEffect(() => {
    const data = {
      page: 1,
    };
    dispatch(fetchServiceListApi(data));
    dispatch(industryListApi());
    dispatch(getAllSellerDataApi());
  }, []);



  return (
    <>
      <section className="gallery">
        <div className="container">
          <div className="profile__box custom-shadow">
            <div className="row">
              <div className="col-md-12">
                <div className="profile__box__heading d-flex align-items-center justify-content-between">
                  <h3 className="text-uppercase h4">Service(s)</h3>
                  {pathname ==
                    `/account/${profileData?.user_slug}/services` && (
                    <>
                      <i
                        className="ri-more-2-fill dot-icon"
                        onClick={handleMenuAction}
                      ></i>

                      <ul
                        id="edit-service"
                        className="dropdown-list create-dropdown-list mt-2 mr-4"
                        onClick={handleOpenEditService}
                        data-toggle="modal"
                        data-target="updateservice"
                      >
                        <li>
                          <a >Edit Services List</a>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
                {pathname == `/account/${profileData?.user_slug}/services` ? (
                  <>
                    {!primaryServices?.length && !secondaryService?.length && (
                      <p>No Services</p>
                    )}

                    {!!getAllSellerDatas &&
                    !!Object.keys(getAllSellerDatas).length ? (
                      <div className="services-listing">
                        {!!getAllSellerDatas &&
                          !!Object.keys(getAllSellerDatas).length &&
                          primaryServices.map((item, i) => (
                            <>
                              {item?.status !== 2 && (
                                <div
                                  className="services-listing__inner"
                                  key={i}
                                >
                                  <div className="services-listing__header d-flex align-items-start">
                                    <div className="services-listing__img">
                                    <img
                                      onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/services-icon.svg")
                                      }
                                      src={item.picture}
                                      alt="services icon"
                                    />
                                    </div>
                                    <div>
                                      <span className="h5 m-0">
                                        {item.label}
                                      </span>
                                      <p className="mb-1 service-description">
                                      {mainServiceDescArr[i]?.description}
                                    </p>
                                    <div className="service-control">
                                    {pathname ==
                                    `/account/${profileData?.user_slug}/services` && item.status !== 0  ? (
                                      <>
                                        <a
                                          href="javascript:;"
                                          onClick={(e) => {
                                            editServicesDescription(item, i);
                                          }}
                                        >
                                          <i className="ri-pencil-line"></i>
                                        </a>
                                        <a
                                          href="javascript:;"
                                          onClick={() =>
                                            openDeleteConfirmModal(
                                              item,
                                              "main_services",
                                              "Service"
                                            )
                                          }
                                        >
                                          <i className="ri-delete-bin-line"></i>
                                        </a>
                                      </>
                                    ) : null}
                                  </div>
                                    </div>
                                    {item.status === 0 && (
                                      <span className="pending-service">
                                        Pending Approval
                                      </span>
                                    )}
                                  </div>


                                  
                                  <div>
                                    {isShow === item.id ? (
                                      <div className="row mt-4">
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label htmlFor="Description">
                                              Description
                                            </label>
                                            <textarea
                                              name="primary_description"
                                              className="form-control"
                                              onChange={(e) => {
                                                item.description =
                                                  e.target.value;
                                                setInputs([...inputs]);
                                              }}
                                              value={item.description || ""}
                                              placeholder="Describe your services and show how you will provide it to your customers "
                                            />
                                            <p
                                              style={{ display: "none" }}
                                              className="error-message"
                                            ></p>
                                          </div>
                                          <div className="form-group  mt-3 form-btn text-right">
                                            <button
                                              onClick={() => {
                                                onUpdateServiceSubmit(
                                                  item,
                                                  "main_services"
                                                );
                                              }}
                                              className="btn btn-blue"
                                              disabled={btnDisable}
                                            >
                                              Update{" "}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              )}
                            </>
                          ))}

                        {secondaryService.map((item, i) => (
                          <>
                            {item?.status !== 2 && (
                              <div className="services-listing__inner" key={i}>
                                <div className="services-listing__header d-flex align-items-start">
                                <div className="services-listing__img">
                                <img
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/services-icon.svg")
                                    }
                                    src={item.picture}
                                    alt="services icon"
                                  />
                                </div>
                                  <div>
                                  <span className="h5 m-0">{item.label}</span>
                                  <p className="mb-2 service-description">
                                  {secondaryServiceDescArr[i]?.description}
                                </p>
                                <div className="service-control">
                                  {pathname ==
                                  `/account/${profileData?.user_slug}/services` && item.status !== 0 ? (
                                    <>
                                      <a
                                        href="javascript:;"
                                        onClick={(e) => {
                                          editServicesDescription(item, i);
                                        }}
                                      >
                                        <i className="ri-pencil-line"></i>
                                      </a>
                                      <a
                                        href="javascript:;"
                                        onClick={() =>
                                          openDeleteConfirmModal(
                                            item,
                                            "secondary_services",
                                            "Service"
                                          )
                                        }
                                      >
                                        <i className="ri-delete-bin-line"></i>
                                      </a>
                                    </>
                                  ) : null}
                                </div>
                                  </div>
                                  {item.status === 0 && (
                                      <span className="pending-service">
                                        Pending Approval
                                      </span>
                                    )}
                                </div>
                              

                                <div>
                                  {isShow === item.id ? (
                                    <div className="row mt-4">
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <label htmlFor="Description">
                                            Description
                                          </label>
                                          <textarea
                                            name="primary_description"
                                            className="form-control"
                                            onChange={(e) => {
                                              item.description = e.target.value;
                                              setInputs([...inputs]);
                                            }}
                                            value={item.description || ""}
                                            placeholder="Describe your services and show how you will provide it to your customers "
                                          />
                                          <p
                                            style={{ display: "none" }}
                                            className="error-message"
                                          ></p>
                                        </div>
                                        <div className="form-group  mt-3 form-btn text-right">
                                          <button
                                            onClick={() => {
                                              onUpdateServiceSubmit(
                                                item,
                                                "secondary_services"
                                              );
                                            }}
                                            className="btn btn-blue"
                                            disabled={btnDisable}
                                          >
                                            {" "}
                                            Update{" "}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                            
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    {!searchProfileData !== undefined &&
                      !searchProfileData?.main_service.length === 0 && (
                        <p>No Services</p>
                      )}

                    {!!searchProfileData !== undefined &&
                      searchProfileData?.main_service?.length > 0 && (
                        <div className="services-listing">
                          {searchProfileData?.main_service?.map((item, i) => (
                            <>
                              {item?.status === 1 && (
                                <div
                                  className="services-listing__inner"
                                  key={i}
                                >
                                  <div className="services-listing__header d-flex align-items-center">
                                    <img
                                      onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/services-icon.svg")
                                      }
                                      src={item.picture}
                                      alt="services icon"
                                    />
                                    <span className="h5 mx-2">
                                      {item.label}
                                    </span>
                                  </div>

                                  <p className="mb-2 service-description">
                                    {item?.description}
                                  </p>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      )}

                    <div className="services-listing__inner"></div>

                    {!!searchProfileData !== undefined &&
                      searchProfileData?.secondary_service?.length > 0 && (
                        <div className="services-listing">
                          {searchProfileData?.secondary_service?.map(
                            (item, i) => (
                              <>
                                {item?.status === 1 && (
                                  <div
                                    className="services-listing__inner"
                                    key={i}
                                  >
                                    <div className="services-listing__header d-flex align-items-center">
                                      <img
                                        onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/services-icon.svg")
                                        }
                                        src={item.picture}
                                        alt="services icon"
                                      />
                                      <span className="h5 mx-2">
                                        {item.label}
                                      </span>
                                    </div>

                                    <p className="mb-2 service-description">
                                      {item?.description}
                                    </p>
                                  </div>
                                )}
                              </>
                            )
                          )}
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {isShowIndustry && (
              <IndustryTab
                getAllSellerDatas={getAllSellerDatas}
                industry={industry}
                btnDisable={btnDisable}
                pathname={pathname}
                onUpdateServiceSubmit={onUpdateServiceSubmit}
                industryDescArr={industryDescArr}
                editIndustryDesc={editServicesDescription}
                isShow={isShow}
                openDeleteConfirmModal={openDeleteConfirmModal}
              />
            )}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {!!isEditServiceOpen && (
              <EditServices
                getAllSellerDatas={getAllSellerDatas}
                primaryServices={primaryServices}
                getServiceDataFromEditService={getServiceDataFromEditService}
                secondaryService={secondaryService}
                industry={industry}
                setIsEditService={setIsEditService}
                setIsShow={setIsShow}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        show={openConfirmModel}
        onHide={() => setConfirmModel(false)}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-size"
      >
        <div className="modal-body text-center">
          <button
            // ref={closeDeleteEl}
            onClick={() => {
              setDeteleItemObj({});
              setConfirmModel(false);
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <p className="pt-4">
            Are you sure you want to delete {whichServiceDel}?
          </p>
          <div className="alert-btn mt-3 pb-4">
            <button
              onClick={() => handleServiceDelete()}
              className="btn btn-secondary btn-sm mr-3"
              disabled={btnDisable}
            >
              Yes
            </button>
            <a
              href="javascript:;"
              onClick={() => {
                setDeteleItemObj({});
                setConfirmModel(false);
              }}
              className="btn btn-blue btn-sm"
            >
              No
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ServicesTab;
