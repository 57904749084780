import {
  CHANGE_SEARCH_NAME_INPUT,
  CHANGE_UNREAD_FRIEND_MESSAGE,
  CHANGE_UNREAD_GROUP_MESSAGE,
  CHNAGE_SEARCH_MEMBERS_NAME_INPUT,
  UNREAD_USER_MESSAGE,
} from "./ToolbarConstants";

const changeSearchName = (newState) => {
  return { type: CHANGE_SEARCH_NAME_INPUT, newState };
};

const changeSearchMembersName = (newState) => {
  return { type: CHNAGE_SEARCH_MEMBERS_NAME_INPUT, newState };
};

const changeUnreadFriendMessage = (newState) => {
  return { type: CHANGE_UNREAD_FRIEND_MESSAGE, newState };
};

const changeUnreadGroupMessage = (newState) => {
  return { type: CHANGE_UNREAD_GROUP_MESSAGE, newState };
};

const unreadUserMessages = (newState)=>
{
  return {type:UNREAD_USER_MESSAGE,newState}
}


export {
  changeSearchName,
  changeUnreadGroupMessage,
  changeUnreadFriendMessage,
  changeSearchMembersName,
  unreadUserMessages
};
