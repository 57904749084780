import React, { forwardRef, useEffect, useRef, useState } from "react";
import { getGalleryPhotoApi, ShareGalleryFilesApi } from "../../../api/ProfilePostApiService";
import { getCookie, useForceUpdate } from "../../../utilities/functions";
import { changeGalleryDetail, changeGalleryShareInput, clearShareGalleryResponse } from "./ProfileGalleryAction";

let allBaseImages = [], files = [];
const ShareGalleryModel = forwardRef(({ GalleryState, addToast, dispatch }) => {
    const forceUpdate = useForceUpdate();
    const { galleryShareInput: { galleryFile, galleryUrl, isFeedShare } } = GalleryState
    const { shareGalleryApi: { shareGalleryLoading, shareGalleryStatus, shareGalleryResponse } } = GalleryState
    const { galleryDetails: { photoList, videoList,photoPage } } = GalleryState

    const inputGalleryFile = useRef();
    const inputGalleryVideo = useRef()
    const closeEl = useRef()
   
        const profileData = !!getCookie("profile_data")
          ? JSON.parse(getCookie("profile_data"))
          : "";

    const handleFileChange = e => {
        const data = e.target.files[0];

        if (!!data) {
            const fileName = data.name.split(".");
            const imageFormat = fileName[fileName.length - 1];
            if (imageFormat === "png" || imageFormat === "jpg" || imageFormat === "jpeg" ||
                imageFormat === "PNG" || imageFormat === "JPG" || imageFormat === "JPEG") {
                files.push(...e.target.files);
                dispatch(changeGalleryShareInput({ galleryFile: files }))

                // Pusing inform with base64
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    allBaseImages.push(reader.result);
                    dispatch(changeGalleryShareInput({ galleryUrl: allBaseImages }))
                });
                reader.readAsDataURL(e.target.files[0]);
            }
            else {
                addToast("Only .png, .jpg, .jpeg image formats supported.", {
                    appearance: 'error',
                    autoDismiss: true,
                 
                });
            }
        }
    }
    const handleVideoChange = e => {
        var data = e.target.files[0];
        if (!!data) {
            const fileName = data.name.split(".");
            const imageFormat = fileName[fileName.length - 1];
            if (imageFormat === "mp4" || imageFormat === "MP4" || imageFormat === "mov" || imageFormat === "MOV" || imageFormat === "webm" || imageFormat === "WEBM") {
                if (data.size < 5000024) {
                    files.push(...e.target.files);
                    dispatch(changeGalleryShareInput({ galleryFile: files }))
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        allBaseImages.push(reader.result);
                        dispatch(changeGalleryShareInput({ galleryUrl: allBaseImages }))

                    });
                    reader.readAsDataURL(e.target.files[0]);
                }
                else {
                    addToast("Maximum upload video limit is 5 mb", {
                        appearance: 'error',
                        autoDismiss: true,
                  
                    });
                }
            }
            else {
                addToast("The video formal is not supported", {
                    appearance: 'error',
                    autoDismiss: true,
                    // autoDismissTimeout: 2000
                });
            }
        }
    }
    const removeFile = (index) => {
        galleryUrl.splice(index, 1);
        dispatch(changeGalleryShareInput({ galleryUrl: galleryUrl }))
        for (let i in galleryFile) {
            if (i == index) {
                galleryFile.splice(i, 1);
                dispatch(changeGalleryShareInput({ galleryFile: galleryFile }))
            }
        }
    }
    const openPicturesFolder = () => {
        if (galleryUrl.length < 8) {
            inputGalleryFile.current.click()
        }
        else {
            addToast("You can upload maximum 8 items", {
                appearance: 'error',
                autoDismiss: true,
                // autoDismissTimeout: 2000
            });
        }
    }
    const openVideosFolder = () => {
        if (galleryUrl.length < 8) {
            inputGalleryVideo.current.click()
        }
        else {
            addToast("You can upload maximum 8 items", {
                appearance: 'error',
                autoDismiss: true,
                // autoDismissTimeout: 2000
            });
        }
    }
    useEffect(() => {
        if (!!shareGalleryStatus && shareGalleryStatus == "200") {
            closeEl.current.click();
            if (!!shareGalleryResponse) {
                for (let i in shareGalleryResponse) {
                    if (shareGalleryResponse[i].ext == "mp4" || shareGalleryResponse[i].ext == "mov" || shareGalleryResponse[i].ext == "MP4" || shareGalleryResponse[i].ext == "MOV" || shareGalleryResponse[i].ext == "webm" || shareGalleryResponse[i].ext == "WEBM") {
                        videoList.unshift(shareGalleryResponse[i])
                        dispatch(changeGalleryDetail({ videoList: videoList }));
                        forceUpdate()
                    }
                    else {
                        photoList.unshift(shareGalleryResponse[i])
                    }
                }

            }
            dispatch(clearShareGalleryResponse())
        }

    }, [shareGalleryStatus])

    const handleSharePost = () => {
        if (galleryFile.length > 0) {

            let picture = []
            let videos = []
            for (let i in galleryFile) {
                const fileName = galleryFile[i].name.split(".");
                const imageFormat = fileName[fileName.length - 1];

                if (imageFormat === "mp4" || imageFormat === "MP4" || imageFormat === "mov" || imageFormat === "MOV" || imageFormat === "webm" || imageFormat === "WEBM") {
                    videos.push(galleryFile[i])
                }
                else {
                    picture.push(galleryFile[i])
                }
            }

            const bodyParameter = new FormData();

            picture.forEach(function (image, i) {
                bodyParameter.append('image_' + i, image);
            });
            videos.forEach(function (video, i) {
                bodyParameter.append('video_' + i, video);
            });

            bodyParameter.append("videos", "" + videos.length);
            bodyParameter.append("images", "" + !!picture ? picture.length : "");
            bodyParameter.append("type", "" + isFeedShare);

            bodyParameter.append("visibility", isFeedShare);
            bodyParameter.append("text", "" + "");
            dispatch(ShareGalleryFilesApi(bodyParameter)).then((res) => {
                if(res.response.data.status===200)
                {

                    const bodyParameter = {
                        page: photoPage,
                        user_id: profileData.id,
                        type: 0, //for image
                      };
                      dispatch(getGalleryPhotoApi(bodyParameter))
                    addToast("Uploaded successfully", {
                        appearance: 'success',
                        autoDismiss: true,
                        // autoDismissTimeout: 2000
                    });
                
              
                }
                else{
                    addToast("Image not uploaded", {
                        appearance: 'error',
                        autoDismiss: true,
                        // autoDismissTimeout: 2000
                    });
                }
             
            })
        }
    }
    
    const handleCloseGallery = () => {
        let clearsInput = {
            galleryFile: [],
            galleryUrl: [],
            isFeedShare: 1
        }
        dispatch(changeGalleryShareInput({ ...GalleryState.galleryShareInput, ...clearsInput }))
        allBaseImages = [],
            files = []
    }

    return (
        <>
            {/* Modal */}
            <div className="modal fade" id="gallery-modal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><img src="/assets/images/icon.svg" alt="icon" className="site-icon" /> Gallery</h5>
                            <button onClick={handleCloseGallery} ref={closeEl} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" >×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="feeds-modal__top d-flex mb-3">
                                <div className="feeds-opt">
                                    <label>Show in Feeds</label>
                                    <select name="visibilty" className="form-control" value={isFeedShare} onChange={(e) => dispatch(changeGalleryShareInput({ isFeedShare: e.target.value }))}>
                                        <option value={0}>Yes</option>
                                        <option value={1}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="grid">
                                {galleryUrl.length > 0 &&
                                    galleryUrl.map((data, index) =>
                                        <div className="media-box" id={index}>
                                            <a href="javascript:;" className="rejected" onClick={() => removeFile(index)}><i className="ri-close-fill" ></i></a>
                                            {data.match("/mp4") || data.match("/webm") || data.match("/mov") ?
                                                <video src={data} /> :
                                                <img src={data} />
                                            }
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="row w-100">
                                <div className="col-md-8 px-0">
                                    <div className="feeds-post__type d-flex justify-content-start w-100">
                                        <div className="feeds-post__category"><a href="javascript:;" onClick={openPicturesFolder} ><i className="ri-image-fill pictures-option" /> Pictures
                                            <input id="uploadfile" type="file" className="d-none" ref={inputGalleryFile} onChange={handleFileChange} multiple={true} accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG" />
                                        </a></div>
                                        <div className="feeds-post__category"> <a href="javascript:;" onClick={openVideosFolder} ><i className="ri-movie-line video-option" /> Videos
                                            <input type="file" name="file" value="" ref={inputGalleryVideo} id="upload_fle" onChange={handleVideoChange} className="d-none" accept=" video/*" />
                                        </a></div>                                    </div>
                                </div>
                                <div className="col-md-4 text-right">
                                    <a href="javascript:;" onClick={handleSharePost} className={!!shareGalleryLoading ? "btn btn-primary btn-sm disabled" : "btn btn-primary btn-sm"}> Upload</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
export default ShareGalleryModel