import React, { useEffect, useState } from "react";
import { numFormatter, useForceUpdate } from "../../../utilities/functions";
import Input from "../Input/Input";
import { Carousel } from "react-responsive-carousel";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { IMAGE_BASE_URL } from "../../../urls";
import {
  changeUserPostActiveCarousal,
  changeUserPostReactDetails,
  changeUserShareListDetail,
  changeUserViewsDetails,
  clearSingleUserPostCmtReplyListResponse,
  clearSingleUserPostCmtReplyResponse,
  clearSingleUserPostCommentListResponse,
  clearSingleUserPostCommentResponse,
  clearSingleUserPostReactListResponse,
  clearSingleUserPostReactResponse,
  clearSingleUserPostShareResponse,
  clearSingleUserPostViewResponse,
  clearUserPostViewResponse,
} from "./ProfilePostAction";
import {
  singlePostLikeDislikeApi,
  singlePostViewApi,
} from "../../../api/HomeApiService";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  singleUserPostCommentApi,
  singleUserPostCommentListApi,
  singleUserPostCommentReplyApi,
  singleUserPostCommentReplyListApi,
  singleUserPostReactApi,
  singleUserPostReactListApi,
  singleUserPostShareApi,
  singleUserPostShareListApi,
  singleUserPostViewApi,
  singleUserPostViewListApi,
} from "../../../api/ProfilePostApiService";

const override = css`
  text-align: center;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 38%;
  right: 50%;
`;
const SingleProfilePost = ({ profilePostState }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const forceUpdate = useForceUpdate();
  const [singleFileId, setSingleFileId] = useState();
  const HomeState = useSelector((state) => state.HomeReducer);
  const {
    activeCarousalPostDetail: { activeCarousalPost, activeIndexPost },
  } = profilePostState;

  const {
    singlePostReactionApi: { singleReactionStatus },
  } = profilePostState;
  const {
    reactionListDetail: { singleReactListpage, reactionFileId },
  } = profilePostState;
  const {
    singlePostCommentApi: { SingleCommentResponse, SingleCommentStatus },
  } = profilePostState;
  const {
    singlePostCommentListApi: {
      singleCommentListLoading,
      singleCommentListStatus,
    },
  } = profilePostState;
  const {
    singlePostCommentReplyApi: {
      singleCommentReplyResponse,
      singleCommentReplyStatus,
    },
  } = profilePostState;
  const {
    singleCommentReplyListApi: {
      singleReplyListStatus,
      singleReplyListLoading,
    },
  } = profilePostState;
  const {
    singlePostViewApi: { SingleViewStatus },
  } = profilePostState;
  const {
    postViewApi: { postViewStatus },
  } = profilePostState;
  const {
    shareSingleFeedPostApi: { shareSingleFeedStatus, shareSingleFeedMessage },
  } = profilePostState;
  const {
    postViewListDetail: { singleViewListpage, viewPostId, viewFileId },
  } = profilePostState;
  const {
    postShareListDetail: { sharePostId, shareFileId, singleShareListpage },
  } = profilePostState;
  const {
    singleReactListApi: { singleReactListStatus },
  } = profilePostState;
  const [commentScrool, setCommentScrool] = useState(false);
  const [singleIsReact, setSingleIsReact] = useState(false);

  const scroolToBottom = () => {
    if (!!singleFileId && commentScrool) {
      var div = document.querySelectorAll(
        "#scroll-single-post-" + singleFileId
      )[activeIndexPost];

      if (!!div) {
        window.setTimeout(() => {
          div.children[0].scroll({
            top: div.children[0].scrollHeight,
            behavior: "smooth",
          });
        }, 100);
      }
      setCommentScrool(true);
    }
  };
  useEffect(() => {
    if (!!singleReactListStatus && !!singleIsReact) {
      dispatch(clearSingleUserPostReactListResponse());
      setSingleIsReact(false);
    }
  }, [singleReactListStatus]);
  useEffect(() => {
    if (!!singleShareListpage) {
      const bodyParameter = {
        page: singleShareListpage,
        post_id: sharePostId,
        file_id: shareFileId,
      };
      dispatch(singleUserPostShareListApi(bodyParameter));
    }
  }, [singleShareListpage]);
  useEffect(() => {
    if (shareSingleFeedStatus == 200) {
      addToast(shareSingleFeedMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearSingleUserPostShareResponse());
    }
  }, [shareSingleFeedStatus]);
  useEffect(() => {
    if (!!singleViewListpage) {
      const body = {
        page: singleViewListpage,
        post_id: viewPostId,
        file_id: viewFileId,
      };
      dispatch(singleUserPostViewListApi(body));
    }
  }, [singleViewListpage]);
  useEffect(() => {
    if (!!singleReplyListStatus) {
      dispatch(clearSingleUserPostCmtReplyListResponse());
    }
  }, [singleReplyListStatus]);
  useEffect(() => {
    if (!!SingleViewStatus) {
      dispatch(clearSingleUserPostViewResponse());
    }
  }, [SingleViewStatus]);
  useEffect(() => {
    if (!!singleCommentListStatus) {
      for (let i in activeCarousalPost) {
        const innerArr = activeCarousalPost[i].singlePostCommentList;
        for (let j in innerArr) {
          innerArr[j].SingleReplyComment = "";
          innerArr[j].singleSubComment = [];
          innerArr[j].singleReplyCommentPage = 0;
          innerArr[j].showHide = false;
        }
      }
      scroolToBottom();
      dispatch(clearSingleUserPostCommentListResponse());
    }
  }, [singleCommentListStatus]);
  useEffect(() => {
    if (activeCarousalPost.length > 0) {
      setCommentScrool(true);
      for (let i in activeCarousalPost) {
        activeCarousalPost[i].singlePostComment = "";
        activeCarousalPost[i].singlePostCommentList = [];
        activeCarousalPost[i].singlePostCommentPage = 0;

        dispatch(
          changeUserPostActiveCarousal({
            activeCarousalPost: activeCarousalPost,
          })
        );
      }
      $("body").click(function (event) { });
    }
  }, [activeCarousalPost]);
  useEffect(() => {
    if (!!SingleCommentStatus) {
      if (!!SingleCommentResponse) {
        for (let i in activeCarousalPost) {
          if (activeCarousalPost[i].post_id == SingleCommentResponse.post_id) {
            activeCarousalPost[i].singlePostCommentList.push(
              SingleCommentResponse
            );
            scroolToBottom();
          }
        }
      }
      dispatch(clearSingleUserPostCommentResponse());
    }
  }, [SingleCommentStatus]);
  useEffect(() => {
    if (!!singleCommentReplyStatus) {
      if (!!singleCommentReplyResponse) {
        let nestedComments = document.querySelectorAll(
          `#single-cmt-${singleCommentReplyResponse.file_id}-nested-${singleCommentReplyResponse.comment_id}`
        )[activeIndexPost].style.display;

        if (nestedComments == "block") {
          for (let i in activeCarousalPost) {
            if (i == activeIndexPost) {
              const innerArr = activeCarousalPost[i].singlePostCommentList;
              for (let j in innerArr) {
                if (
                  innerArr[j].comment_id ==
                  singleCommentReplyResponse.comment_id
                ) {
                  innerArr[j].singleSubComment.push(singleCommentReplyResponse);
                }
              }
            }
          }
        }
      }
      dispatch(clearSingleUserPostCmtReplyResponse());
    }
  }, [singleCommentReplyStatus]);
  useEffect(() => {
    if (!!singleReactListpage) {
      const bodyParameter = {
        file_id: reactionFileId,
        page: singleReactListpage,
      };
      dispatch(singleUserPostReactListApi(bodyParameter));
    }
  }, [singleReactListpage]);

  useEffect(() => {
    if (!!singleReactionStatus) {
      dispatch(clearSingleUserPostReactResponse());
      let singlereactDetailss = {
        singleReactListpage: 1,
      };
      dispatch(changeUserPostReactDetails(singlereactDetailss));
    }
  }, [singleReactionStatus]);

  const handlePlay = (e) => {
    //working...
    var videos = document.querySelectorAll("video");
    videos.forEach(function (video) {
      if (e.currentTarget.id == video.id) {
        video.play();
      } else {
        video.pause();
      }
    });
  };

  useEffect(() => {
    if (activeIndexPost !== "") {
      crowsOnChange(activeIndexPost);
    }
  }, [activeIndexPost]);

  const crowsOnChange = (index) => {
    for (let i in activeCarousalPost) {
      if (i == index) {
        activeCarousalPost[i].singlePostCommentList = [];
        activeCarousalPost[i].singlePostCommentPage = 0;
        activeCarousalPost[i].singlePostCommentPage =
          activeCarousalPost[i].singlePostCommentPage + 1;
        activeCarousalPost[i].singleActualComment =
          activeCarousalPost[i].comment_count;
        if (!activeCarousalPost[i].view) {
          activeCarousalPost[i].view_count =
            activeCarousalPost[i].view_count + 1;
          activeCarousalPost[i].view = true;
        }
        setSingleFileId(activeCarousalPost[i].file_id);
        const bodyParameter = {
          page: activeCarousalPost[i].singlePostCommentPage,
          post_id: activeCarousalPost[i].post_id,
          file_id: activeCarousalPost[i].file_id,
        };
        dispatch(singleUserPostCommentListApi(bodyParameter));
        const body = {
          post_id: activeCarousalPost[i].post_id,
          file_id: activeCarousalPost[i].file_id,
        };
        dispatch(singleUserPostViewApi(body));
      }
    }
    var videos = document.querySelectorAll("video");
    videos.forEach(function (video) {
      video.pause();
    });
  };

  const handleOnChange = (index) => {
    dispatch(changeUserPostActiveCarousal({ activeIndexPost: index }));
  };
  const handleClose = () => {
    let carousal = {
      activeCarousalPost: [],
      activeIndexPost: "",
    };
    dispatch(
      changeUserPostActiveCarousal({
        ...HomeState.activeCarousalDetail,
        ...carousal,
      })
    );
    var videos = document.querySelectorAll("video");
    videos.forEach(function (video) {
      video.pause();
    });
  };
  const handleSingleLikeDislike = (data, index) => {
    const bodyParameter = {
      post_id: data.post_id,
      file_id: data.file_id,
      likecheck: !!data.like ? "false" : "true",
    };
    dispatch(singlePostLikeDislikeApi(bodyParameter));

    for (let i in activeCarousalPost) {
      if (i == index) {
        activeCarousalPost[i].like = !!data.like ? false : true;
        if (data.like) {
          activeCarousalPost[i].file_like = activeCarousalPost[i].file_like + 1;
        } else {
          if (
            activeCarousalPost[i].file_like >
            activeCarousalPost[i].file_like - 1
          )
            activeCarousalPost[i].file_like =
              activeCarousalPost[i].file_like - 1;
        }
      }
    }
  };

  const handleOpenReact = (e, index) => {
    e.currentTarget.children[1].classList.toggle("open-single-post-emoji");
  };

  const handleSingleReact = (id, data, index) => {
    setSingleIsReact(true);
    const bodyParameter = {
      post_id: data.post_id,
      reaction: id,
      file_id: data.file_id,
    };
    dispatch(singleUserPostReactApi(bodyParameter));

    let singlereactDetail = {
      reactionFileId: data.file_id,
    };
    dispatch(changeUserPostReactDetails(singlereactDetail));
    data.reaction = id;
    if (!data.react) {
      data.react = !data.react ? true : data.react;
      forceUpdate();
    }
  };

  const handleSingleReactList = (id) => {
    let singlereactDetail = {
      singleReactListpage: 1,
      reactionFileId: id,
    };
    dispatch(changeUserPostReactDetails(singlereactDetail));
  };
  const singleViewListOpen = (data) => {
    let singleViewDetails = {
      singleViewListpage: 1,
      viewFileId: data.file_id,
      viewPostId: data.post_id,
    };
    dispatch(changeUserViewsDetails(singleViewDetails));
  };
  const handleSinglePostComment = (e, id) => {
    setCommentScrool(true);
    for (let i in activeCarousalPost) {
      if (id == activeCarousalPost[i].file_id) {
        activeCarousalPost[i].singlePostComment = e.target.value;
      }
    }
    dispatch(
      changeUserPostActiveCarousal({ activeCarousalPost: activeCarousalPost })
    );
  };
  const changeSingleNestedComment = (e, id) => {
    for (let i in activeCarousalPost) {
      const innerPost = activeCarousalPost[i].singlePostCommentList;
      for (let j in innerPost) {
        if (id == innerPost[j].comment_id) {
          innerPost[j].SingleReplyComment = e.target.value;
          forceUpdate();
        }
      }
    }
  };

  const handleSumbitSingleComment = (e, data, index) => {
    e.preventDefault();

    if (!!data.singlePostComment) {
      const bodyParameter = {
        post_id: data.post_id,
        comment: data.singlePostComment,
        file_id: data.file_id,
      };
      dispatch(singleUserPostCommentApi(bodyParameter));
      for (let i in activeCarousalPost) {
        if (i == index) {
          activeCarousalPost[i].comment_count =
            activeCarousalPost[i].comment_count + 1;
          activeCarousalPost[i].singlePostComment = "";
          forceUpdate();
        }
      }
    }
  };
  const handleSingleCommentPaginate = (data, index) => {
    setCommentScrool(false);
    for (let i in activeCarousalPost) {
      if (i == index) {
        activeCarousalPost[i].singlePostCommentPage =
          data.singlePostCommentPage + 1;
      }
    }
    if (!singleCommentListLoading) {
      const bodyParameter = {
        page: data.singlePostCommentPage,
        post_id: data.post_id,
        file_id: data.file_id,
      };
      dispatch(singleUserPostCommentListApi(bodyParameter));
    }
  };

  const handleReplySingleCmtPaginate = (data, index) => {
    if (!singleReplyListLoading) {
      for (let i in activeCarousalPost) {
        if (i == activeIndexPost) {
          const innerPost = activeCarousalPost[i].singlePostCommentList;
          for (let j in innerPost) {
            if (innerPost[j].comment_id == data.comment_id) {
              innerPost[j].singleReplyCommentPage =
                innerPost[j].singleReplyCommentPage + 1;
            }
          }
        }
      }
      const bodyParameter = {
        page: data.singleReplyCommentPage,
        post_id: data.post_id,
        file_id: data.file_id,
        comment_id: data.comment_id,
      };
      dispatch(singleUserPostCommentReplyListApi(bodyParameter));
    }
  };
  const handleOpenSinglereply = (i, item) => {
    let commentStyle = document.querySelectorAll(
      `#single-cmt-${item.file_id}-input-${item.comment_id}`
    )[activeIndexPost].style.display;
    if (commentStyle == "none") {
      document.querySelectorAll(
        `#single-cmt-${item.file_id}-input-${item.comment_id}`
      )[activeIndexPost].style.display = "flex";
    } else {
      document.querySelectorAll(
        `#single-cmt-${item.file_id}-input-${item.comment_id}`
      )[activeIndexPost].style.display = "none";
    }
  };
  const handleSingleOpenNested = (item) => {
    dispatch(
      changeUserPostActiveCarousal({ singleCommentId: item.comment_id })
    );
    let nestedComment = document.querySelectorAll(
      `#single-cmt-${item.file_id}-nested-${item.comment_id}`
    )[activeIndexPost].style.display;
    if (nestedComment == "none") {
      if (!singleReplyListLoading) {
        for (let i in activeCarousalPost) {
          if (i == activeIndexPost) {
            const innerPost = activeCarousalPost[i].singlePostCommentList;
            for (let j in innerPost) {
              if (innerPost[j].comment_id == item.comment_id) {
                innerPost[j].actualReplyComment = innerPost[j].reply;
                innerPost[j].singleReplyCommentPage =
                  innerPost[j].singleReplyCommentPage + 1;
                innerPost[j].showHide = true;
                forceUpdate();
              }
            }
          }
        }
        document.querySelectorAll(
          `#single-cmt-${item.file_id}-nested-${item.comment_id}`
        )[activeIndexPost].style.display = "block";
        const bodyParameter = {
          page: 1,
          post_id: item.post_id,
          file_id: item.file_id,
          comment_id: item.comment_id,
        };
        dispatch(singleUserPostCommentReplyListApi(bodyParameter));
      }
    } else {
      for (let i in activeCarousalPost) {
        if (i == activeIndexPost) {
          const innerPost = activeCarousalPost[i].singlePostCommentList;
          for (let j in innerPost) {
            if (innerPost[j].comment_id == item.comment_id) {
              innerPost[j].singleSubComment = [];
              innerPost[j].singleReplyCommentPage = 0;
              innerPost[j].showHide = false;
              forceUpdate();
            }
          }
        }
      }

      document.querySelectorAll(
        `#single-cmt-${item.file_id}-nested-${item.comment_id}`
      )[activeIndexPost].style.display = "none";
    }
  };
  const submitSingleNestedComment = (e, data, index) => {
    e.preventDefault();
    if (!!data.SingleReplyComment) {
      const bodyParameter = {
        post_id: data.post_id,
        file_id: data.file_id,
        comment_id: data.comment_id,
        comment: data.SingleReplyComment,
      };
      dispatch(singleUserPostCommentReplyApi(bodyParameter));
      for (let i in activeCarousalPost) {
        if (i == activeIndexPost) {
          const innerPost = activeCarousalPost[i].singlePostCommentList;
          for (let j in innerPost) {
            if (data.comment_id == innerPost[j].comment_id) {
              innerPost[j].SingleReplyComment = "";
              innerPost[j].reply = innerPost[j].reply + 1;
              forceUpdate();
            }
          }
        }
      }
    }
  };
  const handleOpenShareBox = (index, e) => {
    e.currentTarget.children[1].classList.toggle("single-post-share");
  };
  const handleShareSinglePostFeed = (data) => {
    for (let i in activeCarousalPost) {
      if (i == activeIndexPost) {
        if (activeCarousalPost[i].file_id == data.file_id) {
          activeCarousalPost[i].share_count =
            activeCarousalPost[i].share_count + 1;
          forceUpdate();
        }
      }
    }
    const bodyParameter = {
      post_id: data.post_id,
      file_id: data.file_id,
    };
    dispatch(singleUserPostShareApi(bodyParameter));
  };
  const handleSingleShareList = (data) => {
    if (!!data.share_count) {
      let singleShareDetail = {
        singleShareListpage: 1,
        sharePostId: data.post_id,
        shareFileId: data.file_id,
      };
      dispatch(changeUserShareListDetail(singleShareDetail));
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="post-modal"
        tabIndex={-1}
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content h-100">
            <div className="modal-body h-100">
              <button
                onClick={handleClose}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              {activeCarousalPost.length > 1 ? (
                <Carousel
                  useKeyboardArrows={true}
                  emulateTouch={true}
                  onChange={(index) => {
                    handleOnChange(index);
                  }}
                  showIndicators={false}
                  swipeable={true}
                  selectedItem={activeIndexPost}
                  showStatus={false}
                  showThumbs={false}
                  autoPlay={false}
                >
                  {activeCarousalPost.map((data, i) => {
                    return (
                      <div className="carousel-inner">
                        <div className="row h-100">
                          <div className="col-md-8">
                            <div className="large-img">
                              {data.extension == "mp4" ||
                                data.extension == "mov" ||
                                data.extension == "MP4" ||
                                data.extension == "MOV" ||
                                data.extension == "webm" ||
                                data.extension == "WEBM"

                                ? (
                                  <video
                                    onPlay={(e) => handlePlay(e)}
                                    id={"videoStop" + i}
                                    src={IMAGE_BASE_URL + data.file_name}
                                    controls
                                  />
                                ) : (
                                  <img
                                    onError={(ev) => addDefaultSrc(ev)}
                                    className="d-block w-100"
                                    src={
                                      !!data.file_name
                                        ? IMAGE_BASE_URL + data.file_name
                                        : returnDefaultImage()
                                    }
                                    alt="First slide"
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <h3 className="text-left text-capitalize">
                              {data.user_name}
                            </h3>
                            <div className="post-status d-flex align-items-center justify-content-between">
                              <div className="post__action d-flex align-items-center">
                                <div className="post__action__inner d-flex justify-content-between">
                                  <div className="single-reaction d-flex align-items-center">
                                    <div
                                      className="feed__reaction react-icon"
                                      onClick={(e) => handleOpenReact(e, i)}
                                    >
                                      <div className="react-open">
                                        {!!data.react_count ? (
                                          data.reaction_list.length > 0 && (
                                            <div className="reaction-listing">
                                              {data.reaction_list.map(
                                                (item) => {
                                                  return (
                                                    <div
                                                      className={
                                                        !!Number(
                                                          item.Reaction_check
                                                        )
                                                          ? "my-react"
                                                          : ""
                                                      }
                                                    >
                                                      {item.reaction == 1 ? (
                                                        <img
                                                          src="/assets/images/likepng1.png"
                                                          alt="Like"
                                                        />
                                                      ) : item.reaction == 2 ? (
                                                        <img
                                                          src="/assets/images/love.png"
                                                          alt="Love"
                                                        />
                                                      ) : item.reaction == 3 ? (
                                                        <img
                                                          src="/assets/images/clap.png"
                                                          alt="clab"
                                                        />
                                                      ) : item.reaction == 4 ? (
                                                        <img
                                                          src="/assets/images/celebrate.png"
                                                          alt="celebrate"
                                                        />
                                                      ) : item.reaction == 5 ? (
                                                        <img
                                                          src="/assets/images/think.png"
                                                          alt="Thinking"
                                                        />
                                                      ) : (
                                                        (item.reaction = 6 ? (
                                                          <img
                                                            src="/assets/images/upset.png"
                                                            alt="upset"
                                                          />
                                                        ) : (
                                                          ""
                                                        ))
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          )
                                        ) : (
                                          <i className="ri-emotion-line" />
                                        )}
                                      </div>
                                      <div
                                        className="react-box"
                                        id="carosal-react"
                                      >
                                        <a
                                          className={
                                            data.reaction == 1
                                              ? "last-rection"
                                              : ""
                                          }
                                          href="javascript:;"
                                          id="1"
                                          onClick={() => {
                                            handleSingleReact(1, data, i);
                                          }}
                                        >
                                          <img
                                            src="/assets/images/300x300.gif"
                                            alt="Like"
                                          />
                                        </a>
                                        <a
                                          className={
                                            data.reaction == 2
                                              ? "last-rection"
                                              : ""
                                          }
                                          href="javascript:;"
                                          id="2"
                                          onClick={() => {
                                            handleSingleReact(2, data, i);
                                          }}
                                        >
                                          <img
                                            src="/assets/images/love.gif"
                                            alt="Love"
                                          />
                                        </a>
                                        <a
                                          className={
                                            data.reaction == 3
                                              ? "last-rection"
                                              : ""
                                          }
                                          href="javascript:;"
                                          id="3"
                                          onClick={() => {
                                            handleSingleReact(3, data, i);
                                          }}
                                        >
                                          <img
                                            src="/assets/images/clap.gif"
                                            alt="clab"
                                          />
                                        </a>
                                        <a
                                          className={
                                            data.reaction == 4
                                              ? "last-rection"
                                              : ""
                                          }
                                          href="javascript:;"
                                          id="4"
                                          onClick={() => {
                                            handleSingleReact(4, data, i);
                                          }}
                                        >
                                          <img
                                            src="/assets/images/celebrate.gif"
                                            alt="celebrate"
                                          />
                                        </a>
                                        <a
                                          className={
                                            data.reaction == 5
                                              ? "last-rection"
                                              : ""
                                          }
                                          href="javascript:;"
                                          id="5"
                                          onClick={() => {
                                            handleSingleReact(5, data, i);
                                          }}
                                        >
                                          <img
                                            src="/assets/images/thinking.gif"
                                            alt="Thinking"
                                          />
                                        </a>
                                        <a
                                          className={
                                            data.reaction == 6
                                              ? "last-rection"
                                              : ""
                                          }
                                          href="javascript:;"
                                          id="6"
                                          onClick={() => {
                                            handleSingleReact(6, data, i);
                                          }}
                                        >
                                          <img
                                            src="/assets/images/upset.gif"
                                            alt="upset"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                    <a
                                      href="javascript:;"
                                      data-toggle="modal"
                                      data-target="#react-modal"
                                      onClick={() =>
                                        handleSingleReactList(data.file_id)
                                      }
                                    >
                                      {data.react_count > 0
                                        ? numFormatter(data.react_count)
                                        : ""}
                                    </a>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="single-react-model"
                                    >
                                      &nbsp;React
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="post-share-link"
                                  onClick={(e) => handleOpenShareBox(i, e)}
                                >
                                  <a
                                    href="javascript:;"
                                    className="btn-sm ml-auto link-txt"
                                  >
                                    <i className="ri-share-forward-line"></i>{" "}
                                    Share
                                  </a>
                                  <div
                                    className="share-box"
                                    id="carousal-share"
                                  >
                                    <ul className="list-unstyled mb-0">
                                      <li>
                                        <a
                                          href="javascript:;"
                                          onClick={() =>
                                            handleShareSinglePostFeed(data)
                                          }
                                          className="link-color"
                                        >
                                          <i className="ri-file-list-3-line" />{" "}
                                          Share In feed
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="post-status__comments d-flex">
                                <span>
                                  {numFormatter(data.comment_count)}{" "}
                                  <i className="ri-chat-3-line"></i>{" "}
                                </span>
                                <span
                                  data-toggle="modal"
                                  data-target={
                                    data.share_count > 0 ? "#share-modal" : ""
                                  }
                                  style={{
                                    cursor:
                                      data.share_count > 0
                                        ? "pointer"
                                        : "default",
                                  }}
                                  onClick={() => handleSingleShareList(data)}
                                >
                                  {numFormatter(data.share_count)}{" "}
                                  <i className="ri-share-forward-line"></i>
                                </span>
                                <span
                                  data-toggle="modal"
                                  style={{
                                    cursor:
                                      data.view_count > 0
                                        ? "pointer"
                                        : "default",
                                  }}
                                  onClick={() => singleViewListOpen(data)}
                                  data-target={
                                    data.view_count > 0 ? "#view-modal" : ""
                                  }
                                >
                                  {numFormatter(data.view_count)}{" "}
                                  <i className="ri-eye-line"></i>
                                </span>
                              </div>
                            </div>
                            {!!data.singlePostCommentList &&
                              !!data.singlePostCommentList?.length &&
                              data.singlePostCommentPage * 7 <
                              data.singleActualComment ? (
                              <small
                                className="load-more mt-2"
                                onClick={() =>
                                  handleSingleCommentPaginate(data, i)
                                }
                                style={{ cursor: "pointer" }} >
                                <i className="ri-download-line align-middle"></i>{" "}
                                Load More
                              </small>
                            ) : (
                              ""
                            )}
                            <Scrollbars
                              id={"scroll-single-post-" + data.file_id}
                              style={{ width: 350, height: 470 }}
                              autoHide
                            >
                              <div className="post_comment">
                                {!!data.singlePostCommentList?.length ?
                                  data.singlePostCommentList.map(
                                    (item, index) => {
                                      return (
                                        <div className="post_comment__listing my-3">
                                          <div className="post_comment__listing__user">
                                            {!!item.is_individual ? (
                                              <img
                                                onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/member-default.svg")
                                                }
                                                src={
                                                  !!item
                                                    ? IMAGE_BASE_URL +
                                                    item.image
                                                    : "/assets/images/member-default.svg"
                                                }
                                                alt="user"
                                              />
                                            ) : (
                                              <img
                                                onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/business_logo.svg")
                                                }
                                                src={
                                                  !!item
                                                    ? IMAGE_BASE_URL +
                                                    item.image
                                                    : "/assets/images/business_logo.svg"
                                                }
                                                alt="logo"
                                              />
                                            )}
                                          </div>
                                          <div className="post_comment__listing__review">
                                            <div className="comment-bg">
                                              <p className="mb-1">
                                                {item.comment}
                                              </p>
                                            </div>
                                            <div className="view-reply">
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) =>
                                                  handleOpenSinglereply(
                                                    index,
                                                    item
                                                  )
                                                }
                                              >
                                                <i className="ri-reply-line" />{" "}
                                                Reply
                                              </span>
                                              {!!item.reply ? (
                                                <span
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleSingleOpenNested(item)
                                                  }
                                                  className="open-reply"
                                                >
                                                  <i className="ri-eye-line" />
                                                  {!!item.showHide
                                                    ? "Hide"
                                                    : "View"}{" "}
                                                  {item.reply} Reply
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div
                                              style={{ display: "none" }}
                                              id={`single-cmt-${item.file_id}-nested-${item.comment_id}`}
                                            >
                                              {!!item.singleSubComment &&
                                                !!item.singleSubComment.length >
                                                0 &&
                                                item.singleReplyCommentPage * 3 <
                                                item.actualReplyComment ? (
                                                <small
                                                  style={{ cursor: "pointer" }}
                                                  className="d-block text-center mt-1"
                                                  onClick={() =>
                                                    handleReplySingleCmtPaginate(
                                                      item,
                                                      i
                                                    )
                                                  }
                                                >
                                                  View{" "}
                                                  {item.reply -
                                                    item.singleSubComment
                                                      .length}{" "}
                                                  pervious replies
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                              {!!item.singleSubComment &&
                                                item.singleSubComment.map(
                                                  (subComment) => {
                                                    return (
                                                      <div className="post_comment__listing post__comment__nested ">
                                                        <div className="post_comment__listing__user">
                                                          {!!subComment.is_individual ? (
                                                            <img
                                                              onError={(ev) =>
                                                              (ev.target.src =
                                                                "/assets/images/member-default.svg")
                                                              }
                                                              src={
                                                                !!subComment
                                                                  ? IMAGE_BASE_URL +
                                                                  subComment.image
                                                                  : "/assets/images/member-default.svg"
                                                              }
                                                              alt="user"
                                                            />
                                                          ) : (
                                                            <img
                                                              onError={(ev) =>
                                                              (ev.target.src =
                                                                "/assets/images/business_logo.svg")
                                                              }
                                                              src={
                                                                !!subComment
                                                                  ? IMAGE_BASE_URL +
                                                                  subComment.image
                                                                  : "/assets/images/business_logo.svg"
                                                              }
                                                              alt="logo"
                                                            />
                                                          )}
                                                        </div>
                                                        <div className="post_comment__listing__commentbox">
                                                          <div className="comment-bg">
                                                            <p>
                                                              {
                                                                subComment.comment
                                                              }
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                            <div
                                              style={{ display: "none" }}
                                              id={`single-cmt-${item.file_id}-input-${item.comment_id}`}
                                              className="post_comment__listing post__comment__nested"
                                            >
                                              <div className="post_comment__listing__user">
                                                {!!data.is_individual ? (
                                                  <img
                                                    onError={(ev) =>
                                                    (ev.target.src =
                                                      "/assets/images/member-default.svg")
                                                    }
                                                    src={
                                                      !!data
                                                        ? IMAGE_BASE_URL +
                                                        data.profile_image
                                                        : "/assets/images/member-default.svg"
                                                    }
                                                    alt="user"
                                                  />
                                                ) : (
                                                  <img
                                                    onError={(ev) =>
                                                    (ev.target.src =
                                                      "/assets/images/business_logo.svg")
                                                    }
                                                    src={
                                                      !!data
                                                        ? IMAGE_BASE_URL +
                                                        data.business_image
                                                        : "/assets/images/business_logo.svg"
                                                    }
                                                    alt="logo"
                                                  />
                                                )}{" "}
                                              </div>
                                              <div className="post_comment__listing__commentbox">
                                                <form
                                                  autoComplete="off"
                                                  onSubmit={(e) =>
                                                    submitSingleNestedComment(
                                                      e,
                                                      item,
                                                      i
                                                    )
                                                  }
                                                >
                                                  <Input
                                                    type="text"
                                                    name="SingleReplyComment"
                                                    onChange={(e) =>
                                                      changeSingleNestedComment(
                                                        e,
                                                        item.comment_id
                                                      )
                                                    }
                                                    value={
                                                      item.SingleReplyComment
                                                    }
                                                    className="form-control"
                                                    placeholder="Write a comment"
                                                  />
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  ) : !singleCommentListLoading && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                                    <span>There are no comments.</span>
                                  </div>}
                              </div>

                              {!!data.singlePostCommentList &&
                                data.singlePostCommentList?.length <= 0 && (
                                  <ClipLoader
                                    color={"#212529"}
                                    loading={
                                      !!singleCommentListLoading ? true : false
                                    }
                                    css={override}
                                  />
                                )}
                            </Scrollbars>
                            <div className="post__commtent__user">
                              <div className="feeds-post__header d-flex flex-wrap align-items-center">
                                <div className="feeds-post__user">
                                  {!!data.is_individual ? (
                                    <img
                                      onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/member-default.svg")
                                      }
                                      src={
                                        !!data
                                          ? IMAGE_BASE_URL + data.profile_image
                                          : "/assets/images/member-default.svg"
                                      }
                                      alt="user"
                                    />
                                  ) : (
                                    <img
                                      onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/business_logo.svg")
                                      }
                                      src={
                                        !!data
                                          ? IMAGE_BASE_URL + data.business_image
                                          : "/assets/images/business_logo.svg"
                                      }
                                      alt="logo"
                                    />
                                  )}
                                </div>
                                <div className="feeds-post__comment">
                                  <form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      handleSumbitSingleComment(e, data, i);
                                    }}
                                    autoComplete="off"
                                  >
                                    <Input
                                      type="text"
                                      name="singlePostComment"
                                      value={data.singlePostComment}
                                      onChange={(e) =>
                                        handleSinglePostComment(e, data.file_id)
                                      }
                                      className="form-control"
                                      placeholder="Write a comment"
                                    />
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              ) : (
                activeCarousalPost.map((data, i) => {
                  return (
                    <div className="carousel-inner">
                      <div className="row">
                        <div className="col-lg-8 col-sm-12">
                          <div className="large-img">
                            {data.extension == "mp4" ||
                              data.extension == "mov" ||
                              data.extension == "MP4" ||
                              data.extension == "MOV" ||
                              data.extension == "webm" ||
                              data.extension == "WEBM"

                              ? (
                                <video
                                  onPlay={(e) => handlePlay(e)}
                                  id={"videoStop" + i}
                                  src={IMAGE_BASE_URL + data.file_name}
                                  controls
                                />
                              ) : (
                                <img
                                  onError={(ev) => addDefaultSrc(ev)}
                                  className="d-block w-100"
                                  src={
                                    !!data.file_name
                                      ? IMAGE_BASE_URL + data.file_name
                                      : returnDefaultImage()
                                  }
                                  alt="First slide"
                                />
                              )}
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                          <h3 className="text-left  text-capitalize">
                            {data.user_name}
                          </h3>

                          <div className="post-status d-flex align-items-center justify-content-between">
                            <div className="post__action d-flex align-items-center">
                              <div className="post__action__inner d-flex justify-content-between">
                                <div className="single-reaction d-flex align-items-center">
                                  <div
                                    className="feed__reaction react-icon"
                                    onClick={(e) => handleOpenReact(e, i)}
                                  >
                                    <div className="react-open">
                                      {" "}
                                      {!!data.react_count ? (
                                        data.reaction_list.length > 0 && (
                                          <div className="reaction-listing">
                                            {data.reaction_list.map((item) => {
                                              return (
                                                <div
                                                  className={
                                                    !!Number(
                                                      item.Reaction_check
                                                    )
                                                      ? "my-react"
                                                      : ""
                                                  }
                                                >
                                                  {item.reaction == 1 ? (
                                                    <img
                                                      src="/assets/images/likepng1.png"
                                                      alt="Like"
                                                    />
                                                  ) : item.reaction == 2 ? (
                                                    <img
                                                      src="/assets/images/love.png"
                                                      alt="Love"
                                                    />
                                                  ) : item.reaction == 3 ? (
                                                    <img
                                                      src="/assets/images/clap.png"
                                                      alt="clab"
                                                    />
                                                  ) : item.reaction == 4 ? (
                                                    <img
                                                      src="/assets/images/celebrate.png"
                                                      alt="celebrate"
                                                    />
                                                  ) : item.reaction == 5 ? (
                                                    <img
                                                      src="/assets/images/think.png"
                                                      alt="Thinking"
                                                    />
                                                  ) : (
                                                    (item.reaction = 6 ? (
                                                      <img
                                                        src="/assets/images/upset.png"
                                                        alt="upset"
                                                      />
                                                    ) : (
                                                      ""
                                                    ))
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )
                                      ) : (
                                        <i className="ri-emotion-line" />
                                      )}{" "}
                                    </div>
                                    <div
                                      className="react-box"
                                      id="carosal-react"
                                    >
                                      <a
                                        className={
                                          data.reaction == 1
                                            ? "last-rection"
                                            : ""
                                        }
                                        href="javascript:;"
                                        id="1"
                                        onClick={() => {
                                          handleSingleReact(1, data, i);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/300x300.gif"
                                          alt="Like"
                                        />
                                      </a>
                                      <a
                                        className={
                                          data.reaction == 2
                                            ? "last-rection"
                                            : ""
                                        }
                                        href="javascript:;"
                                        id="2"
                                        onClick={() => {
                                          handleSingleReact(2, data, i);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/love.gif"
                                          alt="Love"
                                        />
                                      </a>
                                      <a
                                        className={
                                          data.reaction == 3
                                            ? "last-rection"
                                            : ""
                                        }
                                        href="javascript:;"
                                        id="3"
                                        onClick={() => {
                                          handleSingleReact(3, data, i);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/clap.gif"
                                          alt="clab"
                                        />
                                      </a>
                                      <a
                                        className={
                                          data.reaction == 4
                                            ? "last-rection"
                                            : ""
                                        }
                                        href="javascript:;"
                                        id="4"
                                        onClick={() => {
                                          handleSingleReact(4, data, i);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/celebrate.gif"
                                          alt="celebrate"
                                        />
                                      </a>
                                      <a
                                        className={
                                          data.reaction == 5
                                            ? "last-rection"
                                            : ""
                                        }
                                        href="javascript:;"
                                        id="5"
                                        onClick={() => {
                                          handleSingleReact(5, data, i);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/thinking.gif"
                                          alt="Thinking"
                                        />
                                      </a>
                                      <a
                                        className={
                                          data.reaction == 6
                                            ? "last-rection"
                                            : ""
                                        }
                                        href="javascript:;"
                                        id="6"
                                        onClick={() => {
                                          handleSingleReact(6, data, i);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/upset.gif"
                                          alt="upset"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <a
                                    href="javascript:;"
                                    data-toggle="modal"
                                    data-target="#react-modal"
                                    onClick={() =>
                                      handleSingleReactList(data.file_id)
                                    }
                                  >
                                    {data.react_count > 0
                                      ? data.react_count
                                      : ""}
                                  </a>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="single-react-model"
                                  >
                                    &nbsp;React
                                  </span>
                                </div>
                              </div>
                              <div
                                className="post-share-link"
                                onClick={(e) => handleOpenShareBox(i, e)}
                              >
                                <a
                                  href="javascript:;"
                                  className="btn-sm ml-auto link-txt"
                                >
                                  <i className="ri-share-forward-line"></i>{" "}
                                  Share
                                </a>
                                <div className="share-box" id="single-share">
                                  <ul className="list-unstyled mb-0">
                                    <li>
                                      <a
                                        href="javascript:;"
                                        onClick={() =>
                                          handleShareSinglePostFeed(data)
                                        }
                                        className="link-color"
                                      >
                                        <i className="ri-file-list-3-line" />{" "}
                                        Share In feed
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="post-status__comments d-flex">
                              <span>
                                {numFormatter(data.comment_count)}{" "}
                                <i className="ri-chat-3-line"></i>
                              </span>
                              <span
                                data-toggle="modal"
                                data-target={
                                  data.share_count > 0 ? "#share-modal" : ""
                                }
                                onClick={() => handleSingleShareList(data)}
                              >
                                {numFormatter(data.share_count)}{" "}
                                <i className="ri-share-forward-line"></i>
                              </span>
                              <span
                                onClick={() => singleViewListOpen(data)}
                                data-toggle="modal"
                                style={{
                                  cursor:
                                    data.view_count > 0 ? "pointer" : "default",
                                }}
                                data-target={
                                  data.view_count > 0 ? "#view-modal" : ""
                                }
                              >
                                {numFormatter(data.view_count)}{" "}
                                <i className="ri-eye-line"></i>
                              </span>
                            </div>
                          </div>
                          {!!data.singlePostCommentList &&
                            !!data.singlePostCommentList?.length &&
                            data.singlePostCommentPage * 7 <
                            data.singleActualComment ? (
                            <small
                              className="load-more"
                              onClick={() =>
                                handleSingleCommentPaginate(data, i)
                              }
                              style={{ cursor: "pointer" }}>
                              <i className="ri-download-line align-middle"></i>{" "}
                              Load More{" "}
                            </small>
                          ) : (
                            ""
                          )}
                          <Scrollbars
                            id={"scroll-single-post-" + data.file_id}
                            style={{ width: 350, height: 470 }}
                            autoHide
                          >
                            <div className="post_comment">
                              {!!data.singlePostCommentList?.length ?
                                data.singlePostCommentList.map(
                                  (item, index) => {
                                    return (
                                      <div className="post_comment__listing my-3">
                                        <div className="post_comment__listing__user">
                                          {!!item.is_individual ? (
                                            <img
                                              onError={(ev) =>
                                              (ev.target.src =
                                                "/assets/images/member-default.svg")
                                              }
                                              src={
                                                !!item
                                                  ? IMAGE_BASE_URL + item.image
                                                  : "/assets/images/member-default.svg"
                                              }
                                              alt="user"
                                            />
                                          ) : (
                                            <img
                                              onError={(ev) =>
                                              (ev.target.src =
                                                "/assets/images/business_logo.svg")
                                              }
                                              src={
                                                !!item
                                                  ? IMAGE_BASE_URL + item.image
                                                  : "/assets/images/business_logo.svg"
                                              }
                                              alt="logo"
                                            />
                                          )}
                                        </div>
                                        <div className="post_comment__listing__review">
                                          <div className="comment-bg">
                                            <p className="mb-1">
                                              {item.comment}
                                            </p>
                                          </div>
                                          <div className="view-reply">
                                            <span
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) =>
                                                handleOpenSinglereply(
                                                  index,
                                                  item
                                                )
                                              }
                                            >
                                              <i className="ri-reply-line" />{" "}
                                              Reply
                                            </span>
                                            {!!item.reply ? (
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleSingleOpenNested(item)
                                                }
                                                className="open-reply"
                                              >
                                                <i className="ri-eye-line" />
                                                {!!item.showHide
                                                  ? "Hide"
                                                  : "View"}{" "}
                                                {item.reply} reply
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div
                                            style={{ display: "none" }}
                                            id={`single-cmt-${item.file_id}-nested-${item.comment_id}`}
                                          >
                                            {!!item.singleSubComment &&
                                              !!item.singleSubComment.length >
                                              0 &&
                                              item.singleReplyCommentPage * 3 <
                                              item.actualReplyComment ? (
                                              <small
                                                style={{ cursor: "pointer" }}
                                                className="d-block text-center mt-1"
                                                onClick={() =>
                                                  handleReplySingleCmtPaginate(
                                                    item,
                                                    i
                                                  )
                                                }
                                              >
                                                View{" "}
                                                {item.reply -
                                                  item.singleSubComment
                                                    .length}{" "}
                                                pervious replies
                                              </small>
                                            ) : (
                                              ""
                                            )}
                                            {!!item.singleSubComment &&
                                              item.singleSubComment.map(
                                                (subComment) => {
                                                  return (
                                                    <div className="post_comment__listing post__comment__nested ">
                                                      <div className="post_comment__listing__user">
                                                        {!!subComment.is_individual ? (
                                                          <img
                                                            onError={(ev) =>
                                                            (ev.target.src =
                                                              "/assets/images/member-default.svg")
                                                            }
                                                            src={
                                                              !!subComment
                                                                ? IMAGE_BASE_URL +
                                                                subComment.image
                                                                : "/assets/images/member-default.svg"
                                                            }
                                                            alt="user"
                                                          />
                                                        ) : (
                                                          <img
                                                            onError={(ev) =>
                                                            (ev.target.src =
                                                              "/assets/images/business_logo.svg")
                                                            }
                                                            src={
                                                              !!subComment
                                                                ? IMAGE_BASE_URL +
                                                                subComment.image
                                                                : "/assets/images/business_logo.svg"
                                                            }
                                                            alt="logo"
                                                          />
                                                        )}
                                                      </div>
                                                      <div className="post_comment__listing__commentbox">
                                                        <div className="comment-bg">
                                                          <p>
                                                            {subComment.comment}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                          <div
                                            style={{ display: "none" }}
                                            id={`single-cmt-${item.file_id}-input-${item.comment_id}`}
                                            className="post_comment__listing post__comment__nested"
                                          >
                                            <div className="post_comment__listing__user">
                                              {!!data.is_individual ? (
                                                <img
                                                  onError={(ev) =>
                                                  (ev.target.src =
                                                    "/assets/images/member-default.svg")
                                                  }
                                                  src={
                                                    !!data
                                                      ? IMAGE_BASE_URL +
                                                      data.profile_image
                                                      : "/assets/images/member-default.svg"
                                                  }
                                                  alt="user"
                                                />
                                              ) : (
                                                <img
                                                  onError={(ev) =>
                                                  (ev.target.src =
                                                    "/assets/images/business_logo.svg")
                                                  }
                                                  src={
                                                    !!data
                                                      ? IMAGE_BASE_URL +
                                                      data.business_image
                                                      : "/assets/images/business_logo.svg"
                                                  }
                                                  alt="logo"
                                                />
                                              )}{" "}
                                            </div>
                                            <div className="post_comment__listing__commentbox">
                                              <form
                                                autoComplete="off"
                                                onSubmit={(e) =>
                                                  submitSingleNestedComment(
                                                    e,
                                                    item,
                                                    i
                                                  )
                                                }
                                              >
                                                <Input
                                                  type="text"
                                                  name="SingleReplyComment"
                                                  value={
                                                    item.SingleReplyComment
                                                  }
                                                  onChange={(e) =>
                                                    changeSingleNestedComment(
                                                      e,
                                                      item.comment_id
                                                    )
                                                  }
                                                  className="form-control"
                                                  placeholder="Write a comment"
                                                />
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                ) :
                                !singleCommentListLoading && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                                  <span>There are no comments.</span>
                                </div>}
                            </div>
                            {!!data.singlePostCommentList?.length &&
                              data.singlePostCommentList?.length <= 0 && (
                                <ClipLoader
                                  color={"#212529"}
                                  loading={
                                    !!singleCommentListLoading ? true : false
                                  }
                                  css={override}
                                />
                              )}
                          </Scrollbars>
                          <div className="post__commtent__user">
                            <div className="feeds-post__header d-flex flex-wrap align-items-center">
                              <div className="feeds-post__user">
                                {!!data.is_individual ? (
                                  <img
                                    onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/member-default.svg")
                                    }
                                    src={
                                      !!data
                                        ? IMAGE_BASE_URL + data.profile_image
                                        : "/assets/images/member-default.svg"
                                    }
                                    alt="user"
                                  />
                                ) : (
                                  <img
                                    onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/business_logo.svg")
                                    }
                                    src={
                                      !!data
                                        ? IMAGE_BASE_URL + data.business_image
                                        : "/assets/images/business_logo.svg"
                                    }
                                    alt="logo"
                                  />
                                )}
                              </div>
                              <div className="feeds-post__comment">
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSumbitSingleComment(e, data, i);
                                  }}
                                  autoComplete="off"
                                >
                                  <Input
                                    type="text"
                                    name="singlePostComment"
                                    value={data.singlePostComment}
                                    onChange={(e) =>
                                      handleSinglePostComment(e, data.file_id)
                                    }
                                    className="form-control"
                                    placeholder="Write a comment"
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SingleProfilePost;
