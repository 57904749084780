const RESET_PASSWORD_INPUT = "RESET_PASSWORD_INPUT";

const CLEAR_RESET_PASSWORD_INPUT = "CLEAR_RESET_PASSWORD_INPUT";

const RESET_PASSWORD_DATA_REQUEST = "RESET_PASSWORD_DATA_REQUEST";
const RESET_PASSWORD_DATA_SUCCESS = "RESET_PASSWORD_DATA_SUCCESS";
const RESET_PASSWORD_DATA_FAILURE = "RESET_PASSWORD_DATA_FAILURE";

const CLEAR_RESET_API_RESPONSE = "CLEAR_RESET_API_RESPONSE";

export {
  RESET_PASSWORD_INPUT,
  CLEAR_RESET_PASSWORD_INPUT,
  RESET_PASSWORD_DATA_REQUEST,
  RESET_PASSWORD_DATA_SUCCESS,
  RESET_PASSWORD_DATA_FAILURE,
  CLEAR_RESET_API_RESPONSE,
};
