import React, { useEffect, useState } from "react";
import Footer from "./HomeSection/Footer";
import "../LandingPage/HomeSection/landingpage.css";
import { useSelector } from "react-redux";

import parse from "html-react-parser";

import { IMAGE_BASE_URL } from "../../../urls";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
export default function Aboutpage() {
  const [loading, setLoading] = useState(true);
  const aboutUsContent = useSelector(
    (state) => state.aboutUsPageReducer?.aboutUsPageContent
  );
  const { getAboutUsContentData } = aboutUsContent;

  useEffect(() => {
    AOS.init({
      duration: 900,
    });
    AOS.refresh();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!!getAboutUsContentData?.data) {
      setLoading(false);
    }
  }, [getAboutUsContentData]);




  return (
    <>
      {loading === false && (
        <div className="landing-wrapper">
          <section className="spacer about-page hero body-spacer">
            <div className="container">
              <div className="row" data-aos="fade-up">
                <div className="col-md-9 mx-auto text-center">
                  <h1 className="font-weight-bold">
                    {getAboutUsContentData?.data?.first_word_heading}{" "}
                    <span className="theme-text-primary">
                      {getAboutUsContentData?.data?.second_word_heading}
                    </span>{" "}
                    <span className="theme-text-secondary">
                      {getAboutUsContentData?.data?.third_word_heading}
                    </span>
                  </h1>
                  <p className="mb-0">{getAboutUsContentData?.data?.title}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="spacer theme-primary-bg text-white section-bright about-detail">
            <div className="container">
              <div className="col-md-12" data-aos="fade-up">
                {!!getAboutUsContentData?.data &&
                  parse(getAboutUsContentData?.data?.description)}
                <Link className="btn mt-3" to="/signup">
                  Get Started
                </Link>
              </div>
            </div>
          </section>

          <section className="spacer bg-light-grey about-other-details">
            <div className="container">
              <div className="row" data-aos="fade-up">
                <div className="col-md-6">
                  {getAboutUsContentData?.data && (
                    <img
                      src={
                        IMAGE_BASE_URL + getAboutUsContentData?.data?.left_image
                      }
                      className="br-20"
                      alt="idea"
                    />
                  )}
                </div>
                <div className="col-md-6">
                  <h2 className="font-weight-bold mb-3">
                    {getAboutUsContentData?.data?.right_side_heading}
                  </h2>

                  <p className="f-18">
                    {getAboutUsContentData?.data &&
                      parse(getAboutUsContentData?.data.right_side_description)}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      )}
    </>
  );
}
