import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllServicesProvider } from "./landingContentAction";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IMAGE_BASE_URL } from "../../../urls";
import { getUniqueItemOfArray } from "../../../utilities/functions";

const ProviderService = ({ searchClick, setSearchPage, searchPage }) => {
  const dispatch = useDispatch();
  const serviceProvidersList = useSelector(
    (state) => state.serviceProviderReducer
  );

  const {
    getServicesProviderData: { getServicesProviderList },
    getSearchProviderData: { getSearchProviderList },
  } = serviceProvidersList;

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProvidersCount, setTotalProvidersCount] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [proivdersList, setProvidersList] = useState([]);
  const [showSearchList, setShowSearchList] = useState([]);

  // change page on view more //
  const handleViewMore = () => {
    if (searchClick) {
      setSearchPage(searchPage + 1);
    } else {
      if (currentPage !== totalPage) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  // when current page is change hit api //
  useEffect(() => {
    if (!searchClick) {
      try {
        dispatch(getAllServicesProvider(currentPage))
          .then((result) => {
            setTotalProvidersCount(result.response?.data?.total);
          })
          .catch((err) => {
            throw err;
          });
      } catch (err) {
        return err;
      }
    }
  }, [currentPage]);

  // get total page if totalProvidersCount have any count//
  useEffect(() => {
    setTotalPage(Math.ceil(totalProvidersCount / 9));
  }, [totalProvidersCount]);

  // get item of provider list from redux state  and store in component's state//
  useEffect(() => {
    if (getServicesProviderList?.length > 0) {
      const uniqueArr = getUniqueItemOfArray(getServicesProviderList);
      setProvidersList(uniqueArr);
    }
  }, [getServicesProviderList]);

  // clear the searchList state whenever navigation path is service-provider //
  useEffect(() => {
    if (history.action === "PUSH") {
      setShowSearchList([]);
    }
  }, []);

  // store previous search list and new data of search list in new state //
  useEffect(() => {
    setShowSearchList([...showSearchList, ...getSearchProviderList]);
  }, [getSearchProviderList]);

  // get unique item in search list array and store in state//
  useEffect(() => {
    if (showSearchList?.length > 0) {
      const uniqueArr = getUniqueItemOfArray(showSearchList);
      setProvidersList(uniqueArr);
    }
  }, [showSearchList]);

  return (
    <section className="spacer service-provider">
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-12">
            <h2 className="font-weight-bold">Services provider</h2>
          </div>
        </div>
        {/* services provider start */}
        <div className="row">
          <div className="col-md-12">
            <div className="service-provider__grid">
              {proivdersList.map((item, index) => (
                <div key={index} className="service-provider__item">
                  {item?.is_business ? (
                    <img
                      onClick={() =>
                        history.push(
                          `/search-user/${item?.id}
`
                        )
                      }
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/member-default.svg")
                      }
                      src={
                        item?.business_image !== null
                          ? IMAGE_BASE_URL + item.business_image
                          : "/assets/images/member-default.svg"
                      }
                      alt={item.is_business ? item.business_name : item.name}
                    />
                  ) : (
                    <img
                      onClick={() =>
                        history.push(
                          `/search-user/${item?.id}
    `
                        )
                      }
                      onError={(ev) =>
                        (ev.target.src = "/assets/images/member-default.svg")
                      }
                      src={
                        item?.profile_image !== null
                          ? IMAGE_BASE_URL + item.profile_image
                          : "/assets/images/member-default.svg"
                      }
                      alt={item.is_business ? item.business_name : item.name}
                    />
                  )}

                  <div>
                    <span
                      onClick={() =>
                        history.push(
                          `/search-user/${item?.id}
`
                        )
                      }
                      className="h5 mb-0 cursor-pointer"
                    >
                      {item.is_business ? item.business_name : item.name}
                    </span>
                    <span className="small cursor-pointer">
                      {item?.getAllServices?.length > 0 &&
                        item?.getAllServices[0]}
                    </span>
                    <Link to={"/login"} className="btn btn-sm btn-blue mt-3">
                      Connect
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* services provider end */}
        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <button className="btn btn-primary" onClick={handleViewMore}>
              {searchClick
                ? `View more`
                : `${currentPage === totalPage ? "No More Data" : "View more"}`}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProviderService;
