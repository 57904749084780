import React from "react";
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { changeGeneralInfoInput } from "../../../../modules/Account/AccountAction";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const BecomeSeller = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const AccountStateData = useSelector((state) => state.AccountReducer);
  const {
    getProfileApi: { getProfileResponse },
  } = AccountStateData;


  const handleSellerSteps = () => {

    history.push("/become-seller-steps");
    window.setTimeout(() => {
      let generaldata = {
        skills: !!getProfileResponse.skills
          ? getProfileResponse.skills.map((data) => {
            return { label: data.skill_name, value: data.id };
          })
          : [],
      };
      dispatch(
        changeGeneralInfoInput({
          ...AccountStateData.GeneralInput,
          ...generaldata,
        })
      )
    }, 0);
  };
  return (
    <>
      <section className="spacer instruction">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-sm-12 mx-auto">
              <h1 className="h2">
                Stand out on PoolsMagnic by building your business profile.
              </h1>
              <p>
                Convert those searching for you into new customers. Take your
                time out to add accurate information about you.
              </p>
              <div className="instruction__wrap">
                <div className="instruction__wrap__inner">
                  <div className="instruction__logo">
                    <img src="/assets/images/information.png" />

                  </div>
                  <div className="instruction__content">
                    <p>
                      <strong>Overview:</strong> Add general Info about your
                      business.
                    </p>
                  </div>
                </div>

                <div className="instruction__wrap__inner">
                  <div className="instruction__logo">
                    <img src="/assets/images/key-skills.png" />
                  </div>
                  <div className="instruction__content">
                    {" "}
                    <p>
                      <strong>Skills &amp; Expertise:</strong> Set up your scope
                      of work to your target audience.
                    </p>{" "}
                  </div>
                </div>
                <div className="instruction__wrap__inner">
                  <div className="instruction__logo">
                    <img src="/assets/images/schedule.png" />
                  </div>
                  <div className="instruction__content">
                    {" "}
                    <p>
                      <strong>Price &amp; Time:</strong> Price your services and
                      availability for service.
                    </p>
                  </div>
                </div>
              </div>
              <p>
                This Process will take 5 to 10 minutes. You will be able to save
                the information as you exit, So, You can always edit it later.
              </p>
              <div className=" d-flex justify-content-end">
                <button
                  className="mt-4 btn btn-primary "
                  onClick={handleSellerSteps}
                >
                  Get Started
                </button>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BecomeSeller;
