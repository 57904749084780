import {
  CHANGE_SEARCH_NAME_INPUT,
  CHANGE_UNREAD_FRIEND_MESSAGE,
  ONSCREEN_NOTIFICATION_DATA_FAILURE,
  ONSCREEN_NOTIFICATION_DATA_REQUEST,
  CHANGE_UNREAD_GROUP_MESSAGE,
  ONSCREEN_NOTIFICATION_DATA_SUCCESS,
  READALL_NOTIFICATION_DATA_FAILURE,
  READALL_NOTIFICATION_DATA_REQUEST,
  READALL_NOTIFICATION_DATA_SUCCESS,
  CHNAGE_SEARCH_MEMBERS_NAME_INPUT,
  UNREAD_USER_MESSAGE,
} from "./ToolbarConstants";

const initialState = {
  searchNameInput: {
    reduxSearchName: "",
    onChangeSearchName: "",
  },
  searchMembersNameInput: {
    reduxSearchMembers: "",
    onChangeSearchMembers: "",
  },
  onScreenNotificationApi: {
    onScreenNotificationLoading: false,
    onScreenNotification: [],
    onScreenNotificationStatus: "",
    allNotificationCount: "",
    unReadCount: "",
  },
  readAllApi: {
    readAllLoading: false,
    readAllStatus: "",
    readAllMessage: "",
  },
  unreadFriendMessage: [],
  unreadGroupMessage: [],
  unreadUserMessageCount:[],
};
export const ToolbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SEARCH_NAME_INPUT:
      return {
        ...state,
        ...{
          searchNameInput: { ...state.searchNameInput, ...action.newState },
        },
      };

    case CHNAGE_SEARCH_MEMBERS_NAME_INPUT:
      return {
        ...state,
        ...{
          searchMembersNameInput: {
            ...state.searchMembersNameInput,
            ...action.newState,
          },
        },
      };
    case ONSCREEN_NOTIFICATION_DATA_REQUEST:
      return {
        ...state,
        ...{
          onScreenNotificationApi: {
            ...state.onScreenNotificationApi,
            ...{ onScreenNotificationLoading: true },
          },
        },
      };
    case ONSCREEN_NOTIFICATION_DATA_SUCCESS:
      const {
        response: {
          data: { data, status, total, unread },
        },
      } = action;
      let notiSuccess = {
        onScreenNotificationLoading: false,
        onScreenNotificationStatus: status,
        onScreenNotification: !!data ? data : [],
        allNotificationCount: total,
        unReadCount: unread,
      };
      return {
        ...state,
        ...{
          onScreenNotificationApi: {
            ...state.onScreenNotificationApi,
            ...notiSuccess,
          },
        },
      };
    case ONSCREEN_NOTIFICATION_DATA_FAILURE:
      let notificationFailure = {
        onScreenNotificationLoading: false,
        onScreenNotificationStatus: action.response.status,
        onScreenNotification: [],
        allNotificationCount: "",
        unReadCount: "",
      };
      return {
        ...state,
        ...{
          onScreenNotificationApi: {
            ...state.onScreenNotificationApi,
            ...notificationFailure,
          },
        },
      };
    case READALL_NOTIFICATION_DATA_REQUEST:
      return {
        ...state,
        ...{ readAllApi: { ...state.readAllApi, ...{ readAllLoading: true } } },
      };
    case READALL_NOTIFICATION_DATA_SUCCESS:
      const { response } = action;
      let readAllSuccess = {
        readAllLoading: false,
        readAllStatus: response.data.status,
        readAllMessage: response.data.message,
      };
      return {
        ...state,
        ...{
          readAllApi: { ...state.readAllApi, ...readAllSuccess },
        },
      };
    case READALL_NOTIFICATION_DATA_FAILURE:
      let notifFailure = {
        readAllLoading: false,
        readAllStatus: action.response.status,
        readAllMessage: "",
      };
      return {
        ...state,
        ...{
          readAllApi: { ...state.readAllApi, ...notifFailure },
        },
      };
    case CHANGE_UNREAD_FRIEND_MESSAGE:
      return {
        ...state,
        ...action.newState,
      };

    case CHANGE_UNREAD_GROUP_MESSAGE:
      return {
        ...state,
        ...action.newState,
      };

      case UNREAD_USER_MESSAGE:
        return{
          ...state,
          ...action.newState
        }
    default:
      return state;
  }
};
