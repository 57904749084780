import React, { useEffect, useState } from "react";
import { IMAGE_BASE_URL } from "../../../urls";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogsApi } from "./landingContentAction";
export default function Allblogs() {
  const [bolgsData, setBlogsData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const allBlogPageContent = useSelector((state) => state.blogPageReducer);
  const totalPage = Math.ceil(bolgsData?.total / bolgsData?.per_page);
  const { allBlogsData, searchBlogsDetails } = allBlogPageContent;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllBlogsApi(currentPage));
  }, [currentPage]);

  useEffect(() => {
    if (!!allBlogsData) {
      setBlogsData(allBlogsData?.getAllBlogsData);
    }
  }, [allBlogsData?.getAllBlogsData]);

  useEffect(() => {
    if (!!searchBlogsDetails) {
      setBlogsData(searchBlogsDetails?.getSearchBlogData);
    }
  }, [searchBlogsDetails]);
  const handlePagination = (data, item) => {
    if (data === "prev") {
      if (currentPage > 1) setCurrentPage(currentPage - 1);
    } else if (data === "next") {
      if (currentPage < totalPage) setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(item);
    }
  };
  // FOR PAGINATION RANGE
  let page = [];
  for (let i = 1; i <= totalPage; i++) {
    page.push(i);
  }
  
  return (
    <div>
      <div>
        <section id="scroollId" className="spacer all-blog-posts">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="h4 font-weight-bold mb-4">All Blog Posts</h2>
              </div>
            </div>

            {!!bolgsData?.list && bolgsData?.list?.length == 0 ? (
              <div className="card text-center p-3 border bg-transparent">
                <h4 className="mb-0">No result found</h4>
              </div>
            ) : (
              <>
                <div className="row custom-gap">
                  {!!bolgsData?.list &&
                    bolgsData?.list.map((item, index) => (
                      <div className="col-sm-6 col-md-4" key={index}>
                        <div className="all-blog-posts__grid">
                          <div className="all-blog-posts__img">
                            <Link to={`/blog-detail/${item.title_slug}`}>
                              <img src={IMAGE_BASE_URL + item?.image} />
                            </Link>
                          </div>

                          <div className="all-blog-posts__content">
                            <span className="blog-category">
                              {item?.main_category}
                            </span>
                            <Link to={`/blog-detail/${item.title_slug}`}>
                              <h2 className="font-weight-bold h4">
                                {item?.title.slice(0, 20)}
                              </h2>
                            </Link>
                            <div className="all-blog-posts__descp">
                              <p>{!!item && parse(item?.summary)}</p>
                            </div>
                            <Link
                              to={`/blog-detail/${item.title_slug}`}
                              className="blog-link"
                            >
                              Read more
                              <i className="ri-arrow-right-circle-fill"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="blog-pagination mt-5">
                  <div>
                    {currentPage > 1 && (
                      <>
                        <a className="text-dark" href="#scroollId">
                          <button onClick={() => handlePagination("prev")}>
                            <i className="ri-arrow-left-line"></i>Previous
                          </button>
                        </a>
                      </>
                    )}
                  </div>

                  <div className="page-numbers ">
                    {page.map((item, index) => (
                      <a href="#scroollId" key={index}>
                        <button
                          className={`${currentPage == item ? "active" : null}`}
                          onClick={(e) => handlePagination("pages", item)}
                        >
                          {item}
                        </button>
                      </a>
                    ))}
                  </div>
                  <div>
                    {currentPage < totalPage && (
                      <>
                        <a className="text-dark" href="#scroollId">
                          <button onClick={() => handlePagination("next")}>
                            Next <i className="ri-arrow-right-line"></i>
                          </button>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
