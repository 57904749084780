import React, { useEffect } from "react";
import Footer from "./HomeSection/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getWorkEarnContent } from "./landingContentAction";
import HTMLReactParser from "html-react-parser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function WorkandEarn() {
  const workEarnContent = useSelector((state) => state.workandEarnReducer);

  const {
    getWorkAndEarnData: { getWorkAndEarnContent },
  } = workEarnContent;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="landing-wrapper">
      <section className="spacer hero earn-hero-section">
        <div className="container">
          <div className="row align-items-center hero-row">
            <div className="col-lg-6 col-md-12 hero-col">
              <h1 className="heading-h1 mb-0 font-typo-bold text-break">
                {getWorkAndEarnContent?.top_section?.page_main_title}
              </h1>
              <p className="mt-3 font-18 text-break">
                {getWorkAndEarnContent?.top_section?.page_main_into?.length &&
                  HTMLReactParser(
                    getWorkAndEarnContent?.top_section?.page_main_into
                  )}
              </p>
              <div className="hero__textslider__btn py-2">
                <Link
                  className="btn btn-primary mt-3 d-none d-md-inline-block"
                  to="/signup"
                >
                  Get started
                </Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 hero-col-img">
              <div className="hero-earn-block position-relative">
                <div className="hero-img-block position-relative">
                  <img
                    src="/assets/images/indoor-photo-1.png"
                    alt="Earn Hero"
                  ></img>
                  <img
                    src="/assets/images/hero-img-bg.svg"
                    className="hero-round-img"
                    alt=""
                  ></img>
                </div>
                <div className="earn-black-box position-absolute">
                  <h6 className="font-typo-bold mb-0 text-white">
                    {
                      getWorkAndEarnContent?.top_section
                        ?.first_img_black_box_title
                    }
                  </h6>
                  <p className="font-14 mt-1 text-white mb-0">
                    {getWorkAndEarnContent?.top_section
                      ?.first_img_black_box_description?.length > 0 &&
                      HTMLReactParser(
                        getWorkAndEarnContent?.top_section
                          ?.first_img_black_box_description
                      )}
                  </p>
                </div>
                <div className="earn-white-box position-absolute">
                  <h6 className="font-typo-bold mb-0">
                    {
                      getWorkAndEarnContent?.top_section
                        ?.first_img_white_box_title
                    }
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="spacer service-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12 mx-auto text-center">
              <p className="theme-text-primary font-typo-bold mb-3">
                {" "}
                {getWorkAndEarnContent?.services_section?.section_one_title}
              </p>
              <h2 className="section__subheading mb-0 mt-0">
                {getWorkAndEarnContent?.services_section
                  ?.section_one_description?.length &&
                  HTMLReactParser(
                    getWorkAndEarnContent?.services_section
                      ?.section_one_description
                  )}
              </h2>
            </div>
          </div>
          <div className="row spacer pb-0 services-blog">
            {getWorkAndEarnContent?.blog_section?.length > 0 &&
              getWorkAndEarnContent?.blog_section.map((blog, index) => (
                <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                  <Link to={`/blog-detail/${blog?.title_slug}`}>
                    <div className="services-main-block">
                      <h5 className="mb-0 font-typo-bold mt-0 mb-2 text-dark">
                        {blog?.title?.length > 20
                          ? blog?.title.slice(0, 20)
                          : blog?.title}
                      </h5>
                      <p className="font-14 mt-0 mb-0">
                        {blog?.summary?.length > 50
                          ? HTMLReactParser(blog?.summary)
                          : HTMLReactParser(blog?.summary)}
                      </p>
                      <Link to="/signup" className="font-typo-bold font-16">
                        Get started <i className="ri-arrow-drop-right-line"></i>
                      </Link>
                    </div>
                  </Link>
                </div>
              ))}
            <></>
          </div>
        </div>
      </section>
      <section className="spacer start-business-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <img
                src={getWorkAndEarnContent?.bottom_section?.section_two_image}
                alt="agency-growth"
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <h2 className="section__subheading font-typo-bold">
                {getWorkAndEarnContent?.bottom_section?.section_two_title}
              </h2>
              <p className="mt-3 mb-0 font-18">
                {getWorkAndEarnContent?.bottom_section?.section_two_description
                  ?.length > 0 &&
                  HTMLReactParser(
                    getWorkAndEarnContent?.bottom_section
                      ?.section_two_description
                  )}
              </p>

              <div className="hero__textslider__btn py-2">
                <Link
                  className="btn btn-primary mt-3 d-none d-md-inline-block"
                  to="/signup"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="spacer maximize-section">
        <div className="container">
          <div className="maximize-block">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <h2 className="font-typo-bold text-white section__subheading">
                  {getWorkAndEarnContent?.bottom_section?.section_three_title}
                </h2>
                <p className="mt-3 mb-0 font-18 text-white">
                  {getWorkAndEarnContent?.bottom_section?.section_three_title
                    ?.length > 0 &&
                    HTMLReactParser(
                      getWorkAndEarnContent?.bottom_section
                        ?.section_three_description
                    )}
                </p>

                <Link
                  className="btn mt-4 icon-btn bg-white font-16 font-typo-bold"
                  to="/signup"
                >
                  Get started
                </Link>
              </div>
              <div className="col-lg-6 col-md-12">
                <img
                  src={
                    getWorkAndEarnContent?.bottom_section?.section_three_image
                  }
                  alt="agency-growth"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="spacer start-business-section">
        <div className="container feaures">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 block-seize-img">
              <svg
                viewBox="0 0 500 500"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="250" cy="250" r="250" fill="#EEF8F5"></circle>
                <image
                  href={
                    getWorkAndEarnContent?.bottom_section?.section_four_image
                  }
                />
              </svg>
            </div>
            <div className="col-lg-6 col-md-12 mt-4 mt-md-0 block-seize-text">
              <h2 className="section__subheading mb-0">
                {getWorkAndEarnContent?.bottom_section?.section_four_title}
              </h2>
              <div className="section__content my-3">
                <p className="mb-0 font-18">
                  {getWorkAndEarnContent?.bottom_section
                    ?.section_four_description?.length > 0 &&
                    HTMLReactParser(
                      getWorkAndEarnContent?.bottom_section
                        ?.section_four_description
                    )}
                </p>

                <div className="hero__textslider__btn py-2">
                  <Link
                    className="btn btn-primary mt-3 d-none d-md-inline-block"
                    to="/signup"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
