import { POST_API } from "../../main/fetch";
import {
  SEARCH_LIST_DATA_FAILURE,
  SEARCH_LIST_DATA_REQUEST,
  SEARCH_LIST_DATA_SUCCESS,
  SEND_REQUEST_DATA_FAILURE,
  SEND_REQUEST_DATA_REQUEST,
  SEND_REQUEST_DATA_SUCCESS,
  SEARCH_SERVICE_DATA_REQUEST,
  SEARCH_SERVICE_DATA_SUCCESS,
  SEARCH_SERVICE_DATA_FAILURE,
} from "../../modules/SearchList/SearchListConstant";

import {
  SEARCH_LIST_MEMBERS_DATA_REQUEST,
  SEARCH_LIST_MEMBERS_DATA_SUCCESS,
  SEARCH_LIST_MEMBERS_DATA_FAILURE,
} from "../../modules/SearchMembersList/SearchMembersListConstant";

import {
  CANCEL_REQUEST_DATA_FAILURE,
  CANCEL_REQUEST_DATA_REQUEST,
  CANCEL_REQUEST_DATA_SUCCESS,
  SEARCH_PROFILE_DATA_FAILURE,
  SEARCH_PROFILE_DATA_REQUEST,
  SEARCH_PROFILE_DATA_SUCCESS,
  UNFRIEND_DATA_FAILURE,
  UNFRIEND_DATA_REQUEST,
  UNFRIEND_DATA_SUCCESS,
} from "../../modules/SearchProfile/SearchProfileConstant";
import {
  CANCEL_REQUEST_API,
  SEARCH_LIST_API,
  SEARCH_PROFILE_DATA_API,
  SEND_REQUEST_API,
  UNFRIEND_USER_API,
  SEARCH_INDIVIDUAL_API,
  MINESTONE_SEARCH,
} from "../urls";

//this function is used to fetch search list
const searchListDataApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SEARCH_LIST_API,
      types: [
        SEARCH_LIST_DATA_REQUEST,
        SEARCH_LIST_DATA_SUCCESS,
        SEARCH_LIST_DATA_FAILURE,
      ],
      body,
    },
  };
};
const searchServiceApi = (body) => {
  return {
    [POST_API]: {
      endpoint: MINESTONE_SEARCH,
      types: [
        SEARCH_SERVICE_DATA_REQUEST,
        SEARCH_SERVICE_DATA_SUCCESS,
        SEARCH_SERVICE_DATA_FAILURE,
      ],
      body,
      is_form_data: true,
    },
  };
};



//this function is used to fetch personal details that are searched by the user
const searchProfileDataApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SEARCH_PROFILE_DATA_API,
      types: [
        SEARCH_PROFILE_DATA_REQUEST,
        SEARCH_PROFILE_DATA_SUCCESS,
        SEARCH_PROFILE_DATA_FAILURE,
      ],
      body,
    },
  };
};

//this function is used to send friend request
const sendRequestDataApi = (body) => {
  return {
    [POST_API]: {
      endpoint: SEND_REQUEST_API,
      types: [
        SEND_REQUEST_DATA_REQUEST,
        SEND_REQUEST_DATA_SUCCESS,
        SEND_REQUEST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to cancel request
const cancelRequestDataApi = (body) => {
  return {
    [POST_API]: {
      endpoint: CANCEL_REQUEST_API,
      types: [
        CANCEL_REQUEST_DATA_REQUEST,
        CANCEL_REQUEST_DATA_SUCCESS,
        CANCEL_REQUEST_DATA_FAILURE,
      ],
      body,
    },
  };
};
//this function is used to unfriend user
const unfriendUserApi = (body) => {
  return {
    [POST_API]: {
      endpoint: UNFRIEND_USER_API,
      types: [
        UNFRIEND_DATA_REQUEST,
        UNFRIEND_DATA_SUCCESS,
        UNFRIEND_DATA_FAILURE,
      ],
      body,
    },
  };
};
export {
  searchListDataApi,
  searchProfileDataApi,
  sendRequestDataApi,
  cancelRequestDataApi,
  unfriendUserApi,
  searchServiceApi,
};
