import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { useHistory, useLocation } from "react-router";
import { SharePost } from "../../../../modules/Home/common";
import { Skeleton } from "primereact/skeleton";
import { ProgressSpinner } from "primereact/progressspinner";

import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/skeleton/skeleton.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import {
  deleteMyPostApi,
  getAllProfilePostApi,
  userPostCommentApi,
  userPostCommentListApi,
  userPostCommentReplyApi,
  userPostCommentReplyListApi,
  userPostFeedShareApi,
  userPostFeedShareListApi,
  userPostReactApi,
  userPostReactListApi,
  userPostViewListApi,
} from "../../../api/ProfilePostApiService";
import { ADMIN, IMAGE_BASE_URL } from "../../../urls";
import {
  getCookie,
  numFormatter,
  scroolTop,
  useForceUpdate,
} from "../../../utilities/functions";
import Input from "../Input/Input";
import SingleProfilePost from "./SingleProfilePost";
import {
  changeUserAllPostDetail,
  changeUserCommentDetailInput,
  changeUserPostActiveCarousal,
  changeUserPostReactDetails,
  changeUserShareListDetail,
  changeUserViewsDetails,
  clearMyDetelePostResponse,
  clearProfilePostResponse,
  clearUserCommentListResponse,
  clearUserCommentReplyListResponse,
  clearUserCommentReplyResponse,
  clearUserPostCommentResponse,
  clearUserPostReactListResponse,
  clearUserPostReactResponse,
  clearUserPostShareResponse,
} from "./ProfilePostAction";
import ProfilePostModel from "./ProfilePostModel";
import { useToasts } from "react-toast-notifications";
import {
  changePostDetails,
  changeSharePostInput,
} from "../../../../modules/Home/HomeActions";
import Microlink from "@microlink/react";
import { clearFriendListApiResponse } from "../../../../modules/MyNetwork/MyNetworkAction";
import { friendListApi } from "../../../api/MyNetworkApiService";

let post_page = 1,
  links = [];
const ProfilePosts = ({ userId, profile_details }) => {
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const profilePostState = useSelector((state) => state.ProfilePostReducer);
  const HomeState = useSelector((state) => state.HomeReducer);
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const {
    uploadPostApi: { uploadPostStatus },
  } = HomeState;
  const forceUpdate = useForceUpdate();
  let { pathname } = useLocation();
  const { addToast } = useToasts();
  const {
    getProfilePostDetail: {
      profilePostPage,
      getProfilePost,
      network_count,
      network_list,
    },
  } = profilePostState;
  const {
    postReactionApi: { reactionStatus },
  } = profilePostState;
  const {
    reactionListDetail: { reactListPage, reactionPostId },
  } = profilePostState;
  const {
    friendListDetail: { friendListPage, friendList, searchFriendPage },
  } = networkState;
  const {
    postViewListDetail: { viewListPage, viewPostId },
  } = profilePostState;
  const {
    getProfilePostApi: { getProfilePostLoading },
  } = profilePostState;
  const {
    commentApi: { commentStatus, commentResponse },
  } = profilePostState;
  const {
    commentListApi: { commentListStatus, commentListLoading },
  } = profilePostState;
  const {
    commentReplyApi: { commentReplyStatus, commentReplyResponse },
  } = profilePostState;
  const {
    commentReplyListApi: { commentReplyListStatus },
  } = profilePostState;
  const {
    shareFeedPostApi: { shareFeedPostStatus, shareFeedPostMessage },
  } = profilePostState;
  const {
    reactListApi: { reactListStatus },
  } = profilePostState;
  const {
    postShareListDetail: { sharePostId, shareListPage },
  } = profilePostState;
  const {
    deletePostApi: { deletePostStatus, deletePostMessage },
  } = profilePostState;
  const [postCommentScrool, setPostCommentScrool] = useState(false);
  const [scroolPostId, setPostId] = useState(false);
  const [isReact, setIsReact] = useState(false);
  const [networkTotal, setNetworkTotal] = useState(0);
  const [networkList, setNetworkList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [propss, setpropss] = useState(false);
  post_page = profilePostPage;
  const {
    activeCarousalPostDetail: { activeCarousalPost },
  } = profilePostState;

  if (history.action === "POP") {
  
    history.push(`/account/${profileData?.user_slug}`);
  }


  const postScroolToBottom = () => {
    if (!!postCommentScrool) {
      var div = document.getElementById("scroll-single-post-" + scroolPostId);
      if (!!div) {
        window.setTimeout(() => {
          div.children[0].scroll({
            top: div.children[0].scrollHeight,
            behavior: "smooth",
          });
        }, 100);
      }
      setPostCommentScrool(true);
    }
  };
  useEffect(() => {
    if (activeCarousalPost.length == 0) {
      setpropss(false);
    } else {
      setpropss(true);
    }
  }, [activeCarousalPost]);
  useEffect(() => {
    if (!!reactListStatus && !!isReact) {
      dispatch(clearUserPostReactListResponse());
      setIsReact(false);
    }
  }, [reactListStatus]);

  useEffect(() => {
    dispatch(clearProfilePostResponse());
    // scroolTop();
    if (!!getProfilePost.length) {
      $(window).scroll(function () {
        if (
          $(window).scrollTop() + $(window).height() >=
            $(document).height() - 1 &&
          !!getProfilePost.length
        ) {
          if (
            pathname == `/account/${profileData?.user_slug}/profile-post` ||
            pathname.match("/search-profile")
          ) {
            if (!!document.getElementById("pills-profile")) {
              if (
                !!document
                  .getElementById("pills-profile")
                  .className.match("active show")
              ) {
                dispatch(
                  changeUserAllPostDetail({ profilePostPage: post_page + 1 })
                );
              }
            }
          }
        }
      });
    }

    return () => {
      unbindJqueryEvents();
      dispatch(clearProfilePostResponse());

      dispatch(clearUserPostReactListResponse());
      let commentDetail = {
        commentListPage: "",
        commentPostId: "",
      };
      dispatch(changeUserCommentDetailInput(commentDetail));
    };
  }, []);
  useEffect(() => {
    if (!!deletePostStatus) {
      addToast(deletePostMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearMyDetelePostResponse());
    }
  }, [deletePostStatus]);

  useEffect(() => {
    if (!!shareListPage) {
      const bodyParameter = {
        post_id: sharePostId,
      };
      dispatch(userPostFeedShareListApi(bodyParameter));
    }
  }, [shareListPage]);
  useEffect(() => {
    if (shareFeedPostStatus == 200) {
      addToast(shareFeedPostMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearUserPostShareResponse());
    }
  }, [shareFeedPostStatus]);
  useEffect(() => {
    if (!!commentReplyListStatus) {
      dispatch(clearUserCommentReplyListResponse());
    }
  }, [commentReplyListStatus]);
  useEffect(() => {
    if (!!commentReplyStatus) {
      if (!!commentReplyResponse) {
        let nestedComments = document.getElementById(
          `cmt-${commentReplyResponse.post_id}-nested-${commentReplyResponse.comment_id}`
        ).style.display;
        if (nestedComments != "none") {
          for (let i in getProfilePost) {
            const innerArr = getProfilePost[i].commentList;

            for (let j in innerArr) {
              if (innerArr[j].comment_id == commentReplyResponse.comment_id) {
                innerArr[j].subComment.push(commentReplyResponse);
                dispatch(
                  changeUserAllPostDetail({ getProfilePost: getProfilePost })
                );
              }
            }
          }
        }
      }
      dispatch(clearUserCommentReplyResponse());
    }
  }, [commentReplyStatus]);
  useEffect(() => {
    if (commentListStatus == 200) {
      postScroolToBottom();
      for (let i in getProfilePost) {
        const innerArr = getProfilePost[i].commentList;
        for (let j in innerArr) {
          innerArr[j].replyComment = "";
          innerArr[j].subComment = [];
          innerArr[j].replyCommentPage = 0;
          innerArr[j].showHide = false;
          dispatch(changeUserAllPostDetail({ getProfilePost: getProfilePost }));
        }
      }
      dispatch(clearUserCommentListResponse());
    }
  }, [commentListStatus]);
  useEffect(() => {
    if (!!commentStatus) {
      if (!!commentResponse) {
        if (
          document
            .getElementById("comment-listing-" + commentResponse.post_id)
            .getAttribute("class")
            .match("show-comments")
        ) {
          for (let i in getProfilePost) {
            if (getProfilePost[i].post_id == commentResponse.post_id) {
              getProfilePost[i].commentList.push(commentResponse);
              dispatch(
                changeUserAllPostDetail({ getProfilePost: getProfilePost })
              );
              postScroolToBottom();
            }
          }
        }
      }
      dispatch(clearUserPostCommentResponse());
    }
  }, [commentStatus]);

  useEffect(() => {
    if (!!reactListPage) {
      const bodyParameter = {
        post_id: reactionPostId,
        page: reactListPage,
      };
      dispatch(userPostReactListApi(bodyParameter));
    }
  }, [reactListPage]);

  useEffect(() => {
    if (!!viewListPage) {
      const bodyParameter = {
        post_id: viewPostId,
        page: viewListPage,
      };
      dispatch(userPostViewListApi(bodyParameter));
    }
  }, [viewListPage]);
  useEffect(() => {
    if (!!reactionStatus) {
      dispatch(clearUserPostReactResponse());
      let reactDetailss = {
        reactListPage: 1,
      };
      dispatch(changeUserPostReactDetails(reactDetailss));
    }
  }, [reactionStatus]);

  useEffect(() => {
    if (profilePostPage > 1) {
      const bodyParameter = {
        page: profilePostPage,
        user_id: userId,
      };
      dispatch(getAllProfilePostApi(bodyParameter));
    }
  }, [profilePostPage]);

  useEffect(() => {
    const bodyParameter = {
      page: profilePostPage,
      user_id: userId,
    };
    dispatch(getAllProfilePostApi(bodyParameter));
  }, [history.location.pathname]);

  const unbindJqueryEvents = () => {
    $(".feeds-post__action .ri-more-2-fill").unbind("click");
    $(".open-comment").unbind("click");
    $(".card-footer .react-icon .react-model").unbind("click");
    $(".post-share-link  a").unbind("click");
    $("body").unbind("click");
  };

  useEffect(() => {
    if (!!getProfilePost || !!uploadPostStatus || !!deletePostStatus) {
      unbindJqueryEvents();
      for (let i in getProfilePost) {
        getProfilePost[i].comment = "";
        getProfilePost[i].commentList = [];
        getProfilePost[i].commentPage = 0;

        dispatch(changeUserAllPostDetail({ getProfilePost: getProfilePost }));
      }
      $(".open-comment").click(function () {
        $(this)
          .parent(".post_comment__counter")
          .nextAll(".comment-post")
          .toggleClass("show-comments");
      });

      $(".card-footer .react-icon .react-model").click(function (event) {
        event.stopPropagation();
        $(this).parent().parent().find(".react-box").toggleClass("open-emoji");
      });

      $(".post-share-link  a").click(function (event) {
        event.stopPropagation();
        $(this)
          .parent(".post-share-link")
          .find(".share-box")
          .toggleClass("open-share");
      });

      $("body").click(function (event) {
        $(".dropdown-list").removeClass("profile-action-active");
      });

      $(".feeds-post__action .ri-more-2-fill").click(function (events) {
        events.stopPropagation();
        if (
          $(this)
            .parents(".card")
            .siblings()
            .find(".dropdown-list")
            .hasClass("profile-action-active")
        ) {
          $(".dropdown-list").removeClass("profile-action-active");
        }
        if (
          $(this)
            .parents(".card-header")
            .find("ul")
            .hasClass("profile-action-active")
        ) {
          $(".dropdown-list").removeClass("profile-action-active");
        } else {
          $(this)
            .parents(".card-header")
            .find("ul")
            .addClass("profile-action-active");
        }
      });
      $("body").click(function (event) {
        $(".react-box").removeClass("open-emoji");
        $(".share-box").removeClass("open-share");
      });
    }
  }, [getProfilePost, uploadPostStatus, deletePostStatus]);

  const checkLinks = (text) => {
    let newText = "<span>";
    if (!!text) {
      let splStr = text.split(" ");
      for (let i = 0; i < splStr.length; i++) {
        if (
          splStr[i].match(
            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
          )
        ) {
          newText =
            newText +
            "<a href=" +
            splStr[i] +
            " target='_blank'>" +
            splStr[i] +
            "</a>" +
            (i !== splStr.length ? " " : "");
        } else {
          newText = newText + splStr[i] + (i !== splStr.length ? " " : "");
        }
      }
      newText = newText + "</span>";
    }

    return newText;
  };

  const handleSinglePost = (data, index, e) => {
    var videos = document.querySelectorAll("video");

    videos.forEach(function (video) {
      video.pause();
    });
    let carousal = {
      activeCarousalPost: data,
      activeIndexPost: 0,
    };
    dispatch(
      changeUserPostActiveCarousal({
        ...profilePostState.activeCarousalPostDetail,
        ...carousal,
      })
    );
  };
  const handleClickItem = (data, index, e) => {
    if (e.target.id.match("video")) {
      e.target.pause();
    }
    let carousal = {
      activeCarousalPost: data,
      activeIndexPost: index,
    };

    dispatch(
      changeUserPostActiveCarousal({
        ...profilePostState.activeCarousalPostDetail,
        ...carousal,
      })
    );
  };
  const handleOnChange = () => {
    var videos = document.querySelectorAll("video");
    videos.forEach(function (video) {
      video.pause();
    });
  };
  const handlePlay = (e) => {
    var videos = document.querySelectorAll("video");
    videos.forEach(function (video) {
      if (e.currentTarget.id == video.id) {
        {
          !propss ? video.play() : video.pause();
        }
      } else {
        video.pause();
      }
    });
  };
  const handleOpenProfile = (data) => {
    dispatch(clearProfilePostResponse());
    if (profileData.id == data.user_id) {
      history.push(`/account/${profileData.user_slug}`);
    } else {
      history.push(
        `/search-profile/${data.user_id}`


        // `/search-profile/${data.user_id}?individual=${
        //   !!data.is_individual ? true : false
        // }`
      );
    }
  };
  
  const handleReact = (id, data, index) => {
    setIsReact(true);
    const bodyParameter = {
      post_id: data.post_id,
      reaction: id,
    };
    dispatch(userPostReactApi(bodyParameter));
    let reactDetail = {
      reactionPostId: data.post_id,
    };
    dispatch(changeUserPostReactDetails(reactDetail));
    for (let i in getProfilePost) {
      if (i == index) {
        getProfilePost[i].reaction = id;
        if (!data.react) {
          getProfilePost[i].react = !data.react ? true : data.react;
          dispatch(changeUserAllPostDetail({ getProfilePost: getProfilePost }));
          forceUpdate();
        }
      }
    }
  };

  const handleLikeDislike = (data, index) => {
    const bodyParameter = {
      post_id: data.post_id,
      likecheck: !!data.like ? "false" : "true",
    };
    dispatch(likeDislikeApi(bodyParameter));
    for (let i in getProfilePost) {
      if (i == index) {
        getProfilePost[i].like = !!data.like ? false : true;
        if (data.like) {
          getProfilePost[i].post_like = getProfilePost[i].post_like + 1;
        } else {
          if (getProfilePost[i].post_like > getProfilePost[i].post_like - 1) {
            getProfilePost[i].post_like = getProfilePost[i].post_like - 1;
          }
        }
      }
    }
  };

  const handleReactList = (id) => {
    let reactDetail = {
      reactListPage: 1,
      reactionPostId: id,
    };
    dispatch(changeUserPostReactDetails(reactDetail));
  };
  const handelViewList = (data) => {
    if (!!data.post_views) {
      let viewDetail = {
        viewListPage: 1,
        viewPostId: data.post_id,
      };
      dispatch(changeUserViewsDetails(viewDetail));
    }
  };
  const handleSumbitComment = (e, data, index) => {
    e.preventDefault();
    if (!!data.comment) {
      const bodyParameter = {
        post_id: data.post_id,
        comment: data.comment,
      };
      dispatch(userPostCommentApi(bodyParameter));
      for (let i in getProfilePost) {
        if (i == index) {
          getProfilePost[i].comment_count = getProfilePost[i].comment_count + 1;
          getProfilePost[i].comment = "";
          forceUpdate();
        }
      }
    }
  };
  const handleCommentReplySumbit = (e, data, index) => {
    e.preventDefault();
    const bodyParameter = {
      post_id: data.post_id,
      comment_id: data.comment_id,
      comment: data.replyComment,
    };
    dispatch(userPostCommentReplyApi(bodyParameter));
    for (let i in getProfilePost) {
      const innerPost = getProfilePost[i].commentList;
      for (let j in innerPost) {
        if (j == index) {
          innerPost[j].replyComment = "";
          innerPost[j].reply = innerPost[j].reply + 1;
          forceUpdate();
          dispatch(changeUserAllPostDetail({ getProfilePost: getProfilePost }));
        }
      }
    }
  };
  const handleChangeComment = (e, id) => {
    setPostCommentScrool(true);
    for (let i in getProfilePost) {
      if (id == getProfilePost[i].post_id) {
        getProfilePost[i].comment = e.target.value;
      }
    }
    dispatch(changeUserAllPostDetail({ getProfilePost: getProfilePost }));
  };
  const handleChangeReplyInput = (e, id) => {
    for (let i in getProfilePost) {
      const innerPost = getProfilePost[i].commentList;
      for (let j in innerPost) {
        if (id == innerPost[j].comment_id) {
          innerPost[j].replyComment = e.target.value;
          dispatch(changeUserAllPostDetail({ getProfilePost: getProfilePost }));
        }
      }
    }
  };
  const showCommentModel = (index, id) => {
    if (
      document
        .getElementById("comment-listing-" + id)
        .getAttribute("class")
        .match("show-comments")
    ) {
      setPostCommentScrool(true);
      for (let i in getProfilePost) {
        if (i == index) {
          getProfilePost[i].actualComment = getProfilePost[i].comment_count;
          if (!!getProfilePost[i].comment_count) {
            if (!commentListLoading) {
              const bodyParameter = {
                post_id: id,
                page: 1,
              };
              dispatch(userPostCommentListApi(bodyParameter));
            }
          }
        }
      }
      setPostId(id);
    } else {
      for (let i in getProfilePost) {
        if (i == index) {
          getProfilePost[i].commentList = [];
          getProfilePost[i].commentPage = "";
        }
      }
      dispatch(clearUserCommentListResponse());
    }
    for (let i in getProfilePost) {
      if (i == index) {
        getProfilePost[i].commentPage = 1;
      }
    }
    let commentDetail = {
      commentListPage: 1,
      commentPostId: id,
    };
    dispatch(changeUserCommentDetailInput(commentDetail));
  };
  const handleCommentPaginate = (data, index) => {
    setPostCommentScrool(false);
    for (let i in getProfilePost) {
      if (i == index) {
        getProfilePost[i].commentPage = data.commentPage + 1;
      }
    }
    const bodyParameter = {
      post_id: data.post_id,
      page: data.commentPage,
    };
    dispatch(userPostCommentListApi(bodyParameter));
  };
  const handleReplyCommentPaginate = (data, index) => {
    for (let i in getProfilePost) {
      const innerPost = getProfilePost[i].commentList;
      for (let j in innerPost) {
        if (j == index) {
          innerPost[j].replyCommentPage = innerPost[j].replyCommentPage + 1;

          dispatch(changeUserAllPostDetail({ getProfilePost: getProfilePost }));
        }
      }
    }
    const bodyParameter = {
      page: data.replyCommentPage,
      post_id: data.post_id,
      comment_id: data.comment_id,
    };
    dispatch(userPostCommentReplyListApi(bodyParameter));
  };

  const handleOpenReplyInput = (e, item) => {
    let commentStyle = document.getElementById(
      `cmt-${item.post_id}-input-${item.comment_id}`
    ).style.display;
    if (commentStyle == "none") {
      document.getElementById(
        `cmt-${item.post_id}-input-${item.comment_id}`
      ).style.display = "flex";
    } else {
      document.getElementById(
        `cmt-${item.post_id}-input-${item.comment_id}`
      ).style.display = "none";
    }
  };

  const handleOpenNestedReply = (item, index) => {
    let nestedComment = document.getElementById(
      `cmt-${item.post_id}-nested-${item.comment_id}`
    ).style.display;
    if (nestedComment == "none") {
      for (let i in getProfilePost) {
        const innerPost = getProfilePost[i].commentList;
        for (let j in innerPost) {
          if (j == index) {
            innerPost[j].replyCommentPage = innerPost[j].replyCommentPage + 1;
            innerPost[j].showHide = true;
            innerPost[j].actualReplyComment = innerPost[j].reply;
            forceUpdate();
          }
        }
      }
      dispatch(changeUserCommentDetailInput({ commentId: item.comment_id }));
      document.getElementById(
        `cmt-${item.post_id}-nested-${item.comment_id}`
      ).style.display = "block";
      const bodyParameter = {
        page: 1,
        post_id: item.post_id,
        comment_id: item.comment_id,
      };
      dispatch(userPostCommentReplyListApi(bodyParameter));
    } else {
      for (let i in getProfilePost) {
        const innerPost = getProfilePost[i].commentList;
        for (let j in innerPost) {
          if (j == index) {
            innerPost[j].subComment = [];
            innerPost[j].replyCommentPage = 0;
            innerPost[j].showHide = false;
            innerPost[j].actualReplyComment = 0;
            forceUpdate();
          }
        }
      }
      document.getElementById(
        `cmt-${item.post_id}-nested-${item.comment_id}`
      ).style.display = "none";
    }
  };
  const handleShareFeedPosts = (data) => {
    const bodyParameter = {
      post_id: data.post_id,
    };
    dispatch(userPostFeedShareApi(bodyParameter));
  };

  const handleShareList = (data) => {
    if (!!data.share_count) {
      let shareDetail = {
        shareListPage: 1,
        sharePostId: data.post_id,
      };
      dispatch(changeUserShareListDetail(shareDetail));
    }
  };

  const convertLinks = (text) => {
    links = [];
    text.trim().replace(/\s\s+/g, " ");
    let splStr = text.split(" ");

    for (let i = 0; i < splStr.length; i++) {
      if (
        splStr[i].match(
          "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
        )
      ) {
        links.push(splStr[i]);
      }
    }
    return links.map((data, i) => (
      <div key={i}>
        <Microlink
          url={data}
          media="video"
          size="large"
          style={{ maxWidth: "90%" }}
          autoPlay
        />
      </div>
    ));
  };
  const openNetwork = () => {
    if (pathname == `/account/${profileData?.user_slug}`) {
      history.push("/network");
      dispatch(clearFriendListApiResponse());
    } else {
      history.push(`/network/${userId}`);
      dispatch(clearFriendListApiResponse());
    }
  };
  const handleDeletePosts = (index, data) => {
    for (let i in getProfilePost) {
      if (i == index) {
        getProfilePost.splice(i, 1);
        dispatch(changeUserAllPostDetail({ getProfilePost: getProfilePost }));
      }
    }
    const bodyParameter = {
      post_id: data.post_id,
    };
    dispatch(deleteMyPostApi(bodyParameter));
  };

  const handleEditPosts = (index, data) => {
    let requestData = [];
    dispatch(changePostDetails({ postDetail: data }));
    dispatch(changeSharePostInput({ shareText: data.post_text }));
    if (data.post_data.length > 0) {
      for (let i = 0; i < data.post_data.length; i++) {
        requestData.push(IMAGE_BASE_URL + data.post_data[i].file_name);
        dispatch(changeSharePostInput({ mediaUrl: requestData }));
      }
    }
  };

  useEffect(() => {
    const body = {
      user_id: userId,
      page: friendListPage,
      support_customer: false,
    };
    dispatch(friendListApi(body)).then((res) => {
      const {
        response: {
          data: { list, total },
        },
      } = res;
      const friends = list.filter((item) => item.user_id != ADMIN);

      if (!!friends.length > 0) {
        const isHaveAdminInList = list.filter((item) => item.user_id == ADMIN);

        if (!!Object.keys(isHaveAdminInList).length) {
          setNetworkTotal(total - 1);
        } else {
          setNetworkTotal(total);
        }

        setNetworkList(friends);
      }
    });
  }, [network_count]);

  useEffect(() => {
    if (pathname !== "/account") {
      const filterPost = getProfilePost.filter(
        (item) => item.user_id === profile_details?.id
      );
      dispatch(changeUserAllPostDetail({ getProfilePost: filterPost }));
    } else {
      const filterPost = getProfilePost.filter(
        (item) => item.user_id === profileData?.id
      );
      dispatch(changeUserAllPostDetail({ getProfilePost: filterPost }));
    }
  }, [pathname]);

  return (
    <>
      <div className="feeds bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-3"></div>
            <div className="col-sm-12 col-lg-6 mx-auto">
              {pathname == `/account/${profileData?.user_slug}/profile-post` ? (
                <div className="feeds-post bg-white p-3 br-20">
                  <div className="feeds-post__header d-flex flex-wrap align-items-center">
                    <div className="feeds-post__user ">
                      {!!profileData.is_individual ? (
                        <img
                          onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                          }
                          src={
                            !!profileData
                              ? IMAGE_BASE_URL + profileData.profile_image
                              : "/assets/images/member-default.svg"
                          }
                          alt="user"
                        />
                      ) : (
                        <img
                          onError={(ev) =>
                            (ev.target.src = "/assets/images/business_logo.svg")
                          }
                          src={
                            !!profileData.business_image
                              ? IMAGE_BASE_URL + profileData.business_image
                              : profileData.profile_image
                              ? IMAGE_BASE_URL + profileData.profile_image
                              : "/assets/images/business_logo.svg"
                          }
                          alt="logo-business"
                        />
                      )}
                    </div>
                    <input
                      type="text"
                      name="post"
                      className="form-control share-thought"
                      placeholder="What do you want to share with your audience?"
                      data-toggle="modal"
                      data-target="#feeds-modal"
                      readOnly
                    />
                  </div>
                  <div className="feeds-post__type mt-4 d-flex justify-content-between feed_main">
                    <div className="feeds-post__category">
                      <a
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#feeds-modal"
                      >
                        <i className="ri-image-fill pictures-option" /> Pictures
                      </a>
                    </div>
                    <div className="feeds-post__category">
                      <a
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#feeds-modal"
                      >
                        <i className="ri-movie-line video-option" /> Videos
                      </a>
                    </div>
                    <div className="feeds-post__category">
                      <a
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#feeds-modal"
                      >
                        <i className="ri-links-line link-option"></i> Links
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {!!getProfilePost.length
                ? getProfilePost.map((data, index) => (
                    <div className="card my-4" key={index}>
                      <div className="card-header d-flex align-items-center">
                        <div className="feeds-post__user mr-3 Posteddd">
                          {!!data.shared ? (
                            !!data.shared_by_user.id_individual ? (
                              <img
                                onError={(ev) =>
                                  (ev.target.src =
                                    "/assets/images/business_logo.svg")
                                }
                                src={
                                  !!data
                                    ? IMAGE_BASE_URL +
                                      data.shared_by_user.user_profile
                                    : "/assets/images/business_logo.svg"
                                }
                                alt="user"
                              />
                            ) : (
                              <div className="business-pofile-lead">
                                <img
                                  onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/business_logo.svg")
                                  }
                                  src={
                                    !!data
                                      ? IMAGE_BASE_URL +
                                        data.shared_by_user.business_image
                                      : "/assets/images/business_logo.svg"
                                  }
                                  alt="logo"
                                />
                              </div>
                            )
                          ) : !!data.is_individual ? (
                            <img
                              onError={(ev) =>
                                (ev.target.src =
                                  "/assets/images/member-default.svg")
                              }
                              src={
                                !!data
                                  ? IMAGE_BASE_URL + data.user_profile
                                  : "/assets/images/member-default.svg"
                              }
                              alt="user"
                            />
                          ) : (
                            <div
                              className="business-pofile-lead one-one
                        "
                            >
                              <img
                                onError={(ev) =>
                                  (ev.target.src =
                                    "/assets/images/business_logo.svg")
                                }
                                src={
                                  !!data.business_image
                                    ? IMAGE_BASE_URL + data.business_image
                                    : "/assets/images/business_logo.svg"
                                }
                                alt="logo"
                              />
                            </div>
                          )}
                        </div>
                        <div className="card-header__timeline">
                          {!!data.shared ? (
                            !!data.shared_by_user.id_individual ? (
                              <h6 className="mb-0 text-capitalize">
                                {data.shared_by_user.user_name}
                              </h6>
                            ) : (
                              <h6 className="mb-0 text-capitalize">
                                {data.shared_by_user.business_name}
                              </h6>
                            )
                          ) : !!data.is_individual ? (
                            <h6 className="mb-0 text-capitalize">
                              {data.user_name}
                            </h6>
                          ) : (
                            <h6 className="mb-0 text-capitalize">
                              {data.business_name}
                            </h6>
                          )}
                          {!!data.shared ? (
                            !!data.shared_by_user.id_individual ? (
                              <span className="small text-capitalize">
                                {!!data.shared_by_user.work
                                  ? data.shared_by_user.work
                                  : ""}
                              </span>
                            ) : (
                              <span className="small text-capitalize">
                                {!!data.shared_by_user.specialization
                                  ? data.shared_by_user.specialization
                                  : ""}
                              </span>
                            )
                          ) : !!data.is_individual ? (
                            <span className="small text-capitalize">
                              {!!data.work ? data.work : ""}
                            </span>
                          ) : (
                            <span className="small text-capitalize">
                              {!!data.specialization ? data.specialization : ""}
                            </span>
                          )}
                        </div>

                        {!!data.shared
                          ? data.shared_by_user.user_id == profileData.id && (
                              <div className="feeds-post__action ml-auto">
                                <i className="ri-more-2-fill dot-icon"></i>
                                <ul className="important-list-drop dropdown-list">
                                  <li>
                                    <a
                                      href="javascript:;"
                                      onClick={() =>
                                        handleDeletePosts(index, data)
                                      }
                                    >
                                      <i className="ri-delete-bin-6-line"></i>{" "}
                                      Delete post
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:;"
                                      data-toggle="modal"
                                      data-target="#feeds-modal"
                                      onClick={() =>
                                        handleEditPosts(index, data)
                                      }
                                    >
                                      <i className="ri-edit-2-fill"></i> Edit
                                      post
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            )
                          : data.user_id == profileData.id && (
                              <div className="feeds-post__action ml-auto">
                                <i className="ri-more-2-fill dot-icon"></i>
                                <ul className="important-list-drop dropdown-list">
                                  <li>
                                    <a
                                      href="javascript:;"
                                      onClick={() =>
                                        handleDeletePosts(index, data)
                                      }
                                    >
                                      <i className="ri-delete-bin-6-line"></i>{" "}
                                      Delete post
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:;"
                                      data-toggle="modal"
                                      data-target="#feeds-modal"
                                      onClick={() =>
                                        handleEditPosts(index, data)
                                      }
                                    >
                                      <i className="ri-edit-2-fill"></i> Edit
                                      post
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            )}
                      </div>
                      <div className="card-body">
                        {data.post_text !== " " && (
                          <div
                            className="card-text mb-4"
                            dangerouslySetInnerHTML={{
                              __html: checkLinks(data.post_text),
                            }}
                          ></div>
                        )}
                        {convertLinks(data.post_text)}
                        {data.post_data?.length > 1 ? (
                          <Carousel
                            useKeyboardArrows={true}
                            dynamicHeight={true}
                            className="home-post"
                            showStatus={false}
                            showArrows={true}
                            swipeable={true}
                            onChange={handleOnChange}
                            showThumbs={false}
                            autoPlay={false}
                          >
                            {data.post_data.map((datas, i) => {
                              return (
                                <div
                                  onClick={(e) =>
                                    handleClickItem(data.post_data, i, e)
                                  }
                                  key={i}
                                >
                                  {datas.extension == "mp4" ||
                                  datas.extension == "mov" ||
                                  datas.extension == "MP4" ||
                                  datas.extension == "MOV" ||
                                  datas.extension == "webm" ||
                                  datas.extension == "WEBM" ? (
                                    <video
                                      onPlay={(e) => handlePlay(e)}
                                      id={"videoPause" + index + i}
                                      src={IMAGE_BASE_URL + datas.file_name}
                                      controls
                                      data-toggle="modal"
                                      data-target="#post-modal"
                                    />
                                  ) : (
                                    <div
                                      data-toggle="modal"
                                      data-target="#post-modal"
                                    >
                                      <img
                                        onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/image-placeholder.jpg")
                                        }
                                        className="d-block w-100"
                                        src={
                                          !!datas.file_name
                                            ? IMAGE_BASE_URL + datas.file_name
                                            : returnDefaultImage()
                                        }
                                        alt="First slide"
                                        data-toggle="modal"
                                        data-target="#post-modal"
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </Carousel>
                        ) : (
                          data.post_data.map((image, i) => {
                            return (
                              <div
                                className="text-center"
                                onClick={(e) =>
                                  handleSinglePost(data.post_data, index, e)
                                }
                                key={i}
                              >
                                {image.extension == "mp4" ||
                                image.extension == "mov" ||
                                image.extension == "MP4" ||
                                image.extension == "MOV" ||
                                image.extension == "webm" ||
                                image.extension == "WEBM" ? (
                                  <video
                                    onPlay={(e) => handlePlay(e)}
                                    id={"videoPause" + index}
                                    controls
                                    src={IMAGE_BASE_URL + image.file_name}
                                    data-toggle="modal"
                                    data-target="#post-modal"
                                  ></video>
                                ) : (
                                  <img
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/image-placeholder.jpg")
                                    }
                                    src={
                                      !!image.file_name
                                        ? IMAGE_BASE_URL + image.file_name
                                        : returnDefaultImage()
                                    }
                                    data-toggle="modal"
                                    data-target="#post-modal"
                                    className="w-100"
                                  />
                                )}
                              </div>
                            );
                          })
                        )}
                        {!!data.shared ? (
                          <div className="share-post d-flex align-items-center mt-3">
                            <div className="feeds-post__user mr-3 share-post-user">
                              {!!data.is_individual ? (
                                <img
                                  onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/image-placeholder.jpg")
                                  }
                                  src={
                                    !!data
                                      ? IMAGE_BASE_URL + data.user_profile
                                      : "/assets/images/image-placeholder.jpg"
                                  }
                                  alt="user"
                                />
                              ) : (
                                <div className="business-pofile-lead">
                                  <img
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/business_logo.svg")
                                    }
                                    src={
                                      !!data
                                        ? IMAGE_BASE_URL + data.business_image
                                        : "/assets/images/business_logo.svg"
                                    }
                                    alt="logo"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="share-post__user">
                              {!!data.is_individual ? (
                                <h6 className="mb-0 text-capitalize">
                                  {data.user_name}
                                </h6>
                              ) : (
                                <h6 className="mb-0 text-capitalize">
                                  {data.business_name}
                                </h6>
                              )}
                              {!!data.is_individual ? (
                                <span className="small text-capitalize">
                                  {!!data.work ? data.work : ""}
                                </span>
                              ) : (
                                <span className="small text-capitalize">
                                  {!!data.specialization
                                    ? data.specialization
                                    : ""}
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="card-footer">
                        <div className="post-status d-flex align-items-center justify-content-between">
                          <div className="post__action d-flex align-items-center">
                            <div className="post__action__inner d-flex justify-content-between">
                              <div className="feed__reaction react-icon">
                                <div className="reaction d-flex align-items-center">
                                  <div className="react-open">
                                    {!!data.post_react ? (
                                      data.reaction_list.length > 0 && (
                                        <div className="reaction-listing">
                                          {data.reaction_list.map((item, i) => {
                                            return (
                                              <div
                                                className={
                                                  !!Number(item.Reaction_check)
                                                    ? "my-react"
                                                    : ""
                                                }
                                                key={i}
                                              >
                                                {item.reaction == 1 ? (
                                                  <img
                                                    src="/assets/images/likepng1.png"
                                                    alt="Like"
                                                  />
                                                ) : item.reaction == 2 ? (
                                                  <img
                                                    src="/assets/images/love.png"
                                                    alt="Love"
                                                  />
                                                ) : item.reaction == 3 ? (
                                                  <img
                                                    src="/assets/images/clap.png"
                                                    alt="clab"
                                                  />
                                                ) : item.reaction == 4 ? (
                                                  <img
                                                    src="/assets/images/celebrate.png"
                                                    alt="celebrate"
                                                  />
                                                ) : item.reaction == 5 ? (
                                                  <img
                                                    src="/assets/images/think.png"
                                                    alt="Thinking"
                                                  />
                                                ) : (
                                                  (item.reaction = 6 ? (
                                                    <img
                                                      src="/assets/images/upset.png"
                                                      alt="upset"
                                                    />
                                                  ) : (
                                                    ""
                                                  ))
                                                )}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )
                                    ) : (
                                      <i className="ri-emotion-line" />
                                    )}
                                  </div>
                                  <a
                                    href="javascript:;"
                                    data-toggle="modal"
                                    data-target="#react-modal"
                                    onClick={() =>
                                      handleReactList(data.post_id)
                                    }
                                  >
                                    {" "}
                                    {data.post_react > 0
                                      ? numFormatter(data.post_react)
                                      : ""}
                                  </a>{" "}
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="react-model"
                                  >
                                    &nbsp;React
                                  </span>
                                </div>
                                <div className="react-box">
                                  <a
                                    className={
                                      data.reaction == 1 ? "last-rection" : ""
                                    }
                                    href="javascript:;"
                                    id="1"
                                  >
                                    <img
                                      src="/assets/images/300x300.gif"
                                      alt="Like"
                                      onClick={() =>
                                        handleReact(1, data, index)
                                      }
                                    />
                                  </a>
                                  <a
                                    className={
                                      data.reaction == 2 ? "last-rection" : ""
                                    }
                                    href="javascript:;"
                                    id="2"
                                  >
                                    <img
                                      src="/assets/images/love.gif"
                                      alt="Love"
                                      onClick={() =>
                                        handleReact(2, data, index)
                                      }
                                    />
                                  </a>
                                  <a
                                    className={
                                      data.reaction == 3 ? "last-rection" : ""
                                    }
                                    href="javascript:;"
                                    id="3"
                                  >
                                    <img
                                      src="/assets/images/clap.gif"
                                      alt="clab"
                                      onClick={() =>
                                        handleReact(3, data, index)
                                      }
                                    />
                                  </a>
                                  <a
                                    className={
                                      data.reaction == 4 ? "last-rection" : ""
                                    }
                                    href="javascript:;"
                                    id="4"
                                  >
                                    <img
                                      src="/assets/images/celebrate.gif"
                                      alt="celebrate"
                                      onClick={() =>
                                        handleReact(4, data, index)
                                      }
                                    />
                                  </a>
                                  <a
                                    className={
                                      data.reaction == 5 ? "last-rection" : ""
                                    }
                                    href="javascript:;"
                                    id="5"
                                  >
                                    <img
                                      src="/assets/images/thinking.gif"
                                      alt="Thinking"
                                      onClick={() =>
                                        handleReact(5, data, index)
                                      }
                                    />
                                  </a>
                                  <a
                                    className={
                                      data.reaction == 6 ? "last-rection" : ""
                                    }
                                    href="javascript:;"
                                    id="6"
                                  >
                                    <img
                                      src="/assets/images/upset.gif"
                                      alt="upset"
                                      onClick={() =>
                                        handleReact(6, data, index)
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="post-share-link">
                              <a
                                href="javascript:;"
                                className="btn-sm ml-auto link-txt"
                              >
                                <i className="ri-share-forward-line"></i> Share
                              </a>
                              <div className="share-box">
                                <ul className="list-unstyled mb-0">
                                  <li>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleShareFeedPosts(data)}
                                      className="link-color"
                                    >
                                      <i className="ri-file-list-3-line" />{" "}
                                      Share In feed
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="post-status__comments d-flex">
                            {data.post_views > 0 ? (
                              <span
                                style={{
                                  cursor:
                                    data.post_views > 0 ? "pointer" : "default",
                                }}
                                data-toggle="modal"
                                data-target={
                                  data.post_views > 0 ? "#view-modal" : ""
                                }
                                onClick={() => handelViewList(data)}
                              >
                                {numFormatter(data.post_views)} Views
                              </span>
                            ) : (
                              ""
                            )}
                            {data.share_count > 0 ? (
                              <span
                                style={{
                                  cursor:
                                    data.share_count > 0
                                      ? "pointer"
                                      : "default",
                                }}
                                data-toggle="modal"
                                data-target={
                                  data.share_count > 0 ? "#share-modal" : ""
                                }
                                onClick={() => handleShareList(data)}
                              >
                                {numFormatter(data.share_count)} Share
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="post_comment" id="post_comments">
                          <div
                            className="post_comment__counter d-flex my-3 justify-content-between align-items-center"
                            onClick={(e) =>
                              showCommentModel(index, data.post_id)
                            }
                          >
                            <span
                              style={{
                                cursor: !!data.comment_count
                                  ? "pointer"
                                  : "default",
                              }}
                              className="open-comment"
                            >
                              {!!data.comment_count > 0
                                ? "Comments" +
                                  " " +
                                  numFormatter(data.comment_count)
                                : "No Commnets"}
                            </span>
                            {!!data.comment_count && (
                              <span
                                style={{
                                  cursor: !!data.comment_count
                                    ? "pointer"
                                    : "default",
                                }}
                                className="open-comment"
                              >
                                <i className="ri-arrow-down-s-line"></i>
                                <i className="ri-arrow-down-s-line"></i>
                              </span>
                            )}
                          </div>
                          <div
                            id={"comment-listing-" + data.post_id}
                            className="comment-post"
                          >
                            {!!data.commentList &&
                            !!data.commentList.length > 0 &&
                            data.commentPage * 5 < data.actualComment ? (
                              <span
                                className="load-more"
                                onClick={() =>
                                  handleCommentPaginate(data, index)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <i className="ri-download-line align-middle"></i>{" "}
                                Load More
                              </span>
                            ) : (
                              ""
                            )}
                            {!!data.commentList &&
                            data.commentList.length > 0 &&
                            data.commentList.length > 4 ? (
                              <Scrollbars
                                id={"scroll-single-post-" + data.post_id}
                                style={{ width: 550, height: 375 }}
                                autoHide
                              >
                                {data.commentList.map((item, i) => (
                                  <div
                                    className="post_comment__listing my-3"
                                    key={i}
                                  >
                                    <div className="post_comment__listing__user">
                                      {!!item.is_individual ? (
                                        <img
                                          onError={(ev) =>
                                            (ev.target.src =
                                              "/assets/images/member-default.svg")
                                          }
                                          src={
                                            !!item
                                              ? IMAGE_BASE_URL + item.image
                                              : "/assets/images/member-default.svg"
                                          }
                                          alt="user"
                                        />
                                      ) : (
                                        <img
                                          onError={(ev) =>
                                            (ev.target.src =
                                              "/assets/images/image-placeholder.jpg")
                                          }
                                          src={
                                            !!item
                                              ? IMAGE_BASE_URL + item.image
                                              : "/assets/images/image-placeholder.jpg"
                                          }
                                          alt="logo"
                                        />
                                      )}
                                    </div>
                                    <div className="post_comment__listing__review">
                                      <div className="comment-bg">
                                        <p className="mb-1">{item.comment}</p>
                                      </div>
                                      <div className="view-reply">
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) =>
                                            handleOpenReplyInput(e, item)
                                          }
                                        >
                                          <i className="ri-reply-line" /> Reply
                                        </span>
                                        {!!item.reply ? (
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleOpenNestedReply(item, i)
                                            }
                                            className="open-reply"
                                          >
                                            <i className="ri-eye-line" />
                                            {!!item.showHide
                                              ? "Hide"
                                              : "View"}{" "}
                                            {item.reply} Reply
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div
                                        style={{ display: "none" }}
                                        id={`cmt-${item.post_id}-nested-${item.comment_id}`}
                                      >
                                        {!!item.subComment &&
                                        !!item.subComment.length > 0 &&
                                        item.replyCommentPage * 3 <
                                          item.actualReplyComment ? (
                                          <span
                                            style={{ cursor: "pointer" }}
                                            className="d-block text-center"
                                            onClick={() =>
                                              handleReplyCommentPaginate(
                                                item,
                                                i
                                              )
                                            }
                                          >
                                            View{" "}
                                            {item.reply -
                                              item.subComment.length}{" "}
                                            pervious replies
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {!!item.subComment &&
                                          item.subComment.map(
                                            (subComment, i) => {
                                              return (
                                                <div
                                                  className="post_comment__listing post__comment__nested "
                                                  key={i}
                                                >
                                                  <div className="post_comment__listing__user">
                                                    {!!subComment.is_individual ? (
                                                      <img
                                                        onError={(ev) =>
                                                          (ev.target.src =
                                                            "/assets/images/member-default.svg")
                                                        }
                                                        src={
                                                          !!subComment
                                                            ? IMAGE_BASE_URL +
                                                              subComment.image
                                                            : "/assets/images/member-default.svg"
                                                        }
                                                        alt="user"
                                                      />
                                                    ) : (
                                                      <img
                                                        onError={(ev) =>
                                                          (ev.target.src =
                                                            "/assets/images/image-placeholder.jpg")
                                                        }
                                                        src={
                                                          !!subComment
                                                            ? IMAGE_BASE_URL +
                                                              subComment.image
                                                            : "/assets/images/image-placeholder.jpg"
                                                        }
                                                        alt="logo"
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="post_comment__listing__commentbox">
                                                    <div className="comment-bg">
                                                      <p>
                                                        {subComment.comment}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                      <div
                                        style={{ display: "none" }}
                                        id={`cmt-${item.post_id}-input-${item.comment_id}`}
                                        className="post_comment__listing post__comment__nested"
                                      >
                                        <div className="post_comment__listing__user">
                                          {!!profileData.is_individual ? (
                                            <img
                                              onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/member-default.svg")
                                              }
                                              src={
                                                !!profileData
                                                  ? IMAGE_BASE_URL +
                                                    profileData.profile_image
                                                  : "/assets/images/member-default.svg"
                                              }
                                              alt="user"
                                            />
                                          ) : (
                                            <img
                                              onError={(ev) =>
                                                (ev.target.src =
                                                  "/assets/images/image-placeholder.jpg")
                                              }
                                              src={
                                                !!profileData
                                                  ? IMAGE_BASE_URL +
                                                    profileData.business_image
                                                  : "/assets/images/image-placeholder.jpg"
                                              }
                                              alt="logo"
                                            />
                                          )}{" "}
                                        </div>
                                        <div className="post_comment__listing__commentbox">
                                          <form
                                            onSubmit={(e) =>
                                              handleCommentReplySumbit(
                                                e,
                                                item,
                                                i
                                              )
                                            }
                                            autoComplete="off"
                                          >
                                            <Input
                                              type="text"
                                              name="replyComment"
                                              value={item.replyComment}
                                              onChange={(e) =>
                                                handleChangeReplyInput(
                                                  e,
                                                  item.comment_id
                                                )
                                              }
                                              className="form-control"
                                              placeholder="Write a comment"
                                            />
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Scrollbars>
                            ) : (
                              !!data.commentList &&
                              data.commentList.length > 0 &&
                              data.commentList.map((item, i) => (
                                <div
                                  className="post_comment__listing my-3"
                                  key={i}
                                >
                                  <div className="post_comment__listing__user">
                                    {!!item.is_individual ? (
                                      <img
                                        onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/member-default.svg")
                                        }
                                        src={
                                          !!item
                                            ? IMAGE_BASE_URL + item.image
                                            : "/assets/images/member-default.svg"
                                        }
                                        alt="user"
                                      />
                                    ) : (
                                      <img
                                        onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/image-placeholder.jpg")
                                        }
                                        src={
                                          !!item
                                            ? IMAGE_BASE_URL + item.image
                                            : "/assets/images/image-placeholder.jpg"
                                        }
                                        alt="logo"
                                      />
                                    )}
                                  </div>
                                  <div className="post_comment__listing__review">
                                    <div className="comment-bg">
                                      <p className="mb-1">{item.comment}</p>
                                    </div>
                                    <div className="view-reply">
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) =>
                                          handleOpenReplyInput(e, item)
                                        }
                                      >
                                        <i className="ri-reply-line" /> Reply
                                      </span>
                                      {!!item.reply ? (
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleOpenNestedReply(item, i)
                                          }
                                          className="open-reply"
                                        >
                                          <i className="ri-eye-line" />
                                          {!!item.showHide
                                            ? "Hide"
                                            : "View"}{" "}
                                          {item.reply} reply
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div
                                      style={{ display: "none" }}
                                      id={`cmt-${item.post_id}-nested-${item.comment_id}`}
                                    >
                                      {!!item.subComment &&
                                      !!item.subComment.length > 0 &&
                                      item.replyCommentPage * 3 <
                                        item.actualReplyComment ? (
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className="d-block text-center"
                                          onClick={() =>
                                            handleReplyCommentPaginate(item, i)
                                          }
                                        >
                                          View{" "}
                                          {item.reply - item.subComment.length}{" "}
                                          pervious replies
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {!!item.subComment &&
                                        item.subComment.map((subComment, i) => {
                                          return (
                                            <div
                                              className="post_comment__listing post__comment__nested "
                                              key={i}
                                            >
                                              <div className="post_comment__listing__user">
                                                {!!subComment.is_individual ? (
                                                  <img
                                                    onError={(ev) =>
                                                      (ev.target.src =
                                                        "/assets/images/image-placeholder.jpg")
                                                    }
                                                    src={
                                                      !!subComment
                                                        ? IMAGE_BASE_URL +
                                                          subComment.image
                                                        : "/assets/images/image-placeholder.jpg"
                                                    }
                                                    alt="user"
                                                  />
                                                ) : (
                                                  <img
                                                    onError={(ev) =>
                                                      (ev.target.src =
                                                        "/assets/images/image-placeholder.jpg")
                                                    }
                                                    src={
                                                      !!subComment
                                                        ? IMAGE_BASE_URL +
                                                          subComment.image
                                                        : "/assets/images/image-placeholder.jpg"
                                                    }
                                                    alt="logo"
                                                  />
                                                )}
                                              </div>
                                              <div className="post_comment__listing__commentbox">
                                                <div className="comment-bg">
                                                  <p>{subComment.comment}</p>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                    <div
                                      style={{ display: "none" }}
                                      id={`cmt-${item.post_id}-input-${item.comment_id}`}
                                      className="post_comment__listing post__comment__nested"
                                    >
                                      <div className="post_comment__listing__user">
                                        {!!profileData.is_individual ? (
                                          <img
                                            onError={(ev) =>
                                              (ev.target.src =
                                                "/assets/images/member-default.svg")
                                            }
                                            src={
                                              !!profileData
                                                ? IMAGE_BASE_URL +
                                                  profileData.profile_image
                                                : "/assets/images/member-default.svg"
                                            }
                                            alt="user"
                                          />
                                        ) : (
                                          <img
                                            onError={(ev) =>
                                              (ev.target.src =
                                                "/assets/images/image-placeholder.jpg")
                                            }
                                            src={
                                              !!profileData
                                                ? IMAGE_BASE_URL +
                                                  profileData.business_image
                                                : "/assets/images/image-placeholder.jpg"
                                            }
                                            alt="logo"
                                          />
                                        )}{" "}
                                      </div>
                                      <div className="post_comment__listing__commentbox">
                                        <form
                                          onSubmit={(e) =>
                                            handleCommentReplySumbit(e, item, i)
                                          }
                                          autoComplete="off"
                                        >
                                          <Input
                                            type="text"
                                            name="replyComment"
                                            value={item.replyComment}
                                            onChange={(e) =>
                                              handleChangeReplyInput(
                                                e,
                                                item.comment_id
                                              )
                                            }
                                            className="form-control"
                                            placeholder="Write a comment"
                                          />
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>

                          <div className="post__commtent__user">
                            <div className="feeds-post__header d-flex flex-wrap align-items-center">
                              <div className="feeds-post__user">
                                {!!profileData.is_individual ? (
                                  <img
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/member-default.svg")
                                    }
                                    src={
                                      !!profileData
                                        ? IMAGE_BASE_URL +
                                          profileData.profile_image
                                        : "/assets/images/member-default.svg"
                                    }
                                    alt="user"
                                  />
                                ) : (
                                  <img
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/business_logo.svg")
                                    }
                                    src={
                                      !!profileData.business_image
                                        ? IMAGE_BASE_URL +
                                          profileData.business_image
                                        : profileData.profile_image
                                        ? IMAGE_BASE_URL +
                                          profileData.profile_image
                                        : "/assets/images/business_logo.svg"
                                    }
                                    alt="logo"
                                  />
                                )}{" "}
                              </div>
                              <form
                                onSubmit={(e) =>
                                  handleSumbitComment(e, data, index)
                                }
                                className="comment-form"
                                autoComplete="off"
                              >
                                <Input
                                  type="text"
                                  name="comment"
                                  value={data.comment}
                                  onChange={(e) =>
                                    handleChangeComment(e, data.post_id)
                                  }
                                  className="form-control"
                                  placeholder="Write a comment"
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : !getProfilePost.length &&
                  pathname !==
                    `/account/${profileData?.user_slug}/profile-post` &&
                  !getProfilePostLoading && (
                    <h5 className="text-center mb-3">
                      There are no more post to show right now
                    </h5>
                  )}
            </div>

            {networkTotal === 0 && (
              <div className="col-sm-12 col-lg-3">
                <div className="bg-white p-3 br-20 sticky-network custom-shadow">
                  <div className="row network mb-0">
                    <div className="col-md-12">
                      <div className="flex">
                        <div className="w-full p-3">
                          <div>
                            {" "}
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          </div>
                          <h6>Loading Networks...</h6>
                          <div className="w-full  p-2">
                            <div className="d-flex">
                              <div className="mb-2">
                                <Skeleton
                                  size="5rem"
                                  className="mr-2 mb-2"
                                ></Skeleton>
                                <Skeleton
                                  size="5rem"
                                  className="mr-2"
                                ></Skeleton>
                              </div>
                              <div>
                                <Skeleton
                                  size="5rem"
                                  className="mr-2 mb-2"
                                ></Skeleton>
                                <Skeleton
                                  size="5rem"
                                  className="mr-2"
                                ></Skeleton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!profileData.is_supporter && (
              <div className="col-sm-12 col-lg-3">
                {networkTotal > 0 && (
                  <div className="bg-white p-3 br-20 sticky-network custom-shadow">
                    <div className="row network mb-0">
                      <div className="col-md-12">
                        <a
                          href="javascript:;"
                          onClick={() => openNetwork()}
                          className="link-color"
                        >
                          <h6 className="mb-3">
                            Network ({!!networkTotal ? networkTotal : null})
                          </h6>{" "}
                        </a>
                      </div>

                      {networkList?.slice(0, 4).map((item, i) => {
                        return (
                          <div
                            className="col-lg-6 col-sm-3 col-50 col-mb-50"
                            key={i}
                          >
                            <div className="post_network__box text-center">
                              {!!item.is_individual ? (
                                <div className="network__box__img normal-user mx-auto">
                                  <a
                                    onClick={() => handleOpenProfile(item)}
                                    href="javascript:;"
                                  >
                                    {" "}
                                    <img
                                      onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/member-default.svg")
                                      }
                                      src={
                                        !!item.user_profile
                                          ? IMAGE_BASE_URL + item.user_profile
                                          : "/assets/images/member-default.svg"
                                      }
                                      alt="user"
                                    />
                                  </a>
                                </div>
                              ) : (
                                <div className="network__box__img business-user mx-auto">
                                  <a
                                    onClick={() => handleOpenProfile(item)}
                                    href="javascript:;"
                                  >
                                    <img
                                      onError={(ev) =>
                                        (ev.target.src =
                                          "/assets/images/business_logo.svg")
                                      }
                                      src={
                                        !!item.user_profile
                                          ? IMAGE_BASE_URL + item.user_profile
                                          : "/assets/images/business_logo.svg"
                                      }
                                      alt="user"
                                    />
                                  </a>
                                </div>
                              )}
                              <div className="network__box__info mt-2">
                                <a
                                  href="javascript:;"
                                  onClick={() => handleOpenProfile(item)}
                                  className="link-color"
                                >
                                  <h5 className="text-capitalize h6 mb-0">
                                    {!!item.is_individual
                                      ? item.user_name.length > 15
                                        ? `${item.user_name?.slice(0, 15)}...`
                                        : item.user_name
                                      : item.business_name.length > 15
                                      ? `${item.business_name?.slice(0, 15)}...`
                                      : item.business_name}
                                  </h5>
                                </a>
                                <p className="mb-0 small">
                                  {" "}
                                  {!!item.is_individual
                                    ? item.work?.length > 15
                                      ? `${item.work?.slice(0, 20)}...`
                                      : item.work
                                    : item.specialization?.length > 15
                                    ? `${item.specialization.slice(0, 20)}...`
                                    : item.specialization}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {networkTotal > 4 && (
                        <div className="col-md-12 text-center d-block">
                          <a
                            onClick={() => openNetwork()}
                            href="javascript:;"
                            className="link-color show-more"
                          >
                            Show More
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <SharePost
        HomeState={HomeState}
        profileData={profileData}
        addToast={addToast}
      />
      <SingleProfilePost profilePostState={profilePostState} />
      <ProfilePostModel profilePostState={profilePostState} />
    </>
  );
};
export default ProfilePosts;
