import {
  GET_PROMOTIONAL_FAIULRE,
  GET_PROMOTIONAL_REQUEST,
  GET_PROMOTIONAL_SUCCESS,
} from "./HomeConstants";

const initialState = {
  promotionalHubContent: {
    getPromotionalLoading: false,

    getPromotionalPageStatus: "",
    getPromotionalPageSuccess: "",
    getPromotionalPageMessage: "",
    getPromotionalPageData: [],
    getPromotionalPageHeader:{}
  },
};

export const PromotionalHubReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROMOTIONAL_REQUEST:
            return {
                ...state,
                ...{
                    promotionalHubContent: {
                        ...state.promotionalHubContent,
                        ...{ getPromotionalLoading: true },
                    },
                },
            };

    case GET_PROMOTIONAL_SUCCESS:
      
      let getPromotionalHubContentSuccess = {
        getPromotionalLoading: false,
        getPromotionalPageStatus: 200,
        getPromotionalPageSuccess: action.response.data.success,
        getPromotionalPageMessage: action.response.data.message,
        getPromotionalPageData: action?.response?.data
          ? action.response.data?.list
          : "",
          getPromotionalPageHeader :action?.response?.data ? action?.response?.data?.header:""
      };

      return {
        ...state,
        ...{
          promotionalHubContent: {
            ...state.promotionalHubContent,
            ...getPromotionalHubContentSuccess,
          },
        },
      };

    case GET_PROMOTIONAL_FAIULRE:
      let getPromotionalFailure = {
        getPromotionalLoading: false,
        getPromotionalPageStatus: action.status,
        getPromotionalPageSuccess: false,
        getPromotionalPageMessage: action.response.data.message,
        getPromotionalPageData: [],
        getPromotionalPageHeader:{}
      };
      return {
        ...state,
        ...{
          promotionalHubContent: {
            ...state.promotionalHubContent,
            ...getPromotionalFailure
          },
     
        },
      };
      default:
        return state;
  }
};
