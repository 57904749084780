import {
  CLEAR_MAIN_SLIDER_RESPONSE,
  MAIN_SLIDER_DATA_FAILURE,
  MAIN_SLIDER_DATA_REQUEST,
  MAIN_SLIDER_DATA_SUCCESS,
  CHECK_AUTH_REQUEST,
  CHECK_AUTH_SUCCESS,
  CHECK_AUTH_FAILURE,
} from "./MainConstants";

const initialState = {
  sliderApi: {
    sliderLoading: "",
    sliderStatus: "",
    sliderMessage: "",
    sliderData: [],
  },
  checkAuthApi: {
    checkAuthLoading: "",
    checkAuthStatus: "",
    checkAuthMessage: "",
    checkAuthData: [],
  },
};

export const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAIN_SLIDER_DATA_REQUEST:
      return {
        ...state,
        ...{ sliderApi: { ...state.sliderApi, ...{ sliderLoading: true } } },
      };
    case MAIN_SLIDER_DATA_SUCCESS:
      const {
        response: {
          data: { message, status, data },
        },
      } = action;
      let sliderSuccess = {
        sliderLoading: false,
        sliderStatus: status,
        sliderMessage: message,
        sliderData: data,
      };
      return {
        ...state,
        ...{
          sliderApi: { ...state.sliderApi, ...sliderSuccess },
        },
      };
    case MAIN_SLIDER_DATA_FAILURE:
      let sliderFailure = {
        sliderLoading: false,
        sliderStatus: action.response.status,
        sliderMessage: "",
        sliderData: [],
      };
      return {
        ...state,
        ...{
          sliderApi: { ...state.sliderApi, ...sliderFailure },
        },
      };
    case CLEAR_MAIN_SLIDER_RESPONSE:
      let clearSliderResponse = {
        sliderStatus: "",
        sliderMessage: "",
        sliderData: [],
      };

      return {
        ...state,
        ...{
          sliderApi: { ...state.sliderApi, ...clearSliderResponse },
        },
      };

    case CHECK_AUTH_REQUEST: {
      return {
        ...state,
        ...{
          checkAuthApi: {
            ...state.checkAuthApi,
            ...{ checkAuthLoading: true },
          },
        },
      };
    }
    default:
      return state;
  }
};
