import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { getCookie } from "../../library/utilities/functions";
import { Accordion, AccordionTab } from "primereact/accordion";
import moment from "moment";

import { SOCKET } from "../../library/urls";
import { useToasts } from "react-toast-notifications";
import {
  acceptEventRequestApi,
  acceptSchedule,
  declineEventRequest,
  declineSchedule,
} from "./PoolsChatAction";
import { useDispatch, useSelector } from "react-redux";
import { EVENT_REQUEST_NAME } from "../../library/common/constants/NameConstant";
export default function AppointMentDetails({
  scheduleList,
  scheduleListPage,
  setScheduleListPage,
  changEventstate,
  messageDetails,
}) {
  // browser stroage //

  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  // toast notification//
  const { addToast } = useToasts();

  // redux //
  const dispatch = useDispatch();
  const allChatList = useSelector((state) => state.chatListReducer);
  const {
    getScheduleListData: { getScheduleList, getScheduleListCounts },
  } = allChatList;

  // states//
  const [actionLoader, setActionLoader] = useState(false);
  const [scrollNextPage, setScrollNextPage] = useState(false);
  const [allEventsList, setAllEventsList] = useState([]);

  // scroll function //
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e;
    if (
      scrollHeight - (Math.floor(scrollTop) + clientHeight) === 0 &&
      scheduleList?.length > 0 &&
      scrollNextPage === false
    ) {
      setScrollNextPage(true);
    }
  };

  // use for setMedia page and scroll next state -- >//
  useEffect(() => {
    if (!!scrollNextPage) {
      setScheduleListPage(scheduleListPage + 1);
      setScrollNextPage(false);
    }
  }, [scrollNextPage]);

  // ---accept event request handler ---//
  const handleAcceptEventRequst = (e, params) => {
    setActionLoader(true);

    dispatch(
      acceptEventRequestApi({
        event_id: params.event_id,
        notification_type: EVENT_REQUEST_NAME.ACCEPT_EVENT_REQUEST,
        from: params?.sender_id,
        to: params?.receiver_id,
      })
    )
      .then((res) => {
        addToast("Request accepted successful.", {
          appearance: "success",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
        setActionLoader(false);
        if (res.response.data.status === 200) {
          SOCKET.emit("get-schedule-events", {
            sender_id: profileData.id,
            reciever_id:
              messageDetails?.type === "user"
                ? history?.location?.pathname.includes("/search-profile")
                  ? messageDetails?.id
                  : messageDetails?.user_id
                : messageDetails?.id,
            page: 1,
            action: true,
          });

          SOCKET.off("set-schedule-events").on(
            "set-schedule-events",
            (response) => {
              dispatch(acceptSchedule(response));
            }
          );

          params.for = "accept";

          SOCKET.emit("event-accept-decline", params);
          changEventstate();
        }
      })
      .catch((err) => {
        addToast("Something went wrong try again.", {
          appearance: "error",
          autoDismiss: true,
        });
        setActionLoader(false);
      });
  };

  // -- decline request handler emit socket and call api for notification//
  const handleRequestDecline = (e, params) => {
    setActionLoader(true);

    dispatch(
      declineEventRequest({
        event_id: params.event_id,
        notification_type: EVENT_REQUEST_NAME.DECLINE_EVENT_REQUEST,
        from: params?.sender_id,
        to: params?.receiver_id,
      })
    )
      .then((res) => {
        addToast("Decline request successful.", {
          appearance: "success",
          autoDismiss: true,
        });
        setActionLoader(false);
        if (res.response.data.status === 200) {
          SOCKET.emit("get-schedule-events", {
            sender_id: profileData.id,
            reciever_id:
              messageDetails?.type === "user"
                ? history?.location?.pathname.includes("/search-profile")
                  ? messageDetails?.id
                  : messageDetails?.user_id
                : messageDetails?.id,
            page: 1,
            action: true,
          });

          SOCKET.off("set-schedule-events").on(
            "set-schedule-events",
            (response) => {
              dispatch(declineSchedule(response));
            }
          );

          params.for = "declined";

          SOCKET.emit("event-accept-decline", params);
          changEventstate();
        }
      })
      .catch((err) => {
        addToast("Something went wrong try again.", {
          appearance: "error",
          autoDismiss: true,
        });
        setActionLoader(false);
      });
  };

  // set updated event list //
  useEffect(() => {
    if (scheduleList?.length > 0) {
      const updatedList = scheduleList.map((request, index) => ({
        ...request,
      }));

      setAllEventsList(updatedList);
    }
  }, [scheduleList]);

  return (
    <Accordion className="event-accordian" activeIndex={0}>
      <AccordionTab header="Awaiting Approval">
        <Scrollbars onScrollFrame={handleScroll} style={{ height: 250 }}>
          {!!allEventsList?.length > 0 &&
            allEventsList
              ?.filter(
                (message) => message?.declined === 0 && message?.accepted === 0
              )
              .map((message, index) => (
                <div
                  key={index}
                  className="assignment-list mt-3 position-relative align-items-end justify-content-between flex-wrap"
                >
                  <div key={index} className="assignment-content text-dark">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <strong className="mb-0">
                        {profileData?.id === message?.sender_id
                          ? "You:"
                          : message.sender_name + ":"}
                      </strong>
                      {message?.sender_id === profileData?.id &&
                        !message?.isCancel && (
                          <p className="text-dark mb-0 pending-text">
                            Pending approval
                          </p>
                        )}
                    </div>

                    {
                      <p className="mb-0 small">{`request a ${
                        message?.request_nature === "Custom"
                          ? message.title
                          : message?.request_nature
                      }
          on
          ${message?.date && moment(message?.date).format("lll")}`}</p>
                    }

                    <p className="mb-0 text-break">
                      {message?.description?.length > 25
                        ? message?.description.slice(0, 25) + "..."
                        : message?.description}
                    </p>

                    {!!message?.isCancel && (
                      <p>The request has been cancelled.</p>
                    )}
                  </div>

                  <div
                    className="confirm-btn-check"
                    style={{ marginLeft: "auto" }}
                  >
                    {message?.isCancel === 0 &&
                      message?.receiver_id === profileData?.id && (
                        <div className="d-flex align-items-center">
                          <div>
                            <i
                              className="ri-checkbox-circle-fill  accept_request_event"
                              onClick={(e) =>
                                !actionLoader
                                  ? handleAcceptEventRequst(e, message)
                                  : null
                              }
                            ></i>
                          </div>

                          <div className="pl-2">
                            <i
                              className="ri-close-circle-fill cancel_reuqest_event"
                              onClick={(e) =>
                                !actionLoader
                                  ? handleRequestDecline(e, message)
                                  : null
                              }
                            ></i>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              ))}
        </Scrollbars>
      </AccordionTab>
      <AccordionTab header="Upcoming Assignments">
        <Scrollbars onScrollFrame={handleScroll} style={{ height: 250 }}>
          {!!allEventsList?.length > 0 &&
            allEventsList
              ?.filter((message) => message?.accepted === 1)
              .map((message, index) => (
                <div
                  key={index}
                  className="assignment-list mt-3 position-relative align-items-end justify-content-between flex-wrap"
                >
                  <div key={index} className="assignment-content text-dark">
                    <strong className="mb-0">
                      {profileData?.id === message?.sender_id
                        ? "You:"
                        : message.sender_name + ":"}
                    </strong>

                    {!message?.isCancel && (
                      <p className="mb-0 small text-break">{`request a ${
                        message?.request_nature === "Custom"
                          ? message.title
                          : message?.request_nature
                      }
          on
          ${message?.date && moment(message?.date).format("lll")}`}</p>
                    )}

                    <p className="mb-0 text-break">{message?.description}</p>

                    {!!message?.isCancel && (
                      <p>The request has been cancelled.</p>
                    )}
                  </div>
                </div>
              ))}
        </Scrollbars>
      </AccordionTab>
    </Accordion>
  );
}
