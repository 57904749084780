import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { css } from "@emotion/react";
import { useToasts } from "react-toast-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import { resetPasswordDataApi } from "../../library/api/ResetPasswordApiServices";
import { Input } from "../../library/common/components";
import {
  addDocumentTitle,
  addValidation,
} from "../../library/utilities/functions";
import {
  changeResetPasswordInput,
  clearResetApiResponse,
  clearResetPasswordInput,
} from "./ResetPasswordAction";
import { resetPasswordValidation } from "./Validation";
import Footer from "../../library/common/components/LandingPage/HomeSection/Footer";
import axios from "axios";
import { API_BASE_URL } from "../../library/urls";
import { HashLoader } from "react-spinners";
import { FaDivide } from "react-icons/fa";
const override = css`
  position: absolute;
  top: 50%;
  right: 47%;
  z-index: 9999;
`;
const ResetPassword = () => {
  const loc = useLocation();
  const token = loc.search.split("=")[1];
  const dispatch = useDispatch();
  const history = useHistory();
  const passwordEl = useRef(null);
  const { addToast } = useToasts();
  const passwordElValidation = useRef(null);
  const confirmPasswordValidation = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const resetPasswordState = useSelector((state) => state.ResetPasswordReducer);
  const {
    resetPasswordInput: { password, verifyPassword },
  } = resetPasswordState;
  const {
    resetApiResponse: { resetApiLoading, resetMessage, resetApiStatus },
  } = resetPasswordState;
  useEffect(() => {
    if (!!resetApiStatus && resetApiStatus == "200") {
      addToast(resetMessage, {
        appearance: "success",
        autoDismiss: true,
      
      });
      history.push("/login");
    }

    if (resetApiStatus == "400") {
      addToast(resetMessage, {
        appearance: "error",
        autoDismiss: true,
      
      });
      dispatch(clearResetApiResponse());
    }
  }, [resetApiStatus]);
  useEffect(() => {
    addDocumentTitle("Reset Password");
    passwordEl?.current?.focus();
    return () => {
      dispatch(clearResetPasswordInput());
      dispatch(clearResetApiResponse());
    };
  }, []);
  //this function is used when the reset password input field changing.
  const handleChange = (e) => {
    const target = e.target;
    dispatch(changeResetPasswordInput({ [target.name]: target.value }));
  };
  //this function is used to send details in api for reset password and then hit api for reset password
  const handleSumbit = () => {
    if (!!password.length && !!verifyPassword.length) {
      let validation = {
        is_valid_password: { status: false, validation: passwordElValidation },
        is_valid_confirmPassword: {
          status: false,
          validation: confirmPasswordValidation,
        },
      };
      validation = resetPasswordValidation(
        validation,
        resetPasswordState.resetPasswordInput
      );
      let { is_valid_password, is_valid_confirmPassword } = validation;
      addValidation(validation);
      if (
        is_valid_password.status &&
        is_valid_confirmPassword.status &&
        password == verifyPassword
      ) {
        let bodyParameter = {
          token: token,
          password: password,
        };
        dispatch(resetPasswordDataApi(bodyParameter));
      }
    } else {
      setError(true);
    }
  };
  const isTokenValid = () => {
    axios.get(API_BASE_URL + `/check-token?token=${token}`).then((res) => {
      if (!res.data.success) {
        addToast("This link has been expired", {
          appearance: "error",
          autoDismiss: true,
      
        });
        history.push("/login");
      } else {
        setLoading(false)
      }
    });

  };
  useEffect(() => {
    isTokenValid();
  }, [token]);
  const renderHashLoader = () => {
    return (
      <HashLoader
        color={"#2f2529"}
        loading={loading}
        width={6}
        height={50}
        css={override}
      />
    )
  }
  const renderResetPassword = () => {
    return (
      <>
        <div className="container">
          <div className="row align-items-center my-3 my-lg-4 pb-5">
            <div className="col-md-6 col-lg-6 mb-4 mb-md-0 d-none d-md-block">
              <div className="login-svg">
                <img src="/assets/images/reset-vector.svg" alt="reset password" />
              </div>
            </div>

            <div className="col-md-6 col-lg-5 ml-lg-auto">
              <div className="form mt-4 bg-white">
                <div className="form__header d-flex align-items-center mb-4 justify-content-center flex-wrap">
                  <img
                    src="/assets/images/icon.svg"
                    alt="icon"
                    className="site-icon"
                  />
                  <h1 className="font-weight-bold mb-0 h3 w-100 text-center">
                    Reset Password
                  </h1>
                </div>
                <form>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="position-relative password-field">
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        ref={passwordEl}
                        value={password}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Password"
                      />
                      {!!password && (
                        <i
                          className={
                            showPassword ? "ri-eye-line" : "ri-eye-off-line"
                          }
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </div>
                    {error && !password.length && (
                      <p className="error-message">Please enter password.</p>
                    )}
                    <p
                      style={{ display: "none" }}
                      ref={passwordElValidation}
                      className="error-message"
                    >
                      Password must contain at least 8 characters, including
                      upper/lowercase, number and special character.{" "}
                    </p>
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <div className="position-relative password-field">
                      <Input
                        type={showVerifyPassword ? "text" : "password"}
                        name="verifyPassword"
                        value={verifyPassword}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Verify Password"
                      />
                      {!!verifyPassword && (
                        <i
                          className={
                            showVerifyPassword ? "ri-eye-line" : "ri-eye-off-line"
                          }
                          onClick={() =>
                            setShowVerifyPassword(!showVerifyPassword)
                          }
                        />
                      )}
                    </div>
                    {error && !verifyPassword.length && (
                      <p className="error-message">
                        Please enter verify password.
                      </p>
                    )}
                    <p
                      style={{ display: "none" }}
                      ref={confirmPasswordValidation}
                      className="error-message"
                    >
                      The passwords don't match. Please try again.
                    </p>
                  </div>
                  <div className="form-group mb-0">
                    <div className="d-flex flex-column mt-4">
                      <a
                        href="javascript:;"
                        onClick={handleSumbit}
                        className={
                          !!resetApiLoading
                            ? "btn btn-secondary disabled"
                            : "btn btn-secondary btn-primary "
                        }
                      >
                        Reset
                      </a>
                      <ClipLoader
                        color={"#fff"}
                        loading={!!resetApiLoading ? true : false}
                        css={override}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
  return (
    loading ? renderHashLoader() : renderResetPassword()
  );
};
export default ResetPassword;
