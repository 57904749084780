import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  clearAcceptRequestResponse,
  clearDeclineRequestResponse,
} from "../../../../modules/Allnotifications/NotificationAction";

import {
  clearMembersAcceptRequestResponse,
  clearMembersDeclineRequestResponse,
} from "../../../../modules/AllMembersNotifications/MembersNotificationAction";
import {
  isServiceLoading,
  serviceList,
} from "../../../../modules/SearchList/SearchListAction";
import {
  acceptRequestApi,
  acceptMembersRequestApi,
  declineRequestApi,
  logOutApi,
  onScreenNotificationsApi,
  readAllNotificationsApi,
  declineMembersRequestApi,
} from "../../../api/NotificationApiService";
import {
  searchListDataApi,
  searchServiceApi,
} from "../../../api/SearchApiService";

import {
  IMAGE_BASE_URL,
  SOCKET,
  READ_SERVICE_NOTIFICATION,
  ADMIN,
} from "../../../urls";
import {
  clearCookies,
  getCookie,
  isMobile,
  is_page_exist_private,
  replceMultiStringWithSIngle,
  setCookie,
} from "../../../utilities/functions";
import { changeIsAuth } from "../../actions/AuthActions";

import Input from "../Input/Input";
import { changeSearchName, changeUnreadFriendMessage } from "./ToolbarActions";
import { useToasts } from "react-toast-notifications";
import { clearLoginApiResponse } from "../../../../modules/Login/LoginActions";
import axios from "axios";
import {
  allFriendandGroupList,
  changeChatDetails,
  clearChatList,
} from "../../../../modules/PoolsChat/PoolsChatAction";
import { clearFriendListApiResponse } from "../../../../modules/MyNetwork/MyNetworkAction";
import { clearProfileData } from "../../../../modules/Account/AccountAction";
import SideBarProfileMenu from "../../../../modules/Home/common/SideBarProfileMenu";

// import { set } from "react-hook-form";
import { getProfileApi } from "../../../api/AccountApiService";

const Toolbar = () => {
  const removeActiveClass = useRef();
  const [ishaveData, setIsHaveData] = useState(false);
  const [friendID, setFriendID] = useState();
  const [isItemRender, setIsItemRender] = useState(false);
  const [allChatMessage, setAllChatMessages] = useState();
  const [notificationData, setNotificationData] = useState([]);
  const [isStopLoading, setIsStopLoading] = useState([]);
  const authState = useSelector((state) => state.Authreducer);
  const [search, setSearch] = useState("");
  const { addToast } = useToasts();

  const checkMobile = isMobile();
  let { pathname } = useLocation();
  const prevCountRef = useRef();
  const history = useHistory();
  const closeSideBar = useRef();

  const dispatch = useDispatch();
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  const toolbarState = useSelector((state) => state.ToolbarReducer);
  const notificationState = useSelector((state) => state.NotificationReducer);
  const membersNotificationState = useSelector(
    (state) => state.MembersNotificationReducer
  );
  const searchListState = useSelector((state) => state.SearchListReducer);
  const AccountState = useSelector((state) => state.AccountReducer);
  const {
    basicInfo: { profileImgData, businessImgData },
  } = AccountState;
  const {
    getAllSellerDataApi: { getAllSellerDatas },
  } = AccountState;
  const {
    onScreenNotificationApi: {
      onScreenNotification,
      allNotificationCount,
      unReadCount,
      onScreenNotificationStatus,
    },
  } = toolbarState;
  const {
    acceptRequestApi: { acceptRequestSuccess, acceptRequestStatus },
  } = notificationState;

  const {
    acceptMembersRequestApi: { acceptMembersRequestStatus },
  } = membersNotificationState;

  const {
    declineRequestApi: { declineRequestStatus },
  } = notificationState;
  const allChatList = useSelector((state) => state.chatListReducer);

  const { getChatandGroupList } = allChatList;

  const {
    declineMembersRequestApi: { declineMembersRequestStatus },
  } = membersNotificationState;
  const {
    searchNameInput: { onChangeSearchName, reduxSearchName },
    unreadUserMessageCount,
  } = toolbarState;
  const {
    getProfileApi: { getPrifileLoading },
  } = AccountState;

  // USE WHEN USER GO TO NOTIFICATION ICON
  useEffect(() => {
    $(".notiifcation").click(function () {
      $(this).toggleClass("show");
    });
    var device_width = screen.width;
    if (device_width < 768) {
      let mobileToggle = document.querySelector(".mobile-toggle");
      let offScreenMenu = document.querySelector(".offscreen-menu");
      mobileToggle.addEventListener("click", function () {
        offScreenMenu.classList.toggle("show");
      });

      document
        .querySelector(".menu-close")
        .addEventListener("click", function () {
          document.querySelector(".offscreen-menu").classList.remove("show");
        });
    }
    return () => {
      dispatch(clearAcceptRequestResponse());
      dispatch(clearDeclineRequestResponse());
    };
  }, [screen.width]);

  let friendId = !!getCookie("friendId") ? Number(getCookie("friendId")) : null;
  useEffect(() => {
    setFriendID(friendId);
  }, [friendId]);

  // USE TO LOGOUT
  const handleLogout = async () => {
    //  CALL SOCKET ON LOGOUT SEND STATUS TO ALL FRIEND
    SOCKET.emit("send_my_message_status", {
      sender_id: profileData.id,
      reciever_id: null,
    });

    dispatch(clearChatList([]));

    dispatch(allFriendandGroupList([]));
    dispatch(logOutApi());
    // UPDATE READ = 0 WHEN USER LOGOUT
    SOCKET.emit("update_all_message_read_unread_status", {
      sender_id: !!profileData ? profileData.id : null,
    });

    // new merged --function ---//

    // WHEN USER LOGGED OUT CLEAR LOGIN RESPONSE,COOKIES DATA AND SEARCH NAME
    dispatch(clearLoginApiResponse());
    dispatch(clearProfileData());
    dispatch(changeSearchName({ reduxSearchName: "" }));

    //CHANGE AUTH STATE FALSE

    dispatch(changeIsAuth({ is_auth: false }));
    addToast("Logout Successful", {
      appearance: "success",
      autoDismiss: true,
      // autoDismissTimeout: 2000,
    });
    history.push("/");
    dispatch(changeUnreadFriendMessage({ unreadFriendMessage: [] }));
    window.localStorage.removeItem("servicenotification");
    window.localStorage.removeItem("clickServiceNotification");
    window.localStorage.removeItem("ClickOnSeachProfile");
    window.localStorage.removeItem("tabActive");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("isSession");
    window.sessionStorage.clear();

    SOCKET.close();
    clearCookies();
  };

  // USER ACCEPT FRIEND REQUESTS
  useEffect(() => {
    if (!!pathname && !!getCookie("token_id")?.length)
      dispatch(onScreenNotificationsApi());
  }, [pathname]);

  useEffect(() => {
    if (!!acceptRequestStatus) {
      dispatch(onScreenNotificationsApi());
      dispatch(clearAcceptRequestResponse());
    }
  }, [acceptRequestStatus]);

  // USER ACCEPT MEMEBER REQUESTS
  useEffect(() => {
    if (!!acceptMembersRequestStatus) {
      dispatch(onScreenNotificationsApi());
      dispatch(clearMembersAcceptRequestResponse());
    }
  }, [acceptMembersRequestStatus]);

  // USER DECLINE REQUESTS
  useEffect(() => {
    if (!!declineRequestStatus) {
      dispatch(clearDeclineRequestResponse());
      dispatch(onScreenNotificationsApi());
    }
  }, [declineRequestStatus]);

  // USER DECLINE MEMBER REQUEST
  useEffect(() => {
    if (!!declineMembersRequestStatus) {
      dispatch(onScreenNotificationsApi());
      dispatch(clearMembersDeclineRequestResponse());
    }
  }, [declineMembersRequestStatus]);

  const handleSumbit = async (e) => {
    e.preventDefault();
    localStorage.setItem("ClickOnSeachProfile", true);
    let Name = replceMultiStringWithSIngle(reduxSearchName);
    let searchExtends = document.querySelector(".search-form");
    var device_width = screen.width;
    const isMobileScreen =
      device_width < 580
        ? search.trim().length > 0 &&
          searchExtends.classList.contains("input-extend")
        : search.trim().length > 0;
    if (isMobileScreen) {
      setIsHaveData(true);
      if (Name != "") {
        sideBarClose();
        if (!window.location.pathname.match("/search-list")) {
          history.push("/search-list");
        }
      }
      const data = new FormData();
      data.append("search", reduxSearchName);
      const res = await dispatch(searchServiceApi(data));
      let {
        response: {
          data: { list, current_page },
        },
      } = res;
      let responsedata = {
        res: list,
        page: current_page,
      };
      setIsStopLoading(list);
      dispatch(serviceList(responsedata));
      const bodyParameter = {
        search: !!reduxSearchName
          ? replceMultiStringWithSIngle(reduxSearchName)
          : "",
      };
      dispatch(searchListDataApi(bodyParameter)).then((res) => {
        setIsHaveData(false);
      });
    }
    searchExtends.classList.add("input-extend");
  };
  //this function is used to go to page notification and read all notifications
  const goToNotification = (data) => {
    notificationData.map((item) => {
      if (item.type !== data) {
        dispatch(readAllNotificationsApi());
      }
    });
    history.push("/all-notification");
    sideBarClose();
    addRemoveClass("remove");
  };

  const addRemoveClass = (data) => {
    if (data === "remove") {
      var addActive = document.querySelector(
        ".list-unstyled .active-notification_box-class "
      );
      addActive.classList.remove("active-notification_box-class");
      addActive.classList.add("remove-notification_box");
    } else {
      var addActive = document.querySelector(
        ".list-unstyled .remove-notification_box"
      );
      if (addActive) {
        addActive.classList.add("active-notification_box-class");
        addActive.classList.remove("remove-notification_box");
      }
    }
  };
  //this function is used to accept a friend request
  const handleAccept = (Id) => {
    const bodyParameter = {
      id: Id,
    };
    dispatch(acceptRequestApi(bodyParameter));
  };
  //this function is used to decline a friend request
  const handleDecline = (Id) => {
    const bodyParameter = {
      id: Id,
    };
    dispatch(declineRequestApi(bodyParameter));
  };

  // this function is used to accept a members request
  const handleMembersAccept = (id) => {
    const bodyParameter = {
      member_request_id: id,
    };
    dispatch(acceptMembersRequestApi(bodyParameter));
  };

  // this function is used  to decline a members request
  const handleMembersDecline = (id) => {
    const bodyParameter = {
      member_request_id: id,
    };
    dispatch(declineMembersRequestApi(bodyParameter));
  };
  //this function is used to show the latest notifications
  const openNotification = () => {
    dispatch(readAllNotificationsApi());
    history.push("/all-notification");
    dispatch(onScreenNotificationsApi());
    addRemoveClass("active");
  };

  const handleChange = (e) => {
    var regex = /^[a-zA-Z ]*$/;
    if (regex.test(e.target.value)) {
      setSearch(e.target.value);
      let searchDetails = {
        reduxSearchName: e.target.value,
        onChangeSearchName: e.target.value,
      };
      dispatch(
        changeSearchName({ ...toolbarState.searchNameInput, ...searchDetails })
      );
    }
  };
  const handleSendInvitation = () => {
    history.push("/first-time-login");
    sideBarClose();
    classHiddenVisible("hidden", 0);
  };

  useEffect(() => {
    if (pathname === "/search-list") {
      setIsStopLoading([]);
    }
  }, [pathname]);
  //this function is used to open my profile.

  const handleRedirectNoti = (data) => {
    if (!data.check.file) {
      window.location.href = "/post?post_id=" + data.check.post_id;
    } else {
      window.location.href =
        "/post?post_id=" +
        data.check.post_id +
        "&file_id=" +
        data.check.file_id;
    }
  };
  //this function is used to open profile
  const handleOpenProfile = async (data) => {
    if (profileData.id == data.sender_id) {
      history.push(`/account/${profileData?.user_slug}`);
    }
    // TO READ FIRST TIME  SERVICE NOTIFICATION
    else if (
      profileData.id !== data.sender_id &&
      data?.check?.post_id === false &&
      data.type == "Become a seller"
    ) {
      localStorage.setItem("clickServiceNotification", true);
      localStorage.setItem("servicenotification", true);
      const filterNotification = notificationData.filter(
        (item) => item.description != data.description
      );
      setNotificationData(filterNotification);
      const formData = new FormData();
      formData.append("id", data.id);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
      };

      await axios(`${READ_SERVICE_NOTIFICATION}/${data.id}`, config)
        .then((response) => {
          if (response?.data?.status == 200) {
            localStorage.setItem("clickServiceNotification", true);
            history.push(`/account/${profileData?.user_slug}/services`);

            dispatch(onScreenNotificationsApi());
          }
        })
        .catch((err) => {
          throw err;
        });
    } else if (data?.type == "Service Request") {
      history.push(`/account/${profileData?.user_slug}/services`);

      const filterNotification = notificationData.filter(
        (item) => item.description != data.description
      );
      setNotificationData(filterNotification);
      const formData = new FormData();
      formData.append("id", data.id);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
      };

      await axios(`${READ_SERVICE_NOTIFICATION}/${data.id}`, config)
        .then((response) => {
          if (response?.data?.status == 200) {
            history.push(`/account/${profileData?.user_slug}/services`);

            dispatch(onScreenNotificationsApi());
          }
        })
        .catch((err) => {
          throw err;
        });
    } else {
      history.push(
        `/search-profile/${data.sender_id}`

        // `/search-profile/${data.sender_id}?individual=${
        //   !!data.is_individual ? true : false
        // }`
      );
    }
  };

  const sideBarClose = () => {
    if (!!closeSideBar) {
      closeSideBar.current.click();
    }
  };

  // -----------RUN IF USER IS UNAUTHORIZED ---------//
  useEffect(() => {
    if (onScreenNotificationStatus === 401) {
      setTimeout(() => {
        clearCookies();
        window.localStorage.removeItem("isSession");
        window.location.replace("/");
      }, 3000);

      addToast("Unauthorized access please try to login again", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
    }
  }, [onScreenNotificationStatus]);

  useEffect(() => {
    if (!getCookie("token_id")?.length) {
      window.localStorage.removeItem("isSession");
      window.location.replace("/");
    }
  }, [localStorage.getItem("isSession")]);

  const handleBecomeSeller = () => {
    if (getAllSellerDatas?.user_id) {
      history.push("/become-seller-steps");
      sideBarClose();
      classHiddenVisible("hidden", 0);
    } else {
      history.push("/become-seller");
      sideBarClose();
      classHiddenVisible("hidden", 0);
      1234;
    }
  };

  useEffect(() => {
    let isPlayOnce = { ishaveData, isItemRender };
    dispatch(isServiceLoading(isPlayOnce));
  }, [ishaveData]);

  // WHEN USER CLICK ON CONTACT US OPEN SUPPORT CHAT
  const handleContacUs = () => {
    setCookie("chatType", "user");
    history.push("/contact-us");
    SOCKET.emit("contact_support", {
      user_id: profileData.id,
      support_id: Number(ADMIN),
    });
    SOCKET.emit("get_friend_and_group_list", {
      u_id: profileData?.id,
      socket_id: SOCKET.id,
    });
    dispatch(changeChatDetails({ currenUserId: ADMIN }));

    sideBarClose();
    classHiddenVisible("hidden", 0);
  };

  useEffect(() => {
    if (!!onScreenNotification) setNotificationData(onScreenNotification);
  }, [onScreenNotification]);

  let searchExtends = document.querySelector(".search-form");
  useEffect(() => {
    if (searchExtends) {
      document.addEventListener("mousedown", (event) => {
        if (searchExtends.contains(event.target)) {
          return null;
        } else {
          searchExtends.classList.remove("input-extend");
        }
      });
    }
  }, [searchExtends]);

  const classHiddenVisible = (val, opacity) => {
    const hover = document.querySelector(".logged-user .profile-dropdown");
    hover.style.visibility = val;
    hover.style.opacity = opacity;
  };
  const handleHover = (data) => {
    if (data == "enter") {
      classHiddenVisible("visible", 1);
    } else if (data == "leave") {
      classHiddenVisible("hidden", 0);
    } else {
      addRemoveClass("addClass");
    }
  };
  const openMyAccount = () => {
    sideBarClose();
    classHiddenVisible("hidden", 0);
  };
  const handleMangeAccount = () => {
    history.push("/manage-account");
    classHiddenVisible("hidden", 0);
  };
  useEffect(() => {
    if (pathname !== "/search-list") {
      setSearch("");
    }
  }, [pathname]);

  return (
    <header>
      <div className="container h-100">
        <div className="row h-100 h-100">
          <div className="col-md-12 h-100">
            <div className="header-inner d-flex align-items-center h-100">
              <i
                className="ri-menu-line mobile-toggle"
                onClick={() => dispatch(getProfileApi())}
              ></i>
              <Link to="/newsfeeds" onClick={() => setSearch("")}>
                <img
                  src="/assets/images/site-logo.svg"
                  alt="logo"
                  className="logo"
                />
              </Link>
              <form
                onSubmit={handleSumbit}
                className="search-form"
                autoComplete="off"
              >
                <div className="form-group mb-0">
                  <Input
                    type="text"
                    name="searchName"
                    className="form-control"
                    value={search}
                    pattern="[A-Za-z]"
                    onChange={(e) => handleChange(e)}
                    placeholder="Search PoolsMagnic"
                  />
                  <i className="ri-search-line" onClick={handleSumbit}></i>
                </div>
              </form>
              <div className="topbar__right ml-auto d-flex h-100">
                <ul className="list-unstyled header-links d-flex align-items-center mb-0">
                  <li className="notiifcation">
                    <a
                      id="notification"
                      onClick={openNotification}
                      href="javascript:;"
                      data-toggle="tooltip"
                      data-placement={!allNotificationCount ? "left" : "left"}
                      title="Notification"
                      onMouseEnter={() => handleHover("openNotification")}
                    >
                      <i className="ri-notification-line" />
                      {!!unReadCount ? (
                        <span className="notification-active">
                          {unReadCount}
                        </span>
                      ) : (
                        ""
                      )}
                    </a>
                    {!!allNotificationCount ? (
                      <div className="notifications__box active-notification_box-class">
                        {notificationData.map((data, index) => (
                          <div className="notification__listing" key={index}>
                            <div className="notification__listing__item w-100">
                              {!!data.is_individual ? (
                                <div className="notification-user">
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOpenProfile(data)}
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/member-default.svg")
                                    }
                                    src={
                                      data.profile_image
                                        ? data?.type == "Become a seller"
                                          ? "/assets/images/notification.svg"
                                          : IMAGE_BASE_URL + data.profile_image
                                        : "/assets/images/member-default.svg"
                                    }
                                    alt="user"
                                  ></img>
                                </div>
                              ) : (
                                <div
                                  onClick={() => handleOpenProfile(data)}
                                  className={`notification-user ${
                                    data?.type != "Become a seller" &&
                                    "notification-business"
                                  }`}
                                >
                                  <img
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/business_logo.svg")
                                    }
                                    src={
                                      !!data.business_image
                                        ? data?.type == "Become a seller"
                                          ? "/assets/images/notification.svg"
                                          : IMAGE_BASE_URL + data.business_image
                                        : "/assets/images/business_logo.svg"
                                    }
                                    alt="user"
                                  ></img>
                                </div>
                              )}

                              {!!data.check.post ? (
                                <span
                                  className="link-color"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleRedirectNoti(data)}
                                >
                                  {data.description?.charAt(0).toUpperCase() +
                                    data.description?.slice(1)}
                                </span>
                              ) : (
                                <>
                                  {data?.description.includes("strong") ? (
                                    <span
                                      className="link-color"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleOpenProfile(data)}
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></span>
                                  ) : (
                                    <span
                                      className="link-color"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleOpenProfile(data)}
                                    >
                                      {data.description
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        data.description?.slice(1)}
                                      {data.type == "Become a seller" && (
                                        <i className="ri-external-link-line"></i>
                                      )}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                            {data.title == "New Friend Request!" ? (
                              <div className="notification__action">
                                <a
                                  href="javascript:;"
                                  className="accepted"
                                  onClick={() => handleAccept(data.sender_id)}
                                >
                                  <i className="ri-check-line" />
                                </a>
                                <a
                                  href="javascript:;"
                                  className="rejected"
                                  onClick={() => handleDecline(data.sender_id)}
                                >
                                  <i className="ri-close-fill" />
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                            {data.type == "Member Add Request" ? (
                              <div className="notification__action">
                                <a
                                  href="javascript:;"
                                  className="accepted"
                                  onClick={() =>
                                    handleMembersAccept(data.check.post_id)
                                  }
                                >
                                  <i className="ri-check-line" />
                                </a>
                                <a
                                  href="javascript:;"
                                  className="rejected"
                                  onClick={() =>
                                    handleMembersDecline(data.check.post_id)
                                  }
                                >
                                  <i className="ri-close-fill" />
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                        <div className="notification__listing">
                          {!!allNotificationCount ? (
                            <a
                              href="javascript:;"
                              className="link-color small"
                              onClick={() =>
                                goToNotification("Become a seller")
                              }
                            >
                              See All
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    <a
                      href="javascript:;"
                      onClick={() => history.push("/newsfeeds")}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Newsfeed"
                    >
                      <i className="ri-file-list-3-line" />
                    </a>
                  </li>
                  {!profileData.is_supporter && (
                    <li>
                      <a
                        href="javascript:;"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Network"
                        onClick={() => {
                          if (pathname != "/network") {
                            dispatch(clearFriendListApiResponse());
                            history.push("/network");
                          } else {
                            history.push("/network");
                          }
                        }}
                      >
                        <i className="ri-global-line" />
                      </a>
                    </li>
                  )}
                  <li>
                    <a
                      href="javascript:;"
                      onClick={() => history.push("/chat")}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="PoolsChat"
                    >
                      <i className="ri-chat-1-line" />
                      {unreadUserMessageCount?.length > 0 ? (
                        <span className="notification-active">
                          {unreadUserMessageCount?.length}
                        </span>
                      ) : (
                        ""
                      )}
                    </a>
                  </li>
                </ul>
                <div
                  className="logged-user  d-flex align-items-center"
                  onMouseEnter={() => handleHover("enter")}
                  onMouseLeave={() => handleHover("leave")}
                >
                  <img
                    onError={(ev) =>
                      (ev.target.src = "/assets/images/member-default.svg")
                    }
                    src={
                      !!profileImgData && !getPrifileLoading
                        ? !!profileImgData
                          ? profileImgData
                          : "/assets/images/member-default.svg"
                        : !!profileData.profile_image
                        ? IMAGE_BASE_URL + profileData.profile_image
                        : "/assets/images/member-default.svg"
                    }
                    alt="user"
                  />
                  <div className="logged-user__name">
                    <span>
                      {profileData.name?.charAt(0).toUpperCase() +
                        profileData.name?.slice(1).substr(0, 23)}
                    </span>{" "}
                    <i className="ri-arrow-down-s-line" />
                  </div>

                  <div className="profile-dropdown">
                    <div
                      className={`profile-dropdown__header ${
                        !!profileData.is_individual &&
                        "profile-dropdown--individual"
                      }`}
                    >
                      <div className="profile-dropdown__headshot d-flex align-items-center">
                        {!profileData.is_individual && (
                          <>
                            <div className="business-logo position-relative">
                              <img
                                onError={(ev) =>
                                  (ev.target.src =
                                    "/assets/images/business_logo.svg")
                                }
                                src={
                                  !!profileData.business_image
                                    ? IMAGE_BASE_URL +
                                      profileData.business_image
                                    : "/assets/images/business_logo.svg"
                                }
                              />
                            </div>
                          </>
                        )}

                        <img
                          onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                          }
                          src={
                            !!profileData.profile_image
                              ? IMAGE_BASE_URL + profileData.profile_image
                              : "/assets/images/member-default.svg"
                          }
                        />
                      </div>
                      <div className="flex flex-column align-items-start pl-2">
                        <span className="text-capitalize">
                          {!!profileData.is_individual && !!profileData
                            ? profileData.name
                            : profileData.business_name}
                        </span>
                        <span className="small d-block mb-2 text-capitalize">
                          {!!profileData ? profileData?.work : null}
                        </span>
                        <Link
                          to={`/account/${profileData?.user_slug}`}
                          className="btn btn-primary btn-sm text-capitalize"
                          onClick={() => openMyAccount()}
                        >
                          View profile
                        </Link>
                      </div>
                    </div>

                    <ul className="list-unstyled">
                      <li>
                        <a
                          href="javascript:;"
                          className="link-color"
                          onClick={handleMangeAccount}
                        >
                          <svg
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 128 128"
                          >
                            <path
                              fill="#333"
                              d="M30.13 51.31h20a1.75 1.75 0 0 0 1.75-1.75v-5.12a11.067 11.067 0 0 0-4.4-8.832 9.977 9.977 0 1 0-14.713 0 11.08 11.08 0 0 0-4.387 8.832v5.12a1.75 1.75 0 0 0 1.75 1.75zm10-28.894a6.477 6.477 0 1 1-6.477 6.477 6.485 6.485 0 0 1 6.473-6.476zM31.88 44.44a7.576 7.576 0 0 1 3.87-6.594 9.857 9.857 0 0 0 8.754 0 7.56 7.56 0 0 1 3.876 6.594v3.37h-16.5z"
                            />
                            <path
                              fill="#333"
                              d="M112.654 4.75H15.346A1.75 1.75 0 0 0 13.6 6.5v115a1.75 1.75 0 0 0 1.75 1.75h97.308a1.75 1.75 0 0 0 1.75-1.75V6.5a1.75 1.75 0 0 0-1.754-1.75zm-1.75 115H17.1V8.25h93.8z"
                            />
                            <path
                              fill="#333"
                              d="M26.346 65.75h75.308a1.75 1.75 0 1 0 0-3.5H26.346a1.75 1.75 0 0 0 0 3.5zM26.346 80.194h75.308a1.75 1.75 0 0 0 0-3.5H26.346a1.75 1.75 0 0 0 0 3.5zM26.346 109.083h75.308a1.75 1.75 0 0 0 0-3.5H26.346a1.75 1.75 0 0 0 0 3.5zM26.346 94.639h75.308a1.75 1.75 0 1 0 0-3.5H26.346a1.75 1.75 0 0 0 0 3.5zM64 22.417h37.654a1.75 1.75 0 0 0 0-3.5H64a1.75 1.75 0 0 0 0 3.5zM64 36.861h37.654a1.75 1.75 0 0 0 0-3.5H64a1.75 1.75 0 0 0 0 3.5zM64 51.306h37.654a1.75 1.75 0 0 0 0-3.5H64a1.75 1.75 0 0 0 0 3.5z"
                            />
                          </svg>{" "}
                          Manage My Account
                        </a>
                      </li>

                      {!profileData.is_supporter && (
                        <li>
                          <a
                            href="javascript:;"
                            className="link-color"
                            onClick={handleBecomeSeller}
                          >
                            <svg className="svg-icon" viewBox="0 0 20 20">
                              <path
                                fill="#333"
                                d="M14.023,12.154c1.514-1.192,2.488-3.038,2.488-5.114c0-3.597-2.914-6.512-6.512-6.512
								c-3.597,0-6.512,2.916-6.512,6.512c0,2.076,0.975,3.922,2.489,5.114c-2.714,1.385-4.625,4.117-4.836,7.318h1.186
								c0.229-2.998,2.177-5.512,4.86-6.566c0.853,0.41,1.804,0.646,2.813,0.646c1.01,0,1.961-0.236,2.812-0.646
								c2.684,1.055,4.633,3.568,4.859,6.566h1.188C18.648,16.271,16.736,13.539,14.023,12.154z M10,12.367
								c-2.943,0-5.328-2.385-5.328-5.327c0-2.943,2.385-5.328,5.328-5.328c2.943,0,5.328,2.385,5.328,5.328
								C15.328,9.982,12.943,12.367,10,12.367z"
                              ></path>
                            </svg>{" "}
                            Become a seller
                          </a>
                        </li>
                      )}

                      {!profileData.is_supporter && (
                        <li>
                          <a
                            href="javascript:;"
                            className="link-color"
                            onClick={() => handleSendInvitation()}
                          >
                            <svg
                              data-name="Layer 1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 128 128"
                            >
                              <path
                                d="M127.994 16.609a1.763 1.763 0 0 0-2.13-1.774L1.363 43.043A1.75 1.75 0 0 0 .775 46.2l33.937 22.756 19.071 36.4a1.773 1.773 0 0 0 2.721.489L70.695 93.08l29.581 19.832a1.75 1.75 0 0 0 2.667-1.008l25-94.917a1.84 1.84 0 0 0 .051-.378zM59.2 82.941a1.82 1.82 0 0 0-.416.759l-4.027 15.972-16.365-31.235 78.027-44.3L59.23 82.908c-.009.01-.016.023-.03.033zm55.887-62.077-78.96 44.83L6.1 45.559zM58.437 99.4l.293-1.16 2.845-11.272 6.122 4.1zm41.747 9.239L71.517 89.417 63.2 83.844l59.659-61.311z"
                                fill="#333"
                              />
                            </svg>{" "}
                            Send an Invitation
                          </a>
                        </li>
                      )}

                      {!profileData.is_supporter && (
                        <li>
                          <a
                            href="javascript:;"
                            className="link-color"
                            onClick={handleContacUs}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48.001"
                              height="48.002"
                              viewBox="0 0 50 50"
                            >
                              <g fill="#1a171b">
                                <path
                                  fill="#333"
                                  d="M47.936 19.105a1.535 1.535 0 0 0-.023-.055.738.738 0 0 0-.177-.221s0-.008-.008-.01l-6.516-5.225v-3.17A.725.725 0 0 0 41 9.91L31.3.213A.722.722 0 0 0 30.788 0H7.516a.729.729 0 0 0-.728.727v12.867L.272 18.819s-.005.008-.009.01a.747.747 0 0 0-.175.221.339.339 0 0 0-.024.055.708.708 0 0 0-.064.281v27.887A.729.729 0 0 0 .728 48h46.545a.728.728 0 0 0 .727-.727V19.386a.693.693 0 0 0-.064-.281zm-6.723-3.644 4.9 3.926-4.9 3.928zm5.334 5.439v24.959L30.982 33.38zM31.517 2.484 38.731 9.7h-7.214zM8.243 1.457h21.818v8.969a.727.727 0 0 0 .728.727h8.97v13.328L29.82 32.45l-5.363-4.3a.728.728 0 0 0-.91 0l-5.363 4.3-9.939-7.969V1.457zm-1.454 14v7.854l-4.9-3.928zm-5.333 30.4V20.9L17.02 33.381 1.456 45.859zm1.467.688L24 29.649l21.078 16.9z"
                                />
                                <path
                                  fill="#333"
                                  d="M13.335 11.152h11.636a.727.727 0 1 0 0-1.454H13.335a.727.727 0 1 0 0 1.454zM21.335 16.244a.729.729 0 0 0 .728.729h12.605a.728.728 0 0 0 0-1.456h-12.6a.728.728 0 0 0-.733.727zM13.335 16.972h4.849a.728.728 0 0 0 0-1.456h-4.849a.728.728 0 1 0 0 1.456zM34.668 21.336H30.7a.728.728 0 1 0 0 1.456h3.968a.728.728 0 0 0 0-1.456zM27.549 22.062a.729.729 0 0 0-.728-.727H13.335a.728.728 0 1 0 0 1.456h13.486a.729.729 0 0 0 .728-.729z"
                                />
                              </g>
                            </svg>{" "}
                            Contact Us
                          </a>
                        </li>
                      )}

                      <li></li>
                    </ul>
                    <div className="profile-logout text-center">
                      <a
                        href="javascript:;"
                        className="btn btn-secondary d-inline-block small"
                        onClick={handleLogout}
                      >
                        Logout of My Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* toggle menu */}

              <div className="offscreen-menu">
                <div className="offscreen-menu__inner">
                  <div className="offscreen-menu__inner__items">
                    <SideBarProfileMenu />
                    <i
                      ref={closeSideBar}
                      className="ri-close-line menu-close"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Toolbar;
