import {
  SUGGESTED_NETWORK_DATA_FAILURE,
  SUGGESTED_NETWORK_DATA_REQUEST,
  SUGGESTED_NETWORK_DATA_SUCCESS,
  UPDATE_SUGGESTED_NETWORK_DATA,
} from "./SuggestedNetworkConstant";

const initialState = {
  suggestedNetworkList: [],
  loading: false,
};

export const SuggestedNetworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUGGESTED_NETWORK_DATA_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case SUGGESTED_NETWORK_DATA_SUCCESS:
      const {
        response: {
          data: { list, message, status },
        },
      } = action;
      let signupStateSuccess = {
        signupApiLoading: false,
        signupApiStatus: status,
        signupMessage: message,
      };
      return {
        ...state,
        suggestedNetworkList: list,
        loading: false,
      };
    case UPDATE_SUGGESTED_NETWORK_DATA:
      return {
        ...state,
        suggestedNetworkList: list,
        loading: false,
      };
    case SUGGESTED_NETWORK_DATA_FAILURE:
      let signupStateFailure = {
        signupApiLoading: false,
        signupApiStatus: action.response.status,
        signupMessage: action.response.message,
      };
      return {
        ...state,
        suggestedNetworkList: [],
        loading: false,
      };
    default:
      return state;
  }
};
