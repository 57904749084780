import { CHANGE_GALLERY_FILE_DETAIL, CHANGE_GALLERY_SHARE_INPUT, CLEAR_DELETE_GALLERY_ITEM_RESPONSE, CLEAR_GET_GALLERY_PHOTO_RESPONSE, CLEAR_GET_GALLERY_VIDEO_RESPONSE, CLEAR_SHARE_GALLERY_RESPONSE } from "./ProfileGalleryConstant"

//this function is used to change gallery share input 
const changeGalleryShareInput = (newState) => {

    return { type: CHANGE_GALLERY_SHARE_INPUT, newState }
}
//this function is used to clear share gallery api response
const clearShareGalleryResponse = (newState) => {

    return { type: CLEAR_SHARE_GALLERY_RESPONSE, newState }
}
// this function is used to chaange gallery details like  photoList, videoList,photoPage ,videoPage, totalVideo , totalPhoto, 

const changeGalleryDetail = (newState) => {

    return { type: CHANGE_GALLERY_FILE_DETAIL, newState }
}
//this function is used to clear get gallery photo api response
const clearGalleryPhotoResponse = (newState) => {

    return { type: CLEAR_GET_GALLERY_PHOTO_RESPONSE, newState }
}
//this function is used to clear get gallery video api response
const clearGalleryVideoResponse = (newState) => {

    return { type: CLEAR_GET_GALLERY_VIDEO_RESPONSE, newState }
}
//this function is used to clear gallery delete api response 
const clearGalleryDeleteResponse = (newState) => {

    return { type: CLEAR_DELETE_GALLERY_ITEM_RESPONSE, newState }
}
export {
    changeGalleryShareInput, clearShareGalleryResponse, changeGalleryDetail, clearGalleryPhotoResponse, clearGalleryVideoResponse,
    clearGalleryDeleteResponse,
}