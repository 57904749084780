import React, { useState, useEffect } from "react";
import { IMAGE_BASE_URL } from "../../library/urls";
import { Link } from "react-router-dom";
import {
  sendRequestDataApi,
} from "../../library/api/SearchApiService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ServicesSearchList from "./ServicesSearchList";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
const override = css`
  position: absolute;
  top: 50%;
  right: 43%;
  z-index: 9999;
`;
const AllSearchList = () => {
  const history = useHistory();
  const searchListState = useSelector((state) => state.SearchListReducer);
  const isloading = useSelector((state) => state?.searchLoadingReducer.data);
  const serviceDataFromRedux = useSelector(
    (state) => state?.searchLoadingReducer.serviceList
  );
  const { searchList } = searchListState;
  const [showOnlyThreeServie, setShowOnlyThreeService] = useState(3);
  const [ishaveData, setisHaveData] = useState(false);
  const [searchListData, setSearchListData] = useState([]);
  const dispatch = useDispatch();
  const sendRequest = (Id, i) => {
    searchList[i].isSendReq = true;
    const bodyParameter = {
      id: Id,
    };
    dispatch(sendRequestDataApi(bodyParameter));
  };
  useEffect(() => {
    setisHaveData(isloading?.ishaveData);
  }, [isloading?.ishaveData]);
  useEffect(() => {
    setTimeout(() => {
      setSearchListData(searchList);
    }, 200);
  }, [searchList]);
  useEffect(() => {
    if (
      isloading.ishaveData === false &&
      serviceDataFromRedux.length === 0 &&
      searchList.length == 0
    ) {
      history.push("/");
    }
  }, []);

  const renderServiceOrPeople = () => {
    return (
      <>
        <ServicesSearchList
          isSevicesPage={true}
          showOnlyThreeServie={showOnlyThreeServie}
          setShowOnlyThreeService={setShowOnlyThreeService}
        />
        {searchListData?.length > 0 && (
          <section className="search-users bg-primary spacer search-result tab2 pt-0">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mx-auto full-md">
                  <h4 className="mb-3">People & Business</h4>
                  {searchListData.map((data, i) => (
                    <div
                      className="search-users__wrapper d-flex flex-wrap align-items-center bg-white p-4 br-20 position-relative mb-3"
                      key={i}
                    >
                      {!!data?.is_individual ? (
                        <div className="search-users__img d-flex align-items-center">
                          <img
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                            }
                            src={
                              !!data
                                ? IMAGE_BASE_URL + data.profile_image
                                : "/assets/images/member-default.svg"
                            }
                            alt="user"
                          ></img>
                        </div>
                      ) : (
                        <div className="search-users__img d-flex align-items-center business-user">
                          <img
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/business_logo.svg")
                            }
                            src={
                              !!data
                                ? IMAGE_BASE_URL + data.business_image
                                : "/assets/images/business_logo.svg"
                            }
                            alt="user"
                          ></img>
                        </div>
                      )}
                      <div className="search-users__detail">
                        <Link
                          to={`/search-profile/${data.id}
                            `}
                            // to={`/search-profile/${data.id}?individual=${!!data.is_individual ? true : false
                            // }`}
                          className="link-color"
                        >
                          <h5 className="text-capitalize mb-0">
                            {data.is_individual == 1
                              ? data.name.slice(0, 23)
                              : data.business_name.slice(0, 23)}
                          </h5>
                        </Link>
                        {!data.is_supporter && (
                          <div>
                            {data.friends == true ? (
                              <a
                                className="btn btn-primary view-profile frnd-btn"
                                href="javascript:;"
                                role="button"
                                title="Part of my network"
                              >
                                <i className="ri-user-follow-line"></i>
                              </a>
                            ) : !!data.request_sent ? (
                              <a
                                className="btn bg-success view-profile"
                                href="javascript:;"
                                role="button"
                              >
                                <i className="ri-user-follow-line" /> Request
                                sent
                              </a>
                            ) : (
                              <button
                                className="btn btn-blue view-profile"
                                href="javascript:;"
                                role="button"
                                disabled={data.isSendReq}
                                onClick={() => sendRequest(data.id, i)}
                              >
                                <i className="ri-user-add-line"></i> Link-Up
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  };
  return (
    <>
      {!ishaveData ? (
        renderServiceOrPeople()
      ) : (
        <ClipLoader loading={ishaveData} size={80} css={override} />
      )}
    </>
  );
};

export default AllSearchList;
