import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  isauth,
  isPagePrivate,
  component: Component,
  ...rest
}) => {



  return (
    <Route
      {...rest}
      render={props =>
        !!isauth ? (
          isPagePrivate ?
            (
              window.location.pathname == "/" ?
                <Redirect
                  to="/newsfeeds"
                />
                :
                <Component {...props} />
            )
            :
            (
              <Redirect
                to="/"
              />
            )
        )
          :
          (
            <Redirect
              to="/"
            />
          )
      }
    />
  );
};
export default PrivateRoute;