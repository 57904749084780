import React, { useEffect } from "react";
import $ from "jquery";
import { useHistory } from "react-router";
import { scroolTop } from "../../library/utilities/functions";

const PrivacyPolicy = () => {
  const history = useHistory();
  scroolTop();
  useEffect(() => {
    $(document).ready(function () {
      document.body.className = "bg-primary";
      $(".toc__links a[href*=\\#]:not([href=\\#])").on("click", function () {
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.substr(1) + "]");
        if (target.length) {
          $("html,body").animate(
            {
              scrollTop: target.offset().top - 30,
            },
            1000
          );
          return false;
        }
      });
    });
    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <>
      <div className="container spacer px-2 px-md-0">
        <div className="row mb-3">
          <div className="col-md-12">
            <a
              href="javascript:;"
              onClick={() => history.goBack()}
              className="btn btn-sm btn-secondary"
            >
              Back
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="toc-blk bg-white br-20 p-4">
              <small className="mb-3 d-block">
                These Terms of Use are effective as of December 31, 2021.
              </small>
              <h1 className="h3">Your Privacy is Important to Us.</h1>
              <p>
                PoolsMagnic’s core mission is to help you Network, Work, and
                Collaborate. It connects the world’s service providers and
                service seekers. Also, It allows you to be more productive and
                efficient in your personal or professional projects. Our
                commitment is to be clear about the information we collect about
                you and explain how it is used and who we will share it with.
              </p>
              <div className="toc-blk__content mb-5" id="Introduction">
                <h2 className="h4 mb-3">Introduction</h2>
                <p>
                  PoolsMagnic is an online Social workplace productivity tool
                  and platform. Individuals’ or businesses using our services to
                  find and be found for business opportunities enhance the work
                  environment. Our Privacy Policy applies to anyone who uses our
                  services. Our registered users share their identities, engage
                  with their network, find business opportunities or services
                  from service providers, and post and view relevant content.{" "}
                </p>
                <h4 className="h5">Future modifications to the Policy:</h4>
                <p>
                  Modification to the Privacy Policy applies to your use of our
                  services after the “effective date.”
                </p>
                <p>
                  PoolsMagnic is entitled to modify this Privacy Policy. If we
                  make material changes to it, we will provide notice through
                  our services or by other means so you can review the changes
                  before they take effect. If you object to any changes, you
                  will be free to close your account and discontinue your
                  membership.
                </p>
                <p>
                  You acknowledge that your continued use of our services after
                  we publish or send a notice about our changes to this Privacy
                  Policy means that your personal data, use, and sharing is
                  subject to the updated Privacy Policy as of its effective
                  date.
                </p>
              </div>

              <div
                className="toc-blk__content mb-5"
                id="Information-we-collect"
              >
                <h2 className="h4 mb-3">Information we collect:</h2>
                <ul>
                  <li>
                    <h4 className="h5">Opening an Account</h4>
                    <p>
                      When you open an account with PoolsMagnic, you need to
                      provide data such as your full name, email address, phone
                      number, and password. You have choices about which
                      information on your profile to provide, such as your work
                      experience, project information, skills, photo, business
                      logo if applicable, or your Location. You are not required
                      to provide additional information. It is your choice to
                      add sensitive information to your profile and to make that
                      sensitive information public. Please do not post or add
                      personal data to your profile that you would not want to
                      be publicly available.
                    </p>
                  </li>
                  <li>
                    <h4 className="h5">Posting and Uploading</h4>
                    <p>
                      We collect your personal and business information from you
                      when you provide, post or upload it to “Our Services”. You
                      do not have to post or upload personal data though this
                      may limit your ability to grow and engage with your
                      network over Our Services.
                    </p>
                    <p className="small">
                      Disclaimer: We are continuously improving our services,
                      which means we get new data and create new ways to use
                      data. Our services are dynamic, and we often introduce new
                      features, which may require collecting further
                      information. If we collect personal data or materially
                      change how we collect, use or share your data, we will
                      notify you. We also reserve the right to modify this
                      Privacy Policy.
                    </p>
                  </li>
                </ul>
              </div>

              <div
                className="toc-blk__content mb-5"
                id="how-we-use-your-information"
              >
                <h2 className="h4 mb-3">How We Use Your Information</h2>
                <p>
                  We use your data to provide, support, personalize and develop
                  our services.{" "}
                </p>
                <ul>
                  <li>
                    <h4 className="h5">Stay Connected </h4>
                    <p>
                      Our services allow you to stay in touch and up to date
                      with your network, clients, and other contacts. To do so,
                      you can “link up” with the professionals you choose and
                      those you wish to “link up” with you. When you link up
                      with other users, you will be able to search each others’
                      networks and exchange professional opportunities.
                    </p>
                    <p>
                      It is your choice to invite someone to our services, send
                      an invitation, or allow another user to become a member of
                      your network. When you invite someone to link up with you,
                      your invitation will include your network and basic
                      profile information (e.g., name, profile photo, business
                      logo and name if applicable, work, or location).{" "}
                    </p>
                  </li>

                  <li>
                    <h4 className="h5">Productivity </h4>
                    <p>
                      Our Services allow you to collaborate with colleagues and
                      search for potential clients, customers, partners, and
                      others with whom you may wish to do business. Our services
                      will enable you to communicate with other users and
                      schedule and prepare meetings with them.{" "}
                    </p>
                  </li>

                  <li>
                    <h4 className="h5">Communications </h4>
                    <p>
                      We will contact you through email, phone, notices posted
                      on our website, messages to your Poolschat, or other ways
                      through Our Services, including text messages and push
                      notifications. We will send you messages about the
                      availability of our services, security, or other
                      service-related issues. We also send messages about how to
                      use our services, network updates, and promotional
                      messages.
                    </p>
                  </li>

                  <li>
                    <h4 className="h5">Customer Support </h4>
                    <p>
                      We use data to help you and fix problems. We use data
                      (including your communications) to investigate, respond to
                      and resolve complaints and service issues (e.g., bugs).
                    </p>
                  </li>

                  <li>
                    <h4 className="h5">Security and Investigations</h4>
                    <p>
                      We use data for security, fraud prevention, and
                      investigation. We use your data (including your
                      communications) for security purposes to prevent or
                      investigate possible fraud or other violations of our User
                      Agreement and/or attempts to harm our users, visitors, or
                      others.
                    </p>
                  </li>
                </ul>
              </div>

              <div
                className="toc-blk__content mb-5"
                id="how-we-share-information"
              >
                <h2 className="h4 mb-3">How we share information</h2>
                <ul>
                  <li>
                    <h4 className="h5">Our Services</h4>
                    <p>
                      Any data you include on your profile and any content you
                      post or social action (e.g., reactions, comments, shares)
                      you take on our services will be visible to others.
                    </p>
                  </li>
                  <li>
                    <h4 className="h5">Profile</h4>
                    <p>
                      Your profile is fully visible to all users and customers
                      of our services, including your posts, reactions, and
                      comments.
                    </p>
                    <p>
                      Our services allow viewing and sharing information,
                      including through posts, reactions, follows, and comments.
                    </p>
                    <p>
                      When you publicly share a post (e.g., image, video, or
                      link), everyone can view it and share it anywhere. Users,
                      visitors, and others will be able to find and see your
                      publicly shared content, including your name (and photo if
                      you have provided one). When you like, re-share, or
                      comment on another’s content (including ads), others will
                      be able to view these “social actions” and associate them
                      with you (e.g., your name, profile, and photo if you
                      provided one).
                    </p>
                  </li>
                  <li>
                    <h4 className="h5">Service Providers</h4>
                    <p>
                      We may use others to help us with our services. We use
                      others to help us with our Services (e.g., maintenance,
                      analysis, audit, payments, fraud detection, marketing, and
                      development). They will have access to your information as
                      reasonably necessary to perform these tasks on our behalf
                      and are obligated not to disclose or use it for other
                      purposes.
                    </p>
                  </li>
                  <li>
                    <h4 className="h5">Legal Disclosures</h4>
                    <p>
                      We may need to share your data when we believe it is
                      required by law or to help protect the rights and safety
                      of you, us, or others.
                    </p>
                    <p>
                      It is possible that we will need to disclose information
                      about you when required by law, subpoena, or other legal
                      processes, or if we have a good faith belief that
                      disclosure is reasonably necessary to (1) investigate,
                      prevent or take action regarding suspected or actual
                      illegal activities or to assist government enforcement
                      agencies; (2) enforce our agreements with you; (3)
                      investigate and defend ourselves against any third-party
                      claims or allegations; (4) protect the security or
                      integrity of our services (such as by sharing data with
                      companies facing similar threats); or (5) exercise or
                      protect the rights and safety of PoolsMagnic, our users,
                      personnel or others. We attempt to notify users about
                      legal demands for their personal data when appropriate in
                      our judgment unless prohibited by law or court order or
                      when the request is an emergency. We may dispute such
                      demands when we believe, at our discretion, that the
                      requests are overbroad, vague, or lack proper authority,
                      but we do not promise to challenge every demand. To learn
                      more, see our Data Request Guidelines and Transparency
                      Report.
                    </p>
                  </li>
                  <li>
                    <h4 className="h5">Change in Control or Sale</h4>
                    <p>
                      We may share your data when our business is sold to
                      others, but it must continue to be used in accordance with
                      this Privacy Policy.
                    </p>
                    <p>
                      We can also share your personal data as part of a sale,
                      merger, change in control, or in preparation for any of
                      these events. Any other entity which buys us or part of
                      our business will have the right to continue to use your
                      data, but only in the manner set out in this Privacy
                      Policy unless you agree otherwise.
                    </p>
                  </li>
                </ul>
              </div>

              <div
                className="toc-blk__content mb-5"
                id="your-choices-and-obligations"
              >
                <h2 className="h4 mb-3">Your Choices &amp; Obligations:</h2>
                <ul>
                  <li>
                    <h4 className="h5">Data Retention</h4>
                    <p>
                      We keep most of your personal data for as long as your
                      account is open. We generally retain your personal data as
                      long as you keep your account open or as needed to provide
                      you with our services. This includes data you or others
                      provided to us and data generated or inferred from your
                      use of our services. Even if you only use our services
                      when looking for a service we provide, we will retain your
                      information and keep your profile open unless you close
                      your account. In some cases, we choose to retain certain
                      information (e.g., insights about service use) in a
                      depersonalized or aggregated form.
                    </p>
                  </li>
                  <li>
                    <h4 className="h5">
                      Rights to Access and Control Your Personal Data
                    </h4>
                    <p>
                      You can access or delete your personal data. You have many
                      choices about how your data is collected, used and
                      shared.We provide many choices about the collection, use
                      and sharing of your data, from deleting or correcting data
                      you include in your profile and controlling the visibility
                      of your posts to advertising opt-outs and communication
                      controls. We offer you settings to control and manage the
                      personal data we have about you.
                    </p>
                    <p>For personal data that we have about you, you can:</p>
                    <p>
                      <strong>Delete data:</strong> You can ask us to erase or
                      delete all or some of your personal data (e.g., if it is
                      no longer necessary to provide services to you).
                    </p>
                    <p>
                      <strong>Change or correct data:</strong> You can edit some
                      of your personal data through your account. You can also
                      ask us to change, update or fix your data in certain
                      cases, particularly if it is inaccurate.
                    </p>
                    <p>
                      <strong>
                        Object to, or limit or restrict, use of data:
                      </strong>{" "}
                      You can ask us to stop using all or some of your personal
                      data (e.g., if we have no legal right to keep using it) or
                      to limit our use of it (e.g., if your personal data is
                      inaccurate or unlawfully held).
                    </p>
                    <p>
                      <strong>Right to access and/or take your Data:</strong>{" "}
                      You can ask us for a copy of your personal data and can
                      ask for a copy of personal data you provided in machine
                      readable form.
                    </p>
                  </li>
                </ul>
              </div>

              <div
                className="toc-blk__content mb-5"
                id="other-important-information"
              >
                <h2 className="h4 mb-3">Other Important Information</h2>
                <ul>
                  <li>
                    <h4 className="h5">Security</h4>
                    <p>
                      We monitor for and try to prevent security breaches.
                      Please use the security features available through our
                      services.We implement security safeguards designed to
                      protect your data, such as HTTPS. We regularly monitor our
                      systems for possible vulnerabilities and attacks. However,
                      we cannot warrant the security of any information that you
                      send us. There is no guarantee that data may not be
                      accessed, disclosed, altered, or destroyed by breach of
                      any of our physical, technical, or managerial safeguards.
                      Please visit our Safety Center for additional information
                      about safely using our services, including two-factor
                      authentication.
                    </p>
                  </li>
                  <li>
                    <h4 className="h5">Cross-Border Data Transfers</h4>
                    <p>
                      We store and use your data outside your country. We
                      process data both inside and outside the United States and
                      rely on legally-provided mechanisms to lawfully transfer
                      data across borders. Countries where we process data may
                      have laws that are different from, and potentially not as
                      protective as, the laws of your own country.
                    </p>
                  </li>
                  <li>
                    <h4 className="h5">Lawful Bases for Processing</h4>
                    <p>
                      We have lawful bases to collect, use and share data about
                      you. You have choices about our use of your data. At any
                      time, you can with the draw consent you have provided by
                      going to settings.
                    </p>
                    <p>
                      Where we rely on your consent to process personal data,
                      you have the right to withdraw or decline your consent at
                      any time and where we rely on legitimate interests, you
                      have the right to object.
                    </p>
                  </li>
                  <li>
                    <h4 className="h5">Contact Information</h4>
                    <p>
                      You can contact us or use other options to resolve any
                      complaints.If you have questions or complaints regarding
                      this Policy, please first contact PoolsMagnic online. You
                      can also reach us by physical mail. This is our Physical
                      address: 1662 Coughlin St, Laramie WY, 82072 or email us{" "}
                      <a
                        href="mailto:pools.support@poolsmagnic.com"
                        className="highlight-tx"
                      >
                        pools.support@poolsmagnic.com
                      </a>{" "}
                      .
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="bg-white br-20 p-4 sticky-widget">
              <h3 className="h4 mb-3">Useful Information</h3>
              <div className="toc__links">
                <ul>
                  <li>
                    <a href="#Introduction" className="link-color">
                      Introduction
                    </a>
                  </li>
                  <li>
                    <a href="#Information-we-collect" className="link-color">
                      Information we collect
                    </a>
                  </li>
                  <li>
                    <a
                      href="#how-we-use-your-information"
                      className="link-color"
                    >
                      How we use your information
                    </a>
                  </li>
                  <li>
                    <a href="#how-we-share-information" className="link-color">
                      How and with whom do we share information
                    </a>
                  </li>
                  <li>
                    <a
                      href="#your-choices-and-obligations"
                      className="link-color"
                    >
                      Your Choices and obligations
                    </a>
                  </li>
                  <li>
                    <a
                      href="#other-important-information"
                      className="link-color"
                    >
                      Other important information
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;
