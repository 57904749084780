import {
  CLEAR_SEARCH_LIST_MEMBERS_RESPONSE,
  CLEAR_SEND_REQUEST_MEMBERS_RESPONSE,
} from "./SearchMembersListConstant";
//this function is used to clear search list api response
const clearSearchListMembersResponse = (newState) => {
  return { type: CLEAR_SEARCH_LIST_MEMBERS_RESPONSE, newState };
};
//this function is used to clear send friend request api response
const clearSendRequestMembersResponse = (newState) => {
  return { type: CLEAR_SEND_REQUEST_MEMBERS_RESPONSE, newState };
};
export { clearSearchListMembersResponse, clearSendRequestMembersResponse };
