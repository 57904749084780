import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import Input from "../Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../utilities/functions";
import {
  changeSearchName,
  changeSearchMembersName,
} from "../Toolbar/ToolbarActions";
import {
  replceMultiStringWithSIngle,
  addValidation,
} from "../../../utilities/functions";

import { searchListDataMembersApi } from "../../../api/AccountApiService";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SearchList } from "../../../../modules";
import { SearchMembersList } from "../../../../modules";
import SearchListMembers from "../../../../modules/SearchMembersList";
import { friendListApi } from "../../../api/MyNetworkApiService";
import { IMAGE_BASE_URL, ADD_MEMBERS } from "../../../urls";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import ClipLoader from "react-spinners/ClipLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import {
  changeInviteModelInput,
  clearinviteapiResponse,
} from "../../../../modules/Home/HomeActions";
import { handleEmailValidation } from "../../../../modules/Home/Validatin";
import { inviteApi } from "../../../../library/api/HomeApiService";
import { clearSearchListMembersResponse } from "../../../../modules/SearchMembersList/SearchMembersListAction";

const AddMembersModal = () => {
  const override = css`
    width: 280px;
    height: 25px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  `;
  const dispatch = useDispatch();
  const emailElValidation = useRef();
  const { addToast } = useToasts();
  const [addSelectedIndividualId, setAddSelectedIndividualId] = useState([]);
  const [selectedIndividualId, setSelectedIndividualId] = useState([]);
  const [searchListData, setSearchListData] = useState([])
  const [isHaveLimit, setIsHaveLimit] = useState(false)
  const [showError, setShowError] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const toolbarState = useSelector((state) => state.ToolbarReducer);
  const invitemodel = useSelector((state) => state.HomeReducer);
  const {
    inviteModelInput: { emails, }
  } = invitemodel;
  const {
    inviteApiResponse: {
      inviteApiLoading,
      inviteApiStatus,
      inviteMessage,
      inviteApiSuccess,
    },
  } = invitemodel;
  const {
    searchMembersNameInput: { onChangeSearchMembers, reduxSearchMembers },
  } = toolbarState;
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const searchListMembersState = useSelector(
    (state) => state.SearchMembersListReducer
  );
  const {
    searchApi: { searchListSuccess, searchListStatus },
    searchList,
  } = searchListMembersState;


  let countLength = searchList.filter((item) => item.team_member == false);
  const handleSumbited = (e) => {
    e.preventDefault();
    if (addSelectedIndividualId.length < 5) {
      let Name = replceMultiStringWithSIngle(reduxSearchMembers);
      if (Name != "") {
        const bodyParameter = {
          search: !!reduxSearchMembers
            ? replceMultiStringWithSIngle(reduxSearchMembers)
            : "",
        };

        dispatch(searchListDataMembersApi(bodyParameter));
        if (searchList.length > 0 && searchListSuccess) {
          $("#searchListModal").modal();
        } else {
          $("#searchListModal").modal("hide");
        }
      }
    } else {
      setIsHaveLimit(true)
      setTimeout(() => setIsHaveLimit(false), 2000)
      addToast("Maximum limit to add member is only 5", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
    }
  };

  useEffect(() => {
    if (searchListStatus === 200) {
      if (searchList.length > 0 && !!searchListSuccess) {
        $("#searchListModal").modal();
      } else {
        $("#searchListModal").modal("hide");
      }
    }
  }, [searchListStatus]);
  useEffect(() => {
    if (!!inviteApiStatus && inviteApiStatus == "200") {
      addToast(inviteMessage, {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(
        changeSearchMembersName({
          onChangeSearchMembers: "",
          reduxSearchMembers: "",
        })
      );
      dispatch(clearSearchListMembersResponse());

      dispatch(clearinviteapiResponse());
      setOpenInviteModal(false);
      dispatch(changeInviteModelInput({ emails: [], message: "" }));
    }
    if (inviteApiStatus == "400") {
      addToast("Please enter valid email address", {
        appearance: "error",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      dispatch(clearinviteapiResponse());
    }
  }, [inviteApiSuccess]);

  const handleChangefunc = (e) => {
    let searchDetailsMembers = {
      reduxSearchMembers: e.target.value,
      onChangeSearchMembers: e.target.value,
    };
    dispatch(
      changeSearchMembersName({
        ...toolbarState.searchMembersNameInput,
        ...searchDetailsMembers,
      })
    );

    dispatch(changeInviteModelInput({ emails: e.target.value }));
    dispatch(clearSearchListMembersResponse());
  };

  let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const handleRemoveUser = (id) => {
    const updatedNewUser = addSelectedIndividualId.filter(
      (item) => item !== String(id)
    );
    const newList = selectedIndividualId.filter((item) => item.id !== id);
    setAddSelectedIndividualId(updatedNewUser);
    setSelectedIndividualId(newList);
  };
  const handlleAddClick = () => {
    const newData = [];
    $(".selectedCheck").removeClass("ri-checkbox-circle-fill");

    for (let i = 0; i < addSelectedIndividualId.length; i++) {
      searchList
        .filter((user) => user.id === Number(addSelectedIndividualId[i]))
        .map((item) => {
          newData.push(item);
        });
    }

    let Users = [];
    if (selectedIndividualId.length > 0) {
      Users = selectedIndividualId.filter((itemA) => {
        return !searchList.find((itemB) => {
          return itemA.id === itemB.id;
        });
      });
      newData.push(...Users);
    }

    setSelectedIndividualId(newData);
    setShowError(false);
    $("#searchListModal").modal("hide");
  };

  let _individaulAdd = [];
  const handleAddIndividual = (e, item) => {
    if (e.target.checked) {
      if (addSelectedIndividualId.length < 5) {
        searchListData.map((data) => {
          if (item.id === data.id) {
            _individaulAdd.push(item.id.toString())
          }
        })
        const _getOnlyUniqueValue = new Set([...addSelectedIndividualId, ..._individaulAdd])
        setAddSelectedIndividualId([..._getOnlyUniqueValue]);
      } else {
        setIsHaveLimit(true)
        setTimeout(() => setIsHaveLimit(false), 2000)
        addToast("Maximum limit to add member is only 5", {
          appearance: "error",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      }
    } else {
      if (addSelectedIndividualId.includes("" + item.id)) {
        let clone = addSelectedIndividualId;
        const x = clone.indexOf("" + item)
        clone.splice(x, 1);
        setAddSelectedIndividualId(clone)
      }
    }
  };
  useEffect(() => {
    dispatch(clearSearchListMembersResponse());
    if (!!reduxSearchMembers) {
      const bodyParameter = {
        search: reduxSearchMembers,
      };
      dispatch(searchListDataMembersApi(bodyParameter));
    }
    return () => {
      dispatch(clearSearchListMembersResponse());
      dispatch(
        changeSearchMembersName({
          onChangeSearchMembers: "",
          reduxSearchMembers: "",
        })
      );
    };
  }, []);

  const handleSendClick = () => {
    if (addSelectedIndividualId.length > 0) {

      $("#members-modal").modal("hide");
      const data = new FormData();
      selectedIndividualId.forEach((item) => data.append("user_id[]", item.id));

      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };

      axios(ADD_MEMBERS, config).then((response) => {

        setShowError(showError);
        setSelectedIndividualId([]);
        setAddSelectedIndividualId([])
        dispatch(
          changeSearchMembersName({
            onChangeSearchMembers: "",
            reduxSearchMembers: "",
          })
        );

        addToast(response.data.message, {
          appearance: "success",
          autoDismiss: true,
          // autoDismissTimeout: 2000,
        });
      });
    } else {
      setShowError(true);
    }
  };

  const handleOpenInviteModal = () => {
    setOpenInviteModal(true);
    $("#members-modal").modal("hide");
  };
  const handleInviteSumbit = () => {

    const email = emails;
    let validation = {
      is_valid_email: { status: false, validation: emailElValidation },
    };
    validation = handleEmailValidation(validation, emails);
    let {
      is_valid_email: { status },
    } = validation;
    if (status) {
      const bodyParameter = {
        // session_id: getCookie("token_id"),
        email_id: email,
        // message: message,
        invited_for: 2,
      };
      dispatch(inviteApi(bodyParameter));
    }
  };

  if (inviteApiStatus == 200) {
    $("#members-modal").modal("hide");
  }

  const handleModalClose = () => {
    $("#members-modal").modal("hide");

    dispatch(
      changeSearchMembersName({
        onChangeSearchMembers: "",
        reduxSearchMembers: "",
      })
    );
    dispatch(clearSearchListMembersResponse());
    setAddSelectedIndividualId([])
    setSelectedIndividualId([]);
  };



  useEffect(() => {
    const _removeElementFromSearchList = searchList.filter((itemA) => {
      return !selectedIndividualId.find((itemB) => {
        return itemA.id == itemB.id
      })
    })

    if (!!_removeElementFromSearchList.length) {
      setSearchListData(_removeElementFromSearchList)
    } else {
      setSearchListData(searchList)
    }
  }, [searchList, selectedIndividualId])

  return (
    <>
      <div
        className="modal fade"
        id="members-modal"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title d-flex" id="exampleModalLabel">
                <img
                  src="/assets/images/icon.svg"
                  alt="icon"
                  className="site-icon"
                />{" "}
                <span>Add team members to your business account</span>
              </h5>
              <button
                type="button"
                className="close"
              >
                <span aria-hidden="true" onClick={handleModalClose}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="feeds-modal__top d-flex mb-3">
                <div className="feeds-opt w-100">
                  <label className="text-normal">
                    Find and add team members to your business account
                  </label>
                  <form
                    onSubmit={handleSumbited}
                    className="search-form w-100 ml-0"
                    autoComplete="off"
                  >
                    <div className="form-group mb-4">
                      <Input
                        type="text"
                        name="searchName"
                        className="form-control"
                        value={onChangeSearchMembers}
                        onChange={(e) => handleChangefunc(e)}
                        placeholder="Type the team member name or email"
                      />
                      <i className={`ri-search-line ${isHaveLimit && 'disabled'}`} onClick={handleSumbited} />
                    </div>
                  </form>

                  {!!searchList && !!searchListSuccess ? (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          {countLength.length == 0 ? (
                            <>
                              <h6 className="mb-2 invitation-label">
                                {reduxSearchMembers.match(regexEmail) ? (
                                  <>
                                    {" "}
                                    Can't find your team member's name, would
                                    you like to{" "}
                                    <a
                                      href="javascript:;"
                                      style={{ textDecoration: "underline" }}
                                      onClick={handleInviteSumbit}
                                      className={
                                        !!inviteApiLoading
                                          ? "text-secondary loading-disable-text"
                                          : "text-secondary "
                                      }
                                    >
                                      Send invitation
                                    </a>
                                    &nbsp;email
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    Member with this name doesn't exit , you can
                                    send invitation by entering the email{" "}
                                  </>
                                )}
                              </h6>
                              <BeatLoader
                                color={"#88001b"}
                                loading={!!inviteApiLoading ? true : false}
                                css={override}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {showError && (
                    <p className="text-danger small mb-0">
                      Please Add the team Members
                    </p>
                  )}
                  <div className="create-footer-group-blk d-flex flex-wrap">
                    {selectedIndividualId.map((item) => {
                      return (
                        <div className="create-footer-group" key={item.user_id}>
                          <img
                            src={
                              !!item.profile_image && item.profile_image !== " "
                                ? IMAGE_BASE_URL + item.profile_image
                                : "/assets/images/member-default.svg"
                            }
                            onError={(ev) =>
                            (ev.target.src =
                              "/assets/images/member-default.svg")
                            }
                            alt="user"
                            className="user-profile"
                          />
                          <i
                            className="ri-close-line position-relative text-white"
                            onClick={() => handleRemoveUser(item.id)}
                          ></i>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {selectedIndividualId.length > 0 && (
              <div className="modal-footer">
                <div className="row w-100 align-items-center">

                  <div className="col-md-6">

                  </div>

                  <div className="col-md-6 text-right">
                    <a
                      href="javascript:;"
                      className="btn btn-primary btn-sm"
                      onClick={handleSendClick}
                    >
                      {" "}
                      Send Request
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* show modal when on searchbar data click */}

      <>
        <div
          className="modal fade upper-modal"
          id="searchListModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ minHeight: "20rem" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img
                    src="/assets/images/icon.svg"
                    alt="icon"
                    className="site-icon"
                  />{" "}
                  Search Results
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <>
                  <>
                    {!!searchList && !!searchListSuccess ? (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            {countLength.length == 0 ? (
                              <h6 className="mb-0">
                                Can't find your team member's name, would you
                                like to send invitation email
                              </h6>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {searchListData.length > 0 && (
                      <form className="mt-4">
                        {searchListData.map((data) => (
                          <>
                            {!!data.is_individual && !data.team_member && (
                              <>
                                <>
                                  <div
                                    className="add-member-block"
                                    key={data.id}
                                  >
                                    <div
                                      className="add-memeber-group"
                                      style={{ pointerEvents: isHaveLimit ? 'none' : "" }}>
                                      <input type="checkbox" id={data.id} onClick={(e) => handleAddIndividual(e, data)} checked={data.isChecked} />
                                      <label
                                        className="d-flex align-items-center"
                                        htmlFor={data.id}
                                      >
                                        <img
                                          onError={(ev) =>
                                          (ev.target.src =
                                            "/assets/images/member-default.svg")
                                          }
                                          src={
                                            !!data
                                              ? IMAGE_BASE_URL +
                                              data.profile_image
                                              : "/assets/images/member-default.svg"
                                          }
                                          alt="user"
                                        ></img>
                                        <p className="text-capitalize mb-0 ml-3">
                                          {data.name}
                                        </p>

                                        {addSelectedIndividualId.includes("" + data.id) && <i className="ri-checkbox-circle-fill selectedCheck" />}
                                      </label>
                                    </div>
                                  </div>
                                </>
                              </>
                            )}
                          </>
                        ))}
                      </form>
                    )}
                  </>
                </>
              </div>
              {countLength.length > 0 && (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handlleAddClick}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>

      {/* {show modal when click on send request} */}

      <>
        <Modal
          show={openInviteModal}
          onHide={() => setOpenInviteModal(false)}
          backdrop="static"
          keyboard={false}
          centered
          className="invite-moda-l"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Invite Team Member with a personalized email
            </h5>
            <button
              onClick={() => setOpenInviteModal(false)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div className="form-group">
                <CreatableSelect
                  placeholder="Enter your team members emails"
                  isClearable
                  isMulti
                  onChange={(newValue) =>
                    dispatch(changeInviteModelInput({ emails: newValue }))
                  }
                />
                <p
                  style={{ display: "none" }}
                  ref={emailElValidation}
                  className="error-message"
                >
                  Please select at least one email.
                </p>
              </div>

              <div className="form-group text-center mt-4">
                <a
                  name="submit"
                  onClick={handleInviteSumbit}
                  className={
                    !!inviteApiLoading
                      ? "btn btn-primary w-75 disabled"
                      : "btn btn-primary w-75"
                  }
                >
                  Invite
                </a>
                <ClipLoader
                  color={"#fff"}
                  loading={!!inviteApiLoading ? true : false}
                  css={override}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default AddMembersModal;
