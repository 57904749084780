import { GET_API, POST_API, getApi } from "../../../../main/fetch";
import {
  GET_LANDING_PAGE_CONTENT,
  GET_ALL_BLOGS,
  GET_RECENT_BLOGS,
  GET_SINGLE_BLOG,
  GET_LANDINGPAGE_INDUSTRIES,
  GET_FOOTER_CONTENT,
  GET_ABOUTUS_PAGE_CONTENT,
  SEARCH_BLOG,
  SEARCH_BLOGS_DATA,
  GET_ALL_POPULAR_SERVICES,
  GET_ALL_SERVICES_PROVIDER,
  SEARCH_PROVIDER_DATA,
  GET_EARN_WORK_CONTENT,
  FIND_SERVICE_PROVIDER,
} from "../../../urls";
import {
  GET_LANDING_PAGE_CONTENT_REQUEST,
  GET_LANDING_PAGE_CONTENT_FAILURE,
  GET_LANDING_PAGE_CONTENT_SUCCESS,
  GET_BLOGS_DATA_SUCCESS,
  GET_BLOGS_DATA_REQUEST,
  GET_BLOGS_DATA_FAILURE,
  GET_RECENT_BLOGS_REQUEST,
  GET_POPULAR_SERVICE_FAILURE,
  GET_POPULAR_SERVICE_REQUEST,
  GET_POPULAR_SERVICE_SUCCESS,
  GET_RECENT_BLOGS_SUCCESS,
  GET_RECENT_BLOGS_FAILURE,
  GET_SINGLE_BLOG_REQUEST,
  GET_SINGLE_BLOG_FAILURE,
  GET_SINGLE_BLOG_SUCCESS,
  GET_LANDINGPAGE_INDUSTRIES_SUCCESS,
  GET_LANDINGPAGE_INDUSTRIES_FAILURE,
  GET_LANDINGPAGE_INDUSTRIES_REQUEST,
  GET_FOOTER_CONTENT_REQUEST,
  GET_FOOTER_CONTENT_SUCCESS,
  GET_FOOTER_CONTENT_FAILURE,
  GET_ABOUTUS_PAGE_REQUEST,
  GET_ABOUTUS_PAGE_SUCCESS,
  GET_ABOUTUS_PAGE_FAILURE,
  SEARCH_BLOG_DATA_REQUEST,
  SEARCH_BLOG_DATA_SUCCESS,
  SEARCH_BLOG_DATA_FAILURE,
  GET_SERVICES_PROVIDER_REQUEST,
  GET_SERVICES_PROVIDER_SUCCESS,
  GET_SERVICES_PROVIDER_FAILURE,
  GET_SEARCH_PROVIDER_REQUEST,
  GET_SEARCH_PROVIDER_SUCCESS,
  GET_SEARCH_PROVIDER_FAILURE,
  CLEAR_SEARCH_PROVIDER_LIST,
  GET_WORKEARN_CONTENT_SUCCESS,
  GET_WORKEARN_CONTENT_REQUEST,
  GET_WORKEARN_CONTENT_FAILURE,
  GET_FIND_SERVICE_PROVIDER_REQUEST,
  GET_FIND_SERVICE_PROVIDER_SUCCESS,
  GET_FIND_SERVICE_PROVIDER_FAILURE,
} from "./LandingContentConstant";

// GET CONTENT OF LANDING PAGE
export const getLandingPageContentApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_LANDING_PAGE_CONTENT,
      types: [
        GET_LANDING_PAGE_CONTENT_REQUEST,
        GET_LANDING_PAGE_CONTENT_SUCCESS,
        GET_LANDING_PAGE_CONTENT_FAILURE,
      ],
    },
  };
};

// GET ABOUT US PAGE CONTENT
export const getAboutusContentApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_ABOUTUS_PAGE_CONTENT,
      types: [
        GET_ABOUTUS_PAGE_REQUEST,
        GET_ABOUTUS_PAGE_SUCCESS,
        GET_ABOUTUS_PAGE_FAILURE,
      ],
    },
  };
};

// GET ALL BLOGS POST
export const getAllBlogsApi = (currentPage) => {
  return {
    [GET_API]: {
      endpoint: GET_ALL_BLOGS(currentPage),
      types: [
        GET_BLOGS_DATA_REQUEST,
        GET_BLOGS_DATA_SUCCESS,
        GET_BLOGS_DATA_FAILURE,
      ],
    },
  };
};

export const getSearchBlogsApi = (searched, startDate, endDate) => {
  return {
    [GET_API]: {
      endpoint: SEARCH_BLOGS_DATA(searched, startDate, endDate),
      types: [
        SEARCH_BLOG_DATA_REQUEST,
        SEARCH_BLOG_DATA_SUCCESS,
        SEARCH_BLOG_DATA_FAILURE,
      ],
    },
  };
};

// GET RECENT BLOGS
export const getRecentBlogApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_RECENT_BLOGS,
      types: [
        GET_RECENT_BLOGS_REQUEST,
        GET_RECENT_BLOGS_SUCCESS,
        GET_RECENT_BLOGS_FAILURE,
      ],
    },
  };
};

// GET SINGLE BLOG DETAILS
export const getSingleBlogApi = (id) => {
  return {
    [GET_API]: {
      endpoint: GET_SINGLE_BLOG + "/" + id,
      types: [
        GET_SINGLE_BLOG_REQUEST,
        GET_SINGLE_BLOG_SUCCESS,
        GET_SINGLE_BLOG_FAILURE,
      ],
    },
  };
};

// GET ALL INDUSTRIES ON LANDING PAGE
export const getLandingPageIndustries = () => {
  return {
    [GET_API]: {
      endpoint: GET_LANDINGPAGE_INDUSTRIES,
      types: [
        GET_LANDINGPAGE_INDUSTRIES_REQUEST,
        GET_LANDINGPAGE_INDUSTRIES_SUCCESS,
        GET_LANDINGPAGE_INDUSTRIES_FAILURE,
      ],
    },
  };
};

// GET CONTENT OF FOOTER IN LANDING PAGE
export const getFooterContentApi = () => {
  return {
    [GET_API]: {
      endpoint: GET_FOOTER_CONTENT,
      types: [
        GET_FOOTER_CONTENT_REQUEST,
        GET_FOOTER_CONTENT_SUCCESS,
        GET_FOOTER_CONTENT_FAILURE,
      ],
    },
  };
};

//fetch popular services api//
export const getAllPopularServices = (currentPage) => {
  return {
    [GET_API]: {
      endpoint: `${GET_ALL_POPULAR_SERVICES}`,
      types: [
        GET_POPULAR_SERVICE_REQUEST,
        GET_POPULAR_SERVICE_SUCCESS,
        GET_POPULAR_SERVICE_FAILURE,
      ],
    },
  };
};

// fetch service provider api //
export const getAllServicesProvider = (currentPage) => {
  return {
    [GET_API]: {
      endpoint: `${GET_ALL_SERVICES_PROVIDER}?page=${currentPage}`,
      types: [
        GET_SERVICES_PROVIDER_REQUEST,
        GET_SERVICES_PROVIDER_SUCCESS,
        GET_SERVICES_PROVIDER_FAILURE,
      ],
    },
  };
};

export const getSearchProvidersData = (search, page) => {
  return {
    [GET_API]: {
      endpoint: `${SEARCH_PROVIDER_DATA}?search=${search}&page=${page}`,
      types: [
        GET_SEARCH_PROVIDER_REQUEST,
        GET_SEARCH_PROVIDER_SUCCESS,
        GET_SEARCH_PROVIDER_FAILURE,
      ],
    },
  };
};

export const clearSearchProviderList = (newState) => {
  return { type: CLEAR_SEARCH_PROVIDER_LIST, newState };
};

export const getWorkEarnContent = () => {
  return {
    [GET_API]: {
      endpoint: `${GET_EARN_WORK_CONTENT}`,
      types: [
        GET_WORKEARN_CONTENT_REQUEST,
        GET_WORKEARN_CONTENT_SUCCESS,
        GET_WORKEARN_CONTENT_FAILURE,
      ],
    },
  };
};


export const getFindServiesProvider = () => {
  return {
    [GET_API]: {
      endpoint: `${FIND_SERVICE_PROVIDER}`,
      types: [
        GET_FIND_SERVICE_PROVIDER_REQUEST,
        GET_FIND_SERVICE_PROVIDER_SUCCESS,
        GET_FIND_SERVICE_PROVIDER_FAILURE,
      ],
    },
  };
};
