import {
  CLEAR_SEARCH_LIST_RESPONSE,
  CLEAR_SEND_REQUEST_RESPONSE,
  SEARCH_LIST_DATA_FAILURE,
  SEARCH_LIST_DATA_REQUEST,
  SEARCH_LIST_DATA_SUCCESS,
  SEND_REQUEST_DATA_FAILURE,
  SEND_REQUEST_DATA_REQUEST,
  SEND_REQUEST_DATA_SUCCESS,
  SEARCH_SERVICE_DATA_REQUEST,
  SEARCH_SERVICE_DATA_SUCCESS,
  SEARCH_SERVICE_DATA_FAILURE,
} from "./SearchListConstant";

const initialState = {
  searchList: [],
  searchApi: {
    searchListLoading: false,
    searchListStatus: "",
    is_list_empty: "",
    searchListSuccess: "",
  },
  sendRequestApi: {
    sendRequestLoading: "",
    sendRequestStatus: "",
    sendRequestSuccess: "",
    sendRequestMessage: "",
  },
  searchServiceData: [],
  searchServiceList: [],
  searchServiceApi: {
    searchServiceListLoading: false,
    searchServiceListStatus: "",
    is_list_empty: "",
    searchServiceListSuccess: "",
  },
};

export const SearchListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_LIST_DATA_REQUEST:
      return {
        ...state,
        ...{
          searchApi: { ...state.searchApi, ...{ searchListLoading: true } },
        },
      };
    case SEARCH_LIST_DATA_SUCCESS:
      const {
        response: {
          data: { success, data },
        },
      } = action;

      let searchStateSuccess = {
        searchListLoading: false,
        searchListStatus: 200,
        is_list_empty: !!data ? (data.length == 0 ? true : false) : false,
        searchListSuccess: success,
      };
      return {
        ...state,
        ...{
          searchApi: { ...state.searchApi, ...searchStateSuccess },
          searchList: !!data ? data : [],
        },
      };
    case SEARCH_LIST_DATA_FAILURE:
      let searchListFailure = {
        searchListLoading: false,
        searchListStatus: action.response.status,
        is_list_empty: false,
        searchListSuccess: false,
      };
      return {
        ...state,
        ...{
          searchApi: { ...state.searchApi, ...searchListFailure },
          searchList: [],
        },
      };

    case SEARCH_SERVICE_DATA_REQUEST:
      const res = action?.response?.data;
      const sucess = action.response?.data?.status;

      let searchServicesStateSuccess = {
        searchServiceListLoading: false,
        searchServiceListStatus: 200,
        is_list_empty: !!res ? (res.length == 0 ? true : false) : false,
        searchServiceListSuccess: sucess,
      };
      return {
        ...state,
        ...{
          searchServiceList: res != undefined ? res : [],
          searchServiceData: res != undefined ? res?.list : [],
        },
      };


 

    case CLEAR_SEARCH_LIST_RESPONSE:
      let clearSearchResponse = {
        searchListStatus: "",
        is_list_empty: "",
        searchListSuccess: "",
      };
      return {
        ...state,
        ...{
          searchApi: { ...state.searchApi, ...clearSearchResponse },
          searchList: [],
        },
      };
    case SEND_REQUEST_DATA_REQUEST:
      return {
        ...state,
        ...{
          sendRequestApi: {
            ...state.sendRequestApi,
            ...{ sendRequestLoading: true },
          },
        },
      };
    case SEND_REQUEST_DATA_SUCCESS:
      const { response } = action;
      let requestStateSuccess = {
        sendRequestLoading: false,
        sendRequestStatus: response.data.status,
        sendRequestMessage: response.data.message,
        sendRequestSuccess: response.data.success,
      };
      return {
        ...state,
        ...{
          sendRequestApi: { ...state.sendRequestApi, ...requestStateSuccess },
        },
      };
    case SEND_REQUEST_DATA_FAILURE:
      let sendRequestFailure = {
        sendRequestLoading: false,
        sendRequestStatus: action.response.status,
        sendRequestMessage: "",
        sendRequestSuccess: "",
      };
      return {
        ...state,
        ...{
          sendRequestApi: { ...state.sendRequestApi, ...sendRequestFailure },
        },
      };
    case CLEAR_SEND_REQUEST_RESPONSE:
      let clearSendResponse = {
        sendRequestStatus: "",
        sendRequestMessage: "",
        sendRequestSuccess: "",
      };
      return {
        ...state,
        ...{
          sendRequestApi: { ...state.sendRequestApi, ...clearSendResponse },
        },
      };

    default:
      return state;
  }
};

let value = { data: {}, serviceList: [], serviceBool: true };
export const searchLoadingReducer = (states = value, action) => {
  switch (action.type) {
    case "GET_BOOL_VAL":
      return {
        ...states,
        data: action.payload,
      };
    case "GET_SERVICE_LIST":
      if (action.payload.page == 1) {
        return {
          ...states,
          serviceList: [...action.payload.res],
        };
      } else if (action.payload?.res != undefined) {
        return {
          ...states,
          serviceList: [...states.serviceList, ...action.payload?.res],
        };
      }

    default:
      return { ...states };
  }
};
