import { Skeleton } from "primereact/skeleton";
import React from "react";

const ServicesSekeleton = () => {
  const skeleton = new Array(8).fill(
    <Skeleton className="p-3 m-4" width="250px" height="220px" />
  );


  return (

      <div className="d-flex row">
        {skeleton.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
 
  );
};

export default ServicesSekeleton;
