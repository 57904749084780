import {
  GET_WORKEARN_CONTENT_FAILURE,
  GET_WORKEARN_CONTENT_REQUEST,
  GET_WORKEARN_CONTENT_SUCCESS,
} from "../components/LandingPage/LandingContentConstant";

const initialState = {
  getWorkAndEarnData: {
    getWorkAndEarnLoading: false,
    getWorkAndEarnStatus: null,
    getWorkAndEarnMessage: "",
    getWorkAndEarnSuccess: "",
    getWorkAndEarnContent: {},
  },
};

export const workandEarnReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKEARN_CONTENT_REQUEST:
      return {
        ...state,
        ...{
          getWorkAndEarnData: {
            ...state.getWorkAndEarnData,
            ...{ getWorkAndEarnLoading: true },
          },
        },
      };
    case GET_WORKEARN_CONTENT_SUCCESS:
      let getWorkEarnDataSuccess = {
        getWorkAndEarnLoading: false,
        getWorkAndEarnStatus: action.response.status,
        getWorkAndEarnMessage: action.response.data.message,
        getWorkAndEarnSuccess: true,
        getWorkAndEarnContent: action.response.data
          ? action.response.data.data
          : {},
      };
      return {
        ...state,
        ...{
          getWorkAndEarnData: {
            ...state.getWorkAndEarnData,
            ...getWorkEarnDataSuccess,
          },
        },
      };

    case GET_WORKEARN_CONTENT_FAILURE:
      let getWorkEarnDataFailure = {
        getWorkAndEarnLoading: false,
        getWorkAndEarnStatus: action.response.status,
        getWorkAndEarnMessage: action.response.data.message,
        getWorkAndEarnSuccess: false,
        getWorkAndEarnContent: {},
      };
      return {
        ...state,
        ...{
          getWorkAndEarnData: {
            ...state.getWorkAndEarnData,
            ...getWorkEarnDataFailure,
          },
        },
      };
      default:
        return state
  }
};
