export const ListData = [
  {
    id: 1,
    name: "Active Seller",
    imageOne: "/assets/images/active-icon.svg",
    color: "green",
  },
  {
    id: 2,
    name: "Fully Booked",
    imageOne: "/assets/images/booking-icon.svg",
    color: "red",
  },
  {
    id: 3,
    name: "Holiday",
    imageOne: "/assets/images/summer-holidays.png",
    color: "purple",
  },
  {
    id: 5,
    name: "Temporarily Closed",
    imageOne: "/assets/images/temporarly.svg",
    color: "#fc0303",
    width: 30,
  },
  {
    id: 4,
    name: "Custom",
    imageOne: "/assets/images/custom-icon.svg",
    color: "brown",
  },
];
