import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ToastProvider } from "react-toast-notifications";
import { PoolsRoutes } from "./main/routes";

import { useDispatch, useSelector } from "react-redux";
import {
  clearCookies,
  getCookie,
  setCookie,
} from "./library/utilities/functions";
import { SOCKET, establishSocket } from "./library/urls";
import { unreadUserMessages } from "./library/common/components/Toolbar/ToolbarActions";

import $ from "jquery";
import { checkAuthApi } from "./library/api/LoginApiService";
import { allFriendandGroupList } from "./modules/PoolsChat/PoolsChatAction";

import { useToasts } from "react-toast-notifications";
import { changeIsAuth } from "./library/common/actions/AuthActions";
import { getPromotionalHub } from "./library/api/HomeApiService";
const removeDublicateFrd = (chatList) => {
  chatList.forEach((data_outer, i) => {
    let count = 0;
    chatList.forEach((data_inner, j) => {
      if (data_inner.user_id == data_outer.user_id) {
        count += 1;
        if (count > 1) {
          chatList.splice(j, 1);
        }
      }
    });
  });
  return chatList;
};

function sortByDate(a, b) {
  if (a.dateTimestamp > b.dateTimestamp) {
    return -1;
  }
  if (a.dateTimestamp < b.dateTimestamp) {
    return 1;
  }
  return 0;
}

let chatListApiList = [];

let messageList = [];

// new merged chat list //
let newChatList = [];
function App() {
  // const { addToast } = useToasts();

  const dispatch = useDispatch();
  const toolbarState = useSelector((state) => state.ToolbarReducer);
  const authState = useSelector((state) => state.Authreducer);
  const poolsChatState = useSelector((state) => state.PoolsChatReducer);
  const getSellerData = useSelector((state) => state.AccountReducer);
  const networkState = useSelector((state) => state.MyNetworkReducer);
  const [socketID, setSocketId] = useState(null);

  let profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  useEffect(() => {
    if (authState.is_auth) {
      establishSocket(profileData.id);
    } else {
      localStorage.clear();
    }
  }, [authState.is_auth]);

  const allChatList = useSelector((state) => state.chatListReducer);
  const { getChatandGroupList } = allChatList;
  const { unreadUserMessageCount } = toolbarState;

  const {
    friendListDetail: { friendList, groupList },
  } = networkState;
  chatListApiList = friendList;

  const {
    chatDetails: { currenUserId, completeMessageList },
  } = poolsChatState;
  messageList = completeMessageList;

  // CALL ON RELOAD AND LOGIN NEW MERGED//

  newChatList = getChatandGroupList;

  // CALL ON RELOAD AND LOGIN
  function generateDeviceId() {
    var nav = window.navigator;
    
    var screen = window.screen;
    var deviceId = nav.userAgent + nav.language + screen.width + screen.height;
    // You can add more properties to the deviceId if needed
    return btoa(deviceId);
  }


  useEffect(() => {
    let isSession = localStorage.getItem("isSession");
    // CHECK ONLINE AWAY OFFLINE STATUS OF USER //
    if (isSession) {
      if (!!authState.is_auth) {
        document.body.className = "bg-primary";
       

        const handleConnectError = (error) => {
          console.error("Socket connection error:", error);
        };

        SOCKET.on("connect_error", handleConnectError);

        SOCKET.on("connect", () => {

          setCookie("socket_id", SOCKET.id, 1);

          let deviceId = generateDeviceId();

          SOCKET.emit("establish_socket_connection", {
            u_id: profileData.id,
            socket_id: SOCKET.id,
            device_id: deviceId,
          });

          localStorage.setItem("deviceId", deviceId);

          SOCKET.emit("count_unread_message");


          SOCKET.emit("get_friend_and_group_list", {
            u_id: profileData?.id,
          });


          SOCKET.off("get_friend_and_group_list").on(
            "get_friend_and_group_list",
            (chatList) => {
              dispatch(allFriendandGroupList(chatList));
            }
          );


          //to check user is available
          document.addEventListener("visibilitychange", (event) => {
            // IF DOCUMENT HIDDEN//
            if (document.hidden) {
              profileData = !!getCookie("profile_data")
                ? JSON.parse(getCookie("profile_data"))
                : "";

              isSession = localStorage.getItem("isSession");

              if (isSession) {
                SOCKET.emit("change_user_status", {
                  user_id: profileData.id,
                  is_online: 2,
                });
              }
            }
            // NOT HIDDEN//
            else {
              isSession = localStorage.getItem("isSession");
              profileData = !!getCookie("profile_data")
                ? JSON.parse(getCookie("profile_data"))
                : "";

              if (isSession) {
                SOCKET.emit("change_user_status", {
                  user_id: profileData.id,
                  is_online: 1,
                });
              }
            }
          });

          // IF WINDOW IS CLOSED//
          if (window.closed) {
            if (profileData?.id) {
              SOCKET.emit("change_user_status", {
                user_id: profileData.id,
                is_online: 0,
              });
            }
          }

          // IF USER NOT IN CHAT PATH//

          // CALL WHEN EVER USER LOGIN OR RELOAD AND SENDER SENDS MESSAGES//
          if (window.location.pathname !== "/chat") {
            SOCKET.off("count_unread_message").on(
              "count_unread_message",
              (unreadMessageNotification) => {
                if (!!unreadMessageNotification) {
                  dispatch(
                    unreadUserMessages({
                      unreadUserMessageCount: unreadMessageNotification,
                    })
                  );
                }
              }
            );

            SOCKET.emit("get_unread_frd_messages", {
              user_id: profileData.id,
              check_user: profileData.id,
              toMe: true,
            });

            SOCKET.emit("get_all_unread_group_messages_for_login_user", {
              user_id: profileData.id,
              toMe: true,
            });

            const friendId = !!getCookie("friendId")
              ? getCookie("friendId")
              : null;

            SOCKET.emit("send_my_message_status", {
              sender_id: profileData.id,
              reciever_id: null,
            });

            // CALL ON RELOAD

            const oldCookieData = !!getCookie("oldFriendId")
              ? Number(getCookie("oldFriendId"))
              : null;

            if (!!oldCookieData) {
              SOCKET.emit("send_my_frd_i_am_on_another_chat", {
                reciever_id: oldCookieData,
                sessionId: getCookie("token_id"),
                sender_id: profileData.id,
              });
            }
          }
        });
        //--socket connection end----//

        //The working next statement.
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const data = new FormData();
            data.append("latitude", position.coords.latitude);
            data.append("longitude", position.coords.longitude);

            dispatch(checkAuthApi(data));
          },
          function (e) {
            // dispatch(checkAuthApi(data))
          },
          {
            enableHighAccuracy: true,
          }
        );
      }
    }

    if (!authState.is_auth) {
      document.body.className = "";
    }

    return () => {
      SOCKET.off("connect");
    };
  }, [authState.is_auth]);

  let currentUserId = currenUserId;
 
  // ---------GET UNREAD MEESGAE COUNT WHEN FRIEND SEND MESSAGE NEW MERGED-------//

  useEffect(() => {
    if (window.location.pathname !== "/chat") {
      SOCKET.off("set_count_unread_message").on(
        "set_count_unread_message",
        (data) => {
          if (window.location.pathname !== "/chat") {
            let newArr = unreadUserMessageCount;
            if (newArr.length > 0) {
              let check = newArr.find((item) => data.id === item.id);
              if (!check) {
                newArr.push(data);
              }
              dispatch(unreadUserMessages(newArr));
            } else {
              newArr.push(data);

              dispatch(unreadUserMessages({ unreadUserMessageCount: newArr }));
            }
          }
        }
      );
    }
  }, [window.location.pathname]);

  // --------SEND STATUS AND UPDATE READ MESSAGE STAUS TO = 0 WHEN USER IS NOT ON CHAT PATH-------//
  useEffect(() => {
    if (
      window.location.pathname !== "/chat" &&
      window.location.pathname !== "/"
    ) {

      setTimeout(()=>
      {
        SOCKET.emit("update_all_message_read_unread_status", {
          sender_id: !!profileData ? profileData.id : null,
        });
  
        SOCKET.emit("send_my_message_status", {
          sender_id: profileData.id,
          reciever_id: null,
        });
      },1500)
    
    }
  }, [window.location.pathname]);

  useEffect(() => {
    $("body").click(function () {
      $(".share-box").removeClass("open-share");
    });
    $("body").click(function (event) {
      $(".react-box").removeClass("open-emoji");
    });
    /* TOP TO SCROLL */
    $(".back-to-top").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, 1200);
      return false;
    });

    $(window).scroll(function () {
      if ($(window).scrollTop() > $("body").height() / 2) {
        $(".back-to-top").show();
      } else {
        $(".back-to-top").hide();
      }
    });

    var deviceWidth = $(window).width();
    if (deviceWidth < 480) {
      $(".share-thought").attr("placeholder", "What do you want to share?");
    } else {
      $(".share-thought").attr(
        "placeholder",
        "What do you want to share with your audience?"
      );
    }

    $(window).on("resize", function () {
      var deviceWidth = $(window).width();
      if (deviceWidth < 480) {
        $(".share-thought").attr("placeholder", "What do you want to share?");
      } else {
        $(".share-thought").attr(
          "placeholder",
          "What do you want to share with your audience?"
        );
      }
    });
  }, []);

  // new merged socket useEffect
  useEffect(() => {

    // single frnd chat//
    SOCKET.off("get_unread_frd_messages").on(
      "get_unread_frd_messages",
      (unreadData) => {

        let total_unread_frds = 0;
        if (unreadData.check_user == profileData.id) {
          let chat_List = newChatList;

          let unreadList = unreadData.unreadMessagesList;

          for (let i in unreadList) {
            for (let j in chat_List) {
              if (unreadList[i].friend_id == chat_List[j].user_id) {
                chat_List[j].counts = unreadList[i].counts;
                chat_List[j].dateTimestamp = unreadList[i].created_at
                  ? unreadList[i].created_at
                  : 0;
              }
            }
          }

          let frdList = unreadList.filter((el) => {
            return !chat_List.find((element) => {
              return element.user_id == el.friend_id;
            });
          });

          for (let i in chat_List) {
            chat_List[i].dateTimestamp = !!chat_List[i]?.dateTimestamp
              ? chat_List[i]?.dateTimestamp
              : chat_List[i]?.created_at;
          }

          if (!!chat_List?.length) {
            chat_List.sort(sortByDate);
          }

          if (window.location.pathname == "/chat") {
            dispatch(allFriendandGroupList(chat_List));
          }
        }
      }
    );

    // for group login user--//
    SOCKET.off("get_all_unread_group_messages_for_login_user").on(
      "get_all_unread_group_messages_for_login_user",
      () => {
        SOCKET.emit("fetch_all_unread_group_messages", {
          user_id: profileData.id,
        });

        SOCKET.off("fetch_all_unread_group_messages").on(
          "fetch_all_unread_group_messages",
          (unreadGroupList) => {
            let group_List = newChatList;

            for (let i in unreadGroupList) {
              for (let j in group_List) {
                if (
                  unreadGroupList[i].group_id == group_List[j].id &&
                  unreadGroupList[i].user_id == profileData.id
                ) {
                  group_List[j].counts = unreadGroupList[i].count;
                  group_List[j].dateTimestamp = unreadGroupList[i].created_at;
                }
              }
            }

            if (window.location.pathname == "/chat") {
              dispatch(allFriendandGroupList(group_List));
            }
          }
        );
      }
    );

    SOCKET.off("ping_frd_my_unread_message").on(
      "ping_frd_my_unread_message",
      function (data) {
        if (data.user_id == profileData.id) {
        }
      }
    );

    // WHEN SOMEONE SENDS MESSAGE AND MESSAGE BOX IS NOT OPEN-------------//
    SOCKET.off("get_all_unread_group_messages").on(
      "get_all_unread_group_messages",
      () => {
        SOCKET.emit("fetch_all_unread_group_messages", {
          user_id: profileData.id,
        });

        SOCKET.off("fetch_all_unread_group_messages").on(
          "fetch_all_unread_group_messages",
          (unreadGroupList) => {
            let group_List = newChatList;

            for (let i in unreadGroupList) {
              for (let j in group_List) {
                if (
                  unreadGroupList[i].group_id == group_List[j].id &&
                  unreadGroupList[i].user_id == profileData.id &&
                  unreadGroupList[i].group_id !== currentUserId
                ) {
                  group_List[j].counts = unreadGroupList[i].count;
                  group_List[j].dateTimestamp = unreadGroupList[i].created_at;
                }
              }
            }

            group_List.sort(sortByDate);

            if (window.location.pathname == "/chat") {
              dispatch(allFriendandGroupList(group_List));
            }
          }
        );
      }
    );
  }, []);

  useEffect(() => {
    SOCKET.off("logout").on("logout", () => {

      dispatch(changeIsAuth({ is_auth: false }));
      addToast("Your account is logged in on another browser or device.", {
        appearance: "error",
        autoDismiss: true,
     
      });

      clearCookies();
      SOCKET.close();
      window.localStorage.removeItem("servicenotification");
      window.localStorage.removeItem("clickServiceNotification");
      window.localStorage.removeItem("ClickOnSeachProfile");
      window.localStorage.removeItem("tabActive");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("isSession");
      window.sessionStorage.clear();

      setTimeout(() => {
        // window.location.replace("/");
      }, 3000);
    });
  }, []);

  useEffect(() => {
    if (profileData.user_status == 0) {
      addToast("Your account is suspended by admin", {
        appearance: "info",
        autoDismiss: true,
   
      });

      setTimeout(() => {
        clearCookies();
        window.localStorage.removeItem("isSession");
        window.sessionStorage.clear();
        window.location.replace("/");
      }, 3000);
    }
  }, [profileData?.user_status]);



  return (
    // <ToastProvider newestOnTop={true} autoDismissTimeout={4000}>
    <Router>
      <Switch>
        <PoolsRoutes is_auth={authState.is_auth} />
      </Switch>
    </Router>
// </ToastProvider>
    
  );
}

export default App;
