import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import copy from "copy-to-clipboard";
import InviteModel from "./InviteModel";
import { useHistory } from "react-router-dom";
import { getCookie } from "../../../library/utilities/functions";
import { changeInviteModelInput } from "../HomeActions";
import { useDispatch } from "react-redux";
import { SUGGESTED_USER_POST_API } from "../../../library/urls";
import { sendRequestDataApi } from "../../../library/api/SearchApiService";
import { IMAGE_BASE_URL } from "../../../library/urls";
import SideBarProfileMenu from "./SideBarProfileMenu";
import introJs from "intro.js";
import "intro.js/introjs.css";

import "./firstTime.css";

const FirstTimeLogin = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const [openInvite, setInviteModel] = useState(false);
  let firstTimeLogin = !!getCookie("firstTimeLogin")
    ? getCookie("firstTimeLogin")
    : "";

  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";

  let displayTutorial = localStorage.getItem("displaySuggestionIntro");

  const history = useHistory();
  const [suggestedList, setSuggestedList] = useState([]);
  const [isCopyClip, setIsCopyClip] = useState(false);

  //Api call to get list of suggested User
  const getSuggestedListApi = () => {
    let config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
    };

    fetch(SUGGESTED_USER_POST_API, config)
      .then((response) => {
        response.json().then((data) => {
          setSuggestedList(data.list ? data.list : []);
        });
      })
      .catch((err) => ({
        data: { error: true, message: "Internal Server Error" },
        status: 500,
      }));
  };

  useEffect(() => {
    getSuggestedListApi();
  }, []);

  //this function is used to copy the link
  const handleCopyClip = () => {
    setIsCopyClip(true);
    copy("https://www.poolsmagnic.com/", {
      debug: true,
    });
    addToast("Copied!", {
      appearance: "success",
      autoDismiss: true,
      // autoDismissTimeout: 2000,
    });
    setTimeout(() => setIsCopyClip(false), 2000);
  };
  //this function is used to clear invite model details
  const closeInviteModel = () => {
    dispatch(changeInviteModelInput({ emails: [], message: "" }));
    setInviteModel(false);
  };

  const handleNewOpenProfile = (item) => {
    history.push(
      `/search-profile/${item.user_id}`

      // `/search-profile/${item.user_id}?individual=${
      //   !!item.is_individual ? true : false
      // }`
    );
  };

  //On click of Link-Up button then this function hit.this function is used to send friend request.
  const sendRequest = (Id, i) => {
    const bodyParameter = {
      id: Id,
    };
    dispatch(sendRequestDataApi(bodyParameter)).then(() => {
      addToast("Link-up request sent successfully", {
        appearance: "success",
        autoDismiss: true,
        // autoDismissTimeout: 2000,
      });
      suggestedList[i].isClicked = true;
      getSuggestedListApi();
    });
  };

  // first time tutorial start //

  const startIntro = () => {
    const intro = introJs().setOptions({
      steps: [
        {
          intro:
            '<div class="container"> <div class="row align-items-center"> <div class="col-md-6"> <div class="d-flex align-items-start flex-column flex-md-row me-2 mb-3"> <img src="assets/images/icon.svg" style="width: 60px;" />  <h5 class="pl-0 pl-md-3 m-0 invite-title">Invite, Link Up, and Build the Potential of Your Network and Clientele Base</h5></div> <p>Build your clientele by inviting both your loyal supporters and new connections. Expand your reach, boost visibility, and drive engagement. Gain traction and significance as your network grows.</p> <p> It starts by extending invitations through diverse tools and establishing connections by linking up with suggested individuals or businesses.</p> </div>  <div class="col-md-6 text-center"> <img class="tutotial-image" src="assets/images/suggestion-tutorial.gif" style="max-width: 100%;max-height: 288px;object-fit: cover;object-position: left;">  </div>   </div>   </div>',
        },
      ],
      doneLabel: "Got it",
      tooltipClass: "custom-tooltip-class",
      buttonClass: "btn btn-sm btn-primary",
      showBullets: false,
    });

    intro.onafterchange(() => {
      setTimeout(() => {
        const tooltipContent = document.querySelector(".custom-tooltip-class");
        if (tooltipContent) {
          tooltipContent.scrollTop = 0;
        }
      }, 1000);

      // Adjust the delay as needed
    });

    intro.start();
    document.querySelector(".introjs-tooltip-title").remove();
  };

  useEffect(() => {
    if (displayTutorial !== "true" && firstTimeLogin === "true") {
      startIntro();
      localStorage.setItem("displaySuggestionIntro", true);
    }
  }, []);

  // first time tutorial end //
  return (
    <section className="network invite spacer bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <SideBarProfileMenu />
          </div>
          <div className="col-md-9">
            {!!firstTimeLogin ? (
              <div className="next-btn text-right">
                <a
                  href="javascript:;"
                  onClick={() =>
                    history.push(`/account/${profileData?.user_slug}`)
                  }
                  className="btn btn-primary mb-5"
                >
                  Next
                </a>
              </div>
            ) : (
              ""
            )}

            <div className="invite__box col-md-10">
              {!!firstTimeLogin ? (
                <>
                  {" "}
                  <h1 className="h2 font-weight-bold">
                    Let's help you build your network
                  </h1>
                  <h5 className="mb-5">Invite an individual or a business</h5>
                </>
              ) : (
                ""
              )}
              <div className="invite-option custom-shadow">
                <a
                  href="javascript:;"
                  className="link-color"
                  onClick={() => setInviteModel(true)}
                  data-toggle="modal"
                  data-target="#invite-modal"
                >
                  <i className="ri-mail-send-line" /> Invite with a personalized
                  email
                </a>
              </div>
              <div
                className={`invite-option custom-shadow cursor-pointer ${
                  isCopyClip ? "disabled" : ""
                }`}
                onClick={handleCopyClip}
              >
                <a href="javascript:;" className="link-color">
                  <i className="ri-link" /> Invite by copying a link
                </a>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 d-flex  justify-content-end">
                  <form className="search-form" autoComplete="off">
                    <div className="form-group mb-0"></div>
                  </form>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12 d-flex align-items-center justify-content-between">
                  <h1 className="h2">Suggested Network</h1>
                </div>
              </div>
              <div className="row mt-3 scale-effect">
                {suggestedList.map(
                  (item, i) =>
                    item.user_id != 176 &&
                    item !== null &&
                    item.if_friends == false && (
                      <div className="col-md-4 col-lg-3 col-sm-6 col-50 card-before">
                        <div className="network__box bg-white pt-4 br-20 text-center position-relative suggest_list">
                          <div
                            className={`network__box__img  mx-auto network__box--lgthumb ${
                              item.account_type === "business"
                                ? "business-user"
                                : ""
                            }`}
                          >
                            <a
                              href="javascript:;"
                              onClick={() => handleNewOpenProfile(item)}
                            >
                              {item.account_type === "individual" ? (
                                <img
                                  onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/member-default.svg")
                                  }
                                  src={
                                    item.profile_image
                                      ? IMAGE_BASE_URL + item.profile_image
                                      : ""
                                  }
                                  alt="user"
                                />
                              ) : (
                                <img
                                  onError={(ev) =>
                                    (ev.target.src =
                                      "/assets/images/business_logo.svg")
                                  }
                                  src={
                                    item.business_image
                                      ? IMAGE_BASE_URL + item.business_image
                                      : ""
                                  }
                                  alt="user"
                                />
                              )}
                            </a>
                          </div>
                          <div className="network__box__info mt-3 px-2">
                            <a
                              href="javascript:;"
                              className="link-color text-capitalize"
                              onClick={() => handleNewOpenProfile(item)}
                            >
                              <h5 className="text-capitalize h6">
                                {item.is_business == 1
                                  ? item.business_name?.length > 15
                                    ? item?.business_name.slice(0, 16) + "..."
                                    : item.business_name
                                  : item.user_name}
                              </h5>
                            </a>
                            <div className="mb-0 text-capitalize  small network-tooltip position-relative pb-2 cursor-pointer">
                              <span className="text-fade">
                                {item?.is_business == 1
                                  ? item.specialization
                                  : item.work}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-hover">
                          <div className="network__action d-flex align-items-center justify-content-center mt-3 call-info">
                            <div className="profile-action mt-2 pb-2">
                              {item.if_friends == true ? (
                                <a
                                  className="btn btn-primary view-profile frnd-btn"
                                  href="javascript:;"
                                  role="button"
                                >
                                  <i className="ri-user-follow-line"></i>{" "}
                                </a>
                              ) : !!item.if_friend_request_sent ? (
                                <a
                                  className="btn bg-success view-profile"
                                  href="javascript:;"
                                  role="button"
                                >
                                  <i className="ri-user-follow-line" /> Request
                                  sent
                                </a>
                              ) : (
                                <button
                                  className="btn btn-blue view-profile"
                                  href="javascript:;"
                                  onClick={() => sendRequest(item.user_id, i)}
                                  disabled={item.isClicked}
                                >
                                  <i className="ri-user-add-line"></i> Link-Up
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <InviteModel
        openInvite={openInvite}
        closeInviteModel={closeInviteModel}
      />
    </section>
  );
};
export default FirstTimeLogin;
