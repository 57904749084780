import React from 'react'
import ReactLoading from "react-loading";
import styled from "tachyons-components";

const Section = styled('div')`
flex flex-wrap content-center justify-center Service-loader-result `;

const Article = styled('div')``;
const SearchMiniLoading = () => {
    return (
        <Section>
            <Article >
                <ReactLoading type={'spin'} color="#000" width='44px' />
            </Article>
        </Section>
    )
}
export default SearchMiniLoading