import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { IMAGE_BASE_URL } from '../../../library/urls';
import { getCookie } from '../../../library/utilities/functions'
import { changePostReactionDetails, changeShareListDetail, changeViewsDetails, clearLikeListResponse, clearPostReactionListResponse, clearPostViewListResponse, clearShareListResponse, clearSingleLikeListResponse, clearSinglePostReactionListResponse, clearSinglePostViewListResponse, clearSingleShareListResonse } from '../HomeActions';
import Posts from './Posts'
import SharePost from './SharePost'
import SinglePostModel from './SinglePostModel';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import { getAllSellerDataApi } from '../../../library/api/AccountApiService';

const override = css`   
    text-align: center;
    width: 55px;
    height: 55px;
    position: absolute;
    top: 40%;
    right: 50%;
`;

const Feeds = ({ HomeState }) => {
  const { reactionListDetail: { reactListPage, totalReact, reactList, singleReactListpage } } = HomeState
  const { reactListApi: { reactListLoading, reactListStatus } } = HomeState
  const { singleReactListApi: { singleReactListLoading } } = HomeState
  const { postViewListDetail: { viewList, totalViews, viewListPage, singleViewListpage } } = HomeState
  const { postViewListApi: { viewListLoading, viewListStatus } } = HomeState
  const { singlePostViewListApi: { singleViewListLoading } } = HomeState
  const { postShareListDetail: { shareListPage, shareList, totalShare, singleShareListpage } } = HomeState
  const { feedShareListApi: { feedShareListLoading, feedShareListStatus } } = HomeState
  const { singlefeedShareListApi: { singleShareListLoading } } = HomeState
  const profileData = !!getCookie("profile_data") ? JSON.parse(getCookie("profile_data")) : ""
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    return () => {
      dispatch(clearPostReactionListResponse())
      dispatch(clearSinglePostReactionListResponse())
      dispatch(clearLikeListResponse())
      dispatch(clearSingleLikeListResponse())
      dispatch(clearPostViewListResponse())
      dispatch(clearSinglePostViewListResponse())
      dispatch(clearShareListResponse())
      dispatch(clearSingleShareListResonse())
    }
  }, [])

  //this function is used to fetch reaction user list 
  const handleScroolReact = (response) => {
    if (response.top == 1) {
      if (reactListStatus == 200) {
        dispatch(changePostReactionDetails({ reactListPage: reactListPage + 1 }))
      }
      else {
        dispatch(changePostReactionDetails({ singleReactListpage: singleReactListpage + 1 }))
      }
    }
  }
  //this function is used to close the react model
  const CloseReactionModel = () => {
    dispatch(clearPostReactionListResponse())
    dispatch(clearSinglePostReactionListResponse())
  }
  const handleViewListClose = () => {
    dispatch(clearPostViewListResponse())
    dispatch(clearSinglePostViewListResponse())
  }
  const handleShareListClose = () => {
    dispatch(clearShareListResponse())
    dispatch(clearSingleShareListResonse())
  }

  const handleViewScrool = (response) => {
    if (response.top == 1) {
      if (viewListStatus == 200) {
        dispatch(changeViewsDetails({ viewListPage: viewListPage + 1 }))
      }
      else {
        dispatch(changeViewsDetails({ singleViewListpage: singleViewListpage + 1 }))
      }
    }
  }
  const handleShareScrool = (response) => {
    if (response.top == 1) {
      if (feedShareListStatus == 200) {
        dispatch(changeShareListDetail({ shareListPage: shareListPage + 1 }))
      }
      else {
        dispatch(changeShareListDetail({ singleShareListpage: singleShareListpage + 1 }))
      }
    }
  }
  const clickPersonalProfile = (data) => {
    const postModal = document.getElementById('post-modal');

    if (!!postModal.classList.value.match("show")) {
      postModal.classList.remove('show');
      postModal.setAttribute('aria-hidden', 'true');
      postModal.setAttribute('style', 'display: none');
      const modalBackdrops = document.getElementsByClassName('modal-backdrop fade show');
      document.body.removeChild(modalBackdrops[0]);
    }
    if (profileData.id == data.user_id) {

      history.push(`/account/${profileData?.user_slug}`)
    }
    else {
      history.push(`/search-profile/${data.user_id}`)
   
      // history.push(`/search-profile/${data.user_id}?individual=${!!data.is_individual ? true : false}`)
   
    }
  }

  const openReactProfile = (data) => {
    const modal = document.getElementById('react-modal');
    document.querySelector('body').classList.remove('modal-open');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop fade show');
    document.body.removeChild(modalBackdrops[0]);
    clickPersonalProfile(data)
  }
  const openViewProfile = (data) => {
    const modal = document.getElementById('view-modal');
    document.querySelector('body').classList.remove('modal-open');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop fade show');

    document.body.removeChild(modalBackdrops[0]);

    clickPersonalProfile(data)
  }
  const openShareProfile = (data) => {
    const modal = document.getElementById('share-modal');

    document.querySelector('body').classList.remove('modal-open');

    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop fade show');

    document.body.removeChild(modalBackdrops[0]);

    clickPersonalProfile(data)
  }
  useEffect(async () => {
    dispatch(getAllSellerDataApi())

  }, []);
  return (
    <>

      <div>
        <Posts profileData={profileData}
          HomeState={HomeState} />
      </div>

      <SharePost HomeState={HomeState}
        profileData={profileData}
        addToast={addToast} />

      <SinglePostModel HomeState={HomeState}
        profileData={profileData}
        clickPersonalProfile={clickPersonalProfile} />

      {/* view MODAL */}
      {/* Modal */}
      <div className="modal fade upper-modal" id="view-modal" tabIndex={-1} data-backdrop="static" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <ul className="nav nav-pills mb-3" id="pills-details" role="tablist">
                <li className="nav-item">
                  <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false"><img src="/assets/images/eye-fill.svg" alt="like" />{totalViews}</a>
                </li>
              </ul>
              <button onClick={() => handleViewListClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div className="users-listing">
                    <Scrollbars onScrollFrame={handleViewScrool} autoHide >
                      {viewList.length > 0 &&
                        viewList.map((data) => (
                          <div className="users-listing__item">
                            {!!data.is_individual ?
                              <img style={{ cursor: 'pointer' }} onClick={() => openViewProfile(data)} onError={(ev) => ev.target.src = '/assets/images/member-default.svg'} src={!!data.user_image ? IMAGE_BASE_URL + data.user_image : "/assets/images/member-default.svg"} alt="user" />
                              :
                              <img style={{ cursor: 'pointer' }} onClick={() => openViewProfile(data)} onError={(ev) => ev.target.src = '/assets/images/business_logo.svg'} src={!!data.user_image ? IMAGE_BASE_URL + data.user_image : '/assets/images/business_logo.svg'} alt="user" />}
                            <h6 style={{ cursor: 'pointer' }} onClick={() => openViewProfile(data)} className="text-capitalize mb-0">{data.user_name}</h6>
                          </div>
                        ))}
                    </Scrollbars>
                    {viewList.length <= 0 &&
                      <ClipLoader color={"#212529"} loading={(!!viewListLoading || !!singleViewListLoading) ? true : false} css={override} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* share MODAL */}
      {/* Modal */}
      <div className="modal fade upper-modal" id="share-modal" tabIndex={-2} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <ul className="nav nav-pills mb-3" id="pills-details" role="tablist">
                <li className="nav-item">
                  <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false"><i className="ri-share-fill"></i>{totalShare}</a>
                </li>
              </ul>
              <button type="button" onClick={() => handleShareListClose()} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div className="users-listing">
                    <Scrollbars onScrollFrame={handleShareScrool} style={{ height: 320 }}>
                      {shareList.length > 0 &&
                        shareList.map((data) => (
                          <div className="users-listing__item">
                            {!!data.is_individual ?
                              <img style={{ cursor: 'pointer' }} onClick={() => openShareProfile(data)} onError={(ev) => ev.target.src = '/assets/images/member-default.svg'} src={!!data.user_image ? IMAGE_BASE_URL + data.user_image : "/assets/images/member-default.svg"} alt="user" />
                              :
                              <img style={{ cursor: 'pointer' }} onClick={() => openShareProfile(data)} onError={(ev) => ev.target.src = '/assets/images/business_logo.svg'} src={!!data.user_image ? IMAGE_BASE_URL + data.user_image : '/assets/images/business_logo.svg'} alt="user" />}
                            <h6 style={{ cursor: 'pointer' }} onClick={() => openShareProfile(data)} className="text-capitalize mb-0">{data.user_name}</h6>
                          </div>
                        ))}
                    </Scrollbars>
                    {shareList.length <= 0 &&
                      <ClipLoader color={"#212529"} loading={(!!feedShareListLoading || !!singleShareListLoading) ? true : false} css={override} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* react MODAL */}
      {/* Modal */}
      <div className="modal fade upper-modal" id="react-modal" tabIndex={-1} data-backdrop="static" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <ul className="nav nav-pills mb-3" id="pills-details" role="tablist">
                {totalReact.length > 0 &&
                  totalReact.map((item) => {
                    return <li className="nav-item">
                      <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">
                        {item.reaction == 1 ? <img src="/assets/images/300x300.gif" alt="Like" />
                          : item.reaction == 2 ? <img src="/assets/images/love.gif" alt="Love" />
                            : item.reaction == 3 ? <img src="/assets/images/clap.gif" alt="clab" />
                              : item.reaction == 4 ? <img src="/assets/images/celebrate.gif" alt="celebrate" />
                                : item.reaction == 5 ? <img src="/assets/images/thinking.gif" alt="Thinking" />
                                  : item.reaction = 6 ? <img src="/assets/images/upset.gif" alt="upset" />
                                    : ""}
                        {item.count}</a>
                    </li>
                  })}
              </ul>
              <button type="button" onClick={CloseReactionModel} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div className="users-listing">
                    <Scrollbars onScrollFrame={handleScroolReact} autoHide >
                      {reactList.length > 0 &&
                        reactList.map((data) => (
                          <div className="users-listing__item align-items-center">
                            <div className="reaction__counter position-relative" style={{ cursor: 'pointer' }} onClick={() => openReactProfile(data)} >
                              {!!data.is_individual ?
                                <img onError={(ev) => ev.target.src = '/assets/images/member-default.svg'} src={!!data.user_image ? IMAGE_BASE_URL + data.user_image : "/assets/images/member-default.svg"} alt="user" />
                                :
                                <img onError={(ev) => ev.target.src = '/assets/images/business_logo.svg'} src={!!data.user_image ? IMAGE_BASE_URL + data.user_image : '/assets/images/business_logo.svg'} alt="user" />}
                              <div className="reaction-image">
                                {data.reaction == 1 ? <img src="/assets/images/300x300.gif" alt="Like" />
                                  : data.reaction == 2 ? <img src="/assets/images/love.gif" alt="Love" />
                                    : data.reaction == 3 ? <img src="/assets/images/clap.gif" alt="clab" />
                                      : data.reaction == 4 ? <img src="/assets/images/celebrate.gif" alt="celebrate" />
                                        : data.reaction == 5 ? <img src="/assets/images/thinking.gif" alt="Thinking" />
                                          : data.reaction = 6 ? <img src="/assets/images/upset.gif" alt="upset" />
                                            : ""
                                }
                              </div>
                            </div>

                            <h6 style={{ cursor: 'pointer' }} onClick={() => openReactProfile(data)} className="text-capitalize mb-0">{data.user_name}</h6>
                          </div>
                        ))}
                    </Scrollbars>
                    {reactList.length <= 0 &&
                      <ClipLoader color={"#212529"} loading={(!!reactListLoading || !!singleReactListLoading) ? true : false} css={override} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default Feeds