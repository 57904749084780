const MAIN_SLIDER_DATA_REQUEST = "MAIN_SLIDER_DATA_REQUEST";
const MAIN_SLIDER_DATA_SUCCESS = "MAIN_SLIDER_DATA_SUCCESS";
const MAIN_SLIDER_DATA_FAILURE = "MAIN_SLIDER_DATA_FAILURE";

const CLEAR_MAIN_SLIDER_RESPONSE = "CLEAR_MAIN_SLIDER_RESPONSE";

const CHECK_AUTH_REQUEST = "CHECK_AUTH_REQUEST";
const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
const CHECK_AUTH_FAILURE = "CHECK_AUTH_FAILURE";

export {
  MAIN_SLIDER_DATA_REQUEST,
  MAIN_SLIDER_DATA_SUCCESS,
  MAIN_SLIDER_DATA_FAILURE,
  CLEAR_MAIN_SLIDER_RESPONSE,
  CHECK_AUTH_REQUEST,
  CHECK_AUTH_SUCCESS,
  CHECK_AUTH_FAILURE,
};
