import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchServiceListApi,
  getAllSellerDataApi,
} from "../../../library/api/AccountApiService";
import Select from "react-select";
import axios from "axios";
import {
  filterSelectedValueOfArr,
  getCookie,
  onlyUniqueArrOfObject,
} from "../../../library/utilities/functions";
import { useToasts } from "react-toast-notifications";
import { API_BASE_URL } from "../../../library/urls";
import CreatableSelect from "react-select/creatable";

export default function EditServices({
  getAllSellerDatas,
  primaryServices,
  getServiceDataFromEditService,
  secondaryService,
  industry,
  setIsEditService,
  setIsShow,
}) {
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const { addToast } = useToasts();
  const [selectedMainServices, setSelectedMainService] = useState([]);
  const [fileterMainServices, setFilterMainServiceOption] = useState([]);
  const [selectedSecondaryServices, setSelectedSecondaryService] = useState([]);
  const [filterSecondaryServiceOption, setFilterSecondaryServiceOption] =
    useState([]);
  const [filterIndustryOption, setFilterIndustryOption] = useState([]);
  const [error, setError] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [page, setPage] = useState(1);
  const AccountStateData = useSelector((state) => state.AccountReducer);
  const dispatch = useDispatch();
  const [cloneMainService, setCloneMainService] = useState([]);
  const [cloneSecondaryService, setCloneSecondaryService] = useState([]);
  /* filter main serviceList  */
  const [isCreatedMainServices, setIsCreatedMainServices] = useState([]);
  const [isCreateSecondaryService, setIsCreateSecondaryService] = useState([]);



  useEffect(() => {
    if (!!AccountStateData?.servicesList) {
      const _MAINSERVICE = filterSelectedValueOfArr(
        AccountStateData?.servicesList,
        selectedMainServices
      );
      const uniqueValue = onlyUniqueArrOfObject(_MAINSERVICE);
      setFilterMainServiceOption(uniqueValue);
      setCloneMainService(uniqueValue);
    }
  }, [AccountStateData, selectedMainServices]);

  useEffect(() => {
    if (!!AccountStateData?.servicesList) {
      const newSecondaryOption = filterSelectedValueOfArr(
        AccountStateData?.servicesList,
        selectedSecondaryServices
      );
      const uniqueValue = onlyUniqueArrOfObject(newSecondaryOption);
      setFilterSecondaryServiceOption(uniqueValue);

      setCloneSecondaryService(uniqueValue);
    }
  }, [AccountStateData?.servicesList, selectedSecondaryServices]);

  useEffect(() => {
    if (AccountStateData?.industryList) {
      const option = AccountStateData?.industryList?.filter((itemA) => {
        return !selectedIndustry?.find((itemB) => {
          return itemA.industries_name === itemB.label;
        });
      });
      const newOptiom = option.map((item) => {
        return {
          label: item.industries_name,
          value: item.id,
        };
      });
      setFilterIndustryOption(newOptiom);
    }
  }, [AccountStateData?.industryList, selectedIndustry]);




  useEffect(() => {
    setSelectedMainService(getAllSellerDatas?.main_service);
    setSelectedSecondaryService(getAllSellerDatas?.secondary_service);
    setSelectedIndustry(getAllSellerDatas?.industry);
  }, [getAllSellerDatas?.main_service?.length > 0]);

  const servicesAndIndustryFunc = (opt, services) => {
    if (opt.length - 1 === services.length) {
      const x = opt[opt.length - 1];
      let arr = [
        ...services,
        { label: x.label, id: x.id, value: x.value, user_id: x.user_id, },
      ];
      return arr;
    } else {
      let res = services.filter(
        (x1) => !opt.some((x2) => x1.label === x2.label)
      );
      const deleteSingleVal = services.filter(
        (item) => res[0].label != item.label
      );
      return deleteSingleVal;
    }
  };
  /* make new array of object on onChange */
  const handleServicesAndIndustry = (opt, data, actionMeta) => {
    if (data === "industry") {
      const arr = servicesAndIndustryFunc(opt, selectedIndustry);
      setSelectedIndustry(arr);
    } else if (data === "main") {
      if (actionMeta.action === "create-option") {
        const newOption = {
          value: actionMeta.option?.value,
          label: actionMeta.option?.label,
          __isNew__: true,
        };

        setIsCreatedMainServices([...isCreatedMainServices, newOption]);
      }

      const arr = servicesAndIndustryFunc(opt, selectedMainServices);
      setSelectedMainService(arr);
    } else {
      if (actionMeta.action === "create-option") {
        const newOption = {
          value: actionMeta.option?.value,
          label: actionMeta.option?.label,
          __isNew__: true,
        };
        setIsCreateSecondaryService([...isCreateSecondaryService, newOption]);
      }
      const arr = servicesAndIndustryFunc(opt, selectedSecondaryServices);
      setSelectedSecondaryService(arr);
    }
  };

  // fetch more options function-----//
  const fetchMoreOptions = () => {
    setPage(page + 1);
  };
  useEffect(() => {
    if (page != 1) {
      const data = {
        page: page,
      };
      dispatch(fetchServiceListApi(data));
    }
  }, [page]);
  // fecth more function end-------//

  // handleInput change services//
  const handleInputChange = (character, type) => {
    const data = new FormData();
    data.append("search", character || null);
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
      data: data,
    };

    axios(API_BASE_URL + "/searchServicesAndIndustry", config).then((res) => {
      if (!!res.data.list?.length) {
        if (type == "service") {
          const newMainOption = filterSelectedValueOfArr(
            res.data.list,
            selectedMainServices
          );
          setFilterMainServiceOption(newMainOption);
        } else if (type == "secondaryService") {
          const newSecondaryOption = filterSelectedValueOfArr(
            res.data.list,
            selectedSecondaryServices
          );
          setFilterSecondaryServiceOption(newSecondaryOption);
        }
      } else {
        setFilterMainServiceOption(cloneMainService);
        setFilterSecondaryServiceOption(cloneSecondaryService);
      }
    });
  };
  // handle input end//
  const handleSubmitData = () => {
    const validation =
      profileData?.is_individual == 0
        ? !!selectedMainServices.length &&
          !!selectedSecondaryServices.length &&
          !!selectedIndustry.length
        : profileData?.is_individual == 1
        ? !!selectedMainServices.length && !!selectedSecondaryServices.length
        : null;

    if (validation) {
      setIsClicked(true);
      const data = new FormData();
      if (profileData?.is_individual == 0) {
        selectedIndustry.forEach((item, i) =>
          data.append(`industry[${i}][value]`, item.value)
        );
        selectedIndustry.forEach((item, i) =>
          data.append(`industry[${i}][id]`, !!item.id ? item.id : "")
        );
      }
      selectedMainServices.forEach((item, i) =>
        data.append(`main_service[${i}][value]`, item.value)
      );
      selectedMainServices.forEach((item, i) =>
        data.append(`main_service[${i}][id]`, !!item.id ? item.id : "")
      );
      selectedSecondaryServices.forEach((item, i) =>
        data.append(`secondary_service[${i}][value]`, item.value)
      );
      selectedSecondaryServices.forEach((item, i) =>
        data.append(`secondary_service[${i}][id]`, !!item.id ? item.id : "")
      );
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };

      axios(API_BASE_URL + "/user-services-list/update", config)
        .then((response) => {
          dispatch(getAllSellerDataApi()).then((res) => {
            const {
              response: {
                data: {
                  data: { industry, main_service, secondary_service },
                },
              },
            } = res;
            getServiceDataFromEditService(
              main_service,
              secondary_service,
              industry
            );
            setIsEditService(false);
            const dropDown = document.getElementById("edit-service");
            dropDown.style.visibility = "hidden";
            dropDown.style.opacity = 0;
            addToast(response.data.message, {
              appearance: "success",
              autoDismiss: true,
              // autoDismissTimeout: 2000,
            });
          });
        })
        .catch((err) => {
          addToast(err, {
            appearance: "error",
            autoDismiss: true,
            // autoDismissTimeout: 2000,
          });
        });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    setSelectedMainService(primaryServices);
    setSelectedSecondaryService(secondaryService);
    setSelectedIndustry(industry);
  }, [primaryServices, secondaryService, industry]);
  const handleEditClose = () => {
    const dropDown = document.getElementById("edit-service");
    dropDown.style.visibility = "hidden";
    dropDown.style.opacity = 0;
    setIsEditService(false);
    setIsShow(null);
  };

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: isCreatedMainServices.some(
        (item) => item.value === state.data.value && item.status===0
      )
        ? "#ffbcc2"
        : "#e6e6e6",
    }),
  };

  const customStyleForSecondary = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: isCreateSecondaryService.some(
        (item) => item.value === state.data.value && item.status===0
      )
        ? "#ffbcc2"
        : "#e6e6e6",
    }),
  };

const fetchedServiceStyle = {
  multiValue:(provided,state)=>({
    ...provided,
    backgroundColor:selectedSecondaryServices?.length > 0 && selectedSecondaryServices?.some((item)=> item.label===state.data.label && item.status===0)?"#ffbcc2":"e63636",

  })
}


const fetchedMainServiceStyle = {
  multiValue:(provided,state)=>({
    ...provided,
    backgroundColor:selectedMainServices?.length > 0 && selectedMainServices?.some((item)=> item.label===state.data.label && item.status===0)?"#ffbcc2":"e63636",

  })
} 



  return (
    <div id="updateservice" className="profile__box custom-shadow">
      {getAllSellerDatas.acc_type == "individual" && (
        <div className="d-flex justify-content-end">
          <span className="btn btn-primary btn-sm " onClick={handleEditClose}>
            Close
          </span>
        </div>
      )}
      {getAllSellerDatas?.acc_type == "business" && !!getAllSellerDatas && (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <span className="mx-2">Edit</span>
              your company's industry, the main service and other secondary
              services you provide
            </div>
            <span className="btn btn-primary btn-sm" onClick={handleEditClose}>
              Close
            </span>
          </div>

          <div className="mb-3 mt-3">
            <label>Industries</label>
            <Select
              options={filterIndustryOption}
              placeholder="Select Industries"
              className="basic-multi-select"
              classNamePrefix="select"
              isClearable
              isMulti
              onChange={(opt, actionMeta) =>
                handleServicesAndIndustry(opt, "industry", actionMeta)
              }
              value={
                selectedIndustry
                  ? selectedIndustry?.map((items) => {
                      return {
                        label: items.label,
                        value: items.value,
                      };
                    })
                  : ""
              }
            />
            {selectedIndustry?.length === 0 && error && (
              <p className="error-message" style={{ color: "red" }}>
                Please select the Industry Services
              </p>
            )}
          </div>
        </>
      )}

      <div className="mb-3">
        <label>Main Service</label>
        <Select
          options={fileterMainServices}
          onInputChange={(text) => handleInputChange(text, "service")}
          placeholder="Select Services"
          className="basic-multi-select"
          classNamePrefix="select"
          isClearable
          isMulti
          onChange={(opt, actionMeta) =>
            handleServicesAndIndustry(opt, "main", actionMeta)
          }
          styles={fetchedMainServiceStyle}
          onMenuScrollToBottom={fetchMoreOptions}
          value={
            selectedMainServices
              ? selectedMainServices?.map((items) => {
                  return {
                    label: items.label,
                    value: items.value,
                  };
                })
              : ""
          }
        />
        {selectedMainServices?.length === 0 && error && (
          <p className="error-message" style={{ color: "red" }}>
            Please select the Main Services
          </p>
        )}
      </div>

      <div className="mb-3">
        <label>Secondary Service</label>
        <Select
          options={filterSecondaryServiceOption}
          onInputChange={(text) => handleInputChange(text, "secondaryService")}
          placeholder="Select Services"
          className="basic-multi-select"
          classNamePrefix="select"
          isClearable
          isMulti
          styles={fetchedServiceStyle}
          onChange={(opt, actionMeta) =>
            handleServicesAndIndustry(opt, "secondary", actionMeta)
          }
          onMenuScrollToBottom={fetchMoreOptions}
          value={
            selectedSecondaryServices
              ? selectedSecondaryServices?.map((items) => {
                  return {
                    label: items.label,
                    value: items.value,

                  };
                })
              : ""
          }
        />
        {selectedSecondaryServices?.length === 0 && error && (
          <p className="error-message" style={{ color: "red" }}>
            Please select the Secondary Services
          </p>
        )}
      </div>
      <div className="form-group  mt-3 form-btn text-right">
        <input
          type="submit"
          name="submit"
          value="Update"
          onClick={() => {
            handleSubmitData();
          }}
          disabled={isClicked}
          className="btn btn-blue"
        />
      </div>
    </div>
  );
}
