import React, { useEffect, useState } from "react";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import axios, { Axios } from "axios";
import { SELLER_SIGNUP_API, SELLER_UPDATE_STATUS } from "../../library/urls";
import { getCookie, setCookie } from "../../library/utilities/functions";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import { TimeArr } from "./TimeRange";
import Select from "react-select";
const PriceAndTime = ({ isEdit }) => {
  const [getAllIndividualData, setGetAllIndividualData] = useState([]);
  const [error, setError] = useState(false);
  const { addToast } = useToasts();
  const [rateValue, setRateValue] = useState("");
  const [days, setDays] = useState([]);
  const history = useHistory();
  const AccountStateData = useSelector((state) => state.AccountReducer);
  const [selectedTimezone, setSelectedTimezone] = useState({})
  const [timeOptions, setTimeOptions] = useState([])
  const profileData = !!getCookie("profile_data")
  ? JSON.parse(getCookie("profile_data"))
  : "";
  const [daysItem, setDaysItem] = useState(
    [
      {
        id: 1,
        day: "Monday",
        startTimeId: 1,
        EndTimeId: 2,
        time_from: "",
        time_to: "",
        startmomentTime: '',
        endMomomentTime: '',
        checked: false
      },
      {
        id: 2,
        day: "Tuesday",
        startTimeId: 1,
        EndTimeId: 2,
        time_from: "",
        time_to: "",
        startmomentTime: '',
        endMomomentTime: '',
        checked: false
      },
      {
        id: 3,
        day: "Wednesday",
        startTimeId: 1,
        EndTimeId: 2,
        time_from: "",
        time_to: "",
        startmomentTime: '',
        endMomomentTime: '',
        checked: false
      },
      {
        id: 4,
        day: "Thursday",
        startTimeId: 1,
        EndTimeId: 2,
        time_from: "",
        time_to: "",
        startmomentTime: '',
        endMomomentTime: '',
        checked: false
      },
      {
        id: 5,
        day: "Friday",
        startTimeId: 1,
        EndTimeId: 2,
        time_from: "",
        time_to: "",
        startmomentTime: '',
        endMomomentTime: '',
        checked: false
      },
      {
        id: 6,
        day: "Saturday",
        startTimeId: 1,
        EndTimeId: 2,
        time_from: "",
        time_to: "",
        startmomentTime: '',
        endMomomentTime: '',
        checked: false
      },
      {
        id: 7,
        day: "Sunday",
        startTimeId: 1,
        EndTimeId: 2,
        time_from: "",
        time_to: "",
        startmomentTime: '',
        endMomomentTime: '',
        checked: false
      }
    ])
  const handleTimePicker = (e, name, inputsTimeId, time_from) => {
    /* trigger onChange of week_of_day */
    const newArray = daysItem.map((item, i) => {
      if (item.day === name) {
        if (inputsTimeId === item.startTimeId) {
          return { ...item, time_from: e.label };
        } else {
          return { ...item, time_to: e.label };
        }
      } else {
        return item;
      }
    });
    setDaysItem(newArray);
    /* add startTime and End time in week_of day array with onchange */
    days.map((item, i) => {
      if (item.day_of_week === name) {
        if (inputsTimeId === 1) {
          days[i].time_from = e.label;
          days[i].time_to = "12:00 AM";
        } else if (inputsTimeId === 2) {
          days[i].time_to = e.label;
        }
      }
    });
  };

  const handleRateChange = (e) => {
    let hourly = e.target.value;
    if (hourly.length > 5) {
      let newValue = hourly.slice(0, 5);
      setRateValue(newValue);
    } else {
      setRateValue(hourly);
    }
  };
  const handleFinish = () => {
    if (rateValue != 0 && days.length > 0 && !!Object.keys(selectedTimezone).length) {
      const data = new FormData();
      data.append("form_step", 3);
      data.append("seller_status", 1);
      data.append("type_individual", 1);
      data.append("value", selectedTimezone.value)
      data.append("label", selectedTimezone.label)
      data.append("altName", selectedTimezone.altName)
      data.append("abbrev", selectedTimezone.abbrev)
      data.append("offset", selectedTimezone.offset)
      if (!!getAllIndividualData?.hourly_rate) {
        data.append("while_edit", true);
      }
      days?.forEach((items, i) => {
        if (items.time_from == "OPEN 24 HOURS") {
          data.append(`business_hours[${i}][is_twenty_four]`, 1)
          data.append(`business_hours[${i}][twenty_four]`, items.time_from)
          data.append(
            `business_hours[${i}][time_from]`,
            ""
          )
          data.append(`business_hours[${i}][day]`, items.day_of_week),
            data.append(
              `business_hours[${i}][time_to]`,
              ""
            )
        } else if (items.time_from && items.time_to) {
          data.append(`business_hours[${i}][is_twenty_four]`, 0)
          data.append(
            `business_hours[${i}][time_from]`, items.time_from
          )
          data.append(`business_hours[${i}][day]`, items.day_of_week),
            data.append(
              `business_hours[${i}][time_to]`,
              items.time_to
            )
        }

      })
      data.append("hourly_rate", rateValue);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      Swal.fire({
        title:
          "<h5 style='color:#212529'>" +
          "Do you want to Finish the Application ?" +
          "</h5>",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        confirmButtonColor: "#00a8f3",
        denyButtonColor: "#88001b",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios(SELLER_SIGNUP_API, config).then((response) => {
            const data = response;
            setError(false);
            setCookie("become_seller", true);

            if (data.data.status === 200) {

              history.push(`/account/${profileData?.user_slug}`);
              const showSuccessMsg = data.data.message.split(".")[0];
              const splitmsg = data.data.message.split(".")[1];
              if (splitmsg?.length > 0) {
                addToast(
                  <p>
                    <span
                      style={{ color: "#000", fontWeight: 700 }}
                    >{`${showSuccessMsg}.`}</span>
                    <br />
                    <br />
                    <span>
                      {data.data.message.slice(50, data.data.message?.length)}
                    </span>
                  </p>,
                  {
                    appearance: "success",
                    autoDismiss: true,
                
                  }
                );
              } else {
                addToast(data.data.message, {
                  appearance: "success",
                  autoDismiss: true,
              
                });
              }

              axios(SELLER_UPDATE_STATUS, config).then(() => { });
            } else if (data.data.status === 400) {
              Swal.fire({
                title:
                  "<h5 style='color:#212529'>" +
                  "Please fill the valid time" +
                  "</h5>",

                showCancelButton: false,
                confirmButtonText: "Go back",
                confirmButtonColor: "#00a8f3",
              });
            }
          });
        }
      });
    } else {
      setError(true);
    }
  };
  const handleSave = () => {
    if (rateValue != 0 && days.length > 0 && !!Object.keys(selectedTimezone).length) {
      const data = new FormData();
      data.append("form_step", 3);
      data.append("seller_status", 1);
      data.append("type_individual", 1);
      if (!!getAllIndividualData?.hourly_rate) {
        data.append("while_edit", true);
      }
      data.append("value", selectedTimezone.value)
      data.append("label", selectedTimezone.label)
      data.append("altName", selectedTimezone.altName)
      data.append("abbrev", selectedTimezone.abbrev)
      data.append("offset", selectedTimezone.offset)
      days?.forEach((items, i) => {
        if (items.time_from == "OPEN 24 HOURS") {
          data.append(`business_hours[${i}][is_twenty_four]`, 1)
          data.append(`business_hours[${i}][twenty_four]`, items.time_from)
          data.append(
            `business_hours[${i}][time_from]`,
            ""
          )
          data.append(`business_hours[${i}][day]`, items.day_of_week),
            data.append(
              `business_hours[${i}][time_to]`,
              ""
            )
        } else {
          data.append(`business_hours[${i}][is_twenty_four]`, 0)
          data.append(
            `business_hours[${i}][time_from]`, items.time_from
          )
          data.append(`business_hours[${i}][day]`, items.day_of_week),
            data.append(
              `business_hours[${i}][time_to]`,
              items.time_to
            )
        }
      })
      data.append("hourly_rate", rateValue);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getCookie("token_id"),
        },
        data: data,
      };
      Swal.fire({
        title: "<h5 style='color:#212529'>" + "Do you want to save ?" + "</h5>",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        confirmButtonColor: "#00a8f3",
        denyButtonColor: "#88001b",
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios(SELLER_SIGNUP_API, config).then((response) => {
            const data = response;
            setError(false);
            setCookie("become_seller", true);
            if (data.data.status === 200) {
              axios(SELLER_UPDATE_STATUS, config).then((response) => { });
            } else if (data.data.status === 400) {
              Swal.fire({
                title:
                  "<h5 style='color:#212529'>" +
                  "Please fill the valid" +
                  "</h5>",
                // showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Go Back",
                confirmButtonColor: "#00a8f3",
              });
            }
          });
        }
      });
    } else {
      setError(true);
    }
  };
  const handleBack = () => {
    $("#pills-tab > li:eq(0) a").trigger("click");
  };
  /* creating newArray with onChange and if toggle button true add new Value in array if toggle false remove value from array which add when toggle button true*/
  const handleDays = (e, data) => {

    if (e.target.checked) {
      setDaysItem((prev) => {
        return prev.map((item) => {
          if (item.id === data.id) {
            return { ...item, day_of_week: e.target.value, time_from: "OPEN 24 HOURS", time_to: "", checked: false }
          } else {
            return { ...item }
          }
        })
      })
      const indexdata = days?.findIndex(
        (data) => data.day_of_week === e.target.value
      );
      let newDaysArray = days;
      newDaysArray.splice(indexdata, 1);
      setDays(newDaysArray);
    } else if (e.target.checked == false) {
      setDaysItem((prev) => {
        return prev.map((item) => {
          if (item.id === data.id) {
            return { ...item, time_from: "", time_to: "", checked: true }
          } else {
            return { ...item }
          }
        })
      })
      setDays([
        ...days,
        { day_of_week: e.target.value, time_from: "OPEN 24 HOURS", time_to: "" },
      ]);
    } else {
      days.slice(-1);
    }
  };


  /* add values in array of object who coming from api */
  useEffect(() => {
    setRateValue(getAllIndividualData?.hourly_rate);
    getAllIndividualData?.availability &&
      getAllIndividualData?.availability?.map((item, index) => {
        daysItem.map((items, i) => {
          if (
            item.day_of_week.charAt(0).toUpperCase().slice(0) +
            item.day_of_week.slice(1) ===
            items.day
          ) {
            daysItem[i].time_from = item.time_from
            daysItem[i].time_to = item.time_to;
            daysItem[i].startmomentTime = moment(`${items.time_from}`, "HH:mm")
            daysItem[i].endMomomentTime = moment(`${items.time_to}`, "HH:mm")
            daysItem[i].checked = true
          }
        });
      });
    if (!!Object.keys(getAllIndividualData).length) {
      setDays([...getAllIndividualData?.availability]);
      if (getAllIndividualData.label) {
        setSelectedTimezone({
          label: getAllIndividualData.label,
          altName: getAllIndividualData.alt_name,
          abbrev: getAllIndividualData.abbrev,
          value: getAllIndividualData.value,
          offset: getAllIndividualData.offset
        })
      }

    }

  }, [getAllIndividualData?.availability, isEdit])

  /* get all individual data from api */
  useEffect(async () => {
    setGetAllIndividualData(
      AccountStateData?.getAllSellerDataApi?.getAllSellerDatas
    );
  }, [AccountStateData]);
  useEffect(() => {
    const options = TimeArr.filter((x) => x.label != "OPEN 24 HOURS")
    setTimeOptions(options)
  }, [TimeArr])

  return (
    <div
      className="tab-pane fade"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div className="form-group">
        <h5>
          Tell people how much your hourly rate is and When you are open for
          business
        </h5>
        <p>You can always change the information later.</p>
        <h5 className="mb-3 text-capitalize">Add your business hours</h5>
        <div className="time-zone mb-3">
          <label className="time-zone__label mr-2 w-auto">Select Time Zone</label>
          <TimezoneSelect
            value={selectedTimezone}
            onChange={setSelectedTimezone}
            className={!isEdit && "disabledTimeZoneSelect"}
            placeholder="Select Time Zone.."
          />
        </div>
        {error && !Object.keys(selectedTimezone).length && (
          <p className="error-message" style={{ color: "red", paddingBottom: 5 }}>
            Please select time zone
          </p>
        )}
        {daysItem?.map((items, i) => {
          return (
            <>
              <div className="timing-block mb-3" key={i}>
                <div className="flex flex-column time-zone__label mr-2">
                  <span className="mb-1">{items.day}</span>
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="switch h-auto"
                      id={items.day}
                      value={items.day}
                      checked={!items.checked}
                      disabled={isEdit ? false : true}
                      onClick={(e) => handleDays(e, items)}
                    />
                    <label className="ml-1 mb-0 small">Closed</label>
                  </div>
                </div>

                <div className="timer-input">
                  <Select
                    options={TimeArr}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Please Select your time from"
                    isDisabled={isEdit ? !items.checked : true}
                    isSearchable={false}
                    value={!!items.time_from ? { label: items.time_from } : { label: "OPEN 24 HOURS" }}
                    onChange={(opt) => handleTimePicker(opt, items.day, items.startTimeId, items.time_from)}

                  />
                  {items.time_from && items.time_from != "OPEN 24 HOURS" &&
                    <Select
                      options={timeOptions}
                      placeholder=""
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isDisabled={isEdit ? false : true}
                      isSearchable={false}
                      value={!!items.time_to ? { label: items.time_to } : { label: '12:00 AM ' }}
                      onChange={(opt) => handleTimePicker(opt, items.day, items.EndTimeId, items.time_from)}
                    />}
                </div>
              </div>
            </>
          );
        })}
        {error && days.length <= 0 ? (
          <p className="error-message" style={{ color: "red" }}>
            Please Select at Least one Day
          </p>
        ) : null}
      </div>
      <div className="form-group">
        <h5>Set your hourly rate.</h5>
        <p>
          Clients will see this rate on your profile and in search results once
          you publish your profile.{" "}
        </p>
        <div className="hourly-rate position-relative w-50">
          <i className="ri-money-dollar-circle-line"></i>
          <input
            type="number"
            min="1"
            name="rate"
            className="form-control"
            placeholder="Hourly rate"
            max="5"
            value={isEdit ? rateValue : rateValue}
            disabled={isEdit ? false : true}
            onChange={(e) => handleRateChange(e)}
          />
        </div>
        {error && rateValue == 0 && (
          <p className="error-message" style={{ color: "red" }}>
            Please add the Hourly Rate
          </p>
        )}
      </div>
      <div className="form-group d-flex justify-content-end flex-wrap btn-wrapper mt-5">
        <a
          href="javascript:;"
          className="btn btn-secondary mr-3"
          onClick={handleBack}
        >
          Start Over
        </a>
        {isEdit ? (
          <>
            <a
              href="javascript:;"
              className="btn btn-blue mr-3"
              onClick={handleSave}
            >
              Save
            </a>
            <a
              href="javascript:;"
              className="btn btn-primary"
              onClick={handleFinish}
            >
              Finish Application
            </a>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PriceAndTime;
