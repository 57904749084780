import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import $ from "jquery";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ServicesTab from "../../library/common/components/ServicesTab";
import {
  cancelRequestDataApi,
  searchProfileDataApi,
  sendRequestDataApi,
  unfriendUserApi,
} from "../../library/api/SearchApiService";
import { ADMIN, IMAGE_BASE_URL, MEMBERS, SOCKET } from "../../library/urls";
import {
  EmploymentDetail,
  GeneralDetail,
  ProjectDetail,
  SocialLink,
} from "./common";
import {
  checkIsPostClicked,
  clearCancelRequestResponse,
  clearProfileResponse,
  clearUnfriendResponse,
} from "./SearchProfileAction";
import { clearSendRequestResponse } from "../SearchList/SearchListAction";
import {
  getCookie,
  scroolTop,
  setCookie,
} from "../../library/utilities/functions";
import {
  ProfileGallery,
  ProfileMembers,
  ProfilePosts,
} from "../../library/common/components";
import {
  changeGalleryDetail,
  clearGalleryPhotoResponse,
  clearGalleryVideoResponse,
} from "../../library/common/components/ProfileGallery/ProfileGalleryAction";
import {
  getGalleryPhotoApi,
  getGalleryVideoApi,
} from "../../library/api/ProfilePostApiService";
import { changeChatDetails } from "../PoolsChat/PoolsChatAction";
import axios from "axios";
import { ListData } from "../Account/common/SellerStatusListData";
import { HashLoader } from "react-spinners";
import { css } from "@emotion/react";
import RequestModal from "../PoolsChat/RequestModal";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const override = css`
  position: absolute;
  top: 50%;
  right: 47%;
  z-index: 9999;
`;
const SearchProfile = () => {
  const profileData = !!getCookie("profile_data")
    ? JSON.parse(getCookie("profile_data"))
    : "";
  const { userId } = useParams();

  const loc = useLocation();
  const is_individual = loc.search.split("=")[1];
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const history = useHistory();
  const [addedMembers, setAddedMembers] = useState([]);
  const [editAppointent, setEditAppointment] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [requestData, setRequestData] = useState({
    date: new Date(),
    request_nature: "",
    description: "",
    reschedule: false,
    iscancel: false,
    title: "",
  });

  const requestModalRef = useRef();
  const [border, setBorder] = useState("");
  const [icon, setIcon] = useState("");
  const [haveNotification, setHaveNotification] = useState(false);
  const [color, setColor] = useState("");
  const [tabStatus, setTabStatus] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const searchState = useSelector((state) => state.SearchProfileReducer);
  const searchListState = useSelector((state) => state.SearchListReducer);
  const GalleryState = useSelector((state) => state.ProfileGalleryReducer);

  const {
    galleryDetails: { photoPage, videoPage, unSelectedState, photoList },
  } = GalleryState;
  const {
    sendRequestApi: { sendRequestStatus, sendRequestMessage },
  } = searchListState;
  const {
    searchProfileApi: { searchProfileResponse, searchProfileLoading },
  } = searchState;
  const { profile_details } = searchProfileResponse;
  const {
    cancleRequestApi: { cancleRequestStatus },
  } = searchState;
  const {
    unfriendApi: { unfriendStatus },
    isPostClicked,
  } = searchState;

  if (profileData?.id == userId) {
    history.push(`/account/${profileData.user_slug}`);
  } else {
    // history.push(`/search-profile/${userId}`)
  }

  var device_width = screen.width;
  useEffect(() => {
    const modalOpen = document.querySelectorAll(".modal-open");
    if (!!modalOpen && modalOpen.length > 0) {
      document.body.classList.remove("modal-open");
      modalOpen[0].style.paddingRight = "0px";
    }
  }, []);
  useEffect(() => {
    if (!!isPostClicked) {
      document.getElementById("pills-profile-tab")?.click();
    }
  }, [isPostClicked]);

  useEffect(() => {
    scroolTop();
    return () => {
      dispatch(clearProfileResponse());
      dispatch(clearSendRequestResponse());
      dispatch(checkIsPostClicked({ isPostClicked: false }));
    };
  }, []);

  // get additional details api call//
  useEffect(() => {
    const bodyParameter = {
      id: userId,
      type: "search",
    };
    dispatch(searchProfileDataApi(bodyParameter));
  }, [history.location.pathname]);

  // additional details api call //
  useEffect(() => {
    if (!!unfriendStatus) {
      const bodyParameter = {
        id: userId,
      };
      dispatch(searchProfileDataApi(bodyParameter));
      dispatch(clearUnfriendResponse());
    }
  }, [unfriendStatus]);

  // additional details api call//
  useEffect(() => {
    if (!!sendRequestStatus) {
      addToast(sendRequestMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      const bodyParameter = {
        id: userId,
      };
      dispatch(searchProfileDataApi(bodyParameter));
      dispatch(clearSendRequestResponse());
    }
  }, [sendRequestStatus]);

  // api call additional-details//
  useEffect(() => {
    if (!!cancleRequestStatus) {
      const bodyParameter = {
        id: userId,
      };
      dispatch(searchProfileDataApi(bodyParameter));
      dispatch(clearCancelRequestResponse());
    }
  }, [cancleRequestStatus]);
  //this function is used to cancel friend request.
  const handleCancelRequest = () => {
    const bodyParameter = {
      id: userId,
    };
    dispatch(cancelRequestDataApi(bodyParameter));
  };
  //this function is used to send friend request.
  const handleSendRequest = () => {
    setIsClicked(true);
    const bodyParameter = {
      id: userId,
    };
    dispatch(sendRequestDataApi(bodyParameter));
  };
  //this function is used to unfriend the friend.
  const handleUnfriend = () => {
    const bodyParameter = {
      id: userId,
    };
    dispatch(unfriendUserApi(bodyParameter));
  };
  useEffect(() => {
    alert;
    const bodyParameter = {
      page: photoPage,
      user_id: userId,
      type: 0, //for image
    };
    dispatch(getGalleryPhotoApi(bodyParameter));
  }, []);
  useEffect(() => {
    if (!!videoPage) {
      const body = {
        page: videoPage,
        user_id: userId,
        type: 1, //for video
      };
      dispatch(getGalleryVideoApi(body));
    }
  }, [videoPage]);
  useEffect(() => {
    let config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getCookie("token_id"),
      },
    };
    axios(MEMBERS + "?user_id=" + userId, config).then((response) => {
      const MembersData = response.data.list;
      setAddedMembers(MembersData);
    });
  }, []);
  //this function is used to clear gallery response when clicking on the about tab and post tab
  const handleOpenUserGallery = () => {
    setTabStatus(2);
    const galleryPages = {
      photoPage: 1,
      videoPage: 1,
      unSelectedState: 0,
    };
    dispatch(changeGalleryDetail(galleryPages));
  };
  // useEffect(() => {
  //   if (tabStatus != 2) {
  //     dispatch(clearGalleryPhotoResponse());
  //     dispatch(clearGalleryVideoResponse());
  //   }
  // }, [tabStatus]);

  const handleChats = (data) => {
    setHaveNotification(true);
    if (profile_details?.friends) {
      setCookie("friendId", userId, 1);
      setCookie("chatType", "user");
      dispatch(changeChatDetails({ currenUserId: +userId }));
      history.push("/chat");
    } else if (profile_details.is_supporter) {
      history.push("/contact-us");
      setCookie("friendId", +userId, 1);
      setCookie("chatType", "user");

      SOCKET.emit("contact_support", {
        user_id: profileData.id,
        support_id: Number(ADMIN),
      });

      dispatch(
        changeChatDetails({
          currenUserId: ADMIN,
        })
      );
    } else {
      addToast("Sorry, not friends with you.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setTimeout(() => setHaveNotification(false), 2000);
  };

  //this function is used to remove active className on nav link tabs.
  const removeActiveTabs = () => {
    const activeTabs = document.querySelectorAll(".nav-link");
    for (let i in activeTabs) {
      if (!!activeTabs[i] && !!activeTabs[i].classList) {
        activeTabs[i].classList.remove("active");
      }
    }
  };
  //this function is used to open tabs in profile.
  const pilssHideShow = (e, pill) => {
    removeActiveTabs();
    const target = e.target;
    const homeElm = document.getElementById("pills-home");
    const profileElm = document.getElementById("pills-profile");
    const contactsElm = document.getElementById("pills-contacts");
    const membersElm = document.getElementById("pills-members");
    const servicesElm = document.getElementById("pills-services");
    if (pill == "home") {
      target.classList.add("active");
      homeElm.classList.add("show");
      homeElm.classList.add("active");

      profileElm.classList.remove("active");
      profileElm.classList.remove("show");

      contactsElm.classList.remove("active");
      contactsElm.classList.remove("show");

      membersElm.classList.remove("active");
      membersElm.classList.remove("show");

      servicesElm.classList.remove("active");
      servicesElm.classList.remove("show");
    } else if (pill == "profile") {
      target.classList.add("active");
      homeElm.classList.remove("show");
      homeElm.classList.remove("active");

      profileElm.classList.add("active");
      profileElm.classList.add("show");

      contactsElm.classList.remove("active");
      contactsElm.classList.remove("show");

      membersElm.classList.remove("active");
      membersElm.classList.remove("show");

      servicesElm.classList.remove("active");
      servicesElm.classList.remove("show");
    } else if (pill == "members") {
      target.classList.add("active");
      homeElm.classList.remove("show");
      homeElm.classList.remove("active");

      membersElm.classList.add("active");
      membersElm.classList.add("show");

      profileElm.classList.remove("active");
      profileElm.classList.remove("show");

      contactsElm.classList.remove("active");
      contactsElm.classList.remove("show");
      servicesElm.classList.remove("active");
      servicesElm.classList.remove("show");
    } else if (pill == "services") {
      target.classList.add("active");

      homeElm.classList.remove("show");
      homeElm.classList.remove("active");

      membersElm.classList.remove("active");
      membersElm.classList.remove("show");

      profileElm.classList.remove("active");
      profileElm.classList.remove("show");

      contactsElm.classList.remove("active");
      contactsElm.classList.remove("show");

      servicesElm.classList.add("active");
      servicesElm.classList.add("show");
    } else {
      target.classList.add("active");
      homeElm.classList.remove("show");
      homeElm.classList.remove("active");

      profileElm.classList.remove("active");
      profileElm.classList.remove("show");

      contactsElm.classList.add("active");
      contactsElm.classList.add("show");

      membersElm.classList.remove("active");
      membersElm.classList.remove("show");

      servicesElm.classList.remove("active");
      servicesElm.classList.remove("show");
    }
  };

  // open request modal //

  const handleOpenRequestModal = (e, value, details) => {
    if (value === "edit") {
      setEditAppointment(true);
      setRequestData({
        ...requestData,
        iscancel: false,
        request_nature: "",
        description: "",
      });
    } else if (value === "no-edit") {
      setEditAppointment(false);
      setUserDetails(details);
      setRequestData({
        date: new Date(),
        request_nature: "",
        description: "",
        reschedule: false,
        iscancel: false,
        title: "",
      });
    }

    // requestModalRef.current.click();
  };
  useEffect(() => {
    ListData.map((item, i) => {
      if (
        item.name == profile_details?.seller_status &&
        profile_details?.seller_status != "Custom"
      ) {
        setIcon(item.imageOne);
        setColor(item.color);
        setBorder(item.color);
      } else if (profile_details?.seller_status == "Custom") {
        setIcon(profile_details.status_image);
        setColor(item.color);
        setBorder(item.color);
      }
    });
  });

  function statusShow() {
    var statuss = document.querySelector(".status-label");
    var statusCurrent = document.querySelector(".search_profile_id");
    statuss?.classList?.add("clip-status");
    statusCurrent?.classList?.add("hide-braces");
    $(".status-icons").addClass("braces-visibility");
  }

  const addCircleStatus = () => {
    const getStaus = document.querySelector(".search_profile_id");
    if (getStaus) {
      var fourChildNode = getStaus.querySelector("img");
      if (fourChildNode.parentNode.matches(":hover")) {
        fourChildNode.style.border = `0px solid #fff`;
      } else if (!!fourChildNode && !!profile_details?.seller_status) {
        fourChildNode.style.border = `2px solid ${border}`;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      addCircleStatus();
      statusShow();
    }, 3000);
  }, [border, profile_details?.seller_status]);

  const handleHover = (data) => {
    const getStaus = document.querySelector(".search_profile_id");
    let fourChildNode = getStaus.querySelector("img");
    if (fourChildNode.parentNode.matches(":hover")) {
      fourChildNode.style.border = `0px solid #fff`;
      statusShow();
    } else if (data == "hover") {
      fourChildNode.style.border = `0px solid #fff`;
      fourChildNode.style.padding = `1px`;
    } else {
      fourChildNode.style.border = `2px solid ${border}`;
      fourChildNode.style.padding = `1px`;
    }
  };
  const handleMember = (id) => {
    consolr.log("handleMeber id");
    if (profileData.id == id) {
      history.push(`/account/${profileData?.user_slug}`);
    } else {
      history.push(
        // `/search-profile/${profile_details?.members[0]?.id}?individual=${false}`
        `/search-profile/${profile_details?.members[0]?.id}`
      );
    }
  };

  const handleMenuAction = () => {
    const list = document.querySelector(".important-list-drop");
    list.classList.toggle("profile-action-active");

    // if (list.classList.contains("visible")) {
    //   list.classList.remove("visible");
    // } else {
    //   list.classList.add("visible");
    // }
  };

  return searchProfileLoading ? (
    <HashLoader
      color={"#2f2529"}
      loading={searchProfileLoading}
      width={6}
      height={50}
      css={override}
    />
  ) : (
    <div>
      <section
        className="profile-header spacer bg-primary"
        style={{
          backgroundImage: `url(${
            !!profile_details?.cover_image
              ? IMAGE_BASE_URL + profile_details?.cover_image
              : "/assets/images/pools_cover_image.png"
          })`,
        }}
      >
        <div className="container profile-area">
          <div className="row">
            <div className="col-md-12">
              {/* BEGIN profile-header-content */}
              <div className="profile-header-content">
                {/* BEGIN profile-header-img */}
                <div className="profile-header-content__inner  d-flex align-items-center">
                  {profile_details?.is_individual == true ? (
                    <div className="profile-header-img">
                      <img
                        onError={(ev) =>
                          (ev.target.src = "/assets/images/member-default.svg")
                        }
                        src={
                          !!profile_details && !!profile_details.profile_image
                            ? IMAGE_BASE_URL + profile_details.profile_image
                            : "/assets/images/member-default.svg"
                        }
                        alt="logo"
                      />
                    </div>
                  ) : (
                    <div className="business-logo">
                      <img
                        onError={(ev) =>
                          (ev.target.src = "/assets/images/business_logo.svg")
                        }
                        src={
                          !!profile_details && !!profile_details.business_image
                            ? IMAGE_BASE_URL + profile_details.business_image
                            : "/assets/images/business_logo.svg"
                        }
                        alt="logo"
                      />
                    </div>
                  )}
                  {profile_details?.profile_visibility == 0 &&
                  profile_details?.is_individual == 0 &&
                  !!profile_details ? (
                    <div className="profile-header-img">
                      <img
                        onError={(ev) =>
                          (ev.target.src = "/assets/images/member-default.svg")
                        }
                        src={
                          !!profile_details && !!profile_details.profile_image
                            ? IMAGE_BASE_URL + profile_details.profile_image
                            : "/assets/images/member-default.svg"
                        }
                        alt="logo"
                      />
                    </div>
                  ) : null}
                  {/* END profile-header-img */}

                  {/* BEGIN profile-header-info */}
                  {profile_details?.is_individual == true ? (
                    <div className="profile-header-info ">
                      <div
                        className="name-status d-inline position-relative d-flex align-items-center individua-status"
                        style={{ cursor: "no-drop", width: "max-content" }}
                      >
                        <h3>
                          {!!profile_details &&
                            profile_details.name?.charAt(0).toUpperCase() +
                              profile_details.name?.slice(1).substr(0, 22)}{" "}
                        </h3>
                        {profile_details?.seller_status?.length > 0 && (
                          <span
                            className="status-current font-weight-bold h6 d-inline-block search_profile_id"
                            style={{ color: color }}
                            onMouseEnter={() => handleHover("hover")}
                            onMouseLeave={() => handleHover("down")}
                          >
                            {" "}
                            &nbsp;{" "}
                            <span className="braces-left status-icons">(</span>
                            <img
                              src={
                                profile_details?.seller_status != "Custom"
                                  ? icon
                                  : !!profile_details?.status_image
                                  ? IMAGE_BASE_URL + icon
                                  : "/assets/images/custom-icon.svg"
                              }
                            />{" "}
                            <span className="status-label">
                              {profile_details?.seller_status == "Custom"
                                ? profile_details?.custom_title
                                : profile_details?.seller_status}
                              <span className="braces-right status-icons">
                                )
                              </span>
                            </span>
                          </span>
                        )}
                        {profile_details?.seller_status != "Active Seller" &&
                          profile_details?.seller_status != undefined &&
                          profile_details?.seller_status.length > 0 && (
                            <p>
                              <span style={{ fontWeight: 600 }}>
                                {profile_details?.seller_status}
                              </span>
                              <span style={{ fontWeight: 600 }}>
                                {!!profile_details?.from_date
                                  ? ` (${profile_details?.from_date}) to (${profile_details?.to_date})`
                                  : `   to (${profile_details?.to_date})`}
                              </span>
                              <br />
                              {profile_details?.description}
                            </p>
                          )}
                      </div>
                      <div className="mb-0">
                        {!!profile_details && profile_details.work}
                      </div>
                      <div className="mb-0 mt-2">
                        {!!profile_details?.members?.length &&
                          !!profile_details?.members[0]?.business_name
                            ?.length && (
                            <div className="d-flex align-items-center w-100">
                              {!!profile_details?.members?.length && (
                                <div
                                  onClick={() =>
                                    handleMember(
                                      profile_details?.members[0]?.id
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    className="rounded"
                                    style={{ width: "25px", height: "25px" }}
                                    onError={(ev) =>
                                      (ev.target.src =
                                        "/assets/images/business_logo.svg")
                                    }
                                    src={
                                      !!profile_details.members[0]
                                        ?.business_image
                                        ? IMAGE_BASE_URL +
                                          profile_details.members[0]
                                            ?.business_image
                                        : "/assets/images/business_logo.svg"
                                    }
                                  />
                                  <span className="mb-0 ml-2">
                                    {!!profile_details.members &&
                                      profile_details.members[0]?.business_name}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`profile-header-info business-profile ${
                        profile_details?.profile_visibility == 1 &&
                        device_width > 768
                          ? "ml-5"
                          : null
                      }`}
                    >
                      <div className="d-flex align-items-center ">
                        <h3 className="mr-1 mb-0">
                          {!!profile_details &&
                            profile_details.business_name
                              ?.charAt(0)
                              .toUpperCase() +
                              profile_details.business_name
                                ?.slice(1)
                                .substr(0, 22)}{" "}
                        </h3>

                        <div
                          className="name-status d-inline position-relative mt-2"
                          style={{ cursor: "no-drop" }}
                        >
                          {profile_details?.seller_status?.length > 0 && (
                            <span
                              className="status-current font-weight-bold h6 d-inline-block search_profile_id"
                              style={{ color: color }}
                              onMouseEnter={() => handleHover("hover")}
                              onMouseLeave={() => handleHover("down")}
                            >
                              <span className="braces-left status-icons">
                                (
                              </span>
                              <img
                                src={
                                  profile_details?.seller_status != "Custom"
                                    ? icon
                                    : !!profile_details?.status_image
                                    ? IMAGE_BASE_URL + icon
                                    : "/assets/images/custom-icon.svg"
                                }
                              />{" "}
                              <span className="status-label">
                                {profile_details?.seller_status == "Custom"
                                  ? profile_details?.custom_title
                                  : profile_details?.seller_status}
                                <span className="braces-right status-icons">
                                  )
                                </span>
                              </span>
                            </span>
                          )}
                          {profile_details?.seller_status != "Active Seller" &&
                            profile_details?.seller_status != undefined &&
                            profile_details?.seller_status.length !== 0 && (
                              <p>
                                <span style={{ fontWeight: 600 }}>
                                  {profile_details?.seller_status}
                                </span>
                                <span style={{ fontWeight: 600 }}>
                                  {!!profile_details?.from_date
                                    ? ` (${profile_details?.from_date}) to (${profile_details?.to_date})`
                                    : `   to (${profile_details?.to_date})`}
                                </span>
                                <br />
                                {profile_details?.description}
                              </p>
                            )}
                        </div>
                      </div>

                      <div className="mb-0">
                        {profile_details?.profile_visibility == 0 &&
                          profile_details?.is_individual == 0 && (
                            <span className="font-weight-bold text-capitalize">
                              {!!profile_details ? profile_details.name : ""}
                            </span>
                          )}
                        <span className="font-italic">
                          {!!profile_details?.specialization
                            ? profile_details?.profile_visibility == 1
                              ? profile_details.specialization
                              : `, ${" "} ${profile_details.specialization}`
                            : null}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="profile-action ml-auto call-info">
                    {!profile_details?.is_supporter && (
                      <div>
                        {!!profile_details &&
                        !!profile_details.request_sent &&
                        profile_details.friends == false ? (
                          <a
                            className="btn bg-success btn-linked btn_fix"
                            href="javascript:;"
                            style={{ cursor: "default" }}
                          >
                            <i className="ri-user-follow-line align-middle" />{" "}
                            <span>Request Sent</span>
                          </a>
                        ) : !!profile_details &&
                          profile_details.friends == false ? (
                          <button
                            href="javascript:;"
                            onClick={handleSendRequest}
                            className="btn btn-blue  btn-linked btn_fix "
                            disabled={isClicked}
                          >
                            <i className="ri-user-follow-line" />{" "}
                            <span>Link Up</span>
                          </button>
                        ) : null}
                      </div>
                    )}

                    {!!profile_details && (
                      <>
                        <a
                          ref={requestModalRef}
                          href="javascript:;"
                          onClick={(e) =>
                            handleOpenRequestModal(
                              e,
                              "no-edit",
                              profile_details
                            )
                          }
                          data-toggle="modal"
                          data-placement="top"
                          title="Request"
                          data-target="#request-modal"
                          className="link-color network-options btn-request"
                        >
                          <i
                            className="ri-calendar-2-line align-middle text-white"
                            alt="request"
                          />
                        </a>
                        {profile_details.friends == true &&
                          !profile_details.phone_visibility && (
                            <a
                              href={
                                "tel:" +
                                "+" +
                                profile_details.country_code +
                                profile_details.phone
                              }
                              className="network-options btn-blue"
                            >
                              <i className="ri-phone-fill text-white"></i>
                              <div className="call-info-modal">
                                <span className="d-block border-0">
                                  <i className="ri-phone-fill align-middle text-primary"></i>{" "}
                                  Phone Number
                                </span>
                              </div>
                            </a>
                          )}
                        <a
                          href="javascript:;"
                          onClick={() => handleChats()}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="PoolsChat"
                          className={`link-color network__action__icon network-options chat-icon ${
                            haveNotification && "disabled"
                          }`}
                        >
                          <img src="/assets/images/chat.png" alt="chat" />
                        </a>
                      </>
                    )}
                  </div>

                  {/* END profile-header-info */}
                </div>
              </div>
              {/* end profile */}
            </div>
          </div>
        </div>

        <RequestModal
          editAppointent={editAppointent}
          setEditAppointment={setEditAppointment}
          messageDetails={userDetails}
          handleOpenRequestModal={handleOpenRequestModal}
          requestData={requestData}
          setRequestData={setRequestData}
        />
      </section>

      <section className="profile-tabs">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <ul className="nav nav-pills my-2" id="pills-tab" role="tablist">
                {!profile_details?.is_supporter &&
                  profile_details?.is_supporter != undefined && (
                    <li className="nav-item" role="presentation">
                      <a
                        onClick={(e) => {
                          setTabStatus(0);
                          pilssHideShow(e, "home");
                        }}
                        className="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        About
                      </a>
                    </li>
                  )}

                <li className="nav-item" role="presentation">
                  <a
                    onClick={(e) => {
                      setTabStatus(1);
                      pilssHideShow(e, "profile");
                    }}
                    className={`nav-link ${
                      profile_details?.is_supporter ? "active" : ""
                    }`}
                    id="pills-profile-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Posts
                  </a>
                </li>

                {!profile_details?.is_supporter &&
                  profile_details?.is_supporter != undefined && (
                    <li className="nav-item" role="presentation">
                      <a
                        onClick={(e) => {
                          handleOpenUserGallery();
                          pilssHideShow(e, "contacts");
                        }}
                        className="nav-link"
                        id="pills-contact-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        gallery
                      </a>
                    </li>
                  )}
                {is_individual == "false" &&
                  !profile_details?.is_supporter &&
                  profile_details?.is_supporter != undefined && (
                    <li className="nav-item" role="presentation">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          pilssHideShow(e, "members");
                        }}
                        className="nav-link"
                        id="pills-members-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-controls="pills-members"
                        aria-selected="false"
                      >
                        Members
                      </a>
                    </li>
                  )}

                {!profile_details?.is_supporter &&
                  profile_details?.is_supporter != undefined && (
                    <li className="nav-item" role="presentation">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          pilssHideShow(e, "services");
                        }}
                        className="nav-link"
                        id="pills-services-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-controls="pills-services"
                        aria-selected="false"
                      >
                        Services
                      </a>
                    </li>
                  )}
              </ul>
            </div>
            <div className="col-sm-4 d-flex justify-content-end">
              <div className="profile-action profile-wrapper ml-auto call-info mt-0">
                {!!profile_details && profile_details.friends == true && (
                  <>
                    <Link
                      className="btn btn-primary view-profile frnd-btn"
                      role="button"
                    >
                      <i className="ri-user-follow-line"></i>{" "}
                    </Link>

                    {/* <p onClick={handleMenuAction} className="abc">abccc</p> */}
                    <i
                      onClick={handleMenuAction}
                      className="ri-more-2-line dot-icon"
                    />

                    <ul className="important-list-drop">
                      {!!profile_details !== undefined &&
                      profile_details?.friends == false ? (
                        !!profile_details?.request_sent == true && (
                          <li>
                            <a
                              href="javascript:;"
                              onClick={handleCancelRequest}
                            >
                              <i className="ri-close-circle-line" /> Cancel
                              Request
                            </a>
                          </li>
                        )
                      ) : (
                        <li>
                          <Link onClick={handleUnfriend}>
                            <i className="ri-user-unfollow-fill" /> Un-link
                          </Link>
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="profile-tab-content spacer bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={`tab-pane fade  ${
                    !profile_details?.is_supporter &&
                    profile_details?.is_supporter != undefined
                      ? "show active"
                      : ""
                  }`}
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <GeneralDetail
                    profile_details={searchProfileResponse.profile_details}
                    is_individual={is_individual}
                  />
                  {is_individual == "true" ? (
                    <EmploymentDetail
                      employment_details={
                        searchProfileResponse.employment_details
                      }
                    />
                  ) : (
                    <ProjectDetail
                      project_details={searchProfileResponse.project_details}
                    />
                  )}
                  <SocialLink
                    social_details={searchProfileResponse.social_details}
                  />
                </div>
                <div
                  className={`tab-pane fade  ${
                    profile_details?.is_supporter ? "show active" : ""
                  }`}
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <ProfilePosts
                    addToast={addToast}
                    userId={userId}
                    profile_details={profile_details}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-contacts"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <ProfileGallery getPhotos={photoList} />
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-members"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <ProfileMembers
                    addedMembers={addedMembers}
                    userId={userId}
                    memberData={addedMembers}
                  />
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-services"
                  role="tabpanel"
                  aria-labelledby="pills-services-tab"
                >
                  <ServicesTab
                    searchProfileData={profile_details}
                    pathname={"/" + loc.pathname.split("/")[1]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default SearchProfile;
