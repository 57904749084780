// TO GET LANDING PAGE CONTENT
export const GET_LANDING_PAGE_CONTENT_REQUEST =
  "GET_LANDING_PAGE_CONTENT_REQUEST";
export const GET_LANDING_PAGE_CONTENT_SUCCESS =
  "GET_LANDING_PAGE_CONTENT_SUCCESS";
export const GET_LANDING_PAGE_CONTENT_FAILURE =
  "GET_LANDING_PAGE_CONTENT_FAILURE";

  // TO GET ALL BLOGS

export const GET_BLOGS_DATA_REQUEST="GET_BLOGS_DATA_REQUEST"
export const GET_BLOGS_DATA_SUCCESS="GET_BLOGS_DATA_SUCCESS"
export const GET_BLOGS_DATA_FAILURE="GET_BLOGS_DATA_FAILURE"

// TO GET RECENT BLOGS
export const GET_RECENT_BLOGS_REQUEST="GET_RECENT_BLOGS_REQUEST"
export const GET_RECENT_BLOGS_SUCCESS="GET_RECENT_BLOGS_SUCCESS"
export const GET_RECENT_BLOGS_FAILURE="GET_RECENT_BLOGS_FAILURE"

// TO GET SINGLE BLOG
export const GET_SINGLE_BLOG_REQUEST="GET_SINGLE_BLOG_REQUEST"
export const GET_SINGLE_BLOG_SUCCESS="GET_SINGLE_BLOG_SUCCESS"
export const GET_SINGLE_BLOG_FAILURE="GET_SINGLE_BLOG_FAILURE"


export const GET_ABOUTUS_PAGE_REQUEST="GET_ABOUTUS_PAGE_REQUEST"
export const GET_ABOUTUS_PAGE_SUCCESS="GET_ABOUTUS_PAGE_SUCCESS"
export const GET_ABOUTUS_PAGE_FAILURE="GET_ABOUTUS_PAGE_FAILURE"


// TO GET INDUSTRIES ON LANDING PAGE
export const GET_LANDINGPAGE_INDUSTRIES_REQUEST="GET_LANDINGPAGE_INDUSTRIES_REQUEST"
export const GET_LANDINGPAGE_INDUSTRIES_SUCCESS="GET_LANDINGPAGE_INDUSTRIES_SUCCESS"
export const GET_LANDINGPAGE_INDUSTRIES_FAILURE="GET_LANDINGPAGE_INDUSTRIES_FAILURE"

// TO GET FOOTER CONTENT
export const GET_FOOTER_CONTENT_REQUEST="GET_FOOTER_CONTENT_REQUEST"
export const GET_FOOTER_CONTENT_SUCCESS="GET_FOOTER_CONTENT_SUCCCESS"
export const GET_FOOTER_CONTENT_FAILURE="GET_FOOTER_CONTENT_FAILURE"

// 
export const SEARCH_BLOG_DATA_REQUEST="SEARCH_BLOG_DATA_REQUEST"
export const SEARCH_BLOG_DATA_SUCCESS="SEARCH_BLOG_DATA_SUCCESS"
export const SEARCH_BLOG_DATA_FAILURE="SEARCH_BLOG_DATA_FAILURE"

// FOR POPULAR SERVICES
export const GET_POPULAR_SERVICE_REQUEST = "GET_POPULAR_SERVICE_REQUEST"
export const GET_POPULAR_SERVICE_SUCCESS =  "GET_POPULAR_SERVICE_SUCCESS"
export const GET_POPULAR_SERVICE_FAILURE =  "GET_POPULAR_SERVICE_FAILURE"

//FOR SERVICES PROVIDER
export const GET_SERVICES_PROVIDER_REQUEST = "GET_SERVICES_PROVIDER_REQUEST"
export const GET_SERVICES_PROVIDER_SUCCESS = "GET_SERVICES_PROVIDER_SUCCESS" 
export const GET_SERVICES_PROVIDER_FAILURE = "GET_SERVICES_PROVIDER_FAILURE" 


// FOR SEARCH SERVICE PROVIDER
export const GET_SEARCH_PROVIDER_REQUEST = "GET_SEARCH_PROVIDER_REQUEST"
export const GET_SEARCH_PROVIDER_SUCCESS = "GET_SEARCH_PROVIDER_SUCCESS" 
export const GET_SEARCH_PROVIDER_FAILURE = "GET_SEARCH_PROVIDER_FAILURE" 

export const CLEAR_SEARCH_PROVIDER_LIST = "CLEAR_SEARCH_PROVIDER_LIST"

// FOR WORK AND EARN //
export const GET_WORKEARN_CONTENT_REQUEST = "GET_WORKEARN_CONTENT_REQUEST"
export const GET_WORKEARN_CONTENT_SUCCESS = "GET_WORKEARN_CONTENT_SUCCESS"
export const GET_WORKEARN_CONTENT_FAILURE = "GET_WORKEARN_CONTENT_FAILURE"

export const GET_FIND_SERVICE_PROVIDER_REQUEST = "GET_FIND_SERVICE_PROVIDER_REQUEST"
export const GET_FIND_SERVICE_PROVIDER_SUCCESS = "GET_FIND_SERVICE_PROVIDER_SUCCESS"
export const GET_FIND_SERVICE_PROVIDER_FAILURE = "GET_FIND_SERVICE_PROVIDER_FAILURE"
